import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { images } from "../../../config/images";
import { useNavigate } from "react-router-dom";

const ReportGroupExpand = ({
  access,
  handleTostMsg,
  row,
  handleEditReportModel,
  handleDeleteReportModel,
  selectCompany,
  plantId,
  handleOpenUsersModal,
  openReportModal,
  oepnUsersmodal,
  editReportModel,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [deviceLastSeenModal, setDeviceLastSeenModal] = useState({});
  const navigate = useNavigate();

  const handleClose = () => {
    if (openReportModal || oepnUsersmodal || editReportModel) {
      return true;
    }
    setIsExpanded(!isExpanded);
  };

  const dateStr = row.configuration_date;
  const date = new Date(dateStr);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayOfWeek = daysOfWeek[date.getDay()];

  const dateStrMonth = "2023-07-02";
  const dateMonth = new Date(dateStrMonth);
  const month = dateMonth.getMonth() + 1;

  // convert 12 hours format
  const convertedTime = row.configuration_time
    ? format12Hour(row.configuration_time)
    : "";

  function format12Hour(time) {
    const [hours, minutes, seconds] = time.split(":");

    let formattedTime = "";

    let hour = parseInt(hours, 10);
    let period = "";

    if (hour >= 12) {
      period = "PM";
      if (hour > 12) {
        hour -= 12;
      }
    } else {
      period = "AM";
      if (hour === 0) {
        hour = 12;
      }
    }

    formattedTime = `${hour}:${minutes} ${period}`;

    return formattedTime;
  }

  const dateString = "2023-07-20";
  const year = parseInt(dateString.split("-")[0], 10);

  const handleOpenModal = (i) => {
    setDeviceLastSeenModal({ [i]: true });
  };

  const handleCloseModal = (i) => {
    setDeviceLastSeenModal({ [i]: false });
  };

  return (
    <>
      <Card className="alarmManagement groupDetails-wrap">
        <Box className="groupDetails-innerWrap">
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              <b>{row.report_name}</b>
            </Typography>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              className="addDeviceDetailsCardetails-text"
            >
              {row.report_description}
            </Typography>
          </Box>
          {(access?.is_viewer && access?.is_editor) ||
          (!access?.is_viewer && access?.is_editor) ||
          (access?.is_viewer && !access?.is_editor) ? (
            <Box className="alarmManagement groupDetailsBtnWrap">
              {!row.user.length ? (
                <img
                  src={images.alertImage}
                  alt=""
                  className="alarmManagement groupIcon"
                  onMouseEnter={() => handleOpenModal(row.id)}
                  onMouseLeave={() => handleCloseModal(row.id)}
                />
              ) : null}
              {deviceLastSeenModal[row.id] && (
                <Card
                  className="clientManagement-updateModal-data alarm-management-update-data top"
                  style={{ marginTop: "105px" }}
                >
                  <Box className="clientMangement-updatedataWrap-data">
                    <div>
                      <h6
                        className="mb-0"
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Configuration Pending..
                      </h6>
                    </div>
                  </Box>
                </Card>
              )}

              <button
                className="alarmManagementConfiguration-btn"
                onClick={() => {
                  if (openReportModal || oepnUsersmodal || editReportModel) {
                    return true;
                  }
                  navigate(
                    `/reportmanagement/company/${selectCompany}/plant/${plantId}/report/${row.id}/configuration`
                  );
                }}
              >
                Configuration
              </button>
              <img
                src={images.editImg}
                alt=""
                className="alarmManagement groupIcon"
                style={{ cursor: "pointer" }}
                onClick={() => handleEditReportModel(row)}
              />
              <img
                src={images.deleteImg}
                style={{ cursor: "pointer" }}
                alt=""
                className="alarmManagement groupIcon"
                onClick={() => handleDeleteReportModel(row)}
              />
            </Box>
          ) : (
            <Box className="alarmManagement groupDetailsBtnWrap">
              {!row.user.length ? (
                <img
                  src={images.alertImage}
                  alt=""
                  className="alarmManagement groupIcon"
                />
              ) : null}
              <button
                className="alarmManagementConfiguration-btn"
                onClick={handleTostMsg}
              >
                Configuration
              </button>
              <img
                src={images.editImg}
                alt=""
                className="alarmManagement groupIcon"
                onClick={handleTostMsg}
                style={{ cursor: "pointer" }}
              />
              <img
                src={images.deleteImg}
                alt=""
                className="alarmManagement groupIcon"
                onClick={handleTostMsg}
                style={{ cursor: "pointer" }}
              />
            </Box>
          )}
        </Box>
        {isExpanded && (
          <>
            <hr></hr>
            <div className="reportMangement-reportDetailsWrap">
              <div class="reportManagement-flex-container">
                <div>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Report Type</b>
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className="reportmanagement-text"
                  >
                    {row.report_type}
                  </Typography>
                </div>
              </div>
              {row.email_schedule == "Daily" && (
                <div class="reportManagement-flex-container">
                  <div>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      style={{ marginBottom: "0px" }}
                    >
                      <b>Report Trigger</b>
                    </Typography>
                  </div>
                  <div>
                    <button className="alarmManagement-deviceList-button">
                      {row.email_schedule}
                    </button>
                  </div>
                  <div>
                    <button className="alarmManagement-deviceList-button">
                      {convertedTime}
                    </button>
                  </div>
                </div>
              )}

              {row.email_schedule == "Weekly" && (
                <div class="reportManagement-flex-container">
                  <div className="invisible">
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      style={{ marginBottom: "0px" }}
                    >
                      <b>Report Trigger</b>
                    </Typography>
                  </div>
                  <div>
                    <button className="alarmManagement-deviceList-button">
                      {row.email_schedule}
                    </button>
                  </div>
                  <div>
                    <button className="alarmManagement-deviceList-button">
                      {dayOfWeek}
                    </button>
                  </div>
                  <div>
                    <button className="alarmManagement-deviceList-button">
                      {convertedTime}
                    </button>
                  </div>
                </div>
              )}

              {row.email_schedule == "Monthly" && (
                <div class="reportManagement-flex-container">
                  <div className="invisible">
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      style={{ marginBottom: "0px" }}
                    >
                      <b>Report Trigger</b>
                    </Typography>
                  </div>
                  <div>
                    <button className="alarmManagement-deviceList-button">
                      {row.email_schedule}
                    </button>
                  </div>
                  <div>
                    <button className="alarmManagement-deviceList-button">
                      {month}
                    </button>
                  </div>
                  <div>
                    <button className="alarmManagement-deviceList-button">
                      {convertedTime}
                    </button>
                  </div>
                </div>
              )}

              {row.email_schedule == "Years" && (
                <div class="reportManagement-flex-container">
                  <div className="invisible">
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      style={{ marginBottom: "0px" }}
                    >
                      <b>Report Trigger</b>
                    </Typography>
                  </div>
                  <div>
                    <button className="alarmManagement-deviceList-button">
                      {row.email_schedule}
                    </button>
                  </div>
                  <div>
                    <button className="alarmManagement-deviceList-button">
                      {year}
                    </button>
                  </div>
                  <div>
                    <button className="alarmManagement-deviceList-button">
                      {convertedTime}
                    </button>
                  </div>
                </div>
              )}
              {/* <div class="reportManagement-flex-container">
                                <div className='invisible'>
                                    <Typography variant="subtitle2" gutterBottom style={{ marginBottom: '0px' }}>
                                        <b>Report Trigger</b>
                                    </Typography>
                                </div>
                                <div>
                                    <button className='alarmManagement-deviceList-button'>
                                        Weekly
                                    </button>
                                </div>
                                <div>
                                    <button className='alarmManagement-deviceList-button'>
                                        Monday
                                    </button>
                                </div>
                                <div>
                                    <button className='alarmManagement-deviceList-button'>
                                        06:00 PM
                                    </button>
                                </div>
                            </div>
                            <div class="reportManagement-flex-container">
                                <div className='invisible'>
                                    <Typography variant="subtitle2" gutterBottom style={{ marginBottom: '0px' }}>
                                        <b>Report Trigger</b>
                                    </Typography>
                                </div>
                                <div>
                                    <button className='alarmManagement-deviceList-button'>
                                        Monthly
                                    </button>
                                </div>
                                <div>
                                    <button className='alarmManagement-deviceList-button'>
                                        2
                                    </button>
                                </div>
                                <div>
                                    <button className="alarmManagement-deviceList-button">06:00 PM</button>
                                </div>
                            </div>
                            */}
              <div class="reportManagement-flex-container">
                <div>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Device</b>
                  </Typography>
                </div>
                {row.device.length
                  ? row.device.map((row1) => {
                      return (
                        <div>
                          <button className="alarmManagement-deviceList-button">
                            {row1.device_name}
                          </button>
                        </div>
                      );
                    })
                  : []}
              </div>
              <div class="reportManagement-flex-container">
                <div>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Users</b>
                  </Typography>
                </div>
                {(access?.is_viewer && access?.is_editor) ||
                (!access?.is_viewer && access?.is_editor) ? (
                  <div>
                    {/* <img src={images.userImg} alt="" style={{ cursor: 'pointer' }} onClick={handleOpenUsersModal} /> */}
                    <img
                      src={images.userImg}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={() => handleOpenUsersModal(row.user, row.id)}
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      src={images.userImg}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={handleTostMsg}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {/* {/ <img src={images.downIconSmall} alt="" className='clientMangement-downIcon' /> /} */}
        {isExpanded == true ? (
          <img
            src={images.upArrow}
            alt=""
            className="clientMangement-downIcon"
            onClick={() => handleClose()}
          />
        ) : (
          <img
            src={images.downIcon}
            alt=""
            className="clientMangement-downIcon"
            onClick={() => handleClose()}
          />
        )}
      </Card>
    </>
  );
};

export default ReportGroupExpand;
