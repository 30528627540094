import { TableCell, TableRow, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { images } from "../../../config/images";
import {
  addMachineData,
  createVirtualUid,
  deleteGateway,
  getMachineData,
  updateGatewayAPI,
} from "../service";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import GateWayExpand from "./GatewayExpandTable";
import ErrorIcon from "@mui/icons-material/Error";

const GatewayTable = ({
  row,
  handlegetGateway,
  addGatewayModal,
  cloudStatusData,
  cloudStatusData1,
  getTopCount,
  dataCount,
  accessManagementDevice,
  handleTostMsg,
  cloudData,
}) => {
  const [open, setOpen] = React.useState(false);
  const [addMachineModal, setAddMachineModal] = useState(false);
  const [updateGatewayModal, setUpdateGateWayModal] = useState(false);
  const [deleteGatewayModal, setDeleteGatewayModal] = React.useState(false);
  const [checkboxUid, setCheckBoxUid] = useState(false);
  const intialData = {
    group_name: "",
    group_description: "",
  };
  const [updateGateway, setUpdateGateway] = useState(intialData);
  const [isHovered, setIsHovered] = useState(false);
  const initialValue = {
    uid: "",
    machine_name: "",
    machine_description: "",
  };
  const [addMachine, setAddMachine] = useState(initialValue);
  const [updateMachine, setUpdateMachine] = useState(initialValue);
  const [getMachine, setGetMachine] = useState([]);
  const [deviceLastSeenModal, setDeviceLastSeenModal] = useState({});
  const [cloudMachineData, setCloudMachineData] = useState([]);
  const [gatewayId, setGatewayId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    CSV: true,
    Time: false,
  });
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedTime, setSelectedTime] = useState("30");

  console.log("selectedOption", selectedTime);

  const handleRadioChange = (event) => {
    const value = event.target.value;

    // Update the selected option state
    setSelectedOption({
      CSV: value === "CSV",
      Time: value === "Time",
    });

    // Control dropdown visibility
    setDropdownVisible(value === "Time");
  };

  const handleDropdownChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const cloudStatusDate = cloudStatusData1
    ? dayjs(cloudStatusData1.sub_time).format("DD/MM/YYYY")
    : "";

  const cloudStatusTime = cloudStatusData1
    ? dayjs(cloudStatusData1.sub_time).format("HH:mm:ss")
    : "";

  const { companyId } = useSelector((state) => state.userState);

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleOpenModal = (i) => {
    setDeviceLastSeenModal({ [i]: true });
  };

  const handleCloseModal = (i) => {
    setDeviceLastSeenModal({ [i]: false });
  };

  const handleChangeAddDevice = (e) => {
    setUpdateGateway({ ...updateGateway, [e.target.name]: e.target.value });
  };

  const handleUpdateMachinedevice = (e) => {
    setUpdateMachine({ ...updateMachine, [e.target.name]: e.target.value });
  };

  const handleAddUIDMachinedevice = (e) => {
    const { name, value } = e.target;

    // Filter out special characters except "/"
    const sanitizedValue = value.replace(/[^a-zA-Z0-9/]/g, "");

    // Update the state with the sanitized value
    setAddMachine({ ...addMachine, [name]: sanitizedValue });
  };

  const handleAddMachinedevice = (e) => {
    setAddMachine({ ...addMachine, [e.target.name]: e.target.value });
  };

  const handleOpenClick = async (row) => {
    setGatewayId(row.id);
    if (
      addGatewayModal ||
      addMachineModal ||
      updateGatewayModal ||
      deleteGatewayModal
    ) {
      return true;
    }
    setAddMachineModal(true);
    setAddMachine(initialValue);
    setUpdateGateWayModal(false);
    setDeleteGatewayModal(false);
  };

  const handleVmsaddDeviceCloseModal = () => {
    setAddMachineModal(false);
    setUpdateGateWayModal(false);
  };

  const handleUpdateGateWay = (row) => {
    if (
      addGatewayModal ||
      addMachineModal ||
      updateGatewayModal ||
      deleteGatewayModal
    ) {
      return true;
    }
    setUpdateGateWayModal(true);
    setUpdateGateway({
      group_name: row.gateway_name,
      group_description: row.gateway_description,
    });
    setAddMachineModal(false);
    setDeleteGatewayModal(false);
  };

  const handleDeleteDevice = async () => {
    try {
      const resp = await deleteGateway(row.id);
      if (resp.status == 200 || resp.status == 201) {
        setDeleteGatewayModal(false);
        getTopCount();
        handlegetGateway();
        notifySuccess("Gateway deleted successfully");
      }
    } catch (error) {
      notify(error.response.data.message);
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (open) {
      handleGetMachine();
    }
  }, [open]);

  const handleGetMachine = async () => {
    const params = {
      gateway: row.id,
    };
    try {
      const resp = await getMachineData(params);
      if (resp.status == 200 || resp.status == 201) {
        setGetMachine(resp.data.payload);
        setCloudMachineData(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
    // const interval = setInterval(handleGetMachine, 10000);

    // return () => clearInterval(interval);
  };

  const handleUpdateAPI = async () => {
    if (updateGateway.group_name === "") {
      notify("Please Enter Gateway Name.");
      return;
    }
    if (updateGateway.group_description === "") {
      notify("Please Enter Gateway Description.");
      return;
    }
    const params = {
      gateway_name: updateGateway.group_name,
      gateway_description: updateGateway.group_description,
      company: companyId,
    };
    try {
      const resp = await updateGatewayAPI(row.id, params);
      if (resp.status == 200 || resp.status == 201) {
        setUpdateGateWayModal(false);
        handlegetGateway();
        notifySuccess("Gateway updated successfully");
      }
    } catch (error) {
      notify(error.response.data.message);
      console.log("handlesubmit", error);
    }
  };
  const handleDeletemodal = () => {
    if (
      addGatewayModal ||
      addMachineModal ||
      updateGatewayModal ||
      deleteGatewayModal
    ) {
      return true;
    }
    setDeleteGatewayModal(true);
    setAddMachineModal(false);
    setUpdateGateWayModal(false);
  };

  const handleAddMachineData = async () => {
    if (addMachine.uid === "") {
      notify("Please Enter Uid.");
      return;
    }
    if (addMachine.machine_name === "") {
      notify("Please Enter Machine Name.");
      return;
    }
    if (addMachine.machine_description === "") {
      notify("Please Enter Machine Description.");
      return;
    }

    const params = {
      gateway: row.id,
      uid: addMachine.uid,
      machine_name: addMachine.machine_name,
      machine_description: addMachine.machine_description,
      is_non_hikar_product: checkboxUid,
      is_csv_upload_enabled: selectedOption.CSV,
      is_frequency_time_enabled: selectedOption.Time,
    };
    if (dropdownVisible) {
      params["data_store_frequency_time"] = selectedTime;
    } else {
      params["data_store_frequency_time"] = 0;
    }
    try {
      const resp = await addMachineData(params);
      if (resp.status == 200 || resp.status == 201) {
        setAddMachineModal(false);
        notifySuccess("Machine added successfully");
        handleGetMachine();
        handlegetGateway();
        getTopCount();
      }
    } catch (error) {
      notify(error.response.data.message);
      console.log("handlesubmit", error);
    }
  };
  const handlCheckUid = async (value) => {
    setCheckBoxUid(!checkboxUid);
    if (value) {
      const params = {
        gateway: gatewayId,
      };
      try {
        const resp = await createVirtualUid(params);
        if (resp.status == 200 || resp.status == 201) {
          setAddMachine({
            ...addMachine,
            ["uid"]: resp.data.payload.virtual_uid,
          });
        }
      } catch (error) {
        console.log("handlesubmit", error);
      }
    } else {
      setAddMachine({ ...addMachine, ["uid"]: "" });
    }
  };
  return (
    <>
      <TableRow className="deviceManagementTable-data">
        <TableCell>
          <div className="d-flex gap-4">
            {cloudStatusData ? (
              <img
                src={images.cloudActiveImg}
                alt=""
                className="cloudActiveImg"
                onMouseEnter={() => handleOpenModal(row.id)}
                onMouseLeave={() => handleCloseModal(row.id)}
              />
            ) : (
              <img
                src={images.cloudImg}
                alt=""
                className="cloudActiveImg"
                onMouseEnter={() => handleOpenModal(row.id)}
                onMouseLeave={() => handleCloseModal(row.id)}
              />
            )}
            {cloudData == true ? (
              <img
                src={images.alertImage}
                alt=""
                className="device-alert-image"
              />
            ) : (
              ""
            )}
          </div>

          {deviceLastSeenModal[row.id] && (
            <Card className="clientManagement-updateModal-data top">
              <Box className="clientMangement-updatedataWrap-data">
                <div>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    className="clientManagement deviceTabListText"
                  >
                    Last Seen
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    className="clientManagement deviceTabListText small"
                  >
                    {cloudStatusDate}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    className="clientManagement deviceTabListText small"
                  >
                    {cloudStatusTime}
                  </Typography>
                </div>
              </Box>
            </Card>
          )}
        </TableCell>
        <TableCell>{row.gateway_name}</TableCell>
        <TableCell>{row.gateway_description}</TableCell>
        <TableCell>{row.company_name}</TableCell>

        <TableCell>
          <div className="product-count-main-container">
            <Stack direction="row" className="deviceManagementTable-license">
              {row.license_status == "Active" ? (
                <img src={images.activeKey} alt="" className="cloudActiveImg" />
              ) : row.license_status == "Expired" ? (
                <img
                  src={images.expiresImg}
                  alt=""
                  className="cloudActiveImg"
                />
              ) : row.license_status == "Expiring Soon" ? (
                <img
                  src={images.expiresSoonImg}
                  alt=""
                  className="cloudActiveImg"
                />
              ) : (
                ""
              )}
              {row.license_status == "Unknown" ? "-" : row.license_status}
            </Stack>

            <div className="icon-popup">
              {Object.keys(row.product_counts).length > 0 ? (
                <ErrorIcon
                  className="OutlineIcon"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
              ) : (
                ""
              )}

              {isHovered && Object.keys(row.product_counts).length > 0 ? (
                <Card className="top product_counts_popup">
                  <Box className="clientMangement-updatedataWrap-data">
                    <div>
                      {row.product_counts
                        ? Object.entries(row.product_counts).map(
                            ([key, value]) => (
                              <Typography
                                key={key} // assuming 'key' is unique for each entry
                                variant="h5"
                                gutterBottom
                                className="clientManagement deviceTabListText"
                              >
                                <h5>
                                  {" "}
                                  {key}: {value}
                                </h5>
                              </Typography>
                            )
                          )
                        : null}
                    </div>
                  </Box>
                </Card>
              ) : (
                ""
              )}
            </div>
          </div>
        </TableCell>
        <TableCell>
          {(accessManagementDevice?.is_viewer &&
            accessManagementDevice?.is_editor) ||
          (!accessManagementDevice?.is_viewer &&
            accessManagementDevice?.is_editor) ? (
            <Stack direction="row" className="deviceManagementTable-icon">
              <img
                src={images.editImg}
                alt=""
                className="cloudActiveImg"
                onClick={() => handleUpdateGateWay(row)}
              />

              <img
                src={images.addImage}
                alt=""
                style={{ height: "23px", marginTop: "3px", cursor: "pointer" }}
                className="cloudActiveImg"
                onClick={() => handleOpenClick(row)}
              />
              <img
                src={images.deleteImg}
                alt=""
                className="cloudActiveImg"
                onClick={handleDeletemodal}
              />
              <IconButton
                style={{ height: "23px", marginTop: "3px", cursor: "pointer" }}
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Stack>
          ) : (
            <Stack direction="row" className="deviceManagementTable-icon">
              <img
                src={images.editImg}
                alt=""
                className="cloudActiveImg"
                onClick={handleTostMsg}
              />

              <img
                src={images.addImage}
                alt=""
                style={{ height: "23px", marginTop: "3px", cursor: "pointer" }}
                className="cloudActiveImg"
                onClick={handleTostMsg}
              />
              <img
                src={images.deleteImg}
                alt=""
                className="cloudActiveImg"
                onClick={handleTostMsg}
              />
              <IconButton
                style={{ height: "23px", marginTop: "3px", cursor: "pointer" }}
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Stack>
          )}
        </TableCell>
        {addMachineModal && (
          <Card className="addDeviceModal-card">
            <Box className="addDeviceModal-titleWrap">
              <Typography
                variant="h5"
                gutterBottom
                className="addDeviceModal-title"
              >
                ADD MACHINE
              </Typography>
              <img
                src={images.closeIcon}
                alt=""
                style={{
                  width: "30px",
                  cursor: "pointer",
                  backgroundColor: "white",
                }}
                className="closeImg"
                onClick={() => handleVmsaddDeviceCloseModal()}
              />
            </Box>
            <Box className="addDeviceModal-headerWrap">
              <Typography variant="body2" gutterBottom>
                Enter the UID of your device and name it individually.
                <br></br> You can find the UID/OR on the nameplate of the device
                <br></br> supplied by <b>HIKAR</b>
              </Typography>
            </Box>
            <Box className="addDeviceModal-innerWrap">
              <Box className="addDeviceModal-checkBoxWrap">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={(e) => handlCheckUid(e.target.checked)}
                        value={checkboxUid}
                      />
                    }
                  />
                </FormGroup>
                <Typography
                  variant="body2"
                  gutterBottomk
                  className="addDeviceModal-text"
                >
                  This Device is not supplied by HIKAR, generate a virtual UID.
                </Typography>
              </Box>

              <Stack spacing={2} pt="10px">
                <TextField
                  id="filled-basic"
                  name="uid"
                  label="UID"
                  variant="filled"
                  className="customeTextField deviceManagement"
                  onChange={handleAddUIDMachinedevice}
                  // value={}
                  value={addMachine.uid}
                  disabled={checkboxUid == true}
                  InputProps={{
                    inputProps: { maxLength: 21 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <img src={images.scanImage} alt="" />
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className="addDeviceModal-text"
                >
                  e.g. 71f73dee-4596-48c8-bdof-7f1234d
                </Typography>
                <TextField
                  id="filled-basic"
                  name="machine_name"
                  label="Machine Name"
                  variant="filled"
                  className="customeTextField deviceManagement"
                  onChange={handleAddMachinedevice}
                  value={addMachine.machine_name}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className="mt-1"
                >
                  e.g. Furnace 01
                </Typography>
                <TextField
                  id="filled-basic"
                  name="machine_description"
                  label="Machine Description"
                  variant="filled"
                  className="customeTextField deviceManagement"
                  onChange={handleAddMachinedevice}
                  value={addMachine.machine_description}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className="addDeviceModal-text"
                >
                  e.g. Furnace 01 of Plant 1
                </Typography>
              </Stack>
              <div className="d-flex justify-content-between">
                <RadioGroup
                  row
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  value={Object.keys(selectedOption).find(
                    (key) => selectedOption[key]
                  )} // Set the selected radio button
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="CSV"
                    control={<Radio />}
                    label="CSV"
                  />
                  <FormControlLabel
                    value="Time"
                    control={<Radio />}
                    label="Time"
                  />
                </RadioGroup>
                {dropdownVisible && (
                  <div>
                    <label htmlFor="time-select" style={{ marginRight: "7px" }}>
                      Time
                    </label>
                    <select
                      id="time-select"
                      className="select-dropdown"
                      value={selectedTime}
                      onChange={handleDropdownChange}
                    >
                      <option value="30">30s</option>
                      <option value="60">1m</option>
                      <option value="90">1.5m</option>
                      <option value="120">2m</option>
                      <option value="150">2.5m</option>
                      <option value="180">3m</option>
                      <option value="210">3.5m</option>
                      <option value="240">4m</option>
                      <option value="270">4.5m</option>
                      <option value="300">5m</option>
                    </select>
                  </div>
                )}
              </div>
              <Box className="accountSecurity-cardButtons deviceManagement">
                <button className="addDeviceBtn" onClick={handleAddMachineData}>
                  ADD MACHINE TO HIKAR.CLOUD
                </button>
              </Box>
            </Box>
          </Card>
        )}

        {updateGatewayModal && (
          <Card className="addDeviceModal-card">
            <Box className="addDeviceModal-titleWrap">
              <Typography
                variant="h5"
                gutterBottom
                className="addDeviceModal-title"
              >
                UPDATE GATEWAY
              </Typography>
              <img
                src={images.closeIcon}
                alt=""
                style={{
                  width: "30px",
                  cursor: "pointer",
                  backgroundColor: "white",
                }}
                className="closeImg"
                onClick={handleVmsaddDeviceCloseModal}
              />
            </Box>

            <Box className="addDeviceModal-innerWrap">
              <Stack spacing={2} pt="10px">
                <TextField
                  id="filled-basic"
                  name="group_name"
                  label="Gateway Name"
                  variant="filled"
                  className="customeTextField deviceManagement"
                  onChange={handleChangeAddDevice}
                  value={updateGateway.group_name}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className="mt-1"
                >
                  e.g. Furnace 01
                </Typography>
                <TextField
                  id="filled-basic"
                  name="group_description"
                  label="Gateway Description"
                  variant="filled"
                  className="customeTextField deviceManagement"
                  onChange={handleChangeAddDevice}
                  value={updateGateway.group_description}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className="addDeviceModal-text"
                >
                  e.g. Furnace 01 of Plant 1
                </Typography>
              </Stack>
              <Box className="accountSecurity-cardButtons deviceManagement">
                <button className="addDeviceBtn" onClick={handleUpdateAPI}>
                  UPDATE GATEWAY TO HIKAR.CLOUD
                </button>
              </Box>
            </Box>
          </Card>
        )}

        {deleteGatewayModal && (
          <Card className="deleteConfirmation-Modal">
            <Typography
              variant="h6"
              gutterBottom
              className="deleteConfirmation-title"
            >
              Are You Sure<br></br> You Want To Delete?
            </Typography>
            <Box className="deleteConfirmation-btnWrap">
              <button
                className="deleteConfirmation-Yesbtn"
                onClick={() => handleDeleteDevice()}
              >
                Yes
              </button>
              <button
                className="deleteConfirmation-Nobtn"
                onClick={() => setDeleteGatewayModal(false)}
              >
                No
              </button>
            </Box>
          </Card>
        )}
      </TableRow>
      <GateWayExpand
        open={open}
        handleUpdateMachinedevice={handleUpdateMachinedevice}
        row={row}
        setAddMachineModal={setAddMachineModal}
        setUpdateGateWayModal={setUpdateGateWayModal}
        setDeleteGatewayModal={setDeleteGatewayModal}
        handleGetMachine={handleGetMachine}
        getMachine={getMachine}
        addMachineModal={addMachineModal}
        updateGatewayModal={updateGatewayModal}
        deleteGatewayModal={deleteGatewayModal}
        getTopCount={getTopCount}
        dataCount={dataCount}
        handlegetGateway={handlegetGateway}
        accessManagementDevice={accessManagementDevice}
        handleTostMsg={handleTostMsg}
        cloudMachineData={cloudMachineData}
      />
    </>
  );
};
export default GatewayTable;
