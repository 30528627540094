import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Switch from "react-switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { images } from "../../../config/images";
import {
    getUser,
    getUserDepartment,
    adduserDepartment,
    updateUserDepartment,
    deleteUserDepartment,
    searchUserDataList,
    getCompanyUserManage,
    exportUserReport,
    getCompanyByDetails,
    createAdmin,
} from "../service";
import "./UserManagement.css";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/Loader/Loader";
import { ValidatorForm } from "react-material-ui-form-validator";

const CompanyByUserManagement = () => {
    const navigate = useNavigate();
    const [openUpdateUserManagement, setUpdateUserManagement] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [userData, setUserData] = useState([]);
    const [addUserModal, setAddUserModal] = useState(false);
    const [department, setDepartment] = useState([]);
    const [deleteUserData, setDeleteUserData] = useState([]);
    const [selectDepartment, setSelectDepartment] = useState("none");
    const [selectRole, setSelectRole] = useState("none");
    const [createAdminModal, setCreateAdminModal] = useState(false);
    const [userId, setUserId] = useState("");
    const [checked, setChecked] = useState(false);
    const [addUserDepartment, setaddUserDepartment] = useState({
        first_name: "",
        last_name: "",
        email: "",
    });
    const [updateUser, setupdateUser] = useState({
        department: "",
        role_name: "",
        first_name: "",
        last_name: "",
        email: "",
    });
    const [userRowId, setUserRowId] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [selectUserId, setSelectUserId] = useState([]);
    const [companyName, setCompanyName] = useState("");

    const { is_super_admin } = useSelector((state) => state.userState);

    const [searchUserDataList, setSearchUserList] = useState({
        first_name: "",
        last_name: "",
        department__department_name: "",
        user_role__name: "",
        email: "",
    });

    const param = useParams();
    const { companyId } = useSelector((state) => state.userState);

    const notify = (message) =>
        toast.error(message, {
            theme: "colored",
            position: toast.POSITION.TOP_RIGHT,
            toastClassName: "custom-toast",
        });

    const notify1 = (message) =>
        toast.success(message, {
            theme: "colored",
            position: toast.POSITION.TOP_RIGHT,
            toastClassName: "custom-toast",
        });

    // company deatils
    const getCompanyByDetailsApi = async () => {
        const paramReq = {
            company_id: param.id,
        };
        const resp = await getCompanyByDetails(paramReq);
        console.log("resprespresp", resp);
        if (resp.status == 200) {
            setCompanyName(resp.data.payload.company_name);
        }
    };

    useEffect(() => {
        getCompanyByDetailsApi();
    }, []);

    //Add user
    const handleAddUser = () => {
        if (openUpdateUserManagement || openDeleteModal || addUserModal) {
            return true;
        }
        setAddUserModal(!addUserModal);
    };

    const handleAddCloseUser = () => {
        setAddUserModal(!addUserModal);
    };

    //Update user
    const handleUserUpdate = (e) => {
        if (openUpdateUserManagement || openDeleteModal || addUserModal) {
            return true;
        }
        // setupdateUser({department: , user_role: "none", first_name: "", last_name: "", email: ""})
        if (is_super_admin == false) {
            if (
                (e.user_role == "Admin" && e.is_admin) ||
                (e.user_role == "Super Admin" && e.is_super_admin)
            ) {
                notify("Admin user is not update");
                return true;
            }
        }

        setupdateUser({
            department: e.department_id,
            role_name: e.role_id,
            first_name: e.first_name,
            last_name: e.last_name,
            email: e.email,
        });

        setUpdateUserManagement(!openUpdateUserManagement);
        setOpenDeleteModal(false);
        setUserRowId(e.id);
    };

    const handleUserCloseUpdate = () => {
        setUpdateUserManagement(!openUpdateUserManagement);
    };

    //Delete user modal
    const handleConfirmationModal = (id) => {
        if (
            openUpdateUserManagement ||
            openDeleteModal ||
            addUserModal ||
            createAdminModal
        ) {
            return true;
        }
        if (!selectedRows.includes(id)) {
            notify("Please Select User");
            return true;
        }
        setUserRowId(id);
        setOpenDeleteModal(true);
        setUpdateUserManagement(false);
    };

    const handleConfirmationModalCloseModel = () => {
        setOpenDeleteModal(!openDeleteModal);
    };

    // get userList
    const getUserList = async () => {
        const params = {
            company: param.id,
        };
        try {
            const response = await getCompanyUserManage(params, searchUserDataList);
            if (response.status == 200 || response.status == 201) {
                setUserData(response.data.payload);
            }
        } catch (error) {
            setLoading(false);
            console.log("handleSubmit", error);
        }
    };

    //get user department list
    const getUserDepartmentList = async () => {
        const paramReq = {
            company: param.id,
        };
        const response = await getUserDepartment(paramReq);
        setDepartment(response.data.payload);
    };

    //Add user Department
    const handleChangeuserManagement = (e) => {
        setaddUserDepartment({
            ...addUserDepartment,
            [e.target.name]: e.target.value,
        });
    };

    // handleUpdateUser
    const handelChangeUpdateUser = (e) => {
        setupdateUser((prevState) => {
            const updatedUser = { ...prevState, [e.target.name]: e.target.value };
            if (e.target.name === "department") {
                updatedUser.role_name = "none";
            }
            return updatedUser;
        });
    };

    const handleSearchUserData = (e) => {
        setSearchUserList({
            ...searchUserDataList,
            [e.target.name]: e.target.value,
        });
    };

    //get Device
    // const searchUserList = async () => {
    //     const response = await searchUserDataList(searchUserDataList);
    //     if (response.status == 200) {
    //         setSearchUserList(response.data.payload)
    //     }
    // }

    const submitUserDepartment = async () => {
        if (selectDepartment === "none") {
            notify("Please Select Department");
            return;
        }

        if (selectRole === "none") {
            notify("Please Select Role");
            return;
        }

        if (addUserDepartment.first_name === "") {
            notify("Please Enter First Name");
            return;
        }

        if (addUserDepartment.last_name === "") {
            notify("Please Enter Last Name");
            return;
        }

        if (addUserDepartment.email === "") {
            notify("Please Enter Email");
            return;
        }

        const params = {
            company_name: param.id,
            department: Number(selectDepartment),
            role: Number(selectRole),
            first_name: addUserDepartment.first_name,
            last_name: addUserDepartment.last_name,
            email: addUserDepartment.email,
        };

        try {
            setLoading(true);
            const response = await adduserDepartment(params);
            if (response.status === 200 || response.status === 201) {
                notify1("add user successfully");
                setLoading(false);
                getUserList();
                setAddUserModal(!addUserModal);
            }
        } catch (error) {
            setLoading(false);
            console.log("err", error);
            notify(error.response.data.email[0]);
        }
    };

    const handleUserExportReport = async () => {
        if (openUpdateUserManagement || openDeleteModal || addUserModal) {
            return true;
        }
        const params = {
            id: selectedRows.length ? selectedRows : selectUserId,
        };
        const resp = await exportUserReport(params);
        if (resp.status == 200 || resp.status == 201) {
            notify1("Export User Management Excel Report Successfully");
        }
    };

    useEffect(() => {
        getUserDepartmentList();
    }, []);

    useEffect(() => {
        getUserList();
    }, [searchUserDataList]);

    const handelUpdateUser = async () => {
        if (updateUser.role_name == "none") {
            notify("Please select role.");
            return;
        }

        if (updateUser.first_name === "") {
            notify("Please Enter First Name");
            return;
        }

        if (updateUser.last_name === "") {
            notify("Please Enter Last Name");
            return;
        }

        if (updateUser.email === "") {
            notify("Please Enter Email");
            return;
        }
        const param = {
            department: Number(updateUser.department),
            role: Number(updateUser.role_name),
            first_name: updateUser.first_name,
            last_name: updateUser.last_name,
            email: updateUser.email,
        };
        try {
            setLoading(true);
            const resp = await updateUserDepartment(userRowId, param);
            if (resp.status == 200 || resp.status == 201) {
                notify1("update user successfully");
                getUserList();
                setLoading(false);
                setUpdateUserManagement(false);
            }
        } catch (error) {
            setLoading(false);
            notify(error.response.data.email[0]);
        }
    };

    const handelDeleteUser = async () => {
        try {
            setLoading(true);
            const resp = await deleteUserDepartment(selectedRows);
            if (resp.data.success == true) {
                notify1(resp.data.message);
                setLoading(false);
                setOpenDeleteModal(!openDeleteModal);

                getUserList();
            }
            if (resp.data.success == false) {
                notify(resp.data.message);
                setCreateAdminModal(true);
                setDeleteUserData(resp.data.payload);
                setLoading(false);
                setOpenDeleteModal(!openDeleteModal);
                getUserList();
            }
            // if (resp.data.success === false) {
            //     notify(resp.data.message);
            //     setCreateAdminModal(true);
            //     setDeleteUserData(resp.data.payload);
            //     setLoading(false);
            //     setOpenDeleteModal(false); // Close openDeleteModal
            //     getUserList();
            //   } else if (resp.data.success === true) {
            //     notify1(resp.data.message);
            //     setLoading(false);
            //     setOpenDeleteModal(!openDeleteModal);
            //     getUserList();
            //   }
        } catch (error) {
            setLoading(false);
            setOpenDeleteModal(!openDeleteModal);
            notify(error.response.data.message);
        }
    };

    const handleSelectRow = (rowId) => {
        if (openUpdateUserManagement || openDeleteModal || addUserModal) {
            return true;
        }
        const selectedIndex = selectedRows.indexOf(rowId);
        let newSelectedRows = [];

        if (selectedIndex === -1) {
            newSelectedRows = [...selectedRows, rowId];
        } else {
            newSelectedRows = selectedRows.filter((id) => id !== rowId);
        }

        setSelectedRows(newSelectedRows);
    };

    const handleCreateAdmin = async (id) => {
        setUserId(id);
        const params = {
            company: param.id,
            user: id,
        };
        try {
            const resp = await createAdmin(params);
            if (resp.status == 200 || resp.status == 201) {
                notify1(resp.data.message);
                setChecked(true);
                getUserList();
            }
        } catch (error) {
            console.log("handlesubmit", error);
        }
    };

    const handelDeleteAdminUser = async () => {
        try {
            setLoading(true);
            const resp = await deleteUserDepartment(selectedRows);
            if (resp.data.success == true) {
                notify1(resp.data.message);
                setLoading(false);
                window.location.reload(false)
                getUserList();
            }
        } catch (error) {
            setLoading(false);
            setOpenDeleteModal(false);
            window.location.reload(false)
            notify(error.response.data.message);
        }
    };
    const handleAdminDelete = () => {
        handelDeleteAdminUser();
        setOpenDeleteModal(false);
        setCreateAdminModal(false);
    };

    const handleNavigateView = (id) => {
        navigate(`/usermanagement/accessManagement/company/${param.id}/view/${id}`)
    }
    return (
        <>
            <div>{/*  */}</div>
            <div className="containerWrap">
                <Container maxWidth="xxl">
                    <div className="deviceManagementHeader-wrap configure">
                        <div className="deviceManagementTitle-wrap">
                            <KeyboardBackspaceIcon
                                onClick={() => navigate("/clientmanagement")}
                                className="backArrow"
                            />{" "}
                            <Typography
                                variant="h5"
                                className="deviceManagementTitle"
                                gutterBottom
                                style={{ margin: "0" }}
                                onClick={() => navigate("/clientmanagement")}
                            >
                                {companyName} - User Management
                            </Typography>
                        </div>
                        <div className="addDevice-buttonWrap">
                            <button
                                className="manageDepartment-button"
                                onClick={() => {
                                    if (
                                        openUpdateUserManagement ||
                                        openDeleteModal ||
                                        addUserModal
                                    ) {
                                        return true;
                                    }
                                    navigate(`/accessManagement/company/${param.id}`);
                                }}
                            >
                                Access Management
                            </button>
                            {/* <img src={images.addImage} alt="" className='cloudActiveImg' onClick={handleUserUpdate} /> */}
                            <img
                                src={images.addImage}
                                alt=""
                                className="cloudActiveImg"
                                onClick={handleAddUser}
                            />
                            <img src={images.deleteIcon} alt="" className="cloudActiveImg" />
                            <img
                                src={images.excelLogo}
                                alt=""
                                className="cloudActiveImg"
                                onClick={handleUserExportReport}
                            />
                        </div>
                    </div>
                    {/* =============== User Add Modal Start =============== */}
                    <ValidatorForm onSubmit={submitUserDepartment}>
                        {addUserModal && (
                            <Card className="inviteUserModal">
                                <Box>
                                    <Stack direction="row" spacing={{ sm: 42, md: 42, lg: 34 }}>
                                        <Typography gutterBottom style={{ display: "none" }}>
                                            ..
                                        </Typography>
                                        <img
                                            src={images.closeIcon}
                                            alt=""
                                            style={{ width: "30px", cursor: "pointer" }}
                                            onClick={handleAddCloseUser}
                                        />
                                    </Stack>
                                    <Typography
                                        gutterBottom
                                        style={{
                                            color: "#1C407B",
                                            fontWeight: "600",
                                            fontSize: "19px",
                                            textAlign: "center",
                                        }}
                                    >
                                        User Management
                                    </Typography>
                                </Box>
                                <Box>
                                    <div className="useInviteWrap">
                                        <label
                                            for="exampleInputEmail1"
                                            class="form-label userInviteLabel"
                                        >
                                            <b>Department</b>{" "}
                                            <span style={{ color: "#E31E24" }}>*</span>
                                        </label>
                                        <select
                                            class="form-select form-select-lg updateUserDropdown"
                                            value={selectDepartment}
                                            onChange={(e) => setSelectDepartment(e.target.value)}
                                            aria-label=".form-select-lg example"
                                        >
                                            <option value="none" selected disabled hidden>
                                                Select Department
                                            </option>
                                            {/* <option value="1">Engineer</option>
                                        <option value="2">Admin</option> */}
                                            {department.length
                                                ? department.map((e) => {
                                                    return (
                                                        <option value={e.id}>{e.department_name}</option>
                                                    );
                                                })
                                                : []}
                                        </select>
                                    </div>
                                    <div className="useInviteWrap">
                                        <label
                                            for="exampleInputEmail1"
                                            class="form-label userInviteLabel"
                                        >
                                            <b>Role</b>
                                            <span style={{ color: "#E31E24" }}>*</span>
                                        </label>
                                        <select
                                            class="form-select form-select-lg updateUserDropdown"
                                            value={selectRole}
                                            onChange={(e) => setSelectRole(e.target.value)}
                                            aria-label=".form-select-lg example"
                                        >
                                            <option value="none" selected disabled hidden>
                                                Select Role
                                            </option>
                                            {department.length
                                                ? department.map((e) => {
                                                    if (selectDepartment == e.id) {
                                                        return e.company_roles.map((e1) => {
                                                            return (
                                                                <option value={e1.id}>{e1.role_name}</option>
                                                            );
                                                        });
                                                    }
                                                })
                                                : []}
                                        </select>
                                    </div>
                                    <div className="useInviteWrap">
                                        <label
                                            for="exampleInputEmail1"
                                            class="form-label userInviteLabel"
                                        >
                                            <b>First Name</b>
                                            <span style={{ color: "#E31E24" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            class="form-control userInviteLabelplaceHolder"
                                            id="exampleInputEmail1"
                                            placeholder="Enter First Name"
                                            onChange={handleChangeuserManagement}
                                        />
                                    </div>
                                    <div className="useInviteWrap">
                                        <label
                                            for="exampleInputEmail1"
                                            class="form-label userInviteLabel"
                                        >
                                            <b>Last Name</b>
                                            <span style={{ color: "#E31E24" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="last_name"
                                            class="form-control userInviteLabelplaceHolder"
                                            id="exampleInputEmail1"
                                            placeholder="Enter Last Name"
                                            onChange={handleChangeuserManagement}
                                        />
                                    </div>
                                    <div className="useInviteWrap">
                                        <label
                                            for="exampleInputEmail1"
                                            class="form-label userInviteLabel"
                                        >
                                            <b>Email Id</b>
                                            <span style={{ color: "#E31E24" }}>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            class="form-control userInviteLabelplaceHolder"
                                            id="exampleInputEmail1"
                                            placeholder="Enter Email Id"
                                            onChange={handleChangeuserManagement}
                                        />
                                    </div>
                                </Box>
                                <Box className="">
                                    <button className="ClientManagement InviteUser-Button">
                                        Add User
                                    </button>
                                </Box>
                            </Card>
                        )}
                    </ValidatorForm>
                    {/* =============== User Add Modal End =============== */}
                    {/* =============== User Upadte Modal Start =============== */}
                    <ValidatorForm onSubmit={handelUpdateUser}>
                        {openUpdateUserManagement && (
                            <Card className="inviteUserModal">
                                <Box>
                                    <Stack direction="row" spacing={{ sm: 42, md: 42, lg: 34 }}>
                                        <Typography gutterBottom style={{ display: "none" }}>
                                            ..
                                        </Typography>
                                        <img
                                            src={images.closeIcon}
                                            alt=""
                                            style={{ width: "30px", cursor: "pointer" }}
                                            onClick={handleUserCloseUpdate}
                                        />
                                    </Stack>
                                    <Typography
                                        gutterBottom
                                        style={{
                                            color: "#1C407B",
                                            fontWeight: "600",
                                            fontSize: "19px",
                                            textAlign: "center",
                                        }}
                                    >
                                        User Management
                                    </Typography>
                                </Box>
                                <Box>
                                    <div className="useInviteWrap">
                                        <label
                                            for="exampleInputEmail1"
                                            class="form-label userInviteLabel"
                                        >
                                            <b>Department</b>{" "}
                                            <span style={{ color: "#E31E24" }}>*</span>
                                        </label>
                                        <select
                                            class="form-select form-select-lg updateUserDropdown"
                                            value={updateUser.department}
                                            name="department"
                                            onChange={(e) => handelChangeUpdateUser(e)}
                                            aria-label=".form-select-lg example"
                                        >
                                            <option value="none" selected disabled hidden>
                                                Select Department
                                            </option>
                                            {/* <option value="1">Engineer</option>
                                        <option value="2">Admin</option> */}
                                            {department.length
                                                ? department.map((e) => {
                                                    return (
                                                        <option value={e.id}>{e.department_name}</option>
                                                    );
                                                })
                                                : []}
                                        </select>
                                    </div>
                                    <div className="useInviteWrap">
                                        <label
                                            for="exampleInputEmail1"
                                            class="form-label userInviteLabel"
                                        >
                                            <b>Role</b>
                                            <span style={{ color: "#E31E24" }}>*</span>
                                        </label>
                                        <select
                                            class="form-select form-select-lg updateUserDropdown"
                                            value={updateUser.role_name}
                                            name="role_name"
                                            onChange={(e) => handelChangeUpdateUser(e)}
                                            aria-label=".form-select-lg example"
                                        >
                                            <option value="none" selected disabled hidden>
                                                Select Role
                                            </option>
                                            {/* <option value="1">Engineer</option>
                                        <option value="2">Admin</option> */}
                                            {department.length
                                                ? department.map((e) => {
                                                    if (updateUser.department == e.id) {
                                                        return e.company_roles.map((e1) => {
                                                            return (
                                                                <option value={e1.id}>{e1.role_name}</option>
                                                            );
                                                        });
                                                    }
                                                })
                                                : []}
                                        </select>
                                    </div>
                                    <div className="useInviteWrap">
                                        <label
                                            for="exampleInputEmail1"
                                            class="form-label userInviteLabel"
                                        >
                                            <b>First Name</b>
                                            <span style={{ color: "#E31E24" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            class="form-control userInviteLabelplaceHolder"
                                            id="exampleInputEmail1"
                                            placeholder="Enter First Name"
                                            value={updateUser.first_name}
                                            onChange={(e) => handelChangeUpdateUser(e)}
                                        />
                                    </div>
                                    <div className="useInviteWrap">
                                        <label
                                            for="exampleInputEmail1"
                                            class="form-label userInviteLabel"
                                        >
                                            <b>Last Name</b>
                                            <span style={{ color: "#E31E24" }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="last_name"
                                            class="form-control userInviteLabelplaceHolder"
                                            id="exampleInputEmail1"
                                            placeholder="Enter Last Name"
                                            value={updateUser.last_name}
                                            onChange={(e) => handelChangeUpdateUser(e)}
                                        />
                                    </div>
                                    <div className="useInviteWrap">
                                        <label
                                            for="exampleInputEmail1"
                                            class="form-label userInviteLabel"
                                        >
                                            <b>Email Id</b>
                                            <span style={{ color: "#E31E24" }}>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            class="form-control userInviteLabelplaceHolder"
                                            id="exampleInputEmail1"
                                            placeholder="Enter Email Id"
                                            value={updateUser.email}
                                            onChange={(e) => handelChangeUpdateUser(e)}
                                        />
                                    </div>
                                </Box>
                                <Box className="">
                                    {/* <button className='ClientManagement InviteUser-Button userManagement' onClick={() => handelUpdateUser()} >Update</button> */}
                                    <button className="ClientManagement InviteUser-Button userManagement">
                                        Update
                                    </button>
                                </Box>
                            </Card>
                        )}
                    </ValidatorForm>
                    {/* =============== User Upadte Modal End =============== */}
                    {/* =============== Delete user Modal Start =============== */}
                    {openDeleteModal && (
                        <Card className="deleteConfirmation-Modal">
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="deleteConfirmation-title"
                            >
                                Are You Sure<br></br> You Want To Delete?
                            </Typography>
                            <Box className="deleteConfirmation-btnWrap">
                                <button
                                    className="deleteConfirmation-Yesbtn"
                                    onClick={handelDeleteUser}
                                >
                                    Yes
                                </button>
                                <button
                                    className="deleteConfirmation-Nobtn"
                                    onClick={handleConfirmationModalCloseModel}
                                >
                                    No
                                </button>
                            </Box>
                        </Card>
                    )}
                    {createAdminModal && (
                        <Card className="createAdminDeleteUser">
                            <CloseIcon
                                className="closeIcon createCloseIcon"
                                onClick={() => setCreateAdminModal(false)}
                            />
                            <Typography
                                variant="h6"
                                gutterBottom
                                className="deleteConfirmation-title"
                            >
                                Create a new admin user
                            </Typography>
                            {/* <div className="d-flex adminUserCard">
                {userData.map((e) => {
                  return (
                    <>
                    <div className="d-flex" >
                    <div className="d-flex">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedRows.includes(e.id)}
                                onChange={() => handleSelectRow(e.id)}
                              />
                            }
                          />
                        </FormGroup>
                      </div>
                      <div
                        className="mt-2"
                        style={{ marginLeft: "-17px", marginRight: "20px" }}
                      >
                        {e.first_name} {e.last_name}
                      </div>
                    </div>
                    
                    </>
                  );
                })}
              </div> */}
                            <div style={{ height: "285px", overflow: "auto" }}>
                                <Table>
                                    <TableHead>
                                        <TableCell colSpan={2}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                First Name
                                            </div>
                                        </TableCell>
                                        <TableCell>Last Name</TableCell>
                                        <TableCell>Department</TableCell>
                                        <TableCell>Role</TableCell>
                                    </TableHead>
                                    {deleteUserData.map((e) => (
                                        <TableBody>
                                            <TableRow className="deviceManagementTable-data">
                                                <TableCell>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={checked}
                                                                    onClick={() => handleCreateAdmin(e.id)}
                                                                />
                                                            }
                                                        />
                                                    </FormGroup>
                                                </TableCell>
                                                <TableCell>{e.first_name}</TableCell>
                                                <TableCell>{e.last_name}</TableCell>
                                                <TableCell>{e.department}</TableCell>
                                                <TableCell>{e.user_role}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))}
                                </Table>
                            </div>
                            <button
                                className={
                                    userId ? "adminNextButton" : "admindisableNextButton"
                                }
                                onClick={() => handleAdminDelete(userId)}
                            >
                                Next
                            </button>
                        </Card>
                    )}
                    {/* =============== Delete user Modal End =============== */}
                    {/* =============== User management table Start =============== */}
                    <div className="userManagementTable">
                        <Table
                            aria-label=""
                            className={`deviceManagementTable ${addUserModal ? "disabledRow" : ""
                                }`}
                        >
                            <TableHead>
                                <TableRow className="deviceManagementTable-title">
                                    <TableCell colSpan={2}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} />
                                            </FormGroup>{" "}
                                            First Name
                                        </div>
                                    </TableCell>
                                    <TableCell>Last Name</TableCell>
                                    <TableCell>Department</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Email Id</TableCell>
                                    <TableCell>Active</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} className="search-table-row">
                                        <SearchIcon className="search-icon" />
                                        <input
                                            type="search"
                                            placeholder="First Name"
                                            name="first_name"
                                            value={searchUserDataList.first_name}
                                            onChange={handleSearchUserData}
                                        />
                                    </TableCell>
                                    <TableCell className="search-table-row">
                                        <SearchIcon className="search-icon" />
                                        <input
                                            type="search"
                                            placeholder="Last Name"
                                            name="last_name"
                                            value={searchUserDataList.last_name}
                                            onChange={handleSearchUserData}
                                        />
                                    </TableCell>
                                    <TableCell className="search-table-row">
                                        <SearchIcon className="search-icon" />
                                        <input
                                            type="search"
                                            placeholder="Department"
                                            name="department__department_name"
                                            value={searchUserDataList.department__department_name}
                                            onChange={handleSearchUserData}
                                        />
                                    </TableCell>
                                    <TableCell className="search-table-row">
                                        <SearchIcon className="search-icon" />
                                        <input
                                            type="search"
                                            placeholder="Role"
                                            name="user_role__name"
                                            value={searchUserDataList.user_role__name}
                                            onChange={handleSearchUserData}
                                        />
                                    </TableCell>
                                    <TableCell className="search-table-row">
                                        <SearchIcon className="search-icon" />
                                        <input
                                            type="search"
                                            placeholder="Email Id"
                                            name="email"
                                            value={userData.email}
                                            onChange={handleSearchUserData}
                                        />
                                    </TableCell>
                                    <TableCell className="search-table-row"></TableCell>
                                    <TableCell className="search-table-row"></TableCell>
                                </TableRow>
                                {/* <TableRow className='deviceManagementTable-data'>
                                    <TableCell >
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox defaultChecked />} />
                                        </FormGroup>
                                    </TableCell>
                                    <TableCell>
                                        Parth
                                    </TableCell>
                                    <TableCell>
                                        Sarthi
                                    </TableCell>
                                    <TableCell>
                                        Instrument
                                    </TableCell>
                                    <TableCell>
                                        Engineer
                                    </TableCell>
                                    <TableCell>
                                        abc@gmail.com
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" className='deviceManagementTable-icon'>
                                            <Switch defaultChecked size="small" color='success' />
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" className='deviceManagementTable-icon'>
                                            <img src={images.editImg} alt="" className='cloudActiveImg' onClick={handleUserUpdate}/>
                                            <img src={images.deleteImg} alt="" className='cloudActiveImg'  onClick={handleConfirmationModal}/>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                                <TableRow className='deviceManagementTable-data'>
                                    <TableCell >
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox />} />
                                        </FormGroup>
                                    </TableCell>
                                    <TableCell>
                                        Shreekar
                                    </TableCell>
                                    <TableCell>
                                        Yadav
                                    </TableCell>
                                    <TableCell>
                                        Maintanance
                                    </TableCell>
                                    <TableCell>
                                        Admin
                                    </TableCell>
                                    <TableCell>
                                        abc@gmail.com
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" className='deviceManagementTable-icon'>
                                            <Switch defaultChecked size="small" color='success' />
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" className='deviceManagementTable-icon'>
                                            <img src={images.editImg} alt="" className='cloudActiveImg' onClick={handleUserUpdate} />
                                            <img src={images.deleteImg} alt="" className='cloudActiveImg' onClick={handleConfirmationModal} />
                                        </Stack>
                                    </TableCell>
                                </TableRow> */}
                                {userData.map((e) => (
                                    <TableRow className="deviceManagementTable-data">
                                        <TableCell>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={selectedRows.includes(e.id)}
                                                            onChange={() => handleSelectRow(e.id)}
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                        </TableCell>
                                        <TableCell>{e.first_name}</TableCell>
                                        <TableCell>{e.last_name}</TableCell>
                                        <TableCell>{e.department}</TableCell>
                                        <TableCell>{e.user_role}</TableCell>
                                        <TableCell>{e.email}</TableCell>
                                        <TableCell>
                                            <Stack
                                                direction="row"
                                                className="deviceManagementTable-icon"
                                            >
                                                <Switch
                                                    className="deviceManagement-switch"
                                                    checked={e.is_login}
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    height={20}
                                                    width={40}
                                                    onColor="#50C878"
                                                />
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            <Stack
                                                direction="row"
                                                className="deviceManagementTable-icon"
                                            >
                                                <img
                                                    src={images.editImg}
                                                    alt=""
                                                    className="cloudActiveImg"
                                                    onClick={() => handleUserUpdate(e)}
                                                    style={{ height: "28px", marginTop: "6px" }}
                                                />
                                                <img
                                                    src={images.deleteImg}
                                                    alt=""
                                                    className="cloudActiveImg"
                                                    style={{ height: "28px", marginTop: "6px" }}
                                                    onClick={() => handleConfirmationModal(e.id)}
                                                />
                                                <img src={images.viewIcon} alt="" className="cloudActiveImg" onClick={() => handleNavigateView(e.user_access?.id)}
                                                />
                                                {/* <button
                          className={e.is_admin ? "createAdmin" : "adminUser"}
                          onClick={() => handleCreateAdmin(e.id)}
                        >
                          Admin
                        </button> */}
                                                {/* <Checkbox  onClick={()=>handleCreateAdmin(e.id)} checked={e.is_admin}/> */}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableHead>
                            <TableBody></TableBody>
                        </Table>
                    </div>
                    {/* =============== User management table Ends =============== */}
                </Container>
                {isLoading && <Loader />}
            </div>
        </>
    );
};
export default CompanyByUserManagement;
