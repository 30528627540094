import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ShimmerText } from "react-shimmer-effects";
import {ParetoChartShimmer} from "../../components/Shimmer/ParetoChartShimmer/ParetoChartShimmer";

HC_more(Highcharts);
exporting(Highcharts);

export function VmsPOPBarChart({
  availabilityPOPBarData,
  availabilityPOP1BarData,
  vmsPOPMachineColor,
  shimmer
}) {
 

  // const chartRef = useRef(null);
  const param = useParams();

  const labels1 = Object.keys(availabilityPOPBarData).length
    ? Object.keys(availabilityPOPBarData).map((date) => date)
    : [];
  const labels2 = Object.keys(availabilityPOP1BarData).length
    ? Object.keys(availabilityPOP1BarData).map((date) => date)
    : [];

  const labels = [];

  if (labels1.length || labels2.length) {
    const popDataLength = labels1.length || 0;
    const pop1DataLength = labels2.length || 0;
    const maxLength = Math.max(popDataLength, pop1DataLength);

    for (let i = 0; i < maxLength; i++) {
      const popData = labels1[i] || "";
      const pop1Data = labels2[i] || "";
      const minKey = popData || "";
      const pop1MinKey = pop1Data || "";
      const labeledMinKey = `<span style="color: #C0255F;">${minKey}</span>`;
      const labeledPop1MinKey = `<span style="color: #E66595;">${pop1MinKey}</span>`;
      labels.push(`${labeledMinKey}    ${labeledPop1MinKey}`);
    }
  }

  // const labels = ["F1METER", "F2METER", "F3METER", "F4METER", "A1METER", "A2METER"]; // Add your labels here
  const findPlantDevice = vmsPOPMachineColor.length
    ? vmsPOPMachineColor.filter((f) => f.plantId == param.id)
    : [];

  console.log("findPlantDevicefindPlantDevicefindPlantDevice", findPlantDevice);

  const stackData1 = findPlantDevice.map((stack, index) => ({
    name: stack.dvName,
    data: Object.keys(availabilityPOPBarData).length
      ? Object.keys(availabilityPOPBarData).map((down, time) =>
          availabilityPOPBarData[down][index]
            ? availabilityPOPBarData[down][index]["down_time"]
            : 0
        )
      : [],
    color: "#D3DAE5",
    borderColor: "#C0255F",
    stack: `Stack 0`,
    barPercentage: 1,
    barThickness: 30,
  }));

  const stackData2 = findPlantDevice.map((stack, index) => ({
    name: stack.dvName,
    data: Object.keys(availabilityPOPBarData).length
      ? Object.keys(availabilityPOPBarData).map((down, time) =>
          availabilityPOPBarData[down][index]
            ? availabilityPOPBarData[down][index]["up_time"]
            : 0
        )
      : [],
    color: "#C0255F",
    borderColor: "#C0255F",
    stack: `Stack 0`,
    barPercentage: 1,
    barThickness: 30,
  }));

  const stackData3 = findPlantDevice.map((stack, index) => ({
    name: stack.dvName,
    data: Object.keys(availabilityPOP1BarData).length
      ? Object.keys(availabilityPOP1BarData).map((down, time) =>
          availabilityPOP1BarData[down][index]
            ? availabilityPOP1BarData[down][index]["down_time"]
            : 0
        )
      : [],
    color: "#D3DAE5",
    borderColor: "#E66595",
    stack: `Stack 1`,
    barPercentage: 1,
    barThickness: 30,
  }));

  const stackData4 = findPlantDevice.map((stack, index) => ({
    name: stack.dvName,
    data: Object.keys(availabilityPOP1BarData).length
      ? Object.keys(availabilityPOP1BarData).map((down, time) =>
          availabilityPOP1BarData[down][index]
            ? availabilityPOP1BarData[down][index]["up_time"]
            : 0
        )
      : [],

    color: "#E66595",
    borderColor: "#E66595",
    stack: `Stack 1`,
    barPercentage: 1,
    barThickness: 30,
  }));

  const options = {
    chart: {
      type: "column",
    },

    title: {
      text: null,
    },
    legend: {
      enabled: false, // Disable legend
    },
    xAxis: {
      categories: labels,
    },
    yAxis: {
      title: {
        text: "24 Hours",
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color: "gray",
        },
      },
      min: 0, // Set the minimum value for y-axis
      max: 24.5, // Set the maximum value for y-axis
      endOnTick: false, // Do not force the axis to end on a tick
      labels: {
        enabled: false, // Enable numeric labels on the y-axis
      },
      tickInterval: 4
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false, // Keep data labels enabled by default for all series
          // Additional data label configuration if needed
        },
      },
    },
    series: [...stackData1, ...stackData2, ...stackData3, ...stackData4],
    scrollbar: {
      enabled: true, // Enable scrollbar
    },
    exporting: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
  };

  // useEffect(() => {
  //   // const chart = chartRef.current?.chart;
  //   if (chart) {
  //     chart.xAxis[0].setExtremes(labels.length - 5, labels.length - 1, false);
  //     chart.redraw();
  //   }
  // }, []);

  return (
    <div>
      {!shimmer ? <HighchartsReact highcharts={Highcharts} options={options} /> : <ParetoChartShimmer className="ParetoChartShimmer-OverView"/>}
    </div>
  );
}


{/* <ShimmerText line={12} gap={15} /> */}