import React, { useMemo, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
// import { Textarea } from '@material-ui/core';
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Grid } from "@mui/material";
// import Typography from '@mui/material/Typography';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@mui/material/Switch";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { images } from "../../../config/images";
import {
  getPlantByCompany,
  getDeviceByCompany,
  updateCompanyActivation,
  getDeviceCloudStatusData,
} from "../service";
import "./ClientManagement.css";
import { useEffect } from "react";
import { useCubeQuery } from "@cubejs-client/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setHeaderDetails } from "../../Header/headerSlice";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

const ExpandCompany = ({
  row,
  handleOpenEditCompanyModal,
  handleConfirmationModal,
  handleOpenInviteModal,
  getcompanyData,
  openInviteUserModal,
  openAddcompany,
  openEditcompany,
  openDeleteModal,
  accessClientManagement,
  handleTostMsg,
}) => {
  const [value, setValue] = useState("1");
  const [Arrow, setArrow] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  // const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [plantData, setPlantData] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [activeCompany, setCompanyActive] = useState(true);
  const [cloudData, setCloudData] = useState([]);
  const [deviceLastSeenModal, setDeviceLastSeenModal] = useState({});
  const [cloudStatusData, setCloudStatusData] = useState([]);

  const cloudStatusDate = cloudStatusData
    ? dayjs(cloudStatusData.sub_time).format("DD/MM/YYYY")
    : "";

  const cloudStatusTime = cloudStatusData
    ? dayjs(cloudStatusData.sub_time).format("HH:mm:ss")
    : "";

  useEffect(() => {
    const fetchData = async () => {
      const resp = await getDeviceCloudStatusData();
      if (resp.data.success === true) {
        setCloudStatusData(resp.data.payload);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 60000);

    return () => clearInterval(interval);
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    if (openInviteUserModal) {
      return true;
    }
    setValue(newValue);
  };

  // // Delete Confirmation Modal
  // const handleConfirmationModal = () => {
  //     setOpenDeleteModal(!openDeleteModal);
  // }

  const notify = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  //Invite user modal
  const handleOpenUserInviteModal = () => {
    handleOpenInviteModal(row);
  };

  const handleClose = () => {

    setArrow(!Arrow);
  };

  const handleOpenUpdateModal = (event, id) => {
 
    setCompanyId(id);
    setUpdateData(true);
    event.stopPropagation();
  };

  useEffect(() => {
    setUpdateData(false);
  }, [openAddcompany]);

  const handleCloseUpdateModal = () => {
    setUpdateData(false);
  };

  const handleOpenEditModel = () => {
    handleOpenEditCompanyModal(row);
  };

  const handleOpenDeleteModel = () => {
    handleConfirmationModal(row.id);
  };

  const getPlantByCompanyApi = async () => {
    const param = {
      company: row.id,
    };
    const resp = await getPlantByCompany(param);
    if (resp.status == 200) {
      setPlantData(resp.data.payload);
    }
  };

  const getDeviceByCompanyApi = async () => {
    const param = {
      company: row.id,
    };
    const resp = await getDeviceByCompany(param);
    if (resp.status == 200) {
      setDeviceData(resp.data.payload[0].device);
    }
  };

  const updateCompanyActivationApi = async () => {
    if (
      openAddcompany ||
      openEditcompany ||
      openDeleteModal ||
      openInviteUserModal
    ) {
      return true;
    }
    const resp = await updateCompanyActivation(row.id);

    if (resp.status == 200 || resp.status == 201) {
      notify(resp.data.message);
      getcompanyData();
    }
  };

  useEffect(() => {
    // updateCompanyActivationApi
  }, []);

  useEffect(() => {
    if (Arrow) {
      getPlantByCompanyApi();
    }
  }, [Arrow]);

  useEffect(() => {
    if (Arrow) {
      getDeviceByCompanyApi();
    }
  }, [Arrow]);

  const istOptions = {
    timeZone: "Asia/Kolkata",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const timeOptions = {
    timeZone: "Asia/Kolkata",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };

  return (
    <>
      <div>{/*  */}</div>
      <Card
        className="cardCompany"
        style={{ marginTop: "20px", overflow: "visible" }}
        onClick={handleCloseUpdateModal}
      >
        <Grid container className="qqq">
          <Grid item xs={12} sm={12} md={3} lg={5} xl={4}>
            <Box className="companyDetails">
              <Box className="companyTitle">
                <Typography style={{ fontWeight: "500", fontSize: "18px" }}>
                  {row.company_name}
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ fontWeight: "00" }}
                >
                  {row.company_description}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={0} xl={2}>
            <Box className="expandCompanyBorder">
              <div className="invisible ">asjd</div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <Box className="clientManagement cardContent1">
              <Typography
                gutterBottom
                className="clientManagement - companyText "
              >
                Device:
              </Typography>
              <div className="clientManagement-discription">
                {/* <Typography variant="body1" className='clientManagement companyNumber1'>{cloudData.length ? cloudData.filter(value => value.isSub == true).length : "0"}</Typography> */}
                <Typography
                  variant="body1"
                  className="clientManagement companyNumber1"
                >
                  {row.active_device_count}
                </Typography>
                <Typography
                  variant="caption"
                  className="clientManagement-companySmalltext"
                >
                  Active
                </Typography>
              </div>
              <Box
                style={{ borderRight: "1px solid #C9CFE7" }}
                paddingRight={2}
              >
                <div className="clientManagement-discription">
                  {/* <Typography variant="body1" className='clientManagement companyNumber2'>{cloudData.length ? cloudData.filter(value => value.isSub == false).length : "0"}</Typography> */}
                  <Typography
                    variant="body1"
                    className="clientManagement companyNumber2"
                  >
                    {row.inactive_device_count}
                  </Typography>
                  <Typography
                    variant="caption"
                    className="clientManagement-companySmalltext"
                  >
                    Inactive
                  </Typography>
                </div>
              </Box>
              <Typography
                gutterBottom
                className="clientManagement companyText "
              >
                User:
              </Typography>
              <div className="clientManagement-discription">
                <Typography
                  variant="body1"
                  className="clientManagement companyNumber1"
                >
                  {row.active_user_count}
                </Typography>
                <Typography
                  variant="caption"
                  className="clientManagement-companySmalltext"
                >
                  Active
                </Typography>
              </div>
              <Box paddingRight={2}>
                <div className="clientManagement-disc">
                  <div className="clientManagement-discription">
                    <Typography
                      variant="body1"
                      className="clientManagement companyNumber2"
                    >
                      {row.inactive_user_count}
                    </Typography>
                    <Typography
                      variant="caption"
                      className="clientManagement-companySmalltext"
                    >
                      Inactive
                    </Typography>
                  </div>
                  <div>
                    <Switch
                      checked={row.is_active}
                      onClick={() => updateCompanyActivationApi()}
                    />
                  </div>
                  <div>
                    <MoreVertIcon
                      className="CoinManagment-detailsIcon"
                      onClick={(event) => handleOpenUpdateModal(event, row.id)}
                    />
                  </div>
                </div>
                <div className="clienManagementBorder"></div>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* =============== Update Modal Start =============== */}
        {updateData && (
          <Card className="clientManagement-updateModal top">
            <Box className="clientMangement-updatedataWrap">
              {(accessClientManagement?.is_viewer &&
                accessClientManagement?.is_editor) ||
              (!accessClientManagement?.is_viewer &&
                accessClientManagement?.is_editor) ? (
                <>
                  <div
                    className="clientManagement-updateData"
                    onClick={handleOpenEditModel}
                  >
                    <img
                      src={images.editImg}
                      alt=""
                      className="clientMangement-editIcon"
                    />
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      className="clientMangement-editText"
                    >
                      Edit
                    </Typography>
                  </div>
                  <div
                    className="clientManagement-deleteData"
                    onClick={handleOpenDeleteModel}
                  >
                    <img
                      src={images.deleteImg}
                      alt=""
                      className="clientMangement-editIcon"
                    />
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      className="clientMangement-editText"
                    >
                      Delete
                    </Typography>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="clientManagement-updateData"
                    onClick={handleTostMsg}
                  >
                    <img
                      src={images.editImg}
                      alt=""
                      className="clientMangement-editIcon"
                    />
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      className="clientMangement-editText"
                    >
                      Edit
                    </Typography>
                  </div>
                  <div
                    className="clientManagement-deleteData"
                    onClick={handleTostMsg}
                  >
                    <img
                      src={images.deleteImg}
                      alt=""
                      className="clientMangement-editIcon"
                    />
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      className="clientMangement-editText"
                    >
                      Delete
                    </Typography>
                  </div>
                </>
              )}
            </Box>
          </Card>
        )}
        {/* =============== Update Modal End =============== */}

        {Arrow && (
          <Box sx={{ width: "100%", typography: "body1" }}>
            <hr></hr>
            <TabContext value={value}>
              <Box>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{
                    bgcolor: "#f2f2f2",
                    border: "1px solid #1C407B",
                    borderRadius: "5px",
                    display: "inline-flex",
                    gap: "0px",
                    width: "fit-content",
                  }}
                >
                  <Tab
                    label="User and Access"
                    value="1"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#1C407B",
                        color: "#fff",
                        textTransform: "capitalize",
                        fontSize: "14px",
                        borderLeft: "1px solid #1C407B",
                      },
                      "&:not(.Mui-selected)": {
                        backgroundColor: "#fff",
                        color: "#001323",
                        textTransform: "capitalize",
                        fontSize: "14px",
                        // borderLeft:'1px solid red'
                      },
                    }}
                  />
                  <Tab
                    label="Plants"
                    value="2"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#1C407B",
                        color: "#fff",
                        textTransform: "capitalize",
                        fontSize: "14px",
                        borderLeft: "1px solid #1C407B",
                      },
                      "&:not(.Mui-selected)": {
                        backgroundColor: "#fff",
                        color: "#001323",
                        textTransform: "capitalize",
                        fontSize: "14px",
                        borderLeft: "1px solid #1C407B",
                        borderRight: "1px solid #1C407B",
                      },
                    }}
                  />
                  <Tab
                    label="Device"
                    value="3"
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#1C407B",
                        color: "#fff",
                        textTransform: "capitalize",
                        fontSize: "14px",
                      },
                      "&:not(.Mui-selected)": {
                        backgroundColor: "#fff",
                        color: "#001323",
                        textTransform: "capitalize",
                        fontSize: "14px",
                      },
                    }}
                  />
                </TabList>
              </Box>
              {/* =============== User and Access tab Start  =============== */}
              <TabPanel value="1" className="userAccessTab">
                {row.user ? (
                  <>
                    <Box className="userAccessTabDataList">
                      <Box>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className="userAccessTabDataName"
                        >
                          First Name :
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className="userAccessTabDataName"
                        >
                          Last Name :
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className="userAccessTabDataName"
                        >
                          Role :
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className="userAccessTabDataName"
                        >
                          Email Id :
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {row.user.first_name}
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {row.user.last_name}
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {row.user.user_role}
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {row.user.email}
                        </Typography>
                      </Box>
                    </Box>
                    {(accessClientManagement?.is_viewer &&
                      accessClientManagement?.is_editor) ||
                    (!accessClientManagement?.is_viewer &&
                      accessClientManagement?.is_editor) ? (
                      <button
                        className="userAccessTab-inviteTab"
                        onClick={() => {
                          dispatch(
                            setHeaderDetails({ headerName: row.company_name })
                          );
                          navigate(`/usermanagement/company/${row.id}`);
                        }}
                      >
                        Manage
                      </button>
                    ) : (
                      <button
                        className="userAccessTab-inviteTab"
                        onClick={handleTostMsg}
                      >
                        Manage
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      className="userAccsessTab-text"
                    >
                      All Set to Start Your Digital Factory Journey with "
                      {row.company_name}"
                    </Typography>
                    {(accessClientManagement?.is_viewer &&
                      accessClientManagement?.is_editor) ||
                    (!accessClientManagement?.is_viewer &&
                      accessClientManagement?.is_editor) ? (
                      <button
                        className="userAccessTab-inviteTab"
                        onClick={handleOpenUserInviteModal}
                      >
                        Invite
                      </button>
                    ) : (
                      <button
                        className="userAccessTab-inviteTab"
                        onClick={handleTostMsg}
                      >
                        Invite
                      </button>
                    )}
                  </>
                )}
              </TabPanel>
              {/* =============== User and Access tab End  =============== */}
              {/* =============== Plant tab Start  =============== */}
              <TabPanel value="2" className="plantTab">
                {plantData.length ? (
                  plantData.map((e, index) => {
                    return (
                      <Card className="clientManagement plantTabListCard">
                        <Box className="clientManagement plantTabListCardWrap">
                          <Box className="plantTabListWrap">
                            <Box className="clientManagement plantTabListImgWrap">
                              <img src={images.clientManagementPlantS} alt="" />
                              <Typography variant="subtitle2" gutterBottom>
                                Device
                              </Typography>
                            </Box>
                            <Box>
                              <Box className="clientManagement plantTabListTextWrap">
                                <Typography
                                  variant="subtitle1"
                                  gutterBottom
                                  className="clientManagement plantTabList plantName"
                                >
                                  {e.plant_name}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  display="block"
                                  gutterBottom
                                >
                                  {e.plant_description}
                                </Typography>
                              </Box>
                              <Box className="clientMangement plantTabList-ButtonWrap">
                                {plantData.length
                                  ? plantData[index].device.map((e) => {
                                      const findCloud = cloudStatusData.length
                                        ? cloudStatusData.find(
                                            (f) => f.id == e.id
                                          )
                                        : "";
                                      console.log(
                                        "findCloud111111111111",
                                        findCloud
                                      );
                                      return (
                                        <button
                                          className={`clientManagemet plantTablist-Button ${
                                            findCloud
                                              ? findCloud.is_sub
                                                ? ""
                                                : "inActive"
                                              : "inActive"
                                          }`}
                                        >
                                          {e.device_name}
                                        </button>
                                      );
                                    })
                                  : []}
                                {/* <button className='clientManagemet plantTablist-Button'>Device 1</button>
                                                            <button className='clientManagemet plantTablist-Button'>Device 2</button>
                                                            <button className='clientManagemet plantTablist-Button inActive'>Device 3</button>
                                                            <button className='clientManagemet plantTablist-Button'>Device 4</button> */}
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            {(accessClientManagement?.is_viewer &&
                              accessClientManagement?.is_editor) ||
                            (!accessClientManagement?.is_viewer &&
                              accessClientManagement?.is_editor) ? (
                              <button
                                className="clientMangement plantTabList-ManageButton"
                                onClick={() =>
                                  navigate(
                                    `/plantmanagement/company/${row.id}/plant/${e.id}`
                                  )
                                }
                              >
                                Manage
                              </button>
                            ) : (
                              <button
                                className="clientMangement plantTabList-ManageButton"
                                onClick={handleTostMsg}
                              >
                                Manage
                              </button>
                            )}
                          </Box>
                        </Box>
                      </Card>
                    );
                  })
                ) : (
                  <>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      className="plantTab-text"
                    >
                      Create Plants for the "{row.company_name}" <br></br> with
                      the Assigned Devices.
                    </Typography>
                    {(accessClientManagement?.is_viewer &&
                      accessClientManagement?.is_editor) ||
                    (!accessClientManagement?.is_viewer &&
                      accessClientManagement?.is_editor) ? (
                      <button
                        className="plantTab-btn"
                        onClick={() => navigate(`/plantmanagement`)}
                      >
                        Manage
                      </button>
                    ) : (
                      <button className="plantTab-btn" onClick={handleTostMsg}>
                        Manage
                      </button>
                    )}
                  </>
                )}
              </TabPanel>
              {/* =============== Plant tab End  =============== */}
              {/* =============== Device tab Start  =============== */}
              <TabPanel value="3" className="deviceTab">
                {deviceData.length ? (
                  deviceData.map((e, index) => {
                    const findCloud = cloudStatusData.length
                      ? cloudStatusData.find((f) => f.id == e.id)?.["is_sub"] ||
                        false
                      : false;
                    return (
                      <Card className="clientManagement deviceTabListCard">
                        <Box className="clientManagement deviceTabListCardWrap">
                          <Box className="deviceTabListWrap">
                            <Box className="clientManagement deviceTabListImgWrap">
                              {findCloud ? (
                                <img
                                  src={images.cloudActiveImg}
                                  alt=""
                                  className="cloudActiveImg"
                                />
                              ) : (
                                <img
                                  src={images.cloudImg}
                                  alt=""
                                  className="cloudActiveImg"
                                />
                              )}

                              <Typography
                                variant="subtitle2"
                                gutterBottom
                                className="clientManagement deviceTabListText"
                              >
                                Last Seen
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                gutterBottom
                                className="clientManagement deviceTabListText small"
                              >
                                {cloudStatusDate}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                gutterBottom
                                className="clientManagement deviceTabListText small"
                              >
                                {cloudStatusTime}
                              </Typography>
                            </Box>
                            <Box>
                              <Box className="clientManagement deviceTabListTextWrap">
                                <Typography
                                  variant="subtitle1"
                                  gutterBottom
                                  className="clientManagement deviceTabList deviceName"
                                >
                                  {e.device_name}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  display="block"
                                  gutterBottom
                                >
                                  UID - {e.uid}
                                </Typography>
                              </Box>
                              <Box className="clientMangement deviceTabList-ButtonWrap">
                                {deviceData.length
                                  ? deviceData[index].plants.map((e) => {
                                      return (
                                        <button className="clientManagemet deviceTablist-Button inActive">
                                          {e.plant_name}
                                        </button>
                                      );
                                    })
                                  : []}
                                {/* <button className='clientManagemet deviceTablist-Button inActive'>Plant 1</button>
                                                            <button className='clientManagemet deviceTablist-Button inActive'>Plant 2</button>
                                                            <button className='clientManagemet deviceTablist-Button inActive'>Plant 3</button> */}
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            {(accessClientManagement?.is_viewer &&
                              accessClientManagement?.is_editor) ||
                            (!accessClientManagement?.is_viewer &&
                              accessClientManagement?.is_editor) ? (
                              <button
                                className="clientMangement plantTabList-ManageButton"
                                onClick={() =>
                                  navigate(
                                    `/plantmanagement/company/${row.id}/plant/${e.plants[0].id}`
                                  )
                                }
                              >
                                Manage
                              </button>
                            ) : (
                              <button
                                className="clientMangement plantTabList-ManageButton"
                                onClick={handleTostMsg}
                              >
                                Manage
                              </button>
                            )}
                          </Box>
                        </Box>
                      </Card>
                    );
                  })
                ) : (
                  <>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      className="deviceTab-text"
                    >
                      Please assign Devices to "{row.company_name}"
                    </Typography>
                    {(accessClientManagement?.is_viewer &&
                      accessClientManagement?.is_editor) ||
                    (!accessClientManagement?.is_viewer &&
                      accessClientManagement?.is_editor) ? (
                      <button
                        className="deviceTab-btn"
                        onClick={() => navigate(`/plantmanagement`)}
                      >
                        Assign Device
                      </button>
                    ) : (
                      <button className="deviceTab-btn" onClick={handleTostMsg}>
                        Assign Device
                      </button>
                    )}
                  </>
                )}

                {/* <Card className='clientManagement deviceTabListCard' style={{ marginTop: '15px' }}>
                                    <Box className="clientManagement deviceTabListCardWrap">
                                        <Box className="deviceTabListWrap">
                                            <Box className="clientManagement deviceTabListImgWrap">
                                                <img src={images.cloudImg} alt="" style={{ height: '40px', width: '40px' }} />
                                                <Typography variant="subtitle2" gutterBottom className="clientManagement deviceTabListText">
                                                    Last Seen
                                                </Typography>
                                                <Typography variant="subtitle2" gutterBottom className="clientManagement deviceTabListText small">
                                                    31/01/2023
                                                </Typography>
                                                <Typography variant="subtitle2" gutterBottom className="clientManagement deviceTabListText small">
                                                    09:30
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Box className="clientManagement deviceTabListTextWrap">
                                                    <Typography variant="subtitle1" gutterBottom className='clientManagement deviceTabList deviceName'>
                                                        Device 2
                                                    </Typography>
                                                    <Typography variant="caption" display="block" gutterBottom>
                                                        UID - 71f73dee-4596-48c8-bdof-7f1234d
                                                    </Typography>
                                                </Box>
                                                <Box className="clientMangement deviceTabList-ButtonWrap">
                                                    <button className='clientManagemet deviceTablist-Button inActive'>Plant 1</button>
                                                    <button className='clientManagemet deviceTablist-Button inActive'>Plant 2</button>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <button className="clientMangement plantTabList-ManageButton">
                                                Manage
                                            </button>
                                        </Box>
                                    </Box>
                                </Card> */}
              </TabPanel>
              {/* =============== Device tab End  =============== */}
            </TabContext>
          </Box>
        )}
        {Arrow == true ? (
          <img
            src={images.upArrow}
            alt=""
            className="clientMangement-downIcon"
            onClick={() => handleClose()}
          />
        ) : (
          <img
            src={images.downIcon}
            alt=""
            className="clientMangement-downIcon"
            onClick={() => handleClose()}
          />
        )}
      </Card>
    </>
  );
};

export default ExpandCompany;
