import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userName: "",
        selectTimeZoneProfile :""
}

const userLoginSlice = createSlice({
    name: "userLogin",
    initialState,
    reducers: {
        setUserLogin(state, { payload }) {
            return { ...state, ...payload };
        },
        clearUserlogin(state) {
            return initialState;
        }
    },
});

export const {
    setUserLogin,
    clearUserlogin
} = userLoginSlice.actions;

export default userLoginSlice.reducer;


