import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    configureModel : false,
    vmsAvailabilityCOCstartDate : null,
    vmsAvailabilityCOCendDate : null,
    vmsAvailabilityPOPstartDate : null,
    vmsAvailabilityPOPendDate : null,
    vmsAvailabilityPOP1startDate : null,
    vmsAvailabilityPOP1endDate : null,
    vmsProductionCOCstartDate : null,
    vmsProductionCOCendDate : null,
    vmsProductionPOPstartDate : null,
    vmsProductionPOPendDate : null,
    vmsProductionPOP1startDate : null,
    vmsProductionPOP1endDate : null,
    vmsProductionTime : false,
    vmsProductionPOPTime : false,
    vmsAvailabilityCOCDataId :[],
    vmsMachineColor : [],
    vmsAvailabilityPOPDataId : [],
    vmsPOPMachineColor : [],


}

const vmsSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setVmsData(state, { payload }) {
            return { ...state, ...payload };
        },
        clearVmsData(state) {
            return initialState;
        }
    },
});

export const {
    setVmsData,
    clearVmsData
} = vmsSlice.actions;

export default vmsSlice.reducer;


