import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./DeviceManagement.css";
import { useSelector } from "react-redux";
import { RedirectAdminDashboard } from "../../../components/CommonFunction/RedirectAdminDashboard";
import {
  Card,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { images } from "../../../config/images";
import {
  addGateway,
  deviceCount,
  deviceExcel,
  gatewayCloudStatus,
  getGateway,
  getGatewayId,
  filetrAPIDeviceManagement,
} from "../service";
import {
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import GatewayTable from "./GatewayTable";
import Textshimmer from "../../../components/Shimmer/TextShimmer/TextShimmer";
import GatewayTableShimmer from "./GatewayTableShimmer";

function DeviceManagement() {
  const [addGatewayModal, setAddGatewayModal] = React.useState(false);
  const [filterModel, setFilterModel] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [countAlarmData, setCountAlarmData] = useState(1);
  const [getGatewayData, setGetGatewayData] = useState([]);
  const [cloudStatusData, setCloudStatusData] = useState([]);
  const [gatewayId, setGatewayId] = useState("");
  const [dataCount, setDataCount] = useState({
    HikemmActivatedCount: 0,
    HikemmUnassignedCount: 0,
    VmsActivatedCount: 0,
    VmsUnassignedCount: 0,
    smartTagActivatedCount: 0,
    smartTagUnassignedCount: 0,
  });
  const [filterSelectValue, setFilterSelectValue] = useState("none");
  const [filterValue, setFilterValue] = useState("none");
  const [filter, setFilter] = useState(false);

  const [isShimmerDataCount, setisShimmerDataCount] = useState(false);
  const intialData = {
    group_name: "",
    group_description: "",
  };
  const [addGatewayData, setAddGatewayData] = useState(intialData);
  const initiallValue = {
    group_name: "",
    group_description: "",
  };
  const [searchFilters, setSearchFilters] = useState({
    gateway_name: "",
    gateway_description: "",
    company_name: "",
    license_status: "",
  });
  const { companyId, user_access, is_super_admin } = useSelector(
    (state) => state.userState
  );

  const [isShimmergatewaytable, setisShimmergatewaytable] = useState(false);
  const navigate = useNavigate();



  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  const handleOpenAddGatewayModal = () => {
    setAddGatewayModal(!addGatewayModal);
    setAddGatewayData(initiallValue);
  };

  const handleCloseGatewayModal = () => {
    setAddGatewayModal(!addGatewayModal);
  };

  const handleChangeAddGateway = (e) => {
    setAddGatewayData({ ...addGatewayData, [e.target.name]: e.target.value });
  };

  const handleAddGateway = async () => {
    if (addGatewayData.group_name === "") {
      notifyError("Please Enter Gateway Name.");
      return;
    }
    if (addGatewayData.group_description === "") {
      notifyError("Please Enter Gateway Description.");
      return;
    }
    const params = {
      gateway_name: addGatewayData.group_name,
      gateway_description: addGatewayData.group_description,
      company: companyId,
    };
    try {
      const resp = await addGateway(params);
      if (resp.status == 200 || resp.status == 201) {
        setAddGatewayModal(false);
        handlegetGateway();
        getTopCount();
        notifySuccess("Gateway added successfully");
      }
    } catch (error) {
      notifyError(error.response.data.message);
      console.log("handlesubmit", error);
    }
  };
  const handleClickExcel = async () => {
    const params = {
      id: gatewayId,
    };
    try {
      const resp = await deviceExcel(params);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message);
      }
    } catch (error) {
      notifyError(error.response.data.message);
    }
  };

  useEffect(() => {
    handlegetAllGateway();
  }, []);

  const handlegetAllGateway = async () => {
    try {
      const resp = await getGatewayId();
      if (resp.status == 200 || resp.status == 201) {
        setGatewayId(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    if (filter) {
      handlefiletrAPIDeviceManagement();
    } else {
      handlegetGateway();
    }
  }, [page, rowsPerPage, filter]);

  const handlegetGateway = async () => {
    setisShimmergatewaytable(true);
    try {
      const resp = await getGateway(page, rowsPerPage);
      if (resp.status == 200 || resp.status == 201) {
        setTimeout(() => {
          setisShimmergatewaytable(false);
        }, 1000);
        setGetGatewayData(resp.data.payload.results);
        setCountAlarmData(resp.data.payload.count);
      }
    } catch (error) {
      setGetGatewayData([]);
      setisShimmergatewaytable(false);
      setCountAlarmData((prev) => prev - 1);
      setPage((prev) => prev - 1);
      console.log("handlesubmit", error);
    }
  };

  const handlefiletrAPIDeviceManagement = async () => {
    setisShimmergatewaytable(true);
    const reqParam = {
      filter_field: filterSelectValue,
      filter_data: filterValue,
    };
    try {
      const resp = await filetrAPIDeviceManagement(reqParam, page, rowsPerPage);
      if (resp.status == 200 || resp.status == 201) {
        setTimeout(() => {
          setisShimmergatewaytable(false);
        }, 1000);
        setGetGatewayData(resp.data.payload.results);
        setCountAlarmData(resp.data.payload.count);
      }
    } catch (error) {
      setGetGatewayData([]);
      setisShimmergatewaytable(false);
      setCountAlarmData((prev) => prev - 1);
      console.log("handlesubmit", error);
    }
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const filteredData = getGatewayData.filter((row) => {
    return (
      row.gateway_name
        .toLowerCase()
        .includes(searchFilters.gateway_name.toLowerCase()) &&
      row.gateway_description
        .toLowerCase()
        .includes(searchFilters.gateway_description.toLowerCase()) &&
      row.company_name
        .toLowerCase()
        .includes(searchFilters.company_name.toLowerCase()) &&
      row.license_status
        .toLowerCase()
        .includes(searchFilters.license_status.toLowerCase())
    );
  });

  useEffect(() => {
    const fetchData = async () => {
      const resp = await gatewayCloudStatus();
      if (resp.data.success === true) {
        setCloudStatusData(resp.data.payload);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getTopCount();
  }, []);

  const getTopCount = async () => {
    try {
      setisShimmerDataCount(true);
      const resp = await deviceCount();
      if (resp.status == 200 || resp.status == 201) {
        setTimeout(() => {
          setisShimmerDataCount(false);
        }, 1000);
        setDataCount({
          HikemmActivatedCount: resp.data.payload.Hikemm.find(
            (f) => f.status == "Activated"
          )?.count,
          HikemmUnassignedCount: resp.data.payload.Hikemm.find(
            (f) => f.status == "Unassigned"
          )?.count,
          VmsActivatedCount: resp.data.payload.VMS.find(
            (f) => f.status == "Activated"
          )?.count,
          VmsUnassignedCount: resp.data.payload.VMS.find(
            (f) => f.status == "Unassigned"
          )?.count,
          smartTagActivatedCount: resp.data.payload["Smart Tag"].find(
            (f) => f.status == "Activated"
          )?.count,
          smartTagUnassignedCount: resp.data.payload["Smart Tag"].find(
            (f) => f.status == "Unassigned"
          )?.count,
        });
      }
    } catch (error) {
      setisShimmerDataCount(false);
      console.log("Error from getTopCount", error);
    }
  };

  const superAdminAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Admin Dashboard"
      )
    : null;
  const accessManagementDevice = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Device Management"
        )
      : null
    : null;

  const handleTostMsg = () => {
    notifyError("You don't have access.");
  };

  const handleFilterModel = () => {
    if (filter) {
      setFilter(false);
      setFilterSelectValue("none");
      setFilterValue("none");
      return true;
    }
    setFilterModel((prev) => !prev);
  };

  const handelFilterSubmit = () => {
    if (filterSelectValue == "none" || filterValue == "none") {
      notifyError("please select a filter name");
      return true;
    }
    setFilter(true);
    setFilterModel((prev) => !prev);
  };

  const handleChangeFilter = (value) => {
    setFilterSelectValue(value);
    setFilterValue("none");
  };



  const handlePageChange = (e, newPage) =>{
    console.log("newPagenewPage",newPage);
  }

  const handleRowPageChange = (e, newPage) =>{
    console.log("newRowPage",e);
  }

  return (
    <>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                onClick={() => {
                  RedirectAdminDashboard(is_super_admin, navigate);
                }}
                className="backArrow"
              />{" "}
              <Typography
                variant="h5"
                gutterBottom
                className="deviceManagementTitle"
                onClick={() => {
                  RedirectAdminDashboard(is_super_admin, navigate);
                }}
              >
                Device Management
              </Typography>
            </div>
            <div>
              <Box className="device-box" style={{ marginRight: "20px" }}>
                <Grid container style={{ color: "#1C407B" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ borderRight: "1px solid #81818129" }}
                  >
                    <div
                      className="d-flex hikemm-device-box"
                      style={{ gap: "27px" }}
                    >
                      <h5 className="mt-3">HikEMM:</h5>
                      <div>
                        <h6>Available</h6>
                        <h6>
                          {!isShimmerDataCount ? (
                            dataCount ? (
                              dataCount.HikemmUnassignedCount
                            ) : (
                              0
                            )
                          ) : (
                            <Textshimmer height="16px" width="90%" />
                          )}{" "}
                        </h6>
                      </div>
                      <div>
                        <h6>Used</h6>
                        <h6>
                          {!isShimmerDataCount ? (
                            dataCount ? (
                              dataCount.HikemmActivatedCount
                            ) : (
                              0
                            )
                          ) : (
                            <Textshimmer height="16px" width="90%" />
                          )}
                        </h6>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ borderRight: "1px solid #81818129" }}
                  >
                    <div
                      className="d-flex hikemm-device-box"
                      style={{ gap: "27px" }}
                    >
                      <h5 className="mt-3" style={{ marginLeft: "20px" }}>
                        PredHik :
                      </h5>
                      <div>
                        <h6>Available</h6>
                        <h6>
                          {!isShimmerDataCount ? (
                            dataCount ? (
                              dataCount.VmsUnassignedCount
                            ) : (
                              0
                            )
                          ) : (
                            <Textshimmer height="16px" width="90%" />
                          )}
                        </h6>
                      </div>
                      <div>
                        <h6>Used</h6>
                        <h6>
                          {!isShimmerDataCount ? (
                            dataCount ? (
                              dataCount.VmsActivatedCount
                            ) : (
                              0
                            )
                          ) : (
                            <Textshimmer height="16px" width="90%" />
                          )}
                        </h6>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div
                      className="d-flex hikemm-device-box"
                      style={{ gap: "27px" }}
                    >
                      <h5 className="mt-3" style={{ marginLeft: "20px" }}>
                        SmartTag :
                      </h5>
                      <div>
                        <h6>Available</h6>
                        <h6>
                          {!isShimmerDataCount ? (
                            dataCount ? (
                              dataCount.smartTagUnassignedCount
                            ) : (
                              0
                            )
                          ) : (
                            <Textshimmer height="16px" width="90%" />
                          )}
                        </h6>
                      </div>
                      <div>
                        <h6>Used</h6>
                        <h6>
                          {!isShimmerDataCount ? (
                            dataCount ? (
                              dataCount.smartTagActivatedCount
                            ) : (
                              0
                            )
                          ) : (
                            <Textshimmer height="16px" width="90%" />
                          )}
                        </h6>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div className="addDevice-buttonWrap">
              {(accessManagementDevice?.is_viewer &&
                accessManagementDevice?.is_editor) ||
              (!accessManagementDevice?.is_viewer &&
                accessManagementDevice?.is_editor) ? (
                <>
                  <button
                    className={filter ? "removeFilter-button" : "filter-button"}
                    onClick={() => handleFilterModel()}
                  >
                    {filter ? "Remove Filter" : "Filter"}
                  </button>
                  <button
                    className="addDevice-button"
                    onClick={handleOpenAddGatewayModal}
                  >
                    Add Gateway
                  </button>
                  <div>
                    <img
                      src={images.excelLogo}
                      alt=""
                      className="cloudActiveImg"
                      onClick={handleClickExcel}
                    />
                  </div>
                </>
              ) : (
                <>
                  <button className="addDevice-button" onClick={handleTostMsg}>
                    Add Gateway
                  </button>
                  <div>
                    <img
                      src={images.excelLogo}
                      alt=""
                      className="cloudActiveImg"
                      onClick={handleTostMsg}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="pt-4">
            <Table aria-label="" className="deviceManagementTable">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>Gateway Name</TableCell>
                  <TableCell>Gateway Description</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>License</TableCell>
                  <TableCell width="12%">Action</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Gateway Name"
                      name="gateway_name"
                      value={searchFilters.gateway_name}
                      onChange={handleSearchChange}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Gateway Description"
                      name="gateway_description"
                      value={searchFilters.gateway_description}
                      onChange={handleSearchChange}
                    />
                  </TableCell>

                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Company"
                      name="company_name"
                      value={searchFilters.company_name}
                      onChange={handleSearchChange}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="License status"
                      name="license_status"
                      value={searchFilters.license_status}
                      onChange={handleSearchChange}
                    />
                  </TableCell>
                  <TableCell className="search-table-row"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isShimmergatewaytable ? (
                  filteredData.length ? (
                    filteredData.map((row) => (
                      <GatewayTable
                        key={row.id} // Make sure to provide a unique key
                        row={row}
                        handlegetGateway={handlegetGateway}
                        addGatewayModal={addGatewayModal}
                        cloudStatusData={
                          cloudStatusData.length
                            ? cloudStatusData.find((f) => f.id == row.id)?.[
                                "is_sub"
                              ] || false
                            : false
                        }
                        cloudStatusData1={
                          cloudStatusData.length
                            ? cloudStatusData.find((f) => f.id == row.id)
                            : ""
                        }
                        getTopCount={getTopCount}
                        dataCount={dataCount}
                        accessManagementDevice={accessManagementDevice}
                        handleTostMsg={handleTostMsg}
                        cloudData={ cloudStatusData.length
                          ? cloudStatusData.find((f) => f.id == row.id)?.[
                              "is_warning"
                            ] || false
                          : false}
                      />
                    ))
                  ) : (
                    <TableRow className="gateWayShimmer-table">
                      <TableCell
                        style={{
                          backgroundColor: "white",
                          textAlign: "center",
                        }}
                        colSpan={6}
                      >
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <GatewayTableShimmer itemCount={7} />
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={countAlarmData}
              page={page}
              onPageChange={(e, newPage) => setPage(newPage)}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(parseInt(e.target.value, 10));
                setPage(0);
              }}
              // className="devicemanagement-pagination"
            />
          </div>

          {addGatewayModal && (
            <Card className="addDeviceModal-card">
              <Box className="addDeviceModal-titleWrap">
                <Typography
                  variant="h5"
                  gutterBottom
                  className="addDeviceModal-title"
                >
                  ADD GATEWAY
                </Typography>
                <img
                  src={images.closeIcon}
                  alt=""
                  style={{
                    width: "30px",
                    cursor: "pointer",
                    backgroundColor: "white",
                  }}
                  className="closeImg"
                  onClick={() => handleCloseGatewayModal()}
                />
              </Box>

              <Box className="addDeviceModal-innerWrap">
                <Stack spacing={2} pt="10px" className="pt-4">
                  <TextField
                    id="filled-basic"
                    name="group_name"
                    label="Gateway Name"
                    variant="filled"
                    className="customeTextField deviceManagement"
                    onChange={handleChangeAddGateway}
                    value={addGatewayData.group_name}
                  />
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className="mt-1"
                  >
                    e.g. Furnace 01
                  </Typography>
                  <TextField
                    id="filled-basic"
                    name="group_description"
                    label="Gateway Description"
                    variant="filled"
                    className="customeTextField deviceManagement"
                    onChange={handleChangeAddGateway}
                    value={addGatewayData.group_description}
                  />
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className="addDeviceModal-text"
                  >
                    e.g. Furnace 01 of Plant 1
                  </Typography>
                </Stack>
                <Box className="accountSecurity-cardButtons deviceManagement">
                  <button className="addDeviceBtn" onClick={handleAddGateway}>
                    ADD GATEWAY TO HIKAR.CLOUD
                  </button>
                </Box>
              </Box>
            </Card>
          )}
          {filterModel && (
            <Card className="filterModal-card">
              <Box className="addDeviceModal-titleWrap">
                <Typography
                  variant="h5"
                  gutterBottom
                  className="addDeviceModal-title"
                >
                  Filter
                </Typography>
                <img
                  src={images.closeIcon}
                  alt=""
                  style={{
                    width: "30px",
                    cursor: "pointer",
                    backgroundColor: "white",
                  }}
                  className="closeImg"
                  onClick={() => setFilterModel(false)}
                />
              </Box>

              <Box className="addDeviceModal-innerWrap">
                <Stack spacing={2} pt="10px" className="pt-4">
                  <Select
                    sx={{ m: 1, minWidth: 80, backgroundColor: "#E6E9F4" }}
                    id="demo-simple-select-autowidth"
                    autoWidth
                    label="r"
                    value={filterSelectValue}
                    onChange={(e) => handleChangeFilter(e.target.value)}
                  >
                    <MenuItem selected value="none">
                      Select Filter
                    </MenuItem>
                    <MenuItem value="Machine Name">Machine Name</MenuItem>
                    <MenuItem value="Machine Description">
                      Machine Description
                    </MenuItem>
                    <MenuItem value="Machine UID">Machine UID</MenuItem>
                    <MenuItem value="Device Name">Device Name</MenuItem>
                    <MenuItem value="Device Description">
                      Device Description
                    </MenuItem>
                    <MenuItem value="Device Type">Device Type</MenuItem>
                    <MenuItem value="Product">Product</MenuItem>
                  </Select>

                  {filterSelectValue == "Product" && (
                    <Select
                      sx={{ m: 1, minWidth: 80, backgroundColor: "#E6E9F4" }}
                      id="demo-simple-select-autowidth"
                      autoWidth
                      label="r"
                      onChange={(e) => setFilterValue(e.target.value)}
                      value={filterValue}
                    >
                      <MenuItem selected value="none">
                        Select Product
                      </MenuItem>
                      <MenuItem value="HikEMM">HikEMM</MenuItem>
                      <MenuItem value="PredHik">PredHik</MenuItem>
                      <MenuItem value="SmarTag">SmarTag</MenuItem>
                    </Select>
                  )}

                  {filterSelectValue == "Device Type" && (
                    <Select
                      sx={{ m: 1, minWidth: 80, backgroundColor: "#E6E9F4" }}
                      id="demo-simple-select-autowidth"
                      autoWidth
                      label="r"
                      onChange={(e) => setFilterValue(e.target.value)}
                      value={filterValue}
                    >
                      <MenuItem selected value="none">
                        Select Device Type
                      </MenuItem>
                      <MenuItem value="Consumption">Consumption</MenuItem>
                      <MenuItem value="Source">Source</MenuItem>
                    </Select>
                  )}

                  {filterSelectValue != "Device Type" &&
                    filterSelectValue != "Product" && (
                      <>
                        <TextField
                          id="filled-basic"
                          name="group_description"
                          label="search"
                          variant="filled"
                          className="customeTextField deviceManagement"
                          onChange={(e) => setFilterValue(e.target.value)}
                          value={filterValue == "none" ? "" : filterValue}
                        />
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className="addDeviceModal-text"
                        >
                          e.g. Furnace 01 of Plant 1
                        </Typography>
                      </>
                    )}
                </Stack>
                <Box className="accountSecurity-cardButtons deviceManagement">
                  <button
                    className="addDeviceBtn"
                    onClick={() => handelFilterSubmit()}
                  >
                    Submit
                  </button>
                </Box>
              </Box>
            </Card>
          )}
        </Container>
      </div>
    </>
  );
}

export default DeviceManagement;