import React, { useState } from "react";
import Container from "@mui/material/Container";
import { redirect, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { images } from "../../../config/images";
import Checkbox from "@mui/material/Checkbox";
import "./AlarmManagemet.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePlant,
  editPlant,
  getPlantByCompany,
  getcompanyListPlantManegement,
} from "../service";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { addGroup, deleteGroup, getGroup, updateGroup } from "./services";
import ExpandGroup from "./ExpandGroup";
import { setCompanyDetails, setPlantDetails } from "../Slice";
import Loader from "../../../components/Loader/Loader";
import { ValidatorForm } from "react-material-ui-form-validator";
import { RedirectAdminDashboard } from "../../../components/CommonFunction/RedirectAdminDashboard";
import { ShimmerText } from "react-shimmer-effects";
import { SelectPlantShimmer } from "../../../components/Shimmer/SelectPlantShimmer/SelectPlantShimmer";
import ErrorIcon from "@mui/icons-material/Error";
import { ShimmerAddGroup } from "../../../components/Shimmer/ShimmerAlert/ShimmerAddGroup";

const AlarmManagement = () => {
  const [openAddgroup, setOpenAddgroup] = useState(false);
  const [oepnUsersmodal, setOpenUsersmodal] = useState(false);
  const [Arrow, setArrow] = useState(false);
  // const [selectCompany, setSelectCompany] = React.useState("");
  const [companyList, setCompanyList] = useState([]);
  const [plantList, setPlantList] = useState([]);
  // const [plantId, setPlantId] = useState("");
  const [addGroupData, setAddGroupData] = useState([]);
  const [getGroupData, setGetGroupData] = useState([]);
  const [openEditGroup, setOpenEditGroup] = useState(false);
  const [editGroupData, setEditGroupData] = useState({
    group_name: "",
    group_description: "",
  });
  const [deleteGroupModal, setDeleteGroupModal] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [groupName, setGroupName] = useState({
    group_name: "",
    group_description: "",
  });

  const [userDetailList, setUserDetailList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isShimmerGroupData, setisShimmerGroupData] = useState(false);
  const [isShimmerPlantList, setisShimmerPlantList] = useState(false);

  const dispatch = useDispatch();

  const {
    companyId,
    companyName,
    companyDescription,
    user_access,
    is_super_admin,
    is_created_by_super_admin_user_company,
  } = useSelector((state) => state.userState);

  const { selectCompany, selectPlant } = useSelector(
    (state) => state.companyDetailsState
  );

  const handleChangeCompany = (e) => {
    if (openAddgroup || oepnUsersmodal || openEditGroup || deleteGroupModal) {
      return true;
    }
    dispatch(
      setCompanyDetails({ selectCompany: e.target.value, selectPlant: "" })
    );
  };

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const navigate = useNavigate();

  //Add Group modal
  const handleOpenaddGroupModal = () => {
    if (openAddgroup || oepnUsersmodal || openEditGroup || deleteGroupModal) {
      return true;
    }

    setOpenAddgroup(true);
    setDeleteGroupModal(false);
    setOpenEditGroup(false);
  };

  const handleCancleOpenModal = () => {
    setOpenAddgroup(false);
  };
  const handleClose = () => {
    if (openAddgroup || oepnUsersmodal || openEditGroup || deleteGroupModal) {
      return true;
    }

    setArrow(!Arrow);
  };

  const handleEditClick = (e) => {
    if (openAddgroup || oepnUsersmodal || openEditGroup || deleteGroupModal) {
      return true;
    }

    setOpenEditGroup(true);
    setOpenAddgroup(false);
    setDeleteGroupModal(false);
    setEditGroupData({
      group_name: e.group_name,
      group_description: e.group_description,
    });
    setGroupId(e.id);
  };

  const handleDeleteClick = (e) => {
    if (openAddgroup || oepnUsersmodal || openEditGroup || deleteGroupModal) {
      return true;
    }

    setDeleteGroupModal(true);
    setOpenEditGroup(false);
    setOpenAddgroup(false);
    setGroupId(e.id);
  };

  const handleEditCancleModal = () => {
    setOpenEditGroup(false);
  };
  //Users modal
  const handleOpenUsersModal = (user, id) => {
    if (openAddgroup || oepnUsersmodal || openEditGroup || deleteGroupModal) {
      return true;
    }

    if (user.length) {
      setOpenUsersmodal(!oepnUsersmodal);
    }
    setUserDetailList(user);
    setGroupId(id);
  };

  useEffect(() => {}, [userDetailList]);

  const handleUpdateGroup = async () => {
    if (editGroupData.group_name === "") {
      notify("Please Enter Group Name");
      return;
    }
    if (editGroupData.group_description === "") {
      notify("Please Enter Group Description");
      return;
    }
    const params = {
      group_name: editGroupData.group_name,
      group_description: editGroupData.group_description,
    };
    try {
      setLoading(true);
      const resp = await updateGroup(params, groupId);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message);
        handleGetGroupData();
        setLoading(false);
        // getPlatListByComapnyAPI();
        setOpenEditGroup(false);
      }
    } catch (error) {
      setLoading(false);
      notify(error.response.data.message);
    }
  };

  useEffect(() => {
    getcompanyListPlantManegementApi();
  }, []);

  const getcompanyListPlantManegementApi = async () => {
    setisShimmerPlantList(true);

    try {
      const resp = await getcompanyListPlantManegement();
      if (resp.status == 200) {
        setTimeout(() => {
          setisShimmerPlantList(false);
        }, 1000);
        if (!selectCompany) {
          //temp change by ut
          dispatch(setCompanyDetails({ selectCompany: companyId }));
        }
        setCompanyList(resp.data.payload);
      }
    } catch (error) {
      setisShimmerPlantList(false);
      setLoading(false);
      if (!selectCompany) {
        //temp change by ut
        dispatch(setCompanyDetails({ selectCompany: companyId }));
      }
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    if (selectCompany) {
      getPlatListByComapnyAPIChnageCountry();
    }
  }, [selectCompany]);

  const getPlatListByComapnyAPIChnageCountry = async () => {
    setisShimmerPlantList(true);
    const param = {
      company: selectCompany,
    };
    try {
      // setLoading(true)
      const resp = await getPlantByCompany(param);
      if (
        resp.status == 200 ||
        (resp.status == 201 && resp.data.payload.length)
      ) {
        setLoading(false);
        setTimeout(() => {
          setisShimmerPlantList(false);
        }, 1000);
        setPlantList(resp.data.payload);
        if (!selectPlant) {
          dispatch(setPlantDetails({ selectPlant: resp.data.payload[0].id }));
        }
      } else {
        setPlantList([]);
        setGetGroupData([]);
      }
    } catch (error) {
      setisShimmerPlantList(false);
      setLoading(false);
      setGetGroupData([]);
      console.log("handleSubmit", error);
    }
  };
  const handleTabValue = (e) => {
    console.log(e.target.id);
  };

  const handleConfirmationgroupModal = () => {
    setDeleteGroupModal(!deleteGroupModal);
  };

  // const handleChangePlantData = (e) => {
  //   setEditPlantData({ ...editPlantData, [e.target.name]: e.target.value });
  // };
  const handleEditGroupData = (e) => {
    setEditGroupData({ ...editGroupData, [e.target.name]: e.target.value });
  };

  // const getPlatListByComapnyAPI = async () => {
  //   const param = {
  //     company: selectCompany,
  //   };
  //   const resp = await getPlantByCompany(param);
  //   // console.log("kgkfdg", resp);
  //   if (resp.status == 200) {
  //     setPlantList(resp.data.payload);

  //     if (plantId) {
  //       setPlantId(plantId);
  //     } else {
  //       setPlantId(resp.data.payload[0].id);
  //     }
  //   }
  // };

  // const handleUpdatePlant = async () => {
  //   if(!editPlantData.plant_name){
  //     notify("Please Enter Plant name")
  //     return;
  //   }
  //   if(!editPlantData.plant_description){
  //     notify("Please Enter Plant description")
  //     return;
  //   }
  //   const params = {
  //     id: plantId,
  //     plant_name: editPlantData.plant_name,
  //     plant_description: editPlantData.plant_description,
  //   };
  //   try {
  //     setLoading(true)
  //     const response = await editPlant(plantId, params);
  //     if (response.status == 200 || response.status == 201) {
  //       setLoading(false)
  //       getPlatListByComapnyAPI();
  //       setOpenEditplant(false);
  //     }
  //   } catch (error) {
  //     notify(error.response.data.message);
  //   }
  // };

  // const handleDeletePlant = async () => {
  //   const response = await deletePlant(plantId);
  //   if (response.status == 200) {
  //     // getcompanyListPlantManegementApi();
  //     getPlatListByComapnyAPI();
  //     setOpenDeleteModal(false);
  //   }
  // };

  const handleDeleteGroup = async () => {
    const resp = await deleteGroup(groupId);
    if (resp.status == 200 || resp.status == 201) {
      notifySuccess(resp.data.message);
      setDeleteGroupModal(false);
      handleGetGroupData();
    }
  };

  const handleSubmitaddGroupModal = async () => {
    if (groupName.group_name === "") {
      notify("Please Enter Group Name");
      return;
    }
    if (groupName.group_description === "") {
      notify("Please Enter Group Description");
      return;
    }

    const params = {
      company: selectCompany,
      plant: selectPlant,
      group_name: groupName.group_name,
      group_description: groupName.group_description,
    };
    try {
      setLoading(true);
      const response = await addGroup(params);
      if (response.data.success == true) {
        notifySuccess(response.data.message);
        handleGetGroupData();
        setLoading(false);
        setOpenAddgroup(false);
        // setAddGroupData(response.data.payload);
        // setGroupId(response.data.payload[0].id);
        setGroupName({ group_name: "", group_description: "" });
      }
    } catch (error) {
      setLoading(false);
      notify(error.response.data.message);
      console.log("handlesubmit", error);
    }
  };

  const handleChangeAlarmDetails = (e) => {
    setGroupName({ ...groupName, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (selectPlant) {
      handleGetGroupData();
    }
  }, [selectCompany, selectPlant]);

  const handleGetGroupData = async () => {
    setisShimmerGroupData(true);
    const params = {
      company: selectCompany,
      plant: selectPlant,
    };
    try {
      const response = await getGroup(params);
      if (response.data.success == true) {
        setTimeout(() => {
          setisShimmerGroupData(false);
        }, 1000);
        setGetGroupData(response.data.payload);
        setGroupId(response.data.payload[0].id);
      }
    } catch (error) {
      setisShimmerGroupData(false);
      // notify(error.response.data.message);
      console.log("Error", error);
    }
  };

  const superAdminAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Admin Dashboard"
      )
    : null;

  const accessManagementUserAlarm = superAdminAccess
    ? superAdminAccess.sub_module.length
      ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "Alarm Management"
        )
      : null
    : null;

  const handleTostMsg = () => {
    notify("You don't have access");
  };
  return (
    <>
      {/*  */}
      {/* {/ <div>AlarmManagement</div> /} */}
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                onClick={() => {
                  RedirectAdminDashboard(is_super_admin, navigate);
                }}
                className="backArrow"
              />{" "}
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  margin: "0",
                  paddingLeft: "10px",
                  color: "#222D39",
                  fontSize: "16px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={() => {
                  RedirectAdminDashboard(is_super_admin, navigate);
                }}
              >
                Alarm Management
              </Typography>
            </div>
          </div>
          {/* =============== Add Group Modal Start =============== */}
          <ValidatorForm onSubmit={handleSubmitaddGroupModal}>
            {openAddgroup && (
              <Card className="addPlant-cardModal">
                <Box className="addPlant-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addPlant-title"
                  >
                    Add Group
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={handleCancleOpenModal}
                  />
                </Box>
                <Box className="addplantMdal-innerWrap">
                  <Stack spacing={2} pt="10px">
                    <TextField
                      id="filled-basic"
                      name="group_name"
                      label="Group Name"
                      variant="filled"
                      className="addplantInputfield"
                      InputProps={{}}
                      onChange={handleChangeAlarmDetails}
                    />
                    <TextField
                      id="filled-basic"
                      name="group_description"
                      label="Description"
                      variant="filled"
                      className="addplantInputfield"
                      InputProps={{}}
                      onChange={handleChangeAlarmDetails}
                    />
                  </Stack>
                  <Box className="addPlant-cardButtons">
                    <button
                      className="addPlantBtn"
                      // onClick={handleSubmitaddGroupModal}
                    >
                      Submit
                    </button>
                  </Box>
                </Box>
              </Card>
            )}
          </ValidatorForm>
          {/* =============== Add Group Modal End =============== */}
          {/* =============== User Modal Start =============== */}
          {oepnUsersmodal && (
            <Card className="alarmManagement userCardModal">
              <Box className="addPlant-titleWrap">
                <Typography
                  variant="h5"
                  gutterBottom
                  className="addPlant-title"
                >
                  Users
                </Typography>
                <img
                  src={images.closeIcon}
                  alt=""
                  style={{
                    width: "30px",
                    cursor: "pointer",
                    backgroundColor: "white",
                  }}
                  className="closeImg"
                  onClick={() => setOpenUsersmodal(false)}
                />
              </Box>

              <Box className="alarmMangemnt userCardInnerWrap">
                <img
                  src={images.settingImage}
                  alt=""
                  className="alarmManagement settingIcon"
                  onClick={() =>
                    navigate(
                      `/alarmmanagement/configuration/${"setting"}/company/${selectCompany}/plant/${selectPlant}/group/${groupId}`
                    )
                  }
                />

                <div className="userDetailsWrap">
                  {userDetailList.length
                    ? userDetailList.map((row) => {
                        return (
                          <>
                            <div
                              className="d-flex"
                              style={{ alignItems: "center" }}
                            >
                              <Checkbox
                                style={{ color: "#1C407B" }}
                                defaultChecked={true}
                              />
                              <Typography
                                variant="body2"
                                gutterBottom
                                className="userDetails name"
                              >
                                {row?.user?.first_name} {row?.user?.last_name}
                              </Typography>
                            </div>
                          </>
                        );
                      })
                    : []}
                </div>
              </Box>
            </Card>
          )}

          {/* {openEditplant && (
            <Card className="addPlant-cardModal">
              <Box className="addPlant-titleWrap">
                <Typography
                  variant="h5"
                  gutterBottom
                  className="addPlant-title"
                >
                  Edit Plant
                </Typography>
                <img
                  src={images.closeIcon}
                  alt=""
                  style={{
                    width: "30px",
                    cursor: "pointer",
                    backgroundColor: "white",
                  }}
                  className="closeImg"
                  onClick={handleEditModal}
                />
              </Box>
              <Box className="addplantMdal-innerWrap">
                <Stack spacing={2} pt="10px">
                  <TextField
                    id="filled-basic"
                    name="plant_name"
                    label="Plant Name"
                    variant="filled"
                    className="addplantInputfield"
                    value={editPlantData.plant_name}
                    onChange={handleChangePlantData}
                    InputProps={{}}
                  />
                  <TextField
                    id="filled-basic"
                    name="plant_description"
                    label="Plant Description"
                    variant="filled"
                    className="addplantInputfield"
                    value={editPlantData.plant_description}
                    onChange={handleChangePlantData}
                    InputProps={{}}
                  />
        
                </Stack>
                <Box className="addPlant-cardButtons">
                  <button className="addPlantBtn" onClick={handleUpdatePlant}>
                    Update
                  </button>
                </Box>
              </Box>
            </Card>
          )} */}

          {/* {openDeleteModal && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure<br></br> You Want To Delete?
              </Typography>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={handleDeletePlant}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleConfirmationModal}
                >
                  No
                </button>
              </Box>
            </Card>
          )} */}
          <ValidatorForm onSubmit={handleUpdateGroup}>
            {openEditGroup && (
              <Card className="addPlant-cardModal">
                <Box className="addPlant-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addPlant-title"
                  >
                    Edit group
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={handleEditCancleModal}
                  />
                </Box>
                <Box className="addplantMdal-innerWrap">
                  <Stack spacing={2} pt="10px">
                    <TextField
                      id="filled-basic"
                      name="group_name"
                      label="group name"
                      variant="filled"
                      className="addplantInputfield"
                      value={editGroupData.group_name}
                      onChange={(e) => handleEditGroupData(e)}
                      InputProps={{}}
                    />
                    <TextField
                      id="filled-basic"
                      name="group_description"
                      label="group Description"
                      variant="filled"
                      className="addplantInputfield"
                      value={editGroupData.group_description}
                      onChange={(e) => handleEditGroupData(e)}
                      InputProps={{}}
                    />
                  </Stack>
                  <Box className="addPlant-cardButtons">
                    <button className="addPlantBtn">Update</button>
                  </Box>
                </Box>
              </Card>
            )}
          </ValidatorForm>

          {deleteGroupModal && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure<br></br> You Want To Delete?
              </Typography>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={handleDeleteGroup}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleConfirmationgroupModal}
                >
                  No
                </button>
              </Box>
            </Card>
          )}

          {/* =============== User Modal End =============== */}
          <Grid container style={{ paddingTop: "20px" }}>
            <Grid item xs={12}>
              <Card className="manageDepartment-card">
                <Grid
                  container
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <Grid item sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Select Company</b>
                      {(accessManagementUserAlarm?.is_viewer &&
                        accessManagementUserAlarm?.is_editor) ||
                      (!accessManagementUserAlarm?.is_viewer &&
                        accessManagementUserAlarm?.is_editor) ? (
                        <FormControl className="selectCompany-dropDown">
                          <Select
                            value={selectCompany}
                            onChange={handleChangeCompany}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value={companyId}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span> {companyName}</span>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  style={{ marginBottom: "0px" }}
                                >
                                  {companyDescription}
                                </Typography>
                              </div>
                            </MenuItem>
                            {is_super_admin ||
                            (is_created_by_super_admin_user_company &&
                              companyList.length)
                              ? companyList.map((e) => {
                                  return (
                                    <MenuItem value={e.id} key={e.id}>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <span>{e.company_name}</span>
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          style={{ marginBottom: "0px" }}
                                        >
                                          {e.company_description}
                                        </Typography>
                                      </div>
                                    </MenuItem>
                                  );
                                })
                              : []}
                          </Select>
                        </FormControl>
                      ) : (
                        <FormControl className="selectCompany-dropDown">
                          <Select
                            value={selectCompany}
                            onChange={handleTostMsg}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value={companyId}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span> {companyName}</span>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  style={{ marginBottom: "0px" }}
                                >
                                  {companyDescription}
                                </Typography>
                              </div>
                            </MenuItem>
                            {is_super_admin ||
                            (is_created_by_super_admin_user_company &&
                              companyList.length)
                              ? companyList.map((e) => {
                                  return (
                                    <MenuItem value={e.id} key={e.id}>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <span>{e.company_name}</span>
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          style={{ marginBottom: "0px" }}
                                        >
                                          {e.company_description}
                                        </Typography>
                                      </div>
                                    </MenuItem>
                                  );
                                })
                              : []}
                          </Select>
                        </FormControl>
                      )}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Select Plant</b>
                    </Typography>
                    <div
                      className="manageDepartment-wrap"
                      style={{ height: "538px", overflow: "auto" }}
                    >
                      {!isShimmerPlantList ? (
                        plantList.length ? (
                          plantList.map((e, i) => {
                            return (
                              <div>
                                {(accessManagementUserAlarm?.is_viewer &&
                                  accessManagementUserAlarm?.is_editor) ||
                                (!accessManagementUserAlarm?.is_viewer &&
                                  accessManagementUserAlarm?.is_editor) ? (
                                  <div
                                    className="plantmanagementDepartment-fieldWrap"
                                    onClick={() => {
                                      if (
                                        openAddgroup ||
                                        oepnUsersmodal ||
                                        openEditGroup ||
                                        deleteGroupModal
                                      ) {
                                        return true;
                                      }
                                      dispatch(
                                        setPlantDetails({ selectPlant: e.id })
                                      );
                                    }}
                                  >
                                    {selectPlant == e.id && (
                                      <img
                                        src={images.activeDeviceimg}
                                        alt=""
                                        className="activeDeviceimg"
                                      />
                                    )}
                                    <div
                                      className="manageDepartment-field"
                                      id="1"
                                      onClick={(e) => handleTabValue(e)}
                                    >
                                      <Typography
                                        variant="body2"
                                        gutterBottom
                                        style={{
                                          margin: "0",
                                          color: "#001323",
                                        }}
                                        id="1"
                                        onClick={(e) => handleTabValue(e)}
                                      >
                                        <b
                                          id="1"
                                          onClick={(e) => handleTabValue(e)}
                                        >
                                          {e.plant_name}
                                        </b>
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          id="1"
                                          onClick={(e) => handleTabValue(e)}
                                        >
                                          {e.plant_description
                                            ? e.plant_description
                                            : "-"}
                                        </Typography>
                                      </Typography>
                                      {/* <div className="manageDepartment-fieldIcon">
                                    <img
                                      src={images.editImg}
                                      alt=""
                                      style={{
                                        width: "25x",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleEditModal(e)}
                                    />
                                    <img
                                      src={images.deleteImg}
                                      alt=""
                                      style={{
                                        width: "25px",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleConfirmationModal}
                                    />
                                  </div> */}
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="plantmanagementDepartment-fieldWrap"
                                    onClick={handleTostMsg}
                                  >
                                    {selectPlant == e.id && (
                                      <img
                                        src={images.activeDeviceimg}
                                        alt=""
                                        className="activeDeviceimg"
                                      />
                                    )}
                                    <div
                                      className="manageDepartment-field"
                                      id="1"
                                      onClick={(e) => handleTabValue(e)}
                                    >
                                      <Typography
                                        variant="body2"
                                        gutterBottom
                                        style={{
                                          margin: "0",
                                          color: "#001323",
                                        }}
                                        id="1"
                                        onClick={(e) => handleTabValue(e)}
                                      >
                                        <b
                                          id="1"
                                          onClick={(e) => handleTabValue(e)}
                                        >
                                          {e.plant_name}
                                        </b>
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          id="1"
                                          onClick={(e) => handleTabValue(e)}
                                        >
                                          {e.plant_description
                                            ? e.plant_description
                                            : "-"}
                                        </Typography>
                                      </Typography>
                                      {/* <div className="manageDepartment-fieldIcon">
                                  <img
                                    src={images.editImg}
                                    alt=""
                                    style={{
                                      width: "25x",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleEditModal(e)}
                                  />
                                  <img
                                    src={images.deleteImg}
                                    alt=""
                                    style={{
                                      width: "25px",
                                      cursor: "pointer",
                                    }}
                                    onClick={handleConfirmationModal}
                                  />
                                </div> */}
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <div className="plant-error-div">
                            <ErrorIcon className="plant-error-icon" />
                            <h5 className="mt-2">Plant not found.</h5>
                          </div>
                        )
                      ) : (
                        <>
                          {[...Array(5)].map((_, index) => (
                            <SelectPlantShimmer />
                          ))}
                        </>
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={12} md={8} lg={8} xl={8}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Alert Groups</b>
                    </Typography>
                    <div className="plantmanagementDepartment-card">
                      <Box className="plantmanagementDepartment-addDevicewrap">
                        {(accessManagementUserAlarm?.is_viewer &&
                          accessManagementUserAlarm?.is_editor) ||
                        (!accessManagementUserAlarm?.is_viewer &&
                          accessManagementUserAlarm?.is_editor) ? (
                          <div
                            className="plantmanagementDepartment-assignDeviceTextWrap"
                            onClick={handleOpenaddGroupModal}
                          >
                            <Typography
                              variant="caption"
                              display="lock"
                              gutterBottom
                              className="plantmanagemetDepartment-assignDeviceText"
                            >
                              Add Group
                            </Typography>
                            <img
                              src={images.addIcon}
                              alt=""
                              className="addDeviceImg"
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        ) : (
                          <div
                            className="plantmanagementDepartment-assignDeviceTextWrap"
                            onClick={handleTostMsg}
                          >
                            <Typography
                              variant="caption"
                              display="lock"
                              gutterBottom
                              className="plantmanagemetDepartment-assignDeviceText"
                            >
                              Add Group
                            </Typography>
                            <img
                              src={images.addIcon}
                              alt=""
                              className="addDeviceImg"
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        )}
                      </Box>
                      {!isShimmerGroupData ? (
                        getGroupData.length ? (
                          getGroupData.map((e) => {
                            return (
                              <>
                                <ExpandGroup
                                  handleOpenaddGroupModal={
                                    handleOpenaddGroupModal
                                  }
                                  handleEditClick={handleEditClick}
                                  Arrow={Arrow}
                                  handleOpenUsersModal={handleOpenUsersModal}
                                  handleClose={handleClose}
                                  handleDeleteClick={handleDeleteClick}
                                  e={e}
                                  plantId={selectPlant}
                                  groupId={groupId}
                                  openAddgroup={openAddgroup}
                                  oepnUsersmodal={oepnUsersmodal}
                                  openEditGroup={openEditGroup}
                                  deleteGroupModal={deleteGroupModal}
                                  selectCompany={selectCompany}
                                  access={accessManagementUserAlarm}
                                  handleTostMsg={handleTostMsg}
                                />
                              </>
                            );
                          })
                        ) : (
                          <div className="configured-error-div">
                            <ErrorIcon className="configured-error-icon" />
                            <h5 className="mt-2">No group found.</h5>
                          </div>
                        )
                      ) : (
                        <>
                          {[...Array(4)].map((_, index) => (
                            <ShimmerAddGroup />
                          ))}
                        </>
                      )}
                    </div>
                    {/* <button className="Plantmanagemet submit">Submit</button> */}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid></Grid>
          </Grid>
        </Container>
        {isLoading && <Loader />}
      </div>
    </>
  );
};

export default AlarmManagement;
