import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";

HC_more(Highcharts);
exporting(Highcharts);

const BarGraph = ({ BarGraphData1 }) => {
  console.log("BarGraphData1BarGraphData1", BarGraphData1);
  const categories = [];

  if (BarGraphData1 || BarGraphData1.length) {
    const popDataLength = BarGraphData1
      ? BarGraphData1[0]?.data?.length || 0
      : 0;

    const maxLength = Math.max(popDataLength);

    for (let i = 0; i < maxLength; i++) {
      const popData = BarGraphData1 ? BarGraphData1[0]?.data[i] || {} : {};

      const minKey = popData?.MIN_KEY || "";

      const labeledMinKey = `<span style="color: black;">${minKey}</span>`;

      categories.push(`${labeledMinKey}`);
    }
  }

  const data = [
    {
      name: "High Count",
      data: BarGraphData1
        ? BarGraphData1.length
          ? BarGraphData1[0] && BarGraphData1[0].data.length
            ? BarGraphData1[0].data.map((row) => parseFloat(row.high_count))
            : []
          : []
        : [],
      color: "#E53439",
      stack: "Stack 0",
      barPercentage: 1,
      barThickness: 30,
    },
    {
      name: "Low Count",
      data: BarGraphData1
        ? BarGraphData1.length
          ? BarGraphData1[0] && BarGraphData1[0].data.length
            ? BarGraphData1[0].data.map((row) => parseFloat(row.low_count))
            : []
          : []
        : [],
      color: "#FFB732",
      stack: "Stack 1",
      barPercentage: 1,
      barThickness: 30,
    },
  ];

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color: "gray",
        },
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
      series: {
        stacking: "normal",
        borderRadius: "5px",
      },
    },

    series: data,
    scrollbar: {
      enabled: true, // Enable scrollbar
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default BarGraph;

{
  /* <ShimmerText line={12} gap={15}/> */
}
