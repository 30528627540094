import AWS from 'aws-sdk';
import { ErrorReport } from './errorReport';

AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const ses = new AWS.SES({ apiVersion: 'latest' });

export async function sendEmail({ to, from, subject, message }) {
    
    const params = {
        Source: from,
        Destination: { ToAddresses: to },
        Message: {
            Subject: { Data: subject },
            Body: {
                Html: {
                    Data: ErrorReport(message),
                },
            },
        },
    };

    try {
        const result = await ses.sendEmail(params).promise();
        console.log('Email sent:', result.MessageId);
        return result.MessageId;
    } catch (error) {
        console.error('Error sending email:', error);
        throw error;
    }
}
