import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { PieChartShimmer } from "../../components/Shimmer/PieChartShimmer/PieChartShimmer";
import { images } from "../../config";
import { useParams } from "react-router-dom";

export function VmsPieChartDownTime({
  AvailabilityCOCPieChartData,
  vmsMachineColor,
  shimmer,
}) {
  const param = useParams();

  const deviceDownTimes = AvailabilityCOCPieChartData?.deviceData?.map(row => row.down_time) || [];
  const deviceNames = AvailabilityCOCPieChartData?.deviceData?.map(row => row.device__device_name) || [];
  
  // Finding device colors based on plantId
  const findPlantDevice = vmsMachineColor.filter(f => f.plantId == param.id) || [];
  const deviceColorMap = new Map(findPlantDevice.map((row) => [row.dvName, row.color]));

  // Prepare data with absolute values for chart display, but keep original values
  const dataForChart = AvailabilityCOCPieChartData?.deviceData?.map((row) => ({
    name: row.device__device_name,
    y: Math.abs(row.down_time),  // Use absolute value for display
    color: deviceColorMap.get(row.device__device_name),
    originalY: row.down_time,    // Keep original value for tooltip
  })) || [];

  const options = {
    chart: {
      type: "pie",
      height: 230,
      width: 230,
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        const total = this.series.data.reduce(
          (acc, point) => acc + Math.abs(point.originalY),  // Calculate total using absolute values
          0
        );
        const percentage = ((Math.abs(this.point.originalY) / total) * 100).toFixed(2) + "%";
        return `<div style ="font-size : 12px">
                  <b>${this.point.name}</b><br/>
                  <span style="color:${this.point.color}">\u25A0</span> ${this.point.originalY} (${percentage})
                </div>`;
      },
    },
    plotOptions: {
      pie: {
        innerSize: "70%",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Down Time",
        data: dataForChart,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  // Check if all downtime values are zero
  const allZeros = deviceDownTimes.every(time => time === 0);

  return (
    <div style={{ width: "100%", height: "195px" }} className="doughnut-chart1">
      {!shimmer ? (
        <>
          {!allZeros ? (
            <HighchartsReact highcharts={Highcharts} options={options} />
          ) : (
            <img src={images.noDataFoundImg} className="notFoundDataImg not-found" alt="" />
          )}
        </>
      ) : (
        <PieChartShimmer size="195px" position="relative" />
      )}

      <p className="doughnut-chartText" style={{ top: "46%", left: "75%" }}>
        {AvailabilityCOCPieChartData?.deviceTotal?.total_down_time}
      </p>
      <p className="doughnut-chartText-unit" style={{ top: "51%", left: "77%" }}>
        {AvailabilityCOCPieChartData?.deviceTotal?.total_down_time ? "hr" : ""}
      </p>
    </div>
  );
}
