import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  makeStyles,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { images } from "../../../../config/images";
import "../VmsMachine/VmsMachine.css";
import { TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDeviceSourceAPI,
  getDeviceType,
} from "../../../ProcessDashboard/Source/services";
import { useEffect } from "react";
import "../../VmsCompanyDashboard/VmsMachine/VmsMachine.css";
import VmsARMachine from "./VmsARMachine/VmsARMachine";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  countForVmsPlant,
  plantWiseTitleCount,
  getUnityModel,
  getNotification,
  getDeviceTagByType,
} from "./service";
import { getDeviceByType, getDeviceDashboard } from "./Reports/service";
import { getConsumptionDeviceData } from "../../../ProcessDashboard/Consumption/service";
import { getDeviceByDashboard } from "../../../SmartTag/services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Mqqtdata from "../../../ProcessDashboard/Mqqtdata";
import { timeStampCheckStatus } from "../../../../components/TimeStampCheckStatus/timeStampCheckStatus";


const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const VmsMachine = () => {
  const [notificationModal, setNotificationModal] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [plantDashboardCount, setPlantDashboardCount] = useState([]);
  const [deviceUID, setDeviceUID] = useState("");
  const [modelFound, setModelFound] = useState(false);
  const [modelUrlData, setModelUrlData] = useState(null);
  const [keyvalue, seykeyvalue] = useState(0);
  const [navigateRoute, setNavigateRoute] = useState("");
  const [notification, setNotification] = useState([]);
  const [machineId, setMachineId] = useState("");
  const [machine, setMachine] = useState({
    device_name: "",
    device_description: "",
    device: "",
  });
  const [topics, setTopics] = useState("");

  //mqtt data state
  const [mqttTagData, setMqttTagData] = useState([]);
  const [tagListData, setTagListData] = useState([]);
  const [sendRealTimeDataModel, setSendRealTimeDataModel] = useState([]);
  const [isNotified, setIsNotified] = useState(false);

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const { user_access } = useSelector((state) => state.userState);
  const filteredDeviceData = deviceData.length
    ? deviceData.filter((device) => {
      const deviceName = device.device__device_name || ""; // Use an empty string if deviceName is null or undefined
      const query = searchQuery || ""; // Use an empty string if searchQuery is null or undefined

      return deviceName.toLowerCase().includes(query.toLowerCase());
    })
    : [];

  const param = useParams();
  const handleNotification = (e) => {
    setNotificationModal(true);
    setMachine({
      device_name: e.device__device_name,
      device_description: e.device__device_description,
      device: e.device__machine__id,
    });
    setDeviceId(e.device);
  };

  const navigate = useNavigate();

  // navigatemachine DashBoard
  const handleMachineDashboardnavigate = () => {
    if (
      (plantAccess?.is_viewer && plantAccess?.is_editor) ||
      (!plantAccess?.is_viewer && plantAccess?.is_editor) ||
      (plantAccess?.is_viewer && !plantAccess?.is_editor)
    ) {
      if (modelFound) {
        navigate(
          `/submachine/company/${param.companyId}/plant/${param.id}/device/${deviceId}/machine/${machine.device}`
        );
        return true;
      }
    }

    if (plantAccess?.is_viewer == false && plantAccess?.is_editor == false) {
      notifyError("You don't have access");
      return true;
    }
    setNavigateRoute(
      `/submachine/company/${param.companyId}/plant/${param.id}/device/${deviceId}/machine/${machine.device}`
    );
  };

  useEffect(() => {
    getDeviceData();
  }, []);

  const getDeviceData = async () => {
    try {
      const params = {
        dashboard: 2,
        plant: Number(param.id),
      };
      const resp = await getDeviceByDashboard(params);
      if (resp.status == 200 || resp.status == 201) {
        setDeviceData(resp.data.payload.device);
        setTagData(resp.data.payload.tag_unit);
      }
    } catch (error) {
      console.log("Error from GetSmartTagMachine", error);
    }
  };
  const getNotificationIconColor = (priority) => {
    switch (priority) {
      case "High":
        return "#e31e24";
      case "Medium":
        return "#ffa500";
      case "Low":
        return "#ebeb27";
      default:
        return "gray";
    }
  };

  useEffect(() => {
    handleAlamNotification();
  }, []);

  const handleAlamNotification = async () => {
    try {
      const params = {
        dashboard: 2,
        plant: Number(param.id),
      };
      const resp = await getNotification(params);
      if (resp.status == 200 || resp.status == 201) {
        setNotification(resp.data.payload);
      }
    } catch (error) {
      console.log("Error from GetSmartTagMachine", error);
    }
  };
  useEffect(() => {
    handlePlantDashboardCount();
  }, []);

  const handlePlantDashboardCount = async () => {
    const params = {
      plant: param.id,
    };

    try {
      const resp = await plantWiseTitleCount(params);
      if (resp.data.success == true) {
        setPlantDashboardCount(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmitError", error);
    }
  };


  useEffect(() => {
    if (deviceId) {
      getDeviceId(deviceId);
      getDeviceTagListData(deviceId);
    }
  }, [deviceId]);

  const getDeviceId = async () => {
    const params = {
      device: deviceId,
    };

    try {
      const resp = await getConsumptionDeviceData(params);
      if (resp.status === 200) {
        setDeviceUID(resp.data.payload.machine_uid.replace(/\//g, "_"));
        seykeyvalue((prev) => prev + 1);
        setTopics(["Hikar/DeviceUID/" + resp.data.payload.machine_uid]);
      }
    } catch (error) {
      console.error("An error occurred while fetching device data:", error);
      // Handle the error appropriately, e.g., show an error message to the user.
    }
  };

  const getDeviceTagListData = async () => {
    const params = {
      device_id: deviceId,
      plant_id: param.id,
      company_id: param.companyId,
    };
    try {
      const resp = await getDeviceTagByType(params);
      if (resp.status === 200 && resp.data.payload.length) {
        console.log("respresprespresprespresprespresp", resp);
        setTagListData(resp.data.payload);
      }
    } catch (error) {
      console.log("error", error);
      // Handle the error appropriately, e.g., show an error message to the user.
    }
  };

  // const getDeviceTagListData = async () => {
  //   const params = {
  //     device_id: deviceId,
  //     plant_id: param.id,
  //     company_id: param.companyId,
  //   };
  //   try {
  //     const resp = await getDeviceTagByType(params);
  //     if (resp.status === 200 && resp.data.payload.length) {
  //       console.log("respresprespresprespresprespresp", resp);
  //       setTagListData(resp.data.payload);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //     // Handle the error appropriately, e.g., show an error message to the user.
  //   }
  // };




  // mqtt send data
  useEffect(() => {
    if (tagListData.length) {
      const mergedArray = [].concat(...Object.values(mqttTagData));
      if (mergedArray.length) {
        console.log("tagListData", tagListData, mergedArray);

        const filteredArray = mergedArray.map((obj) => {
          const device = obj.device__uid;
          const deviceTag = obj.device_tag;

          const tagFind = tagListData.find((item) => item.device__uid == device && item.device_tag == deviceTag)
          return {
            tag_name: tagFind ? tagFind.tag_name : "",
            // tagListData.find(
            //   (item) =>
            //     item.device__uid == device && item.device_tag == deviceTag
            // )?.tag_name || "",
            mqtt_tag_name: deviceTag,
            tag_value: tagFind?.signal_type ? ["AI", "AO"].includes(tagFind.signal_type) ? obj.tagValue : obj.tagValue == "1" ? tagFind.high_range : tagFind.low_range : obj.tagValue,
           alarm: tagFind?.setpoint_hi && tagFind?.setpoint_lo && tagFind?.setpoint_hh && tagFind?.setpoint_ll? tagFind?.setpoint_hi < obj.tagValue || tagFind?.setpoint_lo > obj.tagValue || tagFind?.setpoint_hh < obj.tagValue || tagFind?.setpoint_ll >  obj.tagValue ? "true" : "false" : "false"
          };
        });
        setSendRealTimeDataModel(filteredArray.filter((f) => f.tag_name != ""));
      }

    }
  }, [tagListData, mqttTagData])

  const convertTimeStampFormat = mqttTagData ? Object.keys(mqttTagData).map((row) =>{
    const findTimeStamp = mqttTagData[row].find((f) => f.device_tag == "TIMESTAMP");
    return ({
      ...findTimeStamp,
      TIMESTAMP: findTimeStamp?.TIMESTAMP || "",
    });
  }) : []
  useEffect(() => {
    const getData = timeStampCheckStatus(convertTimeStampFormat);

    if (!isNotified && Array.isArray(getData) && getData.length > 0) {
      const { device_tag, tagDateAndTimeFormat, tagValue } = getData[0];
      const notifyError = (message) =>
        toast.error(message, {
          theme: 'colored',
          position: toast.POSITION.TOP_RIGHT,
          toastClassName: 'custom-toast',
        });

        if (device_tag && tagDateAndTimeFormat && tagValue) {
      
          notifyError(
            <div>
              <p>Device Tag: {device_tag}</p>
              <p>Current Time: {tagDateAndTimeFormat}</p>
              <p>Mqtt Time: {tagValue}</p>
            </div>
          );
          setIsNotified(true); 
        }
    }
  }, [mqttTagData, isNotified]); 


  const PlantDashboardAccess = user_access.length
    ? user_access.find(
      (user) => user.access_module?.access_module_name === "Plant Dashboard"
    )
    : null;
  const plantAccess = PlantDashboardAccess
    ? PlantDashboardAccess.sub_module.length
      ? PlantDashboardAccess.sub_module.find(
        (f) => f.sub_module_name == "Plant Process Dashboard"
      )
      : null
    : null;

  return (
    <>
      {topics && <Mqqtdata topics={topics} setMqttTagData={setMqttTagData} />}
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <Card className="dashboardCard">
            <Grid container>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.criticalEvent}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">
                        MACHINES
                      </p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        {plantDashboardCount.device_count}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img src={images.active} alt="" className="dashboardCard-img" />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">
                        ACTIVE
                      </p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        {plantDashboardCount.active_device_count}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img src={images.inactive} alt="" className="dashboardCard-img" />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">
                        INACTIVE
                      </p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        {plantDashboardCount.inactive_device_count}
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.workOrder}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <div>
                      <p className="dashboardCard-title">
                        WORK ORDER
                      </p>
                    </div>
                    <div>
                      <h6 className="dashboardCard-number">
                        00
                      </h6>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>

          <div className="pt-4">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={8} xl={8}>
                <TextField
                  variant="outlined"
                  style={{ width: "100%", backgroundColor: "white" }}
                  // className="search-textfield"
                  placeholder="Search Machine"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: <SearchIcon />,
                    inputProps: {
                      style: { padding: "9px" },
                    },
                  }}
                />
                <div style={{ maxHeight: "630px", overflow: "auto" }}>
                  <div className="pt-3">
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        {filteredDeviceData.length
                          ? filteredDeviceData.map((device) => {
                            const matchedNotification = notification.find(
                              (notif) => notif.id === device.device
                            );

                            return (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={4}
                                xl={4}
                                key={device.id}
                              >
                                <Card
                                  className="dashboard-machine"
                                  onClick={() => handleNotification(device)}
                                >
                                  <div className="d-flex justify-content-between">
                                    <Typography
                                      gutterBottom
                                      style={{
                                        fontSize: "15px",
                                        marginBottom: "0 !important",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {device.device__device_name}
                                    </Typography>
                                    <div>
                                      <NotificationsIcon
                                        style={{
                                          color: getNotificationIconColor(
                                            matchedNotification?.alarm_priority
                                          ),
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <Typography
                                    gutterBottom
                                    style={{
                                      fontSize: "15px",
                                      marginBottom: "0 !important",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {device.device__device_description}
                                  </Typography>
                                </Card>
                              </Grid>
                            );
                          })
                          : []}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Card className="machine-card">
                  {notificationModal && (
                    <>
                      <div
                        className="notificationModal"
                        style={{ height: "100%" }}
                      >
                        {/* <Typography
                        gutterBottom
                        className="dashboardCard-companyTitle"
                        style={{
                          cursor: "pointer"
                        }}
                        onClick={() => handleMachineDashboardnavigate()}
                      >
                        {machine.device_name}
                      </Typography>
                      <Typography
                        onClick={() => handleMachineDashboardnavigate()}
                        gutterBottom
                        style={{
                          fontSize: "15px",
                          marginBottom: "0 !important",
                          marginTop: "5px",
                          cursor:"pointer"
                        }}
                      >
                        {machine.device_description}
                      </Typography> */}
                        <button style={{
                          background: "#f0f0fe",
                          border: "none",
                          padding: "9px",
                          borderRadius: "4px"
                        }}
                          onClick={() => handleMachineDashboardnavigate()} className="unity-modal-btn">
                          {" "}
                          {machine.device_name} <br />{" "}
                          {machine.device_description}
                        </button>
                        <div
                          style={{
                            height: "88%",
                            textAlign: "center",
                          }}
                        >
                          {deviceUID ? (
                            <VmsARMachine
                              key={keyvalue}
                              deviceUID={deviceUID}
                              navigateRoute={navigateRoute}
                              sendRealTimeDataModel={sendRealTimeDataModel}
                            />
                          ) : null}
                          {/* <div className="device-image-modal">                    
                          {modelFound && <img src={images.noDataFoundImg} className="notFoundDataImg" />}
                        </div> */}
                          {/* {deviceUID && !modelFound && modelUrlData ?
                          <VmsARMachine
                            key={keyvalue}
                            deviceUID={deviceUID}
                            navigateRoute={navigateRoute}
                            modelUrlData={modelUrlData}
                          /> : null}
                        <div className="device-image-modal">
                          {modelFound && (
                            <img
                              src={images.noDataFoundImg}
                              className="notFoundDataImg"
                            />
                          )}
                        </div>
                        {/* <img
                          src={images.machine}
                          alt="machine"
                          style={{ width: "100%", cursor: "pointer" }}
                        /> */}
                        </div>
                      </div>
                    </>
                  )}
                </Card>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </>
  );
};
export default VmsMachine;
