import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { InputAdornment } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { images } from "../../config";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { changePasswordApi } from "../userProfile/service";
import { clearUserDetails, setUserDetails } from "../Login/slice/userSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearCompanyDetails } from "../SuperAdminDashboard/Slice";
import { clearProcessDashboarData } from "../ProcessDashboard/Consumption/slice/processDashboardSlice";
import { clearProcessDashboarDataSet } from "../ProcessDashboard/sliceProcessDashboard/dateSliceprocessDashboard";
import { clearOverviewData } from "../ProcessDashboard/OverView/Slice/OverViewAnalyticsSlice";

const ChangePassword = () => {
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showReEnterPassword, setShowReEnterPassword] = React.useState(false);
  const initialValue = { old_password: "", newPassword: "", RePassword: "" };
  const [password, setpassword] = useState(initialValue);
  const [lengthValid, setLengthValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [usernameValid, setUsernameValid] = useState(false);
  const [logout, setLogout] = useState(false);

  const { id } = useSelector((state) => state.userState);
  const navigate = useNavigate();
  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  //Logout modal
  const handleLogoutmodal = () => {
    setLogout(!logout);
  };

  const handleLogout = () => {
    dispatch(clearUserDetails());
    dispatch(clearCompanyDetails());
    dispatch(clearProcessDashboarData());
    dispatch(clearProcessDashboarDataSet());
    dispatch(clearOverviewData());
    navigate("/forgot-password");
  };
  useEffect(() => {
    // Check password length

    if (password.newPassword.length >= 8 && password.newPassword.length <= 16) {
      setLengthValid(true);
    } else {
      setLengthValid(false);
    }

    // Check for number
    const numberRegex = /\d/;
    if (numberRegex.test(password.newPassword)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }

    // Check for special character
    const specialCharRegex = /[^\w\s]/;
    if (specialCharRegex.test(password.newPassword)) {
      setSpecialCharValid(true);
    } else {
      setSpecialCharValid(false);
    }

    // Check for lowercase letter
    const lowercaseRegex = /[a-z]/;
    if (lowercaseRegex.test(password.newPassword)) {
      setLowercaseValid(true);
    } else {
      setLowercaseValid(false);
    }

    // Check for uppercase letter
    const uppercaseRegex = /[A-Z]/;
    if (uppercaseRegex.test(password.newPassword)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
  }, [password.newPassword]);

  const handelChangePassword = (e) => {
    setpassword({ ...password, [e.target.name]: e.target.value });
  };
  const handlePasswordToggle = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleRePasswordToggle = () => {
    setShowReEnterPassword((prevShowPassword) => !prevShowPassword);
  };
  const dispatch = useDispatch();

  const submitHandlePassword = async () => {
    if (!password.old_password) {
      notify("Please Enter Old Password.");
      return true;
    }

    if (!password.newPassword) {
      notify("Please Enter New Password");
      return true;
    }

    if (password.old_password == password.newPassword) {
      notify(
        "Your old password and new password is same. Please enter different password."
      );
      return true;
    }

    if (!password.RePassword) {
      notify("Please Enter Confirm Password");
      return true;
    }

    if (password.newPassword != password.RePassword) {
      notify("Please check password is not same.");
      return true;
    }
    if (
      !lengthValid ||
      !numberValid ||
      !specialCharValid ||
      !lowercaseValid ||
      !uppercaseValid
    ) {
      return true;
    }

    const params = {
      old_password: password.old_password,
      new_password: password.newPassword,
    };
    try {
      const resp = await changePasswordApi(id, params);
      if (resp.data.success == true) {
        setpassword(initialValue);
        notifySuccess(resp.data.message);
        dispatch(
          setUserDetails({
            token: resp.data.payload.token,
          })
        );
        navigate("/")
      }
    } catch (error) {
      notify(error.response.data.old_password[0]);
    }
  };

  const handleCloseClick = () =>{
    navigate("/")
  }
  return (
    <>
      
      <Card className="accountSecurity-card">
        <Box>
          <Stack direction="row" spacing={{ sm: 22, md: 28, lg: 28 }}>
            <Typography
              gutterBottom
              style={{ color: "#1C407B", fontWeight: "600", fontSize: "19px" }}
            >
              Change Password
            </Typography>
            <img
              src={images.closeIcon}
              alt=""
              className="close-password-popup"
              style={{ width: "30px", cursor: "pointer" }}
                onClick={handleCloseClick}
            />
          </Stack>
        </Box>
        <Stack spacing={2} pt="10px">
          <TextField
            id="filled-basic"
            type={showOldPassword ? "text" : "password"}
            label="Old Password"
            variant="filled"
            className="customeTextField security"
            name="old_password"
            value={password.old_password}
            onChange={handelChangePassword}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowOldPassword(!showOldPassword)}
                >
                  {showOldPassword ? (
                    <img src={images.viewIcon} alt="" />
                  ) : (
                    <img src={images.eyecloseIcon} alt="" />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="filled-basic"
            type={showPassword ? "text" : "password"}
            label="Password"
            variant="filled"
            className="customeTextField security"
            style={{ width: "100%" }}
            name="newPassword"
            value={password.newPassword}
            onChange={handelChangePassword}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={handlePasswordToggle}
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? (
                    <img src={images.viewIcon} alt="" />
                  ) : (
                    <img src={images.eyecloseIcon} alt="" />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id="filled-basic"
            type={showReEnterPassword ? "text" : "password"}
            label="Re-enter Password"
            variant="filled"
            className="customeTextField security"
            style={{ width: "100%" }}
            name="RePassword"
            value={password.RePassword}
            onChange={handelChangePassword}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={handleRePasswordToggle}
                  style={{ cursor: "pointer" }}
                >
                  {showReEnterPassword ? (
                    <img src={images.viewIcon} alt="" />
                  ) : (
                    <img src={images.eyecloseIcon} alt="" />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Typography
            variant="subtitle1"
            gutterBottom
            onClick={handleLogoutmodal}
            style={{
              fontWeight: 600,
              color: "#1C407B",
              paddingBottom: "20px",
              cursor: "pointer",
            }}
          >
            {" "}
            Forgot Password ?
          </Typography>
        </Stack>
        <div
          className="reEnterPassword-TextWrap mb-4"
          style={{ fontSize: "12px" }}
        >
          <h6 className="mb-3" style={{ fontSize: "13px" }}>
            <b>New password must include at least one:</b>
          </h6>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <h6
                style={{
                  color: password.newPassword
                    ? lengthValid
                      ? "green"
                      : "red"
                    : "",
                }}
              >
                Includes 8-16 characters
              </h6>
              <h6
                style={{
                  color: password.newPassword
                    ? numberValid
                      ? "green"
                      : "red"
                    : "",
                }}
              >
                Number
              </h6>
              <h6
                style={{
                  color: password.newPassword
                    ? specialCharValid
                      ? "green"
                      : "red"
                    : "",
                }}
              >
                Special character
              </h6>
            </Grid>
            <Grid item xs={6}>
              <h6
                style={{
                  color: password.newPassword
                    ? lowercaseValid
                      ? "green"
                      : "red"
                    : "",
                }}
              >
                Lowercase letter
              </h6>
              <h6
                style={{
                  color: password.newPassword
                    ? uppercaseValid
                      ? "green"
                      : "red"
                    : "",
                }}
              >
                Uppercase letter
              </h6>
            </Grid>
          </Grid>
        </div>
        <Box className="accountSecurity-cardButtons">
          <button className="nextButtons" onClick={submitHandlePassword}>
            SUBMIT
          </button>
        </Box>
      </Card>
      {logout && (
        <div className="loader-container">
          <Card className="logoutModal">
            <Typography variant="h6" gutterBottom className="logoutModal-text">
              You have to logout first ,<br></br> Are you sure want to logout??
            </Typography>
            <Box className="logoutmodal-btnWrap">
              <button className="logoutBtn" onClick={() => handleLogout()}>
                Yes
              </button>
              <button className="logoutBtn" onClick={() => handleLogoutmodal()}>
                No
              </button>
            </Box>
          </Card>
        </div>
      )}
    </>
  );
};
export default ChangePassword;
