/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  CardActionArea,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Slider,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import "./SubscriptionManagement.css";
import { getSiteConfigData, subscriptionPlan } from "../service";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

import { useSelector } from "react-redux";
import { RedirectAdminDashboard } from "../../../components/CommonFunction/RedirectAdminDashboard";

const SubscriptionManagement = () => {
  const [getSubscriptionPlan, setSubscriptionPlan] = useState([]);
  const [topTitle, setTopTitle] = useState("");
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    // Calculate the maximum height among all cards
    const calculateMaxHeight = () => {
      const cardElements = document.querySelectorAll(".subscriptionCard");
      let maxCardHeight = 0;
      cardElements.forEach(card => {
        maxCardHeight = Math.max(maxCardHeight, card.scrollHeight);
      });
      setMaxHeight(maxCardHeight);
    };

    // Calculate the maximum height initially
    calculateMaxHeight();

    // Recalculate the maximum height when the window is resized
    window.addEventListener('resize', calculateMaxHeight);

    // Clean up event listener
    return () => {
      window.removeEventListener('resize', calculateMaxHeight);
    };
  }, [getSubscriptionPlan,maxHeight]); // Dependency array is empty to run the effect only once

  const navigate = useNavigate();
  useEffect(() => {
    handlegetSubsctionPlan();
  }, []);
  // getSubscriptionPlan

  const handlegetSubsctionPlan = async () => {
    const resp = await subscriptionPlan();
    setSubscriptionPlan(resp.data.payload);
  };

  const handleCardClick = (e) => {
    if (e.is_subscriptable) {
      if (
        (e.license_dashboard == "Device_licenses" &&
          e.license_module == "HikEMM") ||
        e.license_module == "PredHik"
      ) {
        navigate(`/hikemmservices/${e.name}/${e.dashboard_id}`);
      } else {
        navigate(`/smartTagServices/smartag/${e.dashboard_id}`);
      }
    }
  };

  const {
    companyId,
    companyName,
    companyDescription,
    is_super_admin,
    user_access,
  } = useSelector((state) => state.userState);

  useEffect(() => {
    handleSiteConfig();
  }, []);

  const handleSiteConfig = async () => {
    const params = {
      site_config_key: "Subscription_Management",
    };

    try {
      const resp = await getSiteConfigData(params);
      if (resp.status == 200 || resp.status == 201) {
        setTopTitle(resp.data.payload.description);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  return (
    <>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="subscriptionTitle-wrap d-flex justify-content-between">
            <div
              onClick={() => {
                RedirectAdminDashboard(is_super_admin, navigate);
              }}
              className="d-flex"
            >
              <KeyboardBackspaceIcon className="backArrow" />
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  margin: "0",
                  paddingLeft: "10px",
                  color: "#001323",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginTop: "-3px",
                }}
              >
                Subscription Management
              </Typography>
            </div>
            <div>
              <button
                className="subscription-btn"
                onClick={() =>
                  navigate(
                    `/subscription/${getSubscriptionPlan[0].name}/${getSubscriptionPlan[0].id}/${getSubscriptionPlan[0].module_id}`
                  )
                }
              >
                My Subscription
              </button>
            </div>
          </div>
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{ marginBottom: "20px" }}
          >
            {topTitle}
          </Typography>

          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
            columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 1, xl: 2, xxl: 2 }}
            style={{ marginBottom: "25px", justifyContent: "center" }}
          >
            {getSubscriptionPlan.map((e) => (
              <div className="popup">
                <div
                  className="d-flex popup-flex"
                  style={{
                    justifyContent: "center",
                    gap: "20px",
                    flexWrap: "wrap",
                  }}
                >
                  <Card
                    className="subscriptionCard"
                    onClick={() => handleCardClick(e)}
                    style={{ cursor: "pointer", height: maxHeight + "px" }} // Set height dynamically
                  >
                    {/* <CardActionArea> */}
                    <img
                      src={e.background_image}
                      alt="avv"
                      className="subscriptionCard-Image"
                    />
                    <div className="imageWrap"></div>
                    <img
                      src={e.image}
                      style={{ width: "14%" }}
                      alt="avv"
                      className="subscriptionCard-Icon"
                    />
                    <Stack className="subscriptionContent">
                      <Typography
                        gutterBottom
                        variant="h5"
                        className="subscription-title-name"
                      >
                        {e.name}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="h5"
                        className="subscription-text"
                      >
                        {e.price_tag}
                      </Typography>
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto", marginBottom: "26px",
                        }}
                      >
                        {e.plan_detail.map((e1) => (
                          <ListItem key={e1.id}>
                            <ListItemAvatar>
                              <Avatar className="checkIconbg">
                                <CheckRoundedIcon className="checkIcon" />
                              </Avatar>
                            </ListItemAvatar>
                            <Typography
                              gutterBottom
                              variant="body2"
                              className="subscription-details"
                            >
                              {e1.description}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    </Stack>
                    {/* </CardActionArea> */}
                  </Card>
                </div>
              </div>
            ))}
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default SubscriptionManagement;
