import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import '../../container/ProcessDashboard/ProcessDashboard.css';
import { PieChartShimmer } from "../Shimmer/PieChartShimmer/PieChartShimmer";
import { images } from "../../config";

export function VmsCOCPieChart({
  AvailabilityCOCPieChartData,
  shimmer,
}) {
  const chartRef = useRef(null);
  const { analyticsDataDeviceColor } = useSelector(
    (state) => state.analyticsDataState
  );
  const param = useParams();

  const deviceUpTime = AvailabilityCOCPieChartData?.deviceData?.map(row => row.up_time) || [];
  const deviceNames = AvailabilityCOCPieChartData?.deviceData?.map(row => row.device__device_name) || [];
  
  // Finding device colors based on plantId
  const findPlantDevice = analyticsDataDeviceColor.filter(f => f.plantId == param.id) || [];
  const deviceColorMap = new Map(findPlantDevice.map((row) => [row.dvName, row.color]));

  // Convert uptime values to absolute values for chart display
  const dataForChart = AvailabilityCOCPieChartData?.deviceData?.map((row) => ({
    name: row.device__device_name,
    y: Math.abs(row.up_time),  // Use absolute value for display
    color: deviceColorMap.get(row.device__device_name),
    originalY: row.up_time,    // Keep original value for tooltip
  })) || [];

  const options = {
    chart: {
      type: 'pie',
      height: 230,
      width: 230,
      backgroundColor: 'transparent',
    },
    title: {
      text: ''
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        const total = this.series.data.reduce((acc, point) => acc + Math.abs(point.originalY), 0);
        const percentage = ((Math.abs(this.point.originalY) / total) * 100).toFixed(2) + '%';
        return `<div style ="font-size : 12px">
                  <b>${this.point.name}</b><br/>
                  <span style="color:${this.point.color}">\u25A0</span> ${this.point.originalY.toFixed(2)} (${percentage})
                </div>`;
      }
    },
    plotOptions: {
      pie: {
        innerSize: '70%',
        dataLabels: {
          enabled: false,
        }
      }
    },
    series: [{
      name: 'Up Time',
      data: dataForChart,
    }],
    credits: {
      enabled: false
    }
  };

  const allZeros = deviceUpTime.every(time => time === 0);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.update(options);
    }
  }, [AvailabilityCOCPieChartData, findPlantDevice]);

  return (
    <div style={{ width: "100%", height: "195px" }} className="doughnut-chart1">
      {!shimmer ? (
        <>
          {allZeros ? (
            <img src={images.noDataFoundImg} className="notFoundDataImg not-found" alt="No Data Found" />
          ) : (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartRef}
              className="donut-chart-css"
            />
          )}
          <p className="doughnut-chartText" style={{left:"25%"}}>
            {!allZeros ? AvailabilityCOCPieChartData?.deviceTotal?.total_up_time : ""}
          </p>
          <p className="doughnut-chartText-unit">
            {AvailabilityCOCPieChartData?.deviceTotal?.total_up_time ? "hr" : ""}
          </p>
        </>
      ) : (
        <PieChartShimmer size="195px" position="relative"/>
      )}
    </div>
  );
}
