import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Textshimmer from "../../../../components/Shimmer/TextShimmer/TextShimmer";

const ConfigureShimmer = ({ itemCount }) => {
  console.log("itemCount", itemCount);
  const style = {
    backgroundColor: "white",
   
  };
  return (
    <>
      {Array.from({ length: itemCount }).map((_, index) => (
        <TableRow key={index} className="Configure-table">
          <TableCell style={{ backgroundColor: "white" }}>
            <Textshimmer height="24px" width="50%" />
          </TableCell>
          <TableCell style={style}>
            <Textshimmer height="16px" width="100%" />
          </TableCell>
          <TableCell style={style}>
            <Textshimmer height="16px" width="100%" />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default ConfigureShimmer;
