import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts/highstock'; // Import Highstock module
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import exporting from 'highcharts/modules/exporting';
import { useSelector } from 'react-redux';
import { images } from '../../../config';
import { CompactTagUnit } from '../../../components/CompactValue/CompactValue';
import { ShimmerText } from "react-shimmer-effects";
import boost from 'highcharts/modules/boost';
import {ParetoChartShimmer} from "../../../components/Shimmer/ParetoChartShimmer/ParetoChartShimmer";

HC_more(Highcharts); // Initialize the 'highcharts-more' module
exporting(Highcharts); // Initialize the 'exporting' module
boost(Highcharts);

export function PopgroupBarChart({
  analyticsPOPData,
  analyticsPOP1Data,
  deviceUnitPOPRedux,
  Shimmer,
  selectPOPTimeRange
}) {

  const chartRef = useRef(null);

  const {
    selectUnit
  } = useSelector((state) => state.dateSliceprocessDashboardState);

  const labels = [];

  if (analyticsPOPData[0]?.data?.length || analyticsPOP1Data[0]?.data?.length) {
    const popDataLength = analyticsPOPData[0]?.data?.length || 0;
    const pop1DataLength = analyticsPOP1Data[0]?.data?.length || 0;
    const maxLength = Math.max(popDataLength, pop1DataLength);

    for (let i = 0; i < maxLength; i++) {
      const popData = analyticsPOPData[0]?.data[i] || {};
      const pop1Data = analyticsPOP1Data[0]?.data[i] || {};
      const minKey = popData.MIN_KEY || "";
      const pop1MinKey = pop1Data.MIN_KEY || "";
      const labeledMinKey = `<span style="color: #C0255F;">${minKey}</span>`;
      const labeledPop1MinKey = `<span style="color: #E66595;">${pop1MinKey}</span>`;
      labels.push(`${labeledMinKey} ${labeledPop1MinKey}`);
    }
  }

  console.log("deviceUnitPOPReduxdeviceUnitPOPRedux", deviceUnitPOPRedux);

  const yAxiosTitle = deviceUnitPOPRedux.length ? deviceUnitPOPRedux[0]["tagUnit"] : "";


  const data = [
    {
      name: analyticsPOPData.length ? analyticsPOPData[0].device_name : "",
      data: analyticsPOPData.length
        ? analyticsPOPData[0] && analyticsPOPData[0].data.length
          ? analyticsPOPData[0].data.map((row) => parseFloat(row.tag_value))
          : []
        : [],
      color: '#C0255F',
      stack: "Stack 0",
      barPercentage: 1,
      barThickness: 30,
    },

    {
      name: analyticsPOP1Data.length ? analyticsPOP1Data[0].device_name : "",
      data: analyticsPOP1Data.length
        ? analyticsPOP1Data[0] && analyticsPOP1Data[0].data.length
          ? analyticsPOP1Data[0].data.map((row) => parseFloat(row.tag_value))
          : []
        : [],
      color: "#E66595",
      stack: "Stack 1",
      barPercentage: 1,
      barThickness: 30,
    },
  ]
  const options = {
    chart: {
      type: 'column', // Use 'column' type for vertical bar chart
      events: {
        load: function () {
          const xAxis = this.xAxis[0];
          const visibleCategories = labels.slice(-5); // Show the last 5 categories initially
          xAxis.setExtremes(labels.length - visibleCategories.length, labels.length - 1, false);
          this.redraw();
        },
      },
    }, 
    boost: {
      useGPUTranslations: true,
      usePreAllocated: true,
    },

    title: {
      text: null,
    },
    legend: {
      enabled: false, // Disable legend
    },
    xAxis: {
      categories: labels,
    },
    yAxis: {
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: 'gray',
        },
      },
      title: {
        text: CompactTagUnit(selectUnit ,yAxiosTitle),
      }
    },

    series: data,
    scrollbar: {
      enabled: true, // Enable scrollbar
    },
    exporting: {
      enabled: true, // Disable exporting options
    },
    credits: {
      enabled: false, // Disable credits
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        const pointIndex = this.point.index;
        const seriesIndex = this.series.index;
        // const currentData = analyticsPOPData[seriesIndex]?.data[pointIndex];
        console.log("SeriesIndex", seriesIndex);
        const currentData = seriesIndex === 0? analyticsPOPData[0]?.data[pointIndex]: analyticsPOP1Data[0]?.data[pointIndex];
        const deviceName = this.series.name;
        const deviceColor = this.series.color;
        const deviceValue = currentData?.tag_value;
    
        // Initialize tooltip content
        let tooltipContent = `
          <div style="font-size: 10px; line-height: 16px; max-height: 100px; overflow-y: auto;">
            <div><b>${currentData?.MIN_KEY}</b></div>
            <div>
              <span style="color: ${deviceColor}; font-weight: bold;">●</span> 
              <b>${deviceName}</b> : ${deviceValue}
            </div>
            <div style="margin-top: 5px; width: 100%;">
              <table style="width: 100%; border-collapse: collapse;">
        `;
    
        if (selectPOPTimeRange === "1" || selectPOPTimeRange === "7") {
          if (currentData && currentData.recipe_data) {
            currentData.recipe_data.forEach((recipe) => {
              tooltipContent += `
                <tr>
                  <td style="padding: 3px; text-align: center; vertical-align: middle;">${recipe.recipe_name}</td>
                  <td style="padding: 3px; text-align: center; vertical-align: middle; margin-left: auto; margin-right: auto;"><b>${recipe.tag_value}</b></td>
                </tr>
              `;
            });
          }
        } else {
          tooltipContent += `
            <tr>
              <td style="padding: 3px; text-align: center; vertical-align: middle;">${currentData.recipe_name}</td>
              <td style="padding: 3px; text-align: center; vertical-align: middle;"><b>${currentData.tag_value}</b></td>
            </tr>
          `;
        }
    
        // Close the table and div
        tooltipContent += `</table></div></div>`;
    
        // Return the complete tooltip content
        return tooltipContent;
      },
      style: {
        pointerEvents: 'auto', // Ensure that the tooltip can be interacted with
      },
    }
  };

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (chart) {
      chart.options.xAxis.min = labels.length - 5; // Set the initial visible range for the x-axis
      chart.options.xAxis.max = labels.length - 1;
      chart.redraw();
    }
  }, [analyticsPOPData, analyticsPOP1Data]);

  return (
    // <div style={{ width: '100%', height: '322px' }}>
    <>
      {!Shimmer ? analyticsPOPData.length || analyticsPOP1Data.length ? <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />:<img src={images.noDataFoundImg} className="notFoundDataImg" alt='No DataFound Img'/> :  <ParetoChartShimmer barCount={12} className="ParetoChartShimmer-OverView"/>  }

    </>
    // </div>
  );
}

{/* <ShimmerText line={16} gap={15}  /> */}