import * as React from "react";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";

// import FooterText from '../../components/FooterText/FooterText';
import "../../../PlantDashboard/Dashboard.css";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { images } from "../../../../config/images";
import {
  getPlant,
  getPlantByCompanyApi,
} from "../../../CompanyDashboard/services";
import { setHeaderDetails } from "../../../Header/headerSlice";
import { plantDashboardCount } from "../../../SuperAdminDashboard/service";
import { getCompanyById } from "../VmsMachine/Advance Analytics/service";
import { countForVmsPlant } from "../VmsMachine/service";
import ShimmerEffectPlaceholder from "../../../../components/Shimmer/DashboardShimmer/CompanyListShimmer";
import { ShimmerText } from "react-shimmer-effects";
import "../Vms_Dashboard.css";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const VmsPlantDashboard = () => {
  const [plantData, setPlantData] = useState([]);
  const [plantDashboardCount1, setPlantDashboardCount1] = useState([]);
  const [isShimmerPlantList, setisShimmerPlantList] = useState(false);
  const [isShimmerHeader, setisShimmerHeader] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();

  const getPlantByCompany = async () => {
    setisShimmerPlantList(true);
    try {
      const param = {
        dashboard: 2,
        company: params.companyId,
      };
      const resp = await getPlant(param);
      if (resp.status == 200) {
        setTimeout(() => {
          setisShimmerPlantList(false);
        }, 1000);

        setPlantData(resp.data.payload ? resp.data.payload : []);
      }
    } catch (e) {
      setisShimmerPlantList(false);
      console.log(" Error from ...getPlantByCompany", e);
    }
  };

  useEffect(() => {
    getPlantByCompany();
  }, []);

  const getCompanyByIdAPI = async () => {
    try {
      const resp = await getCompanyById(params.companyId);

      if (resp.status == 200) {
        dispatch(setHeaderDetails({ headerName: resp.data.company_name }));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCompanyByIdAPI();
  }, []);

  const handleProcessDashboard = (row) => {
    navigate(`/vmsmachine/company/${params.companyId}/plant/${row.id}`);
    dispatch(setHeaderDetails({ headerName: row.plant_name }));
  };

  useEffect(() => {
    handlePlantDashboardCount();
  }, []);

  const handlePlantDashboardCount = async () => {
    setisShimmerHeader(true);
    const param = {
      company_name: params.companyId,
    };

    try {
      const resp = await countForVmsPlant(param);
      if (resp.data.success == true) {
        setTimeout(() => {
          setisShimmerHeader(false);
        }, 1000);

        setPlantDashboardCount1(resp.data.payload);
      }
    } catch (error) {
      setisShimmerHeader(false);
      console.log("handleSubmitError", error);
    }
  };

  const TextShimmer = () => {
    return (
      <>
        <ShimmerText line={1} gap={10} className="Header-TextShimmer sup-header" />
      </>
    );
  };
  return (
    <>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <Card className="dashboardCard">
            <Grid container>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.criticalEvent}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <p className="dashboardCard-title">
                      MACHINES
                    </p>
                    <h6 className="dashboardCard-number">
                      {!isShimmerHeader
                        ? plantDashboardCount1.device_count
                        : TextShimmer()}
                    </h6>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img src={images.active} alt="" className="dashboardCard-img" />
                  </div>
                  <div className="dashboardCard-text">
                    <p className="dashboardCard-title">
                      ACTIVE
                    </p>
                    <h6 className="dashboardCard-number">
                      {!isShimmerHeader
                        ? plantDashboardCount1.active_device_count
                        : TextShimmer()}
                    </h6>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.inactive}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <p className="dashboardCard-title">
                      INACTIVE
                    </p>
                    <h6 className="dashboardCard-number">
                      {!isShimmerHeader
                        ? plantDashboardCount1.inactive_device_count
                        : TextShimmer()}
                    </h6>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} sm={6} md={3} lg={3} className="grid-item">
                <div className="dashboardCard-item">
                  <div className="dashboard-card-img">
                    <img
                      src={images.VMSWorkOrder}
                      alt=""
                      className="dashboardCard-img"
                    />
                  </div>
                  <div className="dashboardCard-text">
                    <p className="dashboardCard-title">
                      WORK ORDER
                    </p>
                    <h6 className="dashboardCard-number">
                      00
                    </h6>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Card>


          <Grid container spacing={4} mt={"0px"}>
            {!isShimmerPlantList ? (
              plantData.length ? (
                plantData.map((row) => {
                  console.log("row", row);
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Card
                        className="dashboardCard-plant"
                        onClick={() => handleProcessDashboard(row)}
                      >
                        <Stack direction="column">
                          <Typography
                            gutterBottom
                            className="dashboardCard-plantTitle"
                          >
                            {row.plant_name ? row.plant_name : "-"}
                          </Typography>
                          <Typography
                            gutterBottom
                            className="dashboardCard-plantsubTitle"
                          >
                            {row.plant_description
                              ? row.plant_description
                              : "-"}
                          </Typography>
                          {/* <Box className="dashboardCard-plantContent">
                        <Typography
                          gutterBottom
                          className="dashboardCard-plantText"
                        >
                          Device:
                        </Typography>
                        <Stack direction="column">
                          
                          <Typography
                            variant="body1"
                            className="dashboardCard-plantNumber1"
                          >
                          
                           {row.device.filter(value => value.is_sub == true).length}
                          </Typography>
                          <Typography
                            variant="caption"
                            className="dashboardCard-plantSmalltext"
                          >
                            Active
                          </Typography>
                        </Stack>
                        <Box
                          style={{
                            borderRight: "2px solid #C9CFE7",
                            height: "30px",
                          }}
                          paddingRight={2}
                        >
                          <Stack
                            direction="column"
                            style={{ marginTop: "-7px" }}
                          >
                            <Typography
                              variant="body1"
                              className="dashboardCard-plantNumber2"
                            >
                              {row.device.filter(value => value.is_sub == false ).length}
                            </Typography>
                            <Typography
                              variant="caption"
                              className="dashboardCard-plantSmalltext"
                            >
                              Inactive
                            </Typography>
                          </Stack>
                        </Box>
                        <Box
                          style={{
                            borderRight: "2px solid #C9CFE7",
                            height: "30px",
                          }}
                          paddingRight={2}
                        >
                          <Stack
                            direction="column"
                            spacing={1}
                            style={{ marginTop: "-7px" }}
                          >
                            <img
                              src={images.callImg}
                              alt=""
                              className="dashboardCard-plantImg"
                            />
                            <Typography
                              variant="caption"
                              className="dashboardCard-plantSmalltext"
                            >
                              Service call
                            </Typography>
                          </Stack>
                        </Box>
                        <Box
                          style={{
                            borderRight: "2px solid #C9CFE7",
                            height: "30px",
                          }}
                          paddingRight={2}
                        >
                          <Stack
                            direction="column"
                            spacing={1}
                            style={{ marginTop: "-7px" }}
                          >
                            <img
                              src={images.alertImage}
                              alt=""
                              className="dashboardCard-plantImg"
                            />
                            <Typography
                              variant="caption"
                              className="dashboardCard-plantSmalltext"
                            >
                              Subscription
                            </Typography>
                          </Stack>
                        </Box>
                        <Stack direction="column" spacing={1}>
                          <img
                            src={images.notificationactiveImg}
                            alt=""
                            className="dashboardCard-plantImg"
                          />
                          <Typography
                            variant="caption"
                            className="dashboardCard-plantSmalltext"
                          >
                            Notification
                          </Typography>
                        </Stack>
                      </Box> */}
                          <Box className="dashboardCard-companyContent">
                            <Typography
                              gutterBottom
                              className="dashboardCard-plantText"
                            >
                              Device :
                            </Typography>
                            <Stack direction="column">
                              <Typography
                                variant="body1"
                                className="dashboardCard-plantNumber1"
                              >
                                {
                                  row.device.filter(
                                    (value) =>
                                      value.is_sub == true &&
                                      value.device_type_name !== "Other"
                                  ).length
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                className="dashboardCard-plantSmalltext"
                              >
                                Active
                              </Typography>
                            </Stack>
                            <Box>
                              <Stack direction="column">
                                <Typography
                                  variant="body1"
                                  className="dashboardCard-plantNumber2"
                                >
                                  {
                                    row.device.filter(
                                      (value) =>
                                        value.is_sub == false &&
                                        value.device_type_name !== "Other"
                                    ).length
                                  }
                                </Typography>
                                <Typography
                                  variant="caption"
                                  className="dashboardCard-plantSmalltext"
                                >
                                  Inactive
                                </Typography>
                              </Stack>
                            </Box>
                            <div
                              style={{
                                borderRight: "2px solid #C9CFE7",
                                height: "30px",
                              }}
                            ></div>
                            <Box>
                              <Stack
                                direction="column"
                                spacing={1}
                                style={{ marginTop: "5px" }}
                              >
                                <img
                                  src={images.serviceCallImg}
                                  alt=""
                                  className="dashboardCard-companyImg"
                                />
                                <Typography
                                  variant="caption"
                                  className="dashboardCard-plantSmalltext"
                                >
                                  Service call
                                </Typography>
                              </Stack>
                            </Box>
                            <div
                              style={{
                                borderRight: "2px solid #C9CFE7",
                                height: "30px",
                              }}
                            ></div>

                            <Box>
                              <Stack
                                direction="column"
                                spacing={1}
                                style={{ marginTop: "5px" }}
                              >
                                {row.license_status_count == 0 ? (
                                  <img
                                    src={images.noSub}
                                    alt=""
                                    className="dashboardCard-companyImg"
                                  />
                                ) : (
                                  <img
                                    src={images.alertImage}
                                    alt=""
                                    className="dashboardCard-companyImg"
                                  />
                                )}
                                {/* <img
                                  src={images.alertImage}
                                  alt=""
                                  className="dashboardCard-companyImg"
                                /> */}
                                <Typography
                                  variant="caption"
                                  className="dashboardCard-plantSmalltext"
                                >
                                  Subscription
                                </Typography>
                              </Stack>
                            </Box>
                            <div
                              style={{
                                borderRight: "2px solid #C9CFE7",
                                height: "30px",
                              }}
                            ></div>

                            <Box paddingRight={4}>
                              <Stack
                                direction="column"
                                spacing={1}
                                style={{ marginTop: "5px" }}
                              >
                                {row.alarm_count > 0 ? (
                                  <img
                                    src={images.notificationactiveImg}
                                    alt=""
                                    className="dashboardCard-companyImg"
                                  />
                                ) : (
                                  <img
                                    src={images.alertImg}
                                    alt=""
                                    style={{ width: "25px", margin: "0 auto" }}
                                  // className="dashboardCard-companyImg"
                                  />
                                )}
                                <Typography
                                  variant="caption"
                                  className="dashboardCard-plantSmalltext"
                                >
                                  Notification
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>
                        </Stack>
                      </Card>
                    </Grid>
                  );
                })
              ) : (
                []
              )
            ) : (
              <ShimmerEffectPlaceholder />
            )}
          </Grid>
        </Container>
      </div>
    </>
  );
};
export default VmsPlantDashboard;
