export const urls ={
    apiBaseUrl: process.env.REACT_APP_API_URL
}

// export const urls = {
//     apiBaseUrl: "https://hikemm.hikar.cloud"
// }

// export const urls = {
//     apiBaseUrl: "http://192.168.29.157:7000/"
// }