import { request } from "../../../../../api/api";

export const getDeviceByType = (params) => {
    return request.post("/dashboard/getDeviceByType/", params);
};

export const getTagID = (data) => {
    return request.post('/dashboard/getTagId/', data)
}

export const getUnitTag = (params) =>{
    return request.post(`/vms/reports/getTagList/`,params)
}

export const analyticsReportExcel = (params)=>{
    return request.post(`/vms/report/exportVMSAnalyticsCOCExcelReport/`,params)
}

export const vmsOverViewReportExcel = (params)=>{
    return request.post(`/dashboard/exportVMSOverviewReportExcel/`,params)
}

export const getDeviceDashboard = (params)=>{
    return request.post("/dashboard/getDeviceByDashboard/", params);
}
export const anlyticsPopReportExcel = (params)=>{
    return request.post(`/vms/report/exportVMSAnalyticsPOPExcelReport/`,params)
}

export const reportExcel = (params)=>{
    return request.post(`/vms/report/exportVMSReportsExcelReport/`,params)
}

export const getDeviceUnits = (params)=>{
    return request.post(`/dashboard/getDeviceTagUnits/`,params)
}