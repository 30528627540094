import { combineReducers } from "redux";
import userSlice from "../../container/Login/slice/userSlice";
import HeaderSlice from "../../container/Header/headerSlice";
import processDashboardSlice from "../../container/ProcessDashboard/Consumption/slice/processDashboardSlice";
import dateSliceprocessDashboard from "../../container/ProcessDashboard/sliceProcessDashboard/dateSliceprocessDashboard";
import OverViewAnalyticsSlice from "../../container/ProcessDashboard/OverView/Slice/OverViewAnalyticsSlice";
import companySlice from "../../container/SuperAdminDashboard/Slice";
import vmsSlice from "../../container/VMS/VmsCompanyDashboard/vmsSlice/vmsSlice";
import analyticsDataSlice from "../../container/ProcessDashboard/AdvanceAnalytics/Slice/AdvanceAnalyticsSlice";
import vmsOverViewSlice from "../../container/VMS/VmsCompanyDashboard/VmsMachine/OverView/slice/vmsOverViewSlice";
import VmsReportSlice from "../../container/VMS/VmsCompanyDashboard/VmsMachine/Reports/Slice/VmsReportSlice";
import userLoginSlice from "../../container/Login/slice/userLoginSlice";
import vmsAdvanceAnalyticsSlice from "../../container/VMS/VmsCompanyDashboard/VmsMachine/Advance Analytics/slice/vmsAdvanceAnalyticsSlice";
import smartTagReportSlice from "../../container/SmartTag/MachineDashboard/Slice"

const mainReducer = combineReducers({
    userState: userSlice,
    userLoginSlice: userLoginSlice,
    headerState: HeaderSlice,
    processDashboardState: processDashboardSlice,
    overViewState: OverViewAnalyticsSlice,
    dateSliceprocessDashboardState: dateSliceprocessDashboard,
    companyDetailsState: companySlice,
    vmsState: vmsSlice,
    analyticsDataState: analyticsDataSlice,
    vmsOverViewSlice: vmsOverViewSlice,
    VmsReportSlice: VmsReportSlice,
    vmsAdvanceAnalyticsSlice: vmsAdvanceAnalyticsSlice,
    smartTagReportSlice : smartTagReportSlice,
});

const rootReducer = (state, action) => {
    if (action.type === "CLEAR_STORE") {
        // Reset each slice state to its initial state
        state = undefined;
    }
    return mainReducer(state, action);
};

export default rootReducer;
