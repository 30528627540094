import { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { images } from "../../../config";
import { CompactTagUnit } from "../../../components/CompactValue/CompactValue";


const DonutChart = ({
  positionValue,
  PiaChartBorderColor,
  dashboard,
  sum,
  selectUnit,
  positionId,
  mapTagDetails
}) => {
  const chartRef = useRef(null);

  const [chartOption, setChartOption] = useState({
    chart: {
      type: "pie",
      backgroundColor: "transparent",
      height: 300,
      width: 300,
    },
    title: {
      text: "",
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        return `<div style="font-size : 12px;">
              <b>${this.point.name}</b><br/>
              <span style="color:${this.point.color}">\u25A0</span> ${
          this.point.originalValue // Show the original value
        } (${this.percentage.toFixed(2)}%)
          </div>`;
      },
    },
    plotOptions: {
      pie: {
        innerSize: "70%",
        dataLabels: {
          enabled: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Values",
        data: [],
      },
    ],
  });

  useEffect(() => {
    if (!positionValue || !positionId || !PiaChartBorderColor) {
      return;
    }
 
    const seriesData = positionValue.map((value, index) => {
      const { tag_comment, tag_unit } = mapTagDetails(positionId[index]);
      return {
        name: tag_comment,
        y: Math.abs(value),
        color: PiaChartBorderColor[index],
        originalValue: value,
        unit: tag_unit,
      };
    });

    console.log("seriesData",seriesData)

    setChartOption((prevOptions) => ({
      ...prevOptions,
      series: [
        {
          name: "Values",
          data: seriesData.filter((dataPoint) => dataPoint.originalValue !== 0), // Filter out zero values
        },
      ],
    }));
  }, [positionValue, positionId, PiaChartBorderColor]);

  return (
    <div
      style={{
        width: "300px",
        height: "300px",
        marginTop: "48px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className="doughnut-chart"
    >
      {positionValue && positionValue.some((value) => value !== 0) ? (
        <>
          <HighchartsReact highcharts={Highcharts} options={chartOption} />
          <p className="doughnut-chartText">{dashboard.length ? sum : ""}</p>
          <p className="doughnut-chartText-small">
            {dashboard.length
              ? CompactTagUnit(selectUnit, dashboard[0].tag_unit)
              : ""}
          </p>
        </>
      ) : (
        <img
          src={images.noDataFoundImg}
          className="notFoundDataImg not-found"
          alt=""
        />
      )}
    </div>
  );
};

export default DonutChart;
