import { request } from "../../../api/api";


export const addGroup = (params) =>{
    return request.post(`/alarm_management/addGroup/`, params);
}

export const getGroup =(params)=>{
return request.post(`/alarm_management/getGroup/`,params)
}
export const updateGroup =(params,id)=>{
return request.put(`/alarm_management/updateGroup/${id}/`,params)
}

export const getPlantGatewayAlarmmanagement= (params) =>{
return request.post(`/alarm_management/getPlantGateway/`,params)
}

export const getPlantMachineAlarmManagement  = (params) =>{
    return request.post(`/alarm_management/getPlantMachine/`,params)
}

export const getPlantDeviceAlarmManagement = (params) =>{
    return request.post(`/alarm_management/getPlantDevice/`,params)
}
export const deleteGroup =(id)=>{
    return request.put(`/alarm_management/deleteGroup/${id}/`)
}

export const getDeviceListData = () =>{
    return request.get(`/device/getDevice/`)
}

export const getDepartmentRole = (params)=>{
    return request.post(`/alarm_management/getDepartmentRoleUser/`,params)
}

export const addGroupConfiguration =(params)=>{
    return request.post(`/alarm_management/addGroupConfiguration/`,params)
}

export const getGroupConfiguration =(params)=>{
    return request.post(`/alarm_management/getGroupConfiguration/`,params)
}

export const updateGroupConfiguration =(params,id)=>{
    return request.put(`/alarm_management/updateGroupConfiguration/${id}/`,params)
}

export const getDepartmentForManagement =(params)=>{
    return request.post(`/access_management/getDepartmentForManagement/`,params)
}