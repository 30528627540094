import { Chart } from "chart.js";
import { useEffect, useRef } from "react";

const BarChart = ({
  dashboard,
  tagData,
  positionValue,
  PiaChartBorderColor,
  positionId,
  mapTagDetails
}) => {
  const chartContainer = useRef(null);

  useEffect(() => {
   
  


    const barChartData = {
      labels: positionId.map((id) => {
        const { tag_comment } = mapTagDetails(id);
        return tag_comment;
      }),
      datasets: [
        {
          data: positionValue,
          backgroundColor: PiaChartBorderColor,
        },
      ],
    };
  

    const chartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              const dataset = barChartData.datasets[context.datasetIndex];
              const total = dataset.data.reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                0
              );
              const currentValue = dataset.data[context.dataIndex];
              const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
              return `${currentValue} (${percentage})`;
            },
            title: function (tooltipItem) {
              const labelIndex = tooltipItem[0].dataIndex;
              return barChartData.labels[labelIndex];
            },
          },
        },
        legend: {
          display: false,
        },
      },
      layout: {},
      scales: {
        y: {
          title: {
            display: true,
            font: {
              size: 17,
              weight: "bold",
            },
            color: "#001323",
          },
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 12,
            },
            color: "#001323",
          },
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: 14,
            },
            color: "#001323",
          },
        },
      },
    };

    const ctx = chartContainer.current.getContext("2d");
    const chart = new Chart(ctx, {
      type: "bar",
      data: barChartData,
      options: chartOptions,
    });

    return () => {
      chart.destroy();
    };
  }, [positionValue,tagData]);

  return (
    <div style={{ width: "100%", height: "250px", marginTop: "68px" }} className="processdasboard-barChart">
      <canvas ref={chartContainer} id="canvas"></canvas>
    </div>
  );
};

export default BarChart;
