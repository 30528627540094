import React, { useEffect, useState } from "react";
import logo from "../../../assets/hikar.png";
import { Grid } from "@mui/material";
import { Button } from "../../../components/Button/button";
import "./Otp.css";
import OTPInput, { ResendOTP } from "otp-input-react";
import { SliderAuth } from "../../../components/SliderAuth/SliderAuth";
import { otpVerifyApi, forgotPasswordApi } from "../service";
import { ToastContainer, toast } from "react-toastify";
import { images } from "../../../config/images";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import PrivacyPolicy from "../../../components/Privacy/Privacy";


export default function Otp({
  OTP,
  setOTP,
  email,
  onNextClick,
  onCancelClick,
}) {
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(60);
  const [visibleOTP, setvisibleOTP] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleOtpSubmit = async () => {
    if (OTP.length == 6) {
      const params = {
        email: email,
        otp: OTP,
      };
      setLoading(true);
      const resp = await otpVerifyApi(params);
      if (resp.data.success == true) {
        setLoading(false);
        onNextClick();
      }

      if (resp.data.success == false) {
        setLoading(false);
        notify(resp.data.message);
      }
    } else {
      notify("Please Enter valid OTP.");
    }
  };

  const handleResendOTp = async () => {
    if (email) {
      const params = {
        email: email,
      };
      try {
        setLoading(true);
        const resp = await forgotPasswordApi(params);
        if (resp.data.success == true) {
          setLoading(false);
          setSeconds(60);
          notifySuccess("OTP Resend");
        }

        if (resp.data.success == false) {
          notify(resp.data.message);
        }
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    }
  };
  const handleTermsClick = () =>{
    window.location.replace("https://hikartech.com/terms-and-conditions/")
  }

  return (
    <>
      <div>
        
      </div>
      <Grid container className="main-container">
        <SliderAuth />
        <Grid item xs={12} md={5} lg={4} className="signIn-form-wrap">
          <div className="pb-4">
            <img src={logo} alt="" className="signIn-logo" />
          </div>
          <h1 className="forgotPassword text-center p-5 pb-3">
            Forgot Password?
          </h1>
          <div className="forgotPassword-lines pb-4">
            <h5
              style={{
                color: "#001323",
                fontWeight: 400,
                letterSpacing: "0.48px",
              }}
            >
              Please Enter OTP Sent to {email}
            </h5>
          </div>
          <div className="otp-input wrap">
            <OTPInput
              value={OTP}
              onChange={setOTP} // Fix the typo here
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              secure={!visibleOTP}
              className="OTP"
              inputStyle="xyz"
            />
            {visibleOTP ? (
              <img
                src={images.viewIcon}
                alt=""
                className="view icon OTP"
                onClick={() => setvisibleOTP(false)}
              />
            ) : (
              <img
                src={images.eyecloseIcon}
                alt=""
                className="view icon OTP"
                onClick={() => setvisibleOTP(true)}
              />
            )}
          </div>
          <div className="countdown-text d-flex justify-content-center pt-3">
            {seconds > 0 ? (
              <>
                <p className="d-flex" style={{ marginLeft: "50px" }}>
                  Please wait for {seconds < 10 ? `0${seconds}` : seconds}{" "}
                  seconds
                </p>
              </>
            ) : (
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="abc"
              >
                <div>Didn't recieve code?</div>
                <div>
                  <a
                    style={{
                      cursor: "pointer",
                      textDecorationLine: "underline",
                      fontWeight: 500,
                    }}
                    onClick={() => handleResendOTp()}
                  >
                    Resend
                  </a>
                </div>
              </div>
            )}
          </div>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="pb-5"
          >
            <Grid item xs={6}>
              <div onClick={() => onCancelClick()}>
                <Button title="cancel" classname={"border-btn"} />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div onClick={handleOtpSubmit}>
                <Button title="Submit" classname={"custom-button"} />
              </div>
            </Grid>
          </Grid>
          {/* <div className="SingIn-unlock d-flex flex-column pb-0 pb-sm-5 pb-md-3 pb-lg-4">
                        <div>Unlock account?</div>
                        <div>Help</div>
                    </div> */}
          <div className="SingIn-unlock d-flex flex-column">
            <div
              onClick={() =>
                window.location.assign("https://hikartech.com/unlock-account/")
              }
              style={{ cursor: "pointer" }}
            >
              Unlock account?
            </div>
            <div>Help</div>
          </div>
          <PrivacyPolicy />
        </Grid>
      </Grid>
      {isLoading && <Loader />}
    </>
  );
}
