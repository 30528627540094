import React from "react";
import { Box, TextField } from "@mui/material";
import { Grid } from "@material-ui/core";
import ReportTableListData from "./ReportTableListData";



const ReportData = () => {
    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={9}
            className="consumption-table-box"
        >
            <Box className="consumerData-box" style={{ padding: "0px" }}>
                <div style={{ minHeight: "319px" }}>
                    <div className="spinner-container">
                        {/* {isLoadingReport && (
                            <div className="spinner">
                                <img src={images.spinner} alt="Spinner" />
                            </div>
                        )} */}
                        {/* <MultiAxisLineChart
                            fisrtLineChartReportData={fisrtLineChartReportData}
                            secondeLineChartReportData={secondeLineChartReportData}
                            selectFirstUnit={selectFirstUnit}
                            selectSecondUnit={selectSecondUnit}
                        /> */}
                    </div>
                </div>
                <div className="d-flex justify-content-between m-3">
                    <select
                        className="form-input-class"
                        style={{
                            padding: "0px 14px",
                            borderRadius: "4px",
                            height: "40px",
                        }}
                        id="selectedClass"
                        // value={selectFirstUnit}
                        // onChange={(e) => setSelectFirstUnit(e.target.value)}
                        required
                    >
                        <option value="none" selected disabled hidden>
                            Select Unit
                        </option>
                    </select>

                    <select
                        className="form-input-class"
                        style={{
                            padding: "0px 14px",
                            borderRadius: "4px",
                            height: "40px",
                        }}
                        id="selectedClass"
                        // value={selectSecondUnit}
                        // onChange={(e) => setSelectSecondUnit(e.target.value)}
                        required
                    >
                        <option value="none" selected disabled hidden>
                            Select Unit
                        </option>
                    </select>
                </div>
                <ReportTableListData />
            </Box>
        </Grid>
    )
}

export default ReportData;