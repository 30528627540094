import React from 'react'
import './button.css'

export const Button = ({ ...props }) => {
  // Returning a JSX element which is a button with any props passed to the component using the spread operator
  // The text content of the button is specified by the 'title' prop
  return (
    <button {...props} className={`${props.classname}`}>
        {props.title}
    </button>
  )
}

