import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { images } from "../../../config";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useState } from "react";
import PaymentMethod from "./PaymentMethod";
import BillingDetails from "./BillingDetails";
import { useSelector } from "react-redux";
import { getSubscribeDeviceLicense, getSubscription } from "./service";
import { useEffect } from "react";
import dayjs from "dayjs";
import DynamicShimmer from "../../../components/Shimmer/SubscriptionShimmer/ScriptionlistShimmer";

const Subscription = () => {
  const [activeClass, setActiveClass] = useState("subscription");
  const [getSubscriptionData, setSubscriptionData] = useState([]);

  const [filter, setFilter] = useState("");
  const { companyId } = useSelector((state) => state.userState);
  const [isShimmerSubscriptionData, setisShimmerSubscriptionData] =
    useState(false);

  const navigate = useNavigate();

  const param = useParams();
  console.log("param", param);

  let defaultFilter = "";
  if (param.id === "1" && param.moduleid === "1") {
    defaultFilter = "";
  } else if (param.id === "1") {
    defaultFilter = "1";
  } else if (param.id === "2") {
    defaultFilter = "2";
  } else if (param.id === "3") {
    defaultFilter = "3";
  }


  console.log("defaultFilter",defaultFilter,filter)
  const colorArray = [
    {
      name: "Active",
      background: "#E2FFEC",
      boxColor: "active-license",
    },
    {
      name: "Expiring Soon",
      background: "#FFF8E6",
      boxColor: "expiring-license",
    },
    {
      name: "Expired",
      background: "#FFEBEB",
      boxColor: "red-license",
    },

    {
      name: "Not Activated",
      background: "#EEEEEE",
      boxColor: "free-license",
    },
  ];

  const handlePayment = () => {
    setActiveClass("payment");
    navigate(`/payment`);
  };

  const handleBillingdetails = () => {
    navigate(`/billing-details/${param.title}/${param.id}`);
  };

  const handleVisibiltyIcon = (e) => {
    navigate(
      `/editsubscription/${param.title}/${param.id}/subscription/${e.subscription_id}`
    );
  };

  useEffect(() => {
    handlegetSubscriptionData();
  }, [filter]);

  const handlegetSubscriptionData = async () => {
    setisShimmerSubscriptionData(true);
    const params = {
      company: companyId,
    };
    if (filter || defaultFilter) {
      params.product = filter || defaultFilter;
    }
    try {
      const resp = await getSubscription(params);
      if (resp.status == 200 || resp.status == 201) {
        setTimeout(() => {
          setisShimmerSubscriptionData(false);
        }, 1000);
        setSubscriptionData(resp.data.payload);
      }
    } catch (error) {
      setisShimmerSubscriptionData(false);
      console.log("handleSubmit", error);
    }
  };

  const handleNavigate = () => {
    // if (param.title == "smartag") {
    //   navigate(`/smartTagServices/${param.title}/${param.id}`);
    // } else {
    //   navigate(`/hikemmservices/${param.title}/${param.id}`);
    // }
    navigate(-1);
  };
  return (
    <>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="subscriptionTitle-wrap d-flex justify-content-between">
            {activeClass == "subscription" ? (
              <>
                <div
                  // onClick={() => navigate(`/hikemmservices/${param.title}/${param.id}`)}
                  onClick={handleNavigate}
                  className="d-flex"
                >
                  <KeyboardBackspaceIcon className="backArrow" />
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      marginTop: "-4px",
                      paddingLeft: "18px",
                      color: "#001323",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                  >
                    My Subscription
                  </Typography>
                </div>
                <div>
                  <select
                    className="form-input-class"
                    id="selectedClass"
                    style={{
                      height: "45px",
                      marginRight: "10px",
                      padding: "4px 11px",
                      borderRadius: "4px",
                    }}
                    value={filter || defaultFilter}
                    onChange={(event) => setFilter(event.target.value)}
                    required
                  >
                    <option value="">All</option>
                    <option value="1">Hikemm</option>
                    <option value="2">PredHik</option>
                    <option value="3">SmartTag</option>
                  </select>
                  <button className="subscription-btn" onClick={handleNavigate}>
                    Add Subscription
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          {activeClass == "subscription" ? (
            <>
              <div className="pt-3">
                <div>
                  <button
                    className={`${
                      activeClass == "subscription"
                        ? "subscription-button-dark"
                        : "subscription-button"
                    }`}
                    onClick={() => setActiveClass("subscription")}
                  >
                    {activeClass == "subscription" ? (
                      <img src={images.userDark} alt="" />
                    ) : (
                      <img src={images.userLight} alt="" />
                    )}{" "}
                    Subscription
                  </button>

                  <button
                    className={`${
                      activeClass == "payment"
                        ? "payment-button-dark"
                        : "payment-button"
                    }`}
                    onClick={handlePayment}
                  >
                    <img src={images.userLight} alt="" /> Payment Method
                  </button>
                  <button
                    className="payment-button"
                    onClick={handleBillingdetails}
                  >
                    <img src={images.userLight} alt="" /> Billing Details
                  </button>
                  <button className="invoice-button">
                    <img src={images.userLight} alt="" /> Invoice
                  </button>
                </div>
                <div className="subscription-table ">
                  <Table className="subscriptionTable">
                    <TableHead className="submachine-title">
                      <TableCell style={{ fontSize: "18px" }}>
                        <b>License Type</b>
                      </TableCell>
                      <TableCell style={{ fontSize: "18px" }}>
                        <b>Subscription ID</b>
                      </TableCell>
                      <TableCell style={{ fontSize: "18px" }}>
                        <b>Term</b>
                      </TableCell>
                      <TableCell style={{ fontSize: "18px" }}>
                        <b>No. Of License</b>
                      </TableCell>
                      <TableCell style={{ fontSize: "18px" }}>
                        <b>Next Billing Date</b>
                      </TableCell>
                      <TableCell style={{ fontSize: "18px" }}>
                        <b>Next Payment</b>
                      </TableCell>
                      <TableCell style={{ fontSize: "18px" }}>
                        <b>Transaction Status</b>
                      </TableCell>
                      <TableCell style={{ fontSize: "18px" }}>
                        {/* <b>No. Of License</b> */}
                      </TableCell>
                    </TableHead>

                    <TableBody className="table-body-spacing">
                      {/* <TableRow style={{ height: "12px" }}></TableRow> */}
                      {!isShimmerSubscriptionData ? (
                        getSubscriptionData.length ? (
                          getSubscriptionData?.map((e) => {
                            const findArray = colorArray.find(
                              (f) => f.name == e.subscription_status
                            );

                            return (
                              <>
                                <TableRow
                                  style={{
                                    height: "60px",
                                    background:
                                      e.transaction_status == "InProgress"
                                        ? "#ddc98e"
                                        : findArray
                                        ? findArray.background
                                        : "",
                                  }}
                                >
                                  <TableCell>
                                    <div className="d-flex">
                                      <div
                                        className={
                                          e.transaction_status == "InProgress"
                                            ? "inprogress-license"
                                            : findArray
                                            ? findArray.boxColor
                                            : ""
                                        }
                                      ></div>
                                      <div style={{ marginLeft: "12px" }}>
                                        {e.dashboard_name}
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell>{e.subscription_id}</TableCell>
                                  <TableCell>{e.payment_period}</TableCell>

                                  <TableCell>
                                    {e.next_device_to_purchase}
                                  </TableCell>
                                  <TableCell>
                                    {e.next_billing_date
                                      ? dayjs(e.next_billing_date).format(
                                          "YYYY-MM-DD"
                                        )
                                      : "-"}
                                  </TableCell>
                                  <TableCell>{e.next_billing_amount}</TableCell>
                                  <TableCell>
                                    {e.transaction_id !== null
                                      ? e.transaction_status === "captured"
                                        ? "Successful"
                                        : e.transaction_status
                                      : "Offline Payment"}
                                  </TableCell>

                                  <TableCell>
                                    <div
                                      onClick={() => handleVisibiltyIcon(e)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <VisibilityIcon />
                                    </div>
                                  </TableCell>
                                </TableRow>
                                <TableRow style={{ height: "12px" }}></TableRow>
                              </>
                            );
                          })
                        ) : (
                          []
                        )
                      ) : (
                        <TableRow>
                          {" "}
                          <TableCell colSpan={8}>
                            <DynamicShimmer
                              count={9}
                              blocks={[
                                "medium",
                                "medium",
                                "medium",
                                "medium",
                                "medium",
                                "medium",
                                "medium",
                                "medium",
                              ]}
                            />{" "}
                          </TableCell>
                        </TableRow>
                      )}

                      {/* <TableRow
                        style={{ height: "60px", background: "#FFEBEB" }}
                      >
                        <TableCell>
                          <div className="d-flex">
                            <div className="red-license"></div>
                            <div style={{ marginLeft: "12px" }}>Device 1</div>
                          </div>
                        </TableCell>
                        <TableCell>DEASFS45698563258</TableCell>
                        <TableCell>Yearly</TableCell>
                        <TableCell>200</TableCell>
                        <TableCell>31/03/2023</TableCell>
                        <TableCell>31/03/2023</TableCell>
                        <TableCell>
                          <div
                            onClick={handleVisibiltyIcon}
                            style={{ cursor: "pointer" }}
                          >
                            <VisibilityIcon />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ height: "12px" }}></TableRow>
                      <TableRow
                        style={{ height: "60px", background: "#E2FFEC" }}
                      >
                        <TableCell>
                          <div className="d-flex">
                            <div className="red-license"></div>
                            <div style={{ marginLeft: "12px" }}>Device 1</div>
                          </div>
                        </TableCell>
                        <TableCell>DEASFS45698563258</TableCell>
                        <TableCell>Yearly</TableCell>
                        <TableCell>200</TableCell>
                        <TableCell>31/03/2023</TableCell>
                        <TableCell>31/03/2023</TableCell>
                        <TableCell>
                          <VisibilityIcon />
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ height: "12px" }}></TableRow>
                      <TableRow
                        style={{ height: "60px", background: "#FFF8E6" }}
                      >
                        <TableCell>
                          <div className="d-flex">
                            <div className="red-license"></div>
                            <div style={{ marginLeft: "12px" }}>Device 1</div>
                          </div>
                        </TableCell>
                        <TableCell>DEASFS45698563258</TableCell>
                        <TableCell>Yearly</TableCell>
                        <TableCell>200</TableCell>
                        <TableCell>31/03/2023</TableCell>
                        <TableCell>31/03/2023</TableCell>
                        <TableCell>
                          <VisibilityIcon />
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ height: "12px" }}></TableRow>
                      <TableRow
                        style={{ height: "60px", background: "#EEEEEE" }}
                      >
                        <TableCell>
                          <div className="d-flex">
                            <div className="red-license"></div>
                            <div style={{ marginLeft: "12px" }}>Device 1</div>
                          </div>
                        </TableCell>
                        <TableCell>DEASFS45698563258</TableCell>
                        <TableCell>Yearly</TableCell>
                        <TableCell>200</TableCell>
                        <TableCell>31/03/2023</TableCell>
                        <TableCell>31/03/2023</TableCell>
                        <TableCell>
                          <VisibilityIcon />
                        </TableCell>
                      </TableRow>
                      <TableRow style={{ height: "12px" }}></TableRow>
                      <TableRow
                        style={{ height: "60px", background: "#EEEEEE" }}
                      >
                        <TableCell>
                          <div className="d-flex">
                            <div className="red-license"></div>
                            <div style={{ marginLeft: "12px" }}>Device 1</div>
                          </div>
                        </TableCell>
                        <TableCell>DEASFS45698563258</TableCell>
                        <TableCell>Yearly</TableCell>
                        <TableCell>200</TableCell>
                        <TableCell>31/03/2023</TableCell>
                        <TableCell>31/03/2023</TableCell>
                        <TableCell>
                          <VisibilityIcon />
                        </TableCell>
                      </TableRow> */}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </>
          ) : activeClass == "payment" ? (
            <PaymentMethod />
          ) : (
            <BillingDetails />
          )}
        </Container>
      </div>
    </>
  );
};
export default Subscription;
