import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

const TableListData = ({ tableData }) => {
    console.log("tableData", tableData);
    return (
        <>
            <div
                style={{
                    overflowX: "auto",
                    overflowY: "auto",
                    maxHeight: "291px",
                }}
            >
                <Table className="source-table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {tableData ? tableData.length ? tableData.map((row) => {
                                return (
                                    <TableCell>{row.tag_name}</TableCell> 
                                )
                            }) : null : null}
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Min</TableCell>
                            {tableData ? tableData.length ? tableData.map((row) => {
                                return (
                                    <TableCell>{row.min_value}</TableCell>
                                )
                            }) : null : null}
                        </TableRow>
                        <TableRow>
                            <TableCell>Max</TableCell>
                            {tableData ? tableData.length ? tableData.map((row) => {
                                return (
                                    <TableCell>{row.max_value}</TableCell>
                                )
                            }) : null : null}
                        </TableRow>
                        <TableRow>
                            <TableCell>Mean</TableCell>
                            {tableData ? tableData.length ? tableData.map((row) => {
                                return (
                                    <TableCell>{row.mean_value}</TableCell>
                                )
                            }) : null : null}
                        </TableRow>
                        <TableRow>
                            <TableCell>Std. Dev</TableCell>
                            {tableData ? tableData.length ? tableData.map((row) => {
                                return (
                                    <TableCell>{row.std_dev_value}</TableCell>
                                )
                            }) : null : null}
                        </TableRow>
                        <TableRow>
                            <TableCell>All Time Min</TableCell>
                            {tableData ? tableData.length ? tableData.map((row) => {
                                return (
                                    <TableCell>{row.all_time_min_value}</TableCell>
                                )
                            }) : null : null}
                        </TableRow>
                        <TableRow>
                            <TableCell>All Time Max</TableCell>
                            {tableData ? tableData.length ? tableData.map((row) => {
                                return (
                                    <TableCell>{row.all_time_max_value}</TableCell>
                                )
                            }) : null : null}
                        </TableRow>
                    </TableBody>
                </Table>
            </div>{" "}
        </>
    )
}

export default TableListData;