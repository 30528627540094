import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
// import { BrowserRouter as Router } from "react-router-dom";
import Dashboard1 from "../container/CompanyDashboard/Dashboard";
import Dashboard from "../container/PlantDashboard/Dashboard";
import UserProfile from "../container/userProfile/UserProfile";
import UploadData from "../container/UploadData/UploadData";
import SuperAdminDashboard from "../container/SuperAdminDashboard/SuperAdminDashboard";
import SubscriptionManagement from "../container/SuperAdminDashboard/SubscriptionManagement/SubscriptionManagement";
import HikemmServices from "../container/SuperAdminDashboard/SubscriptionManagement/HikemmServices.js/HikemmServices";
import DeviceManagement from "../container/SuperAdminDashboard/DeviceManagement/DeviceManagement";
import Configure from "../container/SuperAdminDashboard/DeviceManagement/Configure/Configure";
import Bridge from "../container/SuperAdminDashboard/DeviceManagement/Configure/Bridge/Bridge";
import UserManagement from "../container/SuperAdminDashboard/UserManagement/UserManagement";
import AccessManagement from "../container/SuperAdminDashboard/Access Management/AccessManagement";
import AddAccesssManagementByID from "../container/SuperAdminDashboard/Access Management/AddAccessManagement/AddAccesssManagementByID";
import AddAccessManagement from "../container/SuperAdminDashboard/Access Management/AddAccessManagement/AddAccessManagement";
import ManageDepartment from "../container/SuperAdminDashboard/Access Management/ManageDepartment/ManageDepartment";
import PlantManagement from "../container/SuperAdminDashboard/PlantManagement/PlantManagement";
import CompanyByPlantmanagement from "../container/SuperAdminDashboard/PlantManagement/CompanyByPlantmanagement";
import AlarmManagement from "../container/SuperAdminDashboard/AlarmManagement/AlarmManagement";
import AlarmManagementConfiguration from "../container/SuperAdminDashboard/AlarmManagement/AlarmMangementConfiguration/AlarmManagementConfiguration";
import ReportManagement from "../container/SuperAdminDashboard/ReportManagement/ReportManagement";
import ReportManagementConfiguration from "../container/SuperAdminDashboard/ReportManagement/ReportManagementConfiguration/ReportManagementConfiguration";
import ProcessDashboard from "../container/ProcessDashboard/ProcessDashboard";
import OverView from "../container/ProcessDashboard/OverView/OverView";
import OverViewAnalytics from "../container/ProcessDashboard/OverView/OverViewAnalytics";
import Source from "../container/ProcessDashboard/Source/Source";
import Consumption from "../container/ProcessDashboard/Consumption/Consumption";
// import Analytics from "../container/ProcessDashboard/AdvanceAnalytics.js/Analytics";
import ClientManagement from "../container/SuperAdminDashboard/ClientManagement/ClientManagement";
import Analytics from "../container/ProcessDashboard/AdvanceAnalytics/Analytics";
import AnalyticsProduction from "../container/ProcessDashboard/AdvanceAnalytics/Production";
import Plan from "../container/ProcessDashboard/AdvanceAnalytics/PlanVsActual";
import EnergyCost from "../container/ProcessDashboard/AdvanceAnalytics/EnergyCost";
import CO2 from "../container/ProcessDashboard/AdvanceAnalytics/CO2";
import Alarm from "../container/ProcessDashboard/Alarm/Alarm";
import Configration from "../container/ProcessDashboard/Configration/Configration";
import ConfigrationOverView from "../container/ProcessDashboard/ConfigrationOverView/ConfigrationOverView";
import ServiceCall from "../container/ProcessDashboard/ServiceCall/ServiceCall";
import ConfigrationAnalytics from "../container/ProcessDashboard/AdvanceAnalytics/ConfigrationAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { clearUserDetails } from "../container/Login/slice/userSlice";
import ConfigrationSource from "../container/ProcessDashboard/Source/ConfigrationSource";
import ConfigrationConsumption from "../container/ProcessDashboard/Consumption/ConfigrationConsumption";
import SourceAlarm from "../container/ProcessDashboard/Alarm/SourceAlarm";
import ConsumptionAlarm from "../container/ProcessDashboard/Alarm/ConsumptionAlarm";
import OverviewAlarm from "../container/ProcessDashboard/Alarm/OverviewAlarm";
import CubeJsApiWrapper from "../container/Cubejs/CubejsApiWrapper";
import { clearProcessDashboarData } from "../container/ProcessDashboard/Consumption/slice/processDashboardSlice";
import { clearProcessDashboarDataSet } from "../container/ProcessDashboard/sliceProcessDashboard/dateSliceprocessDashboard";
import { clearOverviewData } from "../container/ProcessDashboard/OverView/Slice/OverViewAnalyticsSlice";
import ZoomInChart from "../container/ProcessDashboard/OverView/ZoomInEnergyBarChart";
import ZoomInPOPChart from "../container/ProcessDashboard/OverView/ZoomInEnergyBarChartPOP";
import CompanyByUserManagement from "../container/SuperAdminDashboard/UserManagement/CompanyByUserManagement";
import HikemPopUP from "../container/HikEmmPopup/HikemmPopup";
import { clearCompanyDetails } from "../container/SuperAdminDashboard/Slice";
import Subscription from "../container/SuperAdminDashboard/SubscriptionManagement/MySubscription";
import PaymentMethod from "../container/SuperAdminDashboard/SubscriptionManagement/PaymentMethod";
import EditSubscription from "../container/SuperAdminDashboard/SubscriptionManagement/EditSubscription";
import BillingDetails from "../container/SuperAdminDashboard/SubscriptionManagement/BillingDetails";
import VmsDeviceManagement from "../container/VMS/VmsDeviceManagement/VmsDeviceManagement";

//VMS

import VmsCompanyDashboard from "../container/VMS/VmsCompanyDashboard/VmsCompanyDashboard";
import VmsPlantDashboard from "../container/VMS/VmsCompanyDashboard/VmsPlantDashboard/VmsPlantDashboard";
import VmsMachine from "../container/VMS/VmsCompanyDashboard/VmsMachine/VmsMachine";
import SubMachine from "../container/VMS/VmsCompanyDashboard/VmsMachine/SubMachine";
import VmsOverView from "../container/VMS/VmsCompanyDashboard/VmsMachine/OverView/OverView";
import VmsAdvanceAnalytics from "../container/VMS/VmsCompanyDashboard/VmsMachine/Advance Analytics/AdvanceAnalytics";
import VmsReports from "../container/VMS/VmsCompanyDashboard/VmsMachine/Reports/VmsReports";
import Availability from "../container/VMS/VmsCompanyDashboard/VmsMachine/Advance Analytics/Availability/Availability";
import Production from "../container/VMS/VmsCompanyDashboard/VmsMachine/Advance Analytics/Production/Production";

//SmartTag

import Smarttag_Plant_Dashboard from "../container/SmartTag/Dashboard/PlantDashboard/Smarttag_Plant_Dashboard";
import SmartTag_Dashboard from "../container/SmartTag/Dashboard/SmartTag_Dashboard";
import SmartTagMachineDashboard from "../container/SmartTag/MachineDashboard/MachineDashboard";
import MachineReports from "../container/SmartTag/MachineDashboard/Reports/MachineReport";

// Un know Routes
import { UnknowRoutes } from "../components/UnknowRoutes";
import SmartTag from "../container/SuperAdminDashboard/SubscriptionManagement/SmarTag/SmarTag";
import MachineAnalytics from "../container/SmartTag/MachineDashboard/Analytics/MachineAnalytics";
import SmartTagOverview from "../container/SmartTag/MachineDashboard/Overview/OverView";
import CompactDeviceTag from "../container/SmartTag/MachineDashboard/Overview/CompactDeviceTag";
import ChangePassword from "../container/ChangePassword/ChangePassoword";

const AuthenticationRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { is_redirect, is_super_admin } = useSelector(
    (state) => state.userState
  );

  useEffect(() => {
    const end = location.pathname.indexOf("/", 1);
    const dynamicPart = location.pathname.substring(0, end);
    if (dynamicPart == "/redirect") {
      console.log("redirect");
      if (!is_redirect) {
        dispatch(clearUserDetails());
        dispatch(clearCompanyDetails());
        dispatch(clearProcessDashboarData());
        dispatch(clearProcessDashboarDataSet());
        dispatch(clearOverviewData());
      }
    }

    if (dynamicPart == "/create-password") {
      console.log("redirect43434");
      if (!is_redirect) {
        dispatch(clearUserDetails());
        dispatch(clearCompanyDetails());
        dispatch(clearProcessDashboarData());
        dispatch(clearProcessDashboarDataSet());
        dispatch(clearOverviewData());
        // window.location.reload(false)
      }
    }
  }, []);

  return (
    <>
      <CubeJsApiWrapper>
        <Routes>
        <Route path="/" element={<HikemPopUP />} />
          <Route exact path="/dashboard" element={<Dashboard1 />} />
          <Route path="/companyDashboard/plant/:id" element={<Dashboard />} />
          <Route path="/userprofile" element={<UserProfile />} />
          <Route
            path={is_super_admin ? "/superadmindashboard" : "/admindashboard"}
            element={<SuperAdminDashboard />}
          />
          <Route
            path="/uploadManualData"
            element={<UploadData />}
          />
          <Route
            path="/subscriptionManagement"
            element={<SubscriptionManagement />}
          />
          <Route path="/subscription/:title/:id" element={<Subscription />} />
          <Route
            path="/subscription/:title/:id/:moduleid"
            element={<Subscription />}
          />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/smartTagServices/:title/:id" element={<SmartTag />} />
          <Route path="/payment/:title/:id" element={<PaymentMethod />} />
          <Route path="/payment/:title/:id/:subId" element={<PaymentMethod />} />
          <Route path="/payment" element={<PaymentMethod />} />
          <Route
            path="/billing-details/:title/:id"
            element={<BillingDetails />}
          />
          <Route
            path="/editsubscription/:title/:id/subscription/:subId"
            element={<EditSubscription />}
          />
          <Route
            path="/hikemmservices/:title/:id"
            element={<HikemmServices />}
          />
          <Route path="/deviceManagement" element={<DeviceManagement />} />
          {/* <Route path="/deviceManagement" element={<VmsDeviceManagement />} /> */}
          <Route
            path="/deviceManagement/configure/device/:id"
            element={<Configure />}
          />
          <Route
            path="/deviceManagement/configure/machine/:id"
            element={<Configure />}
          />
          <Route
            path="/deviceManagement/configure/device/bridge/:id"
            element={<Bridge />}
          />
           <Route
            path="/:plantmanagement/deviceManagement/configure/machine/:id"
            element={<Configure />}
          />
          <Route
            path="/deviceManagement/configure/device/machine/:machineId/bridge/:id"
            element={<Bridge />}
          />
          <Route path="/usermanagement" element={<UserManagement />} />
          <Route path="/accessManagement" element={<AccessManagement />} />
          <Route
            path="/accessManagement/company/:companyId"
            element={<AccessManagement />}
          />
          <Route
            path="/accessManagement/:departmentId"
            element={<AddAccesssManagementByID />}
          />
          <Route
            path="/usermanagement/accessManagement/:view/:departmentId"
            element={<AddAccesssManagementByID />}
          />
          <Route
            path="/usermanagement/accessManagement/company/:companyId/:view/:departmentId"
            element={<AddAccesssManagementByID />}
          />
          <Route
            path="/accessManagement/company/:companyId/department/:departmentId"
            element={<AddAccesssManagementByID />}
          />
          <Route
            path="/addaccessmanagement"
            element={<AddAccessManagement />}
          />
          <Route
            path="/addaccessmanagement/company/:companyId"
            element={<AddAccessManagement />}
          />
          <Route path="/managedepartment" element={<ManageDepartment />} />
          <Route
            path="/acsessmanagement/managedepartment"
            element={<ManageDepartment />}
          />
          <Route
            path="/managedepartment/:companyId"
            element={<ManageDepartment />}
          />
          <Route path="/plantmanagement" element={<PlantManagement />} />
          <Route
            path="/plantmanagement/company/:companyId/plant/:id"
            element={<CompanyByPlantmanagement />}
          />
          <Route
            path="/usermanagement/company/:id"
            element={<CompanyByUserManagement />}
          />
          <Route path="/alarmmanagement" element={<AlarmManagement />} />
          <Route
            path="/alarmmanagement/configuration/company/:companyId/plant/:plantId/group/:groupId"
            element={<AlarmManagementConfiguration />}
          />
          <Route
            path="/alarmmanagement/configuration/:setting/company/:companyId/plant/:plantId/group/:groupId"
            element={<AlarmManagementConfiguration />}
          />
          <Route path="/reportmanagement" element={<ReportManagement />} />
          <Route
            path="/reportmanagement/company/:companyId/plant/:plantId/report/:reportId/configuration"
            element={<ReportManagementConfiguration />}
          />
          <Route
            path="/reportmanagement/company/:companyId/plant/:plantId/report/:reportId/configuration/:setting"
            element={<ReportManagementConfiguration />}
          />
          <Route
            path="/company/:companyId/plant/plantprocessdashboard/:id"
            element={<ProcessDashboard />}
          />
          <Route
            path="/overView/company/:companyId/plant/:id"
            element={<OverView />}
          />
          <Route
            path="/overView-analytics/company/:companyId/plant/:id"
            element={<OverViewAnalytics />}
          />
          <Route
            path="/source/company/:companyId/plant/:id"
            element={<Consumption />}
          />
          <Route
            path="/consumption/company/:companyId/plant/:id"
            element={<Consumption />}
          />
          <Route
            path="/analytics/company/:companyId/plant/:id"
            element={<Analytics />}
          />
          <Route path="/clientmanagement" element={<ClientManagement />} />
          <Route
            path="/analyticsproduction/company/:companyId/plant/:id"
            element={<AnalyticsProduction />}
          />
          <Route
            path="/energycost/company/:companyId/plant/:id"
            element={<EnergyCost />}
          />
          <Route path="/CO2/company/:companyId/plant/:id" element={<CO2 />} />
          <Route
            path="/alarm/company/:companyId/plant/:id"
            element={<Alarm />}
          />
          <Route
            path="/source-alarm/company/:companyId/plant/:id"
            element={<SourceAlarm />}
          />
          <Route
            path="/consumption-alarm/company/:companyId/plant/:id"
            element={<ConsumptionAlarm />}
          />
          <Route
            path="/Company/:companyId/plant/configration/:id"
            element={<Configration />}
          />
          <Route
            path="/overview-configration/company/:companyId/plant/:id"
            element={<ConfigrationOverView />}
          />
          <Route
            path="/servicecall/company/:companyId/plant/:id"
            element={<ServiceCall />}
          />
          <Route
            path="/analytics-configration/company/:companyId/plant/:id"
            element={<ConfigrationAnalytics />}
          />
          <Route
            path="/configration-source/source/company/:companyId/plant/:id"
            element={<ConfigrationConsumption />}
          />
          <Route
            path="/consumption-configration/company/:companyId/plant/:id"
            element={<ConfigrationConsumption />}
          />
          <Route
            path="/overview-alarm/company/:companyId/plant/:id"
            element={<OverviewAlarm />}
          />
          <Route
            path="/overviewanalytics/energybarchart/company/:companyId/plant/:id"
            element={<ZoomInChart />}
          />
          <Route
            path="/overviewanalytics/energybarchartpop/company/:companyId/plant/:id"
            element={<ZoomInPOPChart />}
          />
          <Route path="/select" element={<HikemPopUP />} />
          ====================================================VMS============================================================
          <Route path="/vms" element={<VmsCompanyDashboard />} />
          <Route
            path="/vms-plants/company/:companyId/plant"
            element={<VmsPlantDashboard />}
          />
          <Route
            path="/VmsMachine/company/:companyId/plant/:id"
            element={<VmsMachine />}
          />
          <Route
            path="/submachine/company/:companyId/plant/:plantId/device/:deviceId/machine/:machineId"
            element={<SubMachine />}
          />
          <Route
            path="/vms-overview/company/:companyId/plant/:plantId/device/:deviceId/machine/:machineId"
            element={<VmsOverView />}
          />
          <Route
            path="/vms-advance-analytics/company/:companyId/plant/:plantId/device/:deviceId/machine/:machineId"
            element={<VmsAdvanceAnalytics />}
          />
          <Route
            path="/vms-reports/company/:companyId/plant/:plantId/device/:deviceId/machine/:machineId"
            element={<VmsReports />}
          />
          <Route
            path="/vms-advance-analytics/avilibility/company/:companyId/plant/:plantId/device/:deviceId/machine/:machineId"
            element={<Availability />}
          />
          <Route
            path="/vms-advance-analytics/production/company/:companyId/plant/:plantId/device/:deviceId/machine/:machineId"
            element={<Production />}
          />
          =================================================SmartTag==========================================================================
          <Route path="/Smarttag-Dashboard/" element={<SmartTag_Dashboard />} />
          <Route
            path="/Smarttag-companyDashboard/company/:companyid"
            element={<Smarttag_Plant_Dashboard />}
          />
          <Route
            path="/Smarttag-machinedashboard/company/:companyid/plant/:plantid"
            element={<SmartTagMachineDashboard />}
          />
          <Route
            path="/Smarttag-machinedashboard/company/:companyid/plant/:plantid/gateway/:gatewayid"
            element={<SmartTagMachineDashboard />}
          />
          <Route
            path="/Smarttag-machinereports/company/:companyid/plant/:plantid/device/:deviceId"
            element={<MachineReports />}
          />
          <Route
            path="/Smarttag-machinereports/company/:companyid/plant/:plantid/report"
            element={<MachineReports />}
          />
          <Route
            path="/Smarttag-machineanalytics/company/:companyid/plant/:plantid/device/:deviceId"
            element={<MachineAnalytics />}
          />
          <Route
            path="/Smarttag-machineanalytics/company/:companyid/plant/:plantid/analytics"
            element={<MachineAnalytics />}
          />
          <Route
            path="/smarttag-machineoverview/company/:companyid/plant/:plantid"
            element={<SmartTagOverview />}
          />
          <Route
            path="/smarttag-device-tag/company/:companyid/plant/:plantid/gateway/:gatewayid/machine/:machineid"
            element={<CompactDeviceTag />}
          />
   
          <Route path="*" element={<UnknowRoutes />} />
        </Routes>
      </CubeJsApiWrapper>
    </>
  );
};

export default AuthenticationRoutes;
