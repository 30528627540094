import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    }
  }
};

const labels = ['1 Min', '2 Min', '3 Min', '4 Min', '5 Min', '6 Min'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [10, 20, 30, 40, 50, 60],
      borderColor: '#C87171',
      backgroundColor: '#C87171',
    },
    {
      label: 'Dataset 2',
      data: [15, 20, 25, 30, 35, 40],
      borderColor: '#7BCBC1',
      backgroundColor: '#7BCBC1',
    },
    {
      label: 'Dataset 3',
      data: [20, 20, 35, 30, 35, 40],
      borderColor: '#9A64B2',
      backgroundColor: '#9A64B2',
    },
  ],
};

export function OverViewLineChart() {
  return (
    <>
      <div style={{ height: "300px", width: "100%",padding:"20px" }}>
        <Line options={options} data={data} />
      </div>
    </>
  )
}
