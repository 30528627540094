import React from 'react';
import './DeviceListShimmer.css';
import { Grid } from '@material-ui/core';

const DeviceListShimmer = ({ itemCount }) => {
  return (
    
    <div className="device-list-shimmer">
      {Array.from({ length: itemCount }, (_, index) => (
        <div key={index} className="device-item-shimmer">
          <div className="device-text-shimmer"></div>
          <div className="device-status-shimmer"></div>
        </div>
      ))}
    </div>
    
  );
};

export default DeviceListShimmer;
