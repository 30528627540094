import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import "./FooterText.css";
import SubscriptionFooter from "../../container/SuperAdminDashboard/SubscriptionManagement/SubscriptionFooter";
import { useLocation } from "react-router-dom";

function FooterText() {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const footerRef = useRef(null);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (footerRef.current && !footerRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <>
      {location.pathname.split("/")[1] == "subscription" ? (
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{
            backgroundColor: "#F1F1FF",
            color: "#4D5963",
            textAlign: "end",
            margin: "0",
            position: "fixed",
            bottom: "0px",
            right: "0",
            padding: "1px",
            width: "100%",
            zIndex: "99",
            paddingRight: "23px",
            justifyContent: "space-between",
            display: "flex",
          }}
          className="exclude-me"
        >
          <SubscriptionFooter />
          <div ref={footerRef} className="footer-text-title">
          {!isVisible ? (
            <span
              onClick={() => setIsVisible(true)}
              style={{ cursor: 'pointer' }}
            >
              ©
            </span>
          ) : (
            <>
              Powered by{" "}
              <b style={{ fontWeight: "900" }}>Hikar®</b>. All Rights Reserved
            </>
          )}
        </div>
        </Typography>
      ) : (
        <Typography
        variant="subtitle2"
        gutterBottom
        style={{
          backgroundColor: "#F1F1FF",
          color: "#4D5963",
          textAlign: "end",
          margin: "0",
          position: "fixed",
          bottom: "0px",
          right: "0",
          padding: "1px",
          width: "100%",
          zIndex: "99",
          paddingRight: "23px",
        }}
        className="exclude-me"
      >
        <div ref={footerRef} className="footer-text-title">
          {!isVisible ? (
            <span
              onClick={() => setIsVisible(true)}
              style={{ cursor: 'pointer' }}
            >
              ©
            </span>
          ) : (
            <>
              Powered by{" "}
              <b style={{ fontWeight: "900" }}>Hikar®</b>. All Rights Reserved
            </>
          )}
        </div>
      </Typography>
      )}
      {location.pathname.split("/")[1] == "editsubscription" ? (
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{
            backgroundColor: "#F1F1FF",
            color: "#4D5963",
            textAlign: "end",
            margin: "0",
            position: "fixed",
            bottom: "0px",
            right: "0",
            padding: "1px",
            width: "100%",
            zIndex: "99",
            paddingRight: "23px",
            justifyContent: "space-between",
            display: "flex",
          }}
           className="exclude-me"
        >
          <div className="sub-footertext">
            <div className="active-plan"></div> Assigned
            <div className="not-activated-plan"></div> Unassigned
          </div>
          <div ref={footerRef} className="footer-text-title">
          {!isVisible ? (
            <span
              onClick={() => setIsVisible(true)}
              style={{ cursor: 'pointer' }}
            >
              ©
            </span>
          ) : (
            <>
               Powered by{" "}
              <b style={{ fontWeight: "900" }}>Hikar®</b>. All Rights Reserved
            </>
          )}
        </div>
        </Typography>
      ) : null}
    </>
  );
}

export default FooterText;
