import { Box, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker,
  LocalizationProvider,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import React from "react";

const POPVmsDatePicker = ({
  time,
  startDateReFormat,
  endDateReFormat,
  handleStartDate,
  handleEndDate,
  handlePOP1StartDate,
  handlePOP1EndDate,
  startDate1ReFormat,
  endDate1ReFormat,
  access,
  handleTostMsg,
}) => {
  return (
    <>
      {time ? (
        <>
          <Box
            style={{ width: "20%", marginRight: "8px" }}
            className=""
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Start Date"
                className="custom-datepicker datepicker-color"
                value={startDateReFormat}
                disableFuture
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                onChange={(newValue) => handleStartDate(newValue)}
              />
            </LocalizationProvider>
          </Box>
          <Box
            className=""
            style={{ width: "20%", marginRight: "8px" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="End Date"
                className="custom-datepicker datepicker-color"
                value={endDateReFormat}
                onChange={(newValue) => handleEndDate(newValue)}
                disableFuture
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box
            style={{ width: "20%", marginRight: "8px" }}
            className=""
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Start Date"
                className="custom-datepicker second-datepicker-color"
                value={startDate1ReFormat}
                onChange={(newValue) => handlePOP1StartDate(newValue)}
                disableFuture
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box
            className=""
            style={{ width: "20%", marginRight: "8px" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="End Date"
                className="custom-datepicker second-datepicker-color"
                value={endDate1ReFormat}
                onChange={(newValue) => handlePOP1EndDate(newValue)}
                disableFuture
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </LocalizationProvider>
          </Box>
        </>
      ) : (
        <>
          <>
            <Box
              style={{ width: "20%", marginRight: "8px" }}
              className=""
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="custom-datepicker datepicker-color"
                  label="Start Date"
                  value={startDateReFormat}
                  inputFormat="DD/MM/YYYY"
                  onChange={(newValue) => handleStartDate(newValue)}
                  disableFuture
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ background: "#ffff" }}
                      InputLabelProps={{
                        style: {
                          color: "#C0255F",
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box
              className=""
              style={{ width: "20%", marginRight: "8px" }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="custom-datepicker datepicker-color"
                  label="End Date"
                  value={endDateReFormat}
                  inputFormat="DD/MM/YYYY"
                  onChange={(newValue) => handleEndDate(newValue)}
                  disableFuture
                  renderInput={(params) => (
                    <TextField {...params} style={{ background: "#ffff" }} />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Box
              style={{ width: "20%", marginRight: "8px" }}
              className=""
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="custom-datepicker second-datepicker-color"
                  label="Start Date"
                  value={startDate1ReFormat}
                  inputFormat="DD/MM/YYYY"
                  onChange={(newValue) => handlePOP1StartDate(newValue)}
                  disableFuture
                  renderInput={(params) => (
                    <TextField {...params} style={{ background: "#ffff" }} />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box
              className=""
              style={{ width: "20%", marginRight: "8px" }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="custom-datepicker second-datepicker-color"
                  label="End Date"
                  value={endDate1ReFormat}
                  inputFormat="DD/MM/YYYY"
                  onChange={(newValue) => handlePOP1EndDate(newValue)}
                  disableFuture
                  renderInput={(params) => (
                    <TextField {...params} style={{ background: "#ffff" }} />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </>
        </>
      )}
    </>
  );
};

export default POPVmsDatePicker;
