import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, FormControlLabel, FormGroup, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import GraphComponent from "../../Reports/AnalyticsGraphComponent/GraphComponent";
import ReportData from "./ReportData";
import { useNavigate, useParams } from "react-router-dom";
import VmsCOCData from "../../../../../../components/VmsCOCData/VmsCOCData";
import COCDatePicker from "../../../../../../components/COCDatepicker/COCDatePicker";
import POPDatePicker from "../../../../../../components/POPDatePicker/POPDatePicker";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { setVmsData } from "../../../vmsSlice/vmsSlice";
import COCVmsDatePicker from "../../../../VMSComponent/COCVmsDatePicker";
import POPVmsDatePicker from "../../../../VMSComponent/POPVmsDatePicker";
import { ToastContainer, toast } from "react-toastify";

const Production = () => {
  const [activeClass, setActiveClass] = useState("Analytics");
  const [activeTabClass, setActiveTabClass] = useState("Consumer");
  const { user_access } = useSelector((state) => state.userState);
  const [COCDeviceData, setCOCDeviceData] = useState([
    {
      device__device_name: "hikar",
    },
  ]);
  const [COCTagUnit, setCOCTagUnit] = useState([
    {
      tag_unit: "kWh",
    },
    {
      tag_unit: "kWh",
    },
    {
      tag_unit: "kWh",
    },
    {
      tag_unit: "kWh",
    },
  ]);
  const [POPDeviceData, setPOPDeviceData] = useState([
    {
      device__device_name: "hikar Tech",
    },
    {
      device__device_name: "hikar Tech",
    },
    {
      device__device_name: "hikar Tech",
    },
  ]);
  const [POPTagUnit, setPOPTagUnit] = useState([
    {
      tag_unit: "kWh",
    },
    {
      tag_unit: "kWh",
    },
    {
      tag_unit: "kWh",
    },
    {
      tag_unit: "kWh",
    },
  ]);
  const {
    vmsProductionCOCstartDate,
    vmsProductionCOCendDate,
    vmsProductionPOPstartDate,
    vmsProductionPOPendDate,
    vmsProductionPOP1startDate,
    vmsProductionPOP1endDate,
    vmsProductionTime,
    vmsProductionPOPTime,
  } = useSelector((state) => state.vmsState);
  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useDispatch();

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const startDateReFormat = vmsProductionCOCstartDate
    ? dayjs(vmsProductionCOCstartDate)
    : null;
  const endDateReFormat = vmsProductionCOCendDate
    ? dayjs(vmsProductionCOCendDate)
    : null;

  const startDatePOPFormat = vmsProductionPOPstartDate
    ? dayjs(vmsProductionPOPstartDate)
    : null;
  const endDatePOPFormat = vmsProductionPOPendDate
    ? dayjs(vmsProductionPOPendDate)
    : null;

  const startDatePOP1Format = vmsProductionPOP1startDate
    ? dayjs(vmsProductionPOP1startDate)
    : null;
  const endDatePOP1Format = vmsProductionPOP1endDate
    ? dayjs(vmsProductionPOP1endDate)
    : null;

  const handleStartDate = (newValue) => {
    dispatch(setVmsData({ vmsProductionCOCstartDate: newValue }));
  };

  const handleEndDate = (newValue) => {
    dispatch(setVmsData({ vmsProductionCOCendDate: newValue }));
  };
  const handleToggleTime = () => {
    dispatch(setVmsData({ vmsProductionTime: !vmsProductionTime }));
  };

  const handlePOPStartDate = (newValue) => {
    dispatch(setVmsData({ vmsProductionPOPstartDate: newValue }));
  };

  const handlePOPEndDate = (newValue) => {
    dispatch(setVmsData({ vmsProductionPOPendDate: newValue }));
  };

  const handlePOPToggleTime = () => {
    dispatch(setVmsData({ vmsProductionPOPTime: !vmsProductionPOPTime }));
  };

  const handlePOP1StartDate = (newValue) => {
    dispatch(setVmsData({ vmsProductionPOP1startDate: newValue }));
  };

  const handlePOP1EndDate = (newValue) => {
    dispatch(setVmsData({ vmsProductionPOP1endDate: newValue }));
  };
  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;
  const analyticsAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Advance Analytics"
        )
      : null
    : null;

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };
  return (
    <>
      <div className="page-wraper">
        <div className="page-header page-header-analytics">
          {activeClass === "Analytics" ? (
            <div
              className="page-back-btn"
              onClick={() =>
                navigate(
                  `/vms-advance-analytics/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`
                )
              }
            >
              <ArrowBackIcon />
              <span>Machine - 1 - Reports - Analytics</span>
            </div>
          ) : (
            <div
              className="page-back-btn"
              onClick={() =>
                navigate(
                  `/vms-advance-analytics/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`
                )
              }
            >
              <ArrowBackIcon />
              <span>Overview - Report </span>
            </div>
          )}
          <div className="d-flex">
            <button
              className={`${
                activeClass == "Analytics" ? "Analytics-tabs" : "Reports-tabs"
              }`}
              onClick={() => setActiveClass("Analytics")}
            >
              Analytics
            </button>
            <button
              className={`${
                activeClass == "Reports" ? "Analytics-tabs" : "Reports-tabs"
              }`}
              onClick={() => setActiveClass("Reports")}
            >
              Reports
            </button>
          </div>
        </div>
        {activeClass == "Analytics" ? (
          <>
            <div className="analysis-tabs-btn pt-3">
              <div className="analytics-tabs-btn">
                <div className="d-flex">
                  <button
                    className={`${
                      activeTabClass == "Consumer"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    // onClick={() =>
                    //     dispatch(
                    //         setOverviewData({ activeClassConsumer: "Consumer" })
                    //     )
                    // }
                    onClick={() => setActiveTabClass("Consumer")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Consumer over Consumer
                  </button>
                  <button
                    className={`${
                      activeTabClass == "Period"
                        ? "Analytics-tabs-overview"
                        : "Reports-tabs-overview"
                    }`}
                    // onClick={() =>
                    //     dispatch(
                    //         setOverviewData({ activeClassConsumer: "Period" })
                    //     )
                    // }
                    onClick={() => setActiveTabClass("Period")}
                    style={{ width: "210px", height: "50px" }}
                  >
                    Period over Period
                  </button>
                </div>
              </div>
              {activeTabClass == "Consumer" ? (
                <>
                  <div
                    className="analytics-aggregation analytics-datepicker"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <h6 className="AGG-dropdown">Range</h6>
                    <Box
                      sx={{ minWidth: 200 }}
                      style={{ float: "right", marginRight: "43px" }}
                    >
                      {(analyticsAccess?.is_viewer &&
                        analyticsAccess?.is_editor) ||
                      (!analyticsAccess?.is_viewer &&
                        analyticsAccess?.is_editor) ||  (analyticsAccess?.is_viewer && !analyticsAccess?.is_editor) ? (
                        <select
                          className="form-input-class"
                          style={{ padding: "16px 8px", borderRadius: "4px" }}
                          id="selectedClass"
                          // value={selectTimeRange}
                          // onChange={(e) =>
                          //     dispatch(
                          //         setOverviewData({ selectTimeRange: e.target.value })
                          //     )
                          // }
                          required
                        >
                          <option value="15" selected disabled hidden>
                            Select Range
                          </option>
                          <option value="15">2</option>
                          <option value="30">2.5</option>
                          <option value="60">3</option>
                          <option value="1">3.5</option>
                          <option value="7">4</option>
                        </select>
                      ) : (
                        <select
                          className="form-input-class"
                          style={{ padding: "16px 8px", borderRadius: "4px" }}
                          id="selectedClass"
                          // value={selectTimeRange}
                          onChange={handleTostMsg}
                          required
                        >
                          <option value="15" selected disabled hidden>
                            Select Range
                          </option>
                          <option value="15">2</option>
                          <option value="30">2.5</option>
                          <option value="60">3</option>
                          <option value="1">3.5</option>
                          <option value="7">4</option>
                        </select>
                      )}
                    </Box>
                    {(analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (!analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||  (analyticsAccess?.is_viewer && !analyticsAccess?.is_editor)? (
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch
                              color="primary"
                              checked={vmsProductionTime}
                            />
                          }
                          label="Time"
                          labelPlacement="top"
                          onChange={handleToggleTime}
                        />
                      </FormGroup>
                    ) : (
                      <FormGroup aria-label="position" row>
                        <FormControlLabel
                          value="top"
                          control={
                            <Switch
                              color="primary"
                              checked={vmsProductionTime}
                            />
                          }
                          label="Time"
                          labelPlacement="top"
                          onChange={handleTostMsg}
                        />
                      </FormGroup>
                    )}

                    <COCVmsDatePicker
                      handleStartDate={handleStartDate}
                      handleEndDate={handleEndDate}
                      time={vmsProductionTime}
                      startDateReFormat={startDateReFormat}
                      endDateReFormat={endDateReFormat}
                      access={analyticsAccess}
                      handleTostMsg={handleTostMsg}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="analytics-aggregation analytics-datepicker analyticsAgg-datePicker"
                    // style={{ justifyContent: "flex-end" }}
                  >
                    <h6 className="AGG-dropdown" style={{ marginLeft: "20px" }}>
                      Range
                    </h6>
                    <Box
                      sx={{ minWidth: 200 }}
                      style={{ float: "right", marginRight: "69px" }}
                    > {(analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (!analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||  (analyticsAccess?.is_viewer && !analyticsAccess?.is_editor) ? 
                      <select
                        className="form-input-class"
                        style={{ padding: "16px 8px", borderRadius: "4px" }}
                        id="selectedClass"
                        // value={selectPOPTimeRange}
                        // onChange={(event) =>
                        //     dispatch(
                        //         setOverviewData({
                        //             selectPOPTimeRange: event.target.value,
                        //         })
                        //     )
                        // }
                        required
                      >
                        <option value="15" selected disabled hidden>
                          Select Range
                        </option>
                        <option value="15">2</option>
                        <option value="30">2.5</option>
                        <option value="60">3</option>
                        <option value="1">3.5</option>
                        <option value="7">4</option>
                      </select>
                      :<select
                      className="form-input-class"
                      style={{ padding: "16px 8px", borderRadius: "4px" }}
                      id="selectedClass"
                      // value={selectPOPTimeRange}
                      onChange={handleTostMsg}
                      required
                    >
                      <option value="15" selected disabled hidden>
                        Select Range
                      </option>
                      <option value="15">2</option>
                      <option value="30">2.5</option>
                      <option value="60">3</option>
                      <option value="1">3.5</option>
                      <option value="7">4</option>
                    </select>}
                    </Box>
                    {(analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (!analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||  (analyticsAccess?.is_viewer && !analyticsAccess?.is_editor) ? 
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={
                          <Switch
                            color="primary"
                            checked={vmsProductionPOPTime}
                          />
                        }
                        label="Time"
                        labelPlacement="top"
                        onChange={handlePOPToggleTime}
                      />
                    </FormGroup> :
                      <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={
                          <Switch
                            color="primary"
                            checked={vmsProductionPOPTime}
                          />
                        }
                        label="Time"
                        labelPlacement="top"
                        onChange={handleTostMsg}
                      />
                    </FormGroup>
                    
                    }

                    <POPVmsDatePicker
                      time={vmsProductionPOPTime}
                      startDateReFormat={startDatePOPFormat}
                      endDateReFormat={endDatePOPFormat}
                      startDate1ReFormat={startDatePOP1Format}
                      endDate1ReFormat={endDatePOP1Format}
                      handleStartDate={handlePOPStartDate}
                      handleEndDate={handlePOPEndDate}
                      handlePOP1StartDate={handlePOP1StartDate}
                      handlePOP1EndDate={handlePOP1EndDate}
                      access={analyticsAccess}
                      handleTostMsg={handleTostMsg}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="pt-2 consumption-grid-card">
              {activeTabClass == "Consumer" ? (
                <VmsCOCData
                  deviceData={COCDeviceData}
                  deviceUnitData={COCTagUnit}
                  access={analyticsAccess}
                      handleTostMsg={handleTostMsg}
                />
              ) : (
                <VmsCOCData
                  deviceData={POPDeviceData}
                  deviceUnitData={POPTagUnit}
                  access={analyticsAccess}
                      handleTostMsg={handleTostMsg}
                />
              )}

              <GraphComponent activeTabClass={activeTabClass} />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-end pt-3">
              <div
                className="analytics-aggregation analytics-datepicker analyticsAgg-datePicker"
                style={{ justifyContent: "flex-end" }}
              >
                <h6 className="AGG-dropdown">Interval</h6>
                <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                  <select
                    className="form-input-class"
                    style={{
                      padding: "14px 8px",
                      borderRadius: "4px",
                      marginRight: "20px",
                    }}
                    id="selectedClass"
                    // value={selectIntervalReport}
                    // onChange={(e) => setSelectIntervalReport(e.target.value)}
                    required
                  >
                    <option value="1" selected disabled hidden>
                      1 min
                    </option>
                    <option value="1">1 Min</option>
                    <option value="15">15 Min</option>
                    <option value="30">30 Min</option>
                    <option value="60">Hourly</option>
                    <option value="24">Day</option>
                    <option value="7">Weekly</option>
                  </select>
                </Box>

                <h6 className="AGG-dropdown">AGG.</h6>
                <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                  <select
                    className="form-input-class"
                    style={{ padding: "14px 8px", borderRadius: "4px" }}
                    id="selectedClass"
                    // value={selectAggReport}
                    // onChange={(e) => setSelectAggReport(e.target.value)}
                    required
                  >
                    <option value="15" selected disabled hidden>
                      15 Min
                    </option>
                    <option value="15">15 Min</option>
                    <option value="30">30 Min</option>
                    <option value="60">Hourly</option>
                    <option value="1">Day</option>
                    <option value="7">Weekly</option>
                  </select>
                </Box>

                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="top"
                    control={<Switch color="primary" />}
                    label="Time"
                    labelPlacement="top"
                    // onChange={() => setShowTimeReport(!showTimeReport)}
                  />
                </FormGroup>

                {true ? (
                  <>
                    <Box style={{ width: "32%" }} className="">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="Start Date"
                          // value={startDateRep}
                          // onChange={(newValue) => setStartDateRep(newValue)}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          className="custom-datepicker"
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box className="" style={{ width: "30%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="End Date"
                          // value={endDateRep}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          // onChange={(newValue) => setEndDateRep(newValue)}
                          className="custom-datepicker"
                        />
                      </LocalizationProvider>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box style={{ width: "32%" }} className="">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="custom-datepicker"
                          label="Start Date"
                          // value={startDateRep}
                          inputFormat="DD/MM/YYYY"
                          // onChange={(newValue) => {
                          //     setStartDateRep(newValue);
                          // }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box className="" style={{ width: "30%" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="custom-datepicker"
                          label="End Date"
                          // value={endDateRep}
                          inputFormat="DD/MM/YYYY"
                          // onChange={(newValue) => {
                          //     setEndDateRep(newValue);
                          // }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </>
                )}
              </div>
            </div>
            <div className="pt-2 consumption-grid-card">
              <VmsCOCData
                deviceData={COCDeviceData}
                deviceUnitData={COCTagUnit}
              />
              <ReportData />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Production;
