import { Box, Card, Grid, Typography } from "@mui/material";
import "./ClientManagementShimmer.css";

const ClientManageShimmer = () => {
	return (
		<Card
        className="cardCompany shimmer-card-company"
        style={{ marginTop: "20px", overflow: "visible" }}
      
      >
        <Grid container className="qqq">
          <Grid item xs={12} sm={12} md={3} lg={5} xl={4}>
            <Box className="companyDetails">
              <Box className="companyTitle">
                <Typography style={{ fontWeight: "500", fontSize: "18px" }}>
				<div class="header-plant-shimmer mb-2"></div>
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{ fontWeight: "00" }}
                >
                   <div class="header-alarm-shimmer"></div>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={1} lg={0} xl={2}>
            <Box className="expandCompanyBorder">
              <div className="invisible ">asjd</div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
            <Box className="clientManagement cardContent1">
              <Typography
                gutterBottom
                className="clientManagement - companyText "
              >
               <div class="shimmer-icon-label"></div>
              </Typography>
              <div className="clientManagement-discription">
                {/* <Typography variant="body1" className='clientManagement companyNumber1'>{cloudData.length ? cloudData.filter(value => value.isSub == true).length : "0"}</Typography> */}
                <Typography
                  variant="body1"
                  className="clientManagement companyNumber1"
                >
                   <div class="shimmer-icon"></div>
                </Typography>
                <Typography
                  variant="caption"
                  className="clientManagement-companySmalltext"
                >
                   <div class="shimmer-icon-label mt-1"></div>
                </Typography>
              </div>
              <Box
                style={{ borderRight: "1px solid #C9CFE7" }}
                paddingRight={2}
              >
                <div className="clientManagement-discription">
                  {/* <Typography variant="body1" className='clientManagement companyNumber2'>{cloudData.length ? cloudData.filter(value => value.isSub == false).length : "0"}</Typography> */}
                  <Typography
                    variant="body1"
                    className="clientManagement companyNumber2"
                  >
                     <div class="shimmer-icon"></div>
                  </Typography>
                  <Typography
                    variant="caption"
                    className="clientManagement-companySmalltext"
                  >
                     <div class="shimmer-icon-label mt-1"></div>
                  </Typography>
                </div>
              </Box>
              <Typography
                gutterBottom
                className="clientManagement companyText "
              >
           <div class="shimmer-icon-label"></div>
              </Typography>
              <div className="clientManagement-discription">
                <Typography
                  variant="body1"
                  className="clientManagement companyNumber1"
                >
                 <div class="shimmer-icon"></div>
                </Typography>
                <Typography
                  variant="caption"
                  className="clientManagement-companySmalltext"
                >
                   <div class="shimmer-icon-label mt-1"></div>
                </Typography>
              </div>
              <Box paddingRight={2}>
                <div className="clientManagement-disc">
                  <div className="clientManagement-discription">
                    <Typography
                      variant="body1"
                      className="clientManagement companyNumber2"
                    >
                      <div class="shimmer-icon"></div>
                    </Typography>
                    <Typography
                      variant="caption"
                      className="clientManagement-companySmalltext"
                    >
                       <div class="shimmer-icon-label mt-1"></div>
                    </Typography>
                  </div>
                  <div>
				  <div class="shimmer-icon-label mt-1"></div>
                  </div>
                  <div>
				  <div class="shimmer-icon-label mt-1" style={{rotate:"89deg",padding:"0px 16px"}}></div>
                  </div>
                </div>
                <div className="clienManagementBorder"></div>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* =============== Update Modal Start =============== */}
  
        {/* =============== Update Modal End =============== */}

    
      
      </Card>
	);
};

export default ClientManageShimmer;
