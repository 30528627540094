import { request, multipartRequest } from "../../api/api"

export const getMachineList = () => {
    return request.get('/device_configuration/device/getMachineList');
}

export const downloadDemoManualCSVFile = () => {
    return request.get('/device_configuration/device/downloadDemoManualCSVFile/');
}

export const uploadManualCSVFile = (data) => {
    return multipartRequest.post(`/device_configuration/device/uploadManualCSVFile/`, data);
}

export const getCSVFileHistory = (page, pageSize) => {
    return request.get(`/device_configuration/device/getUploadedManualCSVFileHistory/?page=${page + 1}&page_size=${pageSize}`);
}

export const deleteCSVFileHistory = (id) => {
    return request.delete(`/device_configuration/device/deleteUploadedManualCSVFile/${id}`)
}