import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Switch } from "@mui/material";
import { images } from "../../../../config/images";
import "./AddAccessManagement.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAccessModule,
  getDepartment,
  addAccessManagement,
  getCompanyByDetails,
  getAccessModuleByCompany,
} from "../../service";
import { useSelector } from "react-redux";
import Textshimmer from "../../../../components/Shimmer/TextShimmer/TextShimmer";

function AddAccessManagement() {
  const navigate = useNavigate();
  const [openAddDepartmentModal, setOpenAddDepartmentModal] =
    React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [department, setDepartment] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState("none");
  const [accessModule, setAccessModule] = useState([]);
  const [subModule, setSubModule] = useState([]);
  const [accessModuleId, setAccessModuleId] = useState("");
  const [selectAccessManagement, setSelectAccessManagement] = useState([]);
  const [selectRole, setSelectRole] = useState("none");
  const [isLoading, setLoading] = useState(false);
  const [companyIdName, setCompanyName] = useState("");
  const [isShimmerAccessModule, setisShimmerAccessModule] = useState(false);
  console.log("selectAccessManagement", selectAccessManagement);

  console.log("subModule", subModule);

  const params = useParams();

  const { companyId } = useSelector((state) => state.userState);
  const handleOpenaddDepartmentModal = () => {
    setOpenAddDepartmentModal(!openAddDepartmentModal);
  };

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  useEffect(() => {
    // Find the specific plant that matches the plantId
    const matchedAccessModule = accessModule.find(
      (e) => e.id === accessModuleId
    );

    if (matchedAccessModule) {
      // If the matchedPlant exists, check if its device array is empty
      setSubModule(
        matchedAccessModule.sub_module.length
          ? matchedAccessModule.sub_module
          : []
      );
    }
  }, [accessModule, accessModuleId]);

  // handleSelectDepartment
  const handleSelectDepartment = (e) => {
    setSelectRole("none");
    setSelectDepartment(e.target.value);
  };
  // Delete Confirmation Modal
  const handleConfirmationModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  // company deatils
  const getCompanyByDetailsApi = async () => {
    const paramReq = {
      company_id: params.companyId,
    };
    const resp = await getCompanyByDetails(paramReq);
    console.log("resprespresp", resp);
    if (resp.status == 200) {
      setCompanyName(resp.data.payload.company_name);
    }
  };

  useEffect(() => {
    if (params.companyId) {
      getCompanyByDetailsApi();
    }
  }, []);

  const getDepartmentList = async () => {
    const paramReq = {
      company: params.companyId ? params.companyId : companyId,
    };
    const response = await getDepartment(paramReq);
    if (response.status == 200 || response.status == 201) {
      setDepartment(response.data.payload);
    }
  };

  // const getAccessmodule = async () => {
  //   try{
  //     setisShimmerAccessModule(true);
  //     const resp = await getAccessModule();
  //     if (resp.status == 200 || resp.status == 201) {
  //       setTimeout(()=>{setisShimmerAccessModule(false)},1000)

  //       setAccessModule(resp.data.payload[0].access_module);
  //       // setAccessModuleId(resp.data.payload[0].access_module[0].id);
  //     }
  //   }catch(Error){
  //     setisShimmerAccessModule(false)
  //     console.log("Error from getAccessmodule")
  //   }

  // };
  const getAccessmoduleByCompany = async () => {
    try {
      setisShimmerAccessModule(true);
      const resp = await getAccessModuleByCompany(params.companyId);
      if (resp.status == 200 || resp.status == 201) {
        setTimeout(() => {
          setisShimmerAccessModule(false);
        }, 1000);
        setAccessModule(resp.data.payload[0].access_module);
        // setAccessModuleId(resp.data.payload[0].access_module[0].id);
      }
    } catch (error) {
      setisShimmerAccessModule(false);
    }
  };
  const getAccessmodule = async () => {
    try {
      setisShimmerAccessModule(true);
      const resp = await getAccessModule();
      if (resp.status == 200 || resp.status == 201) {
        setTimeout(() => {
          setisShimmerAccessModule(false);
        }, 1000);
        setAccessModule(resp.data.payload[0].access_module);
        // setAccessModuleId(resp.data.payload[0].access_module[0].id);
      }
    } catch (error) {
      setisShimmerAccessModule(false);
    }
  };
  useEffect(() => {
    getDepartmentList();
    if (params.companyId) {
      getAccessmoduleByCompany();
    } else {
      getAccessmodule();
    }
  }, []);

  // const handleSelectAccessManagement = (accessModuleId, subModuleId, access, e) => {
  //   const existingIndex = selectAccessManagement.findIndex(
  //     item => item.access_module === accessModuleId
  //   );

  //   if (!subModuleId && !access && !e) {
  //     if (selectAccessManagement.find(
  //       item => item.access_module === accessModuleId
  //     ) ? true : false) {
  //       const updatedAccessManagement = [...selectAccessManagement];
  //       updatedAccessManagement.splice(existingIndex, 1);
  //       setSelectAccessManagement(updatedAccessManagement);
  //       return true
  //     }
  //  }

  //   if (existingIndex !== -1) {
  //     const updatedAccessManagement = [...selectAccessManagement];
  //     const subModuleIndex = updatedAccessManagement[existingIndex].sub_module.indexOf(subModuleId);

  //     if (subModuleIndex !== -1) {
  //       updatedAccessManagement[existingIndex].sub_module.splice(subModuleIndex, 1);
  //       if (updatedAccessManagement[existingIndex].sub_module.length === 0) {
  //         updatedAccessManagement[existingIndex].sub_module = [];
  //       }
  //     } else {
  //       updatedAccessManagement[existingIndex].sub_module = [
  //         ...(updatedAccessManagement[existingIndex].sub_module || []),
  //         subModuleId
  //       ];
  //     }

  //     if (access == "Viewer") {
  //       updatedAccessManagement[existingIndex].is_viewer = e.target.checked
  //     }

  //     if (access == "Editor") {
  //       updatedAccessManagement[existingIndex].is_editor = e.target.checked
  //     }

  //     setSelectAccessManagement(updatedAccessManagement);
  //   } else {
  //     const newAccessManagement = {
  //       access_module: accessModuleId,
  //       sub_module: [],
  //       is_viewer: false,
  //       is_editor: false
  //     };
  //     setSelectAccessManagement(prevState => [...prevState, newAccessManagement]);
  //   }
  // };

  const handleSelectAccessManagement = (accessModuleId) => {
    setSelectAccessManagement((prevState) => {
      // Check if an object with the same access_module already exists
      const existingObjectIndex = prevState.findIndex(
        (item) => item.access_module === accessModuleId
      );

      // If it exists, remove the object by filtering out the matching item
      if (existingObjectIndex !== -1) {
        return prevState.filter((item, index) => index !== existingObjectIndex);
      }

      // If it doesn't exist, add a new object with the given accessModuleId
      const newAccessManagement = {
        access_module: accessModuleId,
        sub_module: [],
        is_viewer: false,
        is_editor: false,
      };

      return [...prevState, newAccessManagement];
    });
  };

  const handleSetAccessViewer = (accessModuleId, is_viewer) => {
    // Extract all the id's from the selectAccessManagement array
    const subModuleIds = subModule.length
      ? subModule.map((item) => item.id)
      : [];

    setSelectAccessManagement((prevState) =>
      prevState.map((item) => {
        // Check if the access_module matches the current accessModuleId
        if (item.access_module === accessModuleId) {
          if (is_viewer) {
            return {
              ...item,
              is_viewer,
              sub_module: is_viewer ? subModuleIds : [],
            };
          } else {
            return {
              ...item,
              is_viewer,
              is_editor: false,
              sub_module: is_viewer ? subModuleIds : [],
            };
          }
          // Update the is_viewer value based on the provided parameter
        }
        return item; // Return the unchanged item if access_module doesn't match
      })
    );
  };

  const handleSetAccessEditer = (accessModuleId, is_editor) => {
    const subModuleIds = subModule.length
      ? subModule.map((item) => item.id)
      : [];

    setSelectAccessManagement((prevState) =>
      prevState.map((item) => {
        // Check if the access_module matches the current accessModuleId
        if (item.access_module === accessModuleId) {
          // Update the is_viewer value based on the provided parameter
          return {
            ...item,
            is_editor,
            is_viewer: true,
            sub_module: subModuleIds,
          };
        }
        return item; // Return the unchanged item if access_module doesn't match
      })
    );
  };

  const handleSubModuleSetId = (accessModuleId, id) => {
    setSelectAccessManagement((prevState) =>
      prevState.map((item) => {
        // Check if the access_module matches the current accessModuleId
        if (item.access_module === accessModuleId) {
          // Check if the id is already in the sub_module array
          const subModuleIndex = item.sub_module.indexOf(id);
          if (subModuleIndex !== -1) {
            // Remove the id from the sub_module array
            const newSubModule = [...item.sub_module];
            newSubModule.splice(subModuleIndex, 1);
            return { ...item, sub_module: newSubModule };
          } else {
            // Add the id to the sub_module array
            return {
              ...item,
              is_viewer: true,
              is_editor: true,
              sub_module: [...item.sub_module, id],
            };
          }
        }
        return item; // Return the unchanged item if access_module doesn't match
      })
    );
  };

  const isViewerChecked = selectAccessManagement.length
    ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
      ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
          .is_viewer
      : false
    : false;
  const isEditorChecked = selectAccessManagement.length
    ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
      ? selectAccessManagement.find((f) => f.access_module == accessModuleId)
          .is_editor
      : false
    : false;

  const submitAddAccessManagement = async () => {
    if (selectDepartment == "none") {
      notify("Please Select Department");
      return true;
    }

    if (selectRole == "none") {
      notify("Please Select Role");
      return true;
    }

    // const filteredData = selectAccessManagement.map(item => {
    //   if (item.sub_module.includes(-1)) {
    //     item.sub_module = item.sub_module.filter(id => id !== -1);
    //   }
    //   return item;
    // });

    const param = {
      department: selectDepartment,
      role: selectRole,
      company: params.companyId ? params.companyId : companyId,
      access_management: selectAccessManagement,
    };

    try {
      setLoading(true);
      const resp = await addAccessManagement(param);

      if (resp.data.success == true) {
        setLoading(false);
        if (params.companyId) {
          navigate(`/accessManagement/company/${params.companyId}`);
        } else {
          navigate("/accessManagement");
        }
      }
    } catch (error) {
      setLoading(false);
      notify(error.response.data.message);
    }
  };
  return (
    <>
      {/* <div>ManagementDepartment</div> */}
      <div>
        
      </div>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                onClick={() => {
                  if (params.companyId) {
                    navigate(`/accessManagement/company/${params.companyId}`);
                    return true;
                  }
                  navigate("/accessManagement");
                }}
                className="backArrow"
              />{" "}
              <Typography
                variant="h5"
                className="deviceManagementTitle"
                gutterBottom
                style={{ margin: "0" }}
                onClick={() => {
                  if (params.companyId) {
                    navigate(`/accessManagement/company/${params.companyId}`);
                    return true;
                  }
                  navigate("/accessManagement");
                }}
              >
                {params.companyId
                  ? `${companyIdName} - Access Management`
                  : "Access Management"}
              </Typography>
            </div>
            <div className="addDevice-buttonWrap">
              <button
                className="manageDepartment-button"
                onClick={() => navigate("/managedepartment")}
              >
                Manage Department
              </button>
              <img src={images.excelLogo} alt="" className="cloudActiveImg" />
            </div>
          </div>
          {/* =============== Add Department Modal Start =============== */}
          {openAddDepartmentModal && (
            <Card className="addDepartment-card">
              <Box>
                {/* <Stack direction="row" spacing={{ sm: 14, md: 20, lg: 25 }}>
                    </Stack> */}
                <Box>
                  <Stack direction="row" spacing={{ sm: 42, md: 42, lg: 38 }}>
                    <Typography gutterBottom style={{ display: "none" }}>
                      ..
                    </Typography>
                    <img
                      src={images.closeIcon}
                      alt=""
                      style={{ width: "30px", cursor: "pointer" }}
                      onClick={handleOpenaddDepartmentModal}
                    />
                  </Stack>
                  <Typography
                    gutterBottom
                    style={{
                      color: "#1C407B",
                      fontWeight: "600",
                      fontSize: "19px",
                      textAlign: "center",
                    }}
                  >
                    Add Department
                  </Typography>
                </Box>
                <div className="departmentWrap">
                  <label for="exampleInputEmail1" class="form-label">
                    Department <span>*</span>
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Department"
                  ></input>
                </div>
                <button className="addDepartmentButton">Add Department</button>
              </Box>
            </Card>
          )}
          {/* =============== Add Department Modal End =============== */}
          {/* =============== Delete Confirmation Modal Start =============== */}
          {openDeleteModal && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure<br></br> You Want To Delete?
              </Typography>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={handleConfirmationModal}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleConfirmationModal}
                >
                  No
                </button>
              </Box>
            </Card>
          )}
          {/* =============== Delete Confirmation Modal End =============== */}
          <Grid container style={{ paddingTop: "20px" }}>
            <Grid item xs={12}>
              <Card className="manageDepartment-card">
                <Grid
                  container
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <Grid item sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Department</b>{" "}
                      <span style={{ color: "#E31E24" }}>*</span>
                    </Typography>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      className="addAccessManagement selectDropDown"
                      value={selectDepartment}
                      onChange={(e) => handleSelectDepartment(e)}
                    >
                      <option selected disabled hidden value="none">
                        Select Department
                      </option>
                      {department.map((e, i) => {
                        return (
                          <option value={e.id}>{e.department_name}</option>
                        );
                      })}
                    </select>
                    <Box className="accessDepartment-textWrap">
                      <div>
                        <Typography variant="subtitle2" gutterBottom>
                          <b>Select Access Module</b>{" "}
                          <span style={{ color: "#E31E24" }}>*</span>
                        </Typography>
                      </div>
                      {/* <div>
                        <img
                          src={images.addIcon}
                          alt=""
                          className="addDeviceImg"
                          style={{ cursor: "pointer" }}
                        />
                      </div> */}
                    </Box>
                    <div className="manageDepartment-wrap">
                      {!isShimmerAccessModule ? (
                        accessModule.length ? (
                          accessModule.map((e1, i) => {
                            const isChecked = selectAccessManagement.find(
                              (f) => f.access_module == e1.id
                            )
                              ? true
                              : false;
                            return (
                              <div
                                className="addAccessManagement-fieldWrap"
                                onClick={() => setAccessModuleId(e1.id)}
                              >
                                {accessModuleId == e1.id && (
                                  <img
                                    src={images.activeDeviceimg}
                                    alt=""
                                    className="activeAccessDepartmentImg"
                                  />
                                )}
                                <div className="manageDepartment-field">
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    style={{ margin: "0" }}
                                  >
                                    <Checkbox
                                      checked={isChecked}
                                      onClick={() =>
                                        handleSelectAccessManagement(e1.id)
                                      }
                                    ></Checkbox>{" "}
                                    {e1.access_module_name}
                                  </Typography>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <>No Data Found</>
                        )
                      ) : (
                        <Textshimmer height="45px" width="90%" itemCount={5} />
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={12} md={8} lg={8} xl={8}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Role</b> <span style={{ color: "#E31E24" }}>*</span>
                    </Typography>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      className="addAccessManagement selectDropDown Roles"
                      value={selectRole}
                      onChange={(e) => setSelectRole(e.target.value)}
                    >
                      <option selected disabled hidden value="none">
                        Select Role
                      </option>
                      {department.length
                        ? department.map((e, i) => {
                            if (selectDepartment == e.id) {
                              return e.company_roles.map((e1) => {
                                return (
                                  <option value={e1.id}>{e1.role_name}</option>
                                );
                              });
                            }
                          })
                        : []}
                    </select>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Select Sub Module</b>{" "}
                      <span style={{ color: "#E31E24" }}>*</span>
                    </Typography>
                    <div className="managementDepartment-card">
                      <div className="accessManagement switchWrap">
                        <Grid container spacing={2} pt={4}>
                          <Grid item xs={4} sm={6}>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{ margin: "0" }}
                            >
                              Viewer
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Switch
                              onClick={(e) =>
                                handleSetAccessViewer(
                                  accessModuleId,
                                  e.target.checked
                                )
                              }
                              // defaultChecked
                              checked={isViewerChecked}
                              size="small"
                              color="success"
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={4} sm={6}>
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{ margin: "0" }}
                            >
                              Editor
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Switch
                              onClick={(e) =>
                                handleSetAccessEditer(
                                  accessModuleId,
                                  e.target.checked
                                )
                              }
                              checked={isEditorChecked}
                              size="small"
                              color="success"
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <Grid container className="pt-3">
                        {accessModule.length
                          ? accessModule.map((e, i) => {
                              if (accessModuleId === e.id) {
                                const getAccessManagement =
                                  selectAccessManagement.find(
                                    (f) => f.access_module === accessModuleId
                                  );

                                const subModules = e.sub_module.map((e1) => {
                                  const isChecked = getAccessManagement
                                    ? getAccessManagement.sub_module.includes(
                                        e1.id
                                      )
                                    : false;

                                  return (
                                    <Grid
                                      item
                                      xs={6}
                                      sm={3}
                                      md={3}
                                      lg={3}
                                      xl={3}
                                      key={e1.id}
                                    >
                                      <Box className="managementDepartment roles ">
                                        <Typography variant="body2">
                                          <Checkbox
                                            onClick={() =>
                                              handleSubModuleSetId(
                                                accessModuleId,
                                                e1.id
                                              )
                                            }
                                            checked={isChecked}
                                          />
                                          {e1.sub_module_name}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  );
                                });

                                return (
                                  <Grid container spacing={2}>
                                    {subModules}
                                  </Grid>
                                );
                              }
                            })
                          : []}
                      </Grid>
                    </div>
                    <button
                      className="Plantmanagemet submit"
                      onClick={() => submitAddAccessManagement()}
                    >
                      Submit
                    </button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}
export default AddAccessManagement;
