import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import { images } from "../../../../config";
import { PieChartShimmer } from "../../../../components/Shimmer/PieChartShimmer/PieChartShimmer";
import '../../ProcessDashboard.css';

export function EnergyPiechartPOP({
  energyCostPOPData,
  energyCostPOP1Data,
  energyCostPOPSum,
  energyCostPOP1Sum,
  emptyDevice,
  shimmer,
}) {
  const chartRef = useRef(null);
  const { user_country_currency_symbol } = useSelector(
    (state) => state.userState
  );

  // Prepare data with absolute values for chart display and keep original values for tooltip
  const dataForChart = [
    {
      name: energyCostPOPData.length ? energyCostPOPData[0].device_id.device_name : '',
      y: Math.abs(energyCostPOPData.length ? energyCostPOPData[0].total : 0), // Use absolute value for chart display
      color: '#C0255F',
      originalY: energyCostPOPData.length ? energyCostPOPData[0].total : 0, // Keep original value for tooltip
    },
    {
      name: energyCostPOP1Data.length ? energyCostPOP1Data[0].device_id.device_name : '',
      y: Math.abs(energyCostPOP1Data.length ? energyCostPOP1Data[0].total : 0), // Use absolute value for chart display
      color: '#E66595',
      originalY: energyCostPOP1Data.length ? energyCostPOP1Data[0].total : 0, // Keep original value for tooltip
    },
  ];

  const options = {
    chart: {
      type: 'pie',
      height: 250,
      width: 250,
      backgroundColor: 'transparent',
    },
    title: {
      text: ''
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        const total = this.series.data.reduce((acc, point) => acc + Math.abs(point.originalY), 0); // Use absolute values for total
        const percentage = ((Math.abs(this.point.originalY) / total) * 100).toFixed(2) + '%';
        return `<div style ="font-size : 12px">
                  <b>${this.point.name}</b><br/>
                  <span style="color:${this.point.color}">\u25A0</span> ${this.point.originalY} (${percentage})
                </div>`;
      }
    },
    plotOptions: {
      pie: {
        innerSize: '70%',
        dataLabels: {
          enabled: false,
        }
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Values',
      data: dataForChart,
    }]
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.update(options);
    }
  }, [energyCostPOPData, energyCostPOP1Data]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "230px" }} className="doughnut-chart1">
      {(energyCostPOPData.length || energyCostPOP1Data.length) &&
      emptyDevice ? (
        <>
          {!shimmer ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartRef}
            />
          ) : (
            <PieChartShimmer size="195px" position="relative"/>
          )}

          <p className="doughnut-chartText">
            {typeof energyCostPOPSum === "number" &&
            typeof energyCostPOP1Sum === "number"
              ? `${(energyCostPOPSum + energyCostPOP1Sum).toFixed(
                  2
                )} ${user_country_currency_symbol}`
              : ""}
          </p>
        </>
      ) : (
        <img src={images.noDataFoundImg} className="notFoundDataImg" alt="" />
      )}
    </div>
  );
}
