import React, { useEffect, useState } from "react";
import { Box, MenuItem, TextField } from "@mui/material";
import { Grid, Paper, Popper } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Switch from "@mui/material/Switch";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";

const VmsCOCData = ({ deviceData, deviceUnitData, access, handleTostMsg }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setAnchorEl2(null);
  };

  const handleClickUnit = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
    setAnchorEl(null);
  };

  const openMachine = Boolean(anchorEl);
  const openUnit = Boolean(anchorEl2);

  useEffect(() => {
    setAnchorEl(null);
    setAnchorEl2(null);
  }, []);

  const switchColors = [
    "#FEBB22",
    "#002B2A",
    "#C99C00",
    "#EB9E79",
    "#69927A",
    "#872B81",
    "#F06292",
    "#CD4D4A",
    "#123782",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#C60BC8",
    "#7D2264",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#0C4562",
    "#001A30",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#075571",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#0B648D",
    "#29445A",
    "#1D733F",
    "#6E02B1",
  ];

  return (
    <>
      <Grid item xs={12} sm={12} md={3}>
        <Box className="consumerData-box">
          <Box className="consumer-text-disc">
            <h6 className="Title">Title</h6>
            <h6>Data Charts</h6>
          </Box>

          <Box>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "13px 8px",
                borderRadius: "4px",
                width: "100%",
                background: "#f5f5f5",
                cursor: "pointer",
                marginTop: "4px",
              }}
              onClick={handleClick}
            >
              Select Machine <ArrowDropDownIcon />
            </div>
          </Box>

          <Popper
            open={openMachine}
            anchorEl={anchorEl}
            placement="bottom-start"
            style={{ width: "23%" }}
          >
            <Paper>
              {deviceData.length
                ? deviceData.map((row) => (
                    <MenuItem style={{ justifyContent: "space-between" }}>
                      {" "}
                      {row.device__device_name}{" "}
                      <Switch style={{ float: "right" }} />
                    </MenuItem>
                  ))
                : null}
            </Paper>
          </Popper>
          <div style={{ marginTop: "14px", fontWeight: "bold" }}>Tag List</div>

          <TextField
            variant="outlined"
            className="search-textfield"
            placeholder="Search Unit here"
            // value={unitSearchQuery}
            // onChange={handleUnitSearchChange}
            InputProps={{
              startAdornment: <SearchIcon />,
              inputProps: {
                style: { padding: "9px" },
              },
            }}
          />

          <div className="vms-device-unit">
            <Box className="Grid-text1 mt-2">
              {deviceUnitData
                // .filter(
                //   (e) =>
                //     e.tag_unit &&
                //     e.tag_unit
                //       .toLowerCase()
                //       .includes(unitSearchQuery.toLowerCase())
                // )
                .map((e) => (
                  <Grid item xs={12}>
                    {e.tag_unit && (
                      <Grid
                        container
                        alignItems="center"
                        style={{
                          background: "#efeffd",
                          width: "100%",
                          marginBottom: "7px",
                        }}
                      >
                        <Grid
                          item
                          xs={8}
                          className="mt-2"
                          style={{ padding: "7px 12px" }}
                        >
                          <h6>{e.tag_unit}</h6>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          textAlign="right"
                          className="grid-switch-unit"
                        >
                          <Switch
                            // checked={deviceUnitRedux.includes(
                            //   e.tag_unit
                            // )}
                            // checked={deviceUnitRedux.length ? deviceUnitRedux.find((f) => f.tagUnit == e.tag_unit && f.plantId == param.id) ? true : false: false }
                            style={{ float: "right" }}
                            // onClick={() =>
                            //   handleSelectTagUnit(e.tag_unit)
                            // }
                            sx={{
                              "& .MuiSwitch-thumb": {
                                bgcolor: "#828282",
                              },
                              "& .MuiSwitch-track": {
                                bgcolor: "#D9D9E5 !important",
                              },
                              "& .Mui-checked": {
                                "& .MuiSwitch-thumb": {
                                  bgcolor: "#50C878",
                                },
                                "& .MuiSwitch-track": {
                                  bgcolor: "#CEE6E1 !important",
                                },
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                ))}
            </Box>
          </div>

          <div className="mt-3">
            <button
              className="export-btn"
              style={{ marginTop: "46px" }}
              //   onClick = {handleExportEnergyData}
            >
              EXPORT ENERGY DATA
            </button>
          </div>
        </Box>
      </Grid>
    </>
  );
};

export default VmsCOCData;
