import React from "react";
import Highcharts from "highcharts/highstock";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";
import { ShimmerText } from "react-shimmer-effects";
import {
  ParetoChartShimmer,
  SmallParetoChartShimmer,
} from "../../../../components/Shimmer/ParetoChartShimmer/ParetoChartShimmer";

HC_more(Highcharts);
exporting(Highcharts);

const AnalyticsGraph = ({ getsummaryGraphData, configureTagUnit, shimmer }) => {
  

  const options = {
    chart: {
      type: "column", // Column chart is suitable for grouped bars
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false, // Enable legend if needed
    },
    xAxis: {
      categories: getsummaryGraphData.map((e) => e.date),
      labels: {
        style: {
          fontSize: "14px",
          color: "#001323",
        },
      },
      scrollbar: {
        enabled: true, // Enable scrollbar for X-axis if there are many categories
      },
    },
    yAxis: {
      title: {
        text: configureTagUnit.accepted
          ? configureTagUnit.accepted + " / " + configureTagUnit.rejected
          : "",
        style: {
          fontSize: "17px",
          fontWeight: "bold",
          color: "#001323",
        },
      },
      labels: {
        style: {
          fontSize: "12px",
          color: "#001323",
        },
      },
    },
    series: [
      {
        name: configureTagUnit.rejected,
        color: "#ccd7eb",
        data: getsummaryGraphData.map((e) => e.rejected),
        
      },
      {
        name: configureTagUnit.accepted,
        color: "#1C407B",
        data: getsummaryGraphData.map((e) => e.accepted),
      },
    ],
    plotOptions: {
      column: {
        stacking: null, // Set to null for grouped bars
        grouping: true, // Ensure bars are grouped
        borderRadius: "4px",
      },
      series: {
        borderRadius: "4px",
      },
    },
    exporting: {
      enabled: true, // Enable exporting options
    },
    credits: {
      enabled: false, // Disable credits
    },
  };
  
  return (
    <div className="analyticsGraph p-3">
      {!shimmer ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          className="analyticsGraph"
        />
      ) : (
        <SmallParetoChartShimmer
          barCount={12}
          className="ParetoChartShimmer-OverView"
        />
      )}
    </div>
  );
};

export default AnalyticsGraph;
