
import { request } from "../../../api/api";

export const getDeviceSourceAPI = (params) => {
  return request.post("/dashboard/getDeviceByType/", params);
};

export const getDeviceTagByTypeAPI = (params) => {
  return request.post("/dashboard/getDeviceTagByType/", params);
};

export const createDeviceConfig = (params) => {
  return request.post("/dashboard/createDeviceConfig/", params);
};
export const getDeviceConfigData = (params) =>{
    return request.post("/dashboard/getDeviceConfig/",params)
}

export const getDeviceType = (params) =>{
    return request.post(`/device/getDevice/`,params)
}

export const getTagID = (data) => {
  return request.post('/dashboard/getTagId/', data)
}