import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "../../ProcessDashboard/ProcessDashboard.css";
import { PieChartShimmer } from "../../../components/Shimmer/PieChartShimmer/PieChartShimmer";
import { images } from "../../../config";

export function PopDonutChart({
  analyticsPopPieChart,
  analyticsPop1PieChart,
  donutPOPChartSum,
  donutPOP1ChartSum,
  donutPOPUnit,
  Shimmer,
}) {
  const chartRef = useRef(null);

  const data = {
    labels: [
      analyticsPopPieChart.length
        ? analyticsPopPieChart[0].device_name
        : "",
      analyticsPop1PieChart.length
        ? analyticsPop1PieChart[0].device_name
        : "",
    ],
    datasets: [
      {
        data: [
          analyticsPopPieChart.length ? Math.abs(analyticsPopPieChart[0].tag_value) : 0,
          analyticsPop1PieChart.length ? Math.abs(analyticsPop1PieChart[0].tag_value) : 0,
        ],
        backgroundColor: ["#C0255F", "#E66595"],
        borderColor: ["#C0255F", "#E66595"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    chart: {
      type: "pie",
      height: 250,
      width: 250,
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        const total = this.series.data.reduce((acc, point) => acc + point.y, 0);
        const percentage = ((this.y / total) * 100).toFixed(2) + "%";
        const index = this.point.index; // Get the index of the point in the data array
        const deviceName = data.labels[index];
        const deviceColor = data.datasets[0].backgroundColor[index];
        const originalValue = (index === 0 && analyticsPopPieChart.length
          ? analyticsPopPieChart[0].tag_value
          : index === 1 && analyticsPop1PieChart.length
          ? analyticsPop1PieChart[0].tag_value
          : 0); // Get the original value
        return `<div style="font-size: 12px">
                  <b>${deviceName}</b><br/>
                  <span style="color:${deviceColor}">\u25A0</span> ${originalValue} (${percentage})
                </div>`;
      },
    },
    plotOptions: {
      pie: {
        innerSize: "70%",
        dataLabels: {
          enabled: false,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Values",
        data: [
          {
            name: analyticsPopPieChart.length
              ? analyticsPopPieChart[0].device_name
              : "",
            y: analyticsPopPieChart.length ? Math.abs(analyticsPopPieChart[0].tag_value) : 0,
            color: "#C0255F",
          },
          {
            name: analyticsPop1PieChart.length
              ? analyticsPop1PieChart[0].device_name
              : "",
            y: analyticsPop1PieChart.length ? Math.abs(analyticsPop1PieChart[0].tag_value) : 0,
            color: "#E66595",
          },
        ],
      },
    ],
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.update(options);
    }
  }, [analyticsPopPieChart, analyticsPop1PieChart]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "230px",
      }}
      className="doughnut-chart1"
    >
      {(analyticsPopPieChart.length || analyticsPop1PieChart.length) ? (
        <>
          {!Shimmer ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartRef}
            />
          ) : (
            <PieChartShimmer size="195px" position="relative" />
          )}

          <p className="doughnut-chartText">
            {typeof donutPOPChartSum === "number" &&
            typeof donutPOP1ChartSum === "number"
              ? (donutPOPChartSum + donutPOP1ChartSum).toFixed(2)
              : ""}
          </p>
          <p className="doughnut-chartText-unit">{donutPOPUnit}</p>
        </>
      ) : (
        <img src={images.noDataFoundImg} className="notFoundDataImg not-found" alt="" />
      )}
    </div>
  );
}
