import { Box, Card, Typography } from "@mui/material";
import React from "react";

export const SelectAssignDevice = () => {
  return (
    <Card className="addDeviceDetailsCard gap assignDevice-shimmer">
      <Box className="addDeviceCardDetailsWrap">
        <Box className="addDeviceDetailsCardetails-wrap">
          <div class="shimmerassign-icon"></div>
          <Box className="addDeviceDetailsCardetails">
            <Typography variant="subtitle2" gutterBottom>
              <div class="shimmer-block shimmer-header-plant"></div>
            </Typography>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              className="addDeviceDetailsCardetails-text"
            >
              <div class="header-plant-shimmer"></div>
            </Typography>
            <Typography
              variant="caption"
              display="block"
              gutterBottom
              className="addDeviceDetailsCardetails-text"
            >
              <div class="header-plant-shimmer1"></div>
            </Typography>
          </Box>
        </Box>
        <div class="configuration-plant-shimmer" style={{height:"20px"}}></div>
        {/* <button className='addDeviceConfi-btn' onClick={() => navigate('/deviceManagement/configure/device/:id')}>Configuration</button> */}
      </Box>
    </Card>
  );
};
