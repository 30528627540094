import { Box, Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./MachineDashboard.css";
import { images } from "../../../config";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  getInteractionTag,
  getSmartTagGateway,
  getTagBarGraphData,
  getTagData,
  tagInteractionData,
} from "../services";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setSmartTagReportData } from "./Slice";
import MqttDataMachineDashboard from "./mqttDataMachineDashboard/mqttDataMachineDashboard";
import GraphDataMqtt from "./mqttDataMachineDashboard/graphDataMqtt";
import SelectedTagGraphData from "./GraphmachineDashboard/selectedGraph";
import Every15minutesRemoveGraph from "./Every15minRemoveGraph/Every15minRemoveGraphdata";
import BarGraph from "../../../components/Graph/SmartTagBarGraph/BarGraph";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { SoftTagFormula } from "../softTag/SoftTagFormula";

const SmartTagMachineDashboard = () => {
  const [value, setValue] = useState(0);
  const [tagData, setTagData] = useState([]);
  const [tagId, setTagId] = useState("");
  const [topics, setTopics] = useState([]);
  const [selectTimeRange, setSelectTimeRange] = useState("live");
  const [selectDuration, setSelectDuration] = useState("");

  // slider tag data state
  const [sliderRealTimeTagData, setSliderRealTimeTagData] = useState([]);
  const [mqttTagPreviousvalue, setMqttTagPreviousvalue] = useState([]);
  const [tagStatus, setTagStatus] = useState([]);

  //graph state
  const [graphRealTimeData, setGraphRealTimeData] = useState([]);
  const [selectTagData, setSelectTagData] = useState([]);
  const [selectGatewayId, setSelectGatewayId] = useState("");
  const [selectedDvTagname, setSelectedDvTagName] = useState("");
  const [selectedTagStatus, setSelectedTagStatus] = useState(0.0);
  const [hiValue, setHIValue] = useState(0);
  const [lowValue, setLowValue] = useState(0);
  const [barGraphData, setBarGraphData] = useState([]);
  console.log("tagDatatagData", tagData);

  const { selectTimeZoneProfile } = useSelector(state => state.userState);
  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (tagData.length) {
      const tagdatalist = tagData
        ?.map((e) => {
          if (e.tag && typeof e.tag === "object" && e.tag.device) {
            const { machine_uid } = e.tag.device;
            return "Hikar/DeviceUID/" + machine_uid;
          } else {
            return null;
          }
        })
        .filter(Boolean);
      setTopics([...new Set(tagdatalist)]);
    }
  }, [tagData]);

  const navigate = useNavigate();
  const params = useParams();

  const {
    tagClickebleData,
    deviceID,
    smartTagHIRange,
    smartTagLowRange,
    selectRangeGraph,
    smartTagtagMachineName,
  } = useSelector((state) => state.smartTagReportSlice);

  const tagIDData = tagClickebleData.length
    ? tagClickebleData.find((f) => f.plantId == params.plantid)?.["tagId"] ||
      false
    : false;

  const dispatch = useDispatch();

  useEffect(() => {
    if (tagIDData) {
      handleBarGraphData();
    }
  }, [tagIDData, hiValue, lowValue, selectRangeGraph]);

  const handleBarGraphData = async () => {
    const params = {
      tag: tagIDData,
      low_range: lowValue,
      high_range: hiValue,
      duration: selectRangeGraph,
    };
    try {
      const resp = await getTagBarGraphData(params);
      if (resp.status == 200 || resp.status == 201) {
        setBarGraphData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (tagId) {
      addInteractionData();
    }
  }, [tagId]);

  const addInteractionData = async () => {
    const param = {
      plant: params.plantid,
      tag: [tagId],
    };
    try {
      const resp = await tagInteractionData(param);
      if (resp.status == 200 || resp.status == 201) {
        console.log("Interaction tag successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleToastMsg = () => {
    notifyError("Please click on any tag");
  };

  useEffect(() => {
    handleTagData();
  }, []);

  const getTagDateAndTime = () => {
    return new Date().toLocaleString("en-US", {
      timeZone: selectTimeZoneProfile,
      hour12: false,
    });
  };

  const handleTagData = async () => {
    const param = {
      plant: params.plantid,
    };
    try {
      const resp = await getInteractionTag(param);
      if (
        resp.status == 200 ||
        (resp.status == 201 && resp.data.payload && resp.data.payload.length)
      ) {
        setTagData(resp.data.payload);
        const tagObj = resp.data.payload.map((row) => {
          const obj = {
            dvName: row.tag?.device_tag ? row.tag?.device_tag || "-" : "-",
            prevValue: 0,
            curValue: 0,
            dateAndTime: getTagDateAndTime(),
          };
          return obj;
        });
        setMqttTagPreviousvalue(tagObj);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };
  useEffect(() => {
    setHIValue(parseFloat(smartTagHIRange));
    setLowValue(parseFloat(smartTagLowRange));
  }, [smartTagHIRange, smartTagLowRange]);

  const getTagIdApi = async (tagId) => {
    const param = {
      tag: tagId,
    };
    try {
      const resp = await getTagData(param);
      if (resp.status == 200 || resp.status == 201) {
        setGraphRealTimeData([]);
        setSelectTagData(resp.data.payload);
        setSelectGatewayId(
          resp.data.payload.tag_config_data[0].device__machine__gateway__id
        );
        dispatch(
          setSmartTagReportData({
            deviceID: resp.data.payload.tag_config_data[0].device,
          })
        );
        dispatch(
          setSmartTagReportData({
            tagUNIT: resp.data.payload.tag_config_data[0].tag_unit,
          })
        );

        setSelectedDvTagName(
          resp.data.payload.tag_config_data &&
            resp.data.payload.tag_config_data[0] &&
            resp.data.payload.tag_config_data[0]?.device_tag !== null
            ? resp.data.payload.tag_config_data[0]?.device_tag
            : ""
        );
        dispatch(
          setSmartTagReportData({
            smartTagHIRange: resp.data.payload.tag_config_data[0].high_range,
          })
        );
        dispatch(
          setSmartTagReportData({
            smartTagLowRange: resp.data.payload.tag_config_data[0].low_range,
          })
        );
        dispatch(
          setSmartTagReportData({
            smartTagtagMachineName:
              resp.data.payload.tag_config_data[0]
                .device__machine__machine_name,
          })
        );

        setSelectedTagStatus(0.0);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    const findPlatTag = tagClickebleData.length
      ? tagClickebleData.find((f) => f.plantId == params.plantid)?.["tagId"] ||
        false
      : false;
    if (findPlatTag) {
      getTagIdApi(findPlatTag);
    }
  }, [tagClickebleData]);


  const handleMachineCardClick = async (tagId, machineId, tagUnit, tagName) => {
    console.log("machineIdmachineId", machineId);
    const existingIndex = tagClickebleData.findIndex(
      (item) => item.plantId === params.plantid
    );

    if (existingIndex !== -1) {
      const updatedTagData = tagClickebleData.map((item, index) => {
        if (index === existingIndex) {
          return {
            ...item,
            machineId: machineId,
            tagUnit: tagUnit,
            tagId: tagId,
            tagName: tagName,
          };
        }
        return item;
      });

      dispatch(
        setSmartTagReportData({
          tagClickebleData: updatedTagData,
        })
      );
    } else {
      dispatch(
        setSmartTagReportData({
          tagClickebleData: [
            ...tagClickebleData,
            {
              machineId: machineId,
              tagUnit: tagUnit,
              tagId: tagId,
              plantId: params.plantid,
              tagName: tagName,
            },
          ],
        })
      );
    }
  };

  useEffect(() => {
    const findPlatTag = tagClickebleData.length
      ? tagClickebleData.find((f) => f.plantId == params.plantid)?.["tagId"] ||
        false
      : false;
    if (findPlatTag) {
      getTagIdApi(findPlatTag);
    }
  }, [tagClickebleData]);

  useEffect(() => {
    if (mqttTagPreviousvalue.length) {
      const averagCalTagvalue = mqttTagPreviousvalue.map((row) => {
        const avgCal = ((row.curValue - row.prevValue) / row.prevValue) * 100;
        console.log("avgCal", avgCal);
        return {
          dvName: row.dvName,
          statusValue: isFinite(avgCal) ? avgCal : 0.0,
        };
      });

      setTagStatus(averagCalTagvalue);
    }
  }, [mqttTagPreviousvalue]);

  // selected Graph status
  useEffect(() => {
    if (selectedDvTagname && tagStatus.length) {
      const findSelectedTagStatus = tagStatus.find(
        (item) => item.dvName == selectedDvTagname
      );
      setSelectedTagStatus(
        findSelectedTagStatus ? findSelectedTagStatus.statusValue : 0.0
      );
    }
  }, [tagStatus]);

  const handleReportClick = () => {
    if (deviceID) {
      navigate(
        `/Smarttag-machine-tag-reports/company/${params.companyid}/plant/${params.plantid}/gateway/${selectGatewayId}/device/${deviceID}`
      );
    } else {
      notifyError("please select tag");
    }
  };

  const handleAnalyticsClick = () => {
    if (deviceID) {
      navigate(
        `/Smarttag-machine-tag-analytics/company/${params.companyid}/plant/${params.plantid}/gateway/${selectGatewayId}/device/${deviceID}`
      );
    } else {
      notifyError("please select tag");
    }
  };
  useEffect(() => {
    setGraphRealTimeData([]);
  }, [selectTimeRange]);

  const handleReportClickData = () => {
    navigate(
      `/Smarttag-machinereports/company/${params.companyid}/plant/${
        params.plantid
      }/device/${
        tagClickebleData.length
          ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
              "machineId"
            ]
          : ""
      }`
    );
  };

  const handleAnalyticsData = () => {
    navigate(
      `/Smarttag-machineanalytics/company/${params.companyid}/plant/${
        params.plantid
      }/device/${
        tagClickebleData.length
          ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
              "machineId"
            ]
          : ""
      }`
    );
  };
  // const incrementValue = () => {
  //   setHIValue((prevValue) => parseInt(prevValue, 10) + 1);
  // };

  // const decrementValue = () => {
  //   setHIValue((prevValue) => parseInt(prevValue, 10) - 1);
  // };
  const incrementValue = () => {
    setHIValue((prevValue) => {
      const newValue = parseInt(prevValue, 10) + 1;
      if (newValue <= smartTagHIRange) {
        return newValue;
      } else {
        notifyError(`HI value cannot exceed ${smartTagHIRange}`);
        return prevValue; // Return previous value if the new value exceeds the range
      }
    });
  };

  const decrementValue = () => {
    setHIValue((prevValue) => {
      const newValue = parseInt(prevValue, 10) - 1;
      if (newValue >= smartTagLowRange) {
        return newValue;
      } else {
        notifyError(`HI value cannot be less than ${smartTagLowRange}`);
        return prevValue;
      }
    });
  };

  const incrementLowValue = () => {
    setLowValue((prevValue) => {
      const newValue = parseInt(prevValue, 10) + 1;
      if (newValue <= smartTagHIRange) {
        return newValue;
      } else {
        notifyError(
          `Low value cannot exceed ${smartTagHIRange - 1}` // Subtracting 1 to keep it below high range
        );
        return prevValue; // Return previous value if the new value exceeds the high range
      }
    });
  };

  const decrementLowValue = () => {
    setLowValue((prevValue) => {
      const newValue = parseInt(prevValue, 10) - 1;
      // Check if the new value goes below 0 or not
      if (newValue >= smartTagLowRange) {
        return newValue;
      } else {
        notifyError(`Low value cannot be less than ${smartTagLowRange}`);
        return prevValue; // Return previous value if the new value goes below 0
      }
    });
  };

  const handleTimeDuration = (e) => {
    dispatch(
      setSmartTagReportData({
        selectRangeGraph: e.target.value,
      })
    );
  };

  const mqttDataReduce = sliderRealTimeTagData.reduce((map, item) => {
    map[item.device_tag] = Number(item.tagValue);
    return map;
  }, {});

  return (
    <>
      <div className="page-wraper">
        {/* ===================old code ============== */}
        {/* {sliderRealTimeTagData.length && selectTagData ? (
          <Every15minutesRemoveGraph
            selectTagData={selectTagData}
            setGraphRealTimeData={setGraphRealTimeData}
          />
        ) : null} */}
        {topics.length ? (
          <MqttDataMachineDashboard
            topics={topics}
            tagData={tagData}
            setSliderRealTimeTagData={setSliderRealTimeTagData}
            setMqttTagPreviousvalue={setMqttTagPreviousvalue}
            mqttTagPreviousvalue={mqttTagPreviousvalue}
          />
        ) : null}
        {sliderRealTimeTagData.length && selectTagData ? (
          <GraphDataMqtt
            sliderRealTimeTagData={sliderRealTimeTagData}
            selectTagData={selectTagData}
            setGraphRealTimeData={setGraphRealTimeData}
            selectTimeRange={selectTimeRange}
          />
        ) : null}

        <Slider {...sliderSettings} className="my-slider-class">
          {tagData?.map((e, index) => {
            console.log("e", e);
            if (
              e.tag &&
              e.tag.device &&
              typeof e.tag === "object" &&
              typeof e.tag.device === "object"
            ) {
              const {
                tag_name,
                tag_comment,
                id,
                device_tag,
                setpoint_hh,
                setpoint_hi,
                setpoint_ll,
                setpoint_lo,
                signal_type,
                low_range,
                high_range,
                tag_unit,
                is_soft_tag,
                soft_tag_id__formula,
                tag_read_write_type,
              } = e.tag;

              const { machine_uid } = e.tag.device;
              let tagStatusData = "";

              let tagValue = sliderRealTimeTagData.length
                ? sliderRealTimeTagData.find(
                    (f) =>
                      f.device__uid == machine_uid && f.device_tag == device_tag
                  )?.["tagValue"] || 0.0
                : 0.0;

              if (
                is_soft_tag &&
                soft_tag_id__formula &&
                Object.keys(mqttDataReduce).length
              ) {
                if (
                  SoftTagFormula(soft_tag_id__formula, mqttDataReduce)
                    ?.softTagNotGetTag == true
                ) {
                  tagValue = SoftTagFormula(
                    soft_tag_id__formula,
                    mqttDataReduce
                  )?.value;
                } else {
                  tagValue = tagValue;
                }
              }

              if (tag_read_write_type === "RW" && signal_type === "DO") {
                if (tagValue == 0) {
                  tagStatusData = "OFF";
                } else if (tagValue == 1) {
                  tagStatusData = "ON";
                }
              } else if (signal_type === "DO" || signal_type === "DI") {
                if (["yes"].includes(e.tag.alert?.toLowerCase())) {
                  if (parseFloat(tagValue) === 0) {
                    tagStatusData = low_range;
                  } else if (parseFloat(tagValue) === 1) {
                    tagStatusData = high_range;
                  }
                } else if (["no"].includes(e.tag.alert?.toLowerCase())) {
                  if (parseFloat(tagValue) === 0) {
                    tagStatusData = low_range;
                  } else if (parseFloat(tagValue) === 1) {
                    tagStatusData = high_range;
                  }
                } else {
                  if (tagValue == 0) {
                    tagStatusData = low_range ? low_range : "ON";
                  } else if (tagValue == 1) {
                    tagStatusData = high_range ? high_range : "ON";
                  }
                }
              }

            



              const findStatus = tagStatus.length
                ? tagStatus.find((f) => f.dvName == device_tag)
                : 0;

              const tag = parseFloat(tagValue);
              const hh = parseFloat(setpoint_hh);
              const hi = parseFloat(setpoint_hi);
              const ll = parseFloat(setpoint_ll);
              const lo = parseFloat(setpoint_lo);
              return (
                <Card
                  key={`card-${index}`}
                  className="machinedashboard-tile"
                  onClick={() =>
                    handleMachineCardClick(
                      id,
                      e.tag.device.id,
                      tag_unit,
                      tag_name,
                      tag_unit
                    )
                  }
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 style={{ fontSize: "15px" }}>{tag_comment}</h6>
                    </div>
                    <div className="d-flex gap-1">
                      {tag > hh ? (
                        <>
                          <div className="triangle-image-container">
                            <img
                              src={images.triandleFillIcon}
                              alt=""
                              className="triangle-image"
                            />
                            <span className="tooltip">HH alarm</span>
                          </div>
                          <div className="triangle-image-container">
                            <img
                              src={images.triandleFillIcon}
                              alt=""
                              className="triangle-image"
                            />
                            <span className="tooltip">HH alarm</span>
                          </div>
                        </>
                      ) : tag > hi ? (
                        <div className="triangle-image-container">
                          <img
                            src={images.triandleFillIcon}
                            alt=""
                            className="triangle-image"
                          />
                          <span className="tooltip">HI alarm</span>
                        </div>
                      ) : tag < ll ? (
                        <>
                          <div className="triangle-image-container">
                            <img
                              src={images.triangleDownIcon}
                              alt=""
                              className="triangle-image"
                            />
                            <span className="tooltip">LL alarm</span>
                          </div>
                          <div className="triangle-image-container">
                            <img
                              src={images.triangleDownIcon}
                              alt=""
                              className="triangle-image"
                            />
                            <span className="tooltip">LL alarm</span>
                          </div>
                        </>
                      ) : tag < lo ? (
                        <div className="triangle-image-container">
                          <img
                            src={images.triangleDownIcon}
                            alt=""
                            className="triangle-image"
                          />
                          <span className="tooltip">LO alarm</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {!tagValue == "" ? (
                        <img
                          src={images.cloudActiveImg}
                          alt=""
                          className="triangle-image"
                        />
                      ) : (
                        <img
                          src={images.cloudImg}
                          alt=""
                          className="triangle-image"
                        />
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h2 className="pt-3">
                        <b>
                          {tagStatusData || tagValue
                            ? tagStatusData || tagValue
                            : 0
                            ? 0
                            : 0}
                          {/* {["DI", "DO"].includes(signal_type)
                            ? tagValue == 0
                              ? low_range || parseFloat(tagValue)
                              : high_range || parseFloat(tagValue)
                            : parseFloat(tagValue)} */}
                        </b>
                      </h2>
                      <h6 className="temperature-description">{tag_name}</h6>
                    </div>
                    {!(
                      signal_type === "DO" ||signal_type === "DI"
                    ) && (
                      <div className="updownArrow">
                        {findStatus ? (
                          findStatus.statusValue.toFixed(2) < 0 ? (
                            <img
                              src={images.smartTagDownArrow}
                              alt="Arrow"
                              style={{ height: "36px" }}
                            />
                          ) : findStatus.statusValue.toFixed(2) != 0 ? (
                            <img
                              src={images.smartTagUpArrow}
                              alt="Arrow"
                              style={{ height: "36px" }}
                            />
                          ) : null
                        ) : null}

                        <h6 className="mt-3">
                          <b>
                            {" "}
                            {findStatus
                              ? findStatus.statusValue.toFixed(2)
                              : 0.0}
                            %
                          </b>
                        </h6>
                      </div>
                    )}
                  </div>
                </Card>
              );
            } else {
              console.error(`e.tag is not an object at index ${index}:`, e.tag);
              return null; // or handle it in another way based on your requirements
            }
          })}
        </Slider>

        <div className="pt-3">
          <div className="Temprature-box">
            <div className="p-3">
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <h6>
                    <b>
                      {selectTagData.tag_config_data &&
                      selectTagData.tag_config_data[0] &&
                      selectTagData.tag_config_data[0]?.tag_name !== null
                        ? selectTagData.tag_config_data[0]?.tag_name
                        : "-"}{" "}
                      :{" "}
                    </b>
                  </h6>
                  <h6 className="machine-temrature-title">
                    {selectTagData.tag_config_data &&
                    selectTagData.tag_config_data[0] &&
                    selectTagData.tag_config_data[0]?.tag_comment !== null
                      ? selectTagData.tag_config_data[0]?.tag_comment
                      : "-"}
                  </h6>
                </div>
                <div className="d-flex">
                  {tagClickebleData.length ? (
                    <button
                      className="smartTag-report-btn"
                      onClick={handleReportClickData}
                    >
                      Report
                    </button>
                  ) : (
                    <button
                      className="smartTag-report-btn"
                      onClick={handleToastMsg}
                    >
                      Report
                    </button>
                  )}
                  {tagClickebleData.length ? (
                    <button
                      className="smartTag-report-btn"
                      onClick={handleAnalyticsData}
                    >
                      Analytics
                    </button>
                  ) : (
                    <button
                      className="smartTag-report-btn"
                      onClick={handleToastMsg}
                    >
                      Analytics
                    </button>
                  )}

                  <h5 className="mb-0 pt-1" style={{ marginLeft: "25px" }}>
                    <b>{smartTagtagMachineName}</b>
                  </h5>
                </div>
              </div>
              <div className="pt-2">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Box className="Temrature-line-graph ">
                      <div className="p-3" style={{ float: "right" }}>
                        <select
                          className="form-input-class"
                          style={{ padding: "6px 8px", borderRadius: "4px" }}
                          id="selectedClass"
                          defaultValue="all"
                          value={selectTimeRange}
                          onChange={(event) =>
                            setSelectTimeRange(event.target.value)
                          }
                          required
                        >
                          <option value="live" selected>
                            Live
                          </option>
                          <option value="1min">1 Min</option>
                          <option value="5min">5 Min</option>
                          <option value="15min">15 Min</option>
                        </select>
                      </div>
                      <div className="pt-5" style={{ width: "100%" }}>
                        <SelectedTagGraphData
                          selectTagUnit={
                            selectTagData.tag_config_data &&
                            selectTagData.tag_config_data[0] &&
                            selectTagData.tag_config_data[0]?.tag_name !== null
                              ? selectTagData.tag_config_data[0]?.tag_name
                              : null
                          }
                          tag_hh={
                            selectTagData.tag_config_data &&
                            selectTagData.tag_config_data[0] &&
                            selectTagData.tag_config_data[0]?.setpoint_hh !==
                              null
                              ? selectTagData.tag_config_data[0]?.setpoint_hh
                              : null
                          }
                          tag_hi={
                            selectTagData.tag_config_data &&
                            selectTagData.tag_config_data[0] &&
                            selectTagData.tag_config_data[0]?.setpoint_hi !==
                              null
                              ? selectTagData.tag_config_data[0]?.setpoint_hi
                              : null
                          }
                          tag_lo={
                            selectTagData.tag_config_data &&
                            selectTagData.tag_config_data[0] &&
                            selectTagData.tag_config_data[0]?.setpoint_lo !==
                              null
                              ? selectTagData.tag_config_data[0]?.setpoint_lo
                              : null
                          }
                          tag_ll={
                            selectTagData.tag_config_data &&
                            selectTagData.tag_config_data[0] &&
                            selectTagData.tag_config_data[0]?.setpoint_ll !==
                              null
                              ? selectTagData.tag_config_data[0]?.setpoint_ll
                              : null
                          }
                          graphRealTimeData={graphRealTimeData}
                        />
                      </div>
                    </Box>
                    <div className="pt-3 d-flex gap-3">
                      <Box className="RealTime-btn p-2">
                        <h6 className="box-real-time-title">Real Time</h6>
                        <h3 className="d-flex box-real-time-value">
                          <b className="box-value">
                            {graphRealTimeData.length
                              ? graphRealTimeData[graphRealTimeData.length - 1][
                                  "tagValue"
                                ]
                              : "-"}
                          </b>{" "}
                          <h6 style={{ marginTop: "-3px" }}>
                            {selectedTagStatus.toFixed(3) < 0 ? (
                              <ArrowDownwardIcon style={{ color: "#E31E24" }} />
                            ) : selectedTagStatus.toFixed(3) != 0 ? (
                              <ArrowUpwardTwoToneIcon
                                style={{ color: "#E31E24" }}
                              />
                            ) : null}
                            {selectedTagStatus.toFixed(3)}%
                          </h6>
                        </h3>
                      </Box>
                      <Box className="RealTime-btn p-2">
                        <h6 className="box-real-time-title">Minimum</h6>
                        <h3 className="box-real-time-value">
                          <b>
                            {selectTagData &&
                            selectTagData.min_tag_value !== null &&
                            selectTagData.min_tag_value !== undefined
                              ? Number(selectTagData.min_tag_value).toFixed(2)
                              : "-"}
                          </b>
                        </h3>
                      </Box>
                      <Box className="RealTime-btn p-2">
                        <h6 className="box-real-time-title">Maximum</h6>
                        <h3 className="box-real-time-value">
                          <b>
                            {selectTagData &&
                            selectTagData.max_tag_value !== null &&
                            selectTagData.max_tag_value !== undefined
                              ? Number(selectTagData.max_tag_value).toFixed(2)
                              : "-"}
                          </b>
                        </h3>
                      </Box>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Box className="Temrature-bar-graph">
                      <div className="p-3 d-flex justify-content-between">
                        <div className="d-flex gap-2">
                          <h6 style={{ marginTop: "11px" }}>HI</h6>
                          <div className="input-field-smartTag">
                            <KeyboardArrowLeftIcon
                              className="arrow left"
                              onClick={decrementValue}
                            />
                            <input
                              style={{
                                padding: "4px",
                                width: "46px",
                                color: "#E53439",
                                fontWeight: "600px",
                              }}
                              value={hiValue}
                              onChange={(e) => {
                                const newValue = parseInt(e.target.value, 10);
                                if (
                                  isNaN(newValue) ||
                                  newValue <= smartTagLowRange ||
                                  newValue > smartTagHIRange
                                ) {
                                  if (e.target.value.trim() === "") {
                                    // Empty or invalid value, clear the input field
                                    setHIValue(""); // or you can set it to a default value if needed
                                  } else {
                                    // Notify error for invalid values
                                    notifyError(
                                      `HI value must be a number greater than Low value and less than ${smartTagHIRange}`
                                    );
                                  }
                                } else {
                                  // Valid value, update state
                                  setHIValue(newValue);
                                }
                              }}
                              onKeyPress={(e) => {
                                // Prevent typing minus (-) character
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                            ></input>
                            <KeyboardArrowRightIcon
                              className="arrow right"
                              onClick={incrementValue}
                            />
                          </div>
                          <h6 style={{ marginLeft: "15px", marginTop: "11px" }}>
                            LO
                          </h6>
                          <div className="input-field-smartTag">
                            <KeyboardArrowLeftIcon
                              className="arrow left"
                              onClick={decrementLowValue}
                            />
                            <input
                              style={{
                                padding: "4px",
                                width: "46px",
                                color: "#ffb732",
                                fontWeight: "600px",
                              }}
                              value={lowValue}
                              onChange={(e) => {
                                const newValue = parseInt(e.target.value, 10);
                                if (
                                  isNaN(newValue) ||
                                  newValue <= smartTagLowRange ||
                                  newValue > smartTagHIRange
                                )
                                  if (e.target.value.trim() === "") {
                                    // Empty or invalid value, clear the input field
                                    setLowValue(""); // or you can set it to a default value if needed
                                  } else {
                                    // Notify error for invalid values
                                    notifyError(
                                      `HI value must be a number greater than Low value and less than ${smartTagLowRange}`
                                    );
                                  }
                                else {
                                  setLowValue(newValue);
                                }
                              }}
                              onKeyPress={(e) => {
                                // Prevent typing minus (-) character
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                            ></input>
                            <KeyboardArrowRightIcon
                              onClick={incrementLowValue}
                              className="arrow right"
                            />
                          </div>
                        </div>

                        <div>
                          <select
                            className="form-input-class"
                            style={{ padding: "6px 8px", borderRadius: "4px" }}
                            id="selectedClass"
                            value={selectRangeGraph}
                            onChange={(e) => handleTimeDuration(e)}
                            required
                          >
                            <option value="Today" selected>
                              Today
                            </option>
                            <option value="Last 15 Minutes">15 Min</option>
                            <option value="Last 1 Hour">1 hour</option>
                            <option value="Last 24 Hour">24 hour</option>
                          </select>
                        </div>
                      </div>
                      <div className="pt-5" style={{ width: "100%" }}>
                        <BarGraph BarGraphData1={barGraphData} />
                      </div>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    container
                    justify="center"
                    alignItems="center"
                  >
                    <div>
                      <Box className="hh-box">
                        <h6 style={{ fontSize: "13px" }}>HH</h6>
                        <h5 style={{ float: "right" }}>
                          <b>
                            {selectTagData.tag_config_data &&
                            selectTagData.tag_config_data[0] &&
                            selectTagData.tag_config_data[0]?.setpoint_hh !==
                              null
                              ? selectTagData.tag_config_data[0]?.setpoint_hh
                              : "-"}
                          </b>
                        </h5>
                      </Box>
                      <Box className="hh-box">
                        <h6 style={{ fontSize: "13px" }}>HI</h6>
                        <h5 style={{ float: "right" }}>
                          <b>
                            {selectTagData.tag_config_data &&
                            selectTagData.tag_config_data[0] &&
                            selectTagData.tag_config_data[0]?.setpoint_hi !==
                              null
                              ? selectTagData.tag_config_data[0]?.setpoint_hi
                              : "-"}
                          </b>
                        </h5>
                      </Box>
                      <Box className="hh-box">
                        <h6 style={{ fontSize: "13px" }}>LO</h6>
                        <h5 style={{ float: "right" }}>
                          <b>
                            {selectTagData.tag_config_data &&
                            selectTagData.tag_config_data[0] &&
                            selectTagData.tag_config_data[0]?.setpoint_lo !==
                              null
                              ? selectTagData.tag_config_data[0]?.setpoint_lo
                              : "-"}
                          </b>
                        </h5>
                      </Box>
                      <Box className="hh-box">
                        <h6 style={{ fontSize: "13px" }}>LL</h6>
                        <h5 style={{ float: "right" }}>
                          <b>
                            {selectTagData.tag_config_data &&
                            selectTagData.tag_config_data[0] &&
                            selectTagData.tag_config_data[0]?.setpoint_ll !==
                              null
                              ? selectTagData.tag_config_data[0]?.setpoint_ll
                              : "-"}
                          </b>
                        </h5>
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <Grid container spacing={1} className="pt-5 mb-3">
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <button
                    className="Process-dashboard-btn"
                    onClick={() =>
                      navigate(
                        `/smarttag-machineoverview/company/${params.companyid}/plant/${params.plantid}`
                      )
                    }
                  >
                    OVERVIEW
                  </button>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <button
                    className="Process-dashboard-btn"
                    onClick={() =>
                      navigate(
                        `/Smarttag-machinereports/company/${params.companyid}/plant/${params.plantid}/report`
                      )
                    }
                  >
                    REPORT
                  </button>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <button
                    className="Process-dashboard-btn"
                    onClick={() =>
                      navigate(
                        `/Smarttag-machineanalytics/company/${params.companyid}/plant/${params.plantid}/analytics`
                      )
                    }
                  >
                    ANALYTICS
                  </button>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <button
                    className="active-process-dashboard"
                    style={{ pointerEvents: "none" }}
                  >
                    ADVANCED ANALYTICS
                  </button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartTagMachineDashboard;
