import { Box, Card, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { images } from "../../config";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DonutChart from "../../container/ProcessDashboard/DonutChart/DonutChart";
import BarChart from "../../container/ProcessDashboard/BarChart";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ShimmerCircularImage ,ShimmerText } from "react-shimmer-effects";
import './PieChartBox.css'
import {PieChartShimmer} from '../Shimmer/PieChartShimmer/PieChartShimmer';
// import {smallParetoChartShimmer} from "../Shimmer/ParetoChartShimmer/ParetoChartShimmer";
import {SmallParetoChartShimmer} from "../Shimmer/ParetoChartShimmer/ParetoChartShimmer";

const PieChartBoxCard = ({ access,activeChart, handleChartClick, startDate, endDate, tagData, dashboard, positionValue, sum, sliderData, sliderColorData, handleStartDate, handleEndDate, PiaChartBorderColor ,ShimmerPieChart,ShimmerBarChart,positionId,mapTagDetails}) => {
  const notifyError = (message) =>
  toast.error(message, {
    theme: "colored",
    position: toast.POSITION.TOP_RIGHT,
    toastClassName: "custom-toast",
  });


  useEffect(()=>{
console.log("ShimmerPieChart",ShimmerPieChart,activeChart)
console.log("ShimmerBarChart",ShimmerBarChart,activeChart)
  },[ShimmerPieChart,ShimmerBarChart])

 
  const { selectUnit } = useSelector((state) => state.dateSliceprocessDashboardState);

  const handleTostMsg =()=>{
    notifyError("You don't have access")
  }
  return (
    <>
      <div className="graph-box ">
        <div className="d-flex">
          <img
            src={
              activeChart === "pie"
                ? images.pieChartBgImg
                : images.pieChart
            }
            alt=""
            className="graph-type-box"
            style={{ cursor: "pointer" }}
            onClick={() =>{if(ShimmerBarChart == false){
              handleChartClick("pie")
              console.log("clicked on pie")
            }
              } }
          />
          <img
            src={
              activeChart === "bar"
                ? images.barChartBgImg
                : images.barChart
            }
            alt=""
            className="graph-type-box"
            style={{ cursor: "pointer" }}
            onClick={() => {if(ShimmerPieChart == false){
              console.log("clicked on bar")
              handleChartClick("bar")
            }
            // if(ShimmerPieChart){
            //   handleChartClick("pie")
            // }
             
            }}

          />
        </div>
      {access?.is_viewer && access?.is_editor || !access?.is_viewer && access?.is_editor   || (access?.is_viewer && !access?.is_editor)? 
      <>
        <div
          className="graph-date pt-3"
          style={{ justifyContent: "flex-end" }}
        >
          <Box className="">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="custom-datepicker"
                label="Start Date"
                value={startDate}
                inputFormat="dd/MM/yyyy" // Set the input format to "dd/MM/yyyy"
                
                onChange={(newValue) =>
                  handleStartDate(newValue)
                }
                disableFuture
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="datepicker-input-box"
                    style={{ background: "#ffff" }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box
            className=""
            style={{ marginRight: "11px" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="custom-datepicker"
                label="End Date"
                value={endDate}
                inputFormat="DD/MM/YYYY"
                onChange={(newValue) => handleEndDate(newValue)}
                disableFuture
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="datepicker-input-box"
                    style={{ background: "#ffff" }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </div>
        </>
        :
        <div
        className="graph-date pt-3"
     
        style={{ justifyContent: "flex-end" }}
      >
        <Box className="" >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="custom-datepicker"
              label="Start Date"
              value={startDate}
              inputFormat="dd/MM/yyyy" // Set the input format to "dd/MM/yyyy"
              
              onChange={(newValue) =>
                handleTostMsg()
              }
              disableFuture
              renderInput={(params) => (
                <TextField
                  {...params}
                    className="datepicker-input-box"
                  style={{ background: "#ffff" }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box
          className=""
          style={{ marginRight: "11px" }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="custom-datepicker"
              label="End Date"
              value={endDate}
              inputFormat="DD/MM/YYYY"
              
              onChange={() =>
                handleTostMsg()
              }
              disableFuture
              renderInput={(params) => (
                <TextField
                  {...params}
                    className="datepicker-input-box"
                  style={{ background: "#ffff" }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </div>
      
        }
      </div>

      <div

      >
        {!ShimmerPieChart ?  activeChart === "pie" && (
          <DonutChart
            tagData={tagData}
            dashboard={
              dashboard
            }
            positionValue={positionValue}
            sum={sum}
            PiaChartBorderColor={PiaChartBorderColor}
            selectUnit={selectUnit}
            positionId={positionId}
            mapTagDetails={mapTagDetails}
          />
        ): <div className="Shimmer-DonutChart"><PieChartShimmer size="250px" position="relative" /></div>
      }
      </div>
      <div

      >

        {!ShimmerBarChart ?  activeChart === "bar" && (
          <BarChart
            tagData={tagData}
            dashboard={dashboard}
            positionValue={positionValue}
            PiaChartBorderColor={PiaChartBorderColor}
            positionId={positionId}
            mapTagDetails={mapTagDetails}
          />
       
        ):  <SmallParetoChartShimmer className="ParetoChartShimmer-small"/> }
      </div>


    </>
  )
}

export default PieChartBoxCard



{/* <ShimmerCircularImage size={250} center={true} className={"Shimmer-DonutChart"}/>  */}


{/* <div className="Shimmer-DonutChart"><PieChartShimmer size="250px" position="relative" /></div>  */}


{/* <ShimmerText line={5} gap={10} /> */}

{/* <ParetoChartShimmer className="ParetoChartShimmer-OverView"/> */}