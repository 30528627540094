import React from "react";
import "./ScriptionlistShimmer.css";

const DynamicShimmer = ({ blocks, count }) => {
  // Define the types of blocks you need based on the expected content

  // const blocks = [
  // 	"medium",
  // 	"short",
  // 	"medium",
  // 	"short",
  // 	"long",
  // 	"short",
  // 	"long",
  // 	"short",
  // ];
  const defaultBlocks = ["long", "medium", "short", "medium", "long", "short"];
  const shimmerBlocks = blocks || defaultBlocks;
  const shimmerSets = Array.from({ length: count }, () => shimmerBlocks);


  return (
    <>
      {shimmerSets
        ? shimmerSets.map(() => {
            return (
              <>
                <div className="datalist-dynamic-shimmer">
                  {shimmerBlocks.map((block, index) => (
                    <div
                      key={index}
                      className={`datalist-shimmer-block ${block}`}
                    ></div>
                  ))}
                </div>
              </>
            );
          })
        : []}
    </>
  );
};

export default DynamicShimmer;
