import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";

HC_more(Highcharts); // Initialize the 'highcharts-more' module
exporting(Highcharts); // Initialize the 'exporting' module

const SelectedTagGraphData = ({
  graphRealTimeData,
  selectTagUnit,
  tag_hh,
  tag_hi,
  tag_lo,
  tag_ll,
}) => {

  const [optionCahrt, setOptionChart] = useState({
    chart: {
      type: "spline",

    },
    title: {
      text: null,
    },
    xAxis: {
      categories: [],
      scrollbar: {
        enabled: true,
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      plotLines: [],
    },
    series: [],
    colors: ["#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#FFFF00"],
    exporting: {
      enabled: true, // Disable exporting options
    },
    credits: {
      enabled: false, // Disable credits
    },
  });

  const seriesFortag = [
    {
      name: selectTagUnit,
      data: graphRealTimeData.length
        ? graphRealTimeData.map((row) => parseFloat(row.tagValue))
        : [],
      color: "#C87171",
      dashStyle: "Solid",
      lineWidth: 1,
    },
  ];

  const seriesData = [...seriesFortag];
  const categories = graphRealTimeData.length
    ? graphRealTimeData.map((row) => row.tagTime)
    : [];

  useEffect(() => {
    setOptionChart({
      ...optionCahrt,
      xAxis: {
        ...optionCahrt.xAxis,
        categories: categories
      },
      yAxis: {
        title: {
          text: selectTagUnit,
        },
        plotLines: [
          {
            value: tag_hh,
            color: "red",
            dashStyle: "ShortDash",
            width: 2,
            label: {
              text: `HH(${tag_hh})`,
              align: "left",
              x: 10, // x offset of label
            },
            zIndex: -1, // Set zIndex to position the plot line behind the chart
          },
          {
            value: tag_hi,
            color: "red",
            dashStyle: "ShortDash",
            width: 2,
            label: {
              text: `HI(${tag_hi})`,
              align: "left",
              x: 10, // x offset of label
            },
            zIndex: -1, // Set zIndex to position the plot line behind the chart
          },
          {
            value: tag_lo,
            color: "#F7C51E",
            dashStyle: "ShortDash",
            width: 2,
            label: {
              text: `LO(${tag_lo})`,
              align: "left",
              x: 10, // x offset of label
            },
            yAxis: {
              ...optionCahrt.yAxis,
              title: {
                text: selectTagUnit,
              },
              plotLines: [
                {
                  value: tag_hh,
                  color: "red",
                  dashStyle: "ShortDash",
                  width: 2,
                  label: {
                    text: `HH(${tag_hh})`,
                    align: "left",
                    x: 10, // x offset of label
                  },
                  zIndex: -1, // Set zIndex to position the plot line behind the chart
                },
                {
                  value: tag_hi,
                  color: "#F7C51E",
                  dashStyle: "ShortDash",
                  width: 2,
                  label: {
                    text: `HI(${tag_hi})`,
                    align: "left",
                    x: 10, // x offset of label
                  },
                  zIndex: -1, // Set zIndex to position the plot line behind the chart
                },
                {
                  value: tag_lo,
                  color: "#F7C51E",
                  dashStyle: "ShortDash",
                  width: 2,
                  label: {
                    text: `LO(${tag_lo})`,
                    align: "left",
                    x: 10, // x offset of label
                  },
                  zIndex: -1, // Set zIndex to position the plot line behind the chart
                },
                {
                  value: tag_ll,
                  color: "red",
                  dashStyle: "ShortDash",
                  width: 2,
                  label: {
                    text: `LL(${tag_ll})`,
                    align: "left",
                    x: 10, // x offset of label
                  },
                  zIndex: -1, // Set zIndex to position the plot line behind the chart
                }
              ],
            },
            zIndex: -1, // Set zIndex to position the plot line behind the chart
          },
          {
            value: tag_ll,
            color: "#F7C51E",
            dashStyle: "ShortDash",
            width: 2,
            label: {
              text: `LL(${tag_ll})`,
              align: "left",
              x: 10, // x offset of label
            },
            zIndex: -1, // Set zIndex to position the plot line behind the chart
          },
        ],
      },
      series: seriesData,
    });
  }, [selectTagUnit, graphRealTimeData]);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={optionCahrt}
          containerProps={{ style: { width: "100%" } }} // Set width to 100%
        />
      </div>
    </div>
  );
};

export default SelectedTagGraphData;
