import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, TextField } from "@mui/material";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  getAdvanceAnalyticsProductionAvailabilityCOCDataApi,
  getAdvanceAnalyticsProductionAvailabilityCOCPieChartDataApi,
} from "../../../../../ProcessDashboard/AdvanceAnalytics/services";
import { ProductionPOPChart } from "../../../../../ProcessDashboard/AdvanceAnalytics/Graph/ProductionGroupChartPOP";
import { ProductionPiechartPOP } from "../../../../../ProcessDashboard/AdvanceAnalytics/Graph/ProductionPieChartPOP";
import { ProductionPiechartPOPDownTime } from "../../../../../ProcessDashboard/AdvanceAnalytics/Graph/ProductionPOPdownTime";
import { ProductionCOC } from "../../../../../ProcessDashboard/AdvanceAnalytics/Graph/ProductionCOC";
import { ProductionPiechartCOC } from "../../../../../ProcessDashboard/AdvanceAnalytics/Graph/PieChartCOC";
import { ProductionPiechartCOCDownTime } from "../../../../../ProcessDashboard/AdvanceAnalytics/Graph/PieChartCOCDownTime";
import COCDataList from "../../../../../../components/COCData/COCData";
import POPDataList from "../../../../../../components/POPData/POPData";
import POPDatePicker from "../../../../../../components/POPDatePicker/POPDatePicker";
import COCDatePicker from "../../../../../../components/COCDatepicker/COCDatePicker";
import COCPOPTabs from "../../../../../../components/Tabs/Tabs";
import { setVmsData } from "../../../vmsSlice/vmsSlice";
import { getDeviceByType, getDeviceDashboard } from "../../Reports/service";
import { COCParetoGraph } from "../../../../../../components/VmsCOCParetoGraph/COCParetoGraph";
import { VmsCOCPieChart } from "../../../../../../components/VmsCOCPieChartUpTime/VmsCOCPieChart";
import { VmsPieChartDownTime } from "../../../../../../components/VmsPieChartDownTime/VmsPieChartDownTime";
import { VmsPOPBarChart } from "../../../../../../components/VmsPOPBarChart/VmsPOPBarChart";
import { VmsUpTimePieChart } from "../../../../../../components/VmsPOPUpTimePieChart/VmsPOPUpTimePieChart";
import { VmsPOPDownTimePieChart } from "../../../../../../components/VmsPOPDownTimePieChart";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { images } from "../../../../../../config";
import { ToastContainer, toast } from "react-toastify";
import COCVmsDatePicker from "../../../../VMSComponent/COCVmsDatePicker";
import POPVmsDatePicker from "../../../../VMSComponent/POPVmsDatePicker";
import { getDeviceByDashboard } from "../../../../../SmartTag/services";
// import "../../OverView/OverView.css"

const Availability = () => {
  const [isLoading, setLoading] = useState(false);
  const [active, setActive] = useState("Consumer");
  const [deviceId, setDeviceId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [deviceData, setDeviceData] = useState([]);
  const [otherDeviceData, setOtherDeviceData] = useState([]);
  const [tagUnitData, setTagUnitData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [availabilityCOCBarData, setAvailabilityCOCBarData] = useState({});
  const [availabilityPOPBarData, setAvailabilityPOPBarData] = useState({});
  const [availabilityPOP1BarData, setAvailabilityPOP1BarData] = useState({});
  const [AvailabilityCOCPieChartData, setAvailabilityCOCPieChartData] =
    useState([]);
  const [AvailabilityPOPPieChartData, setAvailabilityPOPPieChartData] =
    useState("");
  const [AvailabilityPOP1PieChartData, setAvailabilityPOP1PieChartData] =
    useState("");
  const [isShimmer, setShimmer] = useState(false);
  const [isShimmerCOCPie, setShimmerCOCPie] = useState(false);
  const [isShimmerPOP, setShimmerPOP] = useState(false);
  const [isShimmerPOPPie, setShimmerPOPPie] = useState(false);

  const [isShimmerdevicelist, setisShimmerdevicelist] = useState(false);

  const {
    vmsAvailabilityCOCstartDate,
    vmsAvailabilityCOCendDate,
    vmsAvailabilityPOPstartDate,
    vmsAvailabilityPOPendDate,
    vmsAvailabilityPOP1startDate,
    vmsAvailabilityPOP1endDate,
    vmsAvailabilityCOCDataId,
    vmsMachineColor,
    vmsAvailabilityPOPDataId,
    vmsPOPMachineColor,
  } = useSelector((state) => state.vmsState);
  const { user_access } = useSelector((state) => state.userState);
  const dateFormat = "YYYY-MM-DD";
  const navigate = useNavigate();
  const param = useParams();
  const MAX_DATE_RANGE_DAYS = 365; // Maximum duration of one year in days

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const startDateReFormat = vmsAvailabilityCOCstartDate
    ? dayjs(vmsAvailabilityCOCstartDate)
    : null;
  const endDateReFormat = vmsAvailabilityCOCendDate
    ? dayjs(vmsAvailabilityCOCendDate)
    : null;

  const startDatePOPFormat = vmsAvailabilityPOPstartDate
    ? dayjs(vmsAvailabilityPOPstartDate)
    : null;
  const endDatePOPFormat = vmsAvailabilityPOPendDate
    ? dayjs(vmsAvailabilityPOPendDate)
    : null;

  const startDatePOP1Format = vmsAvailabilityPOP1startDate
    ? dayjs(vmsAvailabilityPOP1startDate)
    : null;
  const endDatePOP1Format = vmsAvailabilityPOP1endDate
    ? dayjs(vmsAvailabilityPOP1endDate)
    : null;

  const formatStartDate = vmsAvailabilityCOCstartDate
    ? dayjs(vmsAvailabilityCOCstartDate).format(dateFormat)
    : "";

  const formatEndDate = vmsAvailabilityCOCendDate
    ? dayjs(vmsAvailabilityCOCendDate).format(dateFormat)
    : "";

  const formatstartDatePOP = vmsAvailabilityPOPstartDate
    ? dayjs(vmsAvailabilityPOPstartDate).format(dateFormat)
    : "";
  const formatEndDatePOP = vmsAvailabilityPOPendDate
    ? dayjs(vmsAvailabilityPOPendDate).format(dateFormat)
    : "";

  const formatstartDatePOP1 = vmsAvailabilityPOP1startDate
    ? dayjs(vmsAvailabilityPOP1startDate).format(dateFormat)
    : "";
  const formatEndDatePOP1 = vmsAvailabilityPOP1endDate
    ? dayjs(vmsAvailabilityPOP1endDate).format(dateFormat)
    : "";

  const handleStartDate = (newValue) => {
    const durationInDays = endDateReFormat
      ? endDateReFormat.diff(newValue, "days")
        ? endDateReFormat.diff(newValue, "days") > 0
          ? endDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(setVmsData({ vmsAvailabilityCOCstartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormat && newValue.isBefore(endDateReFormat)) {
      // Start date is before end date
      dispatch(setVmsData({ vmsAvailabilityCOCstartDate: newValue }));
    } else if (!endDateReFormat) {
      // End date is not set yet
      dispatch(setVmsData({ vmsAvailabilityCOCstartDate: newValue }));
    } else if (endDateReFormat && newValue.isSame(endDateReFormat, "day")) {
      // End date is not set yet
      dispatch(setVmsData({ vmsAvailabilityCOCstartDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setVmsData({ vmsAvailabilityCOCstartDate: null }));
      notifyError("End date must be greater than start date");
    }

    // dispatch(setVmsData({ vmsAvailabilityCOCstartDate: newValue }));
  };

  const handleEndDate = (newValue) => {
    const durationInDays = startDateReFormat
      ? startDateReFormat.diff(newValue, "days")
        ? startDateReFormat.diff(newValue, "days") > 0
          ? startDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setVmsData({ vmsAvailabilityCOCendDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormat && newValue.isAfter(startDateReFormat)) {
      dispatch(setVmsData({ vmsAvailabilityCOCendDate: newValue }));
    } else if (!startDateReFormat) {
      dispatch(setVmsData({ vmsAvailabilityCOCendDate: newValue }));
    } else if (startDateReFormat && newValue.isSame(startDateReFormat, "day")) {
      // End date is not set yet
      dispatch(setVmsData({ vmsAvailabilityCOCendDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setVmsData({ vmsAvailabilityCOCendDate: null }));
      notifyError(" startDate must less than endDate");
    }

    // dispatch(setVmsData({ vmsAvailabilityCOCendDate: newValue }));
  };

  const handlePOPStartDate = (newValue) => {
    const durationInDays = endDatePOPFormat
      ? endDatePOPFormat.diff(newValue, "days")
        ? endDatePOPFormat.diff(newValue, "days") > 0
          ? endDatePOPFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(setVmsData({ vmsAvailabilityPOPstartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOPFormat && newValue.isBefore(endDatePOPFormat)) {
      // Start date is before end date
      dispatch(setVmsData({ vmsAvailabilityPOPstartDate: newValue }));
    } else if (!endDatePOPFormat) {
      // End date is not set yet
      dispatch(setVmsData({ vmsAvailabilityPOPstartDate: newValue }));
    } else if (endDatePOPFormat && newValue.isSame(endDatePOPFormat, "day")) {
      // End date is not set yet
      dispatch(setVmsData({ vmsAvailabilityPOPstartDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setVmsData({ vmsAvailabilityPOPstartDate: null }));
      notifyError("End date must be greater than start date");
    }

    // dispatch(setVmsData({ vmsAvailabilityPOPstartDate: newValue }));
  };

  const handlePOPEndDate = (newValue) => {
    const durationInDays = startDatePOPFormat
      ? startDatePOPFormat.diff(newValue, "days")
        ? startDatePOPFormat.diff(newValue, "days") > 0
          ? startDatePOPFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setVmsData({ vmsAvailabilityPOPendDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOPFormat && newValue.isAfter(startDatePOPFormat)) {
      dispatch(setVmsData({ vmsAvailabilityPOPendDate: newValue }));
    } else if (!startDatePOPFormat) {
      dispatch(setVmsData({ vmsAvailabilityPOPendDate: newValue }));
    } else if (
      startDatePOPFormat &&
      newValue.isSame(startDatePOPFormat, "day")
    ) {
      // End date is not set yet
      dispatch(setVmsData({ vmsAvailabilityPOPendDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setVmsData({ vmsAvailabilityPOPendDate: null }));
      notifyError(" startDate must less than endDate");
    }

    // dispatch(setVmsData({ vmsAvailabilityPOPendDate: newValue }));
  };

  const handlePOP1StartDate = (newValue) => {
    const durationInDays = endDatePOP1Format
      ? endDatePOP1Format.diff(newValue, "days")
        ? endDatePOP1Format.diff(newValue, "days") > 0
          ? endDatePOP1Format.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(setVmsData({ vmsAvailabilityPOP1startDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDatePOP1Format && newValue.isBefore(endDatePOP1Format)) {
      // Start date is before end date
      dispatch(setVmsData({ vmsAvailabilityPOP1startDate: newValue }));
    } else if (!endDatePOP1Format) {
      // End date is not set yet
      dispatch(setVmsData({ vmsAvailabilityPOP1startDate: newValue }));
    } else if (endDatePOP1Format && newValue.isSame(endDatePOP1Format, "day")) {
      // End date is not set yet
      dispatch(setVmsData({ vmsAvailabilityPOP1startDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setVmsData({ vmsAvailabilityPOP1startDate: null }));
      notifyError("End date must be greater than start date");
    }

    // dispatch(setVmsData({ vmsAvailabilityPOP1startDate: newValue }));
  };

  const handlePOP1EndDate = (newValue) => {
    const durationInDays = startDatePOP1Format
      ? startDatePOP1Format.diff(newValue, "days")
        ? startDatePOP1Format.diff(newValue, "days") > 0
          ? startDatePOP1Format.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setVmsData({ vmsAvailabilityPOP1endDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDatePOP1Format && newValue.isAfter(startDatePOP1Format)) {
      dispatch(setVmsData({ vmsAvailabilityPOP1endDate: newValue }));
    } else if (!startDatePOP1Format) {
      dispatch(setVmsData({ vmsAvailabilityPOP1endDate: newValue }));
    } else if (
      startDatePOP1Format &&
      newValue.isSame(startDatePOP1Format, "day")
    ) {
      // End date is not set yet
      dispatch(setVmsData({ vmsAvailabilityPOP1endDate: newValue }));
    } else {
      // Start date is after end date
      dispatch(setVmsData({ vmsAvailabilityPOP1endDate: null }));
      notifyError(" startDate must less than endDate");
    }

    // dispatch(setVmsData({ vmsAvailabilityPOP1endDate: newValue }));
  };

  const switchColors = [
    "#FEBB22",
    "#002B2A",
    "#C99C00",
    "#EB9E79",
    "#69927A",
    "#872B81",
    "#F06292",
    "#0EB520",
    "#CD4D4A",
    "#123782",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#C60BC8",
    "#7D2264",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#0C4562",
    "#001A30",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#075571",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#0B648D",
    "#29445A",
    "#1D733F",
    "#6E02B1",
  ];

  useEffect(() => {
    const findPlantByDevice = vmsAvailabilityPOPDataId
      ? vmsAvailabilityPOPDataId.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantByDevice.length) {
      handleAnalyticsPOPBarChart();
    } else {
      setAvailabilityPOPBarData({});
    }
  }, [vmsAvailabilityPOPDataId, formatstartDatePOP, formatEndDatePOP]);

  useEffect(() => {
    const findPlantByDevice = vmsAvailabilityPOPDataId
      ? vmsAvailabilityPOPDataId.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantByDevice.length) {
      handleAnalyticsPOP1BarChart();
    } else {
      setAvailabilityPOP1BarData({});
    }
  }, [vmsAvailabilityPOPDataId, formatstartDatePOP1, formatEndDatePOP1]);

  useEffect(() => {
    const findPlantDevice = vmsAvailabilityPOPDataId.length
      ? vmsAvailabilityPOPDataId
          .filter((f) => f.plantId == param.id)
          .map((e) => e.deviceId)
      : [];

    if (findPlantDevice.length && formatEndDatePOP && formatstartDatePOP) {
      handleAnalyticsPOPpieChartData();
    } else {
      setAvailabilityPOPPieChartData([]);
    }
  }, [vmsAvailabilityPOPDataId, formatEndDatePOP, formatstartDatePOP]);

  useEffect(() => {
    const findPlantDevice = vmsAvailabilityPOPDataId
      ? vmsAvailabilityPOPDataId
          .filter((f) => f.plantId == param.id)
          .map((e) => e.deviceId)
      : [];
    if (findPlantDevice.length && formatEndDatePOP1 && formatstartDatePOP1) {
      handleAnalyticsPOP1pieChartData();
    } else {
      setAvailabilityPOP1PieChartData([]);
    }
  }, [vmsAvailabilityPOPDataId, formatEndDatePOP1, formatstartDatePOP1]);

  useEffect(() => {
    const findPlantDevice = vmsMachineColor
      ? vmsMachineColor
          .filter((f) => f.plantId == param.id)
          .map((e) => e.deviceId)
      : [];

    if (findPlantDevice.length && formatStartDate && formatEndDate) {
      getAdvanceAnalyticsProductionAvailabilityCOCApi();
      getAdvanceAnalyticsProductionAvailabilityCOCPieChartApi();
    } else {
      setAvailabilityCOCBarData({});
      setAvailabilityCOCPieChartData({});
    }
  }, [
    vmsMachineColor,
    vmsAvailabilityCOCDataId,
    formatStartDate,
    formatEndDate,
  ]);

  const dispatch = useDispatch();

  const handleSelectDeviceId = (id, index, event, row) => {
    const existingIndex = vmsMachineColor.findIndex(
      (color) => color.deviceId === row.device && color.plantId == param.id
    );

    if (existingIndex !== -1) {
      const updatedColors = [...vmsMachineColor];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setVmsData({
          vmsMachineColor: updatedColors,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setVmsData({
          vmsMachineColor: [
            ...vmsMachineColor,
            {
              deviceId: row.device,
              dvName: row.device__device_name,
              color: event.target.name,
              plantId: param.id,
            },
          ],
        })
      );
    }

    if (!Array.isArray(vmsAvailabilityCOCDataId)) {
      setDeviceId([]);
      dispatch(setVmsData({ vmsAvailabilityCOCDataId: [] }));
    }
    if (vmsAvailabilityCOCDataId.includes(id)) {
      // setDeviceId(vmsAvailabilityCOCDataId.filter((element) => element !== id));
      dispatch(
        setVmsData({
          vmsAvailabilityCOCDataId: vmsAvailabilityCOCDataId.filter(
            (element) => element !== id
          ),
        })
      );
    } else {
      // setDeviceId([...vmsAvailabilityCOCDataId, id]);
      dispatch(
        setVmsData({
          vmsAvailabilityCOCDataId: [...vmsAvailabilityCOCDataId, id],
        })
      );
    }
  };

  const handleSelectPOPDeviceId = (id, index, event, row) => {
    const existingIndex = vmsPOPMachineColor.findIndex(
      (color) => color.deviceId === row.device && color.plantId == param.id
    );

    const existingIndexDevice = vmsAvailabilityPOPDataId.findIndex(
      (color) => color.deviceID === row.device && color.plantId == param.id
    );

    if (existingIndex !== -1 && existingIndexDevice !== -1) {
      // If the selected device already exists, remove it
      const updatedColors = [...vmsPOPMachineColor];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setVmsData({
          vmsPOPMachineColor: updatedColors,
        })
      );

      // Unselect the device
      const updateDevice = [...vmsAvailabilityPOPDataId];
      updateDevice.splice(existingIndexDevice, 1);
      dispatch(setVmsData({ vmsAvailabilityPOPDataId: updateDevice }));
    } else {
      // Remove any previously selected devices
      // dispatch(setVmsData({ vmsPOPMachineColor: [] }));
      const existingId = vmsAvailabilityPOPDataId
        ? vmsAvailabilityPOPDataId.find((f) => f.plantId == param.id)
          ? true
          : false
        : false;

      if (existingId) {
        return true;
      }

      // Add the selected device to the state array
      dispatch(
        setVmsData({
          vmsPOPMachineColor: [
            {
              deviceId: row.device,
              dvName: row.device__device_name,
              color: event.target.name,
              plantId: param.id,
            },
          ],
        })
      );

      // Set the selected device as the only device in the array
      dispatch(
        setVmsData({
          vmsAvailabilityPOPDataId: [
            ...vmsAvailabilityPOPDataId,
            { plantId: param.id, deviceID: id },
          ],
        })
      );
    }
  };

  const getAdvanceAnalyticsProductionAvailabilityCOCApi = async () => {
    const findPlantDevice = vmsMachineColor
      ? vmsMachineColor
          .filter((f) => f.plantId == param.id)
          .map((e) => e.deviceId)
      : [];
    const paramReq = {
      device: findPlantDevice,
      start_date: formatStartDate,
      end_date: formatEndDate,
    };

    try {
      setShimmer(true);
      const resp = await getAdvanceAnalyticsProductionAvailabilityCOCDataApi(
        paramReq
      );

      if (resp.status === 200 || resp.status === 201) {
        setAvailabilityCOCBarData(resp.data.payload);
        setShimmer(false);
        console.log("respresp", resp);
      } else {
        console.error("Received unexpected status:", resp.status);
      }
    } catch (error) {
      setShimmer(false);
      console.error("An error occurred:", error);
      // You can also set a state to indicate that an error occurred, if needed.
      // setErrorState(true);
    }
  };

  const handleAnalyticsPOPBarChart = async () => {
    const findPlantByDevice = vmsAvailabilityPOPDataId
      ? vmsAvailabilityPOPDataId.filter((f) => f.plantId == param.id)
      : [];

    if (findPlantByDevice.length && formatstartDatePOP && formatEndDatePOP) {
      const params = {
        device: findPlantByDevice.map((row) => row.deviceID),
        start_date: formatstartDatePOP,
        end_date: formatEndDatePOP,
      };
      try {
        setShimmerPOP(true);
        const resp = await getAdvanceAnalyticsProductionAvailabilityCOCDataApi(
          params
        );
        if (resp.data.success === true) {
          setShimmerPOP(false);
          setAvailabilityPOPBarData(resp.data.payload);
        }
      } catch (error) {
        setShimmerPOP(false);
        console.log("handleAnalyticsData", error);
      }
    }
  };

  const handleAnalyticsPOP1BarChart = async () => {
    const findPlantByDevice = vmsAvailabilityPOPDataId
      ? vmsAvailabilityPOPDataId.filter((f) => f.plantId == param.id)
      : [];

    if (findPlantByDevice.length && formatstartDatePOP1 && formatEndDatePOP1) {
      const params = {
        device: findPlantByDevice.map((row) => row.deviceID),
        start_date: formatstartDatePOP1,
        end_date: formatEndDatePOP1,
      };
      try {
        setShimmerPOP(true);
        const resp = await getAdvanceAnalyticsProductionAvailabilityCOCDataApi(
          params
        );
        if (resp.data.success === true) {
          setShimmerPOP(false);
          setAvailabilityPOP1BarData(resp.data.payload);
        }
      } catch (error) {
        setShimmerPOP(false);
        console.log("handleAnalyticsData", error);
      }
    }
  };

  const handleAnalyticsPOPpieChartData = async () => {
    const findPlantByDevice = vmsAvailabilityPOPDataId
      ? vmsAvailabilityPOPDataId.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantByDevice.length && formatstartDatePOP && formatEndDatePOP) {
      const params = {
        device: findPlantByDevice.map((row) => row.deviceID),
        start_date: formatstartDatePOP,
        end_date: formatEndDatePOP,
      };
      try {
        setShimmerPOPPie(true);
        const resp =
          await getAdvanceAnalyticsProductionAvailabilityCOCPieChartDataApi(
            params
          );
        if (resp.data.success == true) {
          setShimmerPOPPie(false);
          setAvailabilityPOPPieChartData(resp.data.payload);
        }
      } catch (error) {
        setShimmerPOPPie(false);
        console.log("handleSubmit", error);
      }
    }
  };

  const handleAnalyticsPOP1pieChartData = async () => {
    const findPlantByDevice = vmsAvailabilityPOPDataId
      ? vmsAvailabilityPOPDataId.filter((f) => f.plantId == param.id)
      : [];
    if (findPlantByDevice.length && formatstartDatePOP1 && formatEndDatePOP1) {
      const params = {
        device: findPlantByDevice.map((row) => row.deviceID),
        start_date: formatstartDatePOP1,
        end_date: formatEndDatePOP1,
      };
      try {
        setShimmerPOPPie(true);
        const resp =
          await getAdvanceAnalyticsProductionAvailabilityCOCPieChartDataApi(
            params
          );
        if (resp.data.success == true) {
          setShimmerPOPPie(false);
          setAvailabilityPOP1PieChartData(resp.data.payload);
        }
      } catch (error) {
        setShimmerPOPPie(false);
        console.log("handleSubmit", error);
      }
    }
  };

  const getAdvanceAnalyticsProductionAvailabilityCOCPieChartApi = async () => {
    const findPlantDevice = vmsMachineColor
      ? vmsMachineColor
          .filter((f) => f.plantId == param.id)
          .map((e) => e.deviceId)
      : [];

    const paramReq = {
      device: findPlantDevice,
      start_date: formatStartDate,
      end_date: formatEndDate,
    };

    try {
      setShimmerCOCPie(true);
      const resp =
        await getAdvanceAnalyticsProductionAvailabilityCOCPieChartDataApi(
          paramReq
        );

      if (resp.status === 200 || resp.status === 201) {
        setShimmerCOCPie(false);
        setAvailabilityCOCPieChartData(resp.data.payload);
      } else {
        console.error("Received unexpected status:", resp.status);
      }
    } catch (error) {
      setShimmerCOCPie(false);
      console.error("An error occurred:", error);
      // You can also set a state to indicate that an error occurred, if needed.
      // setErrorState(true);
    }
  };
  useEffect(() => {
    getDeviceByPlant();
  }, []);

  const getDeviceByPlant = async () => {
    setisShimmerdevicelist(true);

    const paramReq = {
      dashboard: 2,
      plant: param.plantId,
    };
    try {
      const resp = await getDeviceDashboard(paramReq);
      if (resp.status == 200 || resp.status == 201) {
        setTimeout(() => {
          setisShimmerdevicelist(false);
        }, 1000);

        setOtherDeviceData(resp.data.payload.device);
        setTagData(resp.data.payload.tag_unit);
      }
    } catch (error) {
      setisShimmerdevicelist(false);
      console.log("Error from GetSmartTagMachine", error);
    }
  };

  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;
  const analyticsAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Advance Analytics"
        )
      : null
    : null;

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };
  return (
    <>
      <div className="page-wraper">
        <div className="page-header page-header-analytics">
          <div
            onClick={() =>
              navigate(
                `/vms-advance-analytics/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`
              )
            }
            className="page-back-btn"
          >
            <ArrowBackIcon />
            <span>Advanced Analytics ~ Availability</span>
          </div>
        </div>

        <>
          <div className="analysis-tabs-btn analysis-tabs-btn-analytics pt-3">
            <COCPOPTabs active={active} setActive={setActive} />
            <div
              className="analytics-aggregation analytics-datepicker"
              style={{ justifyContent: "flex-end" }}
            >
              {active == "Consumer" ? (
                <>
                  <COCVmsDatePicker
                    startDateReFormat={startDateReFormat}
                    endDateReFormat={endDateReFormat}
                    handleStartDate={handleStartDate}
                    handleEndDate={handleEndDate}
                    access={analyticsAccess}
                  />
                </>
              ) : (
                <>
                  <POPVmsDatePicker
                    startDateReFormat={startDatePOPFormat}
                    endDateReFormat={endDatePOPFormat}
                    startDate1ReFormat={startDatePOP1Format}
                    endDate1ReFormat={endDatePOP1Format}
                    handleStartDate={handlePOPStartDate}
                    handleEndDate={handlePOPEndDate}
                    handlePOP1StartDate={handlePOP1StartDate}
                    handlePOP1EndDate={handlePOP1EndDate}
                    access={analyticsAccess}
                  />
                </>
              )}
            </div>
          </div>

          <div className="pt-2 d-flex gap-3 consumption-grid-card">
            {active == "Consumer" ? (
              <Grid item xs={12} sm={12} md={3}>
                <COCDataList
                  deviceData={otherDeviceData}
                  switchColors={switchColors}
                  handleSelectDeviceId={handleSelectDeviceId}
                  vmsAvailabilityCOCDataId={vmsAvailabilityCOCDataId}
                  vmsMachineColor={vmsMachineColor}
                  access={analyticsAccess}
                  handleTostMsg={handleTostMsg}
                  isShimmerdevicelist={isShimmerdevicelist}
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={3}>
                <POPDataList
                  deviceData={otherDeviceData}
                  switchColors={switchColors}
                  handleSelectPOPDeviceId={handleSelectPOPDeviceId}
                  vmsAvailabilityPOPDataId={vmsAvailabilityPOPDataId}
                  vmsPOPMachineColor={vmsPOPMachineColor}
                  access={analyticsAccess}
                  handleTostMsg={handleTostMsg}
                  isShimmerdevicelist={isShimmerdevicelist}
                />
              </Grid>
            )}
            {active == "Consumer" ? (
              <Grid item xs={12} sm={12} md={9}>
                <Box
                  className="consumerEnergyData-box"
                  style={{ minHeight: "717px" }}
                >
                  <div style={{ minHeight: "322px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6>
                        <b>Pareto Chart - Consumer over Consumer</b>
                      </h6>
                      <div className="d-flex">
                        <img src={images.DownTime} alt="" height="20px" />{" "}
                        <h6
                          style={{
                            marginLeft: "10px",
                            fontWeight: 400,
                            fontSize: "14px",
                            marginTop: "2px",
                          }}
                        >
                          Down Time
                        </h6>
                      </div>
                    </div>

                    <COCParetoGraph
                      availabilityCOCBarData={availabilityCOCBarData}
                      vmsMachineColor={vmsMachineColor}
                      shimmer={isShimmer}
                    />
                  </div>
                  <div>
                    <h6>
                      <b>Pie Chart - Consumer over Consumer</b>
                    </h6>
                    {/* <div className="d-flex position-relative">
                      <VmsCOCPieChart
                        AvailabilityCOCPieChartData={
                          AvailabilityCOCPieChartData
                        }
                        shimmer={isShimmerCOCPie}
                        vmsMachineColor={vmsMachineColor}
                      />
                      <VmsPieChartDownTime
                        AvailabilityCOCPieChartData={
                          AvailabilityCOCPieChartData
                        }
                        shimmer={isShimmerCOCPie}
                        vmsMachineColor={vmsMachineColor}
                      />
                      
                    </div> */}
                    <div className="d-flex position-relative justify-content-around">
                      <>
                        <div>
                          <VmsCOCPieChart
                            AvailabilityCOCPieChartData={
                              AvailabilityCOCPieChartData
                            }
                            shimmer={isShimmerCOCPie}
                            vmsMachineColor={vmsMachineColor}
                          />
                          <div
                            className="text-center mt-4"
                            style={{ fontWeight: "bold", fontSize: "14px" }}
                          >
                            Up Time
                          </div>
                        </div>
                        <div>
                          <VmsPieChartDownTime
                            AvailabilityCOCPieChartData={
                              AvailabilityCOCPieChartData
                            }
                            shimmer={isShimmerCOCPie}
                            vmsMachineColor={vmsMachineColor}
                          />
                          <div
                            className="text-center mt-4"
                            style={{ fontWeight: "bold", fontSize: "14px" }}
                          >
                            Down Time
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={6} lg={9}>
                <Box
                  className="consumerEnergyData-box"
                  style={{ minHeight: "717px" }}
                >
                  <div style={{ minHeight: "322px", position: "relative" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6>
                        <b>Bar Chart - Period over Period</b>
                      </h6>
                      <div className="d-flex">
                        <img src={images.DownTime} alt="" height="20px" />{" "}
                        <h6
                          style={{
                            marginLeft: "10px",
                            fontWeight: 400,
                            fontSize: "14px",
                            marginTop: "2px",
                          }}
                        >
                          Down Time
                        </h6>
                      </div>
                    </div>

                    <VmsPOPBarChart
                      availabilityPOPBarData={availabilityPOPBarData}
                      availabilityPOP1BarData={availabilityPOP1BarData}
                      vmsPOPMachineColor={vmsPOPMachineColor}
                      shimmer={isShimmerPOP}
                    />
                  </div>
                  <div className="position-relative">
                    <h6>
                      <b>Pie Chart - Period over Period</b>
                    </h6>
                  
                      <div className="d-flex position-relative justify-content-around">
                      <>
                        <div>
                        <VmsUpTimePieChart
                        AvailabilityPOPPieChartData={
                          AvailabilityPOPPieChartData
                        }
                        AvailabilityPOP1PieChartData={
                          AvailabilityPOP1PieChartData
                        }
                        shimmer={isShimmerPOPPie}
                      />
                          <div
                            className="text-center mt-4"
                            style={{ fontWeight: "bold", fontSize: "14px" }}
                          >
                            Up Time
                          </div>
                        </div>
                        <div>
                        <VmsPOPDownTimePieChart
                        AvailabilityPOPPieChartData={
                          AvailabilityPOPPieChartData
                        }
                        AvailabilityPOP1PieChartData={
                          AvailabilityPOP1PieChartData
                        }
                        shimmer={isShimmerPOPPie}
                      />
                          <div
                            className="text-center mt-4"
                            style={{ fontWeight: "bold", fontSize: "14px" }}
                          >
                            Down Time
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </Box>
              </Grid>
            )}
          </div>
        </>
      </div>
    </>
  );
};
export default Availability;
