import React, { useMemo, useState } from "react";
import { Box, Link } from "@material-ui/core";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { images } from "../../config/images";
import "../ProcessDashboard/ProcessDashboard.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FooterText from "../../components/FooterText/FooterText";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IconButton, TextField } from "@mui/material";
import { setHeaderDetails } from "../Header/headerSlice";
import { useDispatch, useSelector } from "react-redux";
import { getPlantDashboard } from "./Configration/services";
import { useEffect } from "react";
import { useCubeQuery } from "@cubejs-client/react";
import dayjs from "dayjs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { cubejsApi } from "../Cubejs/CubejsApiWrapper";
import DonutChart from "./DonutChart/DonutChart";
import DonutChart1 from "./DonutChart/DonutChart1";
import BarChart from "./BarChart";
import { setProcessDashboarDateSet } from "./sliceProcessDashboard/dateSliceprocessDashboard";
import Mqqtdata from "./Mqqtdata";
import {
  getOverviewAnalyticsPieChartData,
  getAlarmACKCount,
  getOptimizedPiechartData,
  MQTTTimeStampAlert,
} from "./service";
// import Marquee from "react-fast-marquee";
import Marquee from "react-fast-marquee";
import DonutChart2 from "./DonutChart/DonutChart2";
import { setAnalyticsData } from "./AdvanceAnalytics/Slice/AdvanceAnalyticsSlice";
import Tiles from "../../components/Tile/Tiles";
import PieChartBoxCard from "../../components/PiechartBox/PieChartBox";
import { ToastContainer, toast } from "react-toastify";
import { getPlantByID } from "../SuperAdminDashboard/service";
import { CompactTagUnit } from "../../components/CompactValue/CompactValue";
import { ShimmerText } from "react-shimmer-effects";
import CloseIcon from "@mui/icons-material/Close";
import { SoftTagFormula } from "../SmartTag/softTag/SoftTagFormula";
import { timeStampCheckStatus } from "../../components/TimeStampCheckStatus/timeStampCheckStatus";

const dateFormat = "YYYY-MM-DD"; // Replace with your desired date format

const ProcessDashboard = () => {
  const [activeClass, setActiveClass] = useState("Analytics");

  const [dashboard, setDashboard] = useState([]);
  const [positionValue5, setPositionValue5] = useState("");
  const [positionValue6, setPositionValue6] = useState("");
  const [positionValue7, setPositionValue7] = useState("");
  const [tagData5, setTagData5] = useState("");
  const [tagData6, setTagData6] = useState("");
  const [tagData7, setTagData7] = useState("");
  const [activeChart, setActiveChart] = useState("pie");
  const [activeChart1, setActiveChart1] = useState("pie");
  const [activeChart2, setActiveChart2] = useState("pie");
  const [positionValue_sum_5, setPositionValue_sum_5] = useState(0);
  const [positionValue_sum_6, setPositionValue_sum_6] = useState(0);
  const [positionValue_sum_7, setPositionValue_sum_7] = useState(0);
  const [dashboardTagName, setDashboardTagName] = useState([]);
  const [topics, setTopics] = useState([]);
  const [mqttTagData, setMqttTagData] = useState();
  const [isPaused, setIsPaused] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoading1, setLoading1] = useState(false);
  const [isLoading2, setLoading2] = useState(false);
  const [isShimmer, setisShimmer] = useState(false);
  const [isShimmer1, setisShimmer1] = useState(false);
  const [isShimmer2, setisShimmer2] = useState(false);
  const [isShimmerBarChart, setisShimmerBarChart] = useState(false);
  const [isShimmerBarChart1, setisShimmerBarChart1] = useState(false);
  const [isShimmerBarChart2, setisShimmerBarChart2] = useState(false);
  const [positionId5, setPositionId5] = useState("");
  const [positionId6, setPositionId6] = useState("");
  const [positionId7, setPositionId7] = useState("");
  const [isNotified, setIsNotified] = useState(false);
  const [MqttTime, setMqttTime] = useState("");

  console.log("topics", topics);

  const { user_access } = useSelector((state) => state.userState);

  const convertTimeStampFormat = mqttTagData
    ? Object.keys(mqttTagData).map((row) => {
        const findTimeStamp = mqttTagData[row].find(
          (f) => f.device_tag == "TIMESTAMP"
        );
        // console.log("findTimeStamp",findTimeStamp)

        return {
          ...findTimeStamp,
          TIMESTAMP: findTimeStamp?.TIMESTAMP || "",
        };
      })
    : [];

  const mergedArray = mqttTagData
    ? [].concat(...Object.values(mqttTagData))
    : [];

  const mqttDataReduce = mergedArray.reduce((map, item) => {
    map[item.device_tag] = Number(item.tagValue);
    return map;
  }, {});

  useEffect(() => {
    const getData = timeStampCheckStatus(convertTimeStampFormat);

    console.log("getData", getData);

    if (!isNotified && Array.isArray(getData) && getData.length > 0) {
      const { device_tag, tagDateAndTimeFormat, tagValue } = getData[0];
      setMqttTime(tagValue);
      const notifyError = (message) =>
        toast.error(message, {
          theme: "colored",
          position: toast.POSITION.TOP_RIGHT,
          toastClassName: "custom-toast",
        });

      if (device_tag && tagDateAndTimeFormat && tagValue) {
        notifyError(
          <div>
            <p>Device Tag: {device_tag}</p>
            <p>Current Time: {tagDateAndTimeFormat}</p>
            <p>Mqtt Time: {tagValue}</p>
          </div>
        );
        setIsNotified(true);
      }
    }
  }, [mqttTagData, isNotified]);

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };
  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  const { configurationSummary } = useSelector(
    (state) => state.analyticsDataState
  );

  const handleConfCompanyNavigate = () => {
    navigate(`/Company/${param.companyId}/plant/configration/${param.id}`);
  };


  // console.log("mqttTagData", mqttTagData);
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (dashboard.length) {
      const topicArray = [
        dashboard[0].position_1_name
          ? "Hikar/DeviceUID/" + dashboard[0].position_1_name.device_uid
          : null,
        dashboard[0].position_2_name
          ? "Hikar/DeviceUID/" + dashboard[0].position_2_name.device_uid
          : null,
        dashboard[0].position_3_name
          ? "Hikar/DeviceUID/" + dashboard[0].position_3_name.device_uid
          : null,
        dashboard[0].position_4_name
          ? "Hikar/DeviceUID/" + dashboard[0].position_4_name.device_uid
          : null,
      ].filter((topic) => topic !== null);
      setTopics(topicArray);
    }
  }, [dashboard]);

  const handleChartClick = (chartType) => {
    setActiveChart(chartType);
  };

  const handleChartClick1 = (chartType) => {
    setActiveChart1(chartType);
  };

  const handleChartClick2 = (chartType) => {
    console.log("handleChartClick2........chartType", chartType);
    setActiveChart2(chartType);
  };

  const {
    startDateRe,
    endDateRe,
    startDateRe2,
    endDateRe2,
    startDateRe3,
    endDateRe3,
    selectUnit,
  } = useSelector((state) => state.dateSliceprocessDashboardState);

  const startDateReFormat = startDateRe ? dayjs(startDateRe) : null;
  const endDateReFormat = endDateRe ? dayjs(endDateRe) : null;
  const startDateReFormat2 = startDateRe2 ? dayjs(startDateRe2) : null;
  const endDateReFormat2 = endDateRe2 ? dayjs(endDateRe2) : null;
  const startDateReFormat3 = startDateRe3 ? dayjs(startDateRe3) : null;
  const endDateReFormat3 = endDateRe3 ? dayjs(endDateRe3) : null;

  const MAX_DATE_RANGE_DAYS = 365;

  // console.log("startDateRe", startDateRe);
  const formatStartDate = startDateReFormat
    ? dayjs(startDateReFormat).format(dateFormat)
    : "";
  const formatEndDate = endDateReFormat
    ? dayjs(endDateReFormat).format(dateFormat)
    : "";
  const formatStartDate6 = startDateRe2
    ? dayjs(startDateRe2).format(dateFormat)
    : "";
  const formatEndDate6 = endDateRe2 ? dayjs(endDateRe2).format(dateFormat) : "";

  const formatStartDate7 = startDateRe3
    ? dayjs(startDateRe3).format(dateFormat)
    : "";
  const formatEndDate7 = endDateRe3 ? dayjs(endDateRe3).format(dateFormat) : "";

  const handleStartDate1 = (newValue) => {
    const durationInDays = endDateReFormat
      ? endDateReFormat.diff(newValue, "days")
        ? endDateReFormat.diff(newValue, "days") > 0
          ? endDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    console.log("durationInDaysdurationInDays", durationInDays);

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(
        setProcessDashboarDateSet({
          startDateRe: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormat && newValue.isBefore(endDateReFormat)) {
      // Start date is before end date
      dispatch(
        setProcessDashboarDateSet({
          startDateRe: newValue,
        })
      );
    } else if (!endDateReFormat) {
      // End date is not set yet
      dispatch(
        setProcessDashboarDateSet({
          startDateRe: newValue,
        })
      );
    } else if (endDateReFormat && newValue.isSame(endDateReFormat, "day")) {
      // Start date and end date are the same
      dispatch(setProcessDashboarDateSet({ startDateRe: newValue }));
    } else {
      // Start date is after end date
      dispatch(
        setProcessDashboarDateSet({
          startDateRe: null,
        })
      );
      notifyError("End date must be greater than start date");
    }

    // dispatch(
    //   setProcessDashboarDateSet({
    //     startDateRe: newValue,
    //   })
    // );
  };
  const handleEndDate1 = (newValue) => {
    const durationInDays = startDateReFormat
      ? newValue.diff(startDateReFormat, "days")
        ? newValue.diff(startDateReFormat, "days") > 0
          ? newValue.diff(startDateReFormat, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setProcessDashboarDateSet({ endDateRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormat && newValue.isAfter(startDateReFormat)) {
      dispatch(setProcessDashboarDateSet({ endDateRe: newValue }));
    } else if (!startDateReFormat) {
      dispatch(setProcessDashboarDateSet({ endDateRe: newValue }));
    } else if (startDateReFormat && newValue.isSame(startDateReFormat, "day")) {
      // Start date and end date are the same
      dispatch(setProcessDashboarDateSet({ endDateRe: newValue }));
    } else {
      // Start date is after end date
      dispatch(setProcessDashboarDateSet({ endDateRe: null }));
      notifyError("startDate must less than endDate");
    }

    // dispatch(setProcessDashboarDateSet({ endDateRe: newValue }));
  };

  const handleStartDate2 = (newValue) => {
    const durationInDays = endDateReFormat2
      ? endDateReFormat2.diff(newValue, "days")
        ? endDateReFormat2.diff(newValue, "days") > 0
          ? endDateReFormat2.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(
        setProcessDashboarDateSet({
          startDateRe2: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormat2 && newValue.isBefore(endDateReFormat2)) {
      // Start date is before end date
      dispatch(
        setProcessDashboarDateSet({
          startDateRe2: newValue,
        })
      );
    } else if (!endDateReFormat2) {
      // End date is not set yet
      dispatch(
        setProcessDashboarDateSet({
          startDateRe2: newValue,
        })
      );
    } else if (endDateReFormat2 && newValue.isSame(endDateReFormat2, "day")) {
      // Start date and end date are the same
      dispatch(
        setProcessDashboarDateSet({
          startDateRe2: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setProcessDashboarDateSet({
          startDateRe2: null,
        })
      );
      notifyError("End date must be greater than start date");
    }

    // dispatch(
    //   setProcessDashboarDateSet({
    //     startDateRe2: newValue,
    //   })
    // );
  };

  const handleEndDate2 = (newValue) => {
    const durationInDays = startDateReFormat2
      ? newValue.diff(startDateReFormat2, "days")
        ? newValue.diff(startDateReFormat2, "days") > 0
          ? newValue.diff(startDateReFormat2, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(
        setProcessDashboarDateSet({
          endDateRe2: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormat2 && newValue.isAfter(startDateReFormat2)) {
      dispatch(
        setProcessDashboarDateSet({
          endDateRe2: newValue,
        })
      );
    } else if (!startDateReFormat2) {
      dispatch(
        setProcessDashboarDateSet({
          endDateRe2: newValue,
        })
      );
    } else if (
      startDateReFormat2 &&
      newValue.isSame(startDateReFormat2, "day")
    ) {
      // Start date and end date are the same
      dispatch(
        setProcessDashboarDateSet({
          endDateRe2: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setProcessDashboarDateSet({
          endDateRe2: null,
        })
      );
      notifyError(" startDate must less than endDate");
    }

    // dispatch(
    //   setProcessDashboarDateSet({
    //     endDateRe2: newValue,
    //   })
    // );
  };

  const handleStartDate3 = (newValue) => {
    const durationInDays = endDateReFormat3
      ? endDateReFormat3.diff(newValue, "days")
        ? endDateReFormat3.diff(newValue, "days") > 0
          ? endDateReFormat3.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(
        setProcessDashboarDateSet({
          startDateRe3: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormat3 && newValue.isBefore(endDateReFormat3)) {
      // Start date is before end date
      dispatch(
        setProcessDashboarDateSet({
          startDateRe3: newValue,
        })
      );
    } else if (!endDateReFormat3) {
      // End date is not set yet
      dispatch(
        setProcessDashboarDateSet({
          startDateRe3: newValue,
        })
      );
    } else if (endDateReFormat3 && newValue.isSame(endDateReFormat3, "day")) {
      // End date is not set yet
      dispatch(
        setProcessDashboarDateSet({
          startDateRe3: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setProcessDashboarDateSet({
          startDateRe3: null,
        })
      );
      notifyError("End date must be greater than start date");
    }

    // dispatch(
    //   setProcessDashboarDateSet({
    //     startDateRe3: newValue,
    //   })
    // );
  };

  const handleEndDate3 = (newValue) => {
    const durationInDays = endDateReFormat2
      ? endDateReFormat2.diff(newValue, "days")
        ? endDateReFormat2.diff(newValue, "days") > 0
          ? endDateReFormat2.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(
        setProcessDashboarDateSet({
          endDateRe3: null,
        })
      );
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormat3 && newValue.isAfter(startDateReFormat3)) {
      dispatch(
        setProcessDashboarDateSet({
          endDateRe3: newValue,
        })
      );
    } else if (!startDateReFormat3) {
      dispatch(
        setProcessDashboarDateSet({
          endDateRe3: newValue,
        })
      );
    } else if (
      startDateReFormat3 &&
      newValue.isSame(startDateReFormat3, "day")
    ) {
      // End date is not set yet
      dispatch(
        setProcessDashboarDateSet({
          endDateRe3: newValue,
        })
      );
    } else {
      // Start date is after end date
      dispatch(
        setProcessDashboarDateSet({
          endDateRe3: null,
        })
      );
      notifyError(" startDate must less than endDate");
    }

    // dispatch(
    //   setProcessDashboarDateSet({
    //     endDateRe3: newValue,
    //   })
    // );
  };

  const { headerName } = useSelector((state) => state.headerState);
  const dispatch = useDispatch();

  const param = useParams();

  const handleOverViewClick = () => {
    navigate(`/overView/company/${param.companyId}/plant/${param.id}`);
    setActiveClass("overview");
  };

  const handleSourceClick = () => {
    navigate(`/source/company/${param.companyId}/plant/${param.id}`);
    setActiveClass("source");
  };

  const handleConsumption = (type) => {
    navigate(`/${type}/company/${param.companyId}/plant/${param.id}`);
    setActiveClass("consumption");
  };
  const handleAdvanceAnalytics = () => {
    dispatch(setAnalyticsData({ configurationSummary: false }));
    navigate(
      `/analytics-configration/company/${param.companyId}/plant/${param.id}`
    );
    setActiveClass("Analytics");
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (dashboard.length) {
      handleCubejsPostion5();
      // handleCube();
    }
  }, [dashboard, formatStartDate, formatEndDate, selectUnit]);

  useEffect(() => {
    if (dashboard.length) {
      handleCubejsPostion6();
      // handleCube();
    }
  }, [dashboard, formatStartDate6, formatEndDate6, selectUnit]);

  useEffect(() => {
    if (dashboard.length) {
      handleCubejsPostion7();
      // handleCube();
    }
  }, [dashboard, formatStartDate7, formatEndDate7, selectUnit]);

  const handleCubejsPostion5 = async () => {
    if (!dashboard.length) {
      return true;
    }

    if (startDateReFormat && endDateReFormat) {
      if (activeChart) {
        if (activeChart == "pie") {
          setisShimmer(true);
        } else if (activeChart == "bar") {
          console.log("Api call time ", isShimmerBarChart);
          setisShimmerBarChart(true);
        }
      }

      if (dashboard[0].position_5) {
        try {
          const payload = {
            tag_id: dashboard[0].position_5,
            start_date: formatStartDate,
            end_date: formatEndDate,
            unit: selectUnit,
          };

          const resp = await getOptimizedPiechartData(payload);

          if (
            (resp.status === 200 || resp.status === 201) &&
            resp.data.payload.length
          ) {
            // Map over the response payload and extract tag_value
            const mapCube = resp.data.payload.map((item) =>
              item.tag_value ? item.tag_value : 0
            );
            const mapId = resp.data.payload.map((item) =>
              item.tag_id ? item.tag_id : 0
            );
            if (activeChart) {
              if (activeChart == "pie") {
                setTimeout(() => {
                  setisShimmer(false);
                }, 2000);
              } else if (activeChart == "bar") {
                setTimeout(() => {
                  setisShimmerBarChart(false);
                }, 2000);
              }
            }

            setPositionValue5(mapCube);
            setPositionId5(mapId);
          } else {
            if (activeChart) {
              if (activeChart == "pie") {
                setisShimmer(false);
              } else if (activeChart == "bar") {
                setisShimmerBarChart(false);
              }
            }

            console.log("No data found in the response payload.");
            setPositionValue5([]);
          }
        } catch (error) {
          if (activeChart) {
            if (activeChart == "pie") {
              setisShimmer(false);
            } else if (activeChart == "bar") {
              setisShimmerBarChart(false);
            }
          }
          console.error("Error fetching data:", error);
          setPositionValue5([]);
        }
      } else {
        console.log("position_5 array not found.");
        if (activeChart) {
          if (activeChart == "pie") {
            setisShimmer(false);
          } else if (activeChart == "bar") {
            setisShimmerBarChart(false);
          }
        }
        return true;
      }
    } else {
      console.log("Start or end date missing.");
      if (activeChart) {
        if (activeChart == "pie") {
          setisShimmer(false);
        } else if (activeChart == "bar") {
          setisShimmerBarChart(false);
        }
      }
      return true;
    }
  };
  const handleCubejsPostion6 = async () => {
    if (!dashboard.length) {
      return true;
    }

    if (startDateReFormat && endDateReFormat) {
      if (activeChart1) {
        if (activeChart1 == "pie") {
          setisShimmer1(true);
          console.log("pie......", activeChart1);
        } else if (activeChart1 == "bar") {
          console.log("bar......", activeChart1);
          setisShimmerBarChart1(true);
        }
      }

      if (dashboard[0].position_6) {
        try {
          const payload = {
            tag_id: dashboard[0].position_6,
            start_date: formatStartDate6,
            end_date: formatEndDate6,
            unit: selectUnit,
          };

          const resp = await getOptimizedPiechartData(payload);

          if (
            (resp.status === 200 || resp.status === 201) &&
            resp.data.payload.length
          ) {
            const mapCube = resp.data.payload.map((item) =>
              item.tag_value ? item.tag_value : 0
            );
            const mapId = resp.data.payload.map((item) =>
              item.tag_id ? item.tag_id : 0
            );
            if (activeChart1) {
              if (activeChart1 == "pie") {
                setTimeout(() => {
                  setisShimmer1(false);
                }, 2000);
              } else if (activeChart1 == "bar") {
                setTimeout(() => {
                  setisShimmerBarChart1(false);
                }, 2000);
                console.log("Api call time ", isShimmerBarChart);
              }
            }

            setPositionValue6(mapCube);
            setPositionId6(mapId);
          } else {
            console.log("No data found in the response payload.");
            setPositionValue6([]);

            if (activeChart1) {
              if (activeChart1 == "pie") {
                setisShimmer1(false);
              } else if (activeChart1 == "bar") {
                setisShimmerBarChart1(false);
              }
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setPositionValue6([]);
          if (activeChart1) {
            if (activeChart1 == "pie") {
              setisShimmer1(false);
            } else if (activeChart1 == "bar") {
              setisShimmerBarChart1(false);
            }
          }
        }
      } else {
        console.log("position_5 array not found.");
        if (activeChart1) {
          if (activeChart1 == "pie") {
            setisShimmer1(false);
          } else if (activeChart1 == "bar") {
            console.log("Api call time ", isShimmerBarChart);
            setisShimmerBarChart1(false);
          }
        }
        return true;
      }
    } else {
      if (activeChart1) {
        if (activeChart1 == "pie") {
          setisShimmer1(false);
        } else if (activeChart1 == "bar") {
          console.log("Api call time ", isShimmerBarChart);
          setisShimmerBarChart1(false);
        }
      }
      console.log("Start or end date missing.");
      return true;
    }
  };

  const handleCubejsPostion7 = async () => {
    if (!dashboard.length) {
      return true;
    }

    if (startDateReFormat && endDateReFormat) {
      if (activeChart2) {
        console.log("activeChart....2", activeChart2);
        if (activeChart2 == "pie") {
          setisShimmer2(true);
          console.log("pie......2", activeChart2);
        } else if (activeChart2 == "bar") {
          console.log("bar......2", activeChart2);
          setisShimmerBarChart2(true);
        }
      }
      if (dashboard[0].position_7) {
        try {
          const payload = {
            tag_id: dashboard[0].position_7,
            start_date: formatStartDate7,
            end_date: formatEndDate7,
            unit: selectUnit,
          };

          const resp = await getOptimizedPiechartData(payload);

          if (
            (resp.status === 200 || resp.status === 201) &&
            resp.data.payload.length
          ) {
            const mapCube = resp.data.payload.map((item) =>
              item.tag_value ? item.tag_value : 0
            );
            const mapId = resp.data.payload.map((item) =>
              item.tag_id ? item.tag_id : 0
            );
            if (activeChart2) {
              if (activeChart2 == "pie") {
                setTimeout(() => {
                  setisShimmer2(false);
                }, 2000);
              } else if (activeChart2 == "bar") {
                setTimeout(() => {
                  setisShimmerBarChart2(false);
                }, 2000);
              }
            }

            setPositionValue7(mapCube);
            setPositionId7(mapId);
          } else {
            if (activeChart2) {
              if (activeChart2 == "pie") {
                setisShimmer2(false);
              } else if (activeChart2 == "bar") {
                setisShimmerBarChart2(false);
              }
            }
            console.log("No data found in the response payload.");
            setPositionValue7([]);
          }
        } catch (error) {
          if (activeChart2) {
            if (activeChart2 == "pie") {
              setisShimmer2(false);
            } else if (activeChart2 == "bar") {
              setisShimmerBarChart2(false);
            }
          }
          console.error("Error fetching data:", error);
          setPositionValue7([]);
        }
      } else {
        console.log("position_5 array not found.");

        if (activeChart2) {
          if (activeChart2 == "pie") {
            setisShimmer2(false);
          } else if (activeChart2 == "bar") {
            setisShimmerBarChart2(false);
          }
        }
        return true;
      }
    } else {
      console.log("Start or end date missing.");

      if (activeChart2) {
        if (activeChart2 == "pie") {
          setisShimmer2(false);
        } else if (activeChart2 == "bar") {
          setisShimmerBarChart2(false);
        }
      }
      return true;
    }
  };

  useEffect(() => {
    handleGetPlantDashboard();
  }, []);

  const handleGetPlantDashboard = async () => {
    const params = {
      plant_id: param.id,
    };
    try {
      const resp = await getPlantDashboard(params);
      // console.log("resprespresp", resp);
      if (resp.data.success == true && resp.data.payload.length) {
        setDashboard(resp.data.payload);

        const tagName = [
          resp.data.payload[0].position_1_name &&
            resp.data.payload[0].position_1_name.tag_name,
          resp.data.payload[0].position_2_name &&
            resp.data.payload[0].position_2_name.tag_name,
          resp.data.payload[0].position_3_name &&
            resp.data.payload[0].position_3_name.tag_name,
          resp.data.payload[0].position_4_name &&
            resp.data.payload[0].position_4_name.tag_name,
        ];
        setDashboardTagName(tagName.filter((f) => f != null));
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    dispatch(
      setHeaderDetails({
        ...headerName,
        headerImage: (
          <>
            <div>
              <button
                className="configration-btn"
                // onClick={() => navigate(`/company/31/plant/configration/${location.pathname.substring(location.pathname.lastIndexOf('/') + 1)}`)}
              >
                <img
                  src={images.configration}
                  style={{ height: "18px", width: "18px", marginRight: "8px" }}
                  alt=""
                />
                Configration
              </button>
            </div>
            <div
              onClick={() => navigate("/servicecall")}
              style={{ cursor: "pointer" }}
            >
              <img src={images.callImg} alt="" />
            </div>
            <button className="alarm-btn" onClick={() => navigate("/alarm")}>
              Alarm
            </button>
          </>
        ),
      })
    );
  }, []);

  // getPlantById Detail
  const getPlantByDeatilsAPI = async () => {
    const paramReq = {
      plant_id: param.id,
    };
    try {
      const resp = await getPlantByID(paramReq);
      if (resp.status == 200) {
        dispatch(
          setHeaderDetails({ headerName: resp.data.payload.plant_name })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPlantByDeatilsAPI();
  }, []);

  const sliderColor = [
    "sliderColor1",
    "sliderColor2",
    "sliderColor3",
    "sliderColor4",
    "sliderColor5",
    "sliderColor6",
  ];

  const PiaChartBorderColor1 = [
    "#AEC7A6",
    "#5A8A8A",
    "#DEC591",
    "#E9B9AA",
    "#8CB9C0",
    "#7892B5",
  ];

  const sliderColor1 = [
    "sliderColor7",
    "sliderColor8",
    "sliderColor9",
    "sliderColor10",
    "sliderColor11",
    "sliderColor12",
  ];

  const PiaChartBorderColor2 = [
    "#FEBB22",
    "#002B2A",
    "#C99C00",
    "#EB9E79",
    "#69927A",
    "#872B81",
  ];

  const sliderColor2 = [
    "sliderColor13",
    "sliderColor14",
    "sliderColor15",
    "sliderColor16",
    "sliderColor17",
    "sliderColor18",
  ];

  const PiaChartBorderColor3 = [
    "#F06292",
    "#0EB520",
    "#CD4D4A",
    "#123782",
    "#544632",
    "#94655B",
  ];

  const mapTagDetails = (tagId) => {
    const tagDetail = dashboard[0].position_5_names.find(
      (tag) => tag.tag_id === tagId
    );
    return tagDetail
      ? { tag_comment: tagDetail.tag_comment, tag_unit: tagDetail.tag_unit }
      : { tag_comment: "", tag_unit: "" };
  };

  const sliderData = positionValue5
    ? positionValue5.map((value, index) => {
        const { tag_comment, tag_unit } = mapTagDetails(positionId5[index]);
        return {
          Title: tag_comment,
          data: value,
          unit: tag_unit,
        };
      })
    : [];

  const mapTagDetails6 = (tagId) => {
    const tagDetail = dashboard[0].position_6_names.find(
      (tag) => tag.tag_id === tagId
    );
    return tagDetail
      ? { tag_comment: tagDetail.tag_comment, tag_unit: tagDetail.tag_unit }
      : { tag_comment: "", tag_unit: "" };
  };

  const sliderData1 = positionValue6
    ? positionValue6.map((value, index) => {
        const { tag_comment, tag_unit } = mapTagDetails6(positionId6[index]);
        return {
          Title: tag_comment,
          data: value,
          unit: tag_unit,
        };
      })
    : [];

  const mapTagDetails7 = (tagId) => {
    const tagDetail = dashboard[0].position_7_names.find(
      (tag) => tag.tag_id === tagId
    );
    return tagDetail
      ? { tag_comment: tagDetail.tag_comment, tag_unit: tagDetail.tag_unit }
      : { tag_comment: "", tag_unit: "" };
  };

  const sliderData2 = positionValue7
    ? positionValue7.map((value, index) => {
        const { tag_comment, tag_unit } = mapTagDetails7(positionId7[index]);
        return {
          Title: tag_comment,
          data: value,
          unit: tag_unit,
        };
      })
    : [];
  // const sliderData2 = positionValue7
  //   ? positionValue7.map((e, i) => {
  //       return {
  //         Title: dashboard.length
  //           ? dashboard[0].position_7_names.length
  //             ? dashboard[0].position_7_names[i].tag_comment
  //             : ""
  //           : "",
  //         data: e,
  //         unit: dashboard.length
  //           ? dashboard[0].position_7_names.length
  //             ? dashboard[0].position_7_names[i].tag_unit
  //             : ""
  //           : "",
  //       };
  //     })
  //   : [];

  // sum Of Donutes Chart

  useEffect(() => {
    if (positionValue5) {
      const sum = positionValue5.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const sumRounded = sum.toFixed(2); // Round the sum to 2 decimal places
      setPositionValue_sum_5(sumRounded);
    }

    if (positionValue6) {
      const sum = positionValue6.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const sumRounded1 = sum.toFixed(2);
      setPositionValue_sum_6(sumRounded1);
    }

    if (positionValue7) {
      const sum = positionValue7.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      const sumRounded2 = sum.toFixed(2);
      setPositionValue_sum_7(sumRounded2);
    }
  }, [positionValue5, positionValue6, positionValue7]);

  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;
  const plantAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Plant Process Dashboard"
        )
      : null
    : null;
  const overViewAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Overview"
        )
      : null
    : null;
  const sourceAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Source"
        )
      : null
    : null;
  const consumptionAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Consumption"
        )
      : null
    : null;
  const analyticsAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Advance Analytics"
        )
      : null
    : null;

  useEffect(() => {
    if (dashboard.length) {
      console.log("dashboard.length", dashboard.length);
    }

    if (topics.length) {
      console.log("topics.length", topics.length);
    }
    console.log("dashboard.length", dashboard.length);
    console.log("topics.length", topics.length);
  }, [dashboard.length, topics.length]);


  useEffect(() => {
    if(mqttTagData){
      handleMqttTime();
    }

  }, [MqttTime]);

  const handleMqttTime = async () => {
    const params = {
      device_uid: topics.map(topic => topic.split('/').slice(2).join('/')),
      device_timestamp: MqttTime,
    };
    console.log("params",params)
    try {
      const resp = await MQTTTimeStampAlert(params);
      if (resp.status == 200 || resp.status == 201) {
        console.log("Mqtt Time");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  return (
    <>
      {dashboard.length ? (
        topics.length ? (
          <Mqqtdata setMqttTagData={setMqttTagData} topics={topics} />
        ) : (
          ""
        )
      ) : (
        ""
      )}
      <div className="p-4 pb-5">
        <div className="d-flex justify-content-between process-dashboard-compact">
          <div>
            <select
              className="compact-btn"
              value={selectUnit}
              onChange={(e) =>
                dispatch(
                  setProcessDashboarDateSet({ selectUnit: e.target.value })
                )
              }
            >
              <option value="k">Kilo</option>
              <option value="m">Mega</option>
              <option value="g">Giga</option>
            </select>
          </div>
          <div>
            {(plantAccess?.is_viewer && plantAccess?.is_editor) ||
            (!plantAccess?.is_viewer && plantAccess?.is_editor) ||
            (plantAccess?.is_viewer && !plantAccess?.is_editor) ? (
              <button
                className="configration-btn confi-btn"
                onClick={() => handleConfCompanyNavigate()}
              >
                <img
                  src={images.configration}
                  style={{ height: "18px", width: "18px", marginRight: "8px" }}
                  alt=""
                />
                Configuration
              </button>
            ) : (
              <button
                className="configration-btn confi-btn"
                onClick={handleTostMsg}
              >
                <img
                  src={images.configration}
                  style={{ height: "18px", width: "18px", marginRight: "8px" }}
                  alt=""
                />
                Configuration
              </button>
            )}
          </div>
        </div>
        <div className="d-flex gap-3">
          <Grid container spacing={2} className="grid-container-box">
            <Grid item xs={12} sm={6} md={4} lg={3} className="grid-box">
              <Tiles
                tagComment={
                  dashboard.length
                    ? dashboard[0].position_1_name
                      ? dashboard[0].position_1_name.tag_comment
                      : "-"
                    : "-"
                }
                tagNumber={
                  dashboard[0]?.position_1_name?.is_soft_tag &&
                  dashboard[0]?.position_1_name?.soft_tag_formula &&
                  Object.keys(mqttDataReduce).length
                    ? SoftTagFormula(
                        dashboard[0]?.position_1_name?.soft_tag_formula,
                        mqttDataReduce
                      )?.softTagNotGetTag == true
                      ? SoftTagFormula(
                          dashboard[0]?.position_1_name?.soft_tag_formula,
                          mqttDataReduce
                        )?.value
                      : 0
                    : dashboard.length &&
                      mqttTagData &&
                      mqttTagData[
                        dashboard[0].position_1_name?.device_uid
                      ]?.find(
                        (f) => f[dashboard[0].position_1_name.dvice_tag]
                      )?.[dashboard[0].position_1_name.dvice_tag]
                    ? mqttTagData[
                        dashboard[0].position_1_name?.device_uid
                      ]?.find(
                        (f) => f[dashboard[0].position_1_name.dvice_tag]
                      )?.[dashboard[0].position_1_name.dvice_tag]
                    : 0
                }
                tagUnit={
                  dashboard.length
                    ? dashboard[0].position_1_name
                      ? dashboard[0].position_1_name.tag_unit
                      : "-"
                    : "-"
                }
                selectionUnit={selectUnit}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} className="grid-box">
              <Tiles
                tagComment={
                  dashboard.length
                    ? dashboard[0].position_2_name
                      ? dashboard[0].position_2_name.tag_comment
                      : "-"
                    : "-"
                }
                tagNumber={
                  dashboard[0]?.position_2_name?.is_soft_tag &&
                  dashboard[0]?.position_2_name?.soft_tag_formula &&
                  Object.keys(mqttDataReduce).length
                    ? SoftTagFormula(
                        dashboard[0]?.position_2_name?.soft_tag_formula,
                        mqttDataReduce
                      )?.softTagNotGetTag == true
                      ? SoftTagFormula(
                          dashboard[0]?.position_2_name?.soft_tag_formula,
                          mqttDataReduce
                        )?.value
                      : 0
                    : dashboard.length &&
                      mqttTagData &&
                      mqttTagData[
                        dashboard[0].position_2_name?.device_uid
                      ]?.find(
                        (f) => f[dashboard[0].position_2_name.dvice_tag]
                      )?.[dashboard[0].position_2_name.dvice_tag]
                    ? mqttTagData[
                        dashboard[0].position_2_name?.device_uid
                      ]?.find(
                        (f) => f[dashboard[0].position_2_name.dvice_tag]
                      )?.[dashboard[0].position_2_name.dvice_tag]
                    : 0
                  // dashboard.length &&
                  // mqttTagData &&
                  // mqttTagData[dashboard[0].position_2_name?.device_uid]?.find(
                  //   (f) => f[dashboard[0].position_2_name.dvice_tag]
                  // )?.[dashboard[0].position_2_name.dvice_tag]
                  //   ? mqttTagData[
                  //       dashboard[0].position_2_name?.device_uid
                  //     ]?.find(
                  //       (f) => f[dashboard[0].position_2_name.dvice_tag]
                  //     )?.[dashboard[0].position_2_name.dvice_tag]
                  //   : 0
                }
                tagUnit={
                  dashboard.length
                    ? dashboard[0].position_2_name
                      ? dashboard[0].position_2_name.tag_unit
                      : "-"
                    : "-"
                }
                selectionUnit={selectUnit}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} className="grid-box">
              <Tiles
                tagComment={
                  dashboard.length
                    ? dashboard[0].position_3_name
                      ? dashboard[0].position_3_name.tag_comment
                      : "-"
                    : "-"
                }
                tagNumber={
                  dashboard[0]?.position_3_name?.is_soft_tag &&
                  dashboard[0]?.position_3_name?.soft_tag_formula &&
                  Object.keys(mqttDataReduce).length
                    ? SoftTagFormula(
                        dashboard[0]?.position_3_name?.soft_tag_formula,
                        mqttDataReduce
                      )?.softTagNotGetTag == true
                      ? SoftTagFormula(
                          dashboard[0]?.position_3_name?.soft_tag_formula,
                          mqttDataReduce
                        )?.value
                      : 0
                    : dashboard.length &&
                      mqttTagData &&
                      mqttTagData[
                        dashboard[0].position_3_name?.device_uid
                      ]?.find(
                        (f) => f[dashboard[0].position_3_name.dvice_tag]
                      )?.[dashboard[0].position_3_name.dvice_tag]
                    ? mqttTagData[
                        dashboard[0].position_3_name?.device_uid
                      ]?.find(
                        (f) => f[dashboard[0].position_3_name.dvice_tag]
                      )?.[dashboard[0].position_3_name.dvice_tag]
                    : 0

                  // dashboard.length &&
                  // mqttTagData &&
                  // mqttTagData[dashboard[0].position_3_name?.device_uid]?.find(
                  //   (f) => f[dashboard[0].position_3_name.dvice_tag]
                  // )?.[dashboard[0].position_3_name.dvice_tag]
                  //   ? mqttTagData[
                  //       dashboard[0].position_3_name?.device_uid
                  //     ]?.find(
                  //       (f) => f[dashboard[0].position_3_name.dvice_tag]
                  //     )?.[dashboard[0].position_3_name.dvice_tag]
                  //   : 0
                }
                tagUnit={
                  dashboard.length
                    ? dashboard[0].position_3_name
                      ? dashboard[0].position_3_name.tag_unit
                      : "-"
                    : "-"
                }
                selectionUnit={selectUnit}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} className="grid-box">
              <Tiles
                tagComment={
                  dashboard.length
                    ? dashboard[0].position_4_name
                      ? dashboard[0].position_4_name.tag_comment
                      : "-"
                    : "-"
                }
                tagNumber={
                  dashboard[0]?.position_4_name?.is_soft_tag &&
                  dashboard[0]?.position_4_name?.soft_tag_formula &&
                  Object.keys(mqttDataReduce).length
                    ? SoftTagFormula(
                        dashboard[0]?.position_4_name?.soft_tag_formula,
                        mqttDataReduce
                      )?.softTagNotGetTag == true
                      ? SoftTagFormula(
                          dashboard[0]?.position_4_name?.soft_tag_formula,
                          mqttDataReduce
                        )?.value
                      : 0
                    : dashboard &&
                      dashboard.length &&
                      mqttTagData &&
                      mqttTagData[
                        dashboard[0]?.position_4_name?.device_uid
                      ]?.find(
                        (f) => f[dashboard[0]?.position_4_name?.dvice_tag]
                      )?.[dashboard[0]?.position_4_name?.dvice_tag]
                    ? mqttTagData[
                        dashboard[0]?.position_4_name?.device_uid
                      ]?.find(
                        (f) => f[dashboard[0]?.position_4_name?.dvice_tag]
                      )?.[dashboard[0]?.position_4_name?.dvice_tag]
                    : 0
                }
                tagUnit={
                  dashboard.length
                    ? dashboard[0].position_4_name
                      ? dashboard[0].position_4_name.tag_unit
                      : "-"
                    : "-"
                }
                selectionUnit={selectUnit}
              />
            </Grid>
          </Grid>
        </div>

        <div className="pt-3 process-card-chart">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card
                style={{
                  height: "550px",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="spinner-container">
                  {/* {isLoading && <span className="spinner-gifBox"></span>} */}
                  <PieChartBoxCard
                    activeChart={activeChart}
                    handleChartClick={handleChartClick}
                    startDate={startDateReFormat}
                    endDate={endDateReFormat}
                    sliderData={sliderData}
                    sliderColorData={sliderColor}
                    dashboard={
                      dashboard.length ? dashboard[0].position_5_names : []
                    }
                    tagData={tagData5}
                    positionValue={positionValue5}
                    sum={positionValue_sum_5}
                    access={plantAccess}
                    handleStartDate={handleStartDate1}
                    handleEndDate={handleEndDate1}
                    PiaChartBorderColor={PiaChartBorderColor1}
                    ShimmerPieChart={isShimmer}
                    ShimmerBarChart={isShimmerBarChart}
                    positionId={positionId5}
                    mapTagDetails={mapTagDetails}
                  />
                </div>
                {!isShimmer && !isShimmerBarChart ? (
                  <div className="slider-data-div ">
                    <Marquee pauseOnHover={true}>
                      {sliderData.map((e, i) => (
                        <div className={`slider-title ${sliderColor[i]}`}>
                          <p className="silder--title">{e.Title}</p>
                          <p className="silder--data">{e.data.toFixed(2)}</p>
                          <p className="silder--unit">
                            {CompactTagUnit(selectUnit, e.unit)}
                          </p>
                        </div>
                      ))}
                    </Marquee>
                  </div>
                ) : (
                  <ShimmerText line={5} gap={10} />
                )}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card
                style={{
                  height: "550px",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="spinner-container">
                  {/* {isLoading1 && <span className="spinner-gifBox"></span>} */}
                  <PieChartBoxCard
                    activeChart={activeChart1}
                    handleChartClick={handleChartClick1}
                    startDate={startDateReFormat2}
                    endDate={endDateReFormat2}
                    sliderData={sliderData1}
                    access={plantAccess}
                    sliderColorData={sliderColor1}
                    dashboard={
                      dashboard.length ? dashboard[0].position_6_names : []
                    }
                    tagData={tagData6}
                    positionValue={positionValue6}
                    sum={positionValue_sum_6}
                    handleStartDate={handleStartDate2}
                    handleEndDate={handleEndDate2}
                    PiaChartBorderColor={PiaChartBorderColor2}
                    ShimmerPieChart={isShimmer1}
                    ShimmerBarChart={isShimmerBarChart1}
                    positionId={positionId6}
                    mapTagDetails={mapTagDetails6}
                  />
                </div>

                {!isShimmer1 && !isShimmerBarChart1 ? (
                  <div className="slider-data-div">
                    <Marquee pauseOnHover={true}>
                      {sliderData1.map((e, i) => (
                        <div className={`slider-title ${sliderColor1[i]}`}>
                          <p className="silder--title">{e.Title}</p>
                          <p className="silder--data">{e.data.toFixed(2)}</p>
                          <p className="silder--unit">
                            {CompactTagUnit(selectUnit, e.unit)}
                          </p>
                        </div>
                      ))}
                    </Marquee>
                  </div>
                ) : (
                  <ShimmerText line={5} gap={10} />
                )}
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card
                style={{
                  height: "550px",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="spinner-container">
                  {/* {isLoading2 && <span className="spinner-gifBox"></span>} */}
                  <PieChartBoxCard
                    activeChart={activeChart2}
                    handleChartClick={handleChartClick2}
                    access={plantAccess}
                    startDate={startDateReFormat3}
                    endDate={endDateReFormat3}
                    sliderData={sliderData2}
                    sliderColorData={sliderColor2}
                    dashboard={
                      dashboard.length ? dashboard[0].position_7_names : []
                    }
                    positionValue={positionValue7}
                    tagData={tagData7}
                    sum={positionValue_sum_7}
                    handleStartDate={handleStartDate3}
                    handleEndDate={handleEndDate3}
                    PiaChartBorderColor={PiaChartBorderColor3}
                    ShimmerPieChart={isShimmer2}
                    ShimmerBarChart={isShimmerBarChart2}
                    positionId={positionId7}
                    mapTagDetails={mapTagDetails7}
                  />
                </div>
                {!isShimmer2 && !isShimmerBarChart2 ? (
                  <div className="slider-data-div">
                    <Marquee pauseOnHover={true}>
                      {sliderData2.map((e, i) => (
                        <div className={`slider-title ${sliderColor2[i]}`}>
                          <p className="silder--title">{e.Title}</p>
                          <p className="silder--data">{e.data.toFixed(2)}</p>
                          <p className="silder--unit">
                            {CompactTagUnit(selectUnit, e.unit)}
                          </p>
                        </div>
                      ))}
                    </Marquee>
                  </div>
                ) : (
                  <ShimmerText line={5} gap={10} />
                )}
              </Card>
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={1} className="pt-4 process-btn-grid">
          <Grid item xs={6} sm={6} md={4} lg={3}>
            {(overViewAccess?.is_viewer && overViewAccess?.is_editor) ||
            (!overViewAccess?.is_viewer && overViewAccess?.is_editor) ||
            (overViewAccess?.is_viewer && !overViewAccess?.is_editor) ? (
              <button
                // className="Process-dashboard-btn"
                className={`${
                  activeClass == "overview"
                    ? "active-process-dashboard"
                    : "Process-dashboard-btn"
                }`}
                // style={{pointerEvents:"none"}}
                onClick={() => handleOverViewClick()}
              >
                OVERVIEW
              </button>
            ) : (
              <button
                // className="Process-dashboard-btn"
                className={`${
                  activeClass == "overview"
                    ? "active-process-dashboard"
                    : "Process-dashboard-btn"
                }`}
                // style={{pointerEvents:"none"}}
                onClick={() => handleTostMsg()}
              >
                OVERVIEW
              </button>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            {(sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
            (!sourceAccess?.is_viewer && sourceAccess?.is_editor) ||
            (sourceAccess?.is_viewer && !sourceAccess?.is_editor) ? (
              <button
                className={`${
                  activeClass == "source"
                    ? "active-process-dashboard"
                    : "Process-dashboard-btn"
                }`}
                onClick={() => handleConsumption("source")}
              >
                SOURCE
              </button>
            ) : (
              <button
                className={`${
                  activeClass == "source"
                    ? "active-process-dashboard"
                    : "Process-dashboard-btn"
                }`}
                // style={{ pointerEvents: "none" }}
                onClick={() => handleTostMsg()}
              >
                SOURCE
              </button>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            {(consumptionAccess?.is_viewer && consumptionAccess?.is_editor) ||
            (!consumptionAccess?.is_viewer && consumptionAccess?.is_editor) ||
            (consumptionAccess?.is_viewer && !consumptionAccess?.is_editor) ? (
              <button
                className={`${
                  activeClass == "consumption"
                    ? "active-process-dashboard"
                    : "Process-dashboard-btn"
                }`}
                onClick={() => handleConsumption("consumption")}
              >
                CONSUMPTIONS
              </button>
            ) : (
              <button
                className={`${
                  activeClass == "consumption"
                    ? "active-process-dashboard"
                    : "Process-dashboard-btn"
                }`}
                onClick={() => handleTostMsg()}
              >
                CONSUMPTIONS
              </button>
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3}>
            {(analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
            (!analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
            (analyticsAccess?.is_viewer && !analyticsAccess?.is_editor) ? (
              <button
                className={`${
                  activeClass == "Analytics"
                    ? "active-process-dashboard"
                    : "Process-dashboard-btn"
                }`}
                // style={{ pointerEvents: "none"}}
                onClick={() => handleAdvanceAnalytics()}
              >
                ADVANCED ANALYTICS
              </button>
            ) : (
              <button
                className={`${
                  activeClass == "Analytics"
                    ? "active-process-dashboard"
                    : "Process-dashboard-btn"
                }`}
                // style={{ pointerEvents: "none"}}
                onClick={() => handleTostMsg()}
              >
                ADVANCED ANALYTICS
              </button>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default ProcessDashboard;
