import { Box, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DatePicker,
  LocalizationProvider,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import React from "react";
import { useDispatch } from "react-redux";
import { setAnalyticsData } from "../../container/ProcessDashboard/AdvanceAnalytics/Slice/AdvanceAnalyticsSlice";

const POPDatePicker = ({
  time,
  startDateReFormat,
  endDateReFormat,
  handleStartDate,
  handleEndDate,
  handlePOP1StartDate,
  handlePOP1EndDate,
  startDate1ReFormat,
  endDate1ReFormat,
  access,
  handleTostMsg,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      {time ? (
        <>
          {(access?.is_viewer && access?.is_editor) ||
            (!access?.is_viewer && access?.is_editor) || (access?.is_viewer && !access?.is_editor) ? (
            <>
              <div className="pop-datepicker-div">
                <div className="pop-datepicker-wrap1">
                  <div>
                    <Box
                      // className="box-datepicker"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="Start Date"
                          className="custom-datepicker datepicker-color"
                          value={startDateReFormat}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          disableFuture
                          onChange={(newValue) => handleStartDate(newValue)}
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                  <div>
                    <Box
                      // className="header-btn-month"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="End Date"
                          className="custom-datepicker datepicker-color"
                          value={endDateReFormat}
                          onChange={(newValue) => handleEndDate(newValue)}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          disableFuture
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                </div>
                <div className="pop-datepicker-wrap2">
                  <div>
                    <Box
                      // className="box-datepicker"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="Start Date"
                          className="custom-datepicker second-datepicker-color"
                          value={startDate1ReFormat}
                          onChange={(newValue) => handlePOP1StartDate(newValue)}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          disableFuture
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                  <div>
                    <Box
                      // className="header-btn-month"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="End Date"
                          className="custom-datepicker second-datepicker-color"
                          value={endDate1ReFormat}
                          onChange={(newValue) => handlePOP1EndDate(newValue)}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          disableFuture
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="pop-datepicker-div">
                <div className="pop-datepicker-wrap1">
                  <div>
                    <Box
                      // className="box-datepicker"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="Start Date"
                          className="custom-datepicker datepicker-color"
                          // value={startDateReFormat}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          disableFuture
                          onChange={handleTostMsg}
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                  <div>
                    <Box
                      // className="header-btn-month"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="End Date"
                          className="custom-datepicker datepicker-color"
                          // value={endDateReFormat}
                          onChange={handleTostMsg}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          disableFuture
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                </div>
                <div className="pop-datepicker-wrap2">
                  <div>
                    <Box
                      // className="box-datepicker"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="Start Date"
                          className="custom-datepicker second-datepicker-color"
                          // value={startDate1ReFormat}
                          onChange={handleTostMsg}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          disableFuture
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                  <div>
                    <Box
                      // className="header-btn-month"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="End Date"
                          className="custom-datepicker second-datepicker-color"
                          // value={endDate1ReFormat}
                          onChange={handleTostMsg}
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          disableFuture
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {(access?.is_viewer && access?.is_editor) ||
            (!access?.is_viewer && access?.is_editor) || (access?.is_viewer && !access?.is_editor) ? (
            <>
              <div className="pop-datepicker-div">
                <div className="pop-datepicker-wrap1">
                  <div>
                    <Box
                      // className="box-datepicker"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="custom-datepicker datepicker-color"
                          label="Start Date"
                          value={startDateReFormat}
                          inputFormat="DD/MM/YYYY"

                          onChange={(newValue) => handleStartDate(newValue)}
                          disableFuture
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                              InputLabelProps={{
                                style: {
                                  color: "#C0255F",
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                  <div>
                    <Box
                      // className="header-btn-month"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="custom-datepicker datepicker-color"
                          label="End Date"
                          value={endDateReFormat}
                          inputFormat="DD/MM/YYYY"

                          onChange={(newValue) => handleEndDate(newValue)}
                          disableFuture
                          renderInput={(params) => (
                            <TextField {...params} style={{ background: "#ffff" }} />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                </div>
                <div className="pop-datepicker-wrap2">
                  <div>
                    <Box
                      // className="box-datepicker"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="custom-datepicker second-datepicker-color"
                          label="Start Date"
                          value={startDate1ReFormat}
                          inputFormat="DD/MM/YYYY"

                          onChange={(newValue) => handlePOP1StartDate(newValue)}
                          disableFuture
                          renderInput={(params) => (
                            <TextField {...params} style={{ background: "#ffff" }} />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                  <div>
                    <Box
                      // className="header-btn-month"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="custom-datepicker second-datepicker-color"
                          label="End Date"
                          value={endDate1ReFormat}
                          inputFormat="DD/MM/YYYY"

                          onChange={(newValue) => handlePOP1EndDate(newValue)}
                          disableFuture
                          renderInput={(params) => (
                            <TextField {...params} style={{ background: "#ffff" }} />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="pop-datepicker-div">
                <div className="pop-datepicker-wrap1">
                  <div>
                    <Box
                      // className="box-datepicker"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="custom-datepicker datepicker-color"
                          label="Start Date"
                          value={startDateReFormat}
                          inputFormat="DD/MM/YYYY"

                          onChange={handleTostMsg}
                          disableFuture
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                              InputLabelProps={{
                                style: {
                                  color: "#C0255F",
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                  <div>
                    <Box
                      // className="header-btn-month"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="custom-datepicker datepicker-color"
                          label="End Date"
                          value={endDateReFormat}
                          inputFormat="DD/MM/YYYY"

                          onChange={handleTostMsg}
                          disableFuture
                          renderInput={(params) => (
                            <TextField {...params} style={{ background: "#ffff" }} />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                </div>
                <div className="pop-datepicker-wrap2">
                  <div>
                    <Box
                      // className="box-datepicker"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="custom-datepicker second-datepicker-color"
                          label="Start Date"
                          value={startDate1ReFormat}
                          inputFormat="DD/MM/YYYY"

                          onChange={handleTostMsg}
                          disableFuture
                          renderInput={(params) => (
                            <TextField {...params} style={{ background: "#ffff" }} />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                  <div>
                    <Box
                      // className="header-btn-month"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="custom-datepicker second-datepicker-color"
                          label="End Date"
                          value={endDate1ReFormat}
                          inputFormat="DD/MM/YYYY"

                          onChange={handleTostMsg}
                          disableFuture
                          renderInput={(params) => (
                            <TextField {...params} style={{ background: "#ffff" }} />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default POPDatePicker;
