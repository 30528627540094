import { request } from "../../../api/api";

// Save payment data
export const saveForLater = (param) => {
    return request.post("/subscription/SaveForLater/", param)
}

// get RazorpayKey
export const getrazorpayKey = () => {
    return request.post('/subscription/getPaymentKey/')
}
// get payment Data
export const getSubscriptionOnProccedtoPayment = (params) => {
    return request.post("/subscription/getSubscriptionOnProccedtoPayment/",params)
}

// createPayment method
export const createPaymentMethod = (param) => {
    return request.post("/subscription/createPaymentMethod/", param)
}

// get cretatePay method data
export const getPaymentMethod = () => {
    return request.get("/subscription/getPaymentMethod/")
}

// payment checkout
export const paymentCheckout = (param) => {
    return request.post("/subscription/createOrderId/", param)
}

// capturePayment
export const capturePayment = (param) => {
    return request.post("/subscription/capturePayment/", param)
}

export const getSubscription = (param)=>{
    return request.post("/subscription/getSubscriptions/",param)
}

export const getChoices = () =>{
    return request.get(`/choices/choices/`)
}

export const getSubscribeDeviceLicense = (params) =>{
    return request.post(`/subscription/getSubscribeDeviceLicense/`,params)
}

export const deleteDeviceLicense = (params)=>{
    return request.post(`/subscription/deleteDeviceLicense/`,params)
}

export const customSmartTagPlan = (params) =>{
    return request.post(`/subscription/updateCustomSmartTagLicensePlan/`,params)
}