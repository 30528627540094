// export const CompactTagValue = (selectUnit, value) => {

//     if (selectUnit == "k") {
//         return parseFloat(value).toFixed(2);
//     }

//     if (selectUnit == "m") {
//         return (value / 1000).toFixed(2);
//     }

//     if (selectUnit == "g") {
//         return (value / 1000000).toFixed(2);
//     }
// }

// export const CompactTagUnit = (selectUnit, tagUnit) => {

//     if (selectUnit == "k") {
//         return tagUnit;
//     }

//     if (selectUnit == "m") {
//         return tagUnit.replace(/K/i, match => (match === 'K' ? 'M' : 'm'));
//     }

//     if (selectUnit == "g") {
//         return tagUnit.replace(/K/i, match => (match === 'K' ? 'G' : 'g'));
//     }
// }

export const CompactTagValue = (selectUnit, value) => {
    // Check if the value is a timestamp
    const isTimestamp = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(value);

    if (isTimestamp) {
        return value; // Return the timestamp as it is
    }

    if (selectUnit == "k") {
        return parseFloat(value).toFixed(2);
    }

    if (selectUnit == "m") {
        return (value / 1000).toFixed(2);
    }

    if (selectUnit == "g") {
        return (value / 1000000).toFixed(2);
    }
    
    return value; // Default return if none of the conditions match
}

export const CompactTagUnit = (selectUnit, tagUnit) => {
    if (selectUnit == "k") {
        return tagUnit;
    }

    if (selectUnit == "m") {
        return tagUnit.replace(/K/i, match => (match === 'K' ? 'M' : 'm'));
    }

    if (selectUnit == "g") {
        return tagUnit.replace(/K/i, match => (match === 'K' ? 'G' : 'g'));
    }

    return tagUnit; // Default return if none of the conditions match
}
