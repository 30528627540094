import React, { useRef, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import '../ProcessDashboard.css';

const DonutChart1 = ({ positionValue, tagData, startDate, endDate, sum, dashboard }) => {
    const chartContainer = useRef(null);
    const chartInstance = useRef(null);

    useEffect(() => {
        const data = {
            labels: dashboard.length ? dashboard.map((e) => e.tag_comment) : [''],
            datasets: [
                {
                    data: positionValue ? positionValue : [],
                    backgroundColor: [
                        '#F06292',
                        '#29445A',
                        '#CD4D4A',
                        '#0C4562',
                        '#5A4632',
                        '#94655B',
                    ],
                    hoverBackgroundColor: [
                        '#F06292',
                        '#29445A',
                        '#CD4D4A',
                        '#0C4562',
                        '#5A4632',
                        '#94655B',
                    ],
                },
            ],
        };

        const options = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            const dataset = data.datasets[context.datasetIndex];
                            const total = dataset.data.reduce(
                                (previousValue, currentValue) =>
                                    previousValue + currentValue
                            );
                            const currentValue = dataset.data[context.dataIndex];
                            const percentage =
                                ((currentValue / total) * 100).toFixed(2) + '%';
                            return `${currentValue} (${percentage})`;
                        },
                    },
                },
                legend: {
                    display: false,
                    labels: {
                        display: false,
                    },
                },
                datalabels: {
                    color: '#fff',
                    anchor: 'center',
                    align: 'center',
                    offset: 0,
                    font: {
                        weight: 'bold',
                        size: '14',
                    },
                    formatter: function (value, context) {
                        const dataset = data.datasets[context.datasetIndex];
                        const total = dataset.data.reduce(
                            (previousValue, currentValue) => previousValue + currentValue
                        );
                        const currentValue = dataset.data[context.dataIndex];
                        const percentage =
                            ((currentValue / total) * 100).toFixed(2) + '%';
                        return `${value}\n(${percentage})`;
                    },
                },
            },
            cutout: '70%', // Adjust this value to set the width of the doughnut chart
        };

        if (positionValue) {
            if (chartInstance.current) {
                // Update the existing chart instance
                chartInstance.current.data = data;
                chartInstance.current.options = options;
                chartInstance.current.update();
            } else {
                // Create a new chart instance
                const ctx = chartContainer.current.getContext('2d');
                chartInstance.current = new Chart(ctx, {
                    type: 'doughnut',
                    data: data,
                    options: options,
                });
            }
        }
    }, [positionValue, tagData]);

    return (
        <div style={{ width: '100%', height: '250px', marginTop: "68px" }} className='doughnut-chart'>
            <canvas ref={chartContainer} id="canvas" style={{ margin: 'auto' }} />
            <p className='doughnut-chartText'>
                {dashboard.length ? sum : ""}
            </p>
            <p className='doughnut-chartText-small'>
                {dashboard.length ? dashboard[0].tag_unit : ""}
            </p>
        </div>
    );
};

export default DonutChart1;
