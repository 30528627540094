import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";

HC_more(Highcharts); // Initialize the 'highcharts-more' module
exporting(Highcharts); // Initialize the 'exporting' module

const SelectedTagGraphData = ({ unitySelectTag, selectTagRealTimeData, selectTagHH, selectTagHI }) => {

    // Static data for demonstration
    // const categories = ["2022-01-01", "2022-01-02", "2022-01-03", "2022-01-04", "2022-01-05"];
    const [optionCahrt, setOptionChart] = useState({
        chart: {
            type: "spline",
            // =================oldcode==============
            // scrollablePlotArea: {
            //     minWidth: 700,
            // },
        },
        title: {
            text: null,
        },
        xAxis: {
            categories: [],
            scrollbar: {
                enabled: true,
            },
          
        },
        yAxis: {
            title: {
                text: "",
            },
            plotLines: [],
        },
        series: [],
        colors: ["#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#FFFF00"],
        exporting: {
            enabled: true, // Disable exporting options
        },
        credits: {
            enabled: false, // Disable credits
        },
    })

    const seriesFortag = [
        {
            name: unitySelectTag,
            data: selectTagRealTimeData.length ? selectTagRealTimeData.map((row) => parseFloat(row.tagValue)) : [],
            color: "#C87171",
            dashStyle: "Solid",
            lineWidth: 1,
        }
    ];

    const seriesData = [...seriesFortag];
    const categories = selectTagRealTimeData.length ? selectTagRealTimeData.map((row) => row.tagTime) : []



    useEffect(() => {

        setOptionChart({
            ...optionCahrt,
            xAxis: {
                ...optionCahrt.xAxis,
                categories: categories.concat([""]),
                // =========old code========
                // min: 0,
                // max: categories.length - 1,
            },
            yAxis: {
                title: {
                    text: unitySelectTag,
                },
                plotLines: [
                     {
                        value: selectTagHH, 
                        color: "red", 
                        dashStyle: "ShortDash", 
                        width: 2, 
                        label: {
                            text: `HH(${selectTagHH})`, 
                            align: "left", 
                            x: 10, // x offset of label
                        },
                        zIndex: -1, // Set zIndex to position the plot line behind the chart
                    },
                    {
                        value: selectTagHI,
                        color: "#F7C51E",
                        dashStyle: "ShortDash",
                        width: 2,
                        label: {
                            text: `HI(${selectTagHI})`,
                            align: "left",
                            x: 10, // x offset of label
                        },
                        zIndex: -1, // Set zIndex to position the plot line behind the chart
                    }
                ],
            },
            series: seriesData
        });
        
    }, [unitySelectTag, selectTagRealTimeData]);

    return (
        <div style={{ height: "320px" }}>
            <HighchartsReact highcharts={Highcharts} options={optionCahrt} />
        </div>
    );
};

export default SelectedTagGraphData;