import "./paretoChartShimmer.css";



export const ParetoChartShimmer = ({ barCount=12  }) => {

const generateRandomHeights = (count) => {
    // const CountArray =  Array.from(
	// 	{ length: count },
	// 	() => Math.round(Math.random() * (95 - 30) + 30) + "%",
	// )
// console.log("CountArray....",CountArray)
const CountArray = ['61%', '63%', '64%', '92%', '46%', '43%', '34%', '54%', '78%', '45%', '39%', '77%','43%', '34%','64%', '92%']
	return (
		<div className="verticalBarShimmerContainer">
			{CountArray.map((height, index) => (
				<div
					key={index}
					className="verticalBarShimmer"
					style={{ height }}
				></div>
			))}
		</div>
	);
    

	
};

const Bars =  generateRandomHeights(barCount);

return Bars;
	
};




export const SmallParetoChartShimmer = ({ barCount=12  }) => {

	const generateRandomHeights = (count) => {
		// const CountArray =  Array.from(
		// 	{ length: count },
		// 	() => Math.round(Math.random() * (95 - 30) + 30) + "%",
		// )
	// console.log("CountArray....",CountArray)
	const CountArray = ['61%', '63%', '64%', '92%', '46%', '43%', '34%', '54%', '78%', '45%']
		return (
			<div className="small-verticalBarShimmerContainer">
				{CountArray.map((height, index) => (
					<div
						key={index}
						className="small-verticalBarShimmer"
						style={{ height }}
					></div>
				))}
			</div>
		);
		
	
		
	};
	
	const Bars =  generateRandomHeights(barCount);
	
	return Bars;
		
	};
	