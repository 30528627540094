import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ShimmerText } from "react-shimmer-effects";
import {ParetoChartShimmer} from "../../../components/Shimmer/ParetoChartShimmer/ParetoChartShimmer"

HC_more(Highcharts);
exporting(Highcharts);


const BarGraph = ({
    BarGraphData1,
    BarGraphData2,
    VmsReportSelectmachine,
    shimmer
}) => {

    const categories = [];

    console.log("VmsReportSelectmachineVmsReportSelectmachine", VmsReportSelectmachine);

    const selectUnit = VmsReportSelectmachine ? VmsReportSelectmachine.length ? VmsReportSelectmachine[0]["tagUnit"] : "" : "";

    if (BarGraphData1 || BarGraphData2 || BarGraphData1.length || BarGraphData2.length) {
        const popDataLength = BarGraphData1 ? BarGraphData1[0]?.data?.length || 0 : 0;
        const pop1DataLength = BarGraphData2 ? BarGraphData2[0]?.data?.length || 0 : 0;
        const maxLength = Math.max(popDataLength, pop1DataLength);

        for (let i = 0; i < maxLength; i++) {
            const popData = BarGraphData1 ? BarGraphData1[0]?.data[i] || {} : {};
            const pop1Data = BarGraphData2 ? BarGraphData2[0]?.data[i] || {} : {};
            const minKey = popData?.MIN_KEY || "";
            const pop1MinKey = pop1Data?.MIN_KEY || "";
            const labeledMinKey = `<span style="color: #C0255F;">${minKey}</span>`;
            const labeledPop1MinKey = `<span style="color: #E66595;">${pop1MinKey}</span>`;
            categories.push(`${labeledMinKey} ${labeledPop1MinKey}`);
        }
    }

    const data = [
        {
            name: BarGraphData1 ? BarGraphData1.length ? BarGraphData1[0].tag_name : "" : "",
            data: BarGraphData1 ? BarGraphData1.length
                ? BarGraphData1[0] && BarGraphData1[0].data.length
                    ? BarGraphData1[0].data.map((row) => parseFloat(row.tag_value))
                    : []
                : [] : [],
            color: '#C0255F',
            stack: "Stack 0",
            barPercentage: 1,
            barThickness: 30,
        },

        {
            name: BarGraphData2 ? BarGraphData2.length ? BarGraphData2[0].tag_name : "" : "",
            data: BarGraphData2 ? BarGraphData2.length
                ? BarGraphData2[0] && BarGraphData2[0].data.length
                    ? BarGraphData2[0].data.map((row) => parseFloat(row.tag_value))
                    : []
                : [] : [],
            color: "#E66595",
            stack: "Stack 1",
            barPercentage: 1,
            barThickness: 30,
        },
    ]


    const options = {
        chart: {
            type: "column",
        },
        title: {
            text: null,
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            title: {
                text: selectUnit,
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: "bold",
                    color: "gray",
                },
            },
        },
        plotOptions: {
            column: {
                stacking: "normal",
                dataLabels: {
                    enabled: false
                },  
            },
        },
        series: data,
        scrollbar: {
            enabled: true, // Enable scrollbar
        },
        exporting: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };

    return (
        <div>
            {!shimmer ? <HighchartsReact highcharts={Highcharts} options={options} /> : <ParetoChartShimmer className="ParetoChartShimmer-OverView"/> }
            
        </div>
    );
}

export default BarGraph;




{/* <ShimmerText line={12} gap={15}/> */}
