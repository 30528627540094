
import React from "react";
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckIcon from '@mui/icons-material/Check';
import { images } from "../../../../../config/images";
import { useState } from "react";
import '../Configure.css'


const TabConfigure1 = ({ e }) => {
    const [editIcone, setEditIcone] = useState(false);

    const handleEditRow = (id) => {
        setEditIcone(!editIcone);
    }
    return (
        <TableRow className='deviceManagementTable-data'>
            <TableCell style={{ backgroundColor: '#fff' }}>
                {editIcone ? (
                    <CheckIcon style={{ width: '20px' }} onClick={() => handleEditRow()} />
                ) : (
                    <img src={images.editImg} alt="" style={{ width: '20px' }} onClick={() => handleEditRow()} />
                )}
            </TableCell>
            <TableCell>
                <input type="text" id="fname" className="configure-input" disabled={false} value={e.tag_name == "" ? "-" : e.tag_name} />
            </TableCell>
            <TableCell>
                <input type="text" id="fname" className="configure-input" disabled={false} value={e.tag_comment == "" ? "-" : e.tag_comment} />
            </TableCell>
        </TableRow>
    )
}

export default TabConfigure1;