import React, { useEffect, useMemo, useRef } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Checkbox, Grid, Switch, TextField } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { images } from "../../../../../config/images";
import { OverViewLineChart } from "./OverViewLineChart";
import { getDeviceUnit } from "../../../../ProcessDashboard/OverView/services";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Input from "@mui/material/Input";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import VmsARMachine from "../VmsARMachine/VmsARMachine";
import SettingsIcon from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";
import {
  getDeviceType,
  getTagID,
} from "../../../../ProcessDashboard/Source/services";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { setVmsTagData } from "./slice/vmsOverViewSlice";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { useSelector, useDispatch } from "react-redux";
import Mqqtdata from "../../../../ProcessDashboard/Mqqtdata";
import HighchartsGraph from "./graph/LineChart";
import OverViewGraphData from "./OverViewGraphData";
import Every15minitesRemoveData from "./Every15minitesRemoveData/Every15minitesRemoveData";
import OverViewmqttData from "./OverViewMqttData/OverViewmqttData";
import {
  getUnityModel,
  selectTagDataApi,
  choiceType,
  addOverviewTagSuggestionApi,
  getOverviewTagSuggestionApi,
} from "../service";
import OverViewSelectTagData from "./OverViewSelectTagData/OverViewSelectTagData";
import SelectedTagGraphData from "./graph/selectedTagGraphData";
import { ToastContainer, toast } from "react-toastify";
import { vmsOverViewReportExcel } from "../Reports/service";
import CheckIcon from "@mui/icons-material/Check";
import { getConsumptionDeviceData } from "../../../../ProcessDashboard/Consumption/service";
import "./OverView.css";
import CancelIcon from "@mui/icons-material/Cancel";
import DeviceListShimmer from "../../../../../components/Shimmer/DeviceListShimmer/DeviceListShimmer";

const VmsOverView = () => {
  const navigate = useNavigate();
  const [overViewMachine, setOverViewMachine] = useState(false);
  // const [overViewMachine1, setOverViewMachine1] = useState(true);
  const [overViewModal, setOverViewModal] = useState(false);
  const [tagUnitData, setTagUnitData] = useState([]);
  const [selectUnit, setSelectUnit] = useState("");
  const [tagData, setTagData] = useState([]);
  const [searchTagName, setSearchTagName] = useState("");
  const [findTagUnit, setFindTagUnit] = useState(false);
  const [tagVMSData, setTagVMSData] = useState([]);
  const [deviceData, setDeviceData] = useState("");
  const [lineGraphData1, setLineGraphData1] = useState([]);
  const [lineGraphData2, setLineGraphData2] = useState([]);
  const [lineGraphData3, setLineGraphData3] = useState([]);
  const [lineGraphData4, setLineGraphData4] = useState([]);
  const [combineChartData, setCombineChartData] = useState([[], [], [], []]);
  const [tagMinMaxValue, setTagMinMaxValue] = useState([]);
  const [average, setAverage] = useState(false);
  const [averageValue, setAverageValue] = useState([]);
  const [mqttTagPreviousvalue, setMqttTagPreviousvalue] = useState([]);
  const [tagStatus, setTagStatus] = useState([]);
  const [deviceUID, setDeviceUID] = useState("");
  const [modelFound, setModelFound] = useState(false);
  const [modelUrlData, setModelUrlData] = useState(null);
  const [navigateRoute, setNavigateRoute] = useState("");
  const [unitySelectTag, setUnitySelectTag] = useState("");

  const [selectTagRealTimeData, setSelectTagRealTimeData] = useState([]);
  const [selectTagOverviewData, setSelectTagOverviewData] = useState("");
  const [selectMqttTagPreviousvalue, setSelectMqttTagPreviousvalue] = useState(
    {}
  );

  const [isShimmerUnitlist, setShimmerUnitList] = useState(false);
  const [selectTagRate, setSelectTagRate] = useState("-");
  const [choiceData, setChoiceData] = useState([]);
  const [choiceSelectId, setChoiceSelectId] = useState("");
  const [reasonData, setReasonData] = useState("");
  const [openMachine, setOpenMachine] = useState(false);
  const [getOverviewSuggestionMessage, setGetOverviewSuggestionMessage] =
    useState([]);

  //Number of crossing value
  const [crossingCount, setCrossingCount] = useState(0);
  const [selectTagHiValue, setSelectTagHiValue] = useState(null);
  const [selectTagHhValue, setSelectTagHhValue] = useState(null);
  const [thresoldValue1, setThresoldValue1] = useState(false);
  const [thresoldValue2, setThresoldValue2] = useState(false);

  //model state data
  const [sendRealTimeDataModel, setSendRealTimeDataModel] = useState([]);
  const [tagListDataForDevice, setTagListDataForDevice] = useState([]);

  //fullscreenn
  const [fullScreenModel, setFullScreenModel] = useState(false);

  const { user_access } = useSelector((state) => state.userState);
  // console.log("crossingCountcrossingCountcrossingCount", crossingCount);
  const [anchorEl, setAnchorEl] = useState(null);

  const { vmsTagSelect } = useSelector((state) => state.vmsOverViewSlice);
  const dispatch = useDispatch();
  // enchorEL

  // console.log("tagMinMaxValuetagMinMaxValue", tagMinMaxValue, vmsTagSelect);
  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notify = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  // const handleSetingModel = (event, close) => {
  //   if (close == "close") {
  //     setAnchorEl(null);
  //     return true;
  //   }
  //   setAnchorEl(anchorEl ? null : event.currentTarget);
  // };
  console.log("combineChartDatacombineChartData",combineChartData,averageValue,lineGraphData1,lineGraphData2);
  // handle fullscrenn
  const handleFullScrennModel = () => {
    setFullScreenModel((prev) => !prev);
  };

  const handlePropagation = (event) => {
    event.stopPropagation();
  };

  const isCheckboxTagUnitDisabled = (tagUnit) => {
    const findPlant = vmsTagSelect.length
      ? vmsTagSelect.filter((f) => f.deviceId == param.deviceId)
      : [];
    return (
      findPlant.length >= 1 &&
      !findPlant.some((device) => device.tagUnit === tagUnit)
    );
  };

  const handleOverViewNavigate = async () => {
    // await unload();
    navigate(
      `/vms-overview/company/${param.companyId}/plant/${param.deviceId}/device/${param.deviceId}/machine/${param.machineId}`
    );
  };

  const switchColors = [
    "#FEBB22",
    "#002B2A",
    "#C99C00",
    "#EB9E79",
    "#69927A",
    "#872B81",
    "#F06292",
    "#0EB520",
    "#CD4D4A",
    "#123782",
    "#544632",
    "#94655B",
    "#0F4C81",
    "#8B0000",
    "#C60BC8",
    "#7D2264",
    "#BC2F00",
    "#556830",
    "#FCA311",
    "#021116",
    "#808080",
    "#300338",
    "#634C23",
    "#841E62",
    "#F34424",
    "#003333",
    "#020000",
    "#305355",
    "#5C3A31",
    "#D9750C",
    "#0C4562",
    "#001A30",
    "#00838F",
    "#785049",
    "#FF8800",
    "#0B7C8E",
    "#08535E",
    "#0E3504",
    "#C48200",
    "#846748",
    "#075571",
    "#9C27B0",
    "#A97E07",
    "#CE286D",
    "#8409DF",
    "#0B648D",
    "#29445A",
    "#1D733F",
    "#6E02B1",
  ];

  const param = useParams();

  const handleBackNavigate = async () => {
    if (unitySelectTag) {
      setUnitySelectTag("");
      setSelectTagRealTimeData([]);
      setCrossingCount(0);
      setThresoldValue1(false);
      setThresoldValue2(false);
      return true;
    }

    // if (modelFound) {
    //   navigate(
    //     `/submachine/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}`
    //   );
    //   return true;
    // }
    setNavigateRoute(
      `/submachine/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`
    );
  };

  const handleOpenModal = () => {
    console.log("handleOpenModal Clicked.......", !overViewModal);
    // setOverViewModal((prev) => !prev);
    setOverViewModal(!overViewModal);
  };

  const modalRef = useRef(null);

  // Function to close the modal
  const closeModal = () => {
    console.log("closeModal..................", overViewModal);
    setOverViewModal(false);
  };

  // Add a click event listener to the window when the modal is open
  useEffect(() => {
    console.log("useEffect....overViewModal...", overViewModal);
    const handleWindowClick = (event) => {
      console.log("event.target......", event.target);
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Click occurred outside of the modal
        console.log("useEffect....overViewModal...1", overViewModal);
        // closeModal();//nikunj
      }
    };

    if (overViewModal) {
      window.addEventListener("click", handleWindowClick);
    } else {
      window.removeEventListener("click", handleWindowClick);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [overViewModal]);

  const getDeviceUnitApi = async () => {
    const paramReq = {
      device_id: param.deviceId,
      plant_id: param.deviceId,
      company_id: param.companyId,
    };
    try {
      const resp = await getDeviceUnit(paramReq);
      if (resp.data.payload.length) {
        const uniqueTagUnits = {};

        // Filter out objects with duplicate tag_unit values
        const filteredData = resp.data.payload.filter((obj) => {
          if (!obj.tag_unit) return true; // Keep objects with no tag_unit property
          if (!uniqueTagUnits[obj.tag_unit]) {
            uniqueTagUnits[obj.tag_unit] = true;
            return true;
          }
          return false;
        });
        setTagUnitData(filteredData);
        setTagListDataForDevice(resp.data.payload);
      }
    } catch (error) {
      console.log("errorerror", error);
    }
  };

  useEffect(() => {
    getDeviceUnitApi();
  }, []);

  useEffect(() => {
    const findPlant = vmsTagSelect.length
      ? vmsTagSelect.filter((f) => f.deviceId == param.deviceId)
      : [];
    const findTagUnit = findPlant.length ? findPlant[0]["tagUnit"] : "";

    // console.log("findTagUnit", findTagUnit);
    setSelectUnit(findTagUnit);
    setFindTagUnit(findTagUnit == "" ? true : false);
    setTagData([]);
    getTagIDAPI();
  }, [overViewModal]);

  const getTagIDAPI = async () => {
    if (selectUnit && param.deviceId) {
      const paramReq = {
        device_id: [Number(param.deviceId)],
        tag_unit: selectUnit,
      };
      try {
        setShimmerUnitList(true);
        const resp = await getTagID(paramReq);
        if (resp.status == 200 && resp.data.payload.length) {
          setTimeout(() => {
            setShimmerUnitList(false);
          }, 1000);
          setTagData(resp.data.payload);
        }
      } catch (error) {
        setShimmerUnitList(false);
        // Handle errors here
        console.error("An error occurred:", error);
      }
    }
  };

  const handleExportClick = async () => {
    const params = {
      company: param.companyId,
      plant: param.plantId,
      time: combineChartData.length
        ? combineChartData[0].map((e) => e.tagTime)
        : [],
      device: param.deviceId,
      tag: vmsTagSelect.length ? vmsTagSelect.map((e) => e.tag_id) : [],
      tag_unit: selectUnit,
      tag_value: combineChartData.length
        ? combineChartData.flatMap((array) => array.map((obj) => obj.tagValue))
        : [],
    };
    try {
      const resp = await vmsOverViewReportExcel(params);
      if (resp.data.success == true) {
        notify("Export Excel Successfully.");
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    if (selectUnit != "") {
      getTagIDAPI();
    } else {
      setTagData([]);
    }
  }, [selectUnit]);

  const getDeviceId = async () => {
    const params = {
      device: Number(param.deviceId),
    };

    try {
      const resp = await getConsumptionDeviceData(params);
      if (resp.status === 200) {
        setDeviceData(resp.data.payload);
        setDeviceUID(resp.data.payload.machine_uid.replace(/\//g, "_"));
      }
    } catch (error) {
      console.error("An error occurred while fetching device data:", error);
      // Handle the error appropriately, e.g., show an error message to the user.
    }
  };

  useEffect(() => {
    getDeviceId();
  }, []);

  useEffect(() => {
    getUnityModelAPI();
  }, []);

  // found Device is server side
  const getUnityModelAPI = async () => {
    const paramReq = {
      device_id: param.deviceId,
    };
    try {
      const resp = await getUnityModel(paramReq);
      if (resp.data.success == true) {
        setModelFound(false);
        setModelUrlData({
          dataUrl: resp.data.payload.data.split("?")[0],
          frameworkUrl: resp.data.payload.framework.split("?")[0],
          loaderUrl: resp.data.payload.loader.split("?")[0],
          wasmUrl: resp.data.payload.wasm.split("?")[0],
        });
      }
    } catch (error) {
      console.error("An error occurred while fetching device data:", error);
      setModelFound(true);
      // Handle the error appropriately, e.g., show an error message to the user.
    }
  };

  const handleSelectTagName = (deviceId, index, event, row, selectUnit) => {
    console.log("row", row);

    const existingIndex = vmsTagSelect.findIndex(
      (color) =>
        color.tagName === row.tag_name && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updatedColors = [...vmsTagSelect];
      updatedColors.splice(existingIndex, 1);
      dispatch(
        setVmsTagData({
          vmsTagSelect: updatedColors,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setVmsTagData({
          vmsTagSelect: [
            ...vmsTagSelect,
            {
              deviceId: deviceId,
              tagName: row.tag_name,
              color: event.target.name,
              tagUnit: selectUnit,
              deviceId: param.deviceId,
              device__uid: row.device__machine__uid,
              device_tag: row.device_tag,
              tag_id: row.id,
            },
          ],
        })
      );
    }
  };

  const isCheckboxDisabled = (tagName) => {
    const selectedDevices = vmsTagSelect.filter(
      (tag) => tag.deviceId === param.deviceId
    );
    return (
      selectedDevices.length >= 4 &&
      !selectedDevices.some((tag) => tag.tagName === tagName)
    );
  };

  useEffect(() => {
    setLineGraphData1([]);
    setLineGraphData2([]);
    setLineGraphData3([]);
    setLineGraphData4([]);
    setCombineChartData([[], [], [], []]);
    setTagMinMaxValue([]);
    setAverageValue([]);
    setCrossingCount(0)
  }, [
    vmsTagSelect.filter((tag) => tag.deviceId === param.deviceId).length,
    unitySelectTag,
  ]);

  useEffect(() => {
    const createObject = vmsTagSelect.length
      ? vmsTagSelect.filter((tag) => tag.deviceId === param.deviceId).length
        ? vmsTagSelect
            .filter((tag) => tag.deviceId === param.deviceId)
            .map((row) => {
              const obj = {
                tagName: row.device_tag ? row.device_tag : "-",
                prevValue: 0,
                curValue: 0,
              };
              return obj;
            })
        : []
      : [];

    setMqttTagPreviousvalue(createObject);
  }, [vmsTagSelect.filter((tag) => tag.deviceId === param.deviceId).length]);

  useEffect(() => {
    if (mqttTagPreviousvalue.length) {
      const averagCalTagvalue = mqttTagPreviousvalue.map((row) => {
        const avgCal = ((row.curValue - row.prevValue) / row.prevValue) * 100;
        // console.log("avgCal", avgCal);
        return {
          tagName: row.tagName,
          statusValue: isFinite(avgCal) ? avgCal : 0.0,
        };
      });
      setTagStatus(averagCalTagvalue);
    }
  }, [mqttTagPreviousvalue]);

  // selectTag rate increas
  useEffect(() => {
    if (selectMqttTagPreviousvalue.hasOwnProperty("tagName")) {
      const avgCal =
        ((selectMqttTagPreviousvalue.curValue -
          selectMqttTagPreviousvalue.prevValue) /
          selectMqttTagPreviousvalue.prevValue) *
        100;

      const statusRate = {
        tagName: selectMqttTagPreviousvalue.tagName,
        statusValue: isFinite(avgCal) ? parseFloat(avgCal.toFixed(2)) : 0.0,
      };

      setSelectTagRate(statusRate.statusValue);
    }
  }, [selectMqttTagPreviousvalue]);

  // useEffect(() => {
  //   if (
  //     vmsTagSelect.length &&
  //     vmsTagSelect.filter((f) => f.deviceId == param.deviceId).length == 1 &&
  //     lineGraphData1.length >= 2
  //   ) {
  //     const lastValue = parseFloat(
  //       lineGraphData1[lineGraphData1.length - 1].tagValue
  //     );
  //     const secondLastValue = parseFloat(
  //       lineGraphData1[lineGraphData1.length - 2].tagValue
  //     );
  //     const chartLineDataAverage = (lastValue + secondLastValue) / 2;
  //     // setAverageValue((prev) => [...prev, chartLineDataAverage]);
  //     setAverageValue((prev) => {
  //       // Create a shallow copy of the previous state
  //       const indexCopy = [...prev, chartLineDataAverage];
  //       //==========================new add code==============================
  //       if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
  //         indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
  //       }
  
  //       return indexCopy
  //     });
  //   }
  // }, [lineGraphData1]);

  // useEffect(() => {
  //   if (
  //     vmsTagSelect.length &&
  //     vmsTagSelect.filter((f) => f.deviceId == param.deviceId).length == 2 &&
  //     lineGraphData1.length != 0 &&
  //     lineGraphData2.length != 0 &&
  //     lineGraphData1.length == lineGraphData2.length
  //   ) {
  //     const device1 = lineGraphData1.length
  //       ? [lineGraphData1[lineGraphData1.length - 1]]
  //       : [];
  //     const device2 = lineGraphData2.length
  //       ? [lineGraphData2[lineGraphData2.length - 1]]
  //       : [];
  //     // const device3 = lineGraphData3.length ? [lineGraphData3[lineGraphData3.length - 1]] : [];
  //     // const device4 = lineGraphData4.length ? [lineGraphData4[lineGraphData4.length - 1]] : [];

  //     const nonEmptyArrays = [device1, device2].filter((arr) => arr.length > 0);
  //     const sum = nonEmptyArrays.reduce(
  //       (acc, arr) => acc + (parseFloat(arr[0]?.tagValue) || 0),
  //       0
  //     ); // Safely access tagValue

  //     // Calculate the average
  //     const average =
  //       nonEmptyArrays.length > 0 ? sum / nonEmptyArrays.length : 0;

  //     // Format the average to two decimal places using toFixed(2)
  //     const formattedAverage = average;

  //     // setAverageValue((prev) => [...prev, formattedAverage]);
  //     setAverageValue((prev) => {
  //       // Create a shallow copy of the previous state
  //       const indexCopy = [...prev, formattedAverage];
  //       //==========================new add code==============================
  //       if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
  //         indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
  //       }
  
  //       return indexCopy
  //     });
  //   }
  // }, [lineGraphData2,averageValue]);

  // useEffect(() => {
  //   if (
  //     vmsTagSelect.length &&
  //     vmsTagSelect.filter((f) => f.deviceId == param.deviceId).length == 3 &&
  //     lineGraphData1.length != 0 &&
  //     lineGraphData2.length != 0 &&
  //     lineGraphData3.length != 0 &&
  //     lineGraphData1.length == lineGraphData2.length &&
  //     lineGraphData2.length == lineGraphData3.length
  //   ) {
  //     const device1 = lineGraphData1.length
  //       ? [lineGraphData1[lineGraphData1.length - 1]]
  //       : [];
  //     const device2 = lineGraphData2.length
  //       ? [lineGraphData2[lineGraphData2.length - 1]]
  //       : [];
  //     const device3 = lineGraphData3.length
  //       ? [lineGraphData3[lineGraphData3.length - 1]]
  //       : [];
  //     // const device4 = lineGraphData4.length ? [lineGraphData4[lineGraphData4.length - 1]] : [];

  //     const nonEmptyArrays = [device1, device2, device3].filter(
  //       (arr) => arr.length > 0
  //     );
  //     const sum = nonEmptyArrays.reduce(
  //       (acc, arr) => acc + (parseFloat(arr[0]?.tagValue) || 0),
  //       0
  //     ); // Safely access tagValue

  //     // Calculate the average
  //     const average =
  //       nonEmptyArrays.length > 0 ? sum / nonEmptyArrays.length : 0;

  //     // Format the average to two decimal places using toFixed(2)
  //     const formattedAverage = average;

  //     // setAverageValue((prev) => [...prev, formattedAverage]);
  //     setAverageValue((prev) => {
  //       // Create a shallow copy of the previous state
  //       const indexCopy = [...prev, formattedAverage];
  //       //==========================new add code==============================
  //       if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
  //         indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
  //       }
  
  //       return indexCopy
  //     });
  //   }
  // }, [lineGraphData2, lineGraphData3,averageValue]);

  // useEffect(() => {
  //   if (
  //     vmsTagSelect.length &&
  //     vmsTagSelect.filter((f) => f.deviceId == param.deviceId).length == 4 &&
  //     lineGraphData1.length != 0 &&
  //     lineGraphData2.length != 0 &&
  //     lineGraphData3.length != 0 &&
  //     lineGraphData4.length != 0 &&
  //     lineGraphData1.length == lineGraphData2.length &&
  //     lineGraphData2.length == lineGraphData3.length &&
  //     lineGraphData3.length == lineGraphData4.length
  //   ) {
  //     const device1 = lineGraphData1.length
  //       ? [lineGraphData1[lineGraphData1.length - 1]]
  //       : [];
  //     const device2 = lineGraphData2.length
  //       ? [lineGraphData2[lineGraphData2.length - 1]]
  //       : [];
  //     const device3 = lineGraphData3.length
  //       ? [lineGraphData3[lineGraphData3.length - 1]]
  //       : [];
  //     const device4 = lineGraphData4.length
  //       ? [lineGraphData4[lineGraphData4.length - 1]]
  //       : [];

  //     const nonEmptyArrays = [device1, device2, device3, device4].filter(
  //       (arr) => arr.length > 0
  //     );
  //     const sum = nonEmptyArrays.reduce(
  //       (acc, arr) => acc + (parseFloat(arr[0]?.tagValue) || 0),
  //       0
  //     ); // Safely access tagValue

  //     // Calculate the average
  //     const average =
  //       nonEmptyArrays.length > 0 ? sum / nonEmptyArrays.length : 0;

  //     // Format the average to two decimal places using toFixed(2)
  //     const formattedAverage = average;

  //     // setAverageValue((prev) => [...prev, formattedAverage]);
  //     setAverageValue((prev) => {
  //       // Create a shallow copy of the previous state
  //       const indexCopy = [...prev, formattedAverage];
  //       //==========================new add code==============================
  //       if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
  //         indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
  //       }
  
  //       return indexCopy
  //     });
  //   }
  // }, [lineGraphData2, lineGraphData3, lineGraphData4,averageValue]);

  useMemo(() => {
    if (vmsTagSelect.length == 1 && lineGraphData1.length >= 2) {
      const lastValue = parseFloat(
        lineGraphData1[lineGraphData1.length - 1].tagValue
      );
      const secondLastValue = parseFloat(
        lineGraphData1[lineGraphData1.length - 2].tagValue
      );
      const chartLineDataAverage = (lastValue + secondLastValue) / 2;
      // setAverageValue((prev) => [...prev, chartLineDataAverage]);
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev, chartLineDataAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }
  
        return indexCopy
      });
    }
  }, [lineGraphData1]);

  useMemo(() => {
    if (
      vmsTagSelect.length == 2 && lineGraphData1.length != 0 &&
      lineGraphData1.length == lineGraphData2.length
    ) {
      console.log(
        "chartLineDatachartLineData",
        lineGraphData1.length,
        lineGraphData2.length
      );

      const device1 = lineGraphData1.length
        ? [lineGraphData1[lineGraphData1.length - 1]]
        : [];
      const device2 = lineGraphData2.length
        ? [lineGraphData2[lineGraphData2.length - 1]]
        : [];
      // const device3 = lineGraphData3.length ? [lineGraphData3[lineGraphData3.length - 1]] : [];
      // const device4 = lineGraphData4.length ? [lineGraphData4[lineGraphData4.length - 1]] : [];

      const nonEmptyArrays = [device1, device2].filter((arr) => arr.length > 0);
      const sum = nonEmptyArrays.reduce(
        (acc, arr) => acc + (parseFloat(arr[0]?.tagValue) || 0),
        0
      ); // Safely access tagValue

      // Calculate the average
      const average =
        nonEmptyArrays.length > 0 ? sum / nonEmptyArrays.length : 0;

      // Format the average to two decimal places using toFixed(2)
      const formattedAverage = average;
      console.log("formattedAverage",formattedAverage)

      // setAverageValue((prev) => [...prev, formattedAverage]);
      if(lineGraphData1.length > 2){

      }
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev, formattedAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }
  
        return indexCopy
      });
    }
  }, [lineGraphData2, selectUnit]);

  useMemo(() => {
    if (
      vmsTagSelect.length == 3 && lineGraphData1.length != 0 &&
      lineGraphData1.length == lineGraphData2.length &&
      lineGraphData2.length == lineGraphData3.length
    ) {
      const device1 = lineGraphData1.length
        ? [lineGraphData1[lineGraphData1.length - 1]]
        : [];
      const device2 = lineGraphData2.length
        ? [lineGraphData2[lineGraphData2.length - 1]]
        : [];
      const device3 = lineGraphData3.length
        ? [lineGraphData3[lineGraphData3.length - 1]]
        : [];
      // const device4 = lineGraphData4.length ? [lineGraphData4[lineGraphData4.length - 1]] : [];

      const nonEmptyArrays = [device1, device2, device3].filter(
        (arr) => arr.length > 0
      );
      const sum = nonEmptyArrays.reduce(
        (acc, arr) => acc + (parseFloat(arr[0]?.tagValue) || 0),
        0
      ); // Safely access tagValue

      // Calculate the average
      const average =
        nonEmptyArrays.length > 0 ? sum / nonEmptyArrays.length : 0;

      // Format the average to two decimal places using toFixed(2)
      const formattedAverage = average;

      // setAverageValue((prev) => [...prev, formattedAverage]);
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev,formattedAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }
  
        return indexCopy
      });
    }
  }, [lineGraphData2, lineGraphData3, selectUnit]);

  useMemo(() => {
    if (
      vmsTagSelect.length == 4 && lineGraphData1.length != 0 &&
      lineGraphData1.length == lineGraphData2.length &&
      lineGraphData2.length == lineGraphData3.length &&
      lineGraphData3.length == lineGraphData4.length
    ) {
      const device1 = lineGraphData1.length
        ? [lineGraphData1[lineGraphData1.length - 1]]
        : [];
      const device2 = lineGraphData2.length
        ? [lineGraphData2[lineGraphData2.length - 1]]
        : [];
      const device3 = lineGraphData3.length
        ? [lineGraphData3[lineGraphData3.length - 1]]
        : [];
      const device4 = lineGraphData4.length
        ? [lineGraphData4[lineGraphData4.length - 1]]
        : [];

      const nonEmptyArrays = [device1, device2, device3, device4].filter(
        (arr) => arr.length > 0
      );
      const sum = nonEmptyArrays.reduce(
        (acc, arr) => acc + (parseFloat(arr[0]?.tagValue) || 0),
        0
      ); // Safely access tagValue

      // Calculate the average
      const average =
        nonEmptyArrays.length > 0 ? sum / nonEmptyArrays.length : 0;

      // Format the average to two decimal places using toFixed(2)
      const formattedAverage = average;

      // setAverageValue((prev) => [...prev, formattedAverage]);
      setAverageValue((prev) => {
        // Create a shallow copy of the previous state
        const indexCopy = [...prev,formattedAverage];
        //==========================new add code==============================
        if (indexCopy.length >= process.env.REACT_APP_GRAPH_LENGTH) {
          indexCopy.shift(); // Remove oldest entry if exceeds MAX_LENGTH
        }
  
        return indexCopy
      });
    }
  }, [lineGraphData2, lineGraphData3, lineGraphData4,selectUnit]);

  // useEffect(() => {
  //   const timeStringToMinutes = (timeStr) => {
  //     const [hours, minutes] = timeStr?.split(":").map(Number);
  //     return hours * 60 + minutes;
  //   };

  //   const getTagTime = () => {
  //     return new Date().toLocaleTimeString("en-US", {
  //       timeZone: "Asia/Kolkata",
  //       hour12: false,
  //     });
  //   };

  //   setCombineChartData((prev) => {
  //     const zeroIndx = [...prev];
  //     const innerArrayCopy = [...zeroIndx[0]];
  //     const innerArrayCopy1 = [...zeroIndx[1]];
  //     const innerArrayCopy2 = [...zeroIndx[2]];
  //     const innerArrayCopy3 = [...zeroIndx[3]];

  //     const currentTimeStr = getTagTime();
  //     const currentTimeInMinutes = timeStringToMinutes(currentTimeStr);

  //     const filteredData = innerArrayCopy.filter((item) => {
  //       const itemTimeInMinutes = timeStringToMinutes(item.tagTime);
  //       const timeDifferenceInMinutes =
  //         currentTimeInMinutes - itemTimeInMinutes;
  //       return Math.abs(timeDifferenceInMinutes) !== 15;
  //     });

  //     const filteredData1 = innerArrayCopy1.filter((item) => {
  //       const itemTimeInMinutes = timeStringToMinutes(item.tagTime);
  //       const timeDifferenceInMinutes =
  //         currentTimeInMinutes - itemTimeInMinutes;
  //       return Math.abs(timeDifferenceInMinutes) !== 15;
  //     });

  //     const filteredData2 = innerArrayCopy2.filter((item) => {
  //       const itemTimeInMinutes = timeStringToMinutes(item.tagTime);
  //       const timeDifferenceInMinutes =
  //         currentTimeInMinutes - itemTimeInMinutes;
  //       return Math.abs(timeDifferenceInMinutes) !== 15;
  //     });

  //     const filteredData3 = innerArrayCopy3.filter((item) => {
  //       const itemTimeInMinutes = timeStringToMinutes(item.tagTime);
  //       const timeDifferenceInMinutes =
  //         currentTimeInMinutes - itemTimeInMinutes;
  //       return Math.abs(timeDifferenceInMinutes) !== 15;
  //     });

  //     zeroIndx[0] = filteredData;
  //     zeroIndx[1] = filteredData1;
  //     zeroIndx[2] = filteredData2;
  //     zeroIndx[3] = filteredData3;

  //     return zeroIndx;
  //   });

  //   setAverageValue((prev) => {
  //     const result = prev.slice(-combineChartData[0].length);
  //     return result;
  //   });
  // }, [lineGraphData1,averageValue]);

  // change the graph model
  const handleChangeGraphDetail = () => {
    setOverViewMachine((prev) => !prev);
  };

  // select tag
  const handleSelectTagData = async () => {
    try {
      const paramReq = {
        tag_name: unitySelectTag,
      };
      const resp = await selectTagDataApi(paramReq);
      if (resp.status == 200 || resp.status == 201) {
        setSelectTagOverviewData(resp.data.payload);
        const obj = {
          tagName: unitySelectTag,
          prevValue: 0,
          curValue: 0,
        };
        setSelectMqttTagPreviousvalue(obj);
        setSelectTagHiValue(
          parseFloat(resp.data.payload.tag_config_data[0].setpoint_hi)
        );
        setSelectTagHhValue(
          parseFloat(resp.data.payload.tag_config_data[0].setpoint_hh)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (unitySelectTag) {
      handleSelectTagData();
    }
  }, [unitySelectTag]);

  const choiceTypeApi = async () => {
    try {
      const resp = await choiceType();
      if (resp.status == 200 || resp.status == 201) {
        setChoiceData(resp.data.payload.device_life_choices);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (unitySelectTag) {
      choiceTypeApi();
    }
  }, [unitySelectTag]);


  // add overView Suggestion
  const addOverviewTagSuggestion = async () => {
    if (choiceSelectId == "") {
      notifyError("Please Select Choice");
      return true;
    }
    const formattedData = selectTagRealTimeData.map((item) => ({
      date_time: item.tagDateAndTimeFormat,
      tag_value: parseFloat(item.tagValue),
    }));

    if (!formattedData.length) {
      notifyError("Not Get Select TagList Data.");
      return true;
    }

    try {
      const paramReq = {
        device: param.deviceId,
        tag_name: unitySelectTag,
        device_life: choiceSelectId,
        reason: reasonData,
        data: formattedData,
      };
      const resp = await addOverviewTagSuggestionApi(paramReq);
      if (resp.status == 200 || resp.status == 201) {
        notify("Successfully Send Message");
        setChoiceSelectId("");
        setReasonData("");
        getOverviewTagSuggestion();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getOverviewTagSuggestion = async () => {
    try {
      const paramReq = {
        device: param.deviceId,
        tag_name: unitySelectTag,
      };
      const resp = await getOverviewTagSuggestionApi(paramReq);
      if (resp.status == 200 || resp.status == 201) {
        setGetOverviewSuggestionMessage(resp.data.payload.suggestion);
      }
    } catch (error) {
      setGetOverviewSuggestionMessage([]);
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (unitySelectTag) {
      getOverviewTagSuggestion();
    }
  }, [unitySelectTag]);

  //Number of times Limit crossed
  useEffect(() => {
    if (selectTagRealTimeData.length && selectTagHiValue && selectTagHhValue) {
      const currentValue = parseFloat(
        selectTagRealTimeData[selectTagRealTimeData.length - 1].tagValue
      );

      if (currentValue > selectTagHiValue && !thresoldValue1) {
        setThresoldValue1(true);
        setCrossingCount((prev) => prev + 1);
      } else if (currentValue < selectTagHiValue) {
        setThresoldValue1(false);
      }

      if (currentValue > selectTagHhValue && !thresoldValue2) {
        setThresoldValue2(true);
        setCrossingCount((prev) => prev + 1);
      } else if (currentValue < selectTagHhValue) {
        setThresoldValue2(false);
      }
    }
  }, [selectTagRealTimeData]);
  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;

  const overViewAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Overview"
        )
      : null
    : null;

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };

  const selectUnitRef = useRef(null);

  // Function to handle opening of Popper and set anchor element
  // const handleSetingModel = (event) => {
  //   console.log("openMachine....", openMachine);
  //   setAnchorEl(selectUnitRef.current);
  //   setOpenMachine(!openMachine);
  // };
  const handleSetingModel = (event) => {
    setOpenMachine((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };

  const handleOutsideClickUnit = (event) => {
    if (anchorEl && !anchorEl.contains(event.target)) {
      setOpenMachine(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClickUnit);
    return () => {
      document.removeEventListener("click", handleOutsideClickUnit);
    };
  }, [anchorEl]);

  return (
    <>
      {vmsTagSelect.length &&
      vmsTagSelect.filter((f) => f.deviceId == param.deviceId).length ? (
        <OverViewGraphData
          selectTagData={vmsTagSelect.filter(
            (f) => f.deviceId == param.deviceId
          )}
          setTagMinMaxValue={setTagMinMaxValue}
          vmsTagSelect={vmsTagSelect}
        />
      ) : null}
      {deviceData && (
        <OverViewmqttData
          deviceData={deviceData}
          unitySelectTag={unitySelectTag}
          selectTagData={vmsTagSelect.filter(
            (f) => f.deviceId == param.deviceId
          )}
          setTagVMSData={setTagVMSData}
          setCombineChartData={setCombineChartData}
          setLineGraphData1={setLineGraphData1}
          setLineGraphData2={setLineGraphData2}
          setLineGraphData3={setLineGraphData3}
          setLineGraphData4={setLineGraphData4}
          setMqttTagPreviousvalue={setMqttTagPreviousvalue}
          tagListDataForDevice={tagListDataForDevice}
          setSendRealTimeDataModel={setSendRealTimeDataModel}
        />
      )}

      {/* select tag handle real time data */}
      {unitySelectTag && deviceData && (
        <OverViewSelectTagData
          unitySelectTag={unitySelectTag}
          deviceData={deviceData}
          setSelectTagRealTimeData={setSelectTagRealTimeData}
          setSelectMqttTagPreviousvalue={setSelectMqttTagPreviousvalue}
          tagListDataForDevice={tagListDataForDevice}
        />
      )}
      <div className="page-wraper">
        <div className="page-back-btn">
          <ArrowBackIcon onClick={() => handleBackNavigate()} />
          <span onClick={() => handleBackNavigate()}>
            {deviceData ? deviceData.device_name : ""} ~ OverView
          </span>
        </div>
        <div className="pt-3">
          <div className="d-block">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={5} xl={5}>
                <Box className="machine-overView">
                  <div
                    className="submachine-img"
                    style={{ height: "88%" }}
                    // onClick={() => handleChangeGraphDetail()}
                  >
                    {deviceUID ? (
                      <VmsARMachine
                        deviceUID={deviceUID}
                        navigateRoute={navigateRoute}
                        setUnitySelectTag={setUnitySelectTag}
                        fullScreenModel={fullScreenModel}
                        setFullScreenModel={setFullScreenModel}
                        sendRealTimeDataModel={sendRealTimeDataModel}
                      />
                    ) : null}
                    {/* {deviceUID && !modelFound && modelUrlData ? <VmsARMachine deviceUID={deviceUID} navigateRoute={navigateRoute} modelUrlData={modelUrlData} setUnitySelectTag={setUnitySelectTag} /> : null}
                    <div className="device-image-modal">
                      {modelFound && <img src={images.noDataFoundImg} className="notFoundDataImgVmsOverView" />}
                    </div> */}
                  </div>
                  <div className="d-flex justify-content-end gap-3 pt-4">
                    <img
                      src={images.crossIcon}
                      style={{ cursor: "pointer" }}
                      onClick={handleFullScrennModel}
                    />
                    <img src={images.plusIcon} />
                    <img src={images.MinusIcon} />
                  </div>
                </Box>
              </Grid>
              {unitySelectTag == "" ? (
                <Grid item xs={12} sm={12} md={8} lg={7} xl={7}>
                  <Box className="machine-overView1">
                    {(overViewAccess?.is_viewer && overViewAccess?.is_editor) ||
                    (!overViewAccess?.is_viewer && overViewAccess?.is_editor) ||
                    (overViewAccess?.is_viewer &&
                      !overViewAccess?.is_editor) ? (
                      <div className="d-flex justify-content-end">
                        <p className="average-toggle-vms">Average</p>
                        <Switch
                          checked={average}
                          onChange={(e) => setAverage(e.target.checked)}
                          className="average-toggle-switch-vms"
                          sx={{
                            "& .MuiSwitch-thumb": {
                              bgcolor: "#828282",
                            },
                            "& .MuiSwitch-track": {
                              bgcolor: "#D9D9E5 !important",
                            },
                            "& .Mui-checked": {
                              "& .MuiSwitch-thumb": {
                                bgcolor: "#50C878",
                              },
                              "& .MuiSwitch-track": {
                                bgcolor: "#CEE6E1 !important",
                              },
                            },
                          }}
                        />
                        <SettingsIcon
                          style={{
                            cursor: "pointer",
                            fontSize: "30px",
                            marginRight: "11px",
                          }}
                          onClick={() => handleOpenModal()}
                        />
                      </div>
                    ) : (
                      <div
                        className="d-flex justify-content-end"
                        onClick={handleTostMsg}
                      >
                        <p className="average-toggle-vms">Average</p>
                        <Switch
                          checked={average}
                          onChange={handleTostMsg}
                          // onChange={(e) => setAverage(e.target.checked)}
                          className="average-toggle-switch-vms"
                          sx={{
                            "& .MuiSwitch-thumb": {
                              bgcolor: "#828282",
                            },
                            "& .MuiSwitch-track": {
                              bgcolor: "#D9D9E5 !important",
                            },
                            "& .Mui-checked": {
                              "& .MuiSwitch-thumb": {
                                bgcolor: "#50C878",
                              },
                              "& .MuiSwitch-track": {
                                bgcolor: "#CEE6E1 !important",
                              },
                            },
                          }}
                        />
                        <SettingsIcon
                          style={{
                            cursor: "pointer",
                            fontSize: "30px",
                            marginRight: "11px",
                          }}
                        />
                      </div>
                    )}
                    <div style={{ height: "400px", width: "100%" }}>
                      <HighchartsGraph
                        combineChartData={combineChartData}
                        selectTagData={vmsTagSelect.filter(
                          (f) => f.deviceId == param.deviceId
                        )}
                        lineGraphData={lineGraphData1}
                        selectUnit={
                          vmsTagSelect.length
                            ? vmsTagSelect.filter(
                                (f) => f.deviceId == param.deviceId
                              ).length
                              ? vmsTagSelect.filter(
                                  (f) => f.deviceId == param.deviceId
                                )[0]["tagUnit"]
                              : ""
                            : ""
                        }
                        average={average}
                        averageValue={averageValue}
                      />
                    </div>
                    <div style={{ height: "234px", overflow: "auto" }}>
                      <Table className="source-table overview-source-table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="table-width">
                              {selectUnit != "none" ? selectUnit : ""}
                            </TableCell>

                            {vmsTagSelect.length
                              ? vmsTagSelect.filter(
                                  (f) => f.deviceId == param.deviceId
                                ).length
                                ? vmsTagSelect
                                    .filter((f) => f.deviceId == param.deviceId)
                                    .map((e, index) => {
                                      const findStatus = tagStatus.length
                                        ? tagStatus.find(
                                            (f) => f.tagName == e.device_tag
                                          )
                                        : 0;
                                      return (
                                        <TableCell>
                                          <div className="d-flex justify-content-between">
                                            <div>{e.tagName}</div>
                                            <div>
                                              {findStatus ? (
                                                findStatus.statusValue.toFixed(
                                                  2
                                                ) < 0 ? (
                                                  <SouthIcon
                                                    style={{ color: e.color }}
                                                  />
                                                ) : (
                                                  <NorthIcon
                                                    style={{ color: e.color }}
                                                  />
                                                )
                                              ) : (
                                                <NorthIcon
                                                  style={{ color: e.color }}
                                                />
                                              )}
                                              {findStatus
                                                ? findStatus.statusValue.toFixed(
                                                    2
                                                  )
                                                : 0.0}
                                              %
                                            </div>
                                          </div>
                                        </TableCell>
                                      );
                                    })
                                : []
                              : []}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ fontWeight: "500" }}>
                              Real Time
                            </TableCell>
                            {vmsTagSelect.length
                              ? vmsTagSelect.filter(
                                  (f) => f.deviceId == param.deviceId
                                ).length
                                ? vmsTagSelect
                                    .filter((f) => f.deviceId == param.deviceId)
                                    .map((row) => {
                                      const findTagName = tagVMSData.length
                                        ? tagVMSData.find(
                                            (f) =>
                                              f.device_tag == row.device_tag
                                          )
                                        : "";
                                      return (
                                        <TableCell>
                                          {findTagName
                                            ? findTagName.tagValue
                                            : "-"}
                                        </TableCell>
                                      );
                                    })
                                : null
                              : null}
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: "500" }}>
                              All Time Min.
                            </TableCell>
                            {vmsTagSelect.length
                              ? vmsTagSelect.filter(
                                  (f) => f.deviceId == param.deviceId
                                ).length
                                ? vmsTagSelect
                                    .filter((f) => f.deviceId == param.deviceId)
                                    .map((row) => {
                                      const findMinValue = tagMinMaxValue.length
                                        ? tagMinMaxValue.find(
                                            (f) => row.tag_id == f.tag_id
                                          )
                                        : 0;
                                      return (
                                        <TableCell>
                                          {findMinValue
                                            ? findMinValue.all_time_min_value
                                            : "-"}
                                        </TableCell>
                                      );
                                    })
                                : null
                              : null}
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ fontWeight: "500" }}>
                              All Time Max.
                            </TableCell>
                            {vmsTagSelect.length
                              ? vmsTagSelect.filter(
                                  (f) => f.deviceId == param.deviceId
                                ).length
                                ? vmsTagSelect
                                    .filter((f) => f.deviceId == param.deviceId)
                                    .map((row) => {
                                      const findMaxValue = tagMinMaxValue.length
                                        ? tagMinMaxValue.find(
                                            (f) => row.tag_id == f.tag_id
                                          )
                                        : "";
                                      return (
                                        <TableCell>
                                          {findMaxValue
                                            ? findMaxValue.all_time_max_value
                                            : "-"}
                                        </TableCell>
                                      );
                                    })
                                : null
                              : null}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </Box>
                </Grid>
              ) : null}
              {unitySelectTag ? (
                <Grid item xs={12} sm={12} md={8} lg={7} xl={7}>
                  <Box className="machine-overView1">
                    <div>
                      <SelectedTagGraphData
                        unitySelectTag={unitySelectTag}
                        selectTagRealTimeData={selectTagRealTimeData}
                        selectTagHH={selectTagHhValue}
                        selectTagHI={selectTagHiValue}
                      />
                    </div>
                    <div className="unity-table-modal">
                      <div>
                        <Table className="overViewMachinetable">
                          <TableBody>
                            <TableRow>
                              <TableCell>All time high</TableCell>
                              <TableCell style={{ float: "right" }}>
                                <b>
                                  {selectTagOverviewData
                                    ? selectTagOverviewData.max_tag_value
                                    : "-"}
                                </b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Rate of Increase</TableCell>
                              <TableCell style={{ float: "right" }}>
                                <b>{selectTagRate}%</b>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Number of times Limit crossed
                              </TableCell>
                              <TableCell style={{ float: "right" }}>
                                <b>{crossingCount}</b>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                      <div className="d-flex justify-content-between pt-3">
                        <div>
                          <h6>
                            <b>What do you think?</b>
                          </h6>
                        </div>

                        <div>
                          {choiceData.map((row) => {
                            const buttonClass =
                              row.name === "Healthy"
                                ? `healthy-btn ${
                                    row.id === choiceSelectId
                                      ? "selected-priority-btn"
                                      : ""
                                  }`
                                : `unhealthy-btn ${
                                    row.id === choiceSelectId
                                      ? "selected-unhealthy-btn"
                                      : ""
                                  }`;

                            return (overViewAccess?.is_viewer &&
                              overViewAccess?.is_editor) ||
                              (!overViewAccess?.is_viewer &&
                                overViewAccess?.is_editor) ||
                              (overViewAccess?.is_viewer &&
                                !overViewAccess?.is_editor) ? (
                              <button
                                key={row.id}
                                className={buttonClass}
                                onClick={() => setChoiceSelectId(row.id)}
                              >
                                {row.id === choiceSelectId && (
                                  <CheckIcon className="check-Icon" />
                                )}
                                {row.name}
                              </button>
                            ) : (
                              <button
                                key={row.id}
                                className={buttonClass}
                                onClick={handleTostMsg}
                              >
                                {row.id === choiceSelectId && (
                                  <CheckIcon className="check-Icon" />
                                )}
                                {row.name}
                              </button>
                            );
                          })}
                        </div>
                      </div>
                      <div className="d-flex pt-3">
                        <div className="d-flex" style={{ width: "80%" }}>
                          <h6 className="mt-2">Reason :</h6>
                          <input
                            type="text"
                            value={reasonData}
                            onChange={(e) => setReasonData(e.target.value)}
                            className="reason-textfield"
                          />
                        </div>

                        <div style={{ width: "20%" }}>
                          {(overViewAccess?.is_viewer &&
                            overViewAccess?.is_editor) ||
                          (!overViewAccess?.is_viewer &&
                            overViewAccess?.is_editor) ||
                          (overViewAccess?.is_viewer &&
                            !overViewAccess?.is_editor) ? (
                            <button
                              className="priority-submit-btn"
                              onClick={addOverviewTagSuggestion}
                            >
                              Submit
                            </button>
                          ) : (
                            <button
                              className="priority-submit-btn"
                              onClick={handleTostMsg}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </div>
                      {/* <div className="d-flex pt-3 machine-msg">
                      <div style={{ marginRight: "20px" }}>
                        <h6>Suggestion / Message : </h6>
                      </div>
                      <div style={{ overflowY: 'auto', maxHeight: '5em' }}>
                        {getOverviewSuggestionMessage.length ? getOverviewSuggestionMessage.map((row, index) => (
                          <h6 key={index}>{index + 1}.{row.message}</h6>
                        )) : null}
                      </div>

                    </div> */}
                    </div>
                  </Box>
                </Grid>
              ) : null}
              {overViewModal && (
                <>
                  <div className="overviewModal-box" ref={modalRef}>
                    <CancelIcon
                      className="closeOverviewModal"
                      onClick={() => {
                        closeModal();
                      }}
                    />
                    <Box>
                      <Box className="consumer-text-disc">
                        <h6 className="Title">Title</h6>
                        <h6>Data Trends</h6>
                      </Box>
                      <Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "13px 8px",
                            borderRadius: "4px",
                            width: "100%",
                            background: "#f5f5f5",
                            cursor: "pointer",
                            marginTop: "4px",
                          }}
                          ref={selectUnitRef}
                          onClick={handleSetingModel}
                        >
                          Select Unit <ArrowDropDownIcon />
                        </div>

                        <Popper
                          open={openMachine}
                          anchorEl={anchorEl}
                          placement="bottom-start"
                          style={{
                            width:
                              selectUnitRef && selectUnitRef.current
                                ? selectUnitRef.current.offsetWidth
                                : "auto", // Dynamically set width based on the Select Unit div
                            maxHeight: "300px",
                            overflow: "auto",
                          }}
                        >
                          <Paper>
                            {tagUnitData.length ? (
                              tagUnitData.map((row) => (
                                <MenuItem
                                  className="vms-coc-menuItem"
                                  onClick={handlePropagation}
                                >
                                  {row.tag_unit}
                                  <Switch
                                    style={{ float: "right" }}
                                    checked={selectUnit === row.tag_unit}
                                    disabled={
                                      selectUnit !== "" &&
                                      selectUnit !== row.tag_unit
                                    }
                                    onClick={(event) => {
                                      if (
                                        vmsTagSelect.length
                                          ? !vmsTagSelect.some(
                                              (f) =>
                                                f.deviceId === param.deviceId
                                            )
                                          : true
                                      ) {
                                        setSelectUnit(
                                          event.target.checked
                                            ? row.tag_unit
                                            : ""
                                        );
                                      }
                                    }}
                                  />
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem
                                className="vms-coc-menuItem"
                                onClick={handlePropagation}
                              >
                                No Units Found
                              </MenuItem>
                            )}
                          </Paper>
                        </Popper>
                      </Box>
                      <TextField
                        variant="outlined"
                        className="search-textfield"
                        placeholder="Search Device here"
                        value={searchTagName}
                        onChange={(e) => setSearchTagName(e.target.value)}
                        InputProps={{
                          startAdornment: <SearchIcon />,
                          inputProps: {
                            style: { padding: "9px" },
                          },
                        }}
                      />
                      <div
                        className="furnace-grid"
                        style={{ height: "437px", overflow: "auto" }}
                        // onClick={(event) => handleSetingModel(event, "close")}
                      >
                        <Box className="Grid-text mt-2">
                          {!isShimmerUnitlist ? (
                            tagData.length ? (
                              tagData
                                .filter((e) =>
                                  e.tag_name
                                    ?.toLowerCase()
                                    ?.includes(searchTagName.toLowerCase())
                                )
                                .map((row, index) => {
                                  return (
                                    <Grid item xs={12}>
                                      <Grid
                                        container
                                        alignItems="center"
                                        style={{
                                          background: "#efeffd",
                                          width: "100%",
                                          marginBottom: "7px",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={8}
                                          style={{
                                            padding: "7px 12px",
                                            borderLeft: `4px solid ${
                                              switchColors[
                                                index % switchColors.length
                                              ]
                                            }`,
                                          }}
                                        >
                                          <h6 className="mt-2">
                                            {row.tag_name}
                                          </h6>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={4}
                                          textAlign="right"
                                          className="grid-switch-unit"
                                        >
                                          <Switch
                                            style={{ float: "right" }}
                                            onClick={(event) =>
                                              handleSelectTagName(
                                                param.deviceId,
                                                index,
                                                event,
                                                row,
                                                selectUnit
                                              )
                                            }
                                            name={switchColors[index]}
                                            disabled={isCheckboxDisabled(
                                              row.tag_name
                                            )}
                                            checked={
                                              vmsTagSelect.find(
                                                (f) => f.tagName == row.tag_name
                                              )
                                                ? true
                                                : false
                                            }
                                            sx={{
                                              "& .MuiSwitch-thumb": {
                                                bgcolor: "#828282",
                                              },
                                              "& .MuiSwitch-track": {
                                                bgcolor: "#D9D9E5 !important",
                                              },
                                              "& .Mui-checked": {
                                                "& .MuiSwitch-thumb": {
                                                  bgcolor:
                                                    switchColors[
                                                      index %
                                                        switchColors.length
                                                    ],
                                                },
                                                "& .MuiSwitch-track": {
                                                  bgcolor: "#E2D9F2 !important",
                                                },
                                              },
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  );
                                })
                            ) : (
                              []
                            )
                          ) : (
                            <DeviceListShimmer itemCount={7} />
                          )}
                        </Box>
                      </div>

                      <div className="mt-3">
                        <button
                          className="export-btn"
                          onClick={handleExportClick}
                        >
                          Export PredHik Data
                        </button>
                      </div>
                    </Box>
                  </div>
                </>
              )}
            </Grid>
          </div>
          {/* <div className={`${overViewMachine ? "d-block" : "d-none"}`}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                <Box className="machine-overView">
                  <div className="submachine-img">
                    {deviceUID && !modelFound ? <VmsARMachine deviceUID={deviceUID} navigateRoute={navigateRoute} /> : null}
                    <div className="device-image-modal">
                      {modelFound && <img src={images.noDataFoundImg} className="notFoundDataImgVmsOverView" />}
                    </div>
                  </div>
                  <div className="d-flex justify-content-end gap-3 pt-4">
                    <img src={images.crossIcon} />
                    <img src={images.plusIcon} />
                    <img src={images.MinusIcon} />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                <Box className="machine-overView1">
                  <div>
                    <OverViewLineChart />
                  </div>
                  <div>
                    <Table className="overViewMachinetable">
                      <TableBody>
                        <TableRow>
                          <TableCell>All time high</TableCell>
                          <TableCell style={{ float: "right" }}>
                            <b>100</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Rate of Increase</TableCell>
                          <TableCell style={{ float: "right" }}>
                            <b>20%</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Number of times Limit crossed</TableCell>
                          <TableCell style={{ float: "right" }}>
                            <b>3</b>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <div className="d-flex justify-content-between pt-3">
                    <div>
                      <h6>
                        <b>What do you think ?</b>
                      </h6>
                    </div>
                    <div>
                      <button className="healthy-btn">Healthy</button>
                      <button className="unhealthy-btn">UnHealthy</button>
                    </div>
                  </div>
                  <div className="d-flex pt-3">
                    <h6>Reason :</h6>
                    <input type="text" className="reason-textfield" />
                  </div>
                  <div className="d-flex pt-3 machine-msg">
                    <div style={{ marginRight: "20px" }}>
                      <h6>Suggestion / Message : </h6>
                    </div>
                    <div>
                      <h6>Check Machine Foundations</h6>
                      <h6>Check Winding Temperature </h6>
                      <h6>Shaft Misadjustment</h6>
                    </div>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default VmsOverView;
