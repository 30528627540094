import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import TwoStepVerificationOTP from "./TwoStepVerificationOTP";
import MfaVerification from "./MfaVerification";
import SignIn from "./SignIn";

const Login = () => {

    const navigate = useNavigate()
    const [currentState, setCurrentState] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [mfaUserId, setMfaUserId] = useState("");
    const [email, setEmail] = useState("");

    const onNextClick = (nextState) => {
        setCurrentState(nextState);
    };

    const currentComponent = () => {
        switch (currentState) {
            case "verify2Step":
                return (
                    <TwoStepVerificationOTP
                        mobileNo={mobileNo}
                        // onNextClick={() => onNextClick("changePassword")}
                        onCancelClick={() => onNextClick("")}
                       
                    />
                );
            case "verifyMFA":
                return (
                    <MfaVerification
                        email={email}
                        mfaUserId={mfaUserId}
                        // onNextClick={() => onNextClick("changePassword")}
                        onCancelClick={() => onNextClick("")}

                    />
                );

            default:
                return <SignIn
                    // email={email}
                    setEmail={setEmail}
                    setMfaUserId={setMfaUserId}
                    setMobileNo={setMobileNo}
                    onNextClick={(msg) => onNextClick(msg)}
                    // onCancelClick={() => onNextClick(navigate("/"))}
                />
        }
    };

    return (
        <>
            {currentComponent()}
        </>
    )
}

export default Login;