import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  CardActionArea,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Slider,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

// import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";

// import Box from '@mui/material/Box';
import "../SubscriptionManagement.css";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { ValidatorForm } from "react-material-ui-form-validator";
import { images } from "../../../../config";
import { customSmartTagPlan, getChoices, saveForLater } from "../service";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate, useParams } from "react-router-dom";
import {
  contactUsDetail,
  getCotationPlan,
  getPurchaseDevice,
} from "../../service";
import SubscriptionFooter from "../SubscriptionFooter";
import { useSelector } from "react-redux";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { ReviewsSharp } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";

const SmartTag = () => {
  const [value, setValue] = React.useState(0);
  const [contactModal, setContactModal] = useState(false);
  const [choiceId, setChoiceId] = useState("");
  const [choice, setChoice] = useState([]);
  const [smartTagDevice, setSmartTagDevice] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [smartTagDeviceDetails, setSmartTagDeviceDetails] = useState([]);
  const initiallValue = {
    first_name: "",
    last_name: "",
    company_name: "",
    phone_number: "",
    email_id: "",
    subscription: "",
  };
  const [addContactData, setContactData] = useState(initiallValue);
  const [plantId, setPlanId] = useState("");
  const [editingStates, setEditingStates] = useState(
    Array(smartTagDevice.length).fill(false)
  );
  const [inputValues, setInputValues] = useState("");
  const [clickCard, setClickedCard] = useState(false);

  console.log("inputValuesinputValues", inputValues);

  const handleEditClick = (index) => {
    const newEditingStates = [...editingStates];
    newEditingStates[index] = true;
    setEditingStates(newEditingStates);
  };

  // useEffect(() => {
  //   const initialValues = {};
  //   smartTagDevice.forEach((device, index) => {
  //     initialValues[index] = "";
  //   });
  //   setInputValues(initialValues);
  // }, [smartTagDevice]);

  function findIndexOrNextAboveValue(arr, value) {
    if (value === 0) return -1; // No valid comparison possible with value 0

    let foundIndex = -1;
    let foundValue = Infinity;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i].devices === value) {
        foundIndex = i;
        break;
      } else if (arr[i].devices > value && arr[i].devices < foundValue) {
        foundIndex = i;
        foundValue = arr[i].devices;
      } else if (arr[i].devices === value && arr[i].is_custom) {
        foundIndex = i;
        break; // No need to continue if we find a custom object with the same value
      }
    }
    // If no value greater than or equal to the given value is found, prioritize is_custom true object
    if (foundIndex === -1) {
      const customIndex = arr.findIndex((item) => item.is_custom);
      if (customIndex !== -1) {
        foundIndex = customIndex;
      }
    }

    return foundIndex;
  }

  // In your component:
  const index = findIndexOrNextAboveValue(smartTagDevice, value);

  const selectedCardDetails = index !== -1 ? smartTagDevice[index] : null;
  console.log("selectedCardDetailsselectedCardDetails", selectedCardDetails);
  useEffect(() => {
    if (selectedCardDetails && selectedCardDetails.is_custom) {
      handleSaveClick(value);
    }
  }, [value]);

  // useEffect(() => {
  //   if (selectedCardDetails && selectedCardDetails.is_custom) {
  //     if (!clickCard) {
  //       setEditingStatesForCustomItem(index, editingStates, setEditingStates);
  //     }

  //   }
  // }, [value])

  console.log("clickCardclickCardclickCard", clickCard);

  const handleCardClick = (index, id, device) => {
    // setSelectedCard(index);
    setPlanId(id);
    setValue(device);
    setClickedCard(true);
  };

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const { id, companyId } = useSelector((state) => state.userState);

  const displayValue = value <= 1500 ? value : "1500+";
  const navigate = useNavigate();

  const minValue = 0;
  const maxValue = 1500; // Adjust this as needed

  const maxLabel = 1500; // The maximum value for labels
  const step = 200; // The step size for labels

  const labels = Array.from(
    { length: maxLabel / step + 1 },
    (_, index) => index * step
  );

  const marks = labels.map((label) => ({
    value: label,
    label: label < maxLabel ? label.toString() : "1500+",
  }));

  const handledecrement = () => {
    if (value !== 0) {
      setValue(value - 1);
      setClickedCard(false);
    } else {
      setValue(0);
      setClickedCard(false);
    }
  };

  //Notification Error message
  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleChangeDevice = (event, newValue) => {
    setClickedCard(false);
    setValue(newValue);
  };
  const handleOpenContactCloseModel = () => {
    setContactModal(false);
  };

  const param = useParams();

  const handleChangeAddDetail = (e) => {
    setContactData({ ...addContactData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (choiceId) {
      handleSmarTagDevice();
    }
  }, [choiceId]);

  const handleSmarTagDevice = async () => {
    const params = {
      dashboard: param.id,
      payment_period: choiceId,
    };
    try {
      const resp = await getCotationPlan(params);
      if (
        resp.status == 200 ||
        (resp.status == 201 && resp.data.payload.length)
      ) {
        setSmartTagDevice(resp.data.payload);
      }
    } catch (error) {
      // console.log("handleSubmit", error);
      setSmartTagDevice([]);
    }
  };

  const handleSaveForLater = async () => {
    if (selectedCardDetails?.pack_price <= 0) {
      notifyError("Invalid amount! Minimum amount more than zero");
      return true;
    }
    try {
      const paramReq = {
        payment_period: choiceId,
        user_device_start: 0,
        user_device_end: selectedCardDetails ? selectedCardDetails.devices : 0,
        total_price: selectedCardDetails ? selectedCardDetails.pack_price : "",
        total_device: selectedCardDetails ? selectedCardDetails.devices : 0,
        dashboard: param.id,
        price_per_device: 0,
        is_active: false,
      };

      const resp = await saveForLater(paramReq);

      if (resp.status == 200) {
        navigate(`/payment/${param.title}/${param.id}`);
      }
    } catch (error) {
      console.error("Error saving for later:", error);
    }
  };
  useEffect(() => {
    handleChoice();
  }, []);

  const handleChoice = async () => {
    const resp = await getChoices();
    setChoice(resp.data.payload.term_choices);
    setChoiceId(
      resp.data.payload.term_choices.find((f) => f.name == "Monthly").id
    );
  };

  useEffect(() => {
    handlePurchaseDevices();
  }, []);

  const handlePurchaseDevices = async () => {
    const params = {
      dashboard: param.id,
    };
    try {
      const resp = await getPurchaseDevice(params);
      if (resp.status == 200 || resp.status == 201) {
        setSmartTagDeviceDetails(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handleSubmit = async () => {
    if (addContactData.first_name === "") {
      notify("Please Enter First Name.");
      return;
    }
    if (addContactData.last_name === "") {
      notify("Please Enter Last Name.");
      return;
    }
    if (addContactData.company_name === "") {
      notify("Please Enter Company Name.");
      return;
    }
    if (addContactData.phone_number === "") {
      notify("Please Enter Phone Number.");
      return;
    }
    if (addContactData.email_id === "") {
      notify("Please Enter Email Id.");
      return;
    }
    if (addContactData.subscription === "") {
      notify("Please Enter Subscription.");
      return;
    }

    const params = {
      company: companyId,
      first_name: addContactData.first_name,
      last_name: addContactData.last_name,
      company_name: addContactData.company_name,
      mobile_number: addContactData.phone_number,
      email: addContactData.email_id,
      detail: addContactData.subscription,
      dashboard: param.id,
      user: id,
    };
    try {
      const resp = await contactUsDetail(params);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message);
        setContactModal(false);
        setContactData([]);
      }
    } catch (error) {
      notifyError(error.response.data.message);
      console.log("handleSubmit", error);
    }
  };

  const handleInputChange = (event, index) => {
    const newValue = event.target.value;

    // Regular expression for valid input:
    const validInputRegex = /^[1-9]\d{0,7}$/; // Allows 1-digit numbers (no leading zero) or 2-8 digits

    if (newValue === "" || validInputRegex.test(newValue)) {
      setInputValues(newValue);
    } else {
      console.log(
        "Invalid input. Please enter a positive whole number greater than zero (no decimals, special characters, or leading zero)."
      );
    }
  };

  const handleSaveClick = async (value) => {
    const params = {
      plan_id: selectedCardDetails?.id,
      tags: inputValues ? inputValues : value,
    };
    try {
      const resp = await customSmartTagPlan(params);
      if (resp.status == 200 || resp.status == 201) {
        setEditingStates(Array(smartTagDevice.length).fill(false));
        setSmartTagDevice((prev) => {
          const copyPrev = [...prev];
          return copyPrev.map((row) => {
            if (row.is_custom) {
              return resp.data.payload[0];
            }
            return row;
          });
        });
        setInputValues("");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };
  const handleIncrement = () => {
    setClickedCard(false);
    setValue(value + 1);
  };

  return (
    <>
      <div className="containerWrap">
        
        <Container maxWidth="xxl">
          <div className="d-flex justify-content-between pt-4">
            <div>
              <div
                className="page-back-btn"
                onClick={() => navigate(`/subscriptionManagement`)}
              >
                <ArrowBackIcon />
                <h3 className="mt-2">
                  <b>SmarTag</b>
                </h3>
              </div>
            </div>
            <div className="mt-3 d-flex">
              <h5>
                <b>Preferred Billing Cycle:</b>
              </h5>
              <h6 style={{ marginLeft: "20px", marginTop: "4px" }}>Monthly</h6>
              <div style={{ marginTop: "-5px" }}>
                <Switch
                  className="average-toggle-switch"
                  checked={
                    choice.length
                      ? choice.find((f) => f.id == choiceId).name != "Monthly"
                        ? true
                        : false
                      : false
                  }
                  onClick={(e) => {
                    if (e.target.checked) {
                      setChoiceId(
                        choice.length
                          ? choice.find((f) => f.name == "Yearly").id
                          : ""
                      );
                    } else {
                      setChoiceId(
                        choice.length
                          ? choice.find((f) => f.name == "Monthly").id
                          : ""
                      );
                    }
                  }}
                  sx={{
                    "& .MuiSwitch-thumb": {
                      bgcolor: "#1C407B",
                    },
                    "& .MuiSwitch-track": {
                      bgcolor: "#D9D9E5 !important",
                    },
                    "& .Mui-checked": {
                      "& .MuiSwitch-thumb": {
                        bgcolor: "#50C878",
                      },
                      "& .MuiSwitch-track": {
                        bgcolor: "#CEE6E1 !important",
                      },
                    },
                  }}
                />
              </div>
              <h6 style={{ marginLeft: "20px", marginTop: "4px" }}>Yearly</h6>
            </div>
            <div>
              <button
                className="sub-btn"
                onClick={() =>
                  navigate(`/subscription/${param.title}/${param.id}`)
                }
              >
                My Subscription
              </button>
            </div>
          </div>
          <div>
            <h6 style={{ fontWeight: "400px" }}>
            {smartTagDeviceDetails[0]?.title_two}
            </h6>
          </div>

          <div className="pt-4 smartTag-grid">
            {smartTagDevice.length
              ? smartTagDevice.map((e, i) => (
                  <div className="main_div">
                    {e.is_custom && (
                      <div className="inner_div">
                        <Typography
                          variant="body2"
                          gutterBottom
                          className="smartTag-custome-textField"
                          style={{ margin: "0px" }}
                        >
                          Custom
                        </Typography>
                      </div>
                    )}
                    <>
                      <Card
                        key={i}
                        className={`smart-card ${
                          e.id === selectedCardDetails?.id
                            ? "smart-tag-card selected"
                            : ""
                        }`}
                        onClick={() => handleCardClick(i, e.id, e.devices)}
                      >
                        <Box
                          className="addsmartTag-titleWrap d-flex position-relative"
                          style={{
                            justifyContent: "center",
                            gap: e.is_custom ? "10px" : 0,
                          }}
                        >
                          <Typography
                            variant="h6"
                            gutterBottom
                            className="addPlant-title d-flex"
                            style={{
                              justifyContent: "center",
                              gap: e.is_custom ? "10px" : 0,
                              alignItems: "center",
                            }}
                          >
                            SmarTag{" "}
                            {e.is_custom && editingStates[i] ? (
                              <TextField
                                type="number"
                                variant="standard"
                                value={inputValues}
                                onChange={(event) => handleInputChange(event)}
                                style={{
                                  background: "#1c407b",
                                  // border: "none",
                                  width: "40%",
                                }}
                                inputProps={{
                                  style: {
                                    color: "white",
                                    fontSize: "17px",
                                    borderBottom: "1px solid white",
                                  },
                                }}
                              />
                            ) : (
                              e.devices
                            )}
                          </Typography>
                          {e.is_custom && !editingStates[i] && (
                            <img
                              src={images.editsmartag}
                              alt=""
                              style={{
                                width: "20px",
                                height: "20px",
                                marginTop: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleEditClick(i)}
                            />
                          )}

                          {e.is_custom && editingStates[i] && (
                            <CheckIcon
                              className="checked-icon"
                              style={{
                                width: "20px",
                                color: "white",
                                margin: "5px 15px 0px",
                                cursor: "pointer",
                              }}
                              onClick={handleSaveClick}
                            />
                          )}
                        </Box>

                        <div className="p-2">
                          <div style={{ float: "right" }}>
                            <InfoIcon />
                          </div>
                          <div className="text-center mt-2">
                            <h1
                              className="mb-0"
                              style={{
                                fontSize: e.pack_price > 10000000 ? "34px" : "",
                              }}
                            >
                              <b>
                                {e.currency_symbole}
                                {e.pack_price}
                              </b>
                            </h1>
                            <span className="mt-2 smartTag-rupees">
                              Per{" "}
                              {e.payment_period == "Yearly" ? "Year" : "Month"}{" "}
                            </span>
                          </div>
                          <div
                            className={`${
                              e?.comment_line_1 ? "smarTag-modal" : ""
                            }`}
                          >
                            <Typography
                              variant="body2"
                              gutterBottom
                              style={{ margin: "0px" }}
                            >
                              {e.comment_line_1}
                            </Typography>
                          </div>
                          <span className="smartTag-title">
                            {e.comment_line_2}
                          </span>
                          <ListItem className="p-0">
                            <ListItemAvatar>
                              <Avatar className="checkIconbg modal-check-css">
                                <CheckRoundedIcon className="checkIcon" />
                              </Avatar>
                            </ListItemAvatar>
                            <Typography
                              gutterBottom
                              variant="h6"
                              className="subscription-details"
                            >
                              {e.devices} Tags
                            </Typography>
                          </ListItem>
                          <ListItem className="p-0">
                            <ListItemAvatar>
                              <Avatar className="checkIconbg modal-check-css">
                                <CheckRoundedIcon className="checkIcon" />
                              </Avatar>
                            </ListItemAvatar>
                            <Typography
                              gutterBottom
                              variant="h6"
                              className="subscription-details"
                            >
                              {e.currency_symbole}
                              {e.per_day_cost} / Day
                            </Typography>
                          </ListItem>
                        </div>
                      </Card>
                    </>
                  </div>
                ))
              : []}
          </div>

          <Box className="hikemmDeviceModal-innerWrap pt-5">
            <Stack
              spacing={2}
              direction="row"
              className="hikemmDeviceModal-innerButtonwrap"
            >
              <button className="decrementButton" onClick={handledecrement}>
                -
              </button>
              <Typography variant="h6" gutterBottom style={{ fontWeight: 400 }}>
                {value && value > 1500 ? "1500+" : value ? value : 0}
              </Typography>
              <button className="decrementButton" onClick={handleIncrement}>
                +
              </button>
            </Stack>
            <Box>
              <Slider
                aria-label="Volume"
                value={value}
                min={minValue}
                max={Math.max(maxValue, 1500)}
                marks={
                  value && value <= 1500
                    ? marks
                    : [...marks, { value: 1500 + "", label: "More" }]
                }
                onChange={(e) => handleChangeDevice(e, e.target.value)}
                className={
                  value >= 1500
                    ? "hikemmDeviceModal1-range"
                    : "hikemmDeviceModal-range"
                }
              />
            </Box>
          </Box>

          <div className="pt-5 d-flex justify-content-center">
            <ListItem className="p-0 d-flex justify-content-end">
              <ListItemAvatar>
                <Avatar className="checkIconbg">
                  <CheckRoundedIcon className="checkIcon" />
                </Avatar>
              </ListItemAvatar>
              <Typography gutterBottom variant="h6" className="smarTag-Details">
                {smartTagDeviceDetails[0]?.name}
              </Typography>
            </ListItem>
            <ListItem className="p-0">
              <ListItemAvatar>
                <Avatar className="checkIconbg smartTag-checkIcon">
                  <CheckRoundedIcon className="checkIcon" />
                </Avatar>
              </ListItemAvatar>
              <Typography gutterBottom variant="h6" className="smarTag-Details">
                {smartTagDeviceDetails[0]?.detail}
              </Typography>
            </ListItem>
          </div>
          {selectedCardDetails?.devices <= 1500 && (
            <div className="payment-button-smartTag pt-5">
              <button
                className="Back-button-smarTag"
                onClick={() => navigate("/subscriptionManagement")}
              >
                Back
              </button>
              <button
                className="Proceed-payment-button"
                onClick={() => handleSaveForLater()}
              >
                Proceed to Payment
              </button>
              <h1 className="smartTag-total">
                {selectedCardDetails?.currency_symbole}
                {selectedCardDetails ? selectedCardDetails.pack_price : ""}
              </h1>
              <div
                className={`${
                  selectedCardDetails?.comment_line_3 ? "saved-smartTag" : ""
                }`}
              >
                {selectedCardDetails ? selectedCardDetails.comment_line_3 : ""}
              </div>
            </div>
          )}
        </Container>
        {selectedCardDetails?.devices > 1500 && (
          <div className="payment-button-smartTag pt-5">
            <button
              className="Back-button-smarTag"
              onClick={() => navigate("/subscriptionManagement")}
            >
              Back
            </button>
            <button
              className="Contact-us-button-smartTag"
              onClick={() => setContactModal(true)}
            >
              Contact Us
            </button>
            <button
              className="Proceed-payment-button"
              style={{ pointerEvents: "none", opacity: 0.7 }}
            >
              Proceed to Payment
            </button>
          </div>
        )}

        {
          <ValidatorForm onSubmit={handleSubmit}>
            {contactModal && (
              <Card
                className="addDeviceModal-card"
                style={{ width: "440px", zIndex: 999 }}
              >
                <Box className="addDeviceModal-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addDeviceModal-title"
                  >
                    ASK FOR QUOTE
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={() => handleOpenContactCloseModel()}
                  />
                </Box>
                <Box className="addDeviceModal-headerWrap">
                  <h6> We Promise To Attend You In Less Than 2 Hours!!</h6>
                </Box>
                <Box className="addDeviceModal-innerWrap">
                  <Stack spacing={2} pt="10px">
                    <TextField
                      id="filled-basic"
                      name="first_name"
                      label="First Name *"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      onChange={handleChangeAddDetail}
                      value={addContactData.first_name}
                    />

                    <TextField
                      id="filled-basic"
                      name="last_name"
                      label="Last Name *"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      onChange={handleChangeAddDetail}
                      value={addContactData.last_name}
                    />

                    <TextField
                      id="filled-basic"
                      name="company_name"
                      label="Company Name *"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      onChange={handleChangeAddDetail}
                      value={addContactData.company_name}
                    />
                    <TextField
                      id="filled-basic"
                      name="phone_number"
                      label="Your Phone *"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      onChange={handleChangeAddDetail}
                      value={addContactData.phone_number}
                    />
                    <TextField
                      id="filled-basic"
                      name="email_id"
                      label="Your Email *"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      onChange={handleChangeAddDetail}
                      value={addContactData.email_id}
                    />
                    <TextField
                      id="filled-basic"
                      name="subscription"
                      label="Subscription * (No of required tags 1500+)"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      onChange={handleChangeAddDetail}
                      value={addContactData.subscription}
                    />
                  </Stack>
                  <Box className="accountSecurity-cardButtons deviceManagement">
                    <button className="addDeviceBtn">SUBMIT</button>
                  </Box>
                </Box>
              </Card>
            )}
          </ValidatorForm>
        }
      </div>
    </>
  );
};
export default SmartTag;
