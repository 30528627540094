import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { images } from "../../../../config";
import { getSmartTagMachine } from "../../services";

const DetailView = ({
  gateWayId,
  searchQuery,
  setLoading,
  getNotificationIconColor,
  machineAlarmStatus,
}) => {
  const [machineData, setMachineData] = useState([]);
  const [machineId, setMachineId] = useState("");
  const param = useParams();

  console.log("machineData",machineData)

  const navigate = useNavigate();
  const handleDeviceTag = (id) => {
    setMachineId(id);
    navigate(
      `/smarttag-device-tag/company/${param.companyid}/plant/${param.plantid}/gateway/${gateWayId}/machine/${id}`
    );
  };

  useEffect(() => {
    if (gateWayId) {
      getMachine();
    }
  }, [gateWayId, machineId]);

  const getMachine = async () => {
    const params = {
      gateway_id: gateWayId,
      plant_id :param.plantid
    };
    try {
      setLoading(true);
      const resp = await getSmartTagMachine(params);
      if (resp.status == 200 || resp.status == 201) {
        setLoading(false);
        setMachineData(resp.data.payload);
      }
    } catch (error) {
      setLoading(false);
      console.log("handlesubmit", error);
    }
  };
  return (
    <>
      <div>
        <div className="compactview-box">
          {machineData
            ? machineData
                .filter((e) =>
                  e.machine_name
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                )
                .map((e, index) => {
                  const matchedNotification = machineAlarmStatus.find(
                    (notif) => notif.id === e.id
                  );
                  return (
                    <Box
                      className="compactmachinetile compact-detail-box"
                      onClick={() => handleDeviceTag(e.id)}
                    >
                      <div className="p-2">
                        <div className="d-flex justify-content-between">
                          <div>
                            <h6 style={{ fontSize: "15px", fontWeight: 800 }}>
                              <b>{e.machine_name}</b>
                            </h6>
                          </div>
                          <div className="d-flex gap-1">
                            <NotificationsIcon
                              style={{
                                color: getNotificationIconColor(
                                  matchedNotification?.alarm_priority
                                ),
                              }}
                            />
                            {e.is_sub ? (
                              <img
                                src={images.cloudActiveImg}
                                alt=""
                                className="triangle-image"
                              />
                            ) : (
                              <img
                                src={images.cloudImg}
                                alt=""
                                className="triangle-image"
                              />
                            )}
                          </div>
                        </div>
                        <div>
                          <p
                            style={{ fontSize: "13px", fontWeight: 600 }}
                            className="mb-2"
                          >
                            UID : {e.uid}
                          </p>
                          <p style={{ fontSize: "12px", fontWeight: 400 }}>
                            {e.machine_description}
                          </p>
                        </div>
                      </div>
                    </Box>
                  );
                })
            : []}
        </div>
      </div>
    </>
  );
};

export default DetailView;
