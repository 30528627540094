import {
  Box,
  Card,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Button } from "bootstrap";
import { images } from "../../../config";
import { useState } from "react";
import { useSelector } from "react-redux";
import { deleteDeviceLicense, getSubscribeDeviceLicense } from "./service";
import { useEffect } from "react";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";

const EditSubscription = () => {
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDeleteIconEnabled, setIsDeleteIconEnabled] = useState(false);
  const [editSubData, setEditSubData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [subId, setSubId] = useState("");

  const { companyId } = useSelector((state) => state.userState);

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const colorArray = [
    {
      name: "Active",
      boxColor: "activesub-btn",
    },
    {
      name: "Expiring Soon",
      boxColor: "expiringdsub-btn",
    },
    {
      name: "Expired",
      boxColor: "expiredsub-btn",
    },
    {
      name: "Not Activated",
      boxColor: "not-activated-btn",
    },
  ];
  const statusArray = [
    {
      name: "Activated",
      background: "#E2FFEC",
      color: "active-license",
    },
    {
      name: "Unassigned",
      background: "#EEEEEE",
      color: "free-license",
    },
  ];
  const handleEditSubscriptionClick = () => {
    if (headerData.dashboard == 3) {
      notifyError("Can't edit smartTag subscription");
      return;
    } else {
      setIsDeleteIconEnabled((prevState) => !prevState);
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleDeleteClick = (id) => {
    setSubId(id);
    setOpenDeleteModal(true);
  };

  const param = useParams();

  useEffect(() => {
    handleSubscribeLicense();
  }, []);

  const handleSubscribeLicense = async () => {
    const params = {
      company: companyId,
      subscription_id: param.subId,
    };
    try {
      const resp = await getSubscribeDeviceLicense(params);
      if (resp.status == 200 || resp.status == 201) {
        setEditSubData(resp.data.payload.license);
        setHeaderData(resp.data.payload.header_data);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const handleEditSubDelete = async () => {
    const params = {
      id: subId,
    };
    try {
      const resp = await deleteDeviceLicense(params);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message);
        setOpenDeleteModal(false);
        handleSubscribeLicense();
      }
    } catch (error) {
      notifyError(error.response.data.message);
      console.log("handleSubmit", error);
    }
  };

  const findArray = colorArray.find(
    (f) => f.name == headerData.subscription_status
  );
  return (
    <>
      <div className="containerWrap">
        {/*  */}
        <Container maxWidth="xxl">
          {openDeleteModal && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure You Want To Delete? <br />
              </Typography>
              <p className="deleteConfirmation-title">
                {" "}
                (Associated devices, history and all the related database will
                get permanently deleted and the deletion cannot be re-instated)
              </p>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={handleEditSubDelete}
                  style={{ width: "20%" }}
                  //   onClick={() => handleDeleteDevice()}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleCloseDeleteModal}
                  style={{ width: "20%" }}
                >
                  No
                </button>
              </Box>
            </Card>
          )}
          <div className="subscriptionTitle-wrap d-flex justify-content-between">
            <>
              <div
                // onClick={() =>
                //   navigate(`/subscription/${param.title}/${param.id}`)
                // }
                onClick={() => navigate(-1)}
                className="d-flex"
              >
                <KeyboardBackspaceIcon className="backArrow" />
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    marginTop: "-4px",
                    paddingLeft: "18px",
                    color: "#001323",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                >
                  My Subscription
                </Typography>
              </div>
              <button
                className={
                  headerData?.subscription_status !== "Active" && headerData?.subscription_status !== "Not Activated"
                    ? "editsubscription-btn"
                    : "editDisable-btn"
                }
                onClick={() => {
                  if (headerData?.subscription_status !== "Active" && headerData?.subscription_status !== "Not Activated") {
                    navigate(`/payment/${param.title}/${param.id}/${param.subId}`);
                  }
                }}
              >
                Pay Now!
              </button>
            </>
          </div>

          <div className="pt-2">
            <Card className="payment-card">
              <div>
                <div className="p-4">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <h6 className="subconnection">
                        <b>{headerData?.licence_count}</b>{" "}
                        <b>{headerData?.licence_count ? "Connection" : ""} </b>
                      </h6>
                      <button
                        className="editsub-btn"
                        onClick={handleEditSubscriptionClick}
                      >
                        {isDeleteIconEnabled
                          ? "Cancel Edit"
                          : "Edit Subscription"}
                      </button>
                      <button className={findArray ? findArray.boxColor : ""}>
                        {findArray ? findArray.name : ""}
                      </button>
                    </div>
                    <div>
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{
                          marginTop: "5px",
                          paddingLeft: "18px",
                          color: "#001323",
                          fontWeight: "600",
                        }}
                      >
                        {headerData?.subscription_id}
                      </Typography>
                    </div>
                  </div>
                  <div className="pt-4">
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        xl={3}
                        style={{ borderRight: "1px solid #AEBCD2" }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <h6 style={{ fontWeight: 400 }}>Billing Type</h6>
                          <h6>
                            <b>{headerData?.payment_period}</b>
                          </h6>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        xl={3}
                        style={{ borderRight: "1px solid #AEBCD2" }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <h6 style={{ fontWeight: 400 }}>Next Billing Date</h6>
                          <h6>
                            <b>
                              {dayjs(headerData.next_billing_date).format(
                                "YYYY-MM-DD"
                              )}
                            </b>
                          </h6>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                        lg={3}
                        xl={3}
                        style={{ borderRight: "1px solid #AEBCD2" }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <h6 style={{ fontWeight: 400 }}>Next Payment</h6>
                          <h6>
                            <b>
                              {headerData
                                ? headerData.next_billing_amount
                                : "-"}
                            </b>
                          </h6>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                        <div style={{ textAlign: "center" }}>
                          <h6 style={{ fontWeight: 400 }}>Payment Method</h6>
                          <h6>
                            <b>
                              {headerData ? headerData.payment_choise : "-"}
                            </b>
                          </h6>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div
                  className="subscription-table"
                  style={{ borderRadius: "0px" }}
                >
                  <Table className="subscriptionTable">
                    <TableHead
                      className="submachine-title"
                      style={{ zIndex: 1 }}
                    >
                      <TableCell style={{ fontSize: "18px" }}>
                        <b>Company</b>
                      </TableCell>
                      <TableCell style={{ fontSize: "18px" }}>
                        <b>Plant</b>
                      </TableCell>
                      <TableCell style={{ fontSize: "18px" }}>
                        <b>Device Name</b>
                      </TableCell>
                      <TableCell style={{ fontSize: "18px" }}>
                        <b>Device Description</b>
                      </TableCell>
                      <TableCell style={{ fontSize: "18px" }}>
                        <b>Action</b>
                      </TableCell>
                    </TableHead>

                    <TableBody className="table-body-spacing">
                      {/* <TableRow style={{ height:"5px" }}></TableRow> */}
                      {editSubData.length
                        ? editSubData.map((e, i) => {
                            const findArray = statusArray.find(
                              (f) => f.name == e.status
                            );

                            return (
                              <>
                                <TableRow
                                  style={{
                                    height: "60px",
                                    background: findArray
                                      ? findArray.background
                                      : "",
                                  }}
                                >
                                  <TableCell>
                                    <div className="d-flex">
                                      <div
                                        className={
                                          findArray ? findArray.color : ""
                                        }
                                      ></div>
                                      <div style={{ marginLeft: "12px" }}>
                                        {e.company_name_device_added
                                          ? e.company_name_device_added
                                          : e.user_company_name
                                          ? e.user_company_name
                                          : "-"}
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    {e.plants.length > 0
                                      ? e.plants[0].plant_name
                                      : "-"}
                                  </TableCell>
                                  <TableCell>
                                    {e.device_name ? e.device_name : "-"}
                                  </TableCell>
                                  <TableCell>
                                    {e.device_description
                                      ? e.device_description
                                      : "-"}
                                  </TableCell>

                                  <TableCell>
                                    <div
                                      onClick={() => handleDeleteClick(e.id)}
                                      style={{ cursor: "pointer" }}
                                      className={` ${
                                        isDeleteIconEnabled
                                          ? ""
                                          : "action-delete "
                                      }`}
                                    >
                                      <img src={images.deleteImg} alt="" />
                                    </div>
                                  </TableCell>
                                </TableRow>
                                <TableRow style={{ height: "5px" }}></TableRow>
                              </>
                            );
                          })
                        : []}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Card>
          </div>
        </Container>
      </div>
    </>
  );
};

export default EditSubscription;
