import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { images } from "../../config/images";
// import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
// import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./Hikemmpopup.css";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../Login/slice/userSlice";
import { getCompany, getProductDevice } from "../CompanyDashboard/services";
import { setHeaderDetails } from "../Header/headerSlice";

const HikemPopUP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [productAvailability, setProductAvailability] = useState({
    hikemm: false,
    predhik: false,
    smarttag: false,
  });
  const [companyData, setCompanyData] = useState([]);

  console.log("productAvailability", companyData);
  const {
    redirectHikPred,
    mobileNoRe,
    is_super_admin,
    is_created_by_super_admin_user_company,
  } = useSelector((state) => state.userState);

  // useEffect(() => {
  //   if (mobileNoRe == null) {
  //     navigate("/userprofile");
  //   }
  // }, [mobileNoRe]);
  useEffect(() => {
    if (
      is_super_admin == false &&
      is_created_by_super_admin_user_company == false &&
      mobileNoRe
    ) {
      if (companyData.length) {
        if (
          productAvailability.hikemm == true &&
          productAvailability.predhik == false &&
          productAvailability.smarttag == false
        ) {
          navigate(`/companyDashboard/plant/${companyData[0].id}`);
          dispatch(setUserDetails({ redirectHikPred: "hikemm", dashboard: 1 }));
        } else {
          navigate("/select");
        }

        if (
          productAvailability.predhik == true &&
          productAvailability.hikemm == false &&
          productAvailability.smarttag == false
        ) {
          navigate(`/vms-plants/company/${companyData[0].id}/plant`);
          dispatch(setUserDetails({ redirectHikPred: "Vms", dashboard: 2 }));
        } else {
          navigate("/select");
        }

        if (
          productAvailability.smarttag == true &&
          productAvailability.predhik == false &&
          productAvailability.hikemm == false
        ) {
          navigate(`/Smarttag-companyDashboard/company/${companyData[0].id}`);
          dispatch(
            setUserDetails({ redirectHikPred: "Smarttag", dashboard: 3 })
          );
        } else {
          navigate("/select");
        }

        dispatch(setHeaderDetails({ headerName: companyData[0].company_name }));
      }
    }
    if (mobileNoRe == null) {
      navigate("/userprofile");
    }
  }, [companyData]);

  useEffect(() => {
    if (
      is_super_admin == false &&
      is_created_by_super_admin_user_company == false &&
      mobileNoRe
    ) {
      // Count the number of true values in productAvailability
      const trueCount =
        Object.values(productAvailability).filter(Boolean).length;

      if (trueCount === 1) {
        if (companyData.length) {
          if (productAvailability.hikemm) {
            navigate(`/companyDashboard/plant/${companyData[0]?.id}`);
            dispatch(
              setUserDetails({ redirectHikPred: "hikemm", dashboard: 1 })
            );
          } else if (productAvailability.predhik) {
            navigate(`/vms-plants/company/${companyData[0]?.id}/plant`);
            dispatch(setUserDetails({ redirectHikPred: "Vms", dashboard: 2 }));
          } else if (productAvailability.smarttag) {
            navigate(
              `/Smarttag-companyDashboard/company/${companyData[0]?.id}`
            );
            dispatch(
              setUserDetails({ redirectHikPred: "Smarttag", dashboard: 3 })
            );
          }
        }
        dispatch(
          setHeaderDetails({ headerName: companyData[0]?.company_name })
        );
      }
    }
    if (mobileNoRe == null) {
      navigate("/userprofile");
    }
  }, [companyData, productAvailability]);

  useEffect(() => {
    companyDetail();
    dispatch(
      setHeaderDetails({
        headerName: is_super_admin ? "Company Dashboard" : "",
      })
    );
    dispatch(
      setUserDetails({
        is_redirect: false,
      })
    );
  }, []);

  const companyDetail = async () => {
    // setisShimmerCompanyList(true);
    const params = {
      dashboard:
        productAvailability.hikemm == true
          ? 1
          : productAvailability.predhik == true
          ? 2
          : 3,
    };
    try {
      const resp = await getCompany(params);
      console.log("resp.data...", resp.status);
      if (resp.status == 200) {
        setCompanyData(resp.data.payload);
        // setTimeout(() => {
        //   setisShimmerCompanyList(false);
        // }, 1000);
      }
    } catch (error) {
      // Handle the error here
      // setisShimmerCompanyList(false);
      console.error("An error occurred:", error);
      // You can also perform any necessary error handling or show an error message to the user
    }
  };

  useEffect(() => {
    handleProductDevice();
  }, []);

  const handleProductDevice = async () => {
    const resp = await getProductDevice();
    if (resp.status === 200 || resp.status === 201) {
      setProductAvailability(resp.data.payload);
    }
  };

  const subscriptionPlan = [
    {
      id: 1,
      dashboard_id: 1,
      name: "HikEMM",
      license_dashboard: "Device_licenses",
      license_module: "HikEMM",
      module_id: 1,
      is_subscriptable: true,
      background_image: images.subscriptionbg,
      image: images.speedometerHikemm,
      price_tag: "Hikar EMS Services",
      plan_detail: [
        {
          id: 1,
          description:
            "Smart Energy Monitoring: Real time insights into your energy usage.",
        },
        {
          id: 2,
          description:
            "Maximize Savings: Empowers your business to identify energy inefficiencies.",
        },
        {
          id: 3,
          description:
            "Boost Sustainability: Actively contribute to a greener future.",
        },
      ],
    },
    {
      id: 2,
      dashboard_id: 2,
      name: "PredHik",
      license_dashboard: "Device_licenses",
      license_module: "PredHik",
      module_id: 2,
      is_subscriptable: true,
      background_image: images.subscriptionbg,
      image: images.vmsSpeedometer,
      price_tag: "Hikar VMS Services",
      plan_detail: [
        {
          id: 1,
          description:
            "Predictive Maintenance Made Easy: Insights on equipment health & prevents costly downtime.",
        },
        {
          id: 2,
          description:
            "Optimize Performance: Maximize the efficiency of assets with the real-time insights",
        },
        {
          id: 3,
          description:
            "Extend Equipment Lifespan: Optimize maintenance schedules with data driven approach.",
        },
      ],
    },
    {
      id: 3,
      dashboard_id: 3,
      name: "Smart Tag",
      license_dashboard: "Tag_licenses",
      license_module: "SmartTag",
      module_id: 3,
      is_subscriptable: true,
      background_image: images.subscriptionbg,
      image: images.SmartTaglogo,
      price_tag: "Hikar SmarTag Services",
      plan_detail: [
        {
          id: 1,
          description:
            "Comprehensive Reporting: Delivering in-depth analytics and visualizations.",
        },
        {
          id: 2,
          description:
            "AI-Powered Dashboards: AI/ML analytics, optimized operations, efficiency gains, & proactive decision-making.",
        },
        {
          id: 3,
          description:
            "Effortless Integration: intuitive dashboard is customizable, user friendly experience.",
        },
      ],
    },
  ];

  const handleCardClick = (e) => {
    if (e.is_subscriptable) {
      if (e.license_module == "HikEMM") {
        dispatch(setUserDetails({ redirectHikPred: "hikemm", dashboard: 1 }));

        navigate("/dashboard");
      }
      if (e.license_module == "PredHik") {
        dispatch(setUserDetails({ redirectHikPred: "Vms", dashboard: 2 }));
        navigate("/vms");
      }
      if (e.license_module == "SmartTag") {
        dispatch(setUserDetails({ redirectHikPred: "Smarttag", dashboard: 3 }));
        navigate("/Smarttag-Dashboard");
      }
    }
  };

  return (
    <>
      <div className="containerWrap pt-5">
        <Grid
          container
          rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
          columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 1, xl: 2, xxl: 2 }}
          style={{ marginBottom: "25px", justifyContent: "center" }}
        >
          {subscriptionPlan.map((e) => {
            if (
              (e.name === "HikEMM" && productAvailability.hikemm) ||
              (e.name === "PredHik" && productAvailability.predhik) ||
              (e.name === "Smart Tag" && productAvailability.smarttag)
            ) {
              return (
                <div className="popup" key={e.name}>
                  <div
                    className="d-flex popup-flex"
                    style={{
                      justifyContent: "center",
                      gap: "20px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Card
                      className="subscriptionCard"
                      onClick={() => handleCardClick(e)}
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={e.background_image}
                        alt="avv"
                        className="subscriptionCard-Image"
                      />
                      <div className="imageWrap"></div>
                      <img
                        src={e.image}
                        style={{ width: "14%" }}
                        alt="avv"
                        className="subscriptionCard-Icon"
                      />
                      <Stack className="subscriptionContent">
                        <Typography
                          gutterBottom
                          variant="h5"
                          className="subscription-title-name"
                        >
                          {e.name}
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h5"
                          className="subscription-text"
                        >
                          {e.price_tag}
                        </Typography>
                        <List
                          sx={{
                            width: "100%",
                            maxWidth: 360,
                            bgcolor: "background.paper",
                          }}
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginBottom: "26px",
                          }}
                        >
                          {e.plan_detail.map((e1) => (
                            <ListItem key={e1.id}>
                              <ListItemAvatar>
                                <Avatar className="checkIconbg">
                                  <CheckRoundedIcon className="checkIcon" />
                                </Avatar>
                              </ListItemAvatar>
                              <Typography
                                gutterBottom
                                variant="body2"
                                className="subscription-details"
                              >
                                {e1.description}
                              </Typography>
                            </ListItem>
                          ))}
                        </List>
                      </Stack>
                    </Card>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </Grid>
      </div>
    </>
  );
};
export default HikemPopUP;
