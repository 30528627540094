import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ShimmerText } from "react-shimmer-effects";
import {ParetoChartShimmer} from "../../components/Shimmer/ParetoChartShimmer/ParetoChartShimmer"


HC_more(Highcharts);
exporting(Highcharts);

export function COCParetoGraph({ availabilityCOCBarData,vmsMachineColor,shimmer }) {

  const param = useParams();

  const labels = availabilityCOCBarData ? Object.keys(availabilityCOCBarData).length
    ? Object.keys(availabilityCOCBarData).map((date) => date)
    : [] : [] // Add your labels here
  console.log("labelslabelslabels", Object.keys(availabilityCOCBarData));

  const findPlantDevice = vmsMachineColor ? vmsMachineColor.length
    ? vmsMachineColor.filter((f) => f.plantId == param.id)
    : [] : [];

  const stackData1 = findPlantDevice.map((stack, index) => ({
    name: stack.dvName,
    data: availabilityCOCBarData ? Object.keys(availabilityCOCBarData).length
      ? Object.keys(availabilityCOCBarData).map((down, time) =>
          availabilityCOCBarData[down][index]
            ? availabilityCOCBarData[down][index]["down_time"]
            : 0
        )
      : [] : [],
    color: "#D3DAE5",
    borderColor: stack.color,
    stack: `Stack ${index}`,
    barPercentage: 1,
    barThickness: 30,
  }));

  const stackData2 = findPlantDevice.map((stack, index) => ({
    name: stack.dvName,
    data: availabilityCOCBarData ? Object.keys(availabilityCOCBarData).length
      ? Object.keys(availabilityCOCBarData).map((down, time) =>
          availabilityCOCBarData[down][index]
            ? availabilityCOCBarData[down][index]["up_time"]
            : 0
        )
      : [] : [],

    color: stack.color,
    borderColor: stack.color,
    stack: `Stack ${index}`,
    barPercentage: 1,
    barThickness: 30,
  }));

  
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false, // Disable legend
    },
    xAxis: {
      categories: labels,
    },
    yAxis: {
      title: {
        text: "24 Hours",
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color: "gray",
        },
      },
      min: 0, // Set the minimum value for y-axis
      max: 24.5, // Set the maximum value for y-axis
      endOnTick: false, // Do not force the axis to end on a tick
      labels: {
        enabled: false, // Enable numeric labels on the y-axis
      },
      tickInterval: 4
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false, // Keep data labels enabled by default for all series
          // Additional data label configuration if needed
        },
      },
    },
    series: stackData1.concat(stackData2),
    scrollbar: {
      enabled: true, // Enable scrollbar
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <div>
      {!shimmer ? <HighchartsReact highcharts={Highcharts} options={options} /> :<ParetoChartShimmer className="ParetoChartShimmer-OverView"/>}
    </div>
  );
}


{/* <ShimmerText line={12} gap={15} />  */}