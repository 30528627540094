import { sendEmail } from "./emailConfing";


function currentDateAndTime() {
    const rawDate = new Date();
    const options = { timeZone: 'Asia/Kolkata', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true, weekday: 'long' };
    const formattedDate = rawDate.toLocaleString('en-US', options);
    return formattedDate;
}


export const handleEmailSend = async (error) => {

   

    const emailSendMsg = {
        hostUrl: process.env.REACT_APP_API_URL,
        pageUrl: window.location.href,
        apiEndPoint: error?.config?.url,
        header: error?.config?.headers?.Authorization,
        requestBody: error?.config?.data,
        errorDetails: error.message,
        userId: error?.user?.id,
        emailId: error?.user?.email,
        dateTime: currentDateAndTime(),
        userAgent: navigator.userAgent,
        ipAddress : "-"
    }

    console.log("kp error", emailSendMsg);

    // Process form data and prepare email details
    const emailDetails = {
        to: [
            'khushal.srashtasoft@gmail.com',
            'janki.srashtasoft@gmail.com',
            'parthsoni.srashtasoft@gmail.com',
            'nikunj.srashtasoft@gmail.com',
            'bhagyesh.srashtasoft@gmail.com',
            'ishan.srashtasoft@gmail.com'
        ],
        // to: ["khushal.srashtasoft@gmail.com"],
        from: 'info@hikartech.in',
        subject: 'HIkEmm Server Error',
        message: emailSendMsg
    }

    try {
        await sendEmail(emailDetails);
        console.log('Email sent successfully!');                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
    } catch (error) {
        console.error('Error sending email:', error);
    }
};
 