import React, { useEffect, useRef } from 'react';

function ShimmerEffectLineChart() {
    const canvasRef = useRef(null);

    useEffect(() => {
      
        const generateData = () => {
            let data = [];
            for (let i = 0; i < 100; i++) {
                data.push(Math.floor(Math.random() * 100));
            }
            return data;
        }

        const createLineChart = (data, canvas) => {
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.beginPath();
            ctx.moveTo(0, canvas.height);
            const stepX = canvas.width / (data.length - 1);
            data.forEach((value, index) => {
                const x = index * stepX;
                const y = canvas.height - (value / 100 * canvas.height);
                ctx.lineTo(x, y);
            });
            ctx.strokeStyle = '#3e3f40';
            ctx.lineWidth = 1;
            ctx.stroke();
        }

       
        const main = () => {
            const canvas = canvasRef.current;
            const data = generateData();
            createLineChart(data, canvas);
        }

     
        const handleResize = () => {
            const canvas = canvasRef.current;
            canvas.width = window.innerWidth; 
            main(); 
        }

      
        const canvas = canvasRef.current;
        canvas.width = window.innerWidth; 
        main(); 

        // Event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="chart-container-line-chart">
            <canvas ref={canvasRef} height="400" style={{marginTop:"6px",paddingLeft:"46px",paddingRight:"46px"}}></canvas>
            <div className="shimmer-line"></div>
        </div>
    );
}

export default ShimmerEffectLineChart;
