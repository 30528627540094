import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { images } from "../../config";
import "../../container/ProcessDashboard/ProcessDashboard.css"
import { CompactTagValue, CompactTagUnit } from "../CompactValue/CompactValue";
import "../../container/ProcessDashboard/ProcessDashboard.css"

const Tiles = ({ tagComment, tagUnit, tagNumber, selectionUnit }) => {

  return (
    <>
      <Card
        sx={{ display: "flex" }}
        className="tiles-card"
        style={{ padding: "11px 19px", borderRadius: "6px" }}
      >
        <div className="d-flex ">
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <div className="mt-3">
              <img src={images.speedometer} className="speedometer-img" alt=""/>
            </div>
          </Box>
          <CardContent className="processDashboar-meterCard">
            <Typography className="device-desc">{tagComment}</Typography>
            <Typography   className={`device-number ${tagNumber.length > 10 ? 'long-tag' : ''}`} >{tagUnit.toLowerCase().startsWith("k") ? CompactTagValue(selectionUnit,tagNumber) : tagNumber}</Typography>
            <Typography className="unit">{CompactTagUnit(selectionUnit, tagUnit)}</Typography>
          </CardContent>
        </div>
      </Card>
    </>
  );
};
export default Tiles;
