import React, { useState } from "react";
import Container from "@mui/material/Container";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import { images } from "../../../../config/images";
import Checkbox from "@mui/material/Checkbox";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import Stack from '@mui/material/Stack';
import "./AlarmManagementConfiguration.css";
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FormControlLabel, FormGroup, TextField } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Switch from "@mui/material/Switch";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "@material-ui/core/styles";
import AlarmManagementDeviceList from "./AlarmManagementDeviceList";
import AlarmManagementUserList from "./AlarmManagementUserList";
import { useEffect } from "react";
import { getGroupConfiguration } from "../services";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-input": {
      padding: "8px 10px", // set the desired padding here
    },
  },
});

const AlarmManagementConfiguration = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [getConfigurationData, setGetConfigurationData] = useState([]);
  const [configureDeviceData, setConfigureDeviceData] = useState([])

  const handleSelectedDevicesChange = (devices) => {
    setSelectedDevices(devices);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [activeClass, setActiveClass] = useState("Device");
  const classes = useStyles();

  const handleSearchData = () => {};
  const param = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const groupName = queryParams.get("groupName");

  useEffect(() => {
    handleUpdateDeviceClick();
  }, []);

  const handleUpdateDeviceClick = async () => {
    const params = {
      group: param.groupId,
    };
    try {
      const resp = await getGroupConfiguration(params);
      if (resp.status == 200 || (resp.status == 201 && resp.data.payload)) {
        setGetConfigurationData(resp.data.payload);
        const deviceID = resp.data.payload.device.length
          ? resp.data.payload.device.map((e) => e.id)
          : [];
        setSelectedDevices(deviceID);
        setConfigureDeviceData(resp.data.payload.device.length ? resp.data.payload.device : [])
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };
  return (
    <>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="www">
            <div className="deviceManagementHeader-wrap">
              <div className="deviceManagementTitle-wrap">
                <KeyboardBackspaceIcon
                  onClick={() => navigate("/alarmmanagement")}
                  className="backArrow"
                />{" "}
                <Typography
                  variant="h5"
                  gutterBottom
                  style={{
                    margin: "0",
                    paddingLeft: "10px",
                    color: "#222D39",
                    fontSize: "16px",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/alarmmanagement")}
                >
                  {param.setting ? getConfigurationData.group_name : groupName}{" "}
                  Configuration
                </Typography>
              </div>
            </div>
            <div className="d-flex pt-3 pb-3">
              <button
                className={`${
                  activeClass == "Device" ? "device-tab" : "user-tab"
                }`}
                onClick={() => setActiveClass("Device")}
              >
                Device
              </button>
              <button
                className={`${
                  activeClass == "User" ? "device-tab" : "user-tab"
                }`}
                onClick={() => {
                  if (selectedDevices.length) {
                    setActiveClass("User");
                  }
                }}
              >
                User
              </button>
            </div>
          </div>
          {activeClass == "Device" ? (
            <>
              {/* <Container maxWidth="xxl pt-2"> */}
              <Card>
                <AlarmManagementDeviceList
                  selectedDevices={selectedDevices}
                  onSelectedDevicesChange={handleSelectedDevicesChange}
                  setActiveClass={setActiveClass}
                  setSelectedDevices={setSelectedDevices}
                  getConfigurationData={getConfigurationData}
                  configureDeviceData={configureDeviceData}
                  setConfigureDeviceData={setConfigureDeviceData}
                />
              </Card>
              {/* </Container> */}
            </>
          ) : (
            <>
              {/* <Container maxWidth="xxl"> */}

              <AlarmManagementUserList
                selectedDevices={selectedDevices}
                getConfigurationData={getConfigurationData}
                activeClass={activeClass}
              />
              {/* </Container> */}
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default AlarmManagementConfiguration;
