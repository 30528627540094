import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { images } from "../../../../config";
import { PieChartShimmer } from "../../../../components/Shimmer/PieChartShimmer/PieChartShimmer";
import '../../ProcessDashboard.css';

export function EnergyPiechartCOC({
  energyCostDeviceColors,
  analyticsOverviewData,
  energyCostSum,
  emptyDevice,
  shimmer,
}) {
  const chartRef = useRef(null);
  const param = useParams();
  const { deviceColors } = useSelector((state) => state.overViewState);
  const { user_country_currency_symbol } = useSelector(
    (state) => state.userState

  );

  console.log("energyCostDeviceColors",energyCostDeviceColors)
  const findPlantDevice = energyCostDeviceColors.filter(f => f.plantId == param.id) || [];

  const deviceColorMap = new Map(findPlantDevice.map((row) => [row.dvName, row.color]));

  console.log("deviceColorMap",deviceColorMap)

  // Prepare data with absolute values for chart display and keep original values for tooltip
  const dataForChart = analyticsOverviewData.map((row, index) => ({
    name: row.device_id.device_name,
    y: Math.abs(row.total) || 0,  // Use absolute value for chart display
    color: deviceColorMap.get(row.device_id.device_name),
    originalY: row.total,  // Keep original value for tooltip
  }));

  const options = {
    chart: {
      type: 'pie',
      backgroundColor: 'transparent',
      height: 250,
      width: 250,
    },
    title: {
      text: ''
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        const total = this.series.data.reduce((acc, point) => acc + Math.abs(point.originalY), 0); // Use absolute values for total
        const percentage = ((Math.abs(this.point.originalY) / total) * 100).toFixed(2) + '%';
        return `<div style ="font-size : 12px">
                  <b>${this.point.name}</b><br/>
                  <span style="color:${this.point.color}">\u25A0</span> ${this.point.originalY} (${percentage})
                </div>`;
      }
    },
    plotOptions: {
      pie: {
        innerSize: '70%',
        dataLabels: {
          enabled: false,
        }
      }
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Values',
      data: dataForChart,
    }]
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.update(options);
    }
  }, [analyticsOverviewData, energyCostDeviceColors]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "230px" }} className="doughnut-chart1">
      {analyticsOverviewData.length && emptyDevice ? (
        <>
          {!shimmer ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartRef}
            />
          ) : (
            <PieChartShimmer size="195px" position="relative" />
          )}

          <p className="doughnut-chartText">
            {typeof energyCostSum === "number"
              ? `${energyCostSum.toFixed(2)} ${user_country_currency_symbol}`
              : `${energyCostSum} ${user_country_currency_symbol}`}
          </p>
        </>
      ) : (
        <img src={images.noDataFoundImg} className="notFoundDataImg" alt="No DataFound Img" />
      )}
    </div>
  );
}
