import React, { useRef, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";
import "chartjs-plugin-datalabels";
import { ShimmerCircularImage } from "react-shimmer-effects";
import { PieChartShimmer } from "../../../../../components/Shimmer/PieChartShimmer/PieChartShimmer";
// import './ProcessDashboard.css';

const VmsDonutChart = ({ pieChartData, piChartTotal, shimmer }) => {

  console.log("piChartTotal",piChartTotal)
  const data = {
    labels: pieChartData
      ? pieChartData.length
        ? pieChartData.map((row) => row.device__device_name)
        : []
      : [],
    datasets: [
      {
        label: "# of Votes",
        data: pieChartData
          ? pieChartData.length
            ? pieChartData.map((row) => row.up_time)
            : []
          : [],
        backgroundColor: [
          "#5A8A8A",
          "#AEC7A7",
          "#8CB9BF",
          "#E0C592",
          "#7892B5",
          "#D98481",
        ],
        borderColor: [
          "#5A8A8A",
          "#AEC7A7",
          "#8CB9BF",
          "#E0C592",
          "#7892B5",
          "#D98481",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataset = data.datasets[context.datasetIndex];
            const total = dataset.data.reduce(
              (previousValue, currentValue) => previousValue + currentValue
            );
            const currentValue = dataset.data[context.dataIndex];
            const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
            return `${currentValue} (${percentage})`;
          },
        },
      },
      legend: {
        display: false,
        labels: {
          display: false,
        },
      },
      datalabels: {
        color: "#fff",
        anchor: "center",
        align: "center",
        offset: 0,
        font: {
          weight: "bold",
          size: "14",
        },
        formatter: function (value, context) {
          const dataset = data.datasets[context.datasetIndex];
          const total = dataset.data.reduce(
            (previousValue, currentValue) => previousValue + currentValue
          );
          const currentValue = dataset.data[context.dataIndex];
          const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
          return `${value}\n(${percentage})`;
        },
      },
    },
    cutout: "70%", // Adjust this value to set the width of the doughnut chart
  };
  return (
    <div
      style={{ width: "100%", height: "250px", marginTop: "26px" }}
      className="doughnut-chart"
    >
      {!shimmer ? (
        <Doughnut data={data} options={options} style={{ margin: "auto" }} />
      ) : (
        <PieChartShimmer size="250px" position="relative"/>
      )}

      <p className="doughnut-chartText">{piChartTotal ? piChartTotal : ""}</p>
      <p className="doughnut-chartText-small">{piChartTotal ? "hrs" : ""}</p>
    </div>
  );
};

export default VmsDonutChart;



{/* <ShimmerCircularImage size={250} center={true} /> */}