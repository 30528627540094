import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";



const initialState = {
    startDateRe: dayjs().subtract(7, 'day'),
    endDateRe: dayjs(),
    startDateRe2: dayjs().subtract(7, 'day'),
    endDateRe2: dayjs(),
    startDateRe3: dayjs().subtract(7, 'day'),
    endDateRe3: dayjs(),
    selectDeviceData1:[]
};

const vmsAdvanceAnalyticsSlice = createSlice({
    name: "VmsAdvanceAnalyticsSlice",
    initialState,
    reducers: {
        setVmsAdvanceAnalyticsSet(state, { payload }) {
            return { ...state, ...payload };
        },
        clearVmsAdvanceAnalyticsSet(state) {
            return initialState;
        },
    },
});

export const {
    setVmsAdvanceAnalyticsSet,
    clearVmsAdvanceAnalyticsSet
} = vmsAdvanceAnalyticsSlice.actions;

export default vmsAdvanceAnalyticsSlice.reducer;


