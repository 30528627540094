import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { images } from "../../../config/images";
import Checkbox from "@mui/material/Checkbox";
import "./ReportManagement.css";
import {
  deletePlant,
  editPlant,
  getPlantByCompany,
  getcompanyListPlantManegement,
} from "../service";
import { useSelector, useDispatch } from "react-redux";
import ReportGroupExpand from "./ReportGroupExpand";
import {
  getReportByPlant,
  addReportApi,
  updateReportApi,
  deleteReportApi,
} from "./service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/Loader/Loader";
import { setCompanyDetails } from "../Slice";
import { ValidatorForm } from "react-material-ui-form-validator";
import { RedirectAdminDashboard } from "../../../components/CommonFunction/RedirectAdminDashboard";
import { ShimmerText } from "react-shimmer-effects";
import { SelectPlantShimmer } from "../../../components/Shimmer/SelectPlantShimmer/SelectPlantShimmer";
import ErrorIcon from "@mui/icons-material/Error";
import { ShimmerAddGroup } from "../../../components/Shimmer/ShimmerAlert/ShimmerAddGroup";

const ReportManagement = () => {
  const [openReportModal, setOpenReportModal] = useState(false);
  const [oepnUsersmodal, setOpenUsersmodal] = useState(false);
  const [Arrow, setArrow] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [selectCompany, setSelectCompany] = React.useState("");
  const [plantList, setPlantList] = useState([]);
  const [plantId, setPlantId] = useState("");
  const [reportData, setReportData] = useState([]);
  const [addReport, setAddReport] = useState({
    report_name: "",
    report_description: "",
  });
  const [editPlantData, setEditPlantData] = useState({
    plant_name: "",
    plant_description: "",
    company: "",
  });
  const [isLoading, setLoading] = useState(false);
  const [openEditplant, setOpenEditplant] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editReportModel, setEditReportModel] = useState(false);
  const [reportId, setReportId] = useState("");
  const [editReportData, setEditReportData] = useState({
    report_name: "",
    report_description: "",
  });
  const [deleteReportModel, setDeleteReportModel] = useState(false);
  const [userDetailList, setUserDetailList] = useState([]);
  const [isShimmerReport,setisShimmerReport] = useState(false);
  const [isShimmerPlantList,setisShimmerPlantList] = useState(false);



  const { companyId, companyName, companyDescription, is_super_admin,user_access,is_created_by_super_admin_user_company } = useSelector(
    (state) => state.userState
  );

  console.log("companyIdcompanyIdcompanyId", companyId);
  const dispatch = useDispatch();

  const superAdminAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Admin Dashboard"
      )
    : null;

  const accessManagementUserReport = superAdminAccess ? superAdminAccess.sub_module.length
    ? superAdminAccess.sub_module.find(
      (f) => f.sub_module_name == "Report Management"
    )
    : null : null;

  const { selectCompanyReport, selectPlantReport } = useSelector(
    (state) => state.companyDetailsState
  );

console.log("selectCompanyReport", selectCompanyReport);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const navigate = useNavigate();

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

    const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  // handle Change
  const handleChangeReport = (e) => {
    setAddReport({ ...addReport, [e.target.name]: e.target.value });
  };

  // handleChange Plant
  const handleChangePlantData = (e) => {
    setEditPlantData({ ...editPlantData, [e.target.name]: e.target.value });
  };

  // handle Edit Report model
  const handleChangeEditReport = (e) => {
    setEditReportData({ ...editReportData, [e.target.name]: e.target.value });
  };

  // Edit Plant Model
  const handleEditModal = (e) => {
    setOpenEditplant(!openEditplant);
    dispatch(setCompanyDetails({ selectPlantReport: e.id }));
    // setPlantId(e.id);
    setEditPlantData({
      plant_name: e.plant_name,
      plant_description: e.plant_description,
      company: "",
    });
  };

  // delete Model
  const handleConfirmationModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  //Add Report modal
  const handleOpenaddReportModal = () => {
    if (openReportModal || oepnUsersmodal || editReportModel) {
      return true;
    }

    setOpenReportModal(!openReportModal);
    setOpenDeleteModal(false);
    setOpenUsersmodal(false);
    setEditReportModel(false);
    setDeleteReportModel(false);
    setAddReport({
      report_name: "",
      report_description: "",
    });
  };

  // report Edit model
  const handleEditReportModel = (row) => {
    if (openReportModal || oepnUsersmodal || editReportModel) {
      return true;
    }

    setEditReportModel(!editReportModel);
    setOpenReportModal(false);
    setOpenDeleteModal(false);
    setOpenUsersmodal(false);
    setDeleteReportModel(false);

    setReportId(row.id);
    setEditReportData({
      report_name: row.report_name,
      report_description: row.report_description,
    });
  };

  // close edit Report model
  const handleEditReportCloseModel = () => {
    setEditReportModel(!editReportModel);
    setReportId("");
    setEditReportData({
      report_name: "",
      report_description: "",
    });
  };

  //Users modal
  const handleOpenUsersModal = (user, id) => {
    if (openReportModal || oepnUsersmodal || editReportModel) {
      return true;
    }

    if (user.length) {
      setOpenUsersmodal(!oepnUsersmodal);
      setUserDetailList(user);
      setReportId(id);
    }
  };

  const handleCloseUsersModal = () => {
    setOpenUsersmodal(!oepnUsersmodal);
    setUserDetailList([]);
    setReportId("");
  };

  // delete Report model
  const handleDeleteReportModel = (row) => {
    if (openReportModal || oepnUsersmodal || editReportModel) {
      return true;
    }

    setDeleteReportModel(!deleteReportModel);
    setEditReportModel(false);
    setOpenReportModal(false);
    setOpenDeleteModal(false);
    setOpenUsersmodal(false);
    setReportId(row.id);
  };

  // delete Report close model
  const handleDeleteCloseReportModel = (row) => {
    setDeleteReportModel(!deleteReportModel);
    setReportId("");
  };

  // select company
  const handleChangeCompany = (e) => {
    // setSelectCompany(e.target.value);
    if (openReportModal || oepnUsersmodal || editReportModel) {
      return true;
    }
    dispatch(
      setCompanyDetails({
        selectCompanyReport: e.target.value,
        selectPlantReport: "",
      })
    );
    // setPlantId("")
  };

  useEffect(() => {
    getcompanyListPlantManegementApi();
  }, []);

  // getCompanyList
  const getcompanyListPlantManegementApi = async () => {
    try {
      const resp = await getcompanyListPlantManegement();
      if (resp.status == 200) {
        if (!selectCompanyReport) {
          dispatch(setCompanyDetails({ selectCompanyReport: companyId }));
        }
        // dispatch(setCompanyDetails({ selectCompanyReport: companyId }));
        setCompanyList(resp.data.payload);
      }
    } catch (error) {
      if (!selectCompanyReport) {
        dispatch(setCompanyDetails({ selectCompanyReport: companyId }));
      }
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    if (selectCompanyReport) {
      getPlatListByComapnyAPIChnageCountry();
    }
  }, [selectCompanyReport]);

  const getPlatListByComapnyAPIChnageCountry = async () => {
  try{
    setisShimmerPlantList(true)

    const param = {
      company: selectCompanyReport,
    };
    const resp = await getPlantByCompany(param);
    if(resp.status == 200){
      setTimeout(()=>{ setisShimmerPlantList(false)},1000)
    }
    if (resp.status == 200 && resp.data.payload.length) {
      setPlantList(resp.data.payload);
      if (!selectPlantReport) {
        // setPlantId(resp.data.payload[0].id);
        dispatch(
          setCompanyDetails({ selectPlantReport: resp.data.payload[0].id })
        );
      }
      // setReportData([])
    } else {
      setPlantList([]);
      setReportData([])
    }

  }catch(e){
    setisShimmerPlantList(false)

    console.log("Error from getPlatListByComapnyAPIChnageCountry..")
  }
  };

  // get Report in plant

  useEffect(() => {
    if (selectPlantReport) {
      handleGetPlantByReport();
    }
  }, [selectPlantReport]);

  const handleGetPlantByReport = async () => {
    try{
      setisShimmerReport(true)
      const paramReq = {
        company: selectCompanyReport,
        plant: selectPlantReport,
      };
      const resp = await getReportByPlant(paramReq);
  
      if (
        resp.status == 200 ||
        (resp.status == 201 && resp.data.payload.length)
      ) {
        setTimeout(()=>{ setisShimmerReport(false)},1000)

        
        setReportData(resp.data.payload);
        setOpenUsersmodal(false);
      }
    }catch(e){
      setisShimmerReport(false)
      console.log("Error from handleGetPlantByReport",e)
    }
    
  };

  // Add Report Management
  const submitAddReport = async () => {
    if (!addReport.report_name) {
      notifyError("Please Enter Report Name.");
      return true;
    }

    if (!addReport.report_description) {
      notifyError("Please Enter Report Description.");
      return true;
    }

    const paramReq = {
      company: selectCompanyReport,
      plant: selectPlantReport,
      report_name: addReport.report_name,
      report_description: addReport.report_description,
    };
    setLoading(true);
    try {
      const resp = await addReportApi(paramReq);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message)
        handleOpenaddReportModal();
        handleGetPlantByReport();
        setOpenReportModal(false);
        setAddReport({
          report_name: "",
          report_description: "",
        });
        setInterval(() => {
          setLoading(false);
        }, 2000);
      }
    } catch (error) {
      notifyError(error.response.data.message);
      setLoading(false);
      // setInterval(() => {
      //   setLoading(false);
      // }, 2000);
    }
  };

  // update Plant
  // const handleUpdatePlant = async () => {

  //     if (!editPlantData.plant_name) {
  //         notifyError('Enter Plant Name');
  //         return true;
  //     }

  //     if (!editPlantData.plant_description) {
  //         notifyError('Enter Plant Description');
  //         return true;
  //     }

  //     const params = {
  //         id: plantId,
  //         plant_name: editPlantData.plant_name,
  //         plant_description: editPlantData.plant_description,
  //     };
  //     setLoading(true);
  //     try {
  //         const response = await editPlant(plantId, params);
  //         if (response.status == 200) {
  //             getPlatListByComapnyAPIChnageCountry();
  //             setOpenEditplant(false);
  //             setInterval(() => {
  //                 setLoading(false);
  //             }, 2000);
  //             setEditPlantData({
  //                 plant_name: "",
  //                 plant_description: "",
  //                 company: "",
  //             })
  //         }
  //     } catch (error) {
  //         notifyError(error.response.data.message);
  //         setInterval(() => {
  //             setLoading(false);
  //         }, 2000);
  //     }
  // };

  // const getPlatListByComapnyAPIChnageCountryCallApi = async () => {
  //     const param = {
  //         company: selectCompany,
  //     };
  //     const resp = await getPlantByCompany(param);
  //     if (resp.status == 200 && resp.data.payload.length) {
  //         setPlantList(resp.data.payload);
  //         setPlantId(resp.data.payload[0].id);
  //     }
  // };

  // delete model
  // const handleDeletePlant = async () => {
  //     setLoading(true);
  //     try {
  //         const response = await deletePlant(plantId);
  //         if (response.status == 200) {
  //             setOpenDeleteModal(false);
  //             getPlatListByComapnyAPIChnageCountryCallApi();
  //             setInterval(() => {
  //                 setLoading(false);
  //             }, 2000);
  //         }
  //     }
  //     catch (error) {
  //         console.log("Error", error);
  //         setInterval(() => {
  //             setLoading(false);
  //         }, 2000);
  //     }
  // };

  // handel Report Edit API
  const handleUpdateReport = async () => {
    if (!editReportData.report_name) {
      notifyError("Please Enter Report Name.");
      return true;
    }

    if (!editReportData.report_description) {
      notifyError("Please Enter Report Description.");
      return true;
    }

    const paramReq = {
      report_name: editReportData.report_name,
      report_description: editReportData.report_description,
    };
    setLoading(true);
    try {
      const resp = await updateReportApi(reportId, paramReq);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message)
        handleEditReportCloseModel();
        handleGetPlantByReport();
        setInterval(() => {
          setLoading(false);
        }, 3000);
      }
    } catch (error) {
      console.log("Error", error);
      setInterval(() => {
        setLoading(false);
      }, 3000);
      notifyError(error.response.data.message);
    }
  };

  // delete Report data

  const handleDeleteReport = async () => {
    try {
      setLoading(true);
      const resp = await deleteReportApi(reportId);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message)
        handleDeleteCloseReportModel();
        handleGetPlantByReport();
        setInterval(() => {
          setLoading(false);
        }, 3000);
      }
    } catch (error) {
      notifyError(error.response.data.message)
      console.log("error", error);
      setInterval(() => {
        setLoading(false);
      }, 3000);
    }
  };

  const handleClose = () => {
    setOpenReportModal(false);
  };

  const handleTostMsg = () => {
    notifyError("You don't have access.");
  };
  return (
    <>
      <div>
        {/*  */}
      </div>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                onClick={() =>{
                  RedirectAdminDashboard(is_super_admin, navigate);
                }}
                className="backArrow"
              />{" "}
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  margin: "0",
                  paddingLeft: "10px",
                  color: "#222D39",
                  fontSize: "16px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={() =>{
                  RedirectAdminDashboard(is_super_admin, navigate);
                }}
              >
                Report Management
              </Typography>
            </div>
          </div>
          {/* =============== Add Group Modal Start =============== */}
          <ValidatorForm onSubmit={submitAddReport}>
            {openReportModal && (
              <Card className="addPlant-cardModal">
                <Box className="addPlant-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addPlant-title"
                  >
                    Report
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={handleClose}
                  />
                </Box>
                <Box className="addplantMdal-innerWrap">
                  <Stack spacing={2} pt="10px">
                    <TextField
                      id="filled-basic"
                      name="report_name"
                      label="Report Name"
                      variant="filled"
                      value={addReport.report_name}
                      onChange={handleChangeReport}
                      className="addplantInputfield"
                      InputProps={{}}
                    />
                    <TextField
                      id="filled-basic"
                      name="report_description"
                      label="Description"
                      variant="filled"
                      value={addReport.report_description}
                      onChange={handleChangeReport}
                      className="addplantInputfield"
                      InputProps={{}}
                    />
                  </Stack>
                  <Box className="addPlant-cardButtons">
                    <button className="addPlantBtn">Submit</button>
                  </Box>
                </Box>
              </Card>
            )}
          </ValidatorForm>
          {/* =============== Add Group Modal End =============== */}
          {/* {openEditplant && (
                            <Card className="addPlant-cardModal">
                                <Box className="addPlant-titleWrap">
                                    <Typography
                                        variant="h5"
                                        gutterBottom
                                        className="addPlant-title"
                                    >
                                        Edit Plant
                                    </Typography>
                                    <img
                                        src={images.closeIcon}
                                        alt=""
                                        style={{
                                            width: "30px",
                                            cursor: "pointer",
                                            backgroundColor: "white",
                                        }}
                                        className="closeImg"
                                        onClick={handleEditModal}
                                    />
                                </Box>
                                <Box className="addplantMdal-innerWrap">
                                    <Stack spacing={2} pt="10px">
                                        <TextField
                                            id="filled-basic"
                                            name="plant_name"
                                            label="Plant Name"
                                            variant="filled"
                                            className="addplantInputfield"
                                            value={editPlantData.plant_name}
                                            onChange={handleChangePlantData}
                                            InputProps={{}}
                                        />
                                        <TextField
                                            id="filled-basic"
                                            name="plant_description"
                                            label="Plant Description"
                                            variant="filled"
                                            className="addplantInputfield"
                                            value={editPlantData.plant_description}
                                            onChange={handleChangePlantData}
                                            InputProps={{}}
                                        />
                                    </Stack>
                                    <Box className="addPlant-cardButtons">
                                        <button className="addPlantBtn" onClick={handleUpdatePlant}>
                                            Update
                                        </button>
                                    </Box>
                                </Box>
                            </Card>
                        )} */}

          {/* {openDeleteModal && (
                            <Card className="deleteConfirmation-Modal">
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    className="deleteConfirmation-title"
                                >
                                    Are You Sure<br></br> You Want To Delete?
                                </Typography>
                                <Box className="deleteConfirmation-btnWrap">
                                    <button
                                        className="deleteConfirmation-Yesbtn"
                                        onClick={handleDeletePlant}
                                    >
                                        Yes
                                    </button>
                                    <button
                                        className="deleteConfirmation-Nobtn"
                                        onClick={handleConfirmationModal}
                                    >
                                        No
                                    </button>
                                </Box>
                            </Card>
                        )} */}
          <ValidatorForm onSubmit={handleUpdateReport}>
            {editReportModel && (
              <Card className="addPlant-cardModal">
                <Box className="addPlant-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addPlant-title"
                  >
                    Edit group
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={handleEditReportCloseModel}
                  />
                </Box>
                <Box className="addplantMdal-innerWrap">
                  <Stack spacing={2} pt="10px">
                    <TextField
                      id="filled-basic"
                      name="report_name"
                      label="report name"
                      variant="filled"
                      className="addplantInputfield"
                      value={editReportData.report_name}
                      onChange={(e) => handleChangeEditReport(e)}
                      InputProps={{}}
                    />
                    <TextField
                      id="filled-basic"
                      name="report_description"
                      label="report Description"
                      variant="filled"
                      className="addplantInputfield"
                      value={editReportData.report_description}
                      onChange={(e) => handleChangeEditReport(e)}
                      InputProps={{}}
                    />
                  </Stack>
                  <Box className="addPlant-cardButtons">
                    <button
                      className="addPlantBtn"
                      onClick={() => handleUpdateReport()}
                    >
                      Update
                    </button>
                  </Box>
                </Box>
              </Card>
            )}
          </ValidatorForm>

          {deleteReportModel && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure<br></br> You Want To Delete?
              </Typography>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={handleDeleteReport}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleDeleteCloseReportModel}
                >
                  No
                </button>
              </Box>
            </Card>
          )}
          {/* =============== User Modal Start =============== */}

          {oepnUsersmodal && (
            <Card className="alarmManagement userCardModal">
              <Box className="addPlant-titleWrap">
                <Typography
                  variant="h5"
                  gutterBottom
                  className="addPlant-title"
                >
                  Users
                </Typography>
                <img
                  src={images.closeIcon}
                  alt=""
                  style={{
                    width: "30px",
                    cursor: "pointer",
                    backgroundColor: "white",
                  }}
                  className="closeImg"
                  onClick={handleCloseUsersModal}
                />
              </Box>
              <Box className="alarmMangemnt userCardInnerWrap">
                <img
                  src={images.settingImage}
                  alt=""
                  className="alarmManagement settingIcon icon-pointer"
                  onClick={() =>
                    navigate(
                      `/reportmanagement/company/${selectCompanyReport}/plant/${selectPlantReport}/report/${reportId}/configuration/setting`
                    )
                  }
                />
                {userDetailList.length
                  ? userDetailList.map((row) => {
                      return (
                        <div className="reportDetailsWrap">
                          <Checkbox
                            {...label}
                            style={{ color: "#1C407B" }}
                            checked={true}
                          />
                          <Typography
                            variant="body2"
                            gutterBottom
                            className="userDetails name"
                          >
                            {row.first_name} {row.last_name}
                          </Typography>
                        </div>
                      );
                    })
                  : []}
              </Box>
            </Card>
          )}
          {/* =============== User Modal End =============== */}
          <Grid container style={{ paddingTop: "20px" }}>
            <Grid item xs={12}>
              <Card className="manageDepartment-card">
                <Grid
                  container
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <Grid item sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Select Company</b>

                      {(accessManagementUserReport?.is_viewer &&
                        accessManagementUserReport?.is_editor) ||
                      (!accessManagementUserReport?.is_viewer &&
                        accessManagementUserReport?.is_editor) ? (
                        <>
                          <FormControl className="selectCompany-dropDown">
                            <Select
                                value={selectCompanyReport}
                              onChange={handleChangeCompany}
                              inputProps={{ "aria-label": "Without label" }}
                            >
                              <MenuItem value={companyId}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span> {companyName}</span>
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    style={{ marginBottom: "0px" }}
                                  >
                                    {companyDescription}
                                  </Typography>
                                </div>
                              </MenuItem>
                                {is_super_admin || is_created_by_super_admin_user_company && companyList.length
                                ? companyList.map((e) => {
                                    return (
                                      <MenuItem value={e.id} key={e.id}>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <span>{e.company_name}</span>
                                          <Typography
                                            variant="body2"
                                            gutterBottom
                                            style={{ marginBottom: "0px" }}
                                          >
                                            {e.company_description}
                                          </Typography>
                                        </div>
                                      </MenuItem>
                                    );
                                  })
                                : []}
                            </Select>
                          </FormControl>
                        </>
                      ) : (
                        <FormControl className="selectCompany-dropDown">
                          <Select
                            value={selectCompanyReport}
                            onChange={handleTostMsg}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value={companyId}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span> {companyName}</span>
                                <Typography
                                  variant="body2"
                                  gutterBottom
                                  style={{ marginBottom: "0px" }}
                                >
                                  {companyDescription}
                                </Typography>
                              </div>
                            </MenuItem>
                              {is_super_admin || is_created_by_super_admin_user_company&& companyList.length
                              ? companyList.map((e) => {
                                  return (
                                    <MenuItem value={e.id} key={e.id}>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <span>{e.company_name}</span>
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          style={{ marginBottom: "0px" }}
                                        >
                                          {e.company_description}
                                        </Typography>
                                      </div>
                                    </MenuItem>
                                  );
                                })
                              : []}
                          </Select>
                        </FormControl>
                      )}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Select Plant</b>
                    </Typography>
                    <div className="manageDepartment-wrap"  style={{height:"538px",overflow:"auto"}}>
                      {!isShimmerPlantList ? plantList.length
                        ? plantList.map((e, i) => {
                            return (
                              <div>
                                {(accessManagementUserReport?.is_viewer &&
                                  accessManagementUserReport?.is_editor) ||
                                (!accessManagementUserReport?.is_viewer &&
                                  accessManagementUserReport?.is_editor) ? (
                                  <div
                                    className="plantmanagementDepartment-fieldWrap"
                                    onClick={() => {
                                      if (
                                        openReportModal ||
                                        oepnUsersmodal ||
                                        editReportModel
                                      ) {
                                        return true;
                                      }
                                      dispatch(
                                        setCompanyDetails({
                                          selectPlantReport: e.id,
                                        })
                                      );
                                    }}
                                  >
                                    {selectPlantReport == e.id && (
                                      <img
                                        src={images.activeDeviceimg}
                                        alt=""
                                        className="activeDeviceimg"
                                      />
                                    )}
                                    <div
                                      className="manageDepartment-field"
                                      id="1"
                                     
                                    >
                                      <Typography
                                        variant="body2"
                                        gutterBottom
                                        style={{
                                          margin: "0",
                                          color: "#001323",
                                        }}
                                        id="1"
                                     
                                      >
                                        <b
                                          id="1"
                                        
                                        >
                                          {e.plant_name}
                                        </b>
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          id="1"
                                        
                                        >
                                          {e.plant_description
                                            ? e.plant_description
                                            : "-"}
                                        </Typography>
                                      </Typography>
                                    
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="plantmanagementDepartment-fieldWrap"
                                    onClick={handleTostMsg}
                                  >
                                    {selectPlantReport == e.id && (
                                      <img
                                        src={images.activeDeviceimg}
                                        alt=""
                                        className="activeDeviceimg"
                                      />
                                    )}
                                    <div
                                      className="manageDepartment-field"
                                      id="1"
                                      
                                    >
                                      <Typography
                                        variant="body2"
                                        gutterBottom
                                        style={{
                                          margin: "0",
                                          color: "#001323",
                                        }}
                                        id="1"
                                       
                                      >
                                        <b
                                          id="1"
                                        
                                        >
                                          {e.plant_name}
                                        </b>
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          id="1"
                                        
                                        >
                                          {e.plant_description
                                            ? e.plant_description
                                            : "-"}
                                        </Typography>
                                      </Typography>
                                    
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })
                          : (
                            <div className="plant-error-div">
                              <ErrorIcon className="plant-error-icon" />
                              <h5 className="mt-2">Plant not found.</h5>
                            </div>
                          )
                         : (
                          <>
                            {[...Array(5)].map((_, index) => (
                              <SelectPlantShimmer />
                            ))}
                          </>
                        )}
                    </div>
                  </Grid>
                  <Grid item sm={12} md={8} lg={8} xl={8}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Reports</b>
                    </Typography>
                    <div className="plantmanagementDepartment-card">
                      <Box className="plantmanagementDepartment-addDevicewrap">
                      {(accessManagementUserReport?.is_viewer &&
                                  accessManagementUserReport?.is_editor) ||
                                (!accessManagementUserReport?.is_viewer &&
                                  accessManagementUserReport?.is_editor) ?
                        <div
                          className="plantmanagementDepartment-assignDeviceTextWrap"
                          onClick={handleOpenaddReportModal}
                        >
                          <Typography
                            variant="caption"
                            display="lock"
                            gutterBottom
                            className="plantmanagemetDepartment-assignDeviceText"
                          >
                            Add Reports
                          </Typography>
                          <img
                            src={images.addIcon}
                            alt=""
                            className="addDeviceImg"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        :
                        <div
                        className="plantmanagementDepartment-assignDeviceTextWrap"
                        onClick={handleTostMsg}
                      >
                        <Typography
                          variant="caption"
                          display="lock"
                          gutterBottom
                          className="plantmanagemetDepartment-assignDeviceText"
                        >
                          Add Reports
                        </Typography>
                        <img
                          src={images.addIcon}
                          alt=""
                          className="addDeviceImg"
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                        
                        }
                      </Box>
                      {!isShimmerReport ? reportData.length
                        ? reportData.map((row, index) => {
                            return (
                              <React.Fragment key={index}>
                                <ReportGroupExpand
                                  row={row}
                                  handleEditReportModel={handleEditReportModel}
                                  handleDeleteReportModel={
                                    handleDeleteReportModel
                                  }
                                  selectCompany={selectCompanyReport}
                                  plantId={selectPlantReport}
                                  handleOpenUsersModal={handleOpenUsersModal}
                                  openReportModal={openReportModal}
                                  oepnUsersmodal={oepnUsersmodal}
                                  access={accessManagementUserReport}
                                  handleTostMsg ={handleTostMsg}
                                  editReportModel={editReportModel}
                                />
                                {index !== reportData.length - 1 && (
                                  <div style={{ marginBottom: "10px" }} />
                                )}{" "}
                                {/* Add a margin bottom of 10px between rows */}
                              </React.Fragment>
                            );
                          })
                          : (
                            <div className="configured-error-div">
                              <ErrorIcon className="configured-error-icon" />
                              <h5 className="mt-2">No report found.</h5>
                            </div>
                          )
                         : (
                          <>
                            {[...Array(4)].map((_, index) => (
                              <ShimmerAddGroup />
                            ))}
                          </>
                        )}
                    </div>
                    {/* <button className='Plantmanagemet submit'>Submit</button> */}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid></Grid>
          </Grid>
        </Container>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

export default ReportManagement;
