import { Typography } from "@mui/material";
import React from "react";
import "../SelectPlantShimmer/SelectPlantShimmer.css"
export const SelectPlantShimmer = () => {
  return (
    <div className="isShimmerPlantList plantmanagementDepartment-fieldWrap-shimmer">
      <div className="manageDepartment-field" id="1">
        <Typography
          variant="body2"
          gutterBottom
          style={{
            margin: "0",
            color: "#001323",
          }}
          id="1"
        >
          <b>
            {" "}
            <div class="shimmer-block shimmer-header-plant mb-2"></div>
          </b>
          <Typography variant="body2" gutterBottom id="1">
            <div class="shimmer-block shimmer-header-plant"></div>
          </Typography>
        </Typography>
        <div className="manageDepartment-fieldIcon">
          <div class="shimmer-icon"></div>
          <div class="shimmer-icon"></div>
        </div>
      </div>
    </div>
  );
};
