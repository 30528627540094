import React, { useState } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import { images } from "../../../config/images";
import { useNavigate } from "react-router-dom";

const ExpandGroup = ({
  e,
  handleEditClick,
  handleDeleteClick,
  handleOpenUsersModal,
  handleClose,
  Arrow,
  plantId,
  groupId,
  openAddgroup,
  oepnUsersmodal,
  openEditGroup,
  deleteGroupModal,
  selectCompany,
  access,
  handleTostMsg,
}) => {
  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(Arrow);
  const [deviceLastSeenModal, setDeviceLastSeenModal] = useState({});

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleOpenModal = (i) => {
    setDeviceLastSeenModal({ [i]: true });
  };

  const handleCloseModal = (i) => {
    setDeviceLastSeenModal({ [i]: false });
  };

  return (
    <>
      <div>
        <Card className="alarmManagement groupDetails-wrap mb-2">
          <Box className="groupDetails-innerWrap">
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                <b>{e.group_name}</b>
              </Typography>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                className="addDeviceDetailsCardetails-text"
              >
                {e.group_description}
              </Typography>
            </Box>
            <Box className="alarmManagement groupDetailsBtnWrap">
              {!e.user.length ? (
                <img
                  src={images.alertImage}
                  alt=""
                  className="alarmManagement groupIcon"
                  onMouseEnter={() => handleOpenModal(e.id)}
                  onMouseLeave={() => handleCloseModal(e.id)}
                />
              ) : null}
              {deviceLastSeenModal[e.id] && (
                <Card
                  className="clientManagement-updateModal-data alarm-management-update-data top"
                  style={{ marginTop: "105px", right: "158px" }}
                >
                  <Box className="clientMangement-updatedataWrap-data">
                    <div>
                      <h6
                        className="mb-0"
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Configuration Pending..
                      </h6>
                    </div>
                  </Box>
                </Card>
               )} 
              {(access?.is_viewer && access?.is_editor) ||
              (!access?.is_viewer && access?.is_editor) ||
              (access?.is_viewer && !access?.is_editor) ? (
                <button
                  className="alarmManagementConfiguration-btn"
                  onClick={() => {
                    if (
                      openAddgroup ||
                      oepnUsersmodal ||
                      openEditGroup ||
                      deleteGroupModal
                    ) {
                      return true;
                    }
                    navigate(
                      `/alarmmanagement/configuration/company/${selectCompany}/plant/${plantId}/group/${
                        e.id
                      }?groupName=${encodeURIComponent(e.group_name)}`
                    );
                  }}
                >
                  Configuration
                </button>
              ) : (
                <button
                  className="alarmManagementConfiguration-btn"
                  onClick={handleTostMsg}
                >
                  Configuration
                </button>
              )}
              {(access?.is_viewer && access?.is_editor) ||
              (!access?.is_viewer && access?.is_editor) ||
              (access?.is_viewer && !access?.is_editor) ? (
                <>
                  <img
                    src={images.editImg}
                    alt=""
                    style={{ cursor: "pointer" }}
                    className="alarmManagement groupIcon"
                    onClick={() => handleEditClick(e)}
                  />
                  <img
                    src={images.deleteImg}
                    style={{ cursor: "pointer" }}
                    alt=""
                    className="alarmManagement groupIcon"
                    onClick={() => handleDeleteClick(e)}
                  />
                </>
              ) : (
                <>
                  <img
                    src={images.editImg}
                    alt=""
                    className="alarmManagement groupIcon"
                    onClick={handleTostMsg}
                  />
                  <img
                    src={images.deleteImg}
                    alt=""
                    className="alarmManagement groupIcon"
                    onClick={handleTostMsg}
                  />
                </>
              )}
            </Box>
          </Box>
          {isExpanded && (
            <>
              <hr></hr>
              <div className="alarmManagement-DeviceList">
                <div>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    style={{ marginBottom: "0px" }}
                  >
                    <b>Device</b>
                  </Typography>
                </div>
                {e.device.map((device, index) => (
                  <div className="alaramManagement-devicelist-BtnWrap">
                    <div>
                      <button className="alarmManagement-deviceList-button">
                        {device.device_name}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="alarmMnagement-userDetailsWrap">
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{ marginBottom: "0px" }}
                >
                  <b>Users</b>
                </Typography>
                {(access?.is_viewer && access?.is_editor) ||
                (!access?.is_viewer && access?.is_editor) ? (
                  <img
                    src={images.userImg}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOpenUsersModal(e.user, e.id)}
                  />
                ) : (
                  <img
                    src={images.userImg}
                    alt=""
                    style={{ cursor: "pointer" }}
                    onClick={handleTostMsg}
                  />
                )}
              </div>
            </>
          )}
          {isExpanded ? (
            <img
              src={images.upArrow}
              alt=""
              className="clientMangement-downIcon"
              onClick={handleToggleExpand}
            />
          ) : (
            <img
              src={images.downIcon}
              alt=""
              className="clientMangement-downIcon"
              onClick={handleToggleExpand}
            />
          )}
        </Card>
      </div>
    </>
  );
};

export default ExpandGroup;
