import React, { useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { images } from "../../../config";
import { useState } from "react";
import { useMemo } from "react";
import { CompactTagValue } from "../../../components/CompactValue/CompactValue";

HC_more(Highcharts); // Initialize the 'highcharts-more' module
exporting(Highcharts); // Initialize the 'exporting' module

const HighchartsGraph = ({
  unitName,
  chartCubeData,
  getDevice,
  average,
  selectedColors,
  chartLineData,
  combineChartData,
  selectTimeRange,
  averageValue,
  selectUnit,
  // setCombineChartData
}) => {
  const [chartOption, setChartOption] = useState({
    chart: {
      type: "spline",
    },
    title: {
      text: null, // Remove the title
    },
    xAxis: {
      categories: [],
      scrollbar: {
        enabled: true,
      },
      gridLineWidth: 1,
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    series: [
      {
        name: "Timestamp",
        data: [],
      },
    ],
    colors: selectedColors.length ? selectedColors.map((e) => e.color) : [],
    exporting: {
      enabled: true, // Disable exporting options
    },
    credits: {
      enabled: false, // Disable credits
    },
  });

  const categoriesMaxLegth = combineChartData.reduce(
    (maxIndex, currentArray, currentIndex, array) => {
      if (currentArray.length > array[maxIndex].length) {
        return currentIndex;
      } else {
        return maxIndex;
      }
    },
    0
  );

  const categories =
    combineChartData[categoriesMaxLegth]?.map((e) => e.tagTime) || [];

  const averageSeries = average
    ? [
        {
          name: "Average",
          data: averageValue.map((e) =>  parseFloat(CompactTagValue(selectUnit, e))),
          color: "green",
          dashStyle: "ShortDash", // You can use other dash styles as well
          lineWidth: 2,
        },
      ]
    : [];

  const seriesForDevices = getDevice.map((e, index) => {
    const findDeviceId = selectedColors.find((f) => f.deviceId === e?.device);
    // console.log("combineChartData[index] && combineChartData[index].map((e) => parseFloat(e.tagValue))....",combineChartData[index] && combineChartData[index].map((e) => parseFloat(e.tagValue)))
    return {
      name: e?.device__device_name,

      data:
        (combineChartData[index] &&
          combineChartData[index].map((e) =>
            parseFloat(CompactTagValue(selectUnit, e.tagValue))
          )) ||
        [],
      color: findDeviceId ? findDeviceId.color : "",
      dashStyle: "Solid",
      lineWidth: 1,
    };
  });

  const series = [...seriesForDevices, ...averageSeries];

  useEffect(() => {
    setChartOption({
      ...chartOption,
      xAxis: {
        ...chartOption.xAxis,
        categories: categories.concat([""]),
      },
      yAxis: {
        title: {
          text: unitName,
        },
      },
      series: series,
    });
  }, [combineChartData, average, selectUnit]);

  // useEffect(() => {
  //     options["chart"] = {
  //         type: "spline",
  //         scrollablePlotArea: {
  //             minWidth: 700,
  //             scrollPositionX: categories.length - 1, // Set scroll position to the rightmost end
  //         }
  //     }
  // }, [getDevice, selectTimeRange])

  return (
    <div style={{ height: "500px" }}>
      {combineChartData.every((array) => array.length === 0) ? (
        <img src={images.noDataFoundImg} className="notFoundDataImg" />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={chartOption} />
      )}
    </div>
  );
};

export default HighchartsGraph;
