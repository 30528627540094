import './PieChartShimmer.css';

export const PieChartShimmer = ({ size , position})=>{
    const style = {
        width: size,
        height: size,
        position: position,
        
    };

    return(
        
       
        <div className="center-container">

        <div class="pie-chart-shimmer" style={style}></div>
        
        </div>
   
    )
    
}