import React, { useState, useEffect, useMemo } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import {
  deviceTypeApi,
  licenseApi,
  getDevice,

} from "../../SuperAdminDashboard/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../SuperAdminDashboard/DeviceManagement/DeviceManagement.css"
import { useSelector } from "react-redux";
import { useCubeQuery } from "@cubejs-client/react";
import ExpandDevice from "./ExpandDevice";

function VmsDeviceManagement({handleUpdateVmsDevice,setOpenVmsDeleteModal}) {
  const [openAdddeviceModal, setOpenAdddeviceModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openUpdatedeviceModal, setOpenUpdatedeviceModal] =
    React.useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [deviceTypedata, setdeviceTypeData] = useState([]);
  const [licenseData, setlicenceData] = useState([]);
  const [age, setAge] = useState("");
  
  const intialData = {
    company: "",
    uid: "",
    device_name: "",
    device_description: "",
    device_type: "",
    license: "",
  };
  const [addDeviceForm, setAddDeviceForm] = useState(intialData);
  const [updateDeviceForm, setEditDeviceForm] = useState(intialData);
  const [deviceId, setDeviceId] = useState("");
  const [cloudData, setCloudData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [deviceLastSeenModal, setDeviceLastSeenModal] = useState({});
  const [activeClass, setActiveClass] = useState("vms");

  const initiallValue = {
    device_name: "",
    device_description: "",
    uid: "",
    company__company_name: "",
  };
  const [searcDevice, setSearchDevice] = useState(initiallValue);

  const { companyId } = useSelector((state) => state.userState);
  console.log("addDeviceForm", addDeviceForm);

  const navigate = useNavigate();

  function formatDate(inputDate) {
    if (!inputDate) return "";

    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  const notify = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  //DeviceSearch
  const handleChangeDeviceSearch = (e) => {
    setSearchDevice({ ...searcDevice, [e.target.name]: e.target.value });
  };



  const handleConfirmationModal = (id) => {
    if (openAdddeviceModal || openDeleteModal || openUpdatedeviceModal) {
      return true;
    }
    setOpenUpdatedeviceModal(false);
    setOpenAdddeviceModal(false);
    setOpenDeleteModal(!openDeleteModal);
    setDeviceId(id);
  };




  const handleOpenModal = (i) => {
    setDeviceLastSeenModal({ [i]: true });
  };

  const handleCloseModal = (i) => {
    setDeviceLastSeenModal({ [i]: false });
  };

  // add Device Handle Form


  //get Device
  const getdeviceData = async () => {
    try {
      const response = await getDevice(searcDevice);

      if (response.status === 200) {
        setDeviceData(response.data.payload);
      }
    } catch (error) {
      // Handle the error here
      console.error("An error occurred:", error);
      // You can also perform any necessary error handling or show an error message to the user
    }
  };

  //DeviceTypeApi
  const getdeviceTypeApi = async () => {
    const resp = await deviceTypeApi();
    if (resp.status == 200) {
      setdeviceTypeData(resp.data.payload);
    }
    console.log("devicedata", resp);
  };
  //LicenseApi
  const getlicenceApi = async () => {
    const response = await licenseApi();
    console.log("licenecData", response);

    if (response.data.success == true) {
      setlicenceData(response.data.payload);
    }
  };




  useEffect(() => {
    getdeviceData();
  }, [searcDevice]);

  useEffect(() => {
    getdeviceTypeApi();
    getlicenceApi();
  }, []);

  const cloudColorData = useCubeQuery(
    {
      dimensions: [
        "SuperadminDevice.isSub",
        "SuperadminDevice.subTime",
        "SuperadminDevice.uid",
      ],
      order: {
        "SuperadminDevice.isSub": "asc",
      },
      filters: [
        {
          member: "SuperadminDevice.uid",
          operator: "equals",
          values: deviceData.length ? deviceData.map((e) => e.uid) : ["0"],
        },
      ],
    },
    { subscribe: true }
  );

  useMemo(() => {
    if (cloudColorData.resultSet) {
      const array = cloudColorData.resultSet.tablePivot();
      const colorCloud = array.map((row) => {
        const utcDate = new Date(row["SuperadminDevice.subTime"]);
        const istOffset = 5.5 * 60 * 60 * 1000; // IST offset in milliseconds
        const istDate = new Date(utcDate.getTime() + istOffset);

        // Convert to 12-hour format
        let hours = istDate.getHours();
        const minutes = istDate.getMinutes();
        const seconds = istDate.getSeconds();
        const ampm = hours >= 12 ? "PM" : "AM";
        hours %= 12;
        hours = hours || 12;
        return {
          isSub: row["SuperadminDevice.isSub"],
          uid: row["SuperadminDevice.uid"],
          subDate: istDate.toISOString().slice(0, -5).substr(0, 10),
          subTime: `${hours}:${minutes}:${seconds} ${ampm}`,
        };
      });
      console.log("colorCloud");
      setCloudData(colorCloud);
    }
  }, [cloudColorData.resultSet]);


  return (
    <>
     
          <div className="tableResponsive">
            <Table
              aria-label=""
              className={`deviceManagementTable ${
                openUpdatedeviceModal || openDeleteModal ? "disabledRow" : ""
              }`}
            >
              <TableHead>
                <TableRow className="deviceManagementTable-title">
                  <TableCell colSpan={2}>Machine Name</TableCell>
                  <TableCell>Machine Description</TableCell>
                  <TableCell>UID</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>License</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Device Name"
                      name="device_name"
                      value={searcDevice.device_name}
                      onChange={handleChangeDeviceSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Device Description"
                      name="device_description"
                      value={searcDevice.device_description}
                      onChange={handleChangeDeviceSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="UID"
                      name="uid"
                      value={searcDevice.uid}
                      onChange={handleChangeDeviceSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Company"
                      name="company__company_name"
                      value={searcDevice.company__company_name}
                      onChange={handleChangeDeviceSearch}
                    />
                  </TableCell>
                  <TableCell className="search-table-row">
                 
                  </TableCell>
                  <TableCell className="search-table-row">
                
                  </TableCell>
                </TableRow>
             
                {/* {deviceData.length
                  ? deviceData.map((e, i) => {
                      const findCloud = cloudData.find((f) => f.uid == e.uid);
                      console.log("findCloudfindCloudfindCloud", findCloud);
                      return (
         
                        <ExpandDevice findCloud={findCloud} handleOpenModal={handleOpenModal} handleCloseModal={handleCloseModal} deviceLastSeenModal={deviceLastSeenModal} handleConfirmationModal={handleConfirmationModal} openAdddeviceModal={openAdddeviceModal} openDeleteModal={openDeleteModal} openUpdatedeviceModal={openUpdatedeviceModal}formatDate={formatDate} e={e} i={i} handleUpdateVmsDevice={handleUpdateVmsDevice} setOpenVmsDeleteModal={setOpenVmsDeleteModal}/>
                      );
                    })
                  : []} */}
              </TableHead>
              <TableBody></TableBody>
            </Table>
          </div>
          {/* =============== Device Management Table End =============== */}
        {/* </Container>
        {isLoading && <Loader />}
      </div> */}
    </>
  );
}

export default VmsDeviceManagement;
