import React from 'react'


const SubscriptionFooter = () =>{
    return(
        <>
        <div className='sub-footertext'>
            <div className='active-plan'></div> Active
            <div className='expiring-plan'></div> Expiring Soon
            <div className='expired-plan'></div> Expired
            <div className='not-activated-plan'></div> Free Limited Trial
        </div>
        </>
    )
}

export default SubscriptionFooter