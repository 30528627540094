import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Textshimmer from "../../../components/Shimmer/TextShimmer/TextShimmer";

const GatewayTableShimmer = ({itemCount}) => {
    const style = {
        backgroundColor:"white"
    }
  return (
    <>
      {Array.from(
        { length: itemCount }).map((_, index) => (
          <TableRow key={index} className="gateWayShimmer-table">
            <TableCell style={{ backgroundColor:"white"}}>
              <Textshimmer height="24px" width="50%" />
            </TableCell>
            <TableCell style={style}>
              <Textshimmer height="16px" width="100%" />
            </TableCell>
            <TableCell style={style}>
              <Textshimmer height="16px" width="100%" />
            </TableCell >
            <TableCell style={style}>
              <Textshimmer height="16px" width="100%" />
            </TableCell>
            <TableCell style={style}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Textshimmer height="16px" width="50%" />
                <Textshimmer height="24px" width="20%" />
              </div>
            </TableCell>
            <TableCell style={style}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Textshimmer height="24px" width="20%" />
                <Textshimmer height="24px" width="20%" />
                <Textshimmer height="24px" width="20%" />
                <Textshimmer height="24px" width="20%" />
              </div>
            </TableCell>
          </TableRow>
        ))
      }
    </>
  );
};

export default GatewayTableShimmer;
