import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    vmsTagSelect: []
}

const vmsOverViewSlice = createSlice({
    name: "vmsTag",
    initialState,
    reducers: {
        setVmsTagData(state, { payload }) {
            return { ...state, ...payload };
        },
        clearVmsTagData(state) {
            return initialState;
        }
    },
});

export const {
    setVmsTagData,
    clearVmsData
} = vmsOverViewSlice.actions;

export default vmsOverViewSlice.reducer;


