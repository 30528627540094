import dayjs from "dayjs";
import React, { useRef, useEffect } from "react";

function Clock({ clockArray }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = 30; // Adjust the radius as needed

    // Function to fill a time range with a color
    function fillTimeRange(startHour, endHour, color) {
      const startAngle = ((startHour % 12) / 12) * 2 * Math.PI - Math.PI / 2;
      const endAngle = ((endHour % 12) / 12) * 2 * Math.PI - Math.PI / 2;

      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.arc(centerX, centerY, radius, startAngle, endAngle);
      ctx.lineTo(centerX, centerY);
      ctx.fillStyle = color;
      ctx.fill();
    }

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the clock outline
    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    ctx.lineWidth = 2;
    ctx.strokeStyle = "black";
    ctx.stroke();

    fillTimeRange(0, 12, "black");

     // Check if clockArray has data and draw as needed
     if (clockArray && clockArray.length > 0) {
      clockArray.forEach((row) => {
        if (row.startHour && row.endHour) {
          fillTimeRange(row.startHour, row.endHour == "12" ?  "11.9999999999" :  row.endHour, "black");
        }
      });
    }
  }, [clockArray]);

  return <canvas ref={canvasRef} width="90" height="80" style={{ marginTop: "2px" }}></canvas>;
}


export default Clock;
