import { request } from "../../../api/api";

export const addAlarmNote =(params)=>{
   return request.post("/dashboard/addAlarmNote/",params)
}

export const getAlarm = (params, searchalarm, page, pageSize,selectRorL)=>{
   return request.post(`/dashboard/getAlarm/?page=${page + 1}&page_size=${pageSize}&search=${ searchalarm.date || searchalarm.time || searchalarm.tag || searchalarm.alarm_msg}&state=${selectRorL}`,params)
}


export const alarmAction = (data) => {
   return request.post('/dashboard/alarmAction/', data)
}

export const alarmwindowExcel = (params) =>{
   return request.post(`/dashboard/exportAlarmWindowReportExcel/`,params)
}

export const alarmAllAction =(params)=>{
   return request.post(`/dashboard/allAlarmAction/`,params)
}