import React, { useEffect, useMemo, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Card,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import COCVmsDatePicker from "../../../VMS/VMSComponent/COCVmsDatePicker";
import POPVmsDatePicker from "../../../VMS/VMSComponent/POPVmsDatePicker";
// import TagListComponent from "../../../VMS/VmsCompanyDashboard/VmsMachine/Reports/TagListComponent/TagListComponent";
import SmartTagList from "../../Smartagcomponent/SmarttagtagList";
import GraphComponent from "../../Smartagcomponent/GraphComponent";
import "./MachineAnalytics.css";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSmartTagReportData } from "../Slice";
import { images } from "../../../../config";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import {
  getDeviceByDashboard,
  getTagList,
  tagInteractionData,
} from "../../services";
import TagListComponent from "../../../VMS/VmsCompanyDashboard/VmsMachine/Reports/TagListComponent/TagListComponent";
import { getReportAPIComp } from "../../../VMS/VmsCompanyDashboard/VmsMachine/Reports/getReportAPIComp";
import { getReportLineChartData } from "../../../VMS/VmsCompanyDashboard/VmsMachine/Reports/getReportLineChartData";
import { getDeviceUnits } from "../../../VMS/VmsCompanyDashboard/VmsMachine/Reports/service";
import {
  getStorePreviousSelectedData,
  storeSelectedData,
} from "../../../VMS/VmsCompanyDashboard/VmsMachine/service";

const MachineAnalytics = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [rangeModal, setRangeModal] = useState(false);
  const [rangeModalPOP, setRangeModalPOP] = useState(false);
  const [Machines, setMachines] = useState([]);
  const [unit, setUnit] = useState([]);
  const [tagListData, setTagListData] = useState([]);
  const [tagListData1, setTagListData1] = useState([]);
  const [cocGraphdata, setCocGraphData] = useState([]);
  const [cocLineChartdata, setCocLineChartData] = useState([]);
  const [submittedRange, setSubmittedRange] = useState(""); // New state variable
  const [submittedRangePOP, setSubmittedRangePOP] = useState(""); // New state variable
  const [averageCOCLineArray, setAverageCOCLineArray] = useState([]);
  const [popBarGraphData1, setPopBarGraphData1] = useState([]);
  const [popBarGraphData2, setPopBarGraphData2] = useState([]);
  const [popLineChartdata, setPopLineChartData] = useState([]);
  const [averagePOPLineArray, setAveragePOPLineArray] = useState([]);
  const [pop2LineChartdata, setPop2LineChartData] = useState([]);
  const [isShimmerdevicelistCOC, setisShimmerdevicelistCOC] = useState(false);
  const [isShimmerdevicelistPOP, setisShimmerdevicelistPOP] = useState(false);
  const [isShimmerUnitlistCOC, setisShimmerUnitlistCOC] = useState(false);
  const [isShimmerMachineslistCOC, setisShimmerMachineslistCOC] =
    useState(false);
  const [isShimmerUnitlistPOP, setisShimmerUnitlistPOP] = useState(false);
  const [isShimmerMachineslistPOP, setisShimmerMachineslistPOP] =
    useState(false);
  const [unitsData, setUnitsData] = useState([]);
  const [LineCOCIntervalTime, setLineCOCIntervalTime] = useState("1");
  const [LinePOPIntervalTime, setLinePOPIntervalTime] = useState("1");
  const [rangeCOC, setRangeCOC] = useState("");
  const [rangePOP, setRangePOP] = useState("");
  const [selectedPreviousData, setSelectedPreviousData] = useState([]);
  const [smartTagCOCTime, setSmartTagCOCTime] = useState(false);
  const [smartTagPOPTime, setSmartTagPOPTime] = useState(false)

  const {
    activeClassConsumer,
    // smartTagCOCStartDate,
    // smartTagCOCEndDate,
    // smartTagPOPStartDate,
    // smartTagPOPEndDate,
    // smartTagPOP1StartDate,
    // smartTagPOP1EndDate,
    // smartTagCOCTime,
    // smartTagPOPTime,
    // smartTagCOCSelectMachine,
    // smartTagCOCSelectUnit,
    // smartTagCOCSelectTag,
    // smartTagPOPSelectMachine,
    // smartTagPOPSelectUnit,
    // smartTagPOPSelectTag,
    COCIntervalTime,
    // rangeValue,
    // rangValuePOP,
    POPIntervalTimePop,
    tagClickebleData,
    
  } = useSelector((state) => state.smartTagReportSlice);

  // shortcut data

  const [selectDeviceData, setSelectDeviceData] = useState([]);
  const [selectUnitData, setSelectUnitData] = useState([]);
  const [selectTagData, setSelectTagData] = useState([]);

  const [selectPOPDeviceData, setSelectPOPDeviceData] = useState([]);
  const [selectPOPUnitData, setSelectPOPUnitData] = useState([]);
  const [selectPOPTagData, setSelectPOPTagData] = useState([]);
  const [isShimmerCOC, setShimmerCOC] = useState(false);
  const [isShimmerPOP, setShimmerPOP] = useState(false);
  const [isShimmerLineChartPOP, setShimmerLineChartPOP] = useState(false);
  const [isShimmerLineChartCOC, setShimmerLineChartCOC] = useState(false);
  const [rangeCOCValue, setRangeCOCValue] = useState("Set Range");
  const [rangePOPValue, setRangePOPValue] = useState("Set Range");
  const [smartTagCOCSelectMachine, setSmartTagCOCSelectMachine] = useState([]);
  const [smartTagCOCSelectUnit, setSmartTagCOCSelectUnit] = useState([]);
  const [smartTagCOCSelectTag, setSmartTagCOCSelectTag] = useState([]);
  const [smartTagPOPSelectMachine, setSmartTagPOPSelectMachine] = useState([]);
  const [smartTagPOPSelectUnit, setSmartTagPOPSelectUnit] = useState([]);
  const [smartTagPOPSelectTag, setSmartTagPOPSelectTag] = useState([]);
  const [smartTagCOCSwitch, setSmartTagCOCSwitch] = useState(false);
  const [smartTagPOPSwitch, setSmartTagPOPSwitch] = useState(false);


  const [smartTagCOCStartDate, setSmartTagCOCStartDate] = useState(
    smartTagCOCSwitch ? dayjs().subtract(24, "hour") : null
  );
  const [smartTagCOCEndDate, setSmartTagCOCEndDate] = useState(
    smartTagCOCSwitch ? dayjs() : null
  );
  const [smartTagPOPStartDate, setSmartTagPOPStartDate] = useState(
    smartTagPOPSwitch ? dayjs().subtract(24, "hour") : null
  );
  const [smartTagPOPEndDate, setSmartTagPOPEndDate] = useState(
    smartTagPOPSwitch ? dayjs() : null
  );
  const [smartTagPOP1StartDate, setSmartTagPOP1StartDate] = useState(
    smartTagPOPSwitch ? dayjs().subtract(24, "hour") : null
  );
  const [smartTagPOP1EndDate, setSmartTagPOP1EndDate] = useState(
    smartTagPOPSwitch ? dayjs() : null
  );

  useEffect(() => {
    if (params.deviceId) {
      setSelectDeviceData([
        {
          machineId: tagClickebleData?.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "machineId"
              ]
            : "",
          plantId: params.plantid,
        },
      ]);
      setSelectUnitData([
        {
          tagUnit: tagClickebleData?.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "tagUnit"
              ]
            : "",
          machineId: tagClickebleData?.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "machineId"
              ]
            : "",
          plantId: params.plantid,
        },
      ]);
      setSelectTagData([
        {
          tagId: tagClickebleData?.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "tagId"
              ]
            : "",
          tagName: tagClickebleData?.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "tagName"
              ]
            : "",
          tagUnit: tagClickebleData?.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "tagUnit"
              ]
            : "",
          machineId: tagClickebleData?.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "machineId"
              ]
            : "",
          plantId: params.plantid,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (params.deviceId) {
      setSelectPOPDeviceData([
        {
          machineId: tagClickebleData?.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "machineId"
              ]
            : "",
          plantId: params.plantid,
        },
      ]);
      setSelectPOPUnitData([
        {
          tagUnit: tagClickebleData?.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "tagUnit"
              ]
            : "",
          machineId: tagClickebleData?.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "machineId"
              ]
            : "",
          plantId: params.plantid,
        },
      ]);
      setSelectPOPTagData([
        {
          tagId: tagClickebleData?.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "tagId"
              ]
            : "",
          tagName: tagClickebleData?.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "tagName"
              ]
            : "",
          tagUnit: tagClickebleData?.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "tagUnit"
              ]
            : "",
          machineId: tagClickebleData?.length
            ? tagClickebleData.find((f) => f.plantId == params.plantid)?.[
                "machineId"
              ]
            : "",
          plantId: params.plantid,
        },
      ]);
    }
  }, []);

  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm:ss";

  const formatStartDate = smartTagCOCStartDate
    ? dayjs(smartTagCOCStartDate).format(dateFormat)
    : "";
  const formatEndDate = smartTagCOCEndDate
    ? dayjs(smartTagCOCEndDate).format(dateFormat)
    : "";

  const formatStartTime = smartTagCOCStartDate
    ? dayjs(smartTagCOCStartDate).format(timeFormat)
    : "";
  const formatEndTime = smartTagCOCEndDate
    ? dayjs(smartTagCOCEndDate).format(timeFormat)
    : "";

  const formatStartDatePOP = smartTagPOPStartDate
    ? dayjs(smartTagPOPStartDate).format(dateFormat)
    : "";
  const formatEndDatePOP = smartTagPOPEndDate
    ? dayjs(smartTagPOPEndDate).format(dateFormat)
    : "";

  const formatStartTimePOP = smartTagPOPStartDate
    ? dayjs(smartTagPOPStartDate).format(timeFormat)
    : "";
  const formatEndTimePOP = smartTagPOPEndDate
    ? dayjs(smartTagPOPEndDate).format(timeFormat)
    : "";

  const formatStartDatePOP1 = smartTagPOP1StartDate
    ? dayjs(smartTagPOP1StartDate).format(dateFormat)
    : "";
  const formatEndDatePOP1 = smartTagPOP1EndDate
    ? dayjs(smartTagPOP1EndDate).format(dateFormat)
    : "";

  const formatStartTimePOP1 = smartTagPOP1StartDate
    ? dayjs(smartTagPOP1StartDate).format(timeFormat)
    : "";
  const formatEndTimePOP1 = smartTagPOP1EndDate
    ? dayjs(smartTagPOP1EndDate).format(timeFormat)
    : "";
  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleToggleTime = () => {
    setSmartTagCOCTime(!smartTagCOCTime)
   
  };

  useMemo(() => {
    if (cocLineChartdata.length) {
      const chartDataTagValue = cocLineChartdata[0].data
        ? cocLineChartdata[0].data.map((item) => item.tag_value)
        : [];

      const averagesArray = [];

      if (chartDataTagValue.length && chartDataTagValue.length >= 2) {
        for (let i = 0; i < chartDataTagValue.length - 1; i++) {
          const average = (chartDataTagValue[i] + chartDataTagValue[i + 1]) / 2;
          averagesArray.push(average);
        }
      }
      setAverageCOCLineArray(averagesArray);
    }
  }, [cocLineChartdata]);

  function averageTagValues(arr) {
    // Find the maximum length of the data arrays
    const maxLength = arr.reduce(
      (max, obj) => Math.max(max, obj.data?.length),
      0
    );
    const averages = [];

    // Loop through each index up to the maximum length
    for (let i = 0; i < maxLength; i++) {
      let sum = 0;
      let count = 0;

      // Loop through each object in the array
      for (let j = 0; j < arr.length; j++) {
        const tagValue = arr[j].data[i] ? arr[j].data[i].tag_value : 0;
        sum += tagValue;
        count++;
      }

      // Calculate the average and push it to the averages array
      const average = parseFloat((sum / count).toFixed(2));
      averages.push(average);
    }

    return averages;
  }

  useMemo(() => {
    if (cocLineChartdata.length > 1) {
      const result = averageTagValues(cocLineChartdata);
      setAverageCOCLineArray(result);
    }
  }, [cocLineChartdata]);

  useEffect(() => {
    if (popLineChartdata.length && !pop2LineChartdata.length) {
      const chartDataTagValue = popLineChartdata[0].data
        ? popLineChartdata[0].data.map((item) => item.tag_value)
        : [];

      const averagesArray = [];

      if (chartDataTagValue.length && chartDataTagValue.length >= 2) {
        for (let i = 0; i < chartDataTagValue.length - 1; i++) {
          const average = (chartDataTagValue[i] + chartDataTagValue[i + 1]) / 2;
          averagesArray.push(average);
        }
      }
      console.log("averagesArray", averagesArray);
      setAveragePOPLineArray(averagesArray);
    }

    if (!popLineChartdata.length && pop2LineChartdata.length) {
      const chartDataTagValue = pop2LineChartdata[0].data
        ? pop2LineChartdata[0].data.map((item) => item.tag_value)
        : [];

      const averagesArray = [];

      if (chartDataTagValue.length && chartDataTagValue.length >= 2) {
        for (let i = 0; i < chartDataTagValue.length - 1; i++) {
          const average = (chartDataTagValue[i] + chartDataTagValue[i + 1]) / 2;
          averagesArray.push(average);
        }
      }
      console.log("averagesArray", averagesArray);
      setAveragePOPLineArray(averagesArray);
    }

    if (popLineChartdata.length && pop2LineChartdata.length) {
      const result = averageTagValues(
        popLineChartdata.concat(pop2LineChartdata)
      );
      setAveragePOPLineArray(result);
    }

    if (!popLineChartdata.length && !pop2LineChartdata.length) {
      setAveragePOPLineArray([]);
    }
  }, [popLineChartdata, pop2LineChartdata]);

  const MAX_DATE_RANGE_DAYS = 365;

  const startDateReFormat = smartTagCOCStartDate
    ? dayjs(smartTagCOCStartDate)
    : null;
  const endDateReFormat = smartTagCOCEndDate ? dayjs(smartTagCOCEndDate) : null;

  const startDateRePOPFormat = smartTagPOPStartDate
    ? dayjs(smartTagPOPStartDate)
    : null;
  const endDateRePOPFormat = smartTagPOPEndDate
    ? dayjs(smartTagPOPEndDate)
    : null;

  const startDateRePOP1Format = smartTagPOP1StartDate
    ? dayjs(smartTagPOP1StartDate)
    : null;
  const endDateRePOP1Format = smartTagPOP1EndDate
    ? dayjs(smartTagPOP1EndDate)
    : null;

  const handleStartDate = (newValue) => {
    const durationInDays = endDateReFormat
      ? endDateReFormat.diff(newValue, "days")
        ? endDateReFormat.diff(newValue, "days") > 0
          ? endDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setSmartTagCOCStartDate(null);
      // dispatch(setSmartTagReportData({ smartTagCOCStartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormat && newValue.isBefore(endDateReFormat)) {
      setSmartTagCOCStartDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagCOCStartDate: newValue }));
    } else if (!endDateReFormat) {
      setSmartTagCOCStartDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagCOCStartDate: newValue }));
    } else if (endDateReFormat && newValue.isSame(endDateReFormat, "day")) {
      // Check for time validation if showTime is true
      if (
        smartTagCOCTime &&
        (newValue.hour() > endDateReFormat.hour() ||
          (newValue.hour() === endDateReFormat.hour() &&
            newValue.minute() >= endDateReFormat.minute()))
      ) {
        setSmartTagCOCStartDate(null);
        // dispatch(setSmartTagReportData({ smartTagCOCStartDate: null }));
        notifyError("Start time must be before end time");
      } else {
        setSmartTagCOCStartDate(newValue);
        // dispatch(setSmartTagReportData({ smartTagCOCStartDate: newValue }));
      }
    } else {
      setSmartTagCOCStartDate(null);
      dispatch(setSmartTagReportData({ smartTagCOCStartDate: null }));
      notifyError("End date must be greater than start date");
    }
  };

  const handleEndDate = (newValue) => {
    const durationInDays = startDateReFormat
      ? startDateReFormat.diff(newValue, "days")
        ? startDateReFormat.diff(newValue, "days") > 0
          ? startDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setSmartTagCOCEndDate(null);
      // dispatch(setSmartTagReportData({ smartTagCOCEndDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormat && newValue.isAfter(startDateReFormat)) {
      setSmartTagCOCEndDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagCOCEndDate: newValue }));
    } else if (!startDateReFormat) {
      setSmartTagCOCEndDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagCOCEndDate: newValue }));
    } else if (startDateReFormat && newValue.isSame(startDateReFormat, "day")) {
      // Check for time validation if showTime is true
      if (
        smartTagCOCTime &&
        (newValue.hour() < startDateReFormat.hour() ||
          (newValue.hour() === startDateReFormat.hour() &&
            newValue.minute() <= startDateReFormat.minute()))
      ) {
        setSmartTagCOCEndDate(null);
        // dispatch(setSmartTagReportData({ smartTagCOCEndDate: null }));
        notifyError("End time must be after start time");
      } else {
        setSmartTagCOCEndDate(newValue);
        // dispatch(setSmartTagReportData({ smartTagCOCEndDate: newValue }));
      }
    } else {
      setSmartTagCOCEndDate(null);
      // dispatch(setSmartTagReportData({ smartTagCOCEndDate: null }));
      notifyError("Start date must be less than end date");
    }
  };

  // POP handle date
  const handlePOPStartDate = (newValue) => {
    const durationInDays = endDateRePOPFormat
      ? endDateRePOPFormat.diff(newValue, "days")
        ? endDateRePOPFormat.diff(newValue, "days") > 0
          ? endDateRePOPFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setSmartTagPOPStartDate(null);
      // dispatch(setSmartTagReportData({ smartTagPOPStartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateRePOPFormat && newValue.isBefore(endDateRePOPFormat)) {
      setSmartTagPOPStartDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagPOPStartDate: newValue }));
    } else if (!endDateRePOPFormat) {
      setSmartTagPOPStartDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagPOPStartDate: newValue }));
    } else if (
      endDateRePOPFormat &&
      newValue.isSame(endDateRePOPFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        smartTagPOPTime &&
        (newValue.hour() > endDateRePOPFormat.hour() ||
          (newValue.hour() === endDateRePOPFormat.hour() &&
            newValue.minute() >= endDateRePOPFormat.minute()))
      ) {
        setSmartTagPOPStartDate(null);
        // dispatch(setSmartTagReportData({ smartTagPOPStartDate: null }));
        notifyError("Start time must be before end time");
      } else {
        setSmartTagPOPStartDate(newValue);
        // dispatch(setSmartTagReportData({ smartTagPOPStartDate: newValue }));
      }
    } else {
      setSmartTagPOPStartDate(null);
      // dispatch(setSmartTagReportData({ smartTagPOPStartDate: null }));
      notifyError("End date must be greater than start date");
    }
    // dispatch(setSmartTagReportData({ smartTagPOPStartDate: newValue }));
  };

  const handlePOPEndDate = (newValue) => {
    const durationInDays = startDateRePOPFormat
      ? startDateRePOPFormat.diff(newValue, "days")
        ? startDateRePOPFormat.diff(newValue, "days") > 0
          ? startDateRePOPFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setSmartTagPOPEndDate(null);
      // dispatch(setSmartTagReportData({ smartTagPOPEndDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateRePOPFormat && newValue.isAfter(startDateRePOPFormat)) {
      setSmartTagPOPEndDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagPOPEndDate: newValue }));
    } else if (!startDateRePOPFormat) {
      setSmartTagPOPEndDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagPOPEndDate: newValue }));
    } else if (
      startDateRePOPFormat &&
      newValue.isSame(startDateRePOPFormat, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        smartTagPOPTime &&
        (newValue.hour() < startDateRePOPFormat.hour() ||
          (newValue.hour() === startDateRePOPFormat.hour() &&
            newValue.minute() <= startDateRePOPFormat.minute()))
      ) {
        setSmartTagPOPEndDate(null);
        // dispatch(setSmartTagReportData({ smartTagPOPEndDate: null }));
        notifyError("End time must be after start time");
      } else {
        setSmartTagPOPEndDate(newValue);
        // dispatch(setSmartTagReportData({ smartTagPOPEndDate: newValue }));
      }
    } else {
      setSmartTagPOPEndDate(null);
      // dispatch(setSmartTagReportData({ smartTagPOPEndDate: null }));
      notifyError("Start date must be less than end date");
    }
    // dispatch(setSmartTagReportData({ smartTagPOPEndDate: newValue }));
  };

  const handlePOPToggleTime = () => {
    setSmartTagPOPTime(!smartTagPOPTime)
    // dispatch(setSmartTagReportData({ smartTagPOPTime: !smartTagPOPTime }));
  };

  const handlePOP1StartDate = (newValue) => {
    const durationInDays = endDateRePOP1Format
      ? endDateRePOP1Format.diff(newValue, "days")
        ? endDateRePOP1Format.diff(newValue, "days") > 0
          ? endDateRePOP1Format.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setSmartTagPOP1StartDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagPOP1StartDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateRePOP1Format && newValue.isBefore(endDateRePOP1Format)) {
      setSmartTagPOP1StartDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagPOP1StartDate: newValue }));
    } else if (!endDateRePOP1Format) {
      setSmartTagPOP1StartDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagPOP1StartDate: newValue }));
    } else if (
      endDateRePOP1Format &&
      newValue.isSame(endDateRePOP1Format, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        smartTagPOPTime &&
        (newValue.hour() > endDateRePOP1Format.hour() ||
          (newValue.hour() === endDateRePOP1Format.hour() &&
            newValue.minute() >= endDateRePOP1Format.minute()))
      ) {
        setSmartTagPOP1StartDate(null);
        // dispatch(setSmartTagReportData({ smartTagPOP1StartDate: null }));
        notifyError("Start time must be before end time");
      } else {
        setSmartTagPOP1StartDate(newValue);
        // dispatch(setSmartTagReportData({ smartTagPOP1StartDate: newValue }));
      }
    } else {
      setSmartTagPOP1StartDate(null);
      // dispatch(setSmartTagReportData({ smartTagPOP1StartDate: null }));
      notifyError("End date must be greater than start date");
    }
    // dispatch(setSmartTagReportData({ smartTagPOP1StartDate: newValue }));
  };

  const handlePOP1EndDate = (newValue) => {
    const durationInDays = startDateRePOP1Format
      ? startDateRePOP1Format.diff(newValue, "days")
        ? startDateRePOP1Format.diff(newValue, "days") > 0
          ? startDateRePOP1Format.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      setSmartTagPOP1EndDate(null);
      // dispatch(setSmartTagReportData({ smartTagPOP1EndDate: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateRePOP1Format && newValue.isAfter(startDateRePOP1Format)) {
      setSmartTagPOP1EndDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagPOP1EndDate: newValue }));
    } else if (!startDateRePOP1Format) {
      setSmartTagPOP1EndDate(newValue);
      // dispatch(setSmartTagReportData({ smartTagPOP1EndDate: newValue }));
    } else if (
      startDateRePOP1Format &&
      newValue.isSame(startDateRePOP1Format, "day")
    ) {
      // Check for time validation if showTime is true
      if (
        smartTagPOPTime &&
        (newValue.hour() < startDateRePOP1Format.hour() ||
          (newValue.hour() === startDateRePOP1Format.hour() &&
            newValue.minute() <= startDateRePOP1Format.minute()))
      ) {
        setSmartTagPOP1EndDate(null);
        // dispatch(setSmartTagReportData({ smartTagPOP1EndDate: null }));
        notifyError("End time must be after start time");
      } else {
        setSmartTagPOP1EndDate(newValue);
        // dispatch(setSmartTagReportData({ smartTagPOP1EndDate: newValue }));
      }
    } else {
      setSmartTagPOP1EndDate(null);
      // dispatch(setSmartTagReportData({ smartTagPOP1EndDate: null }));
      notifyError("Start date must be less than end date");
    }
    // dispatch(setSmartTagReportData({ smartTagPOP1EndDate: newValue }));
  };

  useEffect(() => {
    if (smartTagCOCSwitch) {
      // Calculate and set 24-hour difference for start and end dates
      const currentDate = new Date();
      const startDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
      const endDate = currentDate;

      setSmartTagCOCStartDate(startDate);
      setSmartTagCOCEndDate(endDate);
      // setSmartTagCOCTime(true)
    } else {
      // When the switch is off, clear the start and end dates
      setSmartTagCOCStartDate(null);
      setSmartTagCOCEndDate(null);
      // setSmartTagCOCTime(false)
    }
  }, [smartTagCOCSwitch]);

  useEffect(() => {
    if (smartTagPOPSwitch) {
      // Calculate and set 24-hour difference for start and end dates
      const currentDate = new Date();
      const startDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
      const endDate = currentDate;
      setSmartTagPOPStartDate(startDate);
      setSmartTagPOPEndDate(endDate);
      setSmartTagPOP1StartDate(startDate);
      setSmartTagPOP1EndDate(endDate);
      // setVmsReportCOCStartDate(startDate);
      // setVmsReportCOCEndDate(endDate);
    } else {
      // When the switch is off, clear the start and end dates
      setSmartTagPOPStartDate(null);
      setSmartTagPOPEndDate(null);
      setSmartTagPOP1StartDate(null);
      setSmartTagPOP1EndDate(null);
    }
  }, [smartTagPOPSwitch]);

  const dispatch = useDispatch();

  useEffect(() => {
    GetSmartTagMachine();
  }, []);

  const GetSmartTagMachine = async () => {
    setisShimmerUnitlistCOC(true);
    setisShimmerMachineslistCOC(true);
    setisShimmerUnitlistPOP(true);
    setisShimmerMachineslistPOP(true);
    try {
      const param = {
        dashboard: 3,
        plant: params.plantid,
      };
      const resp = await getDeviceByDashboard(param);
      if (resp.status == 200 || resp.status == 201) {
        setMachines(resp.data.payload.device);
        setUnit(resp.data.payload.tag_unit);
        setTimeout(() => {
          setisShimmerUnitlistCOC(false);
          setisShimmerMachineslistCOC(false);
          setisShimmerUnitlistPOP(false);
          setisShimmerMachineslistPOP(false);
        }, 1000);
      }
    } catch (error) {
      setisShimmerUnitlistCOC(false);
      setisShimmerMachineslistCOC(false);
      setisShimmerUnitlistPOP(false);
      setisShimmerMachineslistPOP(false);
      console.log("Error from GetSmartTagMachine", error);
    }
  };

  const handleSelectMachine = (selectedMachine) => {
    if (params.deviceId) {
      const existingIndex = selectDeviceData.findIndex(
        (color) =>
          color.machineId === selectedMachine.device &&
          color.plantId === params.plantid
      );

      if (existingIndex !== -1) {
        const updateMachineId = [...selectDeviceData];
        updateMachineId.splice(existingIndex, 1);
        setSelectDeviceData(updateMachineId);
      } else {
        // If the machine is not selected, add it
        setSelectDeviceData((prev) => {
          return [
            ...prev,
            {
              machineId: selectedMachine.device,
              plantId: params.plantid,
              dvName: selectedMachine.device__device_name,
            },
          ];
        });
      }
    }

    const existingIndex = smartTagCOCSelectMachine.findIndex(
      (color) =>
        color.machineId === selectedMachine.device &&
        color.plantId === params.plantid
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...smartTagCOCSelectMachine];
      updateMachineId.splice(existingIndex, 1);
      setSmartTagCOCSelectMachine(updateMachineId);

      const findSelectedTagUnit = smartTagCOCSelectUnit.length
        ? smartTagCOCSelectUnit.filter((f) => f.plantId != params.plantid)
        : [];
      setSmartTagCOCSelectUnit(findSelectedTagUnit);

      const findSelectedTeg = smartTagCOCSelectTag.length
        ? smartTagCOCSelectTag.filter((f) => f.plantId != params.plantid)
        : [];
      setSmartTagCOCSelectTag(findSelectedTeg);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagCOCSelectMachine: updateMachineId,
      //   })
      // );
    } else {
      // If the machine is not selected, add it
      const newMachine = {
        machineId: selectedMachine.device,
        plantId: params.plantid,
        dvName: selectedMachine.device__device_name,
      };
      // Add the object to the state array if it doesn't exist
      setSmartTagCOCSelectMachine((prevState) => [...prevState, newMachine]);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagCOCSelectMachine: [
      //       ...smartTagCOCSelectMachine,
      //       {
      //         machineId: selectedMachine.device,
      //         plantId: params.plantid,
      //         dvName: selectedMachine.device__device_name,
      //       },
      //     ],
      //   })
      // );
    }
  };

  const handleSelectReportsTagUnit = (selectedUnit) => {
    if (params.deviceId) {
      const existingIndex = selectUnitData.findIndex(
        (color) =>
          color.tagUnit === selectedUnit.tag_unit &&
          color.plantId === params.plantid
      );

      if (existingIndex !== -1) {
        const updateMachineId = [...selectUnitData];
        updateMachineId.splice(existingIndex, 1);
        setSelectUnitData(updateMachineId);
      } else {
        setSelectUnitData((prev) => {
          return [
            ...prev,
            {
              tagUnit: selectedUnit.tag_unit,
              machineId: selectedUnit.device,
              plantId: params.plantid,
            },
          ];
        });
        // If the unit is not selected, add it
      }
    }
    const existingIndex = smartTagCOCSelectUnit.findIndex(
      (color) =>
        color.tagUnit === selectedUnit.tag_unit &&
        color.plantId === params.plantid
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...smartTagCOCSelectUnit];
      updateMachineId.splice(existingIndex, 1);
      setSmartTagCOCSelectUnit(updateMachineId);

      const findSelectedTeg = smartTagCOCSelectTag.length
        ? smartTagCOCSelectTag.filter((f) => f.plantId != params.plantid)
        : [];
      setSmartTagCOCSelectTag(findSelectedTeg);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagCOCSelectUnit: updateMachineId,
      //   })
      // );
      setTagListData([]);
    } else {
      // If the unit is not selected, add it
      const newUnit = {
        tagUnit: selectedUnit.tag_unit,
        machineId: selectedUnit.device,
        plantId: params.plantid,
      };
      // Add the object to the state array if it doesn't exist
      setSmartTagCOCSelectUnit((prevState) => [...prevState, newUnit]);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagCOCSelectUnit: [
      //       ...smartTagCOCSelectUnit,
      //       {
      //         tagUnit: selectedUnit.tag_unit,
      //         machineId: selectedUnit.device,
      //         plantId: params.plantid,
      //       },
      //     ],
      //   })
      // );
    }
  };

  const handleSelectReportTag = (row, color) => {
    if (params.deviceId) {
      const existingIndex = selectTagData.findIndex(
        (color) => color.tagId === row.id && color.plantId === params.plantid
      );

      if (existingIndex !== -1) {
        const updateMachineId = [...selectTagData];
        updateMachineId.splice(existingIndex, 1);
        setSelectTagData(updateMachineId);
      } else {
        // Add the object to the state array if it doesn't exist
        setSelectTagData((prev) => {
          return [
            ...prev,
            {
              tagId: row.id,
              tagName: row.tag_name,
              tagUnit: row.tag_unit,
              machineId: row.device,
              plantId: params.plantid,
              color: color,
            },
          ];
        });
      }
    } else {
      const existingIndex = smartTagCOCSelectTag.findIndex(
        (color) => color.tagId === row.id && color.plantId === params.plantid
      );

      if (existingIndex !== -1) {
        const updateMachineId = [...smartTagCOCSelectTag];
        updateMachineId.splice(existingIndex, 1);
        setSmartTagCOCSelectTag(updateMachineId);
        // dispatch(
        //   setSmartTagReportData({
        //     smartTagCOCSelectTag: updateMachineId,
        //   })
        // );
      } else {
        // Add the object to the state array if it doesn't exist
        const newTag = {
          tagId: row.id,
          tagName: row.tag_name,
          tagUnit: row.tag_unit,
          machineId: row.device,
          plantId: params.plantid,
          color: color,
        };
        // Add the object to the state array if it doesn't exist
        setSmartTagCOCSelectTag((prevState) => [...prevState, newTag]);
        // dispatch(
        //   setSmartTagReportData({
        //     smartTagCOCSelectTag: [
        //       ...smartTagCOCSelectTag,
        //       {
        //         tagId: row.id,
        //         tagName: row.tag_name,
        //         tagUnit: row.tag_unit,
        //         machineId: row.device,
        //         plantId: params.plantid,
        //         color: color,
        //       },
        //     ],
        //   })
        // );
      }
    }
  };

  const handlePOPSelectMachine = (selectedMachine) => {
    if (params.deviceId) {
      const existingIndex = selectPOPDeviceData.findIndex(
        (color) =>
          color.machineId === selectedMachine.device &&
          color.plantId === params.plantid
      );

      if (existingIndex !== -1) {
        const updateMachineId = [...selectPOPDeviceData];
        updateMachineId.splice(existingIndex, 1);
        setSelectPOPDeviceData(updateMachineId);
      } else {
        // If the machine is not selected, add it
        setSelectPOPDeviceData((prev) => {
          return [
            ...prev,
            {
              machineId: selectedMachine.device,
              plantId: params.plantid,
              dvName: selectedMachine.device__device_name,
            },
          ];
        });
      }
    }

    const existingIndex = smartTagPOPSelectMachine.findIndex(
      (color) =>
        color.machineId === selectedMachine.device &&
        color.plantId === params.plantid
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...smartTagPOPSelectMachine];
      updateMachineId.splice(existingIndex, 1);
      setSmartTagPOPSelectMachine(updateMachineId);

      const findSelectedTagUnit = smartTagPOPSelectUnit.length
        ? smartTagPOPSelectUnit.filter((f) => f.plantId != params.plantid)
        : [];

      setSmartTagPOPSelectUnit(findSelectedTagUnit);

      const findSelectedTeg = smartTagPOPSelectTag.length
        ? smartTagPOPSelectTag.filter((f) => f.plantId != params.plantid)
        : [];

      setSmartTagPOPSelectTag(findSelectedTeg);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagPOPSelectMachine: updateMachineId,
      //   })
      // );
    } else {
      // If the machine is not selected, add it
      const newDevice = {
        machineId: selectedMachine.device,
        plantId: params.plantid,
        dvName: selectedMachine.device__device_name,
      };
      // Add the object to the state array if it doesn't exist
      setSmartTagPOPSelectMachine((prevState) => [...prevState, newDevice]);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagPOPSelectMachine: [
      //       ...smartTagPOPSelectMachine,
      //       {
      //         machineId: selectedMachine.device,
      //         plantId: params.plantid,
      //         dvName: selectedMachine.device__device_name,
      //       },
      //     ],
      //   })
      // );
    }
  };

  const handlePOPSelectTagUnit = (selectedUnit) => {
    if (params.deviceId) {
      const existingIndex = selectPOPUnitData.findIndex(
        (color) =>
          color.tagUnit === selectedUnit.tag_unit &&
          color.plantId === params.plantid
      );

      if (existingIndex !== -1) {
        const updateMachineId = [...selectPOPUnitData];
        updateMachineId.splice(existingIndex, 1);
        setSelectPOPUnitData(updateMachineId);
      } else {
        setSelectPOPUnitData((prev) => {
          return [
            ...prev,
            {
              tagUnit: selectedUnit.tag_unit,
              machineId: selectedUnit.device,
              plantId: params.plantid,
            },
          ];
        });
        // If the unit is not selected, add it
      }
    }
    const existingIndex = smartTagPOPSelectUnit.findIndex(
      (color) =>
        color.tagUnit === selectedUnit.tag_unit &&
        color.plantId === params.plantid
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...smartTagPOPSelectUnit];
      updateMachineId.splice(existingIndex, 1);
      setSmartTagPOPSelectUnit(updateMachineId);

      const findSelectedTeg = smartTagPOPSelectTag.length
        ? smartTagPOPSelectTag.filter((f) => f.plantId != params.plantid)
        : [];

      setSmartTagPOPSelectTag(findSelectedTeg);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagPOPSelectUnit: updateMachineId,
      //   })
      // );
      setTagListData([]);
    } else {
      // If the unit is not selected, add it
      const newUnit = {
        tagUnit: selectedUnit.tag_unit,
        machineId: selectedUnit.device,
        plantId: params.plantid,
      };
      // Add the object to the state array if it doesn't exist
      setSmartTagPOPSelectUnit((prevState) => [...prevState, newUnit]);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagPOPSelectUnit: [
      //       ...smartTagPOPSelectUnit,
      //       {
      //         tagUnit: selectedUnit.tag_unit,
      //         machineId: selectedUnit.device,
      //         plantId: params.plantid,
      //       },
      //     ],
      //   })
      // );
    }
  };

  const handlePOPSelectTag = (row, color) => {
    const existingIndex = smartTagPOPSelectTag.findIndex(
      (color) => color.tagId === row.id && color.plantId === params.plantid
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...smartTagPOPSelectTag];
      updateMachineId.splice(existingIndex, 1);
      setSmartTagPOPSelectTag(updateMachineId);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagPOPSelectTag: updateMachineId,
      //   })
      // );
    } else {
      // Add the object to the state array if it doesn't exist
      const oldDataTagSelected = [...smartTagPOPSelectTag];

      const filterOldData = oldDataTagSelected.filter(
        (f) => f.plantId != params.plantid
      );
      const newTag = {
        tagId: row.id,
        tagName: row.tag_name,
        tagUnit: row.tag_unit,
        machineId: row.device,
        plantId: params.plantid,
        color: color,
      };
      // Add the object to the state array if it doesn't exist
      setSmartTagPOPSelectTag([...filterOldData, newTag]);
      // dispatch(
      //   setSmartTagReportData({
      //     smartTagPOPSelectTag: [
      //       ...filterOldData,
      //       {
      //         tagId: row.id,
      //         tagName: row.tag_name,
      //         tagUnit: row.tag_unit,
      //         machineId: row.device,
      //         plantId: params.plantid,
      //         color: color,
      //       },
      //     ],
      //   })
      // );
    }
  };

  useEffect(() => {
    if (params.deviceId) {
      const findSelectedTag = selectTagData.length
        ? selectTagData
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.tagId)
        : [];

      if (
      
        findSelectedTag.length &&
        formatStartDate &&
        formatEndDate &&
        rangeCOC != "none" &&
        activeClassConsumer == "Consumer"
      ) {
        handleStoreSeletedData();
      } else {
        setCocGraphData([]);
      }
    } else {
      if (
       
        // findSelectedTag.length &&
        formatStartDate &&
        formatEndDate &&
        rangeCOC != "none" &&
        activeClassConsumer == "Consumer"
      ) {
        handleStoreSeletedData();
      } else {
        setCocGraphData([]);
      }
    }
  }, [
    smartTagCOCSwitch,
    smartTagCOCSelectTag,
    formatStartDate,
    formatEndDate,
    rangeCOC,
    activeClassConsumer,
    smartTagCOCTime,
    formatStartTime,
    formatEndTime,
    selectTagData,
    LineCOCIntervalTime,
  ]);

  useEffect(() => {
    if (params.deviceId) {
      const findSelectedTag = selectTagData.length
        ? selectTagData
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.tagId)
        : [];

      if (
        
        findSelectedTag.length &&
        formatStartDate &&
        formatEndDate &&
        rangeCOC != "" &&
        activeClassConsumer == "Consumer"
      ) {
        handleStoreSeletedData();
      } else {
        setCocGraphData([]);
        setCocLineChartData([]);
      }
    } else {
      const findSelectedTag = smartTagCOCSelectTag.length
      ? smartTagCOCSelectTag
          .filter((f) => f.plantId === params.plantid)
          .map((row) => row.tagId)
      : [];

      if (
       
        findSelectedTag.length &&
        formatStartDate &&
        formatEndDate &&
        rangeCOC != "" &&
        activeClassConsumer == "Consumer"
      ) {
        handleStoreSeletedData();
      } else {
        setCocGraphData([]);
        setCocLineChartData([]);
      }
    }
  }, [
    smartTagCOCSwitch,
    smartTagCOCSelectTag,
    formatStartDate,
    formatEndDate,
    rangeCOC,
    activeClassConsumer,
    smartTagCOCTime,
    formatStartTime,
    formatEndTime,
    selectTagData,
    LineCOCIntervalTime,
  ]);

  useEffect(() => {
    if (params.deviceId) {
      const findSelectedTag = selectPOPTagData.length
        ? selectPOPTagData
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.tagId)
        : [];

      if (
        findSelectedTag.length &&
        formatStartDatePOP &&
        formatEndDatePOP &&
        rangePOP != "none" &&
        // rangeModalPOP == false &&
        activeClassConsumer == "Period"
      ) {
        handleStoreSeletedDataPOP();
      } else {
        setPopBarGraphData1([]);
      }
    } else {
      const findSelectedTag = smartTagPOPSelectTag.length
        ? smartTagPOPSelectTag
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.tagId)
        : [];

      if (
        
        findSelectedTag.length &&
        formatStartDatePOP &&
        formatEndDatePOP &&
        rangePOP != "none" &&
        // rangeModalPOP == false &&
        activeClassConsumer == "Period"
      ) {
        handleStoreSeletedDataPOP();
      } else {
        setPopBarGraphData1([]);
      }
    }
  }, [
    smartTagPOPSwitch,
    smartTagPOPSelectTag,
    formatStartDatePOP,
    formatEndDatePOP,
    rangePOP,
    activeClassConsumer,
    smartTagPOPTime,
    formatStartTimePOP,
    formatEndTimePOP,
    selectPOPTagData,
  ]);
  useEffect(() => {
    if (params.deviceId) {
      const findSelectedTag = selectPOPTagData.length
        ? selectPOPTagData
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.tagId)
        : [];

      if (
        
        findSelectedTag.length &&
        formatStartDatePOP &&
        formatEndDatePOP &&
        rangePOP != "none" &&
        // rangeModalPOP == false &&
        activeClassConsumer == "Period"
      ) {
        handleStoreSeletedDataPOP();
      } else {
        setPopBarGraphData2([]);
      }
    } else {
      const findSelectedTag = smartTagPOPSelectTag.length
        ? smartTagPOPSelectTag
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.tagId)
        : [];

      if (
       
        findSelectedTag.length &&
        formatStartDatePOP1 &&
        formatEndDatePOP1 &&
        rangePOP != "none" &&
        // rangeModalPOP == false &&
        activeClassConsumer == "Period"
      ) {
        handleStoreSeletedDataPOP();
      } else {
        setPopBarGraphData2([]);
      }
    }
  }, [
    smartTagPOPSwitch,
    smartTagPOPSelectTag,
    formatStartDatePOP1,
    formatEndDatePOP1,
    rangePOP,
    activeClassConsumer,
    smartTagPOPTime,
    formatStartTimePOP1,
    formatEndTimePOP1,
    selectPOPTagData,
  ]);
  const handleStoreSeletedData = async () => {
    const param = {
      company: params.companyid,
      plant: params.plantid,
      dashboard: 3,
      module_display_name: activeClassConsumer,

      device: smartTagCOCSelectMachine.length
        ? smartTagCOCSelectMachine.filter((f) => f.plantId === params.plantid)
            .length
          ? smartTagCOCSelectMachine
              .filter((f) => f.plantId === params.plantid)
              .map((row) => ({
                device_id: row.machineId, // Extract id as tag_id
                color: "null", // Extract color
              }))
          : []
        : [],
      tag_unit: smartTagCOCSelectUnit.length
        ? smartTagCOCSelectUnit
            .filter((f) => f.plantId === params.plantid)
            .map((f) => f.tagUnit)
            .filter((tagUnit) => tagUnit !== undefined)
        : [],
      tag: smartTagCOCSelectTag.length
        ? smartTagCOCSelectTag.filter((f) => f.plantId === params.plantid)
            .length
          ? smartTagCOCSelectTag
              .filter((f) => f.plantId === params.plantid)
              .map((row) => ({
                tag_id: row.tagId, // Extract id as tag_id
                color: row.color, // Extract color
              }))
          : []
        : [],

      set_range: rangeCOC,
      interval: LineCOCIntervalTime,
      is_store : smartTagCOCSwitch,
      recipe_name: []
    };
    try {
      const resp = await storeSelectedData(param);
      if (resp.status == 200 || resp.status == 201) {
        console.log("store data successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const handleStoreSeletedDataPOP = async () => {
    const param = {
      company: params.companyid,
      plant: params.plantid,
      dashboard: 3,
      module_display_name: activeClassConsumer,

      device: smartTagPOPSelectMachine.length
        ? smartTagPOPSelectMachine.filter((f) => f.plantId === params.plantid)
            .length
          ? smartTagPOPSelectMachine
              .filter((f) => f.plantId === params.plantid)
              .map((row) => ({
                device_id: row.machineId,
                color: "null",
              }))
          : []
        : [],

      tag_unit: smartTagPOPSelectUnit.length
        ? smartTagPOPSelectUnit
            .filter((f) => f.plantId === params.plantid)
            .map((f) => f.tagUnit)
            .filter((tagUnit) => tagUnit !== undefined)
        : [],
      tag: smartTagPOPSelectTag.length
        ? smartTagPOPSelectTag.filter((f) => f.plantId === params.plantid)
            .length
          ? smartTagPOPSelectTag
              .filter((f) => f.plantId === params.plantid)
              .map((row) => ({
                tag_id: row.tagId, // Extract id as tag_id
                color: row.color, // Extract color
              }))
          : []
        : [],

      set_range: rangePOP,
      interval: LinePOPIntervalTime,
      is_store : smartTagPOPSwitch,
      recipe_name: []
    };
    try {
      const resp = await storeSelectedData(param);
      if (resp.status == 200 || resp.status == 201) {
        console.log("store data successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (!smartTagCOCSwitch) {
      setSmartTagCOCSelectMachine([]);
      setSmartTagCOCSelectTag([]);
      setSmartTagCOCSelectUnit([]);
      setTagListData([]);
      setRangeCOC("");
      setSmartTagCOCStartDate(null);
      setSmartTagCOCEndDate(null);
      // setVmsReportTime(false)
      setLineCOCIntervalTime("1");
      setCocGraphData([]);
      setCocLineChartData([]);
    }

    if (!smartTagPOPSwitch) {
      setSmartTagPOPSelectMachine([]);
      setSmartTagPOPSelectUnit([]);
      setSmartTagPOPSelectTag([]);
      setTagListData1([]);
      setRangePOP("");
      setSmartTagPOPStartDate(null);
      setSmartTagPOPEndDate(null);
      setSmartTagPOP1StartDate(null);
      setSmartTagPOP1EndDate(null);
      // setVmsReportPOPTime(false)
      setLinePOPIntervalTime("1");
      setPopBarGraphData1([])
      setPopBarGraphData2([])
      setPop2LineChartData([])
        setPopLineChartData([])
      

      // setVmsReportPOPStartDate(null)
    }
  }, [smartTagCOCSwitch, smartTagPOPSwitch, activeClassConsumer]);

  useEffect(() => {

    if (activeClassConsumer == "Consumer" ) {
      handleGetStorePreviousSelectedData();
    } else if (!smartTagCOCSwitch) {
      // Reset states when the switch is off
      setSelectedPreviousData([]);
      setTagListData([]);
      setSmartTagCOCSelectMachine([]);
      setSmartTagCOCSelectTag([]);
      setSmartTagCOCSelectUnit([]);
      setRangeCOC("");
      setSmartTagCOCStartDate(null);
      setSmartTagCOCEndDate(null);
      // setVmsReportTime(false)
      setLineCOCIntervalTime("1");
      setCocLineChartData([]);
    }
  }, [ activeClassConsumer]);
  
  useEffect(() => {
    // Ensure API call only happens when the switch is true
    if (activeClassConsumer == "Period" ) {
      handleGetStorePreviousSelectedData();
    } else if (!smartTagPOPSwitch) {
      // Reset states when the switch is off
      setSelectedPreviousData([]);
      setSmartTagPOPSelectMachine([]);
      setSmartTagPOPSelectUnit([]);
      setSmartTagPOPSelectTag([]);
      setTagListData1([]);
      setRangePOP("");
      setSmartTagPOPStartDate(null);
      setSmartTagPOPEndDate(null);
      setSmartTagPOP1StartDate(null);
      setSmartTagPOP1EndDate(null);
      // setVmsReportPOPTime(false)
      setLinePOPIntervalTime("1");
      setPopLineChartData([]);
      setPopBarGraphData1([]);
      setPopBarGraphData2([]);
      setPop2LineChartData([]);
      setSelectPOPDeviceData([])
      setSelectPOPUnitData([])
      setSelectPOPTagData([])
    }
  }, [activeClassConsumer]);
  

  const handleGetStorePreviousSelectedData = async () => {
    const param = {
      company: params.companyid,
      plant: params.plantid,
      dashboard: 3,
      module_display_name: activeClassConsumer,
      device: [],
      tag_unit: [],
      tag: [],
      recipe_name:[],
    };
    try {
      const resp = await getStorePreviousSelectedData(param);
      if (resp.status == 200 || resp.status == 201) {
        setSelectedPreviousData(resp.data.payload);

        //store data COC
        if (activeClassConsumer == "Consumer") {
          //machine data
          const cocDeviceData = resp.data.payload?.device?.map((row) => {
            return {
              machineId: row.id,
              dvName: row.device_name,
              plantId: params.plantid,
              // deviceId: param.deviced,
            };
          });

          setSmartTagCOCSelectMachine(cocDeviceData);

          const cocTagUnitData = resp.data.payload?.tag_unit?.map((row) => {
            return {
              tagUnit: row,
              machineId: "",
              // dvName:"",
              plantId: params.plantid,
              // deviceId: param.deviceId,
            };
          });
          setSmartTagCOCSelectUnit(cocTagUnitData);

          // const newUnit = {
          //   tagUnit: row.tag_unit,
          //   machineId: row.device,
          //   dvName: row.device__device_name,
          //   plantId: param.plantId,
          //   deviceId: param.deviceId,
          // };
          // Add the object to the state array if it doesn't exist

          const cocTagDataData = resp.data.payload?.tag?.map((row) => {
            return {
              tagId: row.id,
              tagUnit: row.tag_unit,
              machineId: row.device_id,
              dvName: row.device_name,
              plantId: params.plantid,
              // deviceId: param.deviceId,
              color: row.color,
            };
          });

          console.log("cocTagDataData", cocTagDataData);
          setSmartTagCOCSelectTag(cocTagDataData);

          const cocRange = resp.data.payload.set_range;
          setRangeCOC(cocRange);

          const IntervalData = resp.data.payload.interval;
          setLineCOCIntervalTime(IntervalData);

          const switchData = resp.data.payload?.is_store
          setSmartTagCOCSwitch(switchData)

        } else if (activeClassConsumer == "Period") {

          console.log("lkdsnklndslnlk")
          const POPDeviceData = resp.data.payload?.device?.map((row) => {
            return {
              machineId: row.id,
              plantId: params.plantid,
              dvName: row.device_name,
            };
          });

          setSmartTagPOPSelectMachine(POPDeviceData);

          const POPTagUnitData = resp.data.payload?.tag_unit?.map((row) => {
            return {
              tagUnit: row,
              machineId: "",
              plantId: params.plantid,
              
            };
          });
          setSmartTagPOPSelectUnit(POPTagUnitData);

          const POPTagDataData = resp.data.payload?.tag?.map((row) => {
            return {
              tagId: row.id,
              tagName: row.tag_name,
              tagUnit: row.tag_unit,
              machineId: row.device_id,
              plantId: params.plantid,
              color: row.color,
            };
          });
          setSmartTagPOPSelectTag(POPTagDataData);

          const POPRange = resp.data.payload.set_range;
          setRangePOP(POPRange);

          const IntervalData = resp.data.payload.interval;
          setLinePOPIntervalTime(IntervalData);

          const switchData = resp.data.payload?.is_store
          setSmartTagPOPSwitch(switchData)
        }
      }
    } catch (error) {
      setSelectedPreviousData([]);
      console.log("handlesubmit", error);
    }
  };
  const getTagListData = async (selectedMachines, selectedTagUnits) => {
    try {
      setisShimmerdevicelistCOC(true);
      const params = {
        device_id: selectedMachines,
        tag_unit: selectedTagUnits,
      };

      const resp = await getTagList(params);

      if (resp.status === 200 || resp.status === 201) {
        setTimeout(() => {
          setisShimmerdevicelistCOC(false);
        }, 1000);

        setTagListData(resp.data.payload);
      }
    } catch (error) {
      // Handle API call error if needed
      setisShimmerdevicelistCOC(false);

      console.error("Error fetching tag list:", error);
    }
  };

  useEffect(() => {
    if (params.deviceId) {
      if (selectTagData?.length > 0 && selectUnitData?.length) {
        getTagListData(
          selectTagData.map((e) => e.machineId),
          selectUnitData.map((e) => e.tagUnit)
        );
      }
    } else {
      const findPlantByReportsSelectmachine = smartTagCOCSelectMachine.length
        ? smartTagCOCSelectMachine
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.machineId)
            .filter((machineId) => machineId !== undefined)
        : [];

      const findPlantByReportsSelectTagUnit = smartTagCOCSelectUnit.length
        ? smartTagCOCSelectUnit
            .filter((f) => f.plantId === params.plantid)
            .map((f) => f.tagUnit)
            .filter((tagUnit) => tagUnit !== undefined)
        : [];

      if (findPlantByReportsSelectmachine.length > 0) {
        getTagListData(
          findPlantByReportsSelectmachine,
          findPlantByReportsSelectTagUnit
        );
      } else {
        // Handle the case when no machines are selected (unselect all).
        // You may want to reset or handle the state accordingly.
        setTagListData([]);
      }
      if (findPlantByReportsSelectmachine.length > 0) {
        handleGetUnitsData(findPlantByReportsSelectmachine);
      } else {
        setUnitsData([]);
      }
    }
  }, [
    smartTagCOCSelectMachine,
    smartTagCOCSelectUnit,
    params.gatewayid,
    selectTagData,
    selectUnitData,
  ]);

  const getTagListData1 = async (selectedMachines, selectedTagUnits) => {
    setisShimmerdevicelistPOP(true);
    try {
      const params = {
        device_id: selectedMachines,
        tag_unit: selectedTagUnits,
      };

      const resp = await getTagList(params);

      if (resp.status === 200 || resp.status === 201) {
        setTimeout(() => {
          setisShimmerdevicelistPOP(false);
        }, 1000);

        setTagListData1(resp.data.payload);
      }
    } catch (error) {
      setisShimmerdevicelistPOP(false);
      // Handle API call error if needed
      console.error("Error fetching tag list:", error);
    }
  };

  useEffect(() => {
    if (params.deviceId) {
      if (selectPOPTagData.length > 0 && selectPOPUnitData.length) {
        getTagListData1(
          selectPOPTagData.map((e) => e.machineId),
          selectPOPUnitData.map((e) => e.tagUnit)
        );
      }
    } else {
      const findPlantByReportsSelectmachine = smartTagPOPSelectMachine?.length
        ? smartTagPOPSelectMachine
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.machineId)
            .filter((machineId) => machineId !== undefined)
        : [];

      const findPlantByReportsSelectTagUnit = smartTagPOPSelectUnit?.length
        ? smartTagPOPSelectUnit
            .filter((f) => f.plantId === params.plantid)
            .map((f) => f.tagUnit)
            .filter((tagUnit) => tagUnit !== undefined)
        : [];

      if (findPlantByReportsSelectmachine.length > 0) {
        getTagListData1(
          findPlantByReportsSelectmachine,
          findPlantByReportsSelectTagUnit
        );
      } else {
        // Handle the case when no machines are selected (unselect all).
        // You may want to reset or handle the state accordingly.
        setTagListData1([]);
      }
      if (findPlantByReportsSelectmachine.length > 0) {
        handleGetUnitsData(findPlantByReportsSelectmachine);
      } else {
        setUnitsData([]);
      }
    }
  }, [
    smartTagPOPSelectMachine,
    smartTagPOPSelectUnit,
    params.gatewayid,
    selectPOPTagData,
    selectPOPUnitData,
  ]);

  useEffect(() => {
    addInteractionData();
  }, [smartTagCOCSelectTag]);

  const addInteractionData = async () => {
    const param = {
      plant: params.plantid,
      tag: smartTagCOCSelectTag?.map((e) => e.tagId),
    };
    try {
      const resp = await tagInteractionData(param);
      if (resp.status == 200 || resp.status == 201) {
        console.log("Interaction tag successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };
  useEffect(() => {
    addInteractionData1();
  }, [smartTagPOPSelectTag]);

  const addInteractionData1 = async () => {
    const param = {
      plant: params.plantid,
      tag: smartTagPOPSelectTag?.map((e) => e.tagId),
    };
    try {
      const resp = await tagInteractionData(param);
      if (resp.status == 200 || resp.status == 201) {
        console.log("Interaction tag successfully");
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const getReportParatoChatAPI = async (
    tagId,
    startDate,
    endDate,
    rangeCOC,
    setState,
    smartTagCOCTime,
    startTime,
    endTime
  ) => {
    const paramReq = {
      company: params.companyid,
      plant: params.plantid,
      tag_id: tagId,
      range_selected: rangeCOC,
      start_date: `${formatStartDate} 00:00:00`,
      end_date: `${formatEndDate} 23:59:59`,
    };

    if (smartTagCOCTime) {
      paramReq["start_date"] = `${formatStartDate} ${formatStartTime}`;
      paramReq["end_date"] = `${formatEndDate} ${formatEndTime}`;
    }
    console.log("paramReq", paramReq);
    try {
      getReportAPIComp(paramReq, setState, setShimmerCOC);
    } catch (error) {
      console.log("errror", error);
    }
  };

  useEffect(() => {
    if (params.deviceId) {
      const findSelectedTag = selectTagData.length
        ? selectTagData
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.tagId)
        : [];

      if (
        findSelectedTag.length &&
        formatStartDate &&
        formatEndDate &&
        rangeCOC != "none" &&
        activeClassConsumer == "Consumer"
      ) {
        getReportParatoChatAPI(
          findSelectedTag,
          formatStartDate,
          formatEndDate,
          rangeCOC,
          setCocGraphData,
          smartTagCOCTime,
          formatStartTime,
          formatEndTime
        );
      } else {
        setCocGraphData([]);
      }
    } else {
      const findSelectedTag = smartTagCOCSelectTag.length
        ? smartTagCOCSelectTag
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.tagId)
        : [];

      if (
        findSelectedTag.length &&
        formatStartDate &&
        formatEndDate &&
        rangeCOC != "none" &&
        activeClassConsumer == "Consumer"
      ) {
        getReportParatoChatAPI(
          findSelectedTag,
          formatStartDate,
          formatEndDate,
          rangeCOC,
          setCocGraphData,
          smartTagCOCTime,
          formatStartTime,
          formatEndTime
        );
      } else {
        setCocGraphData([]);
      }
    }
  }, [
    smartTagCOCSelectTag,
    formatStartDate,
    formatEndDate,
    rangeCOC,
    activeClassConsumer,
    smartTagCOCTime,
    formatStartTime,
    formatEndTime,
    selectTagData,
  ]);

  const getReportParatoChatAPIPOP = async (
    tagId,
    startDate,
    endDate,
    rangValuePOP,
    setState,
    smartTagPOPTime,
    startTime,
    endTime
  ) => {
    const paramReq = {
      company: params.companyid,
      plant: params.plantid,
      tag_id: tagId,
      range_selected: rangValuePOP,
      start_date: `${formatStartDatePOP} 00:00:00`,
      end_date: `${formatEndDatePOP} 23:59:59`,
    };

    if (smartTagPOPTime) {
      paramReq["start_date"] = `${formatStartDatePOP} ${formatStartTimePOP}`;
      paramReq["end_date"] = `${formatEndDatePOP} ${formatEndTimePOP}`;
    }
    try {
      getReportAPIComp(paramReq, setState, setShimmerPOP);
    } catch (error) {
      console.log("errror", error);
    }
  };

  useEffect(() => {
    if (params.deviceId) {
      const findSelectedTag = selectPOPTagData.length
        ? selectPOPTagData
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.tagId)
        : [];

      if (
        findSelectedTag.length &&
        formatStartDatePOP &&
        formatEndDatePOP &&
        rangePOP != "none" &&
        rangeModalPOP == false &&
        activeClassConsumer == "Period"
      ) {
        getReportParatoChatAPIPOP(
          findSelectedTag,
          formatStartDatePOP,
          formatEndDatePOP,
          rangePOP,
          setPopBarGraphData1,
          smartTagPOPTime,
          formatStartTimePOP,
          formatEndTimePOP
        );
      } else {
        setPopBarGraphData1([]);
      }
    } else {
      const findSelectedTag = smartTagPOPSelectTag.length
        ? smartTagPOPSelectTag
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.tagId)
        : [];

      if (
        findSelectedTag.length &&
        formatStartDatePOP &&
        formatEndDatePOP &&
        rangePOP != "none" &&
        rangeModalPOP == false &&
        activeClassConsumer == "Period"
      ) {
        getReportParatoChatAPIPOP(
          findSelectedTag,
          formatStartDatePOP,
          formatEndDatePOP,
          rangePOP,
          setPopBarGraphData1,
          smartTagPOPTime,
          formatStartTimePOP,
          formatEndTimePOP
        );
      } else {
        setPopBarGraphData1([]);
      }
    }
  }, [
    smartTagPOPSelectTag,
    formatStartDatePOP,
    formatEndDatePOP,
    rangeModalPOP,
    activeClassConsumer,
    smartTagPOPTime,
    formatStartTimePOP,
    formatEndTimePOP,
    selectPOPTagData,
  ]);

  const getReportParatoChatAPIPOP1 = async (
    tagId,
    startDate,
    endDate,
    rangValuePOP,
    setState,
    smartTagPOPTime,
    startTime,
    endTime
  ) => {
    const paramReq = {
      company: params.companyid,
      plant: params.plantid,
      tag_id: tagId,
      range_selected: rangValuePOP,
      start_date: `${formatStartDatePOP1} 00:00:00`,
      end_date: `${formatEndDatePOP1} 23:59:59`,
    };

    if (smartTagPOPTime) {
      paramReq["start_date"] = `${formatStartDatePOP1} ${formatStartTimePOP1}`;
      paramReq["end_date"] = `${formatEndDatePOP1} ${formatEndTimePOP1}`;
    }
    try {
      getReportAPIComp(paramReq, setState, setShimmerPOP);
    } catch (error) {
      console.log("errror", error);
    }
  };
  useEffect(() => {
    if (params.deviceId) {
      const findSelectedTag = selectPOPTagData.length
        ? selectPOPTagData
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.tagId)
        : [];

      if (
        findSelectedTag.length &&
        formatStartDatePOP1 &&
        formatEndDatePOP1 &&
        rangePOP != "none" &&
        activeClassConsumer == "Period"
      ) {
        getReportParatoChatAPIPOP1(
          findSelectedTag,
          formatStartDatePOP1,
          formatEndDatePOP1,
          rangePOP,
          setPopBarGraphData2,
          smartTagPOPTime,
          formatStartTimePOP1,
          formatEndTimePOP1
        );
      } else {
        setPopBarGraphData2([]);
      }
    }
    const findSelectedTag = smartTagPOPSelectTag.length
      ? smartTagPOPSelectTag
          .filter((f) => f.plantId === params.plantid)
          .map((row) => row.tagId)
      : [];

    if (
      findSelectedTag.length &&
      formatStartDatePOP1 &&
      formatEndDatePOP1 &&
      rangePOP != "none" &&
      rangeModalPOP == false &&
      activeClassConsumer == "Period"
    ) {
      getReportParatoChatAPIPOP1(
        findSelectedTag,
        formatStartDatePOP1,
        formatEndDatePOP1,
        rangePOP,
        setPopBarGraphData2,
        smartTagPOPTime,
        formatStartTimePOP1,
        formatEndTimePOP1
      );
    } else {
      setPopBarGraphData2([]);
    }
  }, [
    smartTagPOPSelectTag,
    formatStartDatePOP1,
    formatEndDatePOP1,
    rangePOP,
    activeClassConsumer,
    smartTagPOPTime,
    formatStartTimePOP1,
    formatEndTimePOP1,
    selectPOPTagData,
  ]);

  const handleSubmitRange = () => {
    setRangeCOC(rangeCOCValue);
    // dispatch(setSmartTagReportData({ rangeValue: rangeCOCValue }));
    // Reset the state and close the modal after submission
    setRangeModal(false);
  };

  const handleSubmitRangePOP = () => {
    setRangePOP(rangePOPValue);
    // dispatch(setSmartTagReportData({ rangValuePOP: rangePOPValue }));
    // Reset the state and close the modal after submission
    setRangeModalPOP(false);
  };
  // getReportParatoChat API
  const getReportLineChartAPI = async (
    tagId,
    startDate,
    endDate,
    startTime,
    endTime,
    setState,
    timeActive,
    intervalTime,
    activeClass
  ) => {
    const paramReq = {
      company: params.companyid,
      plant: params.plantid,
      tag_id: tagId,
      interval: LineCOCIntervalTime,
      start_date: `${formatStartDate} 00:00:00`,
      end_date: `${formatEndDate} 23:59:59`,
    };

    // if (activeClass == "Reports") {
    //   paramReq["interval"] = Number(intervalTime);
    // } else {
    //   paramReq["interval"] = 1;
    // }

    if (smartTagCOCTime) {
      paramReq["start_date"] = `${formatStartDate} ${formatStartTime}`;
      paramReq["end_date"] = `${formatEndDate} ${formatEndTime}`;
    }
    try {
      getReportLineChartData(paramReq, setState, setShimmerLineChartCOC);
    } catch (error) {
      console.log("errror", error);
    }
  };

  // COC Line Chart
  useEffect(() => {
    if (params.deviceId) {
      const findSelectedTag = selectTagData.length
        ? selectTagData
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.tagId)
        : [];

      if (
        (findSelectedTag.length && formatStartDate,
        formatEndDate && activeClassConsumer == "Consumer")
      ) {
        getReportLineChartAPI(
          findSelectedTag,
          formatStartDate,
          formatEndDate,
          formatStartTime,
          formatEndTime,
          setCocLineChartData,
          LineCOCIntervalTime,

          smartTagCOCTime
        );
      } else {
        setCocLineChartData([]);
      }
    }
    const findSelectedTag = smartTagCOCSelectTag.length
      ? smartTagCOCSelectTag
          .filter((f) => f.plantId === params.plantid)
          .map((row) => row.tagId)
      : [];

    if (
      (findSelectedTag.length && formatStartDate,
      formatEndDate && activeClassConsumer == "Consumer")
    ) {
      getReportLineChartAPI(
        findSelectedTag,
        formatStartDate,
        formatEndDate,
        formatStartTime,
        formatEndTime,
        setCocLineChartData,
        LineCOCIntervalTime,

        smartTagCOCTime
      );
    } else {
      setCocLineChartData([]);
    }
  }, [
    smartTagCOCSwitch,
    smartTagCOCSelectTag,
    formatStartDate,
    formatEndDate,
    formatStartTime,
    formatEndTime,
    LineCOCIntervalTime,
    smartTagCOCTime,
    activeClassConsumer,
    selectDeviceData,
  ]);

  const getReportLineChartAPIPOP = async (
    tagId,
    startDate,
    endDate,
    startTime,
    endTime,
    setState,
    smartTagPOPTime,
    intervalTime,
    activeClass
  ) => {
    const paramReq = {
      company: params.companyid,
      plant: params.plantid,
      tag_id: tagId,
      interval: LinePOPIntervalTime,
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };

    if (smartTagPOPTime) {
      paramReq["start_date"] = `${startDate} ${startTime}`;
      paramReq["end_date"] = `${endDate} ${endTime}`;
    }
    try {
      getReportLineChartData(paramReq, setState, setShimmerLineChartPOP);
    } catch (error) {
      console.log("errror", error);
    }
  };

  // POP Line Chart
  useEffect(() => {
    if (params.deviceId) {
      const findSelectedTag = selectPOPTagData.length
        ? selectPOPTagData
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.tagId)
        : [];

      if (
        findSelectedTag.length &&
        formatStartDatePOP &&
        formatEndDatePOP &&
        activeClassConsumer == "Period"
      ) {
        getReportLineChartAPIPOP(
          findSelectedTag,
          formatStartDatePOP,
          formatEndDatePOP,
          formatStartTimePOP,
          formatEndTimePOP,
          setPopLineChartData,
          smartTagPOPTime,
          LinePOPIntervalTime
        );
      } else {
        setPopLineChartData([]);
      }
    }
    const findSelectedTag = smartTagPOPSelectTag.length
      ? smartTagPOPSelectTag
          .filter((f) => f.plantId === params.plantid)
          .map((row) => row.tagId)
      : [];

    if (
      findSelectedTag.length &&
      formatStartDatePOP &&
      formatEndDatePOP &&
      activeClassConsumer == "Period"
    ) {
      getReportLineChartAPIPOP(
        findSelectedTag,
        formatStartDatePOP,
        formatEndDatePOP,
        formatStartTimePOP,
        formatEndTimePOP,
        setPopLineChartData,
        smartTagPOPTime,
        LinePOPIntervalTime
      );
    } else {
      setPopLineChartData([]);
    }
  }, [
    smartTagPOPSwitch,
    smartTagPOPSelectTag,
    formatStartDatePOP,
    formatEndDatePOP,
    formatStartTimePOP,
    formatEndTimePOP,
    smartTagPOPTime,
    activeClassConsumer,
    LinePOPIntervalTime,
    selectPOPTagData,
  ]);

  useEffect(() => {
    if (params.deviceId) {
      const findSelectedTag = selectPOPTagData.length
        ? selectPOPTagData
            .filter((f) => f.plantId === params.plantid)
            .map((row) => row.tagId)
        : [];

      if (
        findSelectedTag.length &&
        formatStartDatePOP1 &&
        formatEndDatePOP1 &&
        activeClassConsumer == "Period"
      ) {
        getReportLineChartAPIPOP(
          findSelectedTag,
          formatStartDatePOP1,
          formatEndDatePOP1,
          formatStartTimePOP1,
          formatEndTimePOP1,
          setPop2LineChartData,
          smartTagPOPTime,
          LinePOPIntervalTime
        );
      } else {
        setPop2LineChartData([]);
      }
    }
    const findSelectedTag = smartTagPOPSelectTag.length
      ? smartTagPOPSelectTag
          .filter((f) => f.plantId === params.plantid)
          .map((row) => row.tagId)
      : [];

    if (
      findSelectedTag.length &&
      formatStartDatePOP1 &&
      formatEndDatePOP1 &&
      activeClassConsumer == "Period"
    ) {
      getReportLineChartAPIPOP(
        findSelectedTag,
        formatStartDatePOP1,
        formatEndDatePOP1,
        formatStartTimePOP1,
        formatEndTimePOP1,
        setPop2LineChartData,
        smartTagPOPTime,
        LinePOPIntervalTime
      );
    } else {
      setPop2LineChartData([]);
    }
  }, [
    smartTagPOPSwitch,
    smartTagPOPSelectTag,
    formatStartDatePOP1,
    formatEndDatePOP1,
    formatStartTimePOP1,
    formatEndTimePOP1,
    smartTagPOPTime,
    activeClassConsumer,
    LinePOPIntervalTime,
    selectPOPTagData,
  ]);

  const handleGetUnitsData = async (id) => {
    const params = {
      device: id,
    };
    try {
      const resp = await getDeviceUnits(params);
      if (resp.status == 200 || resp.status == 201) {
        setUnitsData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };
  return (
    <>
      <div className="page-wraper">
        <div className="page-header page-header-analytics">
          <div
            className="page-back-btn"
            onClick={() =>
              navigate(
                `/Smarttag-machinedashboard/company/${params.companyid}/plant/${params.plantid}`
              )
            }
          >
            <ArrowBackIcon
              onClick={() =>
                navigate(
                  `/Smarttag-machinedashboard/company/${params.companyid}/plant/${params.plantid}`
                )
              }
            />
            <span>Machine - 1 - Analytics</span>
          </div>
        </div>

        <>
          <div className="analysis-tabs-btn pt-3">
            <div className="analytics-tabs-btn">
              <div className="d-flex">
                <button
                  className={`${
                    activeClassConsumer == "Consumer"
                      ? "Analytics-tabs-overview"
                      : "Reports-tabs-overview"
                  }`}
                  onClick={() =>
                    dispatch(
                      setSmartTagReportData({ activeClassConsumer: "Consumer" })
                    )
                  }
                  style={{ width: "210px", height: "50px" }}
                >
                  Consumer over Consumer
                </button>
                <button
                  className={`${
                    activeClassConsumer == "Period"
                      ? "Analytics-tabs-overview"
                      : "Reports-tabs-overview"
                  }`}
                  onClick={() =>
                    dispatch(
                      setSmartTagReportData({ activeClassConsumer: "Period" })
                    )
                  }
                  style={{ width: "210px", height: "50px" }}
                >
                  Period over Period
                </button>
              </div>
            </div>
            {activeClassConsumer == "Consumer" ? (
              <>
                <div
                  className="analytics-aggregation analytics-datepicker "
                  style={{ justifyContent: "flex-end" }}
                >
                  <button
                    className="set-range-popup"
                    onClick={() => {
                      setRangeCOCValue(rangeCOC);
                      setRangeModal(true);
                    }}
                  >
                    {rangeCOC ? rangeCOC : "Set Range"}
                  </button>
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      value="top"
                      control={
                        <Switch color="primary" checked={smartTagCOCTime} />
                      }
                      label="Time"
                      labelPlacement="top"
                      onChange={handleToggleTime}
                    />
                  </FormGroup>

                  <COCVmsDatePicker
                    time={smartTagCOCTime}
                    startDateReFormat={startDateReFormat}
                    endDateReFormat={endDateReFormat}
                    handleStartDate={handleStartDate}
                    handleEndDate={handleEndDate}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className="analytics-aggregation analytics-datepicker analyticsAgg-datePicker"
                  // style={{ justifyContent: "flex-end" }}
                >
                  <button
                    className="set-range-popup"
                    style={{ width: "9%", marginRight: "14px" }}
                    onClick={() => {
                      setRangePOPValue(rangePOP);
                      setRangeModalPOP(true);
                    }}
                  >
                    {rangePOP ? rangePOP : "Set Range"}
                  </button>

                  <div className="timeToggle">
                    <h6 style={{ marginBottom: "0px" }}>Time</h6>
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={
                          <Switch color="primary" checked={smartTagPOPTime} />
                        }
                        // label="Time"
                        labelPlacement="top"
                        onChange={handlePOPToggleTime}
                        style={{ margin: "0px" }}
                      />
                    </FormGroup>
                  </div>

                  <POPVmsDatePicker
                    time={smartTagPOPTime}
                    startDateReFormat={startDateRePOPFormat}
                    endDateReFormat={endDateRePOPFormat}
                    startDate1ReFormat={startDateRePOP1Format}
                    endDate1ReFormat={endDateRePOP1Format}
                    handleStartDate={handlePOPStartDate}
                    handleEndDate={handlePOPEndDate}
                    handlePOP1StartDate={handlePOP1StartDate}
                    handlePOP1EndDate={handlePOP1EndDate}
                  />
                </div>
              </>
            )}
            {rangeModal && (
              <Card className="addDeviceModal-card" style={{ width: "440px" }}>
                <Box className="addDeviceModal-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addDeviceModal-title"
                  >
                    Set Range
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={() => setRangeModal(false)}
                  />
                </Box>

                <Box className="addDeviceModal-innerWrap">
                  <Stack spacing={2} pt="10px" className="pt-4">
                    <TextField
                      id="filled-basic"
                      name="group_name"
                      label="Enter range"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      type="number" // Set the type to "number" to allow decimal input
                      onChange={(e) => setRangeCOCValue(e.target.value)}
                      value={rangeCOCValue ? rangeCOCValue : null}
                    />
                  </Stack>
                  <Box className="accountSecurity-cardButtons deviceManagement">
                    <button
                      className="addDeviceBtn"
                      onClick={handleSubmitRange}
                    >
                      Submit
                    </button>
                  </Box>
                </Box>
              </Card>
            )}
            {rangeModalPOP && (
              <Card className="addDeviceModal-card" style={{ width: "440px" }}>
                <Box className="addDeviceModal-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addDeviceModal-title"
                  >
                    Set Range
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={() => setRangeModalPOP(false)}
                  />
                </Box>

                <Box className="addDeviceModal-innerWrap">
                  <Stack spacing={2} pt="10px" className="pt-4">
                    <TextField
                      id="filled-basic"
                      name="group_name"
                      label="Enter range"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      type="number" // Set the type to "number" to allow decimal input
                      onChange={(e) => setRangePOPValue(e.target.value)}
                      value={rangePOPValue ? rangePOPValue : null}
                    />
                  </Stack>
                  <Box className="accountSecurity-cardButtons deviceManagement">
                    <button
                      className="addDeviceBtn"
                      onClick={handleSubmitRangePOP}
                    >
                      Submit
                    </button>
                  </Box>
                </Box>
              </Card>
            )}
          </div>
        </>
        <div className="pt-2 consumption-grid-card">
          {activeClassConsumer == "Consumer" ? (
            <SmartTagList
              time={smartTagCOCTime}
              formatStartDate={formatStartDate}
              formatEndDate={formatEndDate}
              formatStartTime={formatStartTime}
              formatEndTime={formatEndTime}
              range={rangeCOC}
              machines={Machines}
              units={unitsData}
              handleSelectMachine={handleSelectMachine}
              handleSelectTagUnit={handleSelectReportsTagUnit}
              handleSelectTag={handleSelectReportTag}
              tagListData={tagListData}
              isShimmerdevicelist={isShimmerdevicelistCOC}
              isShimmerUnitlist={isShimmerUnitlistCOC}
              isShimmerMachineslist={isShimmerMachineslistCOC}
              smartTagSelectmachine={
                params.deviceId
                  ? selectDeviceData
                  : smartTagCOCSelectMachine.length
                  ? smartTagCOCSelectMachine.filter(
                      (f) => f.plantId === params.plantid
                    )
                  : []
              }
              smartTagSelectTagUnit={
                params.deviceId
                  ? selectUnitData
                  : smartTagCOCSelectUnit.length
                  ? smartTagCOCSelectUnit.filter(
                      (f) => f.plantId === params.plantid
                    )
                  : []
              }
              snartTagSelectTag={
                params.deviceId
                  ? selectTagData
                  : smartTagCOCSelectTag.length
                  ? smartTagCOCSelectTag.filter(
                      (f) => f.plantId === params.plantid
                    )
                  : []
              }
              SmartTagSelectSwitch={smartTagCOCSwitch}
              handleToggleTime={() => setSmartTagCOCSwitch(!smartTagCOCSwitch)
                
              }
              activeTabClass={activeClassConsumer}
            />
          ) : (
            <SmartTagList
              time={smartTagPOPTime}
              formatStartDate={formatStartDatePOP}
              formatEndDate={formatEndDatePOP}
              formatStartTime={formatStartTimePOP}
              formatEndTime={formatEndTimePOP}
              range={rangePOP}
              machines={Machines}
              units={unitsData}
              handleSelectMachine={handlePOPSelectMachine}
              handleSelectTagUnit={handlePOPSelectTagUnit}
              handleSelectTag={handlePOPSelectTag}
              tagListData={tagListData1}
              isShimmerdevicelist={isShimmerdevicelistPOP}
              isShimmerUnitlist={isShimmerUnitlistPOP}
              isShimmerMachineslist={isShimmerMachineslistPOP}
              smartTagSelectmachine={
                params.deviceId
                  ? selectPOPDeviceData
                  : smartTagPOPSelectMachine.length
                  ? smartTagPOPSelectMachine.filter(
                      (f) => f.plantId === params.plantid
                    )
                  : []
              }
              smartTagSelectTagUnit={
                params.deviceId
                  ? selectPOPUnitData
                  : smartTagPOPSelectUnit.length
                  ? smartTagPOPSelectUnit.filter(
                      (f) => f.plantId === params.plantid
                    )
                  : []
              }
              snartTagSelectTag={
                params.deviceId
                  ? selectPOPTagData
                  : smartTagPOPSelectTag.length
                  ? smartTagPOPSelectTag.filter(
                      (f) => f.plantId === params.plantid
                    )
                  : []
              }
              selectPOPUnitData={selectPOPUnitData}
              formatStartDatePOP1={formatStartDatePOP1}
              formatEndDatePOP1={formatEndDatePOP1}
              formatStartTimePOP1={formatStartTimePOP1}
              formatEndTimePOP1={formatEndTimePOP1}
              SmartTagSelectSwitch={smartTagPOPSwitch}
              handleToggleTime={() => setSmartTagPOPSwitch(!smartTagPOPSwitch)
                // dispatch(
                //   setSmartTagReportData({
                //     smartTagPOPSwitch: !smartTagPOPSwitch,
                //   })
                // )
              }
              activeTabClass={activeClassConsumer}
            />
          )}

          <GraphComponent
            activeTabClass={activeClassConsumer}
            Graphdata={cocGraphdata}
            LineGraphData={cocLineChartdata}
            COCIntervalTime={COCIntervalTime}
            POPIntervalTimePop={POPIntervalTimePop}
            tagID={
              params.deviceId
                ? selectTagData
                : smartTagCOCSelectTag.length
                ? smartTagCOCSelectTag.filter(
                    (f) => f.plantId === params.plantid
                  )
                : []
            }
            tagSelectPOP={
              smartTagPOPSelectTag.length
                ? smartTagPOPSelectTag.filter(
                    (f) => f.plantId === params.plantid
                  )
                : []
            }
            averageArray={averageCOCLineArray}
            averagePOP={averagePOPLineArray}
            BarGraphData1={popBarGraphData1}
            BarGraphData2={popBarGraphData2}
            VmsReportSelectmachine={
              smartTagPOPSelectMachine.length
                ? smartTagPOPSelectMachine.filter(
                    (f) => f.plantId === params.plantid
                  )
                : []
            }
            VmsReportSelectmachine1={
              smartTagCOCSelectMachine.length
                ? smartTagCOCSelectMachine.filter(
                    (f) => f.plantId === params.plantid
                  )
                : []
            }
            LineChartdata={popLineChartdata}
            LineChartdata2={pop2LineChartdata}
            isShimmerCOC={isShimmerCOC}
            isShimmerPOP={isShimmerPOP}
            isShimmerLineChartPOP={isShimmerLineChartPOP}
            isShimmerLineChartCOC={isShimmerLineChartCOC}
            setLineCOCIntervalTime={setLineCOCIntervalTime}
            setLinePOPIntervalTime={setLinePOPIntervalTime}
            LineCOCIntervalTime={LineCOCIntervalTime}
            LinePOPIntervalTime={LinePOPIntervalTime}
            smartTagCOCSwitch={smartTagCOCSwitch}
            smartTagPOPSwitch={smartTagPOPSwitch}
          />
        </div>
      </div>
    </>
  );
};
export default MachineAnalytics;
