import React from "react";

const PrivacyPolicy = () => {
  const handleTermsClick = () => {
    window.open("https://hikartech.com/terms-and-conditions/", "_blank");
  };

  const handlePrivacyClick = () => {
    window.open("https://hikartech.com/privacy-policy/", "_blank");
  };
  const handleRefundClick = () => {
    window.open("https://hikartech.com/refund-policy/", "_blank");
  };
  const handleCookiesClick = () => {
    window.open("https://hikartech.com/cookie-policy/", "_blank");
  };
  const handleTrademarkClick = () => {
    window.open("https://hikartech.com/trademark-policy/", "_blank");
  };
  return (
    <div className="footer-content">
      <div className="signIn-Footer mb-3">
        <div>
          <h6 onClick={handleTermsClick} style={{ cursor: "pointer" }}>
            Terms & Conditions
          </h6>
        </div>
        <div>
          <h6 onClick={handlePrivacyClick} style={{ cursor: "pointer" }}>
            Privacy
          </h6>
        </div>
        <div>
          <h6 onClick={handleRefundClick} style={{ cursor: "pointer" }}>
            Refund Policy
          </h6>
        </div>
        <div>
          <h6 onClick={handleCookiesClick} style={{ cursor: "pointer" }}>Cookies</h6>
        </div>
        <div>
          <h6 onClick={handleTrademarkClick} style={{ cursor: "pointer" }}>Trademarks</h6>
        </div>
      </div>
      <p>
        Powered by <br />
        <b>Hikar&#174;Technomation</b> Private Limited &#169; All Rights
        Reserved
      </p>
    </div>
  );
};

export default PrivacyPolicy;
