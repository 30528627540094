
import { request } from "../../../api/api";

export const getDeviceConf = (params) => {
    return request.post("/dashboard/getDeviceConfig/", params);
};

export const getPlantDeviceTag = (params) => {
    return request.post("/dashboard/getPlantDeviceTag/", params);
};

export const exportConsumptionReportExcelApi = (params) => {
    return request.post("/dashboard/exportConsumptionReportExcel/", params);
};

export const exportSourceReportExcelApi = (params) => {
    return request.post("/dashboard/exportSourceReportExcel/", params);
};

export const getConsumptionDeviceData = (params) =>{
    return request.post(`/device/getDeviceData/`,params)
}