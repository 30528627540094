import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import "../Alarm/Alarm.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchIcon from "@mui/icons-material/Search";
import "../ServiceCall/ServiceCall.css";
import { images } from "../../../config/images";
import { Box, Switch, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {
  Card,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    maxHeight: 400,
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "#fff",
    fontSize: 16,
  },
  tableContainer: {
    maxHeight: 700,
  },
}));

const ServiceCall = () => {
  const [serviceCallPopup, setServiceCallPopup] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const classes = useStyles();
  const navigate = useNavigate();

  const handleSearchData = () => {
  };

  const handleServiceCallClick = () => {
    setServiceCallPopup(true);
  };

  const handleCloseClick = () =>{
    setServiceCallPopup(false)
  }
  const handleCancleClick =()=>{
    setServiceCallPopup(false)
  }
  const handleSubmitClick = () =>{
    setServiceCallPopup(false)
  }
  const param = useParams()
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <>
      <div className="page-wraper">
        <div className="page-header d-flex justify-content-between">
          <div
            onClick={() => navigate(`/company/${param.companyId}/plant/plantprocessdashboard/${param.id}`)}
            className="page-back-btn"
          >
            <ArrowBackIcon />
            <span>Plant 1- Service Call</span>
          </div>
          <div
            className="header-btn-group d-flex analytics-aggregation analytics-datepicker"
            style={{ justifyContent: "flex-end" }}
          >
            <button
              className="service-call-btn"
              onClick={handleServiceCallClick}
            >
              <img
                src={images.callImg}
                style={{ height: "20px", width: "20px", marginTop: "-1px" }}
              />{" "}
              Raise Service Call
            </button>

            <Box
              className=""
              style={{ width: "22%", marginRight: "0px" }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="custom-datepicker"
                  label="Start Date"
                  value={startDate}
                  inputFormat="DD/MM/YYYY"
                 
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} style={{ background: "#ffff" }} />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box className="" style={{ width: "22%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="custom-datepicker"
                  label="End Date"
                  value={endDate}
                  inputFormat="DD/MM/YYYY"
                 
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} style={{ background: "#ffff" }} />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <img src={images.excelLogo} style={{ height: "55px" }} />
          </div>
        </div>

        <div className="pt-4 pb-4">
          <TableContainer className={classes.tableContainer}>
            <Table className="alarm-table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.stickyHeader}>Date</TableCell>
                  <TableCell className={classes.stickyHeader}>Time</TableCell>
                  <TableCell className={classes.stickyHeader}>
                    Plant Name
                  </TableCell>
                  <TableCell className={classes.stickyHeader}>
                    Initiated By
                  </TableCell>
                  <TableCell className={classes.stickyHeader}>
                    Remarks
                  </TableCell>
                  <TableCell className={classes.stickyHeader}>
                    Response
                  </TableCell>
                  <TableCell className={classes.stickyHeader}>Action</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Date"
                      name="Date"
                      onChange={handleSearchData}
                    />
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Time"
                      name="Date"
                      onChange={handleSearchData}
                    />
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Plant Name"
                      name="Plant Name"
                      onChange={handleSearchData}
                    />
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Intiated By"
                      name="Date"
                      onChange={handleSearchData}
                    />
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Remarks"
                      name="Date"
                      onChange={handleSearchData}
                    />
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Response"
                      name="Date"
                      onChange={handleSearchData}
                    />
                  </TableCell>
                  <TableCell
                    className="search-table-row"
                    style={{ background: "#F1F1FF" }}
                  >
                    <SearchIcon className="search-icon" />
                    <input
                      type="search"
                      placeholder="Action"
                      name="Date"
                      onChange={handleSearchData}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>Plant 1</TableCell>
                  <TableCell>Shreekar Yadav</TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <select className="selection-action-dropdown">
                      <option disabled selected hidden>
                        Select Action
                      </option>
                      <option>Open</option>
                      <option>In Progress</option>
                      <option>Close</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>09:30</TableCell>
                  <TableCell>Plant 2</TableCell>
                  <TableCell>Parth Sarthi</TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <select className="selection-action-dropdown">
                      <option disabled selected hidden>
                        Select Action
                      </option>
                      <option>Open</option>
                      <option>In Progress</option>
                      <option>Close</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>Plant 3</TableCell>
                  <TableCell>Shreekar Yadav</TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <select className="selection-action-dropdown">
                      <option disabled selected hidden>
                        Select Action
                      </option>
                      <option>Open</option>
                      <option>In Progress</option>
                      <option>Close</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>09:30</TableCell>
                  <TableCell>Plant 4</TableCell>
                  <TableCell>Parth Sarthi</TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <select className="selection-action-dropdown">
                      <option disabled selected hidden>
                        Select Action
                      </option>
                      <option>Open</option>
                      <option>In Progress</option>
                      <option>Close</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>Plant 5</TableCell>
                  <TableCell>Shreekar Yadav</TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <select className="selection-action-dropdown">
                      <option disabled selected hidden>
                        Select Action
                      </option>
                      <option>Open</option>
                      <option>In Progress</option>
                      <option>Close</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>09:30</TableCell>
                  <TableCell>Plant 6</TableCell>
                  <TableCell>Parth Sarthi</TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <select className="selection-action-dropdown">
                      <option disabled selected hidden>
                        Select Action
                      </option>
                      <option>Open</option>
                      <option>In Progress</option>
                      <option>Close</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>Plant 5</TableCell>
                  <TableCell>Shreekar Yadav</TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <select className="selection-action-dropdown">
                      <option disabled selected hidden>
                        Select Action
                      </option>
                      <option>Open</option>
                      <option>In Progress</option>
                      <option>Close</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>09:30</TableCell>
                  <TableCell>Plant 6</TableCell>
                  <TableCell>Parth Sarthi</TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <select className="selection-action-dropdown">
                      <option disabled selected hidden>
                        Select Action
                      </option>
                      <option>Open</option>
                      <option>In Progress</option>
                      <option>Close</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>Plant 5</TableCell>
                  <TableCell>Shreekar Yadav</TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <select className="selection-action-dropdown">
                      <option disabled selected hidden>
                        Select Action
                      </option>
                      <option>Open</option>
                      <option>In Progress</option>
                      <option>Close</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>Plant 5</TableCell>
                  <TableCell>Shreekar Yadav</TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <select className="selection-action-dropdown">
                      <option disabled selected hidden>
                        Select Action
                      </option>
                      <option>Open</option>
                      <option>In Progress</option>
                      <option>Close</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>Plant 5</TableCell>
                  <TableCell>Shreekar Yadav</TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <select className="selection-action-dropdown">
                      <option disabled selected hidden>
                        Select Action
                      </option>
                      <option>Open</option>
                      <option>In Progress</option>
                      <option>Close</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>Plant 5</TableCell>
                  <TableCell>Shreekar Yadav</TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <select className="selection-action-dropdown">
                      <option disabled selected hidden>
                        Select Action
                      </option>
                      <option>Open</option>
                      <option>In Progress</option>
                      <option>Close</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>Plant 5</TableCell>
                  <TableCell>Shreekar Yadav</TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <select className="selection-action-dropdown">
                      <option disabled selected hidden>
                        Select Action
                      </option>
                      <option>Open</option>
                      <option>In Progress</option>
                      <option>Close</option>
                    </select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>01/12/2023</TableCell>
                  <TableCell>12:30</TableCell>
                  <TableCell>Plant 5</TableCell>
                  <TableCell>Shreekar Yadav</TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <img src={images.document} />
                  </TableCell>
                  <TableCell>
                    <select className="selection-action-dropdown">
                      <option disabled selected hidden>
                        Select Action
                      </option>
                      <option>Open</option>
                      <option>In Progress</option>
                      <option>Close</option>
                    </select>
                  </TableCell>
                </TableRow>
                {serviceCallPopup && (
                  <>
                    <div className="serviceCallData">
                      <Card className="serviceCallCard">
                        <div style={{float:"right",cursor:"pointer"}} onClick={handleCloseClick}>
                        <img src={images.closeIcon} style={{marginTop:"-25px",marginRight:"-13px"}}/>
                        </div>
                    
                        <h6 className="mt-4">Company - 1</h6>

                        <div>
                          <select className="selection-action-dropdown-1">
                            <option disabled selected hidden>
                              Select Plant
                            </option>
                            <option>Plant 1</option>
                            <option>Plant 2</option>
                            <option>Plant 3</option>
                            <option>Plant 4</option>
                            <option>Plant 5</option>
                            <option>Plant 6</option>
                          </select>
                        </div>
                        <div className="mt-2">
                          <textarea
                            rows={10}
                            style={{ width: "100%", borderRadius: "6px",padding:"8px",fontSize:"14px"}}
                            placeholder="Provide remarks"
                            placeholderTextColor="#AEBCD2"
                          ></textarea>
                        </div>
                        <div className="serviceCall-btn mt-2">
                          <button className="cancleButton" onClick={handleCancleClick}>Cancel</button>
                          <button className="submitButton" onClick={handleSubmitClick}>Submit</button>
                        </div>
                      </Card>
                    </div>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};
export default ServiceCall;
