/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./HikemmServices.css";
import { ValidatorForm } from "react-material-ui-form-validator";
import { images } from "../../../../config";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  contactUsDetail,
  getCotationPlan,
  getPurchaseDevice,
  getSiteConfigData,
} from "../../service";
import { getChoices, saveForLater } from "../service";
import { RedirectAdminDashboard } from "../../../../components/CommonFunction/RedirectAdminDashboard";

function HikemmServices() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [usersValue, setUsersvalue] = React.useState(3);
  const [contactModal, setContactModal] = useState(false);
  const [totalSubscription, setTotalSubscription] = useState(0);

  const initiallValue = {
    first_name: "",
    last_name: "",
    company_name: "",
    phone_number: "",
    email_id: "",
    subscription: "",
  };

  const [addContactData, setContactData] = useState(initiallValue);
  const [purchaseDeviceData, setPurchaseDeviceData] = useState([]);
  const [cotationPlan, setCotationPlan] = useState({});
  const [activeClass, setActiveClass] = useState("Monthly");
  const [choice, setChoice] = useState([]);
  const [choiceId, setChoiceId] = useState("");
  const [choiceName, setChoiceName] = useState("");
  const [topTitle, setTopTitle] = useState("");

  const [totalCount, setTotalCount] = useState(0);

  const minValue = 0;
  const maxValue = topTitle; // Adjust this as needed
  const selectedRange = `${value}`;
  const maxLabel = topTitle; // The maximum value for labels
  const step = 10; // The step size for labels

  const labels = Array.from(
    { length: maxLabel / step + 1 },
    (_, index) => index * step
  );

  const marks = labels.map((label) => ({
    value: label,
    label: label < maxLabel ? label.toString() : `${maxLabel}+`,
  }));

  const param = useParams();

  //Notification Error message
  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  useEffect(() => {
    handleGetPurchaseDevice();
  }, []);

  useEffect(() => {
    handleRangeSelect();
  }, [value, activeClass, choiceId]);

  const handleChangeAddDetail = (e) => {
    setContactData({ ...addContactData, [e.target.name]: e.target.value });
  };

  const {
    id,
    companyId,
    companyName,
    companyDescription,
    is_super_admin,
    user_access,
  } = useSelector((state) => state.userState);

  const handleChangeDevice = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeUsers = () => {
    setUsersvalue();
  };

  const handleOpenContactUs = () => {
    setContactModal(true);
  };

  const handleOpenContactCloseModel = () => {
    setContactModal(false);
  };

  const handleSubmit = async () => {
    if (addContactData.first_name === "") {
      notifyError("Please Enter First Name.");
      return;
    }
    if (addContactData.last_name === "") {
      notifyError("Please Enter Last Name.");
      return;
    }
    if (addContactData.company_name === "") {
      notifyError("Please Enter Company Name.");
      return;
    }
    if (addContactData.phone_number === "") {
      notifyError("Please Enter Phone Number.");
      return;
    }
    if (addContactData.email_id === "") {
      notifyError("Please Enter Email Id.");
      return;
    }
    if (addContactData.subscription === "") {
      notifyError("Please Enter Subscription.");
      return;
    }
    const params = {
      company: companyId,
      first_name: addContactData.first_name,
      last_name: addContactData.last_name,
      company_name: addContactData.company_name,
      mobile_number: addContactData.phone_number,
      email: addContactData.email_id,
      detail: addContactData.subscription,
      dashboard: param.id,
      user: id,
    };
    try {
      const resp = await contactUsDetail(params);
      if (resp.status == 200 || resp.status == 201) {
        notifySuccess(resp.data.message);
        setContactModal(false);
        setContactData([]);
      }
    } catch (error) {
      notifyError(error.response.data.message);
      console.log("handleSubmit", error);
    }
  };

  const handleGetPurchaseDevice = async () => {
    const params = {
      dashboard: param.id,
    };
    try {
      const resp = await getPurchaseDevice(params);
      if (resp.status == 200 || resp.status == 201) {
        setPurchaseDeviceData(resp.data.payload[0]);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };
  const handledecrement = () => {
    if (value !== 0) {
      setValue(value - 1);
    } else {
      setValue(0);
    }
  };

  const handleRangeSelect = async () => {
    const params = {
      device_range: selectedRange,
      dashboard: param.id,
      payment_period: choiceId,
    };
    try {
      const resp = await getCotationPlan(params);
      if (resp.status == 200 || resp.status == 201) {
        setCotationPlan(resp.data.payload);
      }
    } catch (error) {
      console.log("handleSubmit", error);
      setCotationPlan({});
      setTotalSubscription(0);
    }
  };

  useEffect(() => {
    handleSiteConfig();
  }, []);

  const handleSiteConfig = async () => {
    let site_config_key;
    if (param.title === "Hikemm") {
      site_config_key = "HikEMM_Purchase_Device_Range";
    } else {
      site_config_key = "PredHik_Purchase_Device_Range";
    }

    const params = {
      site_config_key: site_config_key,
    };

    try {
      const resp = await getSiteConfigData(params);
      if (resp.status === 200 || resp.status === 201) {
        setTopTitle(resp.data.payload.description);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  const displayValue = value <= maxLabel ? value : `${maxLabel}+`;

  // saveForLater API

  const handleSaveForLater = async () => {
    if (getFormattedCount(activeClass) <= 0) {
      notifyError("Invalid amount! Minimum amount more than zero");
      return true;
    }

    const selectedPlan = cotationPlan.find(
      (plan) => plan.payment_period === choiceName
    );

    try {
      const paramReq = {
        payment_period: choiceId,
        user_device_start: 0,
        user_device_end: value,
        total_price: getFormattedCount(activeClass),
        total_device: value,
        dashboard: param.id,
        price_per_device: selectedPlan.per_device_price,
        is_active: false,
      };

      const resp = await saveForLater(paramReq);

      if (resp.status == 200 || resp.status == 201) {
        navigate(`/payment/${param.title}/${param.id}`);
      }
    } catch (error) {
      handleSubmit("error", error);
    }
  };

  useEffect(() => {
    handleChoice();
  }, []);

  const handleChoice = async () => {
    const resp = await getChoices();
    setChoice(resp.data.payload.term_choices);
    setChoiceId(
      resp.data.payload.term_choices.find((f) => f.name == "Monthly").id
    );
    setChoiceName(
      resp.data.payload.term_choices.find((f) => f.name == "Monthly").name
    );
  };

  const getFormattedPrice = (paymentPeriod) => {
    if (!Array.isArray(cotationPlan)) {
      return "";
    }

    const plan = cotationPlan.find(
      (plan) => plan.payment_period === paymentPeriod
    );
    return plan ? `${plan.currency_symbole}${plan.per_device_price}` : 0;
  };

  const getFormattedCount = (paymentPeriod) => {
    if (!Array.isArray(cotationPlan)) {
      return "0.00";
    }

    // Find the cotation plan corresponding to the given payment period
    const plan = cotationPlan.find((p) => p.payment_period === paymentPeriod);

    if (!plan) {
      console.error(
        "No cotation plan found for payment period:",
        paymentPeriod
      );
      return "0.00";
    }

    // Extract the per_device_price from the found plan
    const perDevicePrice = plan.per_device_price;

    // Check if perDevicePrice is a valid number
    if (typeof perDevicePrice !== "number" || isNaN(perDevicePrice)) {
      console.error("Invalid per_device_price:", perDevicePrice);
      return "0.00";
    }

    // Calculate the total subscription count
    const count = (perDevicePrice * value).toFixed(2);

    // Check if count is NaN
    if (isNaN(count)) {
      console.error("Calculation resulted in NaN:", count);
      return "0.00";
    }

    return count;
  };

  const getCurrencySymbol = (paymentPeriod) => {
    if (!Array.isArray(cotationPlan)) {
      console.error("cotationPlan is not an array:", cotationPlan);
      return "";
    }
    const plan = cotationPlan.find(
      (plan) => plan.payment_period === paymentPeriod
    );
    return plan ? plan.currency_symbole : "";
  };

  return (
    <>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="subscriptionTitle-wrap d-flex justify-content-between">
            <div
              onClick={() => navigate("/subscriptionManagement")}
              className="d-flex"
            >
              <KeyboardBackspaceIcon className="backArrow" />
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  marginTop: "-4px",
                  color: "#001323",
                  fontWeight: "600",
                  cursor: "pointer",
                  paddingLeft: "10px",
                  marginBottom: 0,
                }}
              >
                {param.title} Services
              </Typography>
            </div>

            <button
              className="subscription-btn"
              onClick={() =>
                navigate(`/subscription/${param.title}/${param.id}`)
              }
            >
              My Subscription
            </button>
          </div>

          <div>
            <h6 style={{ fontWeight: "400px" }}>
              {purchaseDeviceData ? purchaseDeviceData.title_two : ""}
            </h6>
          </div>
        </Container>
        <Container fluid className="abc">
          {/* =============== Device =============== */}

          <Card className="hikemmDeviceModal-card">
            <Box className="hikemmDeviceModal-titleWrap">
              <Typography
                variant="h5"
                gutterBottom
                className="hikemmDeviceModal-title"
              >
                {purchaseDeviceData ? purchaseDeviceData.title : ""}
              </Typography>

              <div className="data-storage-year">
                {purchaseDeviceData ? purchaseDeviceData.storage : ""}
              </div>
            </Box>
            <Box className="hikemmDeviceModal-innercard">
              <Box className="hikemmDeviceModal-headerWrap">
                <Typography
                  variant="h5"
                  gutterBottom
                  className="hikemmDeviceModal-headerText"
                >
                  {purchaseDeviceData ? purchaseDeviceData.header : ""}
                </Typography>
              </Box>
              <Typography
                variant="h5"
                gutterBottom
                className="hikemmDeviceModal-innerWrapText mb-0"
              >
                {purchaseDeviceData ? purchaseDeviceData.name : ""}
              </Typography>
              <Box className="hikemmDeviceModal-innerWrap">
                <Stack
                  spacing={2}
                  direction="row"
                  className="hikemmDeviceModal-innerButtonwrap"
                >
                  <button className="decrementButton" onClick={handledecrement}>
                    -
                  </button>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ fontWeight: 400 }}
                  >
                    {displayValue}
                  </Typography>
                  <button
                    className="decrementButton"
                    onClick={() => setValue(value + 1)}
                  >
                    +
                  </button>
                </Stack>
                <Box>
                  <Slider
                    aria-label="Volume"
                    value={value}
                    min={minValue}
                    max={maxValue}
                    marks={marks}
                    onChange={(e) => handleChangeDevice(e, e.target.value)}
                    className="hikemmDeviceModal-range"
                  />

                  <Box>
                    <Typography
                      variant="h5"
                      gutterBottom
                      className="hikemmDeviceModal-innerText mt-3"
                    >
                      {purchaseDeviceData ? purchaseDeviceData.detail : ""}
                    </Typography>
                  </Box>
                </Box>
                {value <= maxLabel && (
                  <Box className="hikemmDeviceModal-billedBoxwrap">
                    <Box
                      className={
                        activeClass == "Monthly"
                          ? "hikemmDeviceModal-billedBox2"
                          : "hikemmDeviceModal-billedBox1"
                      }
                      onClick={(e) => {
                        setActiveClass("Monthly");
                        setChoiceId(
                          choice.length
                            ? choice.find((f) => f.name == "Monthly").id
                            : ""
                        );
                        setChoiceName(
                          choice.length
                            ? choice.find((f) => f.name == "Monthly").name
                            : ""
                        );
                      }}
                    >
                      <Typography
                        variant="body2"
                        gutterBottom
                        className="hikemmDeviceModal-billedBoxTitle1"
                      >
                        Billed{" "}
                        {choice.length
                          ? choice.find((f) => f.name == "Monthly").name
                          : ""}
                      </Typography>
                      <Box className="hikemmDeviceModal-billedinnerBox1">
                        <Box>
                          <Typography variant="h6" gutterBottom>
                            {getFormattedPrice("Monthly")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2" gutterBottom>
                            per connection
                            <br /> per month
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="hikemmDeviceModal-billedinnerBoxtext1">
                        <Typography variant="body2" gutterBottom>
                          Total{" "}
                          <b>
                            {getCurrencySymbol("Monthly")}
                            {getFormattedCount("Monthly")}
                          </b>
                          per month
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      className={
                        activeClass == "Yearly"
                          ? "hikemmDeviceModal-billedBox2"
                          : "hikemmDeviceModal-billedBox1"
                      }
                      onClick={(e) => {
                        setActiveClass("Yearly");
                        setChoiceId(
                          choice.length
                            ? choice.find((f) => f.name == "Yearly").id
                            : ""
                        );
                        setChoiceName(
                          choice.length
                            ? choice.find((f) => f.name == "Yearly").name
                            : ""
                        );
                      }}
                    >
                      <Typography
                        variant="body2"
                        gutterBottom
                        className="hikemmDeviceModal-billedBoxTitle2"
                      >
                        Billed{" "}
                        {choice.length
                          ? choice.find((f) => f.name == "Yearly").name
                          : ""}
                      </Typography>
                      <Box className="hikemmDeviceModal-billedinnerBox2">
                        <Box>
                          <Typography variant="h6" gutterBottom>
                            {getFormattedPrice("Yearly")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2" gutterBottom>
                            per connection
                            <br /> per year
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="hikemmDeviceModal-billedinnerBoxtext2">
                        <Typography variant="body2" gutterBottom>
                          Total{" "}
                          <b>
                            {getCurrencySymbol("Yearly")}
                            {getFormattedCount("Yearly")}
                          </b>{" "}
                          per year
                        </Typography>
                      </Box>
                      {/* <div className={activeClass == "Yearly" ? 'hikemmDeviceModal-billedBox2Tag' : ''}>
                        <Typography
                          variant="body2"
                          gutterBottom
                          style={{ margin: "0px" }}
                        >
                          Save {cotationPlan.save_pay_yearly ? "₹" : ""}
                          {cotationPlan.save_pay_yearly} per year
                        </Typography>
                      </div> */}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Card>

          {/* =============== Users =============== */}
        </Container>

        {value > maxLabel && (
          <Box className="hikemmDeviceModal-footerText mb-5">
            <Typography variant="h5" gutterBottom>
              Get a quote from sales@hikartech.com
            </Typography>
            <Box className="hikemmDeviceModal-footerBtnwrap">
              <button
                className="contactUs-button"
                onClick={handleOpenContactUs}
              >
                Contact Us
              </button>
              <button
                className="Back-button"
                onClick={() => navigate("/subscriptionManagement")}
              >
                Back
              </button>
            </Box>
          </Box>
        )}

        {value <= maxLabel && (
          <Box className="hikemmPayment-footerText mb-4 mt-0">
            <Typography
              variant="h5"
              gutterBottom
              style={{ fontSize: "30px", display: "flex" }}
              className="mb-0 total-count-footer"
            >
              <div className="mt-2">
                Total for subscription{" "}
                {activeClass == "Monthly" ? "per month" : "per year"} :{" "}
              </div>{" "}
              <b
                style={{
                  fontSize: "50px",
                  color: "#1C407B",
                  marginLeft: "15px",
                }}
              >
                {getCurrencySymbol(activeClass)}
                {getFormattedCount(activeClass)}
              </b>
            </Typography>
            <Box className="hikemmDeviceModal-footerBtnwrap">
              <button
                className="Proceed-payment-button"
                onClick={() => handleSaveForLater()}
              >
                Proceed to Payment
              </button>
              <button
                className="Back-button"
                onClick={() => navigate("/subscriptionManagement")}
              >
                Back
              </button>
            </Box>
          </Box>
        )}

        {
          <ValidatorForm onSubmit={handleSubmit}>
            {contactModal && (
              <Card className="addDeviceModal-card" style={{ width: "440px" }}>
                <Box className="addDeviceModal-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addDeviceModal-title"
                  >
                    ASK FOR QUOTE
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={() => handleOpenContactCloseModel()}
                  />
                </Box>
                <Box className="addDeviceModal-headerWrap">
                  <h6> We Promise To Attend You In Less Than 2 Hours!!</h6>
                </Box>
                <Box className="addDeviceModal-innerWrap">
                  <Stack spacing={2} pt="10px">
                    <TextField
                      id="filled-basic"
                      name="first_name"
                      label="First Name *"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      onChange={handleChangeAddDetail}
                      value={addContactData.first_name}
                    />

                    <TextField
                      id="filled-basic"
                      name="last_name"
                      label="Last Name *"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      onChange={handleChangeAddDetail}
                      value={addContactData.last_name}
                    />

                    <TextField
                      id="filled-basic"
                      name="company_name"
                      label="Company Name *"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      onChange={handleChangeAddDetail}
                      value={addContactData.company_name}
                    />
                    <TextField
                      id="filled-basic"
                      name="phone_number"
                      label="Your Phone *"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      onChange={handleChangeAddDetail}
                      value={addContactData.phone_number}
                    />
                    <TextField
                      id="filled-basic"
                      name="email_id"
                      label="Your Email *"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      onChange={handleChangeAddDetail}
                      value={addContactData.email_id}
                    />
                    <TextField
                      id="filled-basic"
                      name="subscription"
                      label="Subscription * (No of required tags 1500+)"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      onChange={handleChangeAddDetail}
                      value={addContactData.subscription}
                    />
                  </Stack>
                  <Box className="accountSecurity-cardButtons deviceManagement">
                    <button className="addDeviceBtn">SUBMIT</button>
                  </Box>
                </Box>
              </Card>
            )}
          </ValidatorForm>
        }
      </div>
    </>
  );
}

export default HikemmServices;
