import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import "./CompanyListShimmer.css";
import React from "react";
import { images } from "../../../config";

const ShimmerCard = () => (
  // <div class="ss-shimmer-card">
  //   <div class="shimmer-content">
  //     <div class="shimmer-block shimmer-header"></div>
  //     <div class="shimmer-block shimmer-count"></div>
  //     <div class="shimmer-block shimmer-count"></div>
  //     <div class="shimmer-icon-row">
  //       <div class="shimmer-icon"></div>
  //       <div class="shimmer-icon"></div>
  //       <div class="shimmer-icon"></div>
  //       <div class="shimmer-icon"></div>
  //     </div>
  //     <div class="shimmer-icon-row">
  //       <div class="shimmer-icon-label"></div>
  //       <div class="shimmer-icon-label"></div>
  //       <div class="shimmer-icon-label"></div>
  //       <div class="shimmer-icon-label"></div>
  //     </div>
  //   </div>
  // </div>
  <Card
  className="shimer-card ss-shimmer-card"
>
  <Stack direction="column">
    <Typography
      gutterBottom
      className="dashboardCard-plantTitle"
    >
     
    <div class="shimmer-block shimmer-header"></div>

    </Typography>
    <Typography
      gutterBottom
      className="dashboardCard-plantsubTitle"
    >
     <div class="shimmer-block shimmer-count"></div>
    </Typography>
    <Box className="dashboardCard-companyContent">
      <Typography
        gutterBottom
        className="dashboardCard-plantText"
      >
      <div class="shimmer-icon-label"></div>
      </Typography>
   
      <Box>
        <Stack
          direction="column"
          spacing={1}
          style={{ marginTop: "5px" }}
        >
          <div class="shimmer-icon"></div>
          <Typography
            variant="caption"
            className="dashboardCard-plantSmalltext"
          >
           <div class="shimmer-icon-label"></div>
          </Typography>
        </Stack>
      </Box>
    
      <Box >
        <Stack
          direction="column"
          spacing={1}
          style={{ marginTop: "5px" }}
        >
          
          <div class="shimmer-icon"></div>
          <Typography
            variant="caption"
            className="dashboardCard-plantSmalltext"
          >
             <div class="shimmer-icon-label"></div>
          </Typography>
        </Stack>
      </Box>
      <div
        style={{
          borderRight: "2px solid #C9CFE7",
          height: "30px",
        }}
      ></div>
      <Box>
        <Stack
          direction="column"
          spacing={1}
          style={{ marginTop: "5px" }}
        >
          <div class="shimmer-icon"></div>
          <Typography
            variant="caption"
            className="dashboardCard-plantSmalltext"
          >
              <div class="shimmer-icon-label"></div>
          </Typography>
        </Stack>
      </Box>
      <div
        style={{
          borderRight: "2px solid #C9CFE7",
          height: "30px",
        }}
      ></div>

      <Box paddingRight={4}>
        <Stack
          direction="column"
          spacing={1}
          style={{ marginTop: "5px" }}
        >
          
          <div class="shimmer-icon"></div>
          <Typography
            variant="caption"
            className="dashboardCard-plantSmalltext"
          >
               <div class="shimmer-icon-label"></div>
          </Typography>
        </Stack>
      </Box>
    </Box>
  </Stack>
</Card>
);

const ShimmerEffectPlaceholder = ({ count = 9 }) => {
  return (
    <div className="shimmer-wrapper">
      {Array.from({ length: count }, (_, index) => (
         <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
        <ShimmerCard key={index} />
        </Grid>
      ))}
    </div>
  );
};

export default ShimmerEffectPlaceholder;
