export const timeStampCheckStatus = (mqttData) =>{
 return mqttData.filter(obj => {
    const timestamp = new Date(obj.TIMESTAMP);
    const tagDateAndTimeFormat = new Date(obj.tagDateAndTimeFormat);

    // Calculate the difference in milliseconds
    const diffInMs = Math.abs(timestamp - tagDateAndTimeFormat);

   // Convert milliseconds to hours
   const diffInHours = diffInMs / (1000 * 60 * 60);

   // Retain the object if the difference is 1 hour or more
   return diffInHours >= 1 || diffInHours <= -1;
  });
} 