export const SoftTagFormula = (softTagFormula, tagValueMap) => {
    try {
        // Extract variable names from the expression
        const variables = softTagFormula.match(/[a-zA-Z_][a-zA-Z0-9_]*/g);

        // Create a string of variable assignments
        const assignments = variables.map(variable => {
            if (tagValueMap.hasOwnProperty(variable)) {
                return `var ${variable} = ${tagValueMap[variable]};`;
            } else {
                throw new Error(`Value for ${variable} not found in the array`);
            }
        }).join('\n');

        // Evaluate the expression
        const expression = `${assignments}\n${softTagFormula}`;
        // return eval(expression);
        return { softTagNotGetTag: true, value: eval(expression) };
    } catch (error) {
        return { softTagNotGetTag: false };
    }
}
