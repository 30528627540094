import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { useSelector } from "react-redux";
import { images } from "../../../config";
import { useParams } from "react-router-dom";
import { ShimmerText } from "react-shimmer-effects";
import { ParetoChartShimmer } from "../../../components/Shimmer/ParetoChartShimmer/ParetoChartShimmer";
import ShimmerEffectLineChart from "../../ShimmerLineChart/ShimmerLinechart";

HC_more(Highcharts);
exporting(Highcharts);

const MultiAxiosLineChart = ({
  LineChartData1,
  LineChartData2,
  selectedMachine,
  shimmer,
}) => {
  const [xAxisMin, setXAxisMin] = useState(0);
  const [xAxisMax, setXAxisMax] = useState(0);

  const chartRef = useRef(null);

  const categories = [];

  if (
    LineChartData1 ||
    LineChartData2 ||
    LineChartData1.length ||
    LineChartData2.length
  ) {
    const popDataLength = LineChartData1
      ? LineChartData1.length
        ? LineChartData1[0].data
          ? LineChartData1[0].data.length
          : 0
        : 0
      : 0;
    const pop1DataLength = LineChartData2
      ? LineChartData2.length
        ? LineChartData2[0].data
          ? LineChartData2[0].data.length
          : 0
        : 0
      : 0;
    const maxLength = Math.max(popDataLength, pop1DataLength);

    for (let i = 0; i < maxLength; i++) {
      const popData = LineChartData1
        ? LineChartData1.length
          ? LineChartData1[0].data
            ? LineChartData1[0].data[i]
            : {}
          : {}
        : {};
      const pop1Data = LineChartData2
        ? LineChartData2.length
          ? LineChartData2[0].data
            ? LineChartData2[0].data[i]
            : {}
          : {}
        : {};
      const minKey = popData?.MIN_KEY || "";
      const pop1MinKey = pop1Data?.MIN_KEY || "";
      categories.push(`${minKey} ${pop1MinKey}`);
    }
  }

  const findUnitColor1 = LineChartData1
    ? LineChartData1.length
      ? selectedMachine.find((f) => f.tagId == LineChartData1[0]["tag_id"])
      : ""
    : "";
  const findUnitColor2 = LineChartData2
    ? LineChartData2.length
      ? selectedMachine.find((f) => f.tagId == LineChartData2[0]["tag_id"])
      : ""
    : "";

  const options = {
    chart: {
      type: "spline",
      scrollablePlotArea: {
        minWidth: 700,
        scrollPositionX: categories.length - 1, // Set scroll position to the rightmost end
      },
      events: {
        load: function () {
          this.redraw();
        },
      },
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false, // Disable legend
    },
    xAxis: {
      categories: categories,
      scrollbar: {
        enabled: true,
      },
    },
    series: [
      {
        name: LineChartData1
          ? LineChartData1.length
            ? LineChartData1[0]["tag_name"]
            : ""
          : "",
        data: LineChartData1
          ? LineChartData1.length
            ? LineChartData1[0] && LineChartData1[0]?.data
              ? LineChartData1[0] &&
                LineChartData1[0]?.data.map((row) => parseFloat(row.tag_value))
              : []
            : []
          : [],
        color: findUnitColor1 ? findUnitColor1.color : "",
        yAxis: 0, // Set the series to use the first y-axis
      },
      {
        name: LineChartData2
          ? LineChartData2.length
            ? LineChartData2[0]["tag_name"]
            : ""
          : "",
        data: LineChartData2
          ? LineChartData2.length
            ? LineChartData2[0] && LineChartData2[0]?.data
              ? LineChartData2[0]?.data.map((row) => parseFloat(row.tag_value))
              : []
            : []
          : [],
        color: findUnitColor2 ? findUnitColor2.color : "",
        yAxis: 1, // Set the series to use the second y-axis
      },
    ],
    yAxis: [
      {
        title: {
          text: LineChartData1
            ? LineChartData1.length
              ? LineChartData1[0]["tag_name"]
              : ""
            : "",
        },
      },
      {
        title: {
          text: LineChartData2
            ? LineChartData2.length
              ? LineChartData2[0]["tag_name"]
              : ""
            : "",
        },
        opposite: true, // Display the second y-axis on the opposite side
      },
    ],
    tooltip: {
      shared: true,
      formatter: function () {
        let s = `${this.x}<br/>`;
        this.points.forEach(point => {
          s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${point.y}</b>`;
        });
        return s;
      }
    },
    exporting: {
      enabled: true, // Disable exporting options
    },
    credits: {
      enabled: false, // Disable credits
    },
    
  };

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (chart) {
      const visibleCategories = categories.slice(-5); // Show the last 5 categories initially
      chart.xAxis[0].setExtremes(
        categories.length - visibleCategories.length,
        categories.length - 1
      );
      chart.redraw();
    }
  }, [categories]);

  return (
    <>
      {!shimmer ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <ShimmerEffectLineChart />
      )}
    </>
  );
};

export default MultiAxiosLineChart;
