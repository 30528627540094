export const ErrorReport = (message) => {

    const emailBody = `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Error Report</title>
  <style>
    body {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      background-color: #f2f2f2;
      margin: 0;
      padding: 0;
    }

    .container {
      max-width: 600px;
      margin: 0 auto;
      background-color: #fff;
      border-radius: 8px;
      padding: 30px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    h2 {
      color: #3498db;
      margin-bottom: 20px;
    }

    p {
      color: #555;
      line-height: 1.6;
      margin-bottom: 15px;
    }

    pre {
      background-color: #ecf0f1;
      padding: 15px;
      border-radius: 6px;
      overflow: auto;
      color: #333;
      margin-bottom: 20px;
    }

    .details {
      margin-top: 20px;
    }

    .emoji {
      font-size: 22px;
      margin-right: 8px;
    }

    .divider {
      border-top: 1px solid #ddd;
      margin: 20px 0;
    }

    .footer {
      margin-top: 20px;
      font-size: 12px;
      color: #777;
    }
  </style>
</head>
<body>
  <div class="container">
    <h2><span class="emoji">&#x1F525;</span> Error Report <span class="emoji">&#x1F525;</span></h2>
    <p>Dear Developer,</p>
    <p>An error has occurred on the website. Here are the details:</p>

    <div class="details">
      <p><strong><span class="emoji">&#x1F310;</span> Host URL:</strong> {{ hostUrl }}</p>
      <p><strong><span class="emoji">&#x1F310;</span> Page URL:</strong> {{ pageUrl }}</p>
      <p><strong><span class="emoji">&#x1F517;</span> API EndPoint:</strong></p>
      <pre>{{ apiEndPoint }}</pre>
      <p><strong><span class="emoji">&#x1F511;</span> Header:</strong></p>
      <pre>{{ header }}</pre>
      <p><strong><span class="emoji">&#x1F5BF;</span> Reqeust Body:</strong></p>
      <pre> {{ requestBody }}</pre>
      <p><strong><span class="emoji">&#x1F6A8;</span> Error Details:</strong></p>
      <pre>{{ errorDetails }}</pre>
      <p><strong><span class="emoji">&#x1F6C9;</span> User ID:</strong> {{ userId }}</p>
      <p><strong><span class="emoji">&#x1F584;</span> EmailID:</strong> {{ emailId }}</p>
      <p><strong><span class="emoji">&#x1F4C5;</span> Date and Time:</strong> {{ dateTime }}</p>
      <p><strong><span class="emoji">&#x1F4E2;</span> User Agent:</strong> {{ userAgent }}</p>
      <p><strong><span class="emoji">&#x1F4DD;</span> IP Address:</strong> {{ ipAddress }}</p>
    </div>

    <div class="divider"></div>

    <p>If you have any questions or concerns, please investigate and resolve the issue promptly. Thank you for your attention to this matter.</p>

    <div class="footer">
      <p>This email was sent automatically. Do not reply to this email.</p>
    </div>
  </div>
</body>
</html>
`;

    return emailBody
      .replace("{{ hostUrl }}", message.hostUrl)
      .replace("{{ pageUrl }}", message.pageUrl)
      .replace("{{ apiEndPoint }}", message.apiEndPoint)
      .replace("{{ header }}", message.header)
      .replace("{{ requestBody }}", message.requestBody)
        .replace("{{ userAgent }}", message.userAgent)
        .replace("{{ ipAddress }}", message.ipAddress)
        .replace("{{ dateTime }}", message.dateTime)
    .replace("{{ errorDetails }}", message.errorDetails)
      .replace("{{ userId }}", message.userId)
      .replace("{{ emailId }}", message.emailId)
        
};