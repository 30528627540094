import React from "react";

const COCPOPTabs = ({active,setActive}) => {
  return (
    <>
      <div className="analytics-tabs-btn">
        <div className="d-flex">
          <button
            className={`${
              active == "Consumer"
                ? "Analytics-tabs-overview"
                : "Reports-tabs-overview"
            }`}
            onClick={() => setActive("Consumer")}
            style={{ width: "210px", height: "50px" }}
          >
            Consumer over Consumer
          </button>
          <button
            className={`${
              active == "Period"
                ? "Analytics-tabs-overview"
                : "Reports-tabs-overview"
            }`}
            onClick={() => setActive("Period")}
            style={{ width: "210px", height: "50px" }}
          >
            Period over Period
          </button>
        </div>
      </div>
    </>
  );
};

export default COCPOPTabs;
