import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


export const UnknowRoutes = () => {
    const navigate = useNavigate()
    useEffect(() => {
        navigate("/")
    }, [])
}

