import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import ShimmerEffectLineChart from "../../ShimmerLineChart/ShimmerLinechart";
HC_more(Highcharts); // Initialize the 'highcharts-more' module
exporting(Highcharts); // Initialize the 'exporting' module

const LineChart = ({
  LineGraphData,
  tagID,
  averageArray,
  averageActive,
  shimmer,
}) => {
  // Define custom colors for the lines

  console.log("LineGraphData",LineGraphData)
  const lineColors = ["#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#FFFF00"];

  const labels = LineGraphData
    ? LineGraphData.length
      ? LineGraphData[0].data
        ? LineGraphData[0].data.map((row) => row.MIN_KEY)
        : []
      : []
    : [];

  const selectUnit = tagID ? (tagID.length ? tagID[0]["tagUnit"] : "") : "";

  const averageSeries = averageActive
    ? [
        {
          name: "Average",
          data: averageArray ? averageArray : [],
          color: "green",
          dashStyle: "ShortDash", // You can use other dash styles as well
        },
      ]
    : [];

  const seriesData = LineGraphData
    ? LineGraphData.length
      ? LineGraphData.map((row) => {
          const findColor = tagID
            ? tagID.length
              ? tagID.find((f) => f.tagId == row.tag_id)
              : ""
            : "";
          return {
            name: row.tag_name,
            data: row.data ? row.data.map((row2) => row2.tag_value) : [],
            color: findColor ? findColor.color : "",
          };
        })
      : []
    : [];

  const combineSeries = [...seriesData, ...averageSeries];

  const [keyValue, setkeyGraph] = useState(0);
  const [chartOption, setChartOption] = useState({
    chart: {
      type: "spline",
      scrollablePlotArea: {
        minWidth: 700,
        scrollPositionX: labels.length - 1, // Set scroll position to the rightmost end
      },
    },
    title: {
      text: null, // Remove the title
    },
    series: [],
    xAxis: {
      categories: [],
    },
    yAxis: {
      title: {
        text: selectUnit,
      },
    },
    legend: {
      itemStyle: {
        color: "black",
        fontSize: "16px",
      },
    },
    // series: combineSeries,
    colors: lineColors,
    scrollbar: {
      enabled: true, // Enable scrollbar
    },
    exporting: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      formatter: function () {
        let s = `${this.x}<br />`;
        this.points.forEach(point => {
          s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${point.y}</b>`;
        });
        return s;
      },
    },
  });

  useEffect(() => {
    // const min = 0;
    // const max = labels.length;

    setChartOption({
      ...chartOption,
      xAxis: {
        ...chartOption.xAxis,
        // min: min,
        // max: max,
        categories: labels.concat([""]),
      },
      yAxis: {
        ...chartOption.yAxis,
        title: {
          text: selectUnit,
        },
      },
      series: combineSeries,
    });

    // Update the key to force component re-render and chart redraw
    setkeyGraph(keyValue + 1);
  }, [LineGraphData, averageActive]);

  return (
    <div style={{ height: "390px" }} key={keyValue}>
      {!shimmer ? (
        <HighchartsReact highcharts={Highcharts} options={chartOption} />
      ) : (
        <ShimmerEffectLineChart />
      )}
    </div>
  );
};

export default LineChart;

{
  /* <ShimmerText line={12} gap={15} /> */
}
