import React from "react";
import { Grid } from "@mui/material";
import sliderImage2 from "../../assets/images/SliderImg2.jpg";
import sliderImage3 from "../../assets/images/SliderImg3.jpg";
import sliderImage6 from "../../assets/images/SliderImg6.png";
import sliderImage7 from "../../assets/images/SliderImg7.jpg";
import sliderImage4 from "../../assets/images/SliderImg4.jpg";
// import sliderImage5 from "../../assets/images/SliderImg5.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SliderAuth.css'

export function SliderAuth() {

    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        fade: true,
        cssEase: "linear",
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
        pauseOnFocus: false,
    };
    const sliderContent = [
        {
            img: sliderImage2,
            title1: "Innovative Solutions,",
            title2: "Reliable Connections for a Lifetime",
            disc: "A comprehensive offering meeting worlds electrification needs",
        },
        {
            img: sliderImage4,
            title1: "Innovative Solutions,",
            title2: "Reliable Connections for a Lifetime",
            disc: "A comprehensive offering meeting worlds electrification needs",
        },
        {
            img: sliderImage3,
            title1: "Innovative Solutions,",
            title2: "Reliable Connections for a Lifetime",
            disc: "A comprehensive offering meeting worlds electrification needs",
        },
        // {
        //     img: sliderImage4,
        //     title1: "Empowered people,",
        //     title2: "Collaborative Culture",
        //     disc: "An inclusive and happy workplace built on pillars of unleashing talent, outperformance and touching lives",
        // },
        // {
        //     img: sliderImage5,
        //     title1: "Inspiring Innovation,",
        //     title2: "Creating the Future",
        //     disc: "Pioneering technologies, processes, and business practices, challenge the status quo and add value to our stakeholders",
        // },
    ];

    return (
        <Grid item xs={12} md={7} lg={8} className="slider-wrapper">
            <div className="slider-container">
                <Slider {...settings}>
                    {sliderContent.map((sliderdetails, index) => (
                        <div className="single-slide" key={index}>
                            <img src={sliderdetails.img} alt="banner" />
                            <div className="slider-detail">
                                <h3>
                                    {sliderdetails.title1}
                                    <br></br>
                                    {sliderdetails.title2}
                                    <br />
                                </h3>
                                <p>{sliderdetails.disc}</p>
                            </div>
                            <div className="overlaySlider"></div>
                        </div>
                    ))}
                </Slider>
            </div>
        </Grid>
    )
}