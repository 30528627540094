import React, { useRef, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PieChartShimmer } from "../../../../components/Shimmer/PieChartShimmer/PieChartShimmer";
import './Shimmer.css';
import '../../ProcessDashboard.css';
import { images } from "../../../../config";

export function ProductionPiechartCOCDownTime({
  AvailabilityCOCPieChartData,
  shimmer,
}) {
  const chartRef = useRef(null);
  const { analyticsDataDeviceColor } = useSelector(
    (state) => state.analyticsDataState
  );
  const param = useParams();

  // Extracting device names and downtime
  const deviceDownTime = AvailabilityCOCPieChartData?.deviceData?.map(row => row.down_time) || [];
  const deviceNames = AvailabilityCOCPieChartData?.deviceData?.map(row => row.device__device_name) || [];
  
  // Finding device colors based on plantId
  const findPlantDevice = analyticsDataDeviceColor.filter(f => f.plantId == param.id) || [];

  const deviceColorMap = new Map(findPlantDevice.map((row) => [row.dvName, row.color]));

  // Convert downtime values to absolute values for chart display
  const dataForChart = AvailabilityCOCPieChartData?.deviceData?.map((row) => ({
    name: row.device__device_name,
    y: Math.abs(row.down_time),  // Use absolute value for display
    color: deviceColorMap.get(row.device__device_name),
  })) || [];

  const options = {
    chart: {
      type: 'pie',
      height: 230,
      width: 230,
      backgroundColor: 'transparent',
    },
    title: {
      text: ''
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        const total = dataForChart.reduce((acc, point) => acc + point.y, 0);
        const percentage = ((this.y / total) * 100).toFixed(2) + '%';
        return `<div style ="font-size : 12px">
                  <b>${this.point.name}</b><br/>
                  <span style="color:${this.point.color}">\u25A0</span> ${this.point.y} (${percentage})
                </div>`;
      }
    },
    plotOptions: {
      pie: {
        innerSize: '70%',
        dataLabels: {
          enabled: false,
        }
      }
    },
    series: [{
      name: 'Down Time',
      data: dataForChart,
    }],
    credits: {
      enabled: false
    }
  };

  const allZeros = deviceDownTime.every(time => time === 0);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.update(options);
    }
  }, [AvailabilityCOCPieChartData, findPlantDevice, dataForChart]);

  return (
    <>
      <div style={{ width: "100%", height: "195px" }} className="doughnut-chart1">
        {!shimmer ? (
          <>
            {allZeros ? (
              <img src={images.noDataFoundImg} className="notFoundDataImg not-found" alt="No Data Found" />
            ) : (
              <>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                  ref={chartRef}
                  className="donut-chart-css"
                />
                <p className="doughnut-chartText product-donught-charttext" style={{ top: "46%", left: "75%" }}>
                  {!allZeros ? AvailabilityCOCPieChartData?.deviceTotal?.total_down_time : ""}
                </p>
                <p className="doughnut-chartText-unit product-donught-chartUnit" style={{ top: "51%", left: "77%" }}>
                  {!allZeros ? AvailabilityCOCPieChartData?.deviceTotal?.total_down_time ? "hr" : "" : ""}
                </p>
              </>
            )}
          </>
        ) : (
          <PieChartShimmer size="195px" position="relative" />
        )}
      </div>
    </>
  );
}
