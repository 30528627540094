import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutAPI } from "../../container/Login/service";
import { setUserLogin } from "../../container/Login/slice/userLoginSlice";
import { clearUserDetails } from "../../container/Login/slice/userSlice";
import { clearSmartTagReportData } from "../../container/SmartTag/MachineDashboard/Slice";
import { clearAnalyticsData } from "../../container/ProcessDashboard/AdvanceAnalytics/Slice/AdvanceAnalyticsSlice";
import { clearProcessDashboarData } from "../../container/ProcessDashboard/Consumption/slice/processDashboardSlice";
import { clearOverviewData } from "../../container/ProcessDashboard/OverView/Slice/OverViewAnalyticsSlice";
import { clearProcessDashboarDataSet } from "../../container/ProcessDashboard/sliceProcessDashboard/dateSliceprocessDashboard";
import { clearVmsAdvanceAnalyticsSet } from "../../container/VMS/VmsCompanyDashboard/VmsMachine/Advance Analytics/slice/vmsAdvanceAnalyticsSlice";
import { clearVmsReportData } from "../../container/VMS/VmsCompanyDashboard/VmsMachine/Reports/Slice/VmsReportSlice";

export const Session = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inactivityTimeout = 1000 * 60 * 30;

  let timeoutId;
  const { token } = useSelector((state) => state.userState);
  // const logout = async () => {
  //   const params = {
  //     token: token,
  //   };
  //   const logoutapi = await logoutAPI(params);
  //   if (logoutapi.status === 200) {
  //     localStorage.clear();
  //     dispatch({ type: "USER_LOGOUT" }); // Dispatch an action to the Redux store to log the user out
  //     navigate("/login"); // Navigate to the login page
  //   }
  const logout = async () => {
    const params = {
      token: token,
    };

    try {
      const resp = await logoutAPI(params);
      if (resp.status === 200 || resp.status === 201) {
        dispatch(setUserLogin({ userName: null }));
        dispatch(clearUserDetails());
        dispatch(clearSmartTagReportData());
        dispatch(clearAnalyticsData());
        dispatch(clearProcessDashboarData());
        dispatch(clearOverviewData());
        dispatch(clearProcessDashboarDataSet());
        // dispatch(clearVmsTagData());
        dispatch(clearVmsReportData());
        dispatch(clearVmsAdvanceAnalyticsSet());
        navigate("/");
        window.location.reload(false);
        localStorage.clear();
      } else {
        console.error("Logout failed with status:", resp.status);
        // Handle other status codes if needed
      }
    } catch (error) {
      console.error("Error occurred during logout:", error);
      // Handle error in logout process
    }
  };
  

  const resetLogoutTimer = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(logout, inactivityTimeout);
  };

  useEffect(() => {
    resetLogoutTimer();

    const handleActivity = () => {
      resetLogoutTimer();
    };

    document.addEventListener("mousemove", handleActivity);
    document.addEventListener("click", handleActivity);
    document.addEventListener("keydown", handleActivity);
    document.addEventListener("touchstart", handleActivity);

    return () => {
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("click", handleActivity);
      document.removeEventListener("keydown", handleActivity);
      document.removeEventListener("touchstart", handleActivity);
      clearTimeout(timeoutId);
    };
  }, []);

  return <div>{/* Render your data or other components here */}</div>;
};
