import { request } from "../../api/api";

export const getOverviewAnalyticsPieChartData = (data) => {
    return request.post('/dashboard/getOverviewAnalyticsPieChartData/', data)
}

export const getAlarmACKCount = (data) => {
    return request.post('/dashboard/getAlarmACKCount/', data)
}

export const getOptimizedPiechartData = (params) =>{
    return request.post(`/dashboard/getOptimizedOverviewAnalyticsPieChartData/`,params)
}
export const MQTTTimeStampAlert = (params) =>{
    return request.post(`/dashboard/MQTTTimeStampAlert/`,params)
}