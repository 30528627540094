import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin,zoomPlugin);

export function LineChart({ unitName, chartCubeData, getDevice, average, selectedColors, chartLineData, combineChartData }) {

  console.log("selectedColorsselectedColors", combineChartData);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      // scrollbar: {
      //   enabled: true
      // },
      zoom: {
        pan: {
            enabled: true,
            mode: 'x'
        },
        zoom: {
            pinch: {
                enabled: true       // Enable pinch zooming
            },
            wheel: {
                enabled: true       // Enable wheel zooming
            },
            mode: 'x',
        }
    },
      annotation: {
        annotations: average
          ? {
              averageLine: {
                type: "line",
                mode: "horizontal",
                scaleID: "y",
              value: calculateAverage(combineChartData),
                borderColor: "#45BF6D",
                borderWidth: 2,
                borderDash: [5, 5],
                label: {
                  content: "Average",
                  enabled: true,
                  position: "right",
                },
              },
            }
          : {},
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          borderDash: [5, 5],
        },
      },
    },
    scroll: {
      enabled: true, // Enable scrolling
      mode: 'x', // Set the scrolling mode to horizontal
      speed: 10, // Set the scrolling speed (optional)
    },
  };
  
  const labels = [unitName].concat(chartLineData?.map((e) => e.tagTime) || []);

  const data = {
    labels,
    datasets: getDevice.length
      ? getDevice.map((e, index) => {

        const findDeviceId = selectedColors.find((f) => f.deviceId == e.device)
        return ({
          label: e.device__device_name,
          data: (combineChartData[index] && combineChartData[index].map((e) => e.tagValue)) || [],
          borderColor: findDeviceId ? findDeviceId.color : "",
          backgroundColor: findDeviceId ? findDeviceId.color : "" + '80', // Adjust the opacity of the background color
        })
      })
      : [],
  };

  function calculateAverage(data) {
    if (data.length === 0) {
      return 0;
    }

    let sum = 0;
    let count = 0;

    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        sum += data[i][j].tagValue;
        count++;
      }
    }

    // const average = data.reduce((acc, curr) => {
    //   const sum = curr.reduce((innerAcc, innerCurr) => innerAcc + innerCurr.tagValue, 0);
    //   const currAverage = curr.length > 0 ? sum / curr.length : 0;
    //   return acc + currAverage;
    // }, 0)
    return sum / count;
  }

  return <Line options={options} data={data} />;
}
