import { request, multipartRequest } from "../../../api/api"

export const getDeviceList = (params) => {
    return request.post("/dashboard/getDeviceList/", params)
}

export const getDeviceTagList = (params) =>{
return request.post("/dashboard/getDeviceTagList/",params)
}

export const getPlantDashboard = (params) =>{
  return multipartRequest.post("/dashboard/getPlantDashboard/",params)

}
export const getCreatePlantDahboard = (params) => {
    return request.post("/dashboard/createPlantDashboard/", params)
}