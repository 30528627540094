import React, { useState } from "react";
// import { ValidatorForm } from "react-material-ui-form-validator";
import logo from "../../../assets/images/ElectrothermLogo-sm.png";
import { Grid, InputAdornment } from "@mui/material";
// import { InputField } from "../../../components/InputField/InputField";
import TextField from "@mui/material/TextField";
import { Button } from "../../../components/Button/button";
import { useNavigate } from "react-router-dom";
import { SliderAuth } from "../../../components/SliderAuth/SliderAuth";
import { forgotPasswordApi, newPasswordApi } from "../service";
import {
  setUserDetails,
  setUserOtpAndEmail,
  setUserProfiles,
} from "../slice/userSlice";
import { clearProcessDashboarData } from "../../ProcessDashboard/Consumption/slice/processDashboardSlice";
import { clearProcessDashboarDataSet } from "../../ProcessDashboard/sliceProcessDashboard/dateSliceprocessDashboard";
import { clearUserDetails } from "../slice/userSlice";
import { clearOverviewData } from "../../ProcessDashboard/OverView/Slice/OverViewAnalyticsSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../ForgotPassword/ForgotPassword.css";
import { images } from "../../../config/images";
import { useEffect } from "react";
import Loader from "../../../components/Loader/Loader";
import PrivacyPolicy from "../../../components/Privacy/Privacy";

export default function ReEnterPassword({
  userDetail,
  email,
  OTP,
  setEmail,
  setOTP,
  onCancelClick,
}) {
  const [password, setpassword] = useState({ newPassword: "", RePassword: "" });
  const [showPassword, setShowPassword] = React.useState(false);
  const [showReEnterPassword, setShowReEnterPassword] = React.useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handelChangePassword = (e) => {
    setpassword({ ...password, [e.target.name]: e.target.value });
  };

  const handlePasswordToggle = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleRePasswordToggle = () => {
    setShowReEnterPassword((prevShowPassword) => !prevShowPassword);
  };

  const [lengthValid, setLengthValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [lowercaseValid, setLowercaseValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [usernameValid, setUsernameValid] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    // Check password length

    if (password.newPassword.length >= 8 && password.newPassword.length <= 16) {
      setLengthValid(true);
    } else {
      setLengthValid(false);
    }

    // Check for number
    const numberRegex = /\d/;
    if (numberRegex.test(password.newPassword)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }

    // Check for special character
    const specialCharRegex = /[^\w\s]/;
    if (specialCharRegex.test(password.newPassword)) {
      setSpecialCharValid(true);
    } else {
      setSpecialCharValid(false);
    }

    // Check for lowercase letter
    const lowercaseRegex = /[a-z]/;
    if (lowercaseRegex.test(password.newPassword)) {
      setLowercaseValid(true);
    } else {
      setLowercaseValid(false);
    }

    // Check for uppercase letter
    const uppercaseRegex = /[A-Z]/;
    if (uppercaseRegex.test(password.newPassword)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }

    // Check that password.newPassword does not contain username
    const username = userDetail.username; // replace with actual username
    if (username != password.newPassword) {
      setUsernameValid(true);
    } else {
      setUsernameValid(false);
    }
  }, [password.newPassword]);

  const submitUpdatePassword = async () => {
    // const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,16}$/;
    if (!password.newPassword) {
      notify("Please enter a password.");
      return true;
    }
    if (!password.RePassword) {
      notify("Please re-enter the password to confirm.");
      return true;
    }

    if (
      !lengthValid ||
      !numberValid ||
      !specialCharValid ||
      !lowercaseValid ||
      !uppercaseValid ||
      !usernameValid
    ) {
      return true;
    }

    if (password.newPassword !== password.RePassword) {
      notify("Passwords do not match. Please make sure they are the same.");
      return true;
    }
    try {
      const params = {
        otp: OTP,
        email: email,
        password: password.newPassword,
      };
      setLoading(true);
      const resp = await newPasswordApi(params);
      if (resp.data.success == true) {
        setLoading(false);
        //     console.log("kp");
        // setEmail("");
        // setOTP("");
        // dispatch(clearOverviewData())
        // dispatch(clearUserDetails())
        // dispatch(clearProcessDashboarDataSet())
        // dispatch(clearProcessDashboarData())
        dispatch(
          setUserProfiles({
            firstName: resp.data.payload.first_name,
            lastName: resp.data.payload.last_name,
            email: resp.data.payload.email,
            token: resp.data.payload.token,
            id: resp.data.payload.id,
            MFA_UserId: resp.data.payload.mfa_user_id,
            company_logo: resp.data.payload.company_logo,
            companyId: resp.data.payload.company_id,
            is_super_admin: resp.data.payload.is_super_admin,
            is_admin: resp.data.payload.is_admin,
            is_redirect: true,
          })
        );
        // dispatch(setUserProfiles({ firstName: userDetail.first_name, lastName: userDetail.last_name, email: userDetail.email, token: userDetail.token, id: userDetail.id, MFA_UserId: userDetail.mfa_user_id, company_logo: userDetail.company_logo, companyId: userDetail.company_id, is_super_admin: userDetail.is_super_admin, is_admin: userDetail.is_admin }));
        navigate("/userprofile");
        window.location.reload(false);
        // window.location.href = "/userprofile";
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };

  const handleTermsClick = () =>{
    window.location.replace("https://hikartech.com/terms-and-conditions/")
  }
  return (
    <>
     
      <Grid container className="main-container">
        <SliderAuth />
        <Grid
          item
          xs={12}
          md={5}
          lg={4}
          className="createPassword signIn-form-wrap"
        >
          {/* <h1 className="createPassword-text">Welcome Mr. Parth</h1> */}
          <div className="pb-4">
            <img
              src={
                userDetail
                  ? userDetail.company_logo
                    ? userDetail.company_logo
                    : require("../../../assets/hikar.png")
                  : require("../../../assets/hikar.png")
              }
              alt=""
              className={"logo-deafult"}
              style={{ width: "100px" }}
            />
          </div>
          <h4 className="createPasswordText text-center">Create Password</h4>
          <TextField
            id="filled-basic"
            type={showPassword ? "text" : "password"}
            label="Password"
            variant="filled"
            style={{ width: "100%", marginBottom: "20px" }}
            name="newPassword"
            value={password.newPassword}
            onChange={handelChangePassword}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={handlePasswordToggle}
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? (
                    <img src={images.viewIcon} alt="" />
                  ) : (
                    <img src={images.eyecloseIcon} alt="" />
                  )}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: {
                color: "#001323", // black color
              },
            }}
          />
          <TextField
            id="filled-basic"
            type={showReEnterPassword ? "text" : "password"}
            label="Re-enter Password"
            variant="filled"
            style={{ width: "100%", marginBottom: "20px" }}
            name="RePassword"
            value={password.RePassword}
            onChange={handelChangePassword}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={handleRePasswordToggle}
                  style={{ cursor: "pointer" }}
                >
                  {showReEnterPassword ? (
                    <img src={images.viewIcon} alt="" />
                  ) : (
                    <img src={images.eyecloseIcon} alt="" />
                  )}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: {
                color: "#001323", // black color
              },
            }}
          />
          <div className="reEnterPassword-TextWrap">
            <h6>
              <b>New password must include at least one:</b>
            </h6>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <h6
                  style={{
                    color: password.newPassword
                      ? lengthValid
                        ? "green"
                        : "red"
                      : "",
                  }}
                >
                  Includes 8-16 characters
                </h6>
                <h6
                  style={{
                    color: password.newPassword
                      ? numberValid
                        ? "green"
                        : "red"
                      : "",
                  }}
                >
                  Number
                </h6>
                <h6
                  style={{
                    color: password.newPassword
                      ? specialCharValid
                        ? "green"
                        : "red"
                      : "",
                  }}
                >
                  Special character
                </h6>
              </Grid>
              <Grid item xs={6}>
                <h6
                  style={{
                    color: password.newPassword
                      ? lowercaseValid
                        ? "green"
                        : "red"
                      : "",
                  }}
                >
                  Lowercase letter
                </h6>
                <h6
                  style={{
                    color: password.newPassword
                      ? uppercaseValid
                        ? "green"
                        : "red"
                      : "",
                  }}
                >
                  Uppercase letter
                </h6>
                {/* <h6
                  style={{
                    color: password.newPassword
                      ? usernameValid
                        ? "green"
                        : "red"
                      : "",
                  }}
                >
                  Not your username
                </h6> */}
              </Grid>
            </Grid>
          </div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            className="pb-2  mt-2"
          >
            <Grid item xs={6}>
              <div onClick={() => onCancelClick("GetOtp")}>
                <button className="createPassword-button">Cancel</button>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div>
                <button
                  className="createPassword-button getOTP"
                  onClick={() => submitUpdatePassword()}
                >
                  Submit
                </button>
              </div>
            </Grid>
          </Grid>
          <PrivacyPolicy />
          {/* </ValidatorForm> */}
        </Grid>
      </Grid>
      {isLoading && <Loader />}
    </>
  );
}
