import { TableCell, TableRow, TextField } from "@material-ui/core";
import {
  Box,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { images } from "../../../config/images";
import {
  deleteDeviceAPI,
  getDeviceData,
  getDeviceTypeData,
  updateDeviceAPI,
} from "../service";
import { toast } from "react-toastify";
import Switch from "react-switch";

const MachineExpandDevice = ({
  machineId,
  dataCount,
  setDeviceID,
  deviceID,
  deviceTypeData,
  setdeviceTypeData,
  productID,
  companyId,
  addDeviceModal,
  updateMachineModal,
  deleteMachineModal,
  addMachineModal,
  updateGatewayModal,
  deleteGatewayModal,
  handleGetMachine,
  getTopCount,
  handlegetGateway,
  accessManagementDevice,
  handleTostMsg,
}) => {
  const [deviceData, setDeviceData] = useState([]);
  const [deleteDeviceDataModal, setDeleteDataDeviceModal] = useState(false);
  const [updateDeviceModal, setUpdateDeviceModal] = useState(false);
  const initialValue = {
    product: "",
    device_name: "",
    device_description: "",
    device_type: "",
    tag_config_count: "",
  };

  const [updateDeviceData, setUpdateDeviceData] = useState(initialValue);

  useEffect(() => {
    if (updateDeviceModal) {
      getdeviceTypeApi();
    }
  }, [updateDeviceModal]);

  const getdeviceTypeApi = async () => {
    const resp = await getDeviceTypeData();
    if (resp.status == 200 || resp.status == 201) {
      setdeviceTypeData(resp.data.payload);
    }
    console.log("devicedata", resp);
  };
  const handleSwitchToggle = (productId) => {
    // Update the product ID in updateDeviceData
    setUpdateDeviceData((prevData) => ({
      ...prevData,
      product: productId,
    }));
  };
  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleUpdateDevice = (e) => {
    setUpdateDeviceData({
      ...updateDeviceData,
      [e.target.name]: e.target.value,
    });
  };

  const handleGetDeviceData = async () => {
    const params = {
      machine: machineId,
    };
    try {
      const resp = await getDeviceData(params);
      if (resp.status == 200 || resp.status == 201) {
        setDeviceData(resp.data.payload);
        dataCount();
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };



  const handleUpdateDeviceClick = async () => {
    if (updateDeviceData.device_name === "") {
      notifyError("Please Enter Device Name");
      return;
    }
    if (updateDeviceData.device_description === "") {
      notifyError("Please Enter Device Description");
      return;
    }

    if (updateDeviceData.product == 1) {
      if (!updateDeviceData.device_type) {
        notifyError("Please Enter Device Type");
        return;
      }
    }
    const params = {
      machine: machineId,
      product: updateDeviceData.product,
      device_name: updateDeviceData.device_name,
      device_description: updateDeviceData.device_description,
      // device_type: updateDeviceData.device_type,
      company: companyId,
    };
    if (productID == 1) {
      params["device_type"] = updateDeviceData.device_type;
    }
    try {
      const resp = await updateDeviceAPI(deviceID, params);
      if (resp.status == 200 || resp.status == 201) {
        setUpdateDeviceModal(false);
        handleGetDeviceData();
        getTopCount();
        dataCount();
        notifySuccess("Device updated successfully");
      }
    } catch (error) {
      notifyError(error.response.data.message);
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    handleGetDeviceData();
  }, [machineId, dataCount]);

  const handleOpenUpdateDevice = (e, id) => {
    if (
      addDeviceModal ||
      updateMachineModal ||
      deleteMachineModal ||
      addMachineModal ||
      updateGatewayModal ||
      deleteGatewayModal ||
      deleteDeviceDataModal ||
      updateDeviceModal
    ) {
      return true;
    }
    setUpdateDeviceModal(true);
    setUpdateDeviceData({
      product: e.product,
      device_name: e.device_name,
      device_description: e.device_description,
      device_type: e.device_type,
      tag_config_count: e.tag_config_count,
    });
    setDeviceID(id);
  };

  const handleOpenDeleteDevice = (id) => {
    if (
      addDeviceModal ||
      updateMachineModal ||
      deleteMachineModal ||
      addMachineModal ||
      updateGatewayModal ||
      deleteGatewayModal ||
      deleteDeviceDataModal ||
      updateDeviceModal
    ) {
      return true;
    }
    setDeleteDataDeviceModal(true);
    setDeviceID(id);
  };

  const handleDeleteDataModal = async () => {
    try {
      const resp = await deleteDeviceAPI(deviceID);
      if (resp.status == 200 || resp.status == 201) {
        setDeleteDataDeviceModal(false);
        handleGetDeviceData();
        handleGetMachine();
        getTopCount();
        dataCount();
        handlegetGateway();
        notifySuccess("Device deleted successfully");
      }
    } catch (error) {
      notifyError(error.response.data.message);
      console.log("handleSubmit", error);
    }
  };

  return (
    <>
      {deviceData.map((e) => {
        return (
          <TableRow className="deviceManagementTable-gateway-title">
            <TableCell style={{ width: "75px" }}></TableCell>
            <TableCell>{e.device_name}</TableCell>
            <TableCell>{e.device_description}</TableCell>
            <TableCell>{e.product_name}</TableCell>
            <TableCell style={{ width: "221px" }}>
              {(accessManagementDevice?.is_viewer &&
                accessManagementDevice?.is_editor) ||
              (!accessManagementDevice?.is_viewer &&
                accessManagementDevice?.is_editor) ? (
                <Stack direction="row" className="deviceManagementTable-icon">
                  <img
                    src={images.editImg}
                    alt=""
                    className="cloudActiveImg"
                    onClick={() => handleOpenUpdateDevice(e, e.id)}
                  />

                  <img
                    src={images.deleteImg}
                    alt=""
                    className="cloudActiveImg"
                    onClick={() => handleOpenDeleteDevice(e.id)}
                  />
                </Stack>
              ) : (
                <Stack direction="row" className="deviceManagementTable-icon">
                  <img
                    src={images.editImg}
                    alt=""
                    className="cloudActiveImg"
                    onClick={handleTostMsg}
                  />

                  <img
                    src={images.deleteImg}
                    alt=""
                    className="cloudActiveImg"
                    onClick={handleTostMsg}
                  />
                </Stack>
              )}
            </TableCell>
            {deleteDeviceDataModal && (
              <Card className="deleteConfirmation-Modal">
                <Typography
                  variant="h6"
                  gutterBottom
                  className="deleteConfirmation-title"
                >
                  Are You Sure<br></br> You Want To Delete?
                </Typography>
                <Box className="deleteConfirmation-btnWrap">
                  <button
                    className="deleteConfirmation-Yesbtn"
                    onClick={handleDeleteDataModal}
                  >
                    Yes
                  </button>
                  <button
                    className="deleteConfirmation-Nobtn"
                    onClick={() => setDeleteDataDeviceModal(false)}
                  >
                    No
                  </button>
                </Box>
              </Card>
            )}

            {updateDeviceModal && (
              <Card className="addDeviceModal-card">
                <Box className="addDeviceModal-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addDeviceModal-title"
                  >
                    UPDATE DEVICE
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={() => setUpdateDeviceModal(false)}
                  />
                </Box>

                <Box className="addDeviceModal-innerWrap">
                  <Stack spacing={2} pt="10px">
                    <h6 className="pt-3">
                      <b>Choose The Product</b>
                    </h6>
                    {/* <div className="mt-1">
                      <button className="btn-product">
                        SmartTag
                        <Switch
                          checked={updateDeviceData.product == 3}
                          onClick={() => handleSwitchToggle(3)}
                        />
                      </button>
                      <button className="btn-product1">
                        HikEMM
                        <Switch
                          checked={updateDeviceData.product == 1}
                          onClick={() => handleSwitchToggle(1)}
                        />
                      </button>
                      <button className="btn-product2">
                        PredHik
                        <Switch
                          checked={updateDeviceData.product == 2}
                          onClick={() => handleSwitchToggle(2)}
                        />
                      </button>
                    </div> */}
                    <div className="mt-1 d-flex">
                      <button className="btn-product d-flex gap-2">
                        <p className="title-switch">SmartTag</p>
                        {updateDeviceData.tag_config_count === 0 ? (
                          <Switch
                            className="deviceManagement-switch"
                            onChange={() => handleSwitchToggle(3)}
                            checked={updateDeviceData.product === 3}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                            onColor="#1c407b"
                          />
                        ) : (
                          <Switch
                            className="deviceManagement-switch"
                            onChange={() => handleSwitchToggle(3)}
                            checked={updateDeviceData.product === 3}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                            onColor="#1c407b"
                            disabled={true}
                          />
                        )}
                      </button>
                      <button className="btn-product1 d-flex gap-2">
                        <p className="title-switch">HikEMM</p>
                        {updateDeviceData.tag_config_count === 0 ? (
                          <Switch
                            className="deviceManagement-switch"
                            onChange={() => handleSwitchToggle(1)}
                            checked={updateDeviceData.product == 1}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                            onColor="#1c407b"
                          />
                        ) : (
                          <Switch
                            className="deviceManagement-switch"
                            onChange={() => handleSwitchToggle(1)}
                            checked={updateDeviceData.product == 1}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                            onColor="#1c407b"
                            disabled={true}
                          />
                        )}
                      </button>
                      <button className="btn-product2 d-flex gap-2">
                        <p className="title-switch">PredHik</p>
                        {updateDeviceData.tag_config_count === 0 ? (
                          <Switch
                            className="deviceManagement-switch"
                            onChange={() => handleSwitchToggle(2)}
                            checked={updateDeviceData.product == 2}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                            onColor="#1c407b"
                          />
                        ) : (
                          <Switch
                            className="deviceManagement-switch"
                            onChange={() => handleSwitchToggle(2)}
                            checked={updateDeviceData.product == 2}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={20}
                            width={40}
                            onColor="#1c407b"
                            disabled={true}
                          />
                        )}
                      </button>
                    </div>
                    <TextField
                      id="filled-basic"
                      name="device_name"
                      label="Device Name"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      onChange={handleUpdateDevice}
                      value={updateDeviceData.device_name}
                    />
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      className="mt-1"
                    >
                      e.g. Furnace 01
                    </Typography>
                    <TextField
                      id="filled-basic"
                      name="device_description"
                      label="Device Description"
                      variant="filled"
                      className="customeTextField deviceManagement"
                      onChange={handleUpdateDevice}
                      value={updateDeviceData.device_description}
                    />
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      className="addDeviceModal-text"
                    >
                      e.g. Furnace 01 of Plant 1
                    </Typography>
                    <FormControl
                      variant="filled"
                      sx={{ minWidth: 120 }}
                      className="customeTextField deviceManagement"
                    >
                      {updateDeviceData.product === 1 ? (
                        <>
                          <InputLabel id="demo-simple-select-filled-label">
                            Select Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            name="device_type"
                            value={updateDeviceData.device_type}
                            onChange={handleUpdateDevice}
                          >
                            {deviceTypeData.map((e) => (
                              <MenuItem value={e.device_type_name}>
                                {e.device_type_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Stack>
                  <Box className="accountSecurity-cardButtons deviceManagement">
                    <button
                      className="addDeviceBtn"
                      onClick={handleUpdateDeviceClick}
                    >
                      UPDATE DEVICE TO HIKAR.CLOUD
                    </button>
                  </Box>
                </Box>
              </Card>
            )}
          </TableRow>
        );
      })}
    </>
  );
};

export default MachineExpandDevice;
