import React from "react";
import { Box, Grid, Switch, TextField } from "@mui/material";
import { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { useParams } from "react-router-dom";
import DeviceListShimmer from "../Shimmer/DeviceListShimmer/DeviceListShimmer";

const POPDataList = ({
  deviceData,
  switchColors,
  handleSelectPOPDeviceId,
  vmsAvailabilityPOPDataId,
  access,
  handleTostMsg,
  isShimmerdevicelist
}) => {
  const param = useParams();
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <Box className="consumerData-box" style={{ minHeight: "718px" }}>
        <Box className="consumer-text-disc mb-2">
          <h6 className="Title">Title</h6>
          <h6>Availability Charts</h6>
        </Box>
        <Box className="consumer-text-disc">
          <h6 className="Title">Description</h6>
          <h6>Availability Charts - Period over Period</h6>
        </Box>

        <TextField
          variant="outlined"
          className="search-textfield"
          placeholder="Search Device here"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <SearchIcon />,
            inputProps: {
              style: { padding: "9px" },
            },
          }}
        />
        <div
          className="furnace-grid"
          style={{ height: "393px", overflow: "auto" }}
        >
          <Box className="Grid-text mt-2">
            {!isShimmerdevicelist ? deviceData.length
              ? deviceData
                  .filter((e) =>
                    e.device__device_name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase())
                  )
                  .map((e, index) => (
                    <Grid item xs={12} key={e.device__device_name}>
                      <Grid
                        container
                        alignItems="center"
                        style={{
                          background: "#efeffd",
                          width: "100%",
                          marginBottom: "7px",
                        }}
                      >
                        <Grid
                          item
                          xs={8}
                          style={{
                            padding: "7px 12px",
                            borderLeft: `4px solid ${
                              switchColors[index % switchColors.length]
                            }`,
                          }}
                        >
                          <h6 className="mt-2">{e.device__device_name}</h6>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          textAlign="right"
                          className="grid-switch-unit"
                        >
                          {(access?.is_viewer && access?.is_editor) ||
                          (!access?.is_viewer && access?.is_editor) || (access?.is_viewer && !access?.is_editor) ? (
                            <Switch
                              style={{ float: "right" }}
                              onClick={(event) =>
                                handleSelectPOPDeviceId(
                                  e.device,
                                  index,
                                  event,
                                  e
                                )
                              }
                              name={switchColors[index]}
                              // checked={analyticsProductionPOPId.includes(
                              //   e.device
                              // )}

                              checked={
                                vmsAvailabilityPOPDataId.find(
                                  (f) => f.deviceID == e.device
                                )
                                  ? true
                                  : false
                              }
                              sx={{
                                "& .MuiSwitch-thumb": {
                                  bgcolor: "#828282",
                                },
                                "& .MuiSwitch-track": {
                                  bgcolor: "#D9D9E5 !important",
                                },
                                "& .Mui-checked": {
                                  "& .MuiSwitch-thumb": {
                                    bgcolor:
                                      switchColors[index % switchColors.length],
                                  },
                                  "& .MuiSwitch-track": {
                                    bgcolor: "#E2D9F2 !important",
                                  },
                                },
                              }}
                            />
                          ) : (
                            <Switch
                              style={{ float: "right" }}
                              onClick={handleTostMsg}
                              name={switchColors[index]}
                              // checked={analyticsProductionPOPId.includes(
                              //   e.device
                              // )}

                              checked={
                                vmsAvailabilityPOPDataId.find(
                                  (f) => f.deviceID == e.device
                                )
                                  ? true
                                  : false
                              }
                              sx={{
                                "& .MuiSwitch-thumb": {
                                  bgcolor: "#828282",
                                },
                                "& .MuiSwitch-track": {
                                  bgcolor: "#D9D9E5 !important",
                                },
                                "& .Mui-checked": {
                                  "& .MuiSwitch-thumb": {
                                    bgcolor:
                                      switchColors[index % switchColors.length],
                                  },
                                  "& .MuiSwitch-track": {
                                    bgcolor: "#E2D9F2 !important",
                                  },
                                },
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
              : []:<DeviceListShimmer itemCount={7}/>}
          </Box>
        </div>

        <div className="mt-3">
          {(access?.is_viewer && access?.is_editor) ||
          (!access?.is_viewer && access?.is_editor) || (access?.is_viewer && !access?.is_editor) ? (
            <button className="export-btn" style={{ marginTop: "46px" }}>
              EXPORT ENERGY DATA
            </button>
          ) : (
            <button
              className="export-btn"
              style={{ marginTop: "46px" }}
              onClick={handleTostMsg}
            >
              EXPORT ENERGY DATA
            </button>
          )}
        </div>
      </Box>
    </>
  );
};

export default POPDataList;
