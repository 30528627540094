import React, { useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import "../AdvanceAnalytics/Analytics.css";
import { images } from "../../../config/images";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { Card } from "@mui/material";
import { useSelector } from "react-redux";

const Analytics = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectRange, setSelectedRange] = useState([]);
  const [editPopup, setEditPopup] = useState(false);
  const [addpopup, setAddPopup] = useState(false);
  const [numInputs, setNumInputs] = useState(1); // Initialize with 1 input field

  const { user_access } = useSelector((state) => state.userState);
  const ref = useRef();
  const param1 = useParams();
  const handleSelectDateRange = (start, end) => {
    const months = [];
    let currentMonth = start.getMonth();
    let currentYear = start.getFullYear();
    const endMonth = end.getMonth();
    const endYear = end.getFullYear();

    while (
      currentYear < endYear ||
      (currentYear === endYear && currentMonth <= endMonth)
    ) {
      const date = new Date(currentYear, currentMonth);
      const monthString = date.toLocaleString("default", { month: "short" });
      const yearString = date.toLocaleString("default", { year: "numeric" });
      const label = `${monthString}-${yearString}`;
      months.push({ id: currentMonth, label: label });
      currentMonth++;
      if (currentMonth > 11) {
        currentMonth = 0;
        currentYear++;
      }
    }
    setSelectedRange(months);
  };


  const handleEditClick = () => {
    setEditPopup(true);
  };

  const handleCancleClick = () => {
    setEditPopup(false);
    window.location.reload(false);
  };
  const handleSubmitClick = () => {
    setEditPopup(false);
    window.location.reload(false);
  };

  const addInputField = () => {
    setNumInputs(numInputs + 1);
  };

  return (
    <>
      <div className="page-wraper">
        <div className="page-header d-flex justify-content-between">
          <div
            onClick={() =>
              navigate(
                `/company/${param1.companyId}/plant/plantprocessdashboard/${param1.id}`
              )
            }
            className="page-back-btn"
          >
            <ArrowBackIcon />
            <span>Summary</span>
          </div>
          <div className="header-btn-group d-flex">
            {/* <div>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  if (endDate) {
                    handleSelectDateRange(date, endDate);
                  }
                }}
                dateFormat="MMM y"
                className="analytics-date-picker"
                placeholderText="Start Month"
                showMonthYearPicker
                // inline
                ref={ref}
              />
            </div>
            <div>
              <DatePicker
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  if (startDate) {
                    handleSelectDateRange(startDate, date);
                  }
                }}
                dateFormat="MMM y"
                className="analytics-date-picker"
                placeholderText="End Month"
                showMonthYearPicker
                // inline
                ref={ref}
              />
            </div> */}
            <img src={images.excel} />
          </div>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={7}>
            <div
              className="d-flex mt-4 production p-card"
              style={{ width: "100%" }}
            >
              <h4 style={{ width: "50%", textAlign: "left" }}>Availability</h4>
              <h4 style={{ width: "100%", marginLeft: "-43px" }}>Production</h4>
            </div>
            <div className="d-flex dispatch-data" style={{ gap: "20px" }}>
              <div className="production-data-table production-data">
                <Grid container>
                  <Grid
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ P: 0 }}
                    className="single-data-item"
                  >
                    <Table className="no-border-table transparent-table common-table">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <b className="pData">Running Hours</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div className="position-relative">
                              <input
                                id="outlined-basic"
                                className=" form-input-production-pairs-hours"
                              />
                              <span className="input-label">hr</span>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </div>

              <div className="production-data-table1 production-data">
                <Grid container>
                  <Grid
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ P: 0 }}
                    className="single-data-item"
                  >
                    <Table className="no-border-table transparent-table common-table">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <b className="pData">Planned</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div className="position-relative">
                              <input
                                id="outlined-basic"
                                className="form-input-production-pairs"
                              />
                              <span className="input-label-kt">kt</span>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{ P: 0 }}
                    className="single-data-item"
                  >
                    <Table className="no-border-table transparent-table common-table">
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <b className="pData">Actual</b>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div className="position-relative">
                              <input
                                id="outlined-basic"
                                className="form-input-production-pairs"
                              />
                              <span className="input-label-kt">kt</span>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </div>
            </div>

            <div className="d-flex justify-content-between batchNo p-card pt-4">
              <h4>Energy Cost</h4>
            </div>
            <div className="production-data">
              <Grid container>
                <Grid
                  lg={3}
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{ P: 0 }}
                  className="single-data-item"
                >
                  <Table className="no-border-table transparent-table common-table">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b className="pData">Total</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <div className="position-relative">
                            <input
                              id="outlined-basic"
                              className="form-input-production"
                              autoComplete="off"
                            />
                            <span className="input-label-rupees">₹</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid
                  lg={3}
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{ P: 0 }}
                  className="single-data-item"
                >
                  <Table className="no-border-table transparent-table common-table">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <b
                            className="pData pData0"
                            style={{ marginLeft: "-38px" }}
                          >
                            Production
                          </b>
                          <img
                            src={images.editbtn}
                            onClick={handleEditClick}
                            className="energy-img"
                            style={{ cursor: "pointer" }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <div className="position-relative">
                            <input
                              id="outlined-basic"
                              className="form-input-production"
                              autoComplete="off"
                            />
                            <span className="input-label-rupees">₹</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid
                  lg={3}
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{ P: 0 }}
                  className="single-data-item"
                >
                  <Table className="no-border-table transparent-table common-table">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <b
                            className="pData pData0"
                            style={{ marginLeft: "-38px" }}
                          >
                            Auxillary
                          </b>
                          <img
                            src={images.editbtn}
                            onClick={handleEditClick}
                            className="energy-img"
                            style={{ cursor: "pointer" }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <div className="position-relative">
                            <input
                              id="outlined-basic"
                              className="form-input-production"
                              autoComplete="off"
                            />
                            <span className="input-label-rupees">₹</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid
                  lg={3}
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{ P: 0 }}
                  className="single-data-item"
                >
                  <Table className="no-border-table transparent-table common-table">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                          }}
                        >
                          <b
                            className="pData pData0"
                            style={{ marginLeft: "-38px" }}
                          >
                            Green Energy
                          </b>
                          <img
                            src={images.editbtn}
                            onClick={handleEditClick}
                            className="energy-img"
                            style={{ cursor: "pointer" }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <div className="position-relative">
                            <input
                              id="outlined-basic"
                              className="form-input-production"
                              autoComplete="off"
                            />
                            <span className="input-label-rupees">₹</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </div>

            <div className="d-flex justify-content-between batchNo p-card mt-4">
              <h4>CO2 Footprint</h4>
            </div>
            <div className="production-data" style={{ padding: "16px" }}>
              <Grid container className="grid-container">
                <Grid
                  lg={4}
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{ P: 0 }}
                  className="single-data-item"
                >
                  <Table className="no-border-table transparent-table common-table">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b className="pData">Total</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <div className="position-relative">
                            <input
                              id="outlined-basic"
                              className="form-input-production-footprint"
                            />
                            <span className="input-label-ktt">kt</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid
                  lg={4}
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{ P: 0 }}
                  className="single-data-item"
                >
                  <Table className="no-border-table transparent-table common-table">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b className="pData">Production</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <div className="position-relative">
                            <input
                              id="outlined-basic"
                              className="form-input-production-footprint"
                            />
                            <span className="input-label-ktt">kt</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid
                  lg={4}
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{ P: 0 }}
                  className="single-data-item"
                >
                  <Table className="no-border-table transparent-table common-table">
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b className="pData">Auxillary</b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <div className="position-relative">
                            <input
                              id="outlined-basic"
                              className="form-input-production-footprint"
                            />
                            <span className="input-label-ktt">kt</span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className="d-flex justify-content-between batchNo p-card position-relative pt-4">
              <h4>Load Consumption vs Energy Loss (kVAh)</h4>
            </div>
            <Card className="chart-card "></Card>
          </Grid>
        </Grid>

        <div className="pt-4 d-flex gap-3">
          <Link
            to={`/analyticsproduction/company/${param1.companyId}/plant/${param1.id}`}
            className="production-btn"
          >
            PRODUCTION
          </Link>
          <Link
            to={`/energycost/company/${param1.companyId}/plant/${param1.id}`}
            className="production-btn"
          >
            ENERGY COST
          </Link>
          <Link
            to={`/CO2/company/${param1.companyId}/plant/${param1.id}`}
            className="production-btn"
          >
            CO2
          </Link>
        </div>

        {editPopup && (
          <>
            <div className="editData">
              <Card className="editCard d-flex">
                <Grid container spacing={2}>
                  <>
                    <Grid item lg={6}>
                      <h6 style={{ fontWeight: "800" }}>Slot</h6>
                      <Box className="slotBox mt-4">
                        <div style={{ padding: "26px" }}>
                          {Array.from({ length: numInputs }).map((_, index) => (
                            <input
                              key={index}
                              type="text"
                              style={{
                                padding: "7px",
                                borderRadius: "4px",
                                border: "1px solid #475467",
                                marginBottom: "10px",
                              }}
                              value="12:00 AM - 03:00 AM"
                            />
                          ))}
                        </div>
                      </Box>
                    </Grid>
                    <Grid item lg={4}>
                      <h6 style={{ fontWeight: "800" }}>Unit Price</h6>
                      <Box className="slotBox mt-4">
                        <div style={{ padding: "26px" }}>
                          <input
                            type="text"
                            style={{
                              padding: "7px",
                              borderRadius: "4px",
                              border: "1px solid #475467",
                              width: "100%",
                            }}
                            value="10"
                          />
                        </div>
                      </Box>
                    </Grid>
                    <Grid item lg={2}>
                      <button className="addbtn" onClick={addInputField}>
                        Add
                      </button>
                      <div className="mt-3">
                        <h6 style={{ marginLeft: "11px" }}>AM</h6>
                        <img src={images.alarmimg} />
                      </div>

                      <div className="mt-3">
                        <h6 style={{ marginLeft: "11px" }}>PM</h6>
                        <img src={images.alarmEmptyImg} />
                      </div>
                    </Grid>

                    <div className="analytics-btns">
                      <button
                        className="analytics-cancle-btn"
                        onClick={handleCancleClick}
                      >
                        Cancle
                      </button>
                      <button
                        className="analytics-submit-btn"
                        onClick={handleSubmitClick}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                </Grid>
              </Card>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Analytics;
