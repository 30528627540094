import { capturePayment, getrazorpayKey } from "../service";

export const RazorpayPaymentMethodModel = async (
  orderId,
  totalAmount,
  companyId,
  id,
  title,
  notifySuccess,
  notifyError,
  setLoading
) => {
  const {
    data: {
      payload: { r_key },
    },
  } = await getrazorpayKey();

  var options = {
    key: r_key,
    amount: totalAmount,
    currency: "INR",
    image:
      "https://raychem-backend-storage.s3.ap-south-1.amazonaws.com/Final+Loog+29-5-23.png",
    order_id: orderId,
    handler: async function (response) {
      const paramReq = {
        success: true,
        company: companyId,
        order_id: response.razorpay_order_id,
        payment_id: response.razorpay_payment_id,
        signature: response.razorpay_signature,
        amount: totalAmount,
      };
      try {
        setLoading(true)
        const resp = await capturePayment(paramReq);

        if (resp.status == 201 || resp.status == 200) {
          setLoading(false)
          window.location.href = `/subscription/${title}/${id}`;
        } else {
          notifyError(resp.data.message);
        }
      } catch (error) {
        setLoading(false)
        notifyError(error.response.data.message);
        console.log("error", error);
      }
    },
    // prefill: {
    //     name: "Gaurav Kumar",
    //     email: "gaurav.kumar@example.com",
    //     contact: "9000090000"
    // },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };
  var rzp1 = new window.Razorpay(options);
  rzp1.open();
};
