import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deviceIdRe: [],
    deviceUnit: [],
    deviceColor:[]
}

const processDashboardSlice = createSlice({
    name: "processDashboard",
    initialState,
    reducers: {
        setProcessDashboarData(state, { payload }) {
            return { ...state, ...payload };
        },
        clearProcessDashboarData(state) {
            return initialState;
        },
    },
});

export const {
    setProcessDashboarData,
    clearProcessDashboarData
} = processDashboardSlice.actions;

export default processDashboardSlice.reducer;


