import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import './Shimmer.css';
import { PieChartShimmer } from "../../../../components/Shimmer/PieChartShimmer/PieChartShimmer";
import '../../ProcessDashboard.css';
import { images } from "../../../../config";

export function ProductionPiechartPOP({
  AvailabilityPOPPieChartData,
  AvailabilityPOP1PieChartData,
  shimmer,
}) {
  // Prepare the data with absolute values for the chart
  const data = [
    {
      name: AvailabilityPOPPieChartData.deviceData && AvailabilityPOPPieChartData.deviceData.length > 0 
            ? AvailabilityPOPPieChartData.deviceData[0]?.device__device_name 
            : [],  
      y: Math.abs(AvailabilityPOPPieChartData.deviceData && AvailabilityPOPPieChartData.deviceData.length > 0 
            ? AvailabilityPOPPieChartData.deviceData[0]?.up_time || 0 
            : 0),  
      color: "#C0255F",
      originalY: AvailabilityPOPPieChartData.deviceData && AvailabilityPOPPieChartData.deviceData.length > 0 
                ? AvailabilityPOPPieChartData.deviceData[0]?.up_time || 0 
                : 0,
    },
    {
      name: AvailabilityPOP1PieChartData.deviceData && AvailabilityPOP1PieChartData.deviceData.length > 0 
            ? AvailabilityPOP1PieChartData.deviceData[0]?.device__device_name 
            : [], 
      y: Math.abs(AvailabilityPOP1PieChartData.deviceData && AvailabilityPOP1PieChartData.deviceData.length > 0 
            ? AvailabilityPOP1PieChartData.deviceData[0]?.up_time || 0 
            : 0), 
      color: "#E66595",
      originalY: AvailabilityPOP1PieChartData.deviceData && AvailabilityPOP1PieChartData.deviceData.length > 0 
                ? AvailabilityPOP1PieChartData.deviceData[0]?.up_time || 0 
                : 0,
    },
  ];

  const chartOptions = {
    chart: {
      type: 'pie',
      height: 230,
      width: 230,
      backgroundColor: 'transparent',
    },
    title: {
      text: '',
    },
    tooltip: {
      formatter: function () {
        const total = data.reduce((acc, point) => acc + Math.abs(point.originalY), 0);
        const percentage = ((Math.abs(this.point.originalY) / total) * 100).toFixed(2);
        return `<div style="font-size: 12px">
                  <b>${this.point.name}</b><br/>
                  <span style="color:${this.point.color}">\u25A0</span> ${this.point.originalY.toFixed(2)} (${percentage})
                </div>`;
      },
    },
    plotOptions: {
      pie: {
        innerSize: '70%',
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Up Time',
        data: data,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  // Compute the total with absolute values
  const total = (Math.abs(AvailabilityPOPPieChartData?.deviceTotal?.total_up_time || 0) +
                 Math.abs(AvailabilityPOP1PieChartData?.deviceTotal?.total_up_time || 0)).toFixed(2);

  const allZeros = data.every(point => point.y === 0);

  return (
    <div style={{ width: "100%", height: "195px" }} className="doughnut-chart1">
      {!shimmer ? (
        <>
          {allZeros ? (
            <img src={images.noDataFoundImg} className="notFoundDataImg not-found" alt="No Data Found" />
          ) : (
            <>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                className="donut-chart-css"
              />
              <p className="doughnut-chartText pro-donught-text" style={{ left: "25%" }}>
                {!allZeros ? total : ""}
              </p>
              <p className="pro-donught-unit pro-unit" style={{ left: "27%" }}>
                {!allZeros ? "hr" : ""}
              </p>
            </>
          )}
        </>
      ) : (
        <PieChartShimmer size="195px" position="relative" />
      )}
    </div>
  );
}
