import { Box, TextField } from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";

const COCDatePicker = ({
  time,
  startDateReFormat,
  endDateReFormat,
  handleStartDate,
  handleEndDate,
  access,
  handleTostMsg
}) => {
  return (
    <>
      {time ? (
        <>
          {access?.is_viewer && access?.is_editor || !access?.is_viewer && access?.is_editor || (access?.is_viewer && !access?.is_editor) ?
            <>
              <Box
              // style={{ width: "40%", marginRight: "8px" }}
              // className="box-datepicker"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Start Date"
                    value={startDateReFormat}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    disableFuture
                    onChange={(newValue) => handleStartDate(newValue)}
                    className="custom-datepicker"
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="End Date"
                    value={endDateReFormat}
                    onChange={(newValue) => handleEndDate(newValue)}
                    disableFuture
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    className="custom-datepicker"
                  />
                </LocalizationProvider>
              </Box>
            </>
            :
            <>
              <Box
              // style={{ width: "40%", marginRight: "8px" }}
              // className="box-datepicker"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Start Date"
                    value={startDateReFormat}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    disableFuture
                    onChange={handleTostMsg}
                    className="custom-datepicker"
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="End Date"
                    value={endDateReFormat}
                    onChange={handleTostMsg}
                    disableFuture
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    className="custom-datepicker"
                  />
                </LocalizationProvider>
              </Box>
            </>
          }
        </>
      ) : (
        <>
          {(access?.is_viewer && access?.is_editor || !access?.is_viewer && access?.is_editor || (access?.is_viewer && !access?.is_editor)) ?
            <>
              <Box
              // style={{ width: "40%", marginRight: "8px" }}
              // className="box-datepicker"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // className="date-picker-production"
                    label="Start Date"
                    value={startDateReFormat}
                    onChange={(newValue) => handleStartDate(newValue)}
                    disableFuture
                    inputFormat="DD/MM/YYYY"

                    renderInput={(params) => (
                      <TextField {...params} style={{ background: "#ffff" }} />
                    )}
                    className="custom-datepicker"
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // className="date-picker-production"
                    label="End Date"
                    inputFormat="DD/MM/YYYY"
                    value={endDateReFormat}
                    onChange={(newValue) => handleEndDate(newValue)}
                    disableFuture
                    renderInput={(params) => (
                      <TextField {...params} style={{ background: "#ffff" }} />
                    )}
                    className="custom-datepicker"
                  />
                </LocalizationProvider>
              </Box>
            </>
            :
            <>
              <Box
                // style={{ width: "40%", marginRight: "8px" }}
                // className="box-datepicker"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // className="date-picker-production"
                    className="custom-datepicker"
                    label="Start Date"
                    value={startDateReFormat}
                    onChange={handleTostMsg}
                    disableFuture
                    inputFormat="DD/MM/YYYY"

                    renderInput={(params) => (
                      <TextField {...params} style={{ background: "#ffff" }} />
                    )}
                  />
                </LocalizationProvider>
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    // className="date-picker-production"
                    className="custom-datepicker"
                    label="End Date"
                    inputFormat="DD/MM/YYYY"

                    value={endDateReFormat}
                    onChange={handleTostMsg}
                    disableFuture
                    renderInput={(params) => (
                      <TextField {...params} style={{ background: "#ffff" }} />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </>
          }
        </>
      )}
    </>
  );
};

export default COCDatePicker;
