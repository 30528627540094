import {
  Box,
  Card,
  Container,
  FormControl,
  Grid,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  createPaymentMethod,
  getSubscriptionOnProccedtoPayment,
  getPaymentMethod,
  paymentCheckout,
  getChoices,
  getSubscribeDeviceLicense,
} from "./service";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import { RazorpayPaymentMethodModel } from "./RazorpayPaymentModel/RazorpayPaymentMethodModel";

const PaymentMethod = () => {
  const navigate = useNavigate();
  const [emailFields, setEmailFields] = useState([{ id: 1, value: "" }]);
  const [detailsFields, setDetailsFields] = useState([]);
  const [poDetailsField, setPODetailsField] = useState([]);
  const [nextId, setNextId] = useState(2);
  const [paymentData, setPaymentData] = useState({});
  const [choiceId, setChoiceId] = useState("");
  const [choiceId1, setChoiceId1] = useState("");
  const [choice, setChoice] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [offlinePopup, setOfflinePopup] = useState(false);
  const [headerData, setHeaderData] = useState([]);

  console.log("headerData", headerData);

  console.log("paymentData", paymentData);
  const param = useParams();
  const location = useLocation();
  // Billing Info
  const initialBillingInfo = { company_name: "", name: "", email: "" };
  const [billingInfo, setBillingInfo] = useState(initialBillingInfo);

  // Billing address
  const initialBillingAddress = {
    street_address: "",
    city: "",
    area_code: "",
    state: "",
    country: "",
  };
  const [billingAddress, setBillingAddress] = useState(initialBillingAddress);

  // Gst Details
  const [gstDetails, setGstDetails] = useState("");

  //PO details
  const [poDetails, setPoDetails] = useState("");

  // Redux state
  const { id, companyId } = useSelector((state) => state.userState);

  // payment Method Id state
  const [paymentMethodId, setPaymentMethodId] = useState("");

  //Notification Error message
  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  //Notification Success message
  const notifySuccess = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  // handle change billing info
  const handleBillingInfo = (e) => {
    setBillingInfo({ ...billingInfo, [e.target.name]: e.target.value });
  };

  // handle change billing address
  const handleBillingAddress = (e) => {
    setBillingAddress({ ...billingAddress, [e.target.name]: e.target.value });
  };

  const getSubscriptionOnProccedtoPaymentAPI = async () => {
    const params = {
      dashboard: param.id,
    };
    try {
      const resp = await getSubscriptionOnProccedtoPayment(params);
      if (resp.status == 200 || resp.status == 201) {
        setPaymentData(resp.data.payload);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    handleSubscribeLicense();
  }, []);
  const handleSubscribeLicense = async () => {
    const params = {
      company: companyId,
      subscription_id: param.subId,
    };
    try {
      const resp = await getSubscribeDeviceLicense(params);
      if (resp.status == 200 || resp.status == 201) {
        setHeaderData(resp.data.payload.header_data);
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };
  useEffect(() => {
    handleChoice();
  }, []);

  const handleChoice = async () => {
    const resp = await getChoices();
    if (resp.status == 200 || resp.status == 201) {
      setChoice(resp.data.payload.payment_choices);
      setChoiceId(
        resp.data.payload.payment_choices.find((f) => f.name == "Offline").id
      );
      setChoiceId1(
        resp.data.payload.payment_choices.find((f) => f.name == "Online").id
      );
    }
  };

  useState(() => {
    console.log("Effect triggered");
    getSubscriptionOnProccedtoPaymentAPI();
  }, []);

  // getPaymentMethod API

  const getPaymentMethodAPI = async () => {
    try {
      const resp = await getPaymentMethod();

      if (resp.status == 200 || resp.status == 201) {
        setBillingInfo(resp.data.payload?.billing_info || initialBillingInfo);
        setBillingAddress(
          resp.data.payload?.billing_address || initialBillingAddress
        );
        setGstDetails(resp.data.payload?.gst_details || "");
        setPoDetails(resp.data.payload?.po_details || "");
        setPaymentMethodId(resp.data.payload?.id || "");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPaymentMethodAPI();
  }, []);

  const handleValidationFeild = () => {
    if (!billingInfo.company_name) {
      notifyError("please enter company name.");
      return true;
    }

    if (!billingInfo.name) {
      notifyError("please enter name.");
      return 1;
    }

    if (!billingInfo.email) {
      notifyError("please enter email.");
      return 1;
    }

    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(billingInfo.email)
    ) {
      notifyError("Please Enter valid Email.");
      return 1;
    }

    if (gstDetails) {
      // Regular expression for matching GST number pattern
      const gstRegex =
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[0-9A-Z]{1}[A-Z]{1}$/;

      // Remove white spaces and convert to uppercase
      const cleanedGST = gstDetails.replace(/\s/g, "").toUpperCase();

      if (!gstRegex.test(cleanedGST)) {
        notifyError("Invalid GST number.");
        return 1;
      }
    }

    if (!billingAddress.street_address) {
      notifyError("please enter street address.");
      return 1;
    }

    if (!billingAddress.city) {
      notifyError("please enter city.");
      return 1;
    }

    if (!billingAddress.area_code) {
      notifyError("please enter area code.");
      return 1;
    }

    const areaCode = parseInt(billingAddress.area_code, 10);

    if (isNaN(areaCode) || areaCode > 2147483647) {
      notifyError(
        "Area code must be a number less than or equal to 2147483647."
      );
      return 1;
    }

    if (!billingAddress.state) {
      notifyError("please enter state.");
      return 1;
    }

    if (!billingAddress.country) {
      notifyError("please enter country.");
      return 1;
    }
  };

  const handleCreatePaymentMethod = async () => {
    // handle Validation
    if (handleValidationFeild()) {
      return 1;
    }

    const paramReq = {
      user: id,
      billing_info: {
        company_name: billingInfo.company_name,
        name: billingInfo.name,
        email: billingInfo.email,
      },
      billing_address: {
        street_address: billingAddress.street_address,
        city: billingAddress.city,
        area_code: billingAddress.area_code,
        state: billingAddress.state,
        country: billingAddress.country,
      },
      gst_details: gstDetails,
      po_details: poDetails,
      dashboard: param.id,
    };

    try {
      const resp = await createPaymentMethod(paramReq);

      if (resp.status == 200 || resp.status == 201) {
        setPaymentMethodId(resp.data.payload.id);

        if (paymentMethodId) {
          notifySuccess(resp.data.message);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // generate Receipt number
  function generateRandomReceiptNumber(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters[randomIndex];
    }
    return `Hik_${randomString}`;
  }

  const handlePaymentCheckoutOnline = async () => {
    const paramReq = {
      company: companyId,
      dashboard: param.id,
      receipt: generateRandomReceiptNumber(10),
      payment_capture: 0,
      payment_choise: choiceId1,
      payment_method: paymentMethodId,
      total_device_purchase: paymentData?.total_device || 0,
      price_per_device: paymentData?.price_per_device || 0,
      total_amount: paymentData?.total_price || 0,
      payment_period: paymentData?.payment_period || 0,
      final_amount: paymentData.final_amount,
    };
    if (param.subId) {
      paramReq["subscription_id"] = Number(param.subId);
      paramReq["total_amount"] = Number(headerData.total_amount);
      paramReq["final_amount"] = Number(headerData.next_gst_amount);
    }

    try {
      setLoading(true);

      const resp = await paymentCheckout(paramReq);
      if (resp.status == 200 || resp.status == 201) {
        setLoading(false);
        RazorpayPaymentMethodModel(
          resp.data.payload.order_id?.id,
          resp.data.payload.order_id?.amount,
          companyId,
          param.id,
          param.title,
          notifySuccess,
          notifyError,
          setLoading
        );
      }
    } catch (error) {
      setLoading(false);
      console.log("error");
    }
  };

  const handlePaymentCheckoutOffline = async () => {
    const paramReq = {
      company: companyId,
      dashboard: param.id,
      receipt: generateRandomReceiptNumber(10),
      payment_capture: 0,
      payment_choise: choiceId,
      payment_method: paymentMethodId,
      total_device_purchase: paymentData?.total_device || 0,
      price_per_device: paymentData?.price_per_device || 0,
      total_amount: paymentData?.total_price || 0,
      payment_period: paymentData?.payment_period || 0,
      final_amount: paymentData.final_amount,
    };
    if (param.subId) {
      paramReq["subscription_id"] = Number(param.subId);
    }
    if (param.subId) {
      paramReq["total_amount"] = Number(headerData.total_amount);
    }
    try {
      setLoading(true);
      const resp = await paymentCheckout(paramReq);

      if (resp.status == 200 || resp.status == 201) {
        setLoading(false);

        notifySuccess("Payment Successfully");
        navigate(`/subscription/${param.title}/${param.id}`);
        return 1;
      }
    } catch (error) {
      setLoading(false);
      console.log("error");
    }
  };

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="subscriptionTitle-wrap">
            <div
              // onClick={() =>
              //   navigate(`/subscription/${param.title}/${param.id}`)
              // }
              onClick={handleClick}
              className="d-flex"
            >
              <KeyboardBackspaceIcon className="backArrow" />
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  marginTop: "-4px",
                  paddingLeft: "18px",
                  color: "#001323",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Payment method
              </Typography>
            </div>
          </div>
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{ marginBottom: "20px" }}
          >
            Update your billing details and address.
          </Typography>

          <div className="pt-2">
            <Card className="payment-card">
              <Grid container spacing={2} style={{ padding: "30px 50px" }}>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <div>
                    <h5>
                      <b className="d-flex">
                        Billing Info <h6 style={{ color: "red" }}>*</h6>
                      </b>
                    </h5>
                  </div>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                  <div>
                    <div className="d-flex pt-3">
                      <div style={{ marginRight: "20px" }}>
                        <h6>Company Name</h6>
                        <FormControl
                          sx={{ width: "50ch" }}
                          className="city-details"
                        >
                          <OutlinedInput
                            className="name-card"
                            name="company_name"
                            value={billingInfo.company_name}
                            onChange={handleBillingInfo}
                            inputProps={{
                              maxLength: 255,
                            }}
                          />
                        </FormControl>
                      </div>
                      <div>
                        <h6>Name</h6>
                        <FormControl
                          sx={{ width: "46ch" }}
                          className="code-details"
                        >
                          <OutlinedInput
                            className="name-card"
                            name="name"
                            value={billingInfo.name}
                            onChange={handleBillingInfo}
                            inputProps={{
                              maxLength: 255,
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div className="pt-3">
                      <div>
                        <div className="d-flex">
                          <div style={{ marginRight: "20px" }}>
                            <h6>Email ID</h6>
                            <FormControl
                              sx={{ width: "85ch", marginBottom: "10px" }}
                              className="email-input-details-billing"
                            >
                              <OutlinedInput
                                className="name-card"
                                name="email"
                                value={billingInfo.email}
                                onChange={handleBillingInfo}
                                inputProps={{
                                  maxLength: 255,
                                }}
                              />
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <div style={{ padding: "0px 50px" }}>
                <hr />
              </div>
              <Grid container spacing={2} style={{ padding: "30px 50px" }}>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <div>
                    <h5>
                      <b>GST Details (Optional)</b>
                    </h5>
                  </div>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                  <div>
                    <div style={{ marginBottom: "10px" }}>
                      <FormControl
                        sx={{ width: "98ch" }}
                        className="email-input-details"
                      >
                        <OutlinedInput
                          className="name-card"
                          value={gstDetails.toUpperCase()}
                          onChange={(e) => setGstDetails(e.target.value)}
                          inputProps={{
                            maxLength: 255,
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <div style={{ padding: "0px 50px" }}>
                <hr />
              </div>
              <Grid container spacing={2} style={{ padding: "30px 50px" }}>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <div>
                    <h5>
                      <b>PO Details (Optional)</b>
                    </h5>
                  </div>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                  <div>
                    <div style={{ marginBottom: "10px" }}>
                      <FormControl
                        sx={{ width: "98ch" }}
                        className="email-input-details"
                      >
                        <OutlinedInput
                          className="name-card"
                          value={poDetails}
                          onChange={(e) => setPoDetails(e.target.value)}
                          inputProps={{
                            maxLength: 255,
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <div style={{ padding: "0px 50px" }}>
                <hr />
              </div>
              <Grid container spacing={2} style={{ padding: "30px 50px" }}>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <div>
                    <h5>
                      <b>Billing Address</b>
                    </h5>
                  </div>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                  <div style={{ marginRight: "20px" }}>
                    <h6 className="d-flex">
                      Street address{" "}
                      <b style={{ color: "red", marginLeft: "4px" }}>*</b>
                    </h6>
                    <FormControl
                      sx={{ width: "98ch" }}
                      className="email-input-details"
                    >
                      <OutlinedInput
                        className="name-card"
                        name="street_address"
                        value={billingAddress.street_address}
                        onChange={handleBillingAddress}
                      />
                    </FormControl>
                  </div>
                  <div className="d-flex pt-3">
                    <div style={{ marginRight: "20px" }}>
                      <h6>
                        City <b style={{ color: "red" }}>*</b>
                      </h6>
                      <FormControl
                        sx={{ width: "50ch" }}
                        className="city-details"
                      >
                        <OutlinedInput
                          className="name-card"
                          name="city"
                          value={billingAddress.city}
                          onChange={handleBillingAddress}
                          inputProps={{
                            maxLength: 255,
                          }}
                        />
                      </FormControl>
                    </div>
                    <div>
                      <h6>
                        Area Code <b style={{ color: "red" }}>*</b>
                      </h6>
                      <FormControl
                        sx={{ width: "46ch" }}
                        className="code-details"
                      >
                        <OutlinedInput
                          type="number"
                          className="name-card"
                          name="area_code"
                          value={billingAddress.area_code}
                          onChange={handleBillingAddress}
                          inputProps={{
                            maxLength: 255,
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="d-flex pt-3">
                    <div style={{ marginRight: "20px" }}>
                      <h6>
                        State <b style={{ color: "red" }}>*</b>
                      </h6>
                      <FormControl
                        sx={{ width: "50ch" }}
                        className="city-details"
                      >
                        <OutlinedInput
                          className="name-card"
                          name="state"
                          value={billingAddress.state}
                          onChange={handleBillingAddress}
                          inputProps={{
                            maxLength: 255,
                          }}
                        />
                      </FormControl>
                    </div>
                    <div>
                      <h6>
                        Country <b style={{ color: "red" }}>*</b>
                      </h6>
                      <FormControl
                        sx={{ width: "46ch" }}
                        className="code-details"
                      >
                        <OutlinedInput
                          className="name-card"
                          name="country"
                          value={billingAddress.country}
                          onChange={handleBillingAddress}
                          inputProps={{
                            maxLength: 255,
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                  {/* <div className="pt-3">
                    {location.pathname === "/payment" ? (
                      <button className="save-button-payment" onClick={handleCreatePaymentMethod}>
                        SAVE
                      </button>
                    ): (
                      <>
                        <button
                          className="save-button-payment"
                          style={{ marginRight: "10px" }}
                          onClick={() => setOfflinePopup(true)}
                        >
                          PROCEED OFFLINE
                        </button>
                        <button
                          className="save-button-payment"
                          style={{ marginRight: "10px" }}
                          onClick={() =>
                            handlePaymentCheckoutOnline(
                              setChoiceId1(
                                choice.length
                                  ? choice.find((f) => f.name == "Online").id
                                  : ""
                              )
                            )
                          }
                        >
                          PROCEED ONLINE
                        </button>
                      </>
                    ) }
                  </div> */}
                  <div className="pt-3 d-flex">
                    {paymentMethodId && param.title ? (
                      <>
                        <button
                          className="save-button-payment"
                          style={{ marginRight: "10px" }}
                          onClick={() => setOfflinePopup(true)}
                        >
                          PROCEED OFFLINE
                        </button>
                        <button
                          className="save-button-payment"
                          style={{ marginRight: "10px" }}
                          onClick={() =>
                            handlePaymentCheckoutOnline(
                              setChoiceId1(
                                choice.length
                                  ? choice.find((f) => f.name == "Online").id
                                  : ""
                              )
                            )
                          }
                        >
                          PROCEED ONLINE
                        </button>
                        <div style={{ textAlign: "center" }}>
                          <h6 className="total-price-title">
                            <b>Total Price</b>
                          </h6>
                          <h4 className="total-price">
                            {param.subId
                              ? headerData.next_billing_amount
                              : paymentData?.total_price}
                          </h4>
                        </div>
                        <div className="plus-sign">+</div>
                        <div style={{ textAlign: "center" }}>
                          <h6 className="total-price-title">
                            <b>Gst Amount</b>
                          </h6>
                          <h4 className="total-price">
                            {param.subId
                              ? (
                                  headerData.next_gst_amount -
                                  headerData.next_billing_amount
                                ).toFixed(2)
                              : paymentData?.gst_amount}
                          </h4>
                        </div>
                        <div className="plus-sign">=</div>
                        <div style={{ textAlign: "center" }}>
                          <h6 className="total-price-title">
                            <b>Final Amount</b>
                          </h6>
                          <h4 className="total-price">
                            {param.subId
                              ? headerData.next_gst_amount
                              : paymentData?.final_amount}
                          </h4>
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          className="save-button-payment"
                          style={{ marginRight: "10px" }}
                          onClick={() => handleCreatePaymentMethod()}
                        >
                          SAVE
                        </button>
                      </>
                    )}
                  </div>
                  {offlinePopup && (
                    <Card className="deleteConfirmation-Modal">
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="deleteConfirmation-title"
                      >
                        Proceed with Offline Payment?
                      </Typography>
                      <Box className="deleteConfirmation-btnWrap">
                        <button
                          className="deleteConfirmation-Yesbtn"
                          onClick={() =>
                            handlePaymentCheckoutOffline(
                              setChoiceId(
                                choice.length
                                  ? choice.find((f) => f.name == "Offline").id
                                  : ""
                              )
                            )
                          }
                        >
                          Yes
                        </button>
                        <button
                          className="deleteConfirmation-Nobtn"
                          onClick={() => setOfflinePopup(false)}
                          // onClick={handleConfirmationModal}
                        >
                          No
                        </button>
                      </Box>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </Card>
          </div>
        </Container>
        {isLoading && <Loader />}
      </div>
    </>
  );
};

export default PaymentMethod;
