import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../OverView/OverView.css";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import { fontSize } from "@mui/system";
import { Box, Grid, Typography } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  createOverViewList,
  getDeviceOverview,
  getDeviceUnit,
  getOverViewConfuguration,
} from "../OverView/services";
import Mqqtdata from "../Mqqtdata";
import { useEffect } from "react";
import { images } from "../../../config";
import "../ConfigrationOverView/ConfigrationOverView.css";
import { useRef } from "react";
import { setProcessDashboarDateSet } from "../sliceProcessDashboard/dateSliceprocessDashboard";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableWrapper: {
    position: "relative", // Set the position of the table wrapper to relative
    maxHeight: 600,
    overflow: "auto",
  },
  overlay: {
    position: "absolute", // Set the position of the overlay to absolute
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "#AEBCD2B3", // Overlay color with transparency
    zIndex: 1, // Ensure the overlay is on top of the table
  },
});

const ConfigrationOverView = () => {
  const [overViewTagData, setOverViewTagData] = useState([]);
  const [overViewDeviceData, setOverViewDeviceData] = useState([]);
  const [configrationModal, setConfigrationModal] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [deviceId, setDeviceId] = useState("");
  const [deviceUnit, setDeviceUnit] = useState([]);
  const [selectNumberLength, setSelectNumberLength] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [selectAllDevice, setSelectAllDevice] = useState(false);
  const [selectAllUnits, setSelectAllUnits] = useState(false);
  const [apiDeviceId, setApiDeviceId] = useState([]);
  const [topics, setTopics] = useState([]);
  const [mqttTagData, setMqttTagData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [deviceIndex, setDeviceIndex] = useState([]);
  const [unitIndex, setUnitIndex] = useState([]);
  const [unitId, setUnitId] = useState([]);

  const navigate = useNavigate();
  const classes = useStyles();
  const param = useParams();
  const tableRef = useRef();

  console.log("mqttTagData",mqttTagData)

  // const handleTableScroll = () => {
  //   const tableContainer = tableRef.current;

  //   // Calculate the horizontal scroll percentage
  //   const scrollPercentage = Math.min((tableContainer.scrollLeft / (tableContainer.scrollWidth - tableContainer.clientWidth)) * 100, 100);
  //   console.log("scrollPercentage", scrollPercentage);

  //   // Update the width of the scroll overlay using CSS variables
  //   tableContainer.style.setProperty('--scroll-overlay-width', `${scrollPercentage}%`);

  //   // Your logic here to perform actions when the table is scrolled
  //   console.log("Table is being scrolled!");
  //   // Add your event handling code here
  // };

  // useEffect(() => {
  //   const tableElement = tableRef.current;
  //   tableElement.addEventListener("scroll", handleTableScroll);

  //   return () => {
  //     tableElement.removeEventListener("scroll", handleTableScroll);
  //   };
  // }, []);

  const handleConfigrationClick = (e) => {
    setConfigrationModal(true);
  };
  const handleCloseConfigrationClick = (e) => {
    setConfigrationModal(false);
    e.stopPropagation();
    setSelectedDevices([]);
    setSelectedUnits([]);
  };

  const handelSelectDevice = (row, id) => {
    row.stopPropagation();
    setDeviceId(id);
  };
  useEffect(() => {
    handlegetDevice();
  }, []);

  useEffect(() => {
    if (apiDeviceId.every((value) => selectedDevices.includes(value))) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedDevices, apiDeviceId]);

  const handlegetDevice = async () => {
    const params = {
      plant_id: param.id,
      company_id: param.companyId,
      dashboard : 1,
    };
    try {
      const resp = await getDeviceOverview(params);
      if (resp.data.success == true && resp.data.payload.length) {
        setDeviceList(resp.data.payload);
        if (resp.data.payload.length) {
          setDeviceId(resp.data.payload[0].device);
        }

        setApiDeviceId(resp.data.payload.map((row) => row.device));
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (configrationModal) {
      GetOverviewConfigurationApi();
    }
  }, [configrationModal]);

  const GetOverviewConfigurationApi = async () => {
    const paramRequset = {
      plant_id: param.id,
      company_id: param.companyId,
    };
    const resp = await getOverViewConfuguration(paramRequset);
    if (
      resp.status === 200 &&
      resp.data.payload &&
      resp.data.payload.length > 0
    ) {
      const devicePayload = resp.data.payload[0];
      if (devicePayload.device) {
        const deviceId = devicePayload.device.map((e) => e.id);
        setSelectedDevices(deviceId);
      }
      if (devicePayload.tag_assigned) {
        const unitId = devicePayload.tag_assigned.map((e) => e.id);
        setSelectedUnits(unitId);
      }
    }
  };

  useEffect(() => {
    GetOverviewConfigurationOverViewTableApi();
  }, []);
  const GetOverviewConfigurationOverViewTableApi = async () => {
    const paramRequset = {
      plant_id: param.id,
      company_id: param.companyId,
    };
    const resp = await getOverViewConfuguration(paramRequset);
    if (
      resp.status === 200 &&
      resp.data.payload &&
      resp.data.payload.length > 0
    ) {
      setOverViewTagData(resp.data.payload[0].tag_assigned);
      setOverViewDeviceData(resp.data.payload[0].device);
    }
  };

  useEffect(() => {
     handleGetUnit();
  }, [deviceId, selectedUnits]);

  const handleGetUnit = async () => {
    const params = {
      device_id: deviceId,
      plant_id: param.id,
      company_id: param.companyId,
    };
    try {
      const resp = await getDeviceUnit(params);
      if (resp.data.success == true) {
        setDeviceUnit(resp.data.payload);
        // const selected = [];
        // const select = resp.data.payload.map((e) => {
        //   if (e.is_selected) {
        //     selected.push(e.id);
        //   }
        // });

        const getDeviceId = resp.data.payload.map((e) => e.id);

        if (getDeviceId.every((value) => selectedUnits.includes(value))) {
          setSelectAllUnits(true);
        } else {
          setSelectAllUnits(false);
          // console.log("selected",selected);
        }
        // setSelectedUnits(selected);
      }
    } catch (error) {
      setDeviceUnit([]);
      console.log("handlesubmit", error);
    }
  };

  const handleCheckBox = (id) => {
    if (selectedUnits.includes(id)) {
      setSelectedUnits(selectedUnits.filter((d) => d !== id));
    } else {
      setSelectedUnits([...selectedUnits, id]);
    }
  };

  const handledeviceCheckBox = (device) => {
    if (selectedDevices.includes(device)) {
      setSelectedDevices(selectedDevices.filter((d) => d !== device));
    } else {
      setSelectedDevices([...selectedDevices, device]);
    }
  };

  const handleSelectDevice = (id, index) => {
    if (!Array.isArray(deviceId)) {
      setDeviceId([]);
    }
    if (deviceId.includes(id) || deviceIndex.includes(index)) {
      setDeviceId(deviceId.filter((element) => element !== id));
    } else {
      setDeviceId([id]);
    }

    if (!Array.isArray(deviceIndex)) {
      setDeviceIndex([]);
    }
    if (deviceIndex.includes(index)) {
      setDeviceIndex(deviceIndex.filter((element) => element !== index));
    } else {
      setDeviceIndex([index]);
    }
  };

  const handleSelectUnit = (id, index) => {
    if (!Array.isArray(unitId)) {
      setUnitId([]);
    }
    if (unitId.includes(id) || deviceIndex.includes(index)) {
      setUnitId(unitId.filter((element) => element !== id));
    } else {
      setUnitId([id]);
    }

    if (!Array.isArray(unitIndex)) {
      setUnitIndex([]);
    }
    if (unitIndex.includes(index)) {
      setUnitIndex(unitIndex.filter((element) => element !== index));
    } else {
      setUnitIndex([index]);
    }
  };

  function handleDeviceUpMoveRaw(id) {
    const itemIndex = overViewDeviceData.findIndex((item) => item.id === id);

    if (!deviceIndex.length) {
      console.log("overViewDeviceData");
      return true;
    }

    if (deviceIndex[0] == -1) {
      console.log("overViewDeviceData");
      return true;
    }

    if (itemIndex !== -1) {
      const item = overViewDeviceData[itemIndex];
      overViewDeviceData.splice(itemIndex, 1); // Remove the item from its current position
      overViewDeviceData.splice(deviceIndex[0], 0, item); // Insert the item at the new position
      setOverViewDeviceData(overViewDeviceData);
      setDeviceIndex([deviceIndex[0] - 1]);
    }
  }

  function handleDeviceDownMoveRaw(id) {
    const itemIndex = overViewDeviceData.findIndex((item) => item.id === id);

    if (!deviceIndex.length) {
      console.log("overViewDeviceData");
      return true;
    }

    if (deviceIndex[0] == overViewDeviceData.length - 1) {
      console.log("overViewDeviceData");
      return true;
    }

    if (itemIndex !== -1) {
      const item = overViewDeviceData[itemIndex];
      overViewDeviceData.splice(itemIndex, 1); // Remove the item from its current position

      // Adjust the target index based on the current deviceIndex
      const targetIndex = deviceIndex[0] === 0 ? 1 : deviceIndex[0] + 1;

      overViewDeviceData.splice(targetIndex, 0, item); // Insert the item at the new position
      setOverViewDeviceData([...overViewDeviceData]); // Make a shallow copy before updating the state
      setDeviceIndex([deviceIndex[0] + 1]); // Update the deviceIndex
    }
  }

  function handleUnitLeftMoveRaw(id) {
    const itemIndex = overViewTagData.findIndex((item) => item.id === id);

    if (!unitIndex.length) {
      console.log("overViewDeviceData");
      return true;
    }

    if (unitIndex[0] == -1) {
      console.log("overViewDeviceData");
      return true;
    }

    if (itemIndex !== -1) {
      const item = overViewTagData[itemIndex];
      overViewTagData.splice(itemIndex, 1); // Remove the item from its current position
      overViewTagData.splice(unitIndex[0], 0, item); // Insert the item at the new position
      setOverViewTagData(overViewTagData);
      setUnitIndex([unitIndex[0] - 1]);
    }

    // if (!unitIndex.length) {
    //   console.log("overViewDeviceData");
    //   return true;
    // }

    // if (unitIndex[0] == 0) {
    //   console.log("overViewDeviceData");
    //   return true;
    // } else {
    //   const updateArray = [...overViewTagData]
    //   const element = updateArray.splice(indexToMove, 1)[0];
    //   updateArray.splice(indexToMove - 1, 0, element);
    //   setOverViewTagData(updateArray);
    //   setDeviceIndex([deviceIndex[0] - 1])
    // }
  }

  function handleUnitRightMoveRaw(id) {
    const itemIndex = overViewTagData.findIndex((item) => item.id === id);

    if (!unitIndex.length) {
      console.log("overViewTagData");
      return true;
    }

    if (unitIndex[0] == overViewTagData.length - 1) {
      console.log("overViewTagData");
      return true;
    }

    if (itemIndex !== -1) {
      const item = overViewTagData[itemIndex];
      overViewTagData.splice(itemIndex, 1); // Remove the item from its current position

      // Adjust the target index based on the current unitIndex
      const targetIndex = unitIndex[0] === 0 ? 1 : unitIndex[0] + 1;

      overViewTagData.splice(targetIndex, 0, item); // Insert the item at the new position
      setOverViewTagData([...overViewTagData]); // Make a shallow copy before updating the state
      setUnitIndex([unitIndex[0] + 1]); // Update the deviceIndex
    }
  }

  const handleAllCheckBox = (event) => {
    if (event.target.checked) {
      const allDeviceIds = deviceList.map((e) => e.device);
      setSelectNumberLength(allDeviceIds);
    } else {
      setSelectNumberLength([]);
    }
  };

  const handleSelectAllUnitCheckbox = () => {
    if (selectAllUnits) {
      // setSelectedUnits([]);
      setSelectedUnits(
        selectedUnits.filter((d) => !deviceUnit.map((e) => e.id).includes(d))
      );
    } else {
      const allDeviceIds = deviceUnit.map((e) => e.id);
      setSelectedUnits([...selectedUnits].concat(allDeviceIds));
    }
    setSelectAllUnits(!selectAllUnits);
  };

  const handleSelectAllCheckBox = () => {
    if (selectAll) {
      setSelectedDevices([]);
    } else {
      const allDeviceIds = deviceList.map((e) => e.device);
      setSelectedDevices(allDeviceIds);
    }
    setSelectAll(!selectAll);
  };

  const handleSubmitData = async () => {
    const params = {
      plant_id: Number(param.id),
      company_id: Number(param.companyId),
      device: selectedDevices,
      tag_assigned: selectedUnits,
    };
    try {
      const resp = await createOverViewList(params);
      if (resp.data.success == true) {
        setConfigrationModal(false);
        setSelectedDevices([]);
        setSelectedUnits([]);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (overViewDeviceData.length) {
      const topicArray = overViewDeviceData.map(
        (e) => "Hikar/DeviceUID/" + e.uid
      );
      // console.log("topicArray", topicArray);
      setTopics(topicArray);
    }
  }, [overViewDeviceData]);
  const { user_access } = useSelector((state) => state.userState);

  useEffect(() => {
    if (overViewTagData.length) {
      const mergedArray = [].concat(...Object.values(mqttTagData));

      const filteredArray = mergedArray.filter((obj) => {
        const device = obj.device__uid;
        const deviceTag = obj.device_tag;
        return overViewTagData.some(
          (item) => item.device__uid === device && item.device_tag === deviceTag
        );
      });
      console.log("filteredArray", filteredArray);
      setTagData(filteredArray);
    }
  }, [mqttTagData]);

  const plantDashboardAccess = user_access.length
  ? user_access.find(
      (user) => user.access_module?.access_module_name === "Plant Dashboard"
    )
  : null;

const overViewAccess = plantDashboardAccess
  ? plantDashboardAccess.sub_module.length
    ? plantDashboardAccess.sub_module.find(
        (f) => f.sub_module_name === "Overview"
      )
    : null
  : null;

  const dispatch = useDispatch()
  
  const { selectUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );
  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  const handleTostMsg = () => {
    notifyError("You don't have access");
  };
  const handleConfOverViewNavigate =()=>{
    navigate(`/overView/company/${param.companyId}/plant/${param.id}`)
  }
  return (
    <>
      {overViewDeviceData.length ? (
        <Mqqtdata
          setMqttTagData={setMqttTagData}
          topics={topics}
          overViewTagData={overViewTagData}
        />
      ) : (
        ""
      )}
      <div className="page-wraper pb-5">
        {/* <div className="page-header d-flex justify-content-between">
          <div
            onClick={() =>
              navigate(`/overView/company/${param.companyId}/plant/${param.id}`)
            }
            className="page-back-btn"
          >
            <ArrowBackIcon />
            <span>Overview</span>
          </div>

          <div className="header-btn-group d-flex">
            <button
              className="overviewReport"
              style={{ pointerEvents: "none" }}
            >
              Report & Analysis
            </button>
          </div>
        </div> */}
           <div className="page-header d-flex justify-content-between overview-table-header">
          <div
            onClick={() =>
              navigate(
                `/company/${param.companyId}/plant/plantprocessdashboard/${param.id}`
              )
            }
            className="page-back-btn"
          >
            <ArrowBackIcon />
            <span>Overview</span>
          </div>

          <div className="header-btn-group d-flex">
            <button
              className="overviewReport"
              style={{ pointerEvents: "none" }}
            >
              Report & Analysis
            </button>
          </div>
        </div>
        <div className="overview-card-header">
        <div className="page-header d-flex justify-content-between">
          <div
            className="d-flex justify-content-between"
            style={{ width: "100%",flexWrap:"wrap" }}
          >
            <div
              onClick={() =>
                navigate(
                  `/company/${param.companyId}/plant/plantprocessdashboard/${param.id}`
                )
              }
              className="over-view-header"
              style={{ marginTop: "-19px" }}
            >
              <ArrowBackIcon />
              <span>Overview</span>
            </div>
            <div className="d-flex gap-2">
              <div>
                <select
                  className="compact-btn"
                  value={selectUnit}
                  onChange={(e) =>
                    dispatch(
                      setProcessDashboarDateSet({ selectUnit: e.target.value })
                    )
                  }
                >
                  <option value="k">Kilo</option>
                  <option value="m">Mega</option>
                  <option value="g">Giga</option>
                </select>
              </div>
              <div>
                {(overViewAccess?.is_viewer && overViewAccess?.is_editor) ||
                (!overViewAccess?.is_viewer && overViewAccess?.is_editor) ||
                (overViewAccess?.is_viewer && !overViewAccess?.is_editor) ? (
                  <button
                    className="configration-btn confi-btn"
                    onClick={() => handleConfOverViewNavigate()}
                  >
                    <img
                      src={images.configration}
                      style={{
                        height: "18px",
                        width: "18px",
                        marginRight: "8px",
                      }}
                      alt=""
                    />
                    Configuration
                  </button>
                ) : (
                  <button className="configration-btn" onClick={handleTostMsg}>
                    <img
                      src={images.configration}
                      style={{
                        height: "18px",
                        width: "18px",
                        marginRight: "8px",
                      }}
                      alt=""
                    />
                    Configuration
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3">
     
            <button
              className="overviewReport"
              style={{ float: "right", marginTop: "-9px",pointerEvents: "none"}}
        
            >
              Report & Analysis
            </button>
        
        </div>
        </div>
        <div className="pt-3">
          <div
            className={classes.tableWrapper}
            onClick={handleConfigrationClick}
            style={{ position: "relative" }}
          >
         <div className={classes.overlay} />
            <TableContainer
              component={Paper}
              className="overviewtable-data-container"
              style={{
                // backgroundColor: "#AEBCD2B3",
                border: "1px dashed #1C407B",
                position: "relative",
              }}
              ref={tableRef}
            >
              <Table
                className="overView-data-table overView-table-data1"
                aria-label="my table"
              >
                <TableHead>
                  <TableRow className={classes.stickyHeader}>
                    <TableCell
                      colSpan={2}
                      style={{
                        // background: "#ffff",
                        position: "sticky",
                        left: "-8px",
                        // zIndex: 99,
                      }}
                    >
                      {/* <TableCell colSpan={2} style={{ background: "#ffff"}}> */}
                      <div className="d-flex">
                        <div>
                          <div
                            onClick={() => handleDeviceUpMoveRaw(deviceId[0])}
                            style={{ cursor: "pointer" }}
                          >
                            <img src={images.up} alt="" />
                          </div>
                          <div
                            onClick={() => handleDeviceDownMoveRaw(deviceId[0])}
                            style={{ cursor: "pointer" }}
                          >
                            <img src={images.down} alt="" />
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: "10px",
                            marginLeft: "16px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleUnitLeftMoveRaw(unitId[0])}
                        >
                          <img src={images.left} alt="" />
                        </div>
                        <div
                          style={{ marginTop: "10px", cursor: "pointer" }}
                          onClick={() => handleUnitRightMoveRaw(unitId[0])}
                        >
                          <img src={images.right1} alt="" />
                        </div>
                      </div>
                    </TableCell>
                    {overViewTagData.length
                      ? overViewTagData.map((row, index) => {
                          // Check if the current name has already been included
                          const isDuplicate = overViewTagData
                            .slice(0, index)
                            .some(
                              (prevRow) => prevRow.tag_unit === row.tag_unit
                            );

                          if (!isDuplicate) {
                            return (
                              <TableCell
                                style={{
                                  fontWeight: "700",
                                  fontSize: "17px",
                                  background: "#fff",
                                }}
                                key={row.tag_unit}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Checkbox
                                    onClick={() =>
                                      handleSelectUnit(row.id, index)
                                    }
                                  />
                                  <span style={{ marginLeft: "5px" }}>
                                    {row.tag_unit}
                                  </span>
                                </div>
                              </TableCell>
                            );
                          }

                          return null;
                        })
                      : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {overViewDeviceData.length
                    ? overViewDeviceData.map((row, ind) => {
                        return (
                          <TableRow>
                            <TableCell style={{ width: "0%" }}>
                              <Checkbox
                                onClick={() => handleSelectDevice(row.id, ind)}
                              />
                            </TableCell>
                            <TableCell>{row.device_name}</TableCell>
                            {overViewTagData.length
                              ? overViewTagData.map((row2, index) => {
                                  const findTagUnitValue1 = tagData.find(
                                    (f) =>
                                      f.tagUnit == row2.tag_unit &&
                                      f.device__uid == row.machine_uid
                                  );

                                  const isDuplicate = overViewTagData
                                    .slice(0, index)
                                    .some(
                                      (prevRow) =>
                                        prevRow.tag_unit === row2.tag_unit
                                    );

                                  if (!isDuplicate) {
                                    return (
                                      <TableCell>
                                        {findTagUnitValue1
                                          ? findTagUnitValue1.tagValue
                                          : ""}
                                      </TableCell>
                                    );
                                  }

                                  return null;
                                })
                              : null}
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>

            {configrationModal && (
              <>
               <div className="overlay123">
                <div className="deviceModal-popup">
              
                    <div className="d-flex devices-text">
                  <h6 style={{ marginTop: "7px" }}>Devices</h6>
                 
                </div>

                  <Grid container spacing={2} className="pt-2">
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <Box className="devicemodal-box">
                        <FormGroup>
                          <FormControlLabel
                            style={{ display: "flex", justifyContent: "end" }}
                            control={
                              <Checkbox
                                style={{
                                  color: "#1C407B",
                                  padding: "0px",
                                  marginRight: "3px",
                                }}
                                checked={selectAll}
                                onClick={handleSelectAllCheckBox}
                              />
                            }
                            label="All"
                          />
                        </FormGroup>
                        <div className="configration-details">
                        <div style={{ maxHeight: "420px", overflow: "auto" }}>
                          {deviceList.length
                            ? deviceList.map((e) => {
                                return (
                                  <Box
                                    className={`Device-caption d-flex ${
                                      deviceId == e.device
                                        ? "Device-caption1"
                                        : ""
                                    }  mb-2`}
                                    onClick={(row) =>
                                      handelSelectDevice(row, e.device)
                                    }
                                  >
                                    <>
                                      <div>
                                        <Checkbox
                                          style={{
                                            color: "#1C407B",
                                            marginTop: "8px",
                                            marginLeft: "-10px",
                                          }}
                                          checked={selectedDevices.includes(
                                            e.device
                                          )}
                                          onClick={() =>
                                            handledeviceCheckBox(e.device)
                                          }
                                        />
                                      </div>
                                      <div>
                                        <h6>{e.device__device_name}</h6>
                                        <p>UID - {e.device__machine__uid}</p>
                                        <p>{e.device__device_description}</p>
                                      </div>
                                    </>
                                  </Box>
                                );
                              })
                            : []}
                        </div>
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                      <Box className="devicemodal-box1">
                        <FormGroup>
                          <FormControlLabel
                            style={{ display: "flex", justifyContent: "end" }}
                            control={
                              <Checkbox
                                style={{
                                  color: "#1C407B",
                                  padding: "0px",
                                  marginRight: "3px",
                                }}
                                checked={selectAllUnits}
                                onClick={handleSelectAllUnitCheckbox}

                                // onClick={handleSelectAll}
                              />
                            }
                            label={
                              <Typography style={{ fontSize: "15px" }}>
                                All
                              </Typography>
                            }
                          />
                        </FormGroup>
                        <div
                          class="container pt-2"
                          style={{
                            maxHeight: "420px",
                            overflow: "auto",
                          
                          }}
                        >
                          {deviceUnit.length ? (
                            <div class="row">
                              {deviceUnit.map((e, index) => (
                                <div className="col-3 mt-4" key={index}>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          style={{
                                            color: "#1C407B",
                                            padding: "0px",
                                            marginRight: "8px",
                                          }}
                                          checked={selectedUnits.includes(e.id)}
                                          onClick={() => handleCheckBox(e.id)}
                                          disabled={!selectedDevices.includes(e.device_id)}
                                          // onClick={handleClick}
                                        />
                                      }
                                      label={
                                        <Typography
                                          style={{ fontSize: "15px" }}
                                        >
                                          {e.tag_unit}
                                        </Typography>
                                      }
                                    />
                                  </FormGroup>
                                </div>
                              ))}
                            </div>
                          ) : (
                            []
                          )}
                        </div>
                      </Box>
                    </Grid>
                  </Grid>

                  <div className="text-center pt-5 confi-overview-btn gap-2 d-flex justify-content-center">
                    <button
                      className="canclebtn"
                      onClick={(e) => handleCloseConfigrationClick(e)}
                    >
                      Cancel
                    </button>
                    <button
                      className="submitbtn"
                      onClick={() => handleSubmitData()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* <div>
        <FooterText/>
        </div> */}
      </div>
    </>
  );
};
export default ConfigrationOverView;
