import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Menu } from "@mui/material";
import Box from "@mui/material/Box";
import TabPanel from "@mui/lab/TabPanel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OTPInput from "otp-input-react";
// import Menu from '@material-ui/core';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { Card, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import uploadLogoImgage from "../../../assets/images/UploadLogo.png";
import {
  getCountry,
  getCountryList,
  getMobileOTP,
  updateUserProfile,
  verifyUserMobileOTP,
  getTimeZoneList
} from "../service";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PlacesAutocomplete from "react-places-autocomplete";
import VerifiedIcon from "@mui/icons-material/Verified";
import "./PersonalTab.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { images } from "../../../config/images";
import { setUserDetails } from "../../Login/slice/userSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    // justifyContent: 'space-between',
    // maxWidth: 400,
    maxWidth: "100%",
    margin: "auto",
  },
}));

const PersonalTab = ({
  userProfile,
  setUserProfile,
  value,
  setValue,
  getUserProfile,
  mobielNo,
  mobileCountryCode,
  setMobileNo,
  setMobileCountryCode,
}) => {
  const [selecteCountry, setSelecteCountry] = React.useState("India");
  const [selectUserCountryCode, setUserCountryCode] = useState("");
  const [countryData, setCountryData] = useState([]);
  const { id } = useSelector((state) => state.userState);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [countryCode, setCountryCode] = useState("91");
  const [uploadLogo, setUploadLogo] = useState("");
  const [OTPMobile, setOTPMobile] = useState("");
  const [openOtpMobileModel, setOpenOtMobilepModel] = useState(false);
  const [greenIcone, setGreenIcone] = useState(false);
  const [address, setAddress] = useState("");
  const [siteAddress, setSiteAddress] = useState("");
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(60);
  const [visibleOTP, setvisibleOTP] = useState(false);
  const [mobileCountryCode1, setMobileCountryCode1] = useState("+1"); // Default value, change as needed
  const [selectUserCountry, setSelectUserCountry] = useState("");
  const { user_access } = useSelector((state) => state.userState);
  const [timeZones, setTimeZones] = useState([]);
  const [selectedTimeZone,setSelectedTimeZone] = useState("")


  const { selectTimeZoneProfile } = useSelector(state => state.userState);
  console.log("selectTimeZoneProfile",selectTimeZoneProfile)
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const { mobileNoRe } = useSelector((state) => state.userState);

  const selecteCountryCode = countryData.length
    ? countryData.find((f) => f.id == selecteCountry)
    : "";

  const handleChangeCountryCode1 = (event) => {
    const selectedCode = event.target.value;
    setMobileCountryCode(selectedCode);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedItem(index);
    setAnchorEl(null);
    setCountryCode(event.target.value);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // find Place
  const handleSelect = (value) => {
    setAddress(value.split(", ")[0]);
    setSiteAddress(value);
  };

  const notify = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleChangeSelectCountry = (event) => {
    let selectedCountryCode = event.target.value;
    let selectedCountry = event.target.id;
    console.log("selectedCountryCode", selectedCountryCode);
    console.log("countryData", countryData);
    const selectedCountry1 = countryData.find(
      (country) => country.country === selectedCountryCode
    );
    if (selectedCountry1) {
      setUserCountryCode(selectedCountry1.id);
      setSelecteCountry(selectedCountry1.country);
    }
  };

  const getCountryListAPI = async () => {
    try {
      const resp = await getCountryList();
      if (resp.status == 200) {
        setCountryData(resp.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTimeZoneListAPI = async () => {
    try {
      const response = await getTimeZoneList();
      if (response.status === 200 || response.status === 201) {
        console.log("responseTimeZone", response.data.payload);
        setTimeZones(response.data.payload);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  }
const dispatch = useDispatch()
  const handleTimeZoneChange = (event) => {
    dispatch(setUserDetails({selectTimeZoneProfile : event.target.value}))
    setSelectedTimeZone(event.target.value);
    console.log("Selected TimeZone", event.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, 
        width: 250,
      },
    },
  };

    useEffect(() => {
      getCountryListAPI();
      getTimeZoneListAPI();
    }, []);

    useEffect(() => {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }, [seconds]);

    useEffect(() => {
      setAddress(userProfile.company_name);
      setSiteAddress(
        userProfile.site_address == "null" ? "" : userProfile.site_address
      );
      setSelecteCountry(userProfile.user_country_name);
      setUserCountryCode(userProfile.user_country);
      setSelectedTimeZone(userProfile.preferred_timezone);
      dispatch(setUserDetails({selectTimeZoneProfile : userProfile.preferred_timezone}))
      console.log("userProfile", userProfile);
    }, [userProfile.company_name, userProfile.site_address, value,userProfile.preferred_timezone]);

    useEffect(() => {
      const firstThreeDigits = Math.floor(
        userProfile.mobile_number /
        Math.pow(10, Math.floor(Math.log10(userProfile.mobile_number)) - 1)
      );
      setCountryCode(firstThreeDigits);
    }, []);

    const handlePersonalDataForm = (e) => {
      setUserProfile({ ...userProfile, [e.target.name]: e.target.value });
    };

    const handleChangeMobile = (e) => {
      if (!openOtpMobileModel) {
        setMobileNo(e.target.value);
      }
    };

    const handleChangeCountryCode = (e) => {
      setMobileCountryCode(e.target.value);
    };

    // OTP Model
    const handleCloseOtpMobile = () => {
      setOpenOtMobilepModel(!openOtpMobileModel);
    };

    const handleLogoUpload = (event) => {
      const logoFile = event.target.files[0];
      setUserProfile({ ...userProfile, company_logo: logoFile.name });
      setUploadLogo(logoFile);
    };

    function validateMobileNumber(mobileNumber) {
      // Regular expression pattern for a valid 10-digit mobile number
      var pattern = /^[1-9]{1}[0-9]{9}$/;

      // Test the input mobile number against the pattern
      return pattern.test(mobileNumber);
    }

    const updateSubmit = async () => {
      const formData = new FormData();
      formData.append("first_name", userProfile.first_name);
      formData.append("last_name", userProfile.last_name);
      formData.append("company_name", address);
      formData.append("site_address", siteAddress);
      formData.append("job_title", userProfile.job_title);
      formData.append("two_step_verification", userProfile.two_step_verification);
      formData.append("mfa", userProfile.mfa);
      formData.append("communication_email", userProfile.communication_email);
      formData.append(
        "communication_phone_call",
        userProfile.communication_phone_call
      );
      formData.append("preferred_timezone", selectTimeZoneProfile);

      if (!mobielNo) {
        notifyError("Please Verify Mobile No");
        return true;
      }

      if (userProfile.mobile_number) {
        if (
          userProfile.mobile_number != Number(`${mobileCountryCode}${mobielNo}`)
        ) {
          notifyError("Please Verify Mobile No");
          return true;
        }
      }

      if (!validateMobileNumber(mobielNo)) {
        notifyError("Invalid mobile number.");
        return true;
      }

      if (mobielNo) {
        formData.append(
          "mobile_number",
          Number(`${mobileCountryCode}${mobielNo}`)
        );
      }

      if (uploadLogo && userProfile.company_name) {
        formData.append("company_logo", uploadLogo);
      }

      if (selectUserCountryCode) {
        formData.append("user_country", selectUserCountryCode);
      }

      if (!selectTimeZoneProfile) {
        notifyError("Please select timezone");
        return true;
      }

      try {
        const resp = await updateUserProfile(id, formData);
        console.log("respresp", resp)
        if (resp) {
          notify("Updated Successfully");
          getUserProfile();
          setValue("2");
          console.log("respresp", resp)
        }
      } catch (error) {
        notifyError(error.response.data.message);
      }
    };

    const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };
      const formattedDate = `${date.toLocaleTimeString("en-US", options)} IST`;
      return formattedDate;
    };

    const dateStr = userProfile.last_login;
    console.log("dateStrdateStrdateStr", dateStr);
    const formattedDate = dateStr ? formatDate(dateStr) : "-";

    // mobile verification
    const handlegetMobileOTP = async () => {
      if (!mobielNo) {
        notifyError("Please Fill the Mobile No");
        return true;
      }

      if (mobielNo.toString().length != 10) {
        notifyError("Wrong Mobile No.");
        return true;
      }

      if (!validateMobileNumber(mobielNo)) {
        notifyError("Invalid Mobile Number.");
        return true;
      }

      const params = {
        mobile_number: Number(mobileCountryCode + mobielNo),
      };
      try {
        const resp = await getMobileOTP(params);
        if (resp.data.success == true) {
          setOpenOtMobilepModel(!openOtpMobileModel);
          // getUserProfile();
          setSeconds(60);
          notify("OTP Sent Successfully");
        }
      } catch (error) {
        notifyError(error.response.data.message);
      }
    };

    const handleverifyMobileOTP = async () => {
      if (OTPMobile.length != 6) {
        notifyError("Please fill the OTP");
        return true;
      }
      const params = {
        mobile_number: Number(`${mobileCountryCode}${mobielNo}`),
        otp: OTPMobile,
      };
      const resp = await verifyUserMobileOTP(params);
      if (resp.status == 200 && resp.data.success == true) {
        setOpenOtMobilepModel(false);
        setOTPMobile("");
        setGreenIcone(true);
        getUserProfile();
        notify("Mobile No. Verify Successfully");
      }

      if (resp.data.success == false) {
        notifyError(resp.data.message);
      }
    };

    const handlegetMobileResendOTP = async () => {
      const params = {
        mobile_number: Number(`${mobileCountryCode}${mobielNo}`),
      };
      const resp = await getMobileOTP(params);
      if (resp.data.success == true) {
        notify("OTP Sent Successfully");
        setSeconds(60);
      }
    };

    const indiaAddressRegex = `/${selecteCountry}\b/`;

    const options = {
      componentRestrictions: {
        country: selecteCountryCode ? selecteCountryCode.country_code_name : "",
      },
    };

    const shouldRenderSuggestions = (value) => {
      return indiaAddressRegex.test(value);
    };

    const superAdminAccess = user_access.length
      ? user_access.find(
        (user) => user.access_module?.access_module_name === "Admin Dashboard"
      )
      : null;

    const userProfileAccess = superAdminAccess
      ? superAdminAccess.sub_module.length
        ? superAdminAccess.sub_module.find(
          (f) => f.sub_module_name == "User Profile"
        )
        : null
      : null;

    const handleToastMsg = () => {
      notifyError("You don't have access");
    };

    return (
      <>
        <TabPanel value="1">
          <Typography variant="subtitle1">Last login {formattedDate}</Typography>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={12} md={6} lg={5}>
              <Typography
                variant="subtitle1"
                gutterBottom
                pt="20px"
                style={{ fontWeight: 600, color: "#001323" }}
              >
                {" "}
                Your Personal Details
              </Typography>
              <Stack spacing={2} pt="10px">
                <TextField
                  id="filled-basic"
                  name="first_name"
                  label="First Name"
                  variant="filled"
                  className="customeTextField personal"
                  value={userProfile?.first_name}
                  onChange={handlePersonalDataForm}
                  InputLabelProps={{
                    shrink: Boolean(userProfile?.first_name),
                  }}
                />
                <TextField
                  id="filled-basic"
                  name="last_name"
                  label="Last Name"
                  variant="filled"
                  className="customeTextField personal"
                  value={userProfile?.last_name}
                  onChange={handlePersonalDataForm}
                  InputLabelProps={{
                    shrink: Boolean(userProfile?.last_name),
                  }}
                />
                <TextField
                  id="filled-basic"
                  label="Email"
                  variant="filled"
                  className="customeTextField personal"
                  value={userProfile?.email}
                  disabled
                  InputLabelProps={{
                    shrink: Boolean(userProfile?.email),
                  }}
                />
                <TextField
                  id="phone-number"
                  label="Mobile No"
                  variant="filled"
                  value={mobielNo}
                  onChange={handleChangeMobile}
                  InputLabelProps={{
                    style: { marginBottom: "10px" },
                  }}
                  //  style={{padding:'20px 0px 20px 0px'}}
                  InputProps={{
                    inputProps: { maxLength: 10 },
                    startAdornment: (
                      <div className="dropdown-container">
                        <select
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            marginRight: "0px",
                            paddingTop: "0px",
                            cursor: "pointer",
                            fontSize: "14px",
                          }}
                          className="PersonalTabn-dropdown"
                          value={selecteCountry ? selecteCountry : "India"}
                          onChange={handleChangeSelectCountry}
                        >
                          {countryData.length
                            ? countryData.map((row) => {
                              console.log("row", row);
                              return (
                                <option
                                  id={row.country_code_name}
                                  value={row.country}
                                  style={{ paddingTop: "20px" }}
                                >
                                  {row.country_code}
                                </option>
                              );
                            })
                            : []}
                        </select>
                      </div>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {userProfile.mobile_number ==
                          Number(`${mobileCountryCode}${mobielNo}`) ? (
                          <VerifiedIcon
                            style={{ color: "green", cursor: "poiter" }}
                          />
                        ) : (userProfileAccess?.is_viewer &&
                          userProfileAccess?.is_editor) ||
                          (!userProfileAccess?.is_viewer &&
                            userProfileAccess?.is_editor) ? (
                          <button
                            onClick={handlegetMobileOTP}
                            className="personalTab-verifyButton"
                          >
                            Verify
                          </button>
                        ) : (
                          <button
                            onClick={handleToastMsg}
                            className="personalTab-verifyButton"
                          >
                            Verify
                          </button>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControl
                  variant="filled"
                  sx={{ minWidth: 120 }}
                  className="customeTextField personal"
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Time zone
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={selectedTimeZone}
                    MenuProps={MenuProps}
                    onChange={handleTimeZoneChange}
                  >
             
                    {timeZones.map((zone, index) => (
                      <MenuItem key={index} value={zone}>
                        {zone}
                      </MenuItem>
                    ))}

                  </Select>
                </FormControl>
                <Typography variant="subtitle1" className="userId">
                  User Id: {userProfile ? userProfile.user_id : ""}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={5}>
              <Typography
                variant="subtitle1"
                className="userProfile-companyDetails"
                style={{ fontWeight: 600, color: "#001323" }}
              >
                Your Company Details
              </Typography>
              <Stack spacing={2} pt="15px">
                <FormControl
                  variant="filled"
                  sx={{ minWidth: 120 }}
                  className="customeTextField personal"
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    value={selecteCountry}
                    onChange={handleChangeSelectCountry}
                  >
                    {console.log("countryData...", countryData)}
                    {countryData.map((e) => (
                      <MenuItem id={e.id} value={e.country} name={e.country_code}>
                        {e.country}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                  searchOptions={options}
                  shouldRenderSuggestions={shouldRenderSuggestions}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      {/* <input {...getInputProps()} /> */}
                      <TextField
                        {...getInputProps()}
                        id="filled-basic"
                        name="company_name"
                        label="Company"
                        style={{ width: "100%" }}
                        variant="filled"
                        className="customeTextField personal"
                        value={address}
                      />
                      <br />
                      <br />
                      <TextField
                        id="filled-basic"
                        name="site_address"
                        label="Site Address"
                        variant="filled"
                        style={{ width: "100%" }}
                        className="customeTextField personal"
                        value={siteAddress}
                        onChange={handlePersonalDataForm}
                      />
                      <div>
                        {loading ? <div>Loading...</div> : null}

                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          const style = suggestion.active
                            ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                              padding: "5px 10px",
                            }
                            : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                              padding: "5px 10px",
                            };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                {/* <TextField
                id="filled-basic"
                name="company_name"
                label="Company"
                variant="filled"
                className="customeTextField personal"
                value={userProfile ? userProfile.company_name == "null" ? "" : userProfile.company_name : ""}
                onChange={handlePersonalDataForm}
              /> */}
                <TextField
                  id="filled-basic"
                  label="Company Logo"
                  variant="filled"
                  className="customeTextField personal"
                  value={
                    userProfile
                      ? userProfile.company_logo
                        ? userProfile.company_logo.split("/").pop()
                        : ""
                      : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <label htmlFor="logo-upload">
                          <img
                            src={uploadLogoImgage}
                            alt="Upload logo"
                            style={{ cursor: "pointer" }}
                          />
                        </label>
                        {(userProfileAccess?.is_viewer &&
                          userProfileAccess?.is_editor) ||
                          (!userProfileAccess?.is_viewer &&
                            userProfileAccess?.is_editor) ? (
                          <input
                            id="logo-upload"
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleLogoUpload}
                          />
                        ) : (
                          <input
                            id="logo-upload"
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleToastMsg}
                          />
                        )}
                      </InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: Boolean(userProfile ? userProfile.company_logo : ""),
                  }}
                />
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  className="mt-1"
                >
                  <b>Note:</b> not more than 2MB, 200x40 Aspect ratio
                </Typography>

                <TextField
                  id="filled-basic"
                  name="job_title"
                  label="Job Role"
                  variant="filled"
                  className="customeTextField personal"
                  value={
                    userProfile
                      ? userProfile.user_role
                        ? userProfile.user_role
                        : ""
                      : ""
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  id="filled-basic"
                  name="job_title"
                  label="Job Title"
                  variant="filled"
                  className="customeTextField personal"
                  value={
                    userProfile
                      ? userProfile.job_title == "null"
                        ? ""
                        : userProfile.job_title
                      : ""
                  }
                  onChange={handlePersonalDataForm}
                />
              </Stack>
            </Grid>
            <Box className="profileButton-wrap personalTab">
              <button
                className="cancelButton"
                onClick={() => {
                  if (!mobileNoRe) {
                    notifyError("Please Verify Mobile No");
                    return true;
                  }
                  navigate("/");
                }}
              >
                Cancel
              </button>
              {(userProfileAccess?.is_viewer && userProfileAccess?.is_editor) ||
                (!userProfileAccess?.is_viewer && userProfileAccess?.is_editor) ? (
                <button
                  className="updateButton"
                  style={{ marginLeft: "10px" }}
                  onClick={() => updateSubmit()}
                >
                  Update
                </button>
              ) : (
                <button
                  className="updateButton"
                  style={{ marginLeft: "10px" }}
                  onClick={handleToastMsg}
                >
                  Update
                </button>
              )}
            </Box>
          </Grid>
          {openOtpMobileModel && (
            <Card className="optCard">
              <Box>
                <Stack direction="row" spacing={{ sm: 32, md: 30, lg: 30 }}>
                  <Typography
                    gutterBottom
                    style={{
                      color: "#1C407B",
                      fontWeight: "600",
                      fontSize: "19px",
                    }}
                  >
                    Mobile Number
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{ width: "35px", cursor: "pointer" }}
                    onClick={handleCloseOtpMobile}
                  />
                </Stack>
                {/* <Typography variant="subtitle1" gutterBottom style={{ fontWeight: 600, color: '#001323', paddingTop: '20px' }}> Password </Typography> */}
                <Typography
                  variant="subtitle1"
                  style={{ paddingTop: "20px", paddingBottom: "40px" }}
                >
                  Please Enter OTP Sent to +91 XXXXX XX
                  {mobielNo.toString().slice(-3)}
                </Typography>
              </Box>
              <div className="otp-input wrap">
                <OTPInput
                  value={OTPMobile}
                  onChange={setOTPMobile}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  secure={!visibleOTP}
                  className="mobileNumber-input"
                />
                {visibleOTP ? (
                  <img
                    src={images.viewIcon}
                    alt=""
                    className="view icon OTP"
                    onClick={() => setvisibleOTP(false)}
                  />
                ) : (
                  <img
                    src={images.eyecloseIcon}
                    alt=""
                    className="view icon OTP"
                    onClick={() => setvisibleOTP(true)}
                  />
                )}
              </div>
              {/* <ResendOTP className="resend-btn pb-2" /> */}
              <div className="countdown-text d-flex justify-content-center pt-4">
                {seconds > 0 ? (
                  <>
                    <p className="d-flex" style={{ marginLeft: "50px" }}>
                      Please wait for {seconds < 10 ? `0${seconds}` : seconds}{" "}
                      seconds
                    </p>
                  </>
                ) : (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    className="abc"
                  >
                    <div>Didn't recieve code?</div>
                    <div>
                      <a
                        style={{
                          cursor: "pointer",
                          textDecorationLine: "underline",
                          fontWeight: 500,
                        }}
                        onClick={handlegetMobileResendOTP}
                      >
                        Resend
                      </a>
                    </div>
                  </div>
                )}
              </div>

              <Box className="accountSecurity-cardButtons">
                <button className="cancelButton" onClick={handleCloseOtpMobile}>
                  Cancel
                </button>
                <button className="nextButtons" onClick={handleverifyMobileOTP}>
                  Verify
                </button>
              </Box>
            </Card>
          )}
          {/* <Box className='profileButton-wrap'>
                    <button className='cancelButton'>Cancel</button>
                    <button className='updateButton' style={{ marginLeft: '10px' }} onClick={() => setValue("2")}>Update</button>
                </Box> */}
        </TabPanel>
      </>
    );
  };

  export default PersonalTab;
