import React, { useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { images } from "../../../config";
import { ShimmerText } from "react-shimmer-effects";
import {ParetoChartShimmer} from "../../../components/Shimmer/ParetoChartShimmer/ParetoChartShimmer";
import ShimmerEffectLineChart from "../../ShimmerLineChart/ShimmerLinechart";
HC_more(Highcharts); // Initialize the 'highcharts-more' module
exporting(Highcharts); // Initialize the 'exporting' module


const LineChart2 = ({ LineChartdata, LineChartdata2, averagePOP, averageActive, VmsReportSelectmachine,shimmer }) => {

    // Define custom colors for the lines
    const lineColors = ["#FF0000", "#00FF00", "#0000FF", "#FF00FF", "#FFFF00"];
    console.log("LineChartdata", LineChartdata);

    const selectUnit = VmsReportSelectmachine ? VmsReportSelectmachine.length ? VmsReportSelectmachine[0]["tagUnit"] : "" : "";

    const categories = [];

    if (LineChartdata || LineChartdata2 || LineChartdata.length || LineChartdata2.length) {
        const popDataLength = LineChartdata ? LineChartdata.length ? LineChartdata[0].data ? LineChartdata[0].data.length : 0 : 0 : 0;
        const pop1DataLength = LineChartdata2 ? LineChartdata2.length ? LineChartdata2[0].data ? LineChartdata2[0].data.length : 0 : 0 : 0;
        const maxLength = Math.max(popDataLength, pop1DataLength);

        for (let i = 0; i < maxLength; i++) {
            const popData = LineChartdata ? LineChartdata.length ? LineChartdata[0].data ? LineChartdata[0].data[i] : {} : {} : {};
            const pop1Data = LineChartdata2 ? LineChartdata2.length ? LineChartdata2[0].data ? LineChartdata2[0].data[i] : {} : {} : {};
            const minKey = popData?.MIN_KEY || "";
            const pop1MinKey = pop1Data?.MIN_KEY || "";
            const labeledMinKey = `<span style="color: #C0255F;">${minKey}</span>`;
            const labeledPop1MinKey = `<span style="color: #E66595;">${pop1MinKey}</span>`;
            categories.push(`${labeledMinKey} ${labeledPop1MinKey}`);
        }
    }



    const averageSeries = averageActive ? averagePOP ? [{
        name: 'Average',
        data: averagePOP ? averagePOP : [],
        color: 'green',
        dashStyle: 'ShortDash', // You can use other dash styles as well
    }] : [] : []

    const seriesData = [
        {
            name: LineChartdata ? LineChartdata.length ? LineChartdata[0].tag_name : "" : "",
            data: LineChartdata && LineChartdata.length && LineChartdata[0] && LineChartdata[0].data
                ? LineChartdata[0].data.map((row) => parseFloat(row.tag_value))
                : [],
            color: '#C0255F',
        }
    ]

    const seriesData2 = [
        {
            name: LineChartdata2 ? LineChartdata2.length ? LineChartdata2[0].tag_name : "" : "",
            data: LineChartdata2 && LineChartdata2.length && LineChartdata2[0] && LineChartdata2[0].data
                ? LineChartdata2[0].data.map((row) => parseFloat(row.tag_value))
                : [],
            color: "#E66595",
        }
    ]

    

    const combineSeries = [...seriesData, ...seriesData2, ...averageSeries]



    const options = {
        chart: {
            type: "spline",
            scrollablePlotArea: {
                minWidth: 700,
                scrollPositionX: categories.length - 1, // Set scroll position to the rightmost end
            },
        },
        title: {
            text: null, // Remove the title
        },
        legend: {
            itemStyle: {
                color: 'black',
                fontSize: '16px',
            }},
        xAxis: {
            categories: categories,
            scrollbar: {
                enabled: true,
            },
        },
        yAxis: {
            title: {
                text: selectUnit,
            },
        },
        series: combineSeries,
        colors: lineColors,
        exporting: {
            enabled: true, // Disable exporting options
        },
        credits: {
            enabled: false, // Disable credits
        },
        tooltip: {
            shared: true,
            formatter: function () {
              let s = `${this.x}<br />`;
              this.points.forEach((point) => {
                s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${point.y}</b>`;
              });
              return s;
            },
          },
    };



    return (
        <div style={{ height: "390px" }}>
            {!shimmer ? <HighchartsReact highcharts={Highcharts} options={options} /> : <ShimmerEffectLineChart />}
            
        </div>
    );
}

export default LineChart2;


{/* <ShimmerText line={12} gap={15} /> */}