import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { Grid } from "@material-ui/core";

import Switch from "@mui/material/Switch";
import { useDispatch } from "react-redux";
import ParetoGraph from "../../../components/Graph/ParetoGraph/ParetoGraph";
import { setSmartTagReportData } from "../MachineDashboard/Slice";
import LineChart from "../../../components/Graph/LineChart/LineChart";
import BarGraph from "../../../components/Graph/BarGraph/BarGraph";
import LineChart2 from "../../../components/Graph/LineChart/LineChart2";
import { images } from "../../../config";
// import ParetoGraph from "../../../../../../components/Graph/ParetoGraph/ParetoGraph";
// import LineChart from "../../../../../../components/Graph/LineChart/LineChart";
// import BarGraph from "../../../../../../components/Graph/BarGraph/BarGraph";
// import { useState } from "react";
// import LineChart2 from "../../../../../../components/Graph/LineChart/LineChart2";
// import { useDispatch, useSelector } from "react-redux";
// import { setVmsReportData } from "../Slice/VmsReportSlice";

const GraphComponent = ({
  activeTabClass,
  COCIntervalTime,
  POPIntervalTimePop,
  LineGraphData,
  Graphdata,
  tagID,
  tagSelectPOP,
  averageArray,
  averagePOP,
  BarGraphData1,
  BarGraphData2,
  VmsReportSelectmachine,
  VmsReportSelectmachine1,
  LineChartdata,
  LineChartdata2,
  isShimmerCOC,
  isShimmerLineChartCOC,
  isShimmerPOP,
  isShimmerLineChartPOP,
  setLineCOCIntervalTime,
  setLinePOPIntervalTime,
  LineCOCIntervalTime,
  LinePOPIntervalTime,
  smartTagCOCSwitch,
  smartTagPOPSwitch
}) => {
  const [averageCOCActive, setAverageCOCActive] = useState(false);
  const [averagePOPActive, setAveragePOPActive] = useState(false);

  
  console.log("VmsReportSelectmachine", VmsReportSelectmachine);
  const dispatch = useDispatch();

  const handleReportData = (e)=>{
    if(smartTagPOPSwitch) {
      setLinePOPIntervalTime(e.target.value)
    }
    else{
      dispatch(
        setSmartTagReportData({
          POPIntervalTimePop: e.target.value,
            })
          )
    }
  }
  const handleReportDataCOC = (e)=>{
    if(smartTagCOCSwitch) {
      setLineCOCIntervalTime(e.target.value)
    }
    else{
      dispatch(
        setSmartTagReportData({
          COCIntervalTime: e.target.value,
            })
          )
    }
  }
  return (
    <>
      <Grid item xs={12} sm={12} md={9} className="consumption-table-box">
        <Box className="consumerData-box">
          <div style={{ minHeight: "322px" }}>
            <div className="d-flex justify-content-between">
              <h6>
                {activeTabClass == "Consumer" ? (
                  <b>Pareto Chart - Consumer over Consumer No. of Spikes</b>
                ) : (
                  <b>Bar Chart - Period over Period No. of Spikes</b>
                )}
              </h6>
            </div>

            {activeTabClass == "Consumer" ? (
              <ParetoGraph
                Graphdata={Graphdata}
                tagID={tagID}
                shimmer={isShimmerCOC}
              />
            ) : (
              <BarGraph
                BarGraphData1={BarGraphData1}
                BarGraphData2={BarGraphData2}
                shimmer={isShimmerPOP}
              />
            )}
          </div>

          <div className="mt-2">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h6>
                {activeTabClass == "Consumer" ? (
                  <b>Line Chart - Consumer over Consumer Trend</b>
                ) : (
                  <b>Line Chart - Period over Period Trend</b>
                )}
              </h6>
              <div style={{ display: "flex", alignItems: "center" }}>
                {activeTabClass == "Consumer" ? (
                  <>
                    <h6 className="AGG-dropdown">Interval</h6>
                    <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                      <select
                        className="form-input-class"
                        style={{
                          padding: "14px 8px",
                          borderRadius: "4px",
                          marginRight: "20px",
                        }}
                        id="selectedClass"
                        value={smartTagCOCSwitch ? LineCOCIntervalTime : COCIntervalTime}
                        // value={LinePOPIntervalTime}
                        onChange={(e)=>handleReportDataCOC(e)}
                        // value={COCIntervalTime}
                        // onChange={(e) =>
                        //   dispatch(
                        //     setSmartTagReportData({
                        //       COCIntervalTime: e.target.value,
                        //     })
                        //   )
                        // }
                        required
                      >
                        <option value="1" selected disabled hidden>
                          1 min
                        </option>
                        <option value="1">1 Min</option>
                        <option value="3">3 Min</option>
                        <option value="5">5 Min</option>
                        <option value="15">15 Min</option>
                      </select>
                    </Box>
                    <span
                      style={{
                        color: "#001323",
                        fontWeight: "500",
                        marginRight: "10px",
                      }}
                    >
                      Average
                    </span>
                    <Switch
                      checked={averageCOCActive}
                      onClick={() => setAverageCOCActive((prev) => !prev)}
                      // Add your switch event handling logic here
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#828282",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#D9D9E5 !important",
                        },
                        "& .Mui-checked": {
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#50C878",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#CEE6E1 !important",
                          },
                        },
                      }}
                    />
                  </>
                ) : (
                  <>
                    <h6 className="AGG-dropdown">Interval</h6>
                    <Box sx={{ minWidth: 200 }} style={{ float: "right" }}>
                      <select
                        className="form-input-class"
                        style={{
                          padding: "14px 8px",
                          borderRadius: "4px",
                          marginRight: "20px",
                        }}
                        id="selectedClass"
                        value={smartTagPOPSwitch ? LineCOCIntervalTime : POPIntervalTimePop}
                        // value={LinePOPIntervalTime}
                        onChange={(e)=>handleReportData(e)}
                        // value={POPIntervalTimePop}
                        // onChange={(e) =>
                        //   dispatch(
                        //     setSmartTagReportData({
                        //       POPIntervalTimePop: e.target.value,
                        //     })
                        //   )
                        // }
                        required
                      >
                        <option value="1" selected disabled hidden>
                          1 min
                        </option>
                        <option value="1">1 Min</option>
                        <option value="3">3 Min</option>
                        <option value="5">5 Min</option>
                        <option value="15">15 Min</option>
                      </select>
                    </Box>
                    <span
                      style={{
                        color: "#001323",
                        fontWeight: "500",
                        marginRight: "10px",
                      }}
                    >
                      Average
                    </span>
                    <Switch
                      checked={averagePOPActive}
                      onClick={() => setAveragePOPActive((prev) => !prev)}
                      // Add your switch event handling logic here
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#828282",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#D9D9E5 !important",
                        },
                        "& .Mui-checked": {
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#50C878",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#CEE6E1 !important",
                          },
                        },
                      }}
                    />
                  </>
                )}
              </div>
            </div>

            {activeTabClass == "Consumer" ? (
              <LineChart
                LineGraphData={LineGraphData}
                tagID={tagID}
                averageArray={averageArray}
                averageActive={averageCOCActive}
                shimmer={isShimmerLineChartCOC}
              />
            ) : (
              <LineChart2
                LineChartdata={LineChartdata}
                LineChartdata2={LineChartdata2}
                averagePOP={averagePOP}
                averageActive={averagePOPActive}
                VmsReportSelectmachine={tagSelectPOP}
                shimmer={isShimmerLineChartPOP}
              />
            )}
            {activeTabClass == "Period" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  gap: "25px",
                  marginLeft: "44px",
                  height: "38px",
                }}
              >
                {VmsReportSelectmachine && VmsReportSelectmachine.length
                  ? VmsReportSelectmachine.map((row, index) => {
                      return (
                        <>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{ color: "#001323", fontWeight: "500" }}
                            >
                              {row.dvName}
                            </span>
                            <Switch
                              checked={true}
                              style={{ pointerEvents: "none" }}
                              // Add your switch event handling logic here
                              sx={{
                                "& .MuiSwitch-thumb": {
                                  bgcolor: "#828282",
                                },
                                "& .MuiSwitch-track": {
                                  bgcolor: "#D9D9E5 !important",
                                },
                                "& .Mui-checked": {
                                  "& .MuiSwitch-thumb": {
                                    bgcolor: "#50C878",
                                  },
                                  "& .MuiSwitch-track": {
                                    bgcolor: "#CEE6E1 !important",
                                  },
                                },
                              }}
                            />
                          </div>
                        </>
                      );
                    })
                  : null}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  gap: "25px",
                  marginLeft: "44px",
                  height: "38px",
                }}
              >
                {VmsReportSelectmachine1 && VmsReportSelectmachine1.length
                  ? VmsReportSelectmachine1.map((row, index) => {
                      return (
                        <>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{ color: "#001323", fontWeight: "500" }}
                            >
                              {row.dvName}
                            </span>
                            <Switch
                              checked={true}
                              style={{ pointerEvents: "none" }}
                              // Add your switch event handling logic here
                              sx={{
                                "& .MuiSwitch-thumb": {
                                  bgcolor: "#828282",
                                },
                                "& .MuiSwitch-track": {
                                  bgcolor: "#D9D9E5 !important",
                                },
                                "& .Mui-checked": {
                                  "& .MuiSwitch-thumb": {
                                    bgcolor: "#50C878",
                                  },
                                  "& .MuiSwitch-track": {
                                    bgcolor: "#CEE6E1 !important",
                                  },
                                },
                              }}
                            />
                          </div>
                        </>
                      );
                    })
                  : null}
              </div>
            )}
          </div>
        </Box>
      </Grid>
    </>
  );
};

export default GraphComponent;
