import React, { useMemo, useState } from "react";
import { Box, Link, TextField } from "@material-ui/core";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import Switch from "@mui/material/Switch";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Slider from "react-slick";
import { images } from "../../../../../config/images";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import VmsDonutChart from "./VmsDonutChart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getAdvanceAnalyticsPieChartDataApi, getCompanyById } from "./service";
import { setVmsAdvanceAnalyticsSet } from "./slice/vmsAdvanceAnalyticsSlice";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect } from "react";
import { getDeviceByType, getDeviceDashboard } from "../Reports/service";
import Marquee from "react-fast-marquee";
import { ToastContainer, toast } from "react-toastify";
import { getDeviceByDashboard } from "../../../../SmartTag/services";

const dateFormat = "YYYY-MM-DD"; // Replace with your desired date format

const VmsAdvanceAnalytics = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deviceData, setDeviceData] = useState([]);
  const [pieChart1Data, setPieChart1Data] = useState([]);
  const [pieChart1Total, setPieChart1Total] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [isShimmer,setShimmer] = useState(false)

  console.log("deviceDatadeviceData", deviceData);
  const {
    startDateRe,
    endDateRe,
    startDateRe2,
    endDateRe2,
    startDateRe3,
    endDateRe3,
    selectDeviceData1,
  } = useSelector((state) => state.vmsAdvanceAnalyticsSlice);
  const { user_access } = useSelector((state) => state.userState);

  const startDateReFormat = startDateRe ? dayjs(startDateRe) : null;
  const endDateReFormat = endDateRe ? dayjs(endDateRe) : null;
  const startDateReFormat2 = dayjs(startDateRe2);
  const endDateReFormat2 = dayjs(endDateRe2);
  const startDateReFormat3 = dayjs(startDateRe3);
  const endDateReFormat3 = dayjs(endDateRe3);

  const dispatch = useDispatch();
  const MAX_DATE_RANGE_DAYS = 365; // Maximum duration of one year in days

  console.log("pieChart1Total", pieChart1Total);

  // console.log("startDateRe", startDateRe);
  const formatStartDate = startDateReFormat
    ? dayjs(startDateReFormat).format(dateFormat)
    : "";
  const formatEndDate = endDateReFormat
    ? dayjs(endDateReFormat).format(dateFormat)
    : "";
  const formatStartDate6 = startDateRe2
    ? dayjs(startDateRe2).format(dateFormat)
    : "";
  const formatEndDate6 = endDateRe2 ? dayjs(endDateRe2).format(dateFormat) : "";

  const formatStartDate7 = startDateRe3
    ? dayjs(startDateRe3).format(dateFormat)
    : "";
  const formatEndDate7 = endDateRe3 ? dayjs(endDateRe3).format(dateFormat) : "";

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { headerName } = useSelector((state) => state.headerState);

  const param = useParams();

  const notifyError = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openDevice = Boolean(anchorEl);

  const handleOverViewClick = () => {
    navigate(`/vms-advance-analytics/avilibility/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`)
  };

  const handleSourceClick = () => {
    navigate(`/vms-advance-analytics/production/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`)
  };


  const handleConsumption = () => {};

  const handleAdvanceAnalytics = () => {};
  const navigate = useNavigate();

  const sliderColor = [
    "sliderColor1",
    "sliderColor2",
    "sliderColor3",
    "sliderColor4",
    "sliderColor5",
    "sliderColor6",
  ];

  const getDeviceByPlant = async () => {
    const paramReq = {
      dashboard: 2,
      plant: param.plantId
    }
    try {
      const resp = await getDeviceDashboard(paramReq)
      if (resp.status == 200) {
        console.log("resprespresp", resp);
        if (resp.data.payload.device.length) {
          setDeviceData(resp.data.payload.device);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getDeviceByPlant();
  }, []);

  const handleDeviceSelect1 = (row) => {
    const existingIndex = selectDeviceData1.findIndex(
      (color) =>
        color.machineId === row.device && color.deviceId == param.deviceId
    );

    if (existingIndex !== -1) {
      const updateMachineId = [...selectDeviceData1];
      updateMachineId.splice(existingIndex, 1);
      dispatch(
        setVmsAdvanceAnalyticsSet({
          selectDeviceData1: updateMachineId,
        })
      );
    } else {
      // Add the object to the state array if it doesn't exist
      dispatch(
        setVmsAdvanceAnalyticsSet({
          selectDeviceData1: [
            ...selectDeviceData1,
            {
              machineId: row.device,
              dvName: row.device__device_name,
              plantId: param.plantId,
              deviceId: param.deviceId,
            },
          ],
        })
      );
    }
  };
  const plantDashboardAccess = user_access.length
    ? user_access.find(
        (user) => user.access_module?.access_module_name === "Plant Dashboard"
      )
    : null;
  const analyticsAccess = plantDashboardAccess
    ? plantDashboardAccess.sub_module.length
      ? plantDashboardAccess.sub_module.find(
          (f) => f.sub_module_name === "Advance Analytics"
        )
      : null
    : null;

  const handleTostMsg = () => {
    notifyError("You don't have access");
  };

  // Pie Chart

  const getAdvanceAnalyticsPieChartData = async (
    startDate,
    endDate,
    deviceId,
    setPieData,
    setTotal
  ) => {
    try {
      const params = {
        device: deviceId,
        start_date: startDate,
        end_date: endDate,
      };
      setShimmer(true)
      const resp = await getAdvanceAnalyticsPieChartDataApi(params);

      if (resp.status === 200 || resp.status == 201) {
        setShimmer(false)
        setPieData(resp.data.payload.deviceData);
        setTotal(resp.data.payload.deviceTotal.total_up_time);
        // If you have logic for setting total, add it here.
        // Example: setTotal(resp.data.total);
      }
    } catch (error) {
      setShimmer(false)
      // Handle errors that occur during the API call or data processing.
      console.error("Error occurred:", error);
      // Optionally, you can set an error state or display an error message to the user.
    }
  };

  useEffect(() => {
    const findSelectDeviceId = selectDeviceData1.length
      ? selectDeviceData1.filter((f) => f.deviceId == param.deviceId).length
        ? selectDeviceData1
            .filter((f) => f.deviceId == param.deviceId)
            .map((row) => row.machineId)
        : []
      : [];
    if (formatStartDate && formatEndDate && findSelectDeviceId.length) {
      getAdvanceAnalyticsPieChartData(
        formatStartDate,
        formatEndDate,
        findSelectDeviceId,
        setPieChart1Data,
        setPieChart1Total
      );
    } else {
      setPieChart1Data([]);
      setPieChart1Total([]);
    }
  }, [selectDeviceData1, formatStartDate, formatEndDate]);

  const getCompanyByIdAPI = async () => {
    try {
      const resp = await getCompanyById(param.companyId);

      if (resp.status == 200) {
        setCompanyName(resp.data.company_name);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getCompanyByIdAPI();
  }, []);

  const handleStartDate1 = (newValue) => {
    const durationInDays = endDateReFormat
      ? endDateReFormat.diff(newValue, "days")
        ? endDateReFormat.diff(newValue, "days") > 0
          ? endDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      // Valid date range (within one year)
      dispatch(setVmsAdvanceAnalyticsSet({ startDateRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (endDateReFormat && newValue.isBefore(endDateReFormat)) {
      // Start date is before end date
      dispatch(setVmsAdvanceAnalyticsSet({ startDateRe: newValue }));
    } else if (!endDateReFormat) {
      // End date is not set yet
      dispatch(setVmsAdvanceAnalyticsSet({ startDateRe: newValue }));
    } else if (endDateReFormat && newValue.isSame(endDateReFormat, "day")) {
      // End date is not set yet
      dispatch(setVmsAdvanceAnalyticsSet({ startDateRe: newValue }));
    } else {
      // Start date is after end date
      dispatch(setVmsAdvanceAnalyticsSet({ startDateRe: null }));
      notifyError("End date must be greater than start date");
    }

    // dispatch(setVmsAdvanceAnalyticsSet({ startDateRe: newValue }))
  };
  const handleEndDate1 = (newValue) => {
    const durationInDays = startDateReFormat
      ? startDateReFormat.diff(newValue, "days")
        ? startDateReFormat.diff(newValue, "days") > 0
          ? startDateReFormat.diff(newValue, "days")
          : 1
        : 1
      : MAX_DATE_RANGE_DAYS;

    if (!(durationInDays > 0 && durationInDays <= MAX_DATE_RANGE_DAYS)) {
      dispatch(setVmsAdvanceAnalyticsSet({ endDateRe: null }));
      notifyError("Date range must be within one year");
      return true;
    }

    if (startDateReFormat && newValue.isAfter(startDateReFormat)) {
      dispatch(setVmsAdvanceAnalyticsSet({ endDateRe: newValue }));
    } else if (!startDateReFormat) {
      dispatch(setVmsAdvanceAnalyticsSet({ endDateRe: newValue }));
    } else if (startDateReFormat && newValue.isSame(startDateReFormat, "day")) {
      // End date is not set yet
      dispatch(setVmsAdvanceAnalyticsSet({ endDateRe: newValue }));
    } else {
      // Start date is after end date
      dispatch(setVmsAdvanceAnalyticsSet({ endDateRe: null }));
      notifyError(" startDate must less than endDate");
    }

    // dispatch(setVmsAdvanceAnalyticsSet({ endDateRe: newValue }))
  };

  return (
    <>
      
      <div className="p-4 pb-5">
        <div
          className="page-back-btn"
          onClick={() =>
            navigate(`/submachine/company/${param.companyId}/plant/${param.plantId}/device/${param.deviceId}/machine/${param.machineId}`)
          }
        >
          <ArrowBackIcon />
          <span>{companyName} ~ Advanced Analytics</span>
        </div>
        <div className="d-flex gap-3 pt-3">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{ display: "flex" }}
                style={{ padding: "11px 19px", borderRadius: "6px" }}
              >
                <div className="d-flex ">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div className="mt-3">
                      <img src={images.criticalEvent} />
                    </div>
                  </Box>
                  <CardContent className="processDashboar-meterCard">
                    <Typography className="device-desc">
                      Critical Event Recorder
                    </Typography>
                    <Typography className="device-number">00003486</Typography>
                    <Typography className="unit">
                      {/* {tagData1 ? tagData1.unit : "-"} */}-
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{ display: "flex" }}
                style={{ padding: "11px 19px", borderRadius: "6px" }}
              >
                <div className="d-flex ">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div className="mt-3">
                      <img src={images.workOrder} />
                    </div>
                  </Box>
                  <CardContent className="processDashboar-meterCard">
                    <Typography className="device-desc">
                      {/* {tagData2 ? tagData2.comment : "-"} */}
                      Work Order Generated
                    </Typography>
                    <Typography className="device-number">00003486</Typography>
                    <Typography className="unit">-</Typography>
                  </CardContent>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{ display: "flex" }}
                style={{ padding: "11px 19px", borderRadius: "6px" }}
              >
                <div className="d-flex">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div className="mt-3">
                      <img src={images.workOrder} />
                    </div>
                  </Box>
                  <CardContent className="processDashboar-meterCard">
                    <Typography className="device-desc">
                      Work Order Closed
                    </Typography>
                    <Typography className="device-number">00003486</Typography>
                    <Typography className="unit">-</Typography>
                  </CardContent>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Card
                sx={{ display: "flex" }}
                style={{ padding: "11px 19px", borderRadius: "6px" }}
              >
                <div className="d-flex ">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <div className="mt-3">
                      <img src={images.alarmImg} />
                    </div>
                  </Box>
                  <CardContent className="processDashboar-meterCard">
                    <Typography className="device-desc">
                      Down Time Saved
                    </Typography>
                    <Typography className="device-number">00003486</Typography>
                    <Typography className="unit" style={{ height: "20px" }}>
                      hrs
                    </Typography>
                  </CardContent>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>

        <div className="pt-3 process-card-chart">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card
                className="vms-analytics-marquee"
                style={{
                  height: "526px",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                }}
              >
                <div className="graph-box">
                  <div className="d-flex">
                    <Box>
                      <div
                        className="selectMachine-dropdown"
                        onClick={handleClick}
                      >
                        Select Machine <ArrowDropDownIcon />
                      </div>
                    </Box>

                    <Popper
                      open={openDevice}
                      anchorEl={anchorEl}
                      placement="bottom-start"
                      style={{ zIndex: 1 }}
                    >
                      <Paper>
                        {deviceData.length
                          ? deviceData.map((row) => (
                              <MenuItem>
                                {" "}
                                {row.device__device_name}
                                {(analyticsAccess?.is_viewer &&
                                  analyticsAccess?.is_editor) ||
                                (!analyticsAccess?.is_viewer &&
                                  analyticsAccess?.is_editor) ||
                                (analyticsAccess?.is_viewer &&
                                  !analyticsAccess?.is_editor) ? (
                                  <Switch
                                    style={{ float: "right" }}
                                    checked={
                                      selectDeviceData1.length
                                        ? selectDeviceData1.filter(
                                            (f) => f.deviceId == param.deviceId
                                          ).length
                                          ? selectDeviceData1
                                              .filter(
                                                (f) =>
                                                  f.deviceId == param.deviceId
                                              )
                                              .find(
                                                (f) => f.machineId == row.device
                                              )
                                            ? true
                                            : false
                                          : false
                                        : false
                                    }
                                    onClick={() => handleDeviceSelect1(row)}
                                  />
                                ) : (
                                  <Switch
                                    style={{ float: "right" }}
                                    checked={
                                      selectDeviceData1.length
                                        ? selectDeviceData1.filter(
                                            (f) => f.deviceId == param.deviceId
                                          ).length
                                          ? selectDeviceData1
                                              .filter(
                                                (f) =>
                                                  f.deviceId == param.deviceId
                                              )
                                              .find(
                                                (f) => f.machineId == row.device
                                              )
                                            ? true
                                            : false
                                          : false
                                        : false
                                    }
                                    onClick={handleTostMsg}
                                  />
                                )}
                              </MenuItem>
                            ))
                          : []}
                      </Paper>
                    </Popper>
                    {/* <Box >
                    <select
                      className="form-input-class"
                      style={{ padding: "16px 8px", borderRadius: "4px",marginBottom:"10px"  }}
                      id="selectedClass"
                      //   value={selectTimeRange}
                      //   onChange={(event) =>

                      //   }
                      // defaultValue="all"
                      // value={selectYear}
                      // onChange={(event) => setSelectYear(event.target.value)}
                      required
                    >
                      <option value="15" selected disabled hidden>
                        Plants
                      </option>
                      <option value="15">Plants - 1</option>
                      <option value="30">Plants - 2</option>
                      <option value="60">Plants - 3</option>
                      <option value="1">Plants - 4</option>
                      <option value="7">Plants - 5</option>
                    </select>
                  </Box> */}
                  </div>
                  <div
                    className="graph-date"
                    style={{ justifyContent: "flex-end" }}
                  >
                    {(analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (!analyticsAccess?.is_viewer &&
                      analyticsAccess?.is_editor) ||
                    (analyticsAccess?.is_viewer &&
                      !analyticsAccess?.is_editor) ? (
                      <>
                        <Box className="">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              className="custom-datepicker"
                              label="Start Date"
                              value={startDateReFormat}
                              inputFormat="dd/MM/yyyy" // Set the input format to "dd/MM/yyyy"
                              onChange={(newValue) =>
                                handleStartDate1(newValue)
                              }
                              disableFuture
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  style={{ background: "#ffff" }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Box>
                        <Box className="">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              className="custom-datepicker"
                              label="End Date"
                              value={endDateReFormat}
                              inputFormat="DD/MM/YYYY"
                              onChange={(newValue) => handleEndDate1(newValue)}
                              disableFuture
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  style={{ background: "#ffff" }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box className="">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              className="custom-datepicker"
                              label="Start Date"
                              value={startDateReFormat}
                              inputFormat="dd/MM/yyyy" // Set the input format to "dd/MM/yyyy"
                              onChange={handleTostMsg}
                              disableFuture
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  style={{ background: "#ffff" }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Box>
                        <Box className="">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              className="custom-datepicker"
                              label="End Date"
                              value={endDateReFormat}
                              inputFormat="DD/MM/YYYY"
                              onChange={handleTostMsg}
                              disableFuture
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  style={{ background: "#ffff" }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Box>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <VmsDonutChart
                    pieChartData={pieChart1Data}
                    piChartTotal={pieChart1Total}
                    shimmer = {isShimmer}
                  />
                </div>

                <p className="machineanalytics-chartText  pt-3">Running Hrs</p>

                <div className="slider-data-div ">
                  <Marquee pauseOnHover={true}>
                    {pieChart1Data.length
                      ? pieChart1Data.map((e, i) => (
                          <div className={`slider-title ${sliderColor[i]}`}>
                            <p className="silder--title">
                              {e.device__device_name}
                            </p>
                            <p className="silder--data">{e.up_time}</p>
                            <p className="silder--unit">hrs</p>
                          </div>
                        ))
                      : []}
                  </Marquee>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card
                className="vms-analytics-marquee"
                style={{
                  height: "526px",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                }}
              >
                <div className="graph-box">
                  <div className="d-flex">
                    <Box>
                      <div className="selectMachine-dropdown">
                        Select Machine <ArrowDropDownIcon />
                      </div>
                    </Box>
                  </div>
                  <div
                    className="graph-date"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Box className="">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="custom-datepicker"
                          label="Start Date"
                          value={startDate}
                          inputFormat="dd/MM/yyyy" // Set the input format to "dd/MM/yyyy"
                          onChange={(newValue) => setStartDate(newValue)}
                          disableFuture
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box className="">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="custom-datepicker"
                          label="End Date"
                          value={endDate}
                          inputFormat="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setEndDate(newValue);
                            // dispatch(
                            //   setProcessDashboarDateSet({ endDateRe: newValue })
                            // );
                          }}
                          disableFuture
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                </div>
                <div>
                  <VmsDonutChart />
                </div>
                <p className="machineanalytics-chartText  pt-3">
                  Event Generated
                </p>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card
                className="vms-analytics-marquee"
                style={{
                  height: "526px",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                }}
              >
                <div className="graph-box">
                  <div className="d-flex">
                    <Box>
                      <div className="selectMachine-dropdown">
                        Select Machine <ArrowDropDownIcon />
                      </div>
                    </Box>
                  </div>
                  <div
                    className="graph-date"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Box className="">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="custom-datepicker"
                          label="Start Date"
                          value={startDate}
                          inputFormat="dd/MM/yyyy" // Set the input format to "dd/MM/yyyy"
                          onChange={(newValue) => setStartDate(newValue)}
                          disableFuture
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box className="">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="custom-datepicker"
                          label="End Date"
                          value={endDate}
                          inputFormat="DD/MM/YYYY"
                          onChange={(newValue) => {
                            setEndDate(newValue);
                            // dispatch(
                            //   setProcessDashboarDateSet({ endDateRe: newValue })
                            // );
                          }}
                          disableFuture
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{ background: "#ffff" }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Box>
                  </div>
                </div>
                <div>
                  <VmsDonutChart />
                </div>
                <p className="machineanalytics-chartText  pt-3">
                  Reason of Events
                </p>
              </Card>
            </Grid>
          </Grid>
        </div>

        <Grid container spacing={2} className="pt-4">
          <Grid item xs={12} sm={6} md={4} lg={4}>
            {(analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
            (!analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
            (analyticsAccess?.is_viewer && !analyticsAccess?.is_editor) ? (
              <button
                // className="Process-dashboard-btn"
                className="analytics-dashboard-btn"
                // style={{pointerEvents:"none"}}
                onClick={() => handleOverViewClick()}
              >
                AVAILABILITY
              </button>
            ) : (
              <button
                // className="Process-dashboard-btn"
                className="analytics-dashboard-btn"
                // style={{pointerEvents:"none"}}
                onClick={handleTostMsg}
              >
                AVAILABILITY
              </button>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            {(analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
            (!analyticsAccess?.is_viewer && analyticsAccess?.is_editor) ||
            (analyticsAccess?.is_viewer && !analyticsAccess?.is_editor) ? (
              <button
                className="analytics-dashboard-btn"
                style={{ pointerEvents: "none" }}
                onClick={() => handleSourceClick()}
              >
                PRODUCTION
              </button>
            ) : (
              <button
                className="analytics-dashboard-btn"
                style={{ pointerEvents: "none" }}
                onClick={handleTostMsg}
              >
                PRODUCTION
              </button>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <button
              className="analytics-dashboard-btn"
              style={{ pointerEvents: "none" }}
              onClick={() => handleConsumption()}
            >
              SPECTRUM
            </button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default VmsAdvanceAnalytics;
