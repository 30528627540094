import { getReportLineChart } from "../service"

export const getReportLineChartData = async (param, setData,setLoading) => {
    try {
        setLoading(true)
        const resp = await getReportLineChart(param);
        if (resp.status == 200 || resp.status == 201) {
            setTimeout(()=>{setLoading(false)},2000)
            setData(resp.data.payload)
        }
    }
    catch (error) {
        setLoading(false);
        console.log("error", error);
    }

}