import React, { useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { FormControlLabel, FormGroup, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { images } from "../../../config/images";
import Checkbox from "@mui/material/Checkbox";
import SearchIcon from "@material-ui/icons/Search";
import ErrorIcon from "@mui/icons-material/Error";
import { InputAdornment } from "@material-ui/core";
import {
  getcompanyListPlantManegement,
  getPlantByCompany,
  addPlantData,
  editPlant,
  deletePlant,
  getDevicePlant,
  assignDeviceApi,
  DeviceByPlantApi,
  getDeviceAssign,
  getGatewayAssign,
  getMachineData,
  getDeviceData,
} from "../service";
import "./PlantManagement.css";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/Loader/Loader";
import { ValidatorForm } from "react-material-ui-form-validator";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { ShimmerText } from "react-shimmer-effects";

function CompanyByPlantmanagement() {
  // const [selectCompany, setSelectCompany] = React.useState(param.id);

  const [openAddplant, setOpenAddplant] = useState(false);
  const [openAssignDeviceModal, setOpenAssignDeviceModal] = useState(false);
  const [openEditplant, setOpenEditplant] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDeleteDeviceModal, setOpenDeleteDeviceModal] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [plantList, setPlantList] = useState([]);
  const [assignGateway, setAssignGateway] = useState([]);
  const [searchDevice, setSearcDevice] = useState("");
  const [selectedMachineId, setSelectedMachineId] = useState(null);
  const [addPlant, setAddPlant] = useState({
    plant_name: "",
    plant_description: "",
    company: "",
  });
  const [editPlantData, setEditPlantData] = useState({
    plant_name: "",
    plant_description: "",
    company: "",
  });
  const [assignDeviceIdArray, setAssignDeviceIdArray] = useState([]);
  const [selectDeviceListArray, setSelectDeviceListArray] = useState([]);
  const [assignDevice, setAssignDevice] = useState([]);
  const [isShimmerPlantList, setisShimmerPlantList] = useState(false);

  const navigate = useNavigate();

  const param = useParams();
  const [selectCompany, setSelectCompany] = React.useState(param.companyId);
  const [plantId, setPlantId] = useState(param.id);
  const selectCompanyName = companyList.find((f) => f.id == selectCompany);
  const [isLoading, setLoading] = useState(false);
  const [isEmpatyDevice, setEmpatyDevice] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [machineName, setMachineName] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(null);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [deviceLastSeenModal, setDeviceLastSeenModal] = useState({});

  const notify = (message) =>
    toast.error(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });

  const notify1 = (message) =>
    toast.success(message, {
      theme: "colored",
      position: toast.POSITION.TOP_RIGHT,
      toastClassName: "custom-toast",
    });
  // useEffect(() => {
  //   getDeviceByCompanyApi();
  // }, [selectCompany]);

  // const getDeviceByCompanyApi = async () => {
  //   const params = {
  //     company: selectCompany,
  //   };
  //   try {
  //     // setLoading(true);
  //     const resp = await getDeviceAssign(params);
  //     if (resp.status == 200 || resp.status == 201) {
  //       setLoading(false);
  //       setAssignDevice(resp.data.payload);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.log("handleSubmit", error);
  //   }
  // };
  const handleCheckBox = (id) => {
    let updatedAssignDeviceIdArray;
    if (assignDeviceIdArray.includes(id)) {
      updatedAssignDeviceIdArray = assignDeviceIdArray.filter((d) => d !== id);
    } else {
      updatedAssignDeviceIdArray = [...assignDeviceIdArray, id];
    }

    // Check if all devices are selected
    const allDevicesSelected = deviceData.every((device) =>
      updatedAssignDeviceIdArray.includes(device.id)
    );

    setAssignDeviceIdArray(updatedAssignDeviceIdArray);
    setSelectAll(allDevicesSelected);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // setAssignDeviceIdArray([]);
      setAssignDeviceIdArray(
        assignDeviceIdArray.filter(
          (d) => !deviceData.map((e) => e.id).includes(d)
        )
      );
    } else {
      const allDeviceIds = deviceData.map((e) => e.id);
      setAssignDeviceIdArray([...assignDeviceIdArray].concat(allDeviceIds));
    }
    setSelectAll(!selectAll);
  };

  const handleOpenModal = (i) => {
    setDeviceLastSeenModal({ [i]: true });
  };

  const handleCloseModal = (i) => {
    setDeviceLastSeenModal({ [i]: false });
  };

  const handleClick = async (id, event) => {
    const params = {
      gateway: id,
    };
    try {
      const resp = await getMachineData(params);
      if (resp.status == 200 || resp.status == 201) {
        setMachineData(resp.data.payload);
        setSelectedGateway((prevState) => (prevState === id ? null : id));
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handleChangeCompany = (e) => {
    if (
      openAddplant ||
      openAssignDeviceModal ||
      openEditplant ||
      openDeleteModal ||
      openDeleteDeviceModal
    ) {
      return true;
    }
    setSelectCompany(e.target.value);
  };
  const handleTabValue = (e) => {};
  //Add plant modal
  const handleOpenaddPlantMdal = () => {
    if (
      openAddplant ||
      openAssignDeviceModal ||
      openEditplant ||
      openDeleteModal ||
      openDeleteDeviceModal
    ) {
      return true;
    }
    setOpenAddplant(!openAddplant);
  };

  const handleOpenaddPlantCloseMdal = () => {
    setOpenAddplant(!openAddplant);
  };
  //Assign Device modal
  const handleOPenAssignDeviceModal = () => {
    if (
      openAddplant ||
      openAssignDeviceModal ||
      openEditplant ||
      openDeleteModal ||
      openDeleteDeviceModal
    ) {
      return true;
    }
    if (!plantList.length) {
      notify("Please Add Plant");

      return true;
    }
    setOpenAssignDeviceModal(!openAssignDeviceModal);
    setOpenEditplant(false);
    setOpenDeleteModal(false);
  };

  const handleOPenAssignDeviceCloseModal = () => {
    setOpenAssignDeviceModal(!openAssignDeviceModal);
  };
  //Edit plant modal
  const handleEditModal = (e) => {
    if (
      openAddplant ||
      openAssignDeviceModal ||
      openEditplant ||
      openDeleteModal ||
      openDeleteDeviceModal
    ) {
      return true;
    }
    setOpenEditplant(!openEditplant);
    setEditPlantData({
      plant_name: e.plant_name,
      plant_description: e.plant_description,
      company: "",
    });
    setPlantId(e.id);
    setOpenAssignDeviceModal(false);
  };

  const closePlantEditModel = () => {
    setOpenEditplant(!openEditplant);
  };

  //Delete plant modal
  const handleConfirmationModal = () => {
    if (
      openAddplant ||
      openAssignDeviceModal ||
      openEditplant ||
      openDeleteModal ||
      openDeleteDeviceModal
    ) {
      return true;
    }
    setOpenEditplant(false);
    setOpenDeleteModal(!openDeleteModal);
    setOpenAssignDeviceModal(false);
    setOpenDeleteDeviceModal(false);
  };

  const handleConfirmationCloseModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  //Delete device modal
  const handleConfirmationDeleteDeviceModal = () => {
    if (
      openAddplant ||
      openAssignDeviceModal ||
      openEditplant ||
      openDeleteModal ||
      openDeleteDeviceModal
    ) {
      return true;
    }
    if (!isEmpatyDevice) {
      setOpenDeleteDeviceModal(!openDeleteDeviceModal);
      setOpenEditplant(false);
      setOpenAssignDeviceModal(false);
      setOpenDeleteModal(false);
    }
  };

  const handleConfirmationDeleteDeviceCloseModal = () => {
    setOpenDeleteDeviceModal(!openDeleteDeviceModal);
  };

  // Add plant API
  const handleChangePlantDetails = (e) => {
    setAddPlant({ ...addPlant, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    // Find the specific plant that matches the plantId
    if (plantList.length && plantId) {
      const matchedPlant = plantList.find((e) => e.id == plantId);
      console.log("matchedPlant", matchedPlant, plantList, plantId);
      if (matchedPlant) {
        // If the matchedPlant exists, check if its device array is empty

        setEmpatyDevice(matchedPlant.device.length === 0);
        setAssignDeviceIdArray(
          matchedPlant.device.length ? matchedPlant.device.map((e) => e.id) : []
        );
      }
    }
  }, [plantList, plantId]);

  // select DeviceIdArray
  const handleSelectAssingDeviceId = (id) => {
    if (!Array.isArray(assignDeviceIdArray)) {
      setAssignDeviceIdArray([]);
    }
    if (assignDeviceIdArray.includes(id)) {
      setAssignDeviceIdArray(
        assignDeviceIdArray.filter((element) => element !== id)
      );
    } else {
      setAssignDeviceIdArray([...assignDeviceIdArray, id]);
    }
  };

  const handleSelectDeviceId = (id) => {
    if (
      openAddplant ||
      openAssignDeviceModal ||
      openEditplant ||
      openDeleteModal ||
      openDeleteDeviceModal
    ) {
      return true;
    }
    if (!Array.isArray(selectDeviceListArray)) {
      setSelectDeviceListArray([]);
    }
    if (selectDeviceListArray.includes(id)) {
      setSelectDeviceListArray(
        selectDeviceListArray.filter((element) => element !== id)
      );
    } else {
      setSelectDeviceListArray([...selectDeviceListArray, id]);
    }
  };

  const submitAddPlant = async () => {
    if (addPlant.plant_name === "") {
      notify("please enter plant");
      return;
    }
    if (addPlant.plant_description === "") {
      notify("Please enter description");
      return;
    }
    const param = {
      plant_name: addPlant.plant_name,
      plant_description: addPlant.plant_description,
      company: selectCompany,
    };
    try {
      setLoading(true);
      const response = await addPlantData(param);
      if (response.data.success == true) {
        notify1("add plant successfully");
        setLoading(false);
        setOpenAddplant(false);
        getcompanyListPlantManegementApi();
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };

  const getcompanyListPlantManegementApi = async () => {
    const resp = await getcompanyListPlantManegement();
    if (resp.status == 200) {
      if (!selectCompany) {
        setSelectCompany(resp.data.payload[0].id);
      }
      setCompanyList(resp.data.payload);
    }
  };
  useEffect(() => {
    handlegetGatewayAssign();
  }, [selectCompany]);

  const handlegetGatewayAssign = async () => {
    const params = {
      company: selectCompany,
    };
    try {
      const resp = await getGatewayAssign(params);
      if (resp.status == 200 || resp.status == 201) {
        setAssignGateway(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };
  const handleMachineClick = async (id, e) => {
    setMachineName(e.machine_name);
    setSelectedMachineId(id);
    const params = {
      machine: id,
    };
    try {
      const resp = await getDeviceData(params);
      if (resp.status == 200 || resp.status == 201) {
        setDeviceData(resp.data.payload);
        const getDeviceId = resp.data.payload.map((e) => e.id);

        if (getDeviceId.every((value) => assignDeviceIdArray.includes(value))) {
          setSelectAll(true);
        } else {
          setSelectAll(false);
          // console.log("selected",selected);
        }
      }
    } catch (error) {
      console.log("handleSubmit", error);
    }
  };

  // update plant
  const handleChangePlantData = (e) => {
    setEditPlantData({ ...editPlantData, [e.target.name]: e.target.value });
  };

  // Update plant api
  const handleUpdatePlant = async () => {
    if (editPlantData.plant_name === "") {
      notify("Please Enter Plant Name");
      return;
    }
    if (editPlantData.plant_description === "") {
      notify("Please Enter Plant Description");
      return;
    }
    const params = {
      id: plantId,
      plant_name: editPlantData.plant_name,
      plant_description: editPlantData.plant_description,
    };
    try {
      setLoading(true);
      const response = await editPlant(plantId, params);
      if (response.status == 200 || response.status == 201) {
        notify1("update plant successfully");
        setLoading(false);
        getPlatListByComapnyAPI();
        setOpenEditplant(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };

  // Delete plant
  const handleDeletePlant = async () => {
    const response = await deletePlant(plantId);
    if (response.status == 200) {
      getcompanyListPlantManegementApi();
      setOpenDeleteModal(false);
    }
  };

  useEffect(() => {
    getcompanyListPlantManegementApi();
  }, []);

  const getPlatListByComapnyAPI = async () => {
    setisShimmerPlantList(true);
    const param = {
      company: selectCompany,
    };
    try {
      // setLoading(true);
      const resp = await getPlantByCompany(param);
      if (resp.status == 200 || resp.status == 201) {
        setTimeout(() => {
          setisShimmerPlantList(false);
        }, 1000);
        setLoading(false);
        setPlantList(resp.data.payload);
        if (plantId) {
          setPlantId(plantId);
        } else {
          setPlantId(resp.data.payload[0].id);
        }
      }
    } catch (error) {
      setisShimmerPlantList(false);
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };

  const getPlatListByComapnyAPIChnageCountry = async () => {
    setisShimmerPlantList(true);
    const param = {
      company: selectCompany,
    };
    try {
      // setLoading(true);
      const resp = await getPlantByCompany(param);
      if (resp.status == 200 || resp.status == 201) {
        setTimeout(() => {
          setisShimmerPlantList(false);
        }, 1000);

        setLoading(false);
        setPlantList(resp.data.payload);

        // if (resp.data.payload.length) {
        //   setPlantId(resp.data.payload[0].id);
        // }
      }
    } catch (error) {
      setisShimmerPlantList(false);
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };

  useEffect(() => {
    if (selectCompanyName) {
      getPlatListByComapnyAPIChnageCountry();
    }
  }, [selectCompanyName]);

  // getDeviceList
  // const getDeviceListApi = async () => {
  //     const resp = await getDevicePlant(searchDevice);
  //     if (resp.status == 200) {
  //         setDeviceList(resp.data.payload)
  //     }
  // }

  // useEffect(() => {
  //     getDeviceListApi();
  // }, [searchDevice])

  // assign Device Api
  const submitAssignDevice = async () => {
    // if (!assignDevice.length) {
    //   notify("device not found");
    //   return true;
    // }

    const param = {
      plant: plantId,
      device: assignDeviceIdArray,
    };

    if (!assignDeviceIdArray.length) {
      notify("Please Select Device");
      return true;
    }
    try {
      setLoading(true);
      const resp = await assignDeviceApi(param);
      if (resp.status == 200 || resp.status == 201) {
        setLoading(false);
        setOpenAssignDeviceModal(false);
        getPlatListByComapnyAPI();
        setAssignDeviceIdArray([]);
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };

  // plant Device delete Api
  const deleteDeviceByPlant = async () => {
    const param = {
      plant: plantId,
      device: selectDeviceListArray,
    };

    if (!selectDeviceListArray.length) {
      notify("Please Select Device");
      return true;
    }
    try {
      setLoading(true);
      const resp = await DeviceByPlantApi(param);
      if (resp.status == 200 || resp.status == 201) {
        setLoading(false);
        setOpenDeleteDeviceModal(false);
        getPlatListByComapnyAPI();
        setSelectDeviceListArray([]);
      }
    } catch (error) {
      setLoading(false);
      console.log("handleSubmit", error);
    }
  };

  return (
    <>
      <div>{/*  */}</div>
      <div className="containerWrap">
        <Container maxWidth="xxl">
          <div className="deviceManagementHeader-wrap">
            <div className="deviceManagementTitle-wrap">
              <KeyboardBackspaceIcon
                onClick={() => navigate("/clientmanagement")}
                className="backArrow"
              />{" "}
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  margin: "0",
                  paddingLeft: "10px",
                  color: "#222D39",
                  fontSize: "16px",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/clientmanagement")}
              >
                Plant Management
              </Typography>
            </div>
            <div className="addDevice-buttonWrap">
              <button
                className="addDevice-button plant"
                onClick={handleOpenaddPlantMdal}
              >
                Add Plant
              </button>
            </div>
          </div>
          {/* =============== Add Plant Modal Start =============== */}
          <ValidatorForm onSubmit={submitAddPlant}>
            {openAddplant && (
              <Card className="addPlant-cardModal">
                <Box className="addPlant-titleWrap">
                  <Typography
                    variant="h5"
                    gutterBottom
                    className="addPlant-title"
                  >
                    Add Plant
                  </Typography>
                  <img
                    src={images.closeIcon}
                    alt=""
                    style={{
                      width: "30px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                    className="closeImg"
                    onClick={handleOpenaddPlantCloseMdal}
                  />
                </Box>
                <Box className="addplantMdal-innerWrap">
                  <Stack spacing={2} pt="10px">
                    <TextField
                      id="filled-basic"
                      label="Plant Name"
                      name="plant_name"
                      variant="filled"
                      className="addplantInputfield"
                      InputProps={{}}
                      onChange={handleChangePlantDetails}
                    />
                    <TextField
                      id="filled-basic"
                      label="Plant Description"
                      name="plant_description"
                      variant="filled"
                      className="addplantInputfield"
                      InputProps={{}}
                      onChange={handleChangePlantDetails}
                    />
                    {/* <TextField id="filled-basic" label="Iron and Steel Industry" variant="filled" className='addplantInputfield' InputProps={{
                                }} /> */}
                  </Stack>
                  <Box className="addPlant-cardButtons">
                    <button className="addPlantBtn">Add</button>
                  </Box>
                </Box>
              </Card>
            )}
          </ValidatorForm>
          {/* =============== Add Plant Modal End =============== */}
          {/* =============== Edit Plant Modal Start =============== */}
          {openEditplant && (
            <Card className="addPlant-cardModal">
              <Box className="addPlant-titleWrap">
                <Typography
                  variant="h5"
                  gutterBottom
                  className="addPlant-title"
                >
                  Edit Plant
                </Typography>
                <img
                  src={images.closeIcon}
                  alt=""
                  style={{
                    width: "30px",
                    cursor: "pointer",
                    backgroundColor: "white",
                  }}
                  className="closeImg"
                  onClick={closePlantEditModel}
                />
              </Box>
              <Box className="addplantMdal-innerWrap">
                <Stack spacing={2} pt="10px">
                  <TextField
                    id="filled-basic"
                    name="plant_name"
                    label="Plant Name"
                    variant="filled"
                    className="addplantInputfield"
                    value={editPlantData.plant_name}
                    onChange={handleChangePlantData}
                    InputProps={{}}
                  />
                  <TextField
                    id="filled-basic"
                    name="plant_description"
                    label="Plant Description"
                    variant="filled"
                    className="addplantInputfield"
                    value={editPlantData.plant_description}
                    onChange={handleChangePlantData}
                    InputProps={{}}
                  />
                  {/* <TextField id="filled-basic" label="Iron and Steel Industry" variant="filled" className='addplantInputfield' InputProps={{
                                }} /> */}
                </Stack>
                <Box className="addPlant-cardButtons">
                  <button className="addPlantBtn" onClick={handleUpdatePlant}>
                    Update
                  </button>
                </Box>
              </Box>
            </Card>
          )}
          {/* =============== Edit Plant Modal Start =============== */}
          {/* =============== Delete Plant Modal Start =============== */}
          {openDeleteModal && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure<br></br> You Want To Delete?
              </Typography>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={handleDeletePlant}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleConfirmationCloseModal}
                >
                  No
                </button>
              </Box>
            </Card>
          )}
          {openDeleteDeviceModal && (
            <Card className="deleteConfirmation-Modal">
              <Typography
                variant="h6"
                gutterBottom
                className="deleteConfirmation-title"
              >
                Are You Sure<br></br> You Want To Delete?
              </Typography>
              <Box className="deleteConfirmation-btnWrap">
                <button
                  className="deleteConfirmation-Yesbtn"
                  onClick={deleteDeviceByPlant}
                >
                  Yes
                </button>
                <button
                  className="deleteConfirmation-Nobtn"
                  onClick={handleConfirmationDeleteDeviceCloseModal}
                >
                  No
                </button>
              </Box>
            </Card>
          )}
          {/* =============== Delete Plant Modal End =============== */}
          {/* =============== Assign Device Modal Start =============== */}
          {/* {openAssignDeviceModal && (
            <Card className="assignDevice-cardModal">
              <div className="assignDevice-cardTitle">
                <Box>
                  <Stack direction="row" spacing={{ sm: 42, md: 42, lg: 34 }}>
                    <Typography gutterBottom style={{ display: "none" }}>
                      ..
                    </Typography>
                    <img
                      src={images.closeIcon}
                      alt=""
                      style={{ width: "30px", cursor: "pointer" }}
                      onClick={handleOPenAssignDeviceCloseModal}
                    />
                  </Stack>
                  <Typography
                    gutterBottom
                    style={{
                      color: "#1C407B",
                      fontWeight: "600",
                      fontSize: "19px",
                      textAlign: "center",
                    }}
                  >
                    Assign Device
                  </Typography>
                </Box>
                <TextField
                  type="search"
                  fullWidth
                  placeholder="Search Device"
                  value={searchDevice}
                  onChange={(e) => setSearcDevice(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  className="assignDeviceSearch"
                />
              </div>
              <div className="container__content">
                <Box>
                  {assignDevice
                    ? assignDevice
                        .filter((e) =>
                          e.device_name
                            .toLowerCase()
                            .includes(searchDevice.toLowerCase())
                        )

                        .map((e) => {
                          return (
                            <>
                              <div className="assignDeviceModalWrap">
                                <div className="assignDeviceDetails">
                                  <div className="assignDeviceDetailsWrap">
                                    <div>
                                      <Checkbox
                                        {...label}
                                        checked={assignDeviceIdArray.includes(e.id)}
                                        onClick={() =>
                                          handleSelectAssingDeviceId(e.id)
                                        }
                                      />
                                    </div>
                                    <div>
                                      <Typography
                                        variant="body2"
                                        gutterBottom
                                        className="assignDeviceDetailsTitle"
                                      >
                                        {e.device_name}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        display="block"
                                        gutterBottom
                                        className="assignDeviceDetailsDisc"
                                      >
                                        {e.device_description}
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                    : []}
                </Box>
              </div>
              <div className="assignDevice-cardTitle1">
                <Box>
                  <button
                    className="assignDevice-submitButton"
                    onClick={submitAssignDevice}
                  >
                    Submit
                  </button>
                </Box>
              </div>
            </Card>
          )} */}
          <ValidatorForm onSubmit={submitAssignDevice}>
            {openAssignDeviceModal && (
              <>
                <div className="select-gateway-popup">
                  {/* <div className="d-flex devices-text"> */}
                  <div style={{ display: "flex" }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={5}>
                        <h5 style={{ color: "#1C407B" }}>
                          <b>Assign Device</b>
                        </h5>
                      </Grid>
                      <Grid item xs={12} sm={7}></Grid>
                    </Grid>
                  </div>

                  <Grid container spacing={2} className="pt-2">
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box className="devicemodal-box">
                        <h6 style={{ fontWeight: 600 }}>Select Gateway</h6>
                        <div
                          style={{
                            height: "420px",
                            overflow: "auto",
                            position: "relative",
                          }}
                        >
                          {assignGateway?.length ? (
                            assignGateway?.map((gateway, index) => (
                              <div key={gateway.id}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "13px 8px",
                                    background: "white",
                                    cursor: "pointer",
                                    marginTop: index > 0 ? "4px" : "0", // Add margin top for all but the first gateway
                                  }}
                                  onClick={() => handleClick(gateway.id)}
                                >
                                  {gateway.gateway_name} <ArrowDropDownIcon />
                                </div>
                                {selectedGateway === gateway.id && (
                                  <div
                                    style={{
                                      maxHeight: "300px",
                                      overflow: "auto",
                                    }}
                                  >
                                    {machineData.map((machine) => (
                                      <div
                                        key={machine.id}
                                        style={{
                                          position: "relative",
                                          padding: "7px 25px",
                                          cursor: "pointer",
                                          boxShadow: "0px 6px 20px #6D6D6D1A",
                                          background: "#ffff",
                                          borderRight:
                                            selectedMachineId === machine.id
                                              ? "3px solid #1c407b"
                                              : "none",
                                        }}
                                        onClick={() =>
                                          handleMachineClick(
                                            machine.id,
                                            machine
                                          )
                                        }
                                      >
                                        <h6 className="machine-title">
                                          {machine.machine_name}
                                        </h6>
                                        <p className="machine-description">
                                          {machine.uid}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ))
                          ) : (
                            <div className="gateway-error-div">
                              <ErrorIcon className="gateway-error-icon" />
                              <h5 className="mt-2">No data found.</h5>
                            </div>
                          )}
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Box className="devicemodal-box">
                        <div className="d-flex justify-content-between">
                          {machineName ? (
                            <h6 style={{ fontWeight: 600 }}>
                              {machineName} - Device List
                            </h6>
                          ) : (
                            <h6></h6>
                          )}
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  style={{
                                    color: "#1C407B",
                                    padding: "0px",
                                    marginRight: "3px",
                                  }}
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              }
                              label={
                                <Typography style={{ fontSize: "15px" }}>
                                  All
                                </Typography>
                              }
                            />
                          </FormGroup>
                        </div>

                        <div
                          className="pt-2 mb-2"
                          style={{
                            height: "420px",
                            overflow: "auto",
                          }}
                        >
                          {deviceData?.length ? (
                            deviceData?.map((e) => {
                              return (
                                <div
                                  className="assignDeviceModalWrap mb-2"
                                  style={{ background: "white" }}
                                >
                                  <div className="assignDeviceDetails">
                                    <div className="assignDeviceDetailsWrap">
                                      <div>
                                        <Checkbox
                                          style={{
                                            color: "#1C407B",
                                          }}
                                          {...label}
                                          checked={assignDeviceIdArray.includes(
                                            e.id
                                          )}
                                          onClick={() => handleCheckBox(e.id)}
                                        />
                                      </div>
                                      <div>
                                        <Typography
                                          variant="body2"
                                          gutterBottom
                                          className="assignDeviceDetailsTitle"
                                        >
                                          {e.device_name}
                                          {/* {e.device_name} */}
                                        </Typography>
                                        <Typography
                                          variant="caption"
                                          display="block"
                                          gutterBottom
                                          className="assignDeviceDetailsDisc"
                                        >
                                          {e.device_description}
                                          {/* {e.device_description} */}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="gateway-error-div">
                              <ErrorIcon className="gateway-error-icon" />
                              <h5 className="mt-2">No data found.</h5>
                            </div>
                          )}
                        </div>
                      </Box>
                    </Grid>
                  </Grid>

                  <div className="text-center pt-5">
                    <button
                      className="canclebtn"
                      onClick={() => setOpenAssignDeviceModal(false)}
                      // onClick={(e) => handleCloseConfigrationClick(e)}
                    >
                      Cancel
                    </button>
                    <button
                      className="submitbtn"
                      // onClick={() => submitAssignDevice()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </>
            )}
          </ValidatorForm>
          {/* =============== Assign Device Modal End =============== */}
          <Grid container style={{ paddingTop: "20px" }}>
            <Grid item xs={12}>
              <Card className="manageDepartment-card">
                <Grid
                  container
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "30px",
                    paddingBottom: "30px",
                  }}
                >
                  <Grid item sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Select Company</b>
                      <FormControl className="selectCompany-dropDown">
                        <Select
                          value={selectCompany}
                          onChange={handleChangeCompany}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {companyList.length
                            ? companyList.map((e) => {
                                return (
                                  <MenuItem value={e.id} key={e.id}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <span>{e.company_name}</span>
                                      <Typography
                                        variant="body2"
                                        gutterBottom
                                        style={{ marginBottom: "0px" }}
                                      >
                                        {e.company_description}
                                      </Typography>
                                    </div>
                                  </MenuItem>
                                );
                              })
                            : []}
                        </Select>
                      </FormControl>
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Select Plant</b>
                    </Typography>
                    <div
                      className="manageDepartment-wrap"
                      style={{ height: "538px", overflow: "auto" }}
                    >
                      {!isShimmerPlantList ? (
                        plantList.length ? (
                          plantList.map((e, i) => {
                            return (
                              <div
                                className="plantmanagementDepartment-fieldWrap"
                                onClick={() => {
                                  if (
                                    openAddplant ||
                                    openAssignDeviceModal ||
                                    openEditplant ||
                                    openDeleteModal ||
                                    openDeleteDeviceModal
                                  ) {
                                    return true;
                                  }
                                  setPlantId(e.id);
                                }}
                              >
                                {plantId == e.id && (
                                  <img
                                    src={images.activeDeviceimg}
                                    alt=""
                                    className="activeDeviceimg"
                                  />
                                )}
                                <div
                                  className="manageDepartment-field"
                                  id="1"
                                  onClick={(e) => handleTabValue(e)}
                                >
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    style={{ margin: "0", color: "#001323" }}
                                    id="1"
                                    onClick={(e) => handleTabValue(e)}
                                  >
                                    <b
                                      id="1"
                                      onClick={(e) => handleTabValue(e)}
                                    >
                                      {e.plant_name}
                                    </b>
                                    <Typography
                                      variant="body2"
                                      gutterBottom
                                      id="1"
                                      onClick={(e) => handleTabValue(e)}
                                    >
                                      {e.plant_description
                                        ? e.plant_description
                                        : "-"}
                                    </Typography>
                                  </Typography>
                                  <div className="manageDepartment-fieldIcon">
                                    <img
                                      src={images.editImg}
                                      alt=""
                                      style={{
                                        width: "25x",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleEditModal(e)}
                                    />
                                    <img
                                      src={images.deleteImg}
                                      alt=""
                                      style={{
                                        width: "25px",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleConfirmationModal}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <Card className="isShimmerPlantList plantmanagementDepartment-fieldWrap">
                            No Data Found
                          </Card>
                        )
                      ) : (
                        <>
                          {[...Array(7)].map((_, index) => (
                            <Card
                              key={index}
                              className="isShimmerPlantList plantmanagementDepartment-fieldWrap"
                            >
                              <ShimmerText
                                line={3}
                                gap={10}
                                center
                                className="Assign-Device-ShimmerText"
                              />
                            </Card>
                          ))}
                        </>
                      )}
                    </div>
                  </Grid>
                  <Grid item sm={12} md={8} lg={8} xl={8}>
                    <Typography variant="subtitle2" gutterBottom>
                      <b>Assign Device</b>
                    </Typography>
                    <div className="plantmanagementDepartment-card">
                      <Box className="plantmanagementDepartment-addDevicewrap">
                        <div
                          onClick={handleOPenAssignDeviceModal}
                          className="plantmanagementDepartment-assignDeviceTextWrap"
                        >
                          <Typography
                            variant="caption"
                            display="lock"
                            gutterBottom
                            className="plantmanagemetDepartment-assignDeviceText"
                          >
                            Assign Device
                          </Typography>
                          <img
                            src={images.addIcon}
                            alt=""
                            className="addDeviceImg"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <img
                          src={images.editImg}
                          alt=""
                          className="addDeviceImg"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (
                              openAddplant ||
                              openAssignDeviceModal ||
                              openEditplant ||
                              openDeleteModal ||
                              openDeleteDeviceModal
                            ) {
                              return true;
                            }
                            navigate("/deviceManagement");
                          }}
                        />
                        <img
                          src={images.deleteImg}
                          alt=""
                          className="addDeviceImg"
                          style={{ cursor: "pointer" }}
                          onClick={handleConfirmationDeleteDeviceModal}
                        />
                      </Box>
                      {!isShimmerPlantList ? (
                        plantList.length ? (
                          plantList.map((e, i) => {
                            if (e.id == plantId) {
                              return e.device.map((e1) => {
                                return (
                                  // <Card className="addDeviceDetailsCard gap">
                                  //   <Box className="addDeviceCardDetailsWrap">
                                  //     <Box className="addDeviceDetailsCardetails-wrap">
                                  //       <Checkbox
                                  //         {...label}
                                  //         className="signIncheckBox"
                                  //         onClick={() =>
                                  //           handleSelectDeviceId(e1.id)
                                  //         }
                                  //       />
                                  //       <Box className="addDeviceDetailsCardetails">
                                  //         <Typography
                                  //           variant="subtitle2"
                                  //           gutterBottom
                                  //         >
                                  //           <b>{e1.device_name}</b>
                                  //         </Typography>
                                  //         <Typography
                                  //           variant="caption"
                                  //           display="block"
                                  //           gutterBottom
                                  //           className="addDeviceDetailsCardetails-text"
                                  //         >
                                  //           {e1.uid}
                                  //         </Typography>
                                  //         <Typography
                                  //           variant="caption"
                                  //           display="block"
                                  //           gutterBottom
                                  //           className="addDeviceDetailsCardetails-text"
                                  //         >
                                  //           {e1.device_description}
                                  //         </Typography>
                                  //       </Box>
                                  //     </Box>
                                  //     <button
                                  //       className="addDeviceConfi-btn"
                                  //       onClick={() => {
                                  //         if (
                                  //           openAddplant ||
                                  //           openAssignDeviceModal ||
                                  //           openEditplant ||
                                  //           openDeleteModal ||
                                  //           openDeleteDeviceModal
                                  //         ) {
                                  //           return true;
                                  //         }
                                  //         navigate(
                                  //           `/deviceManagement/configure/device/${e1.id}`
                                  //         );
                                  //       }}
                                  //     >
                                  //       Configuration
                                  //     </button>
                                  //     {/* <button className='addDeviceConfi-btn' onClick={() => navigate('/deviceManagement/configure/device/:id')}>Configuration</button> */}
                                  //   </Box>

                                  // </Card>
                                  <Card className="addDeviceDetailsCard gap">
                                    <Box className="addDeviceCardDetailsWrap">
                                      <Box className="addDeviceDetailsCardetails-wrap">
                                        <Checkbox
                                          {...label}
                                          className="signIncheckBox"
                                          defaultChecked={false}
                                          onClick={() =>
                                            handleSelectDeviceId(e1.id)
                                          }
                                        />
                                        <Box className="addDeviceDetailsCardetails">
                                          <Typography
                                            variant="subtitle2"
                                            gutterBottom
                                          >
                                            <b>{e1.device_name}</b>
                                          </Typography>
                                          <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className="addDeviceDetailsCardetails-text"
                                          >
                                            UID - {e1.machine_uid}
                                          </Typography>
                                          <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                            className="addDeviceDetailsCardetails-text"
                                          >
                                            {e1.device_description}
                                          </Typography>
                                        </Box>
                                      </Box>

                                      <>
                                        <div>
                                          {!e1.is_configure ? (
                                            <img
                                              src={images.alertImage}
                                              alt=""
                                              className="alarmManagement groupIcon"
                                              onMouseEnter={() =>
                                                handleOpenModal(e1.id)
                                              }
                                              onMouseLeave={() =>
                                                handleCloseModal(e1.id)
                                              }
                                            />
                                          ) : null}
                                          {deviceLastSeenModal[e1.id] && (
                                            <Card className="clientManagement-updateModal-data plant-management-update-data top">
                                              <Box className="clientMangement-updatedataWrap-data">
                                                <div>
                                                  <h6
                                                    className="mb-0"
                                                    style={{
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Configuration Pending..
                                                  </h6>
                                                </div>
                                              </Box>
                                            </Card>
                                          )}

                                          <button
                                            className="addDeviceConfi-btn"
                                            onClick={() => {
                                              if (
                                                openAddplant ||
                                                openAssignDeviceModal ||
                                                openEditplant ||
                                                openDeleteModal ||
                                                openDeleteDeviceModal
                                              ) {
                                                return true;
                                              }
                                              // navigate(
                                              //   `/deviceManagement/configure/machine/${e1.machine}`
                                              // );
                                              navigate(
                                                `/plantmanagement/deviceManagement/configure/machine/${e1.machine}`
                                              );
                                            }}
                                          >
                                            Configuration
                                          </button>
                                        </div>
                                      </>

                                      {/* <button className='addDeviceConfi-btn' onClick={() => navigate('/deviceManagement/configure/device/:id')}>Configuration</button> */}
                                    </Box>
                                  </Card>
                                );
                              });
                            }
                          })
                        ) : (
                          <Card className="isShimmerPlantList addDeviceDetailsCard gap">
                            No Device Found
                          </Card>
                        )
                      ) : (
                        <>
                          {[...Array(7)].map((_, index) => (
                            <Card
                              key={index}
                              className="isShimmerPlantList addDeviceDetailsCard gap"
                            >
                              <ShimmerText
                                line={3}
                                gap={10}
                                center
                                className="Assign-Device-ShimmerText"
                              />
                            </Card>
                          ))}
                        </>
                      )}
                      {/* <Card className='addDeviceDetailsCard gap'>
                                            <Box className="addDeviceCardDetailsWrap">
                                                <Box className='addDeviceDetailsCardetails-wrap'>
                                                    <Checkbox {...label} className='signIncheckBox' />
                                                    <Box className='addDeviceDetailsCardetails'>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            <b>Device - 2</b>
                                                        </Typography>
                                                        <Typography variant="caption" display="block" gutterBottom className='addDeviceDetailsCardetails-text'>
                                                            UID - 71f73dee-4596-48c8-bdof-7f1234d
                                                        </Typography>
                                                        <Typography variant="caption" display="block" gutterBottom className='addDeviceDetailsCardetails-text'>
                                                            Lorem Ipsum is simply dummy text
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <button className='addDeviceConfi-btn'>Configuration</button>
                                            </Box>
                                        </Card> */}
                      {/* <Card className='addDeviceDetailsCard gap'>
                                            <Box className="addDeviceCardDetailsWrap">
                                                <Box className='addDeviceDetailsCardetails-wrap'>
                                                    <Checkbox {...label} className='signIncheckBox' />
                                                    <Box className='addDeviceDetailsCardetails'>
                                                        <Typography variant="subtitle2" gutterBottom>
                                                            <b>Device - 3</b>
                                                        </Typography>
                                                        <Typography variant="caption" display="block" gutterBottom className='addDeviceDetailsCardetails-text'>
                                                            UID - 71f73dee-4596-48c8-bdof-7f1234d
                                                        </Typography>
                                                        <Typography variant="caption" display="block" gutterBottom className='addDeviceDetailsCardetails-text'>
                                                            Lorem Ipsum is simply dummy text
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <button className='addDeviceConfi-btn'>Configuration</button>
                                            </Box>
                                        </Card> */}
                    </div>
                    <button
                      className="Plantmanagemet submit"
                      onClick={() => {
                        if (
                          openAddplant ||
                          openAssignDeviceModal ||
                          openEditplant ||
                          openDeleteModal ||
                          openDeleteDeviceModal
                        ) {
                          return true;
                        }
                        navigate("/clientmanagement");
                      }}
                    >
                      Submit
                    </button>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid></Grid>
          </Grid>
        </Container>
      </div>
      {isLoading && <Loader />}
    </>
  );
}

export default CompanyByPlantmanagement;
