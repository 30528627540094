import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

const ReportTableListData = () => {
    return (
        <>
            <div
                style={{
                    overflowX: "auto",
                    overflowY: "auto",
                    maxHeight: "291px",
                }}
            >
                <Table className="source-table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Min</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Max</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Mean</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Std. Dev</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>All Time Min</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>All Time Max</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>{" "}
        </>
    )
}

export default ReportTableListData;