import {request} from "../../api/api";

export const getSmartTagGateway = (params)=>{
    return request.post(`/smarttag/overview/getGateway/`,params)

}

export const getSmartTagMachine = (params)=>{
    return request.post(`/smarttag/overview/getMachine/`,params)
}

export const getDeviceByDashboard = (params)=>{
    return request.post(`/dashboard/getDeviceByDashboard/`,params)
}

export const getTagList =(params) =>{
    return request.post(`/vms/reports/getTagList/`,params)
}

export const getTagDevice =(params)=>{
    return request.post(`/smarttag/overview/getDevice/`,params)
}

export const getTagGroups =(params) =>{
    return request.post(`/smarttag/overview/getTagsGroups/`,params)
}

export const getInteractionTag = (params) =>{
    return request.post(`/smarttag/getInteractedTag/`,params)
}

export const getTagData = (params) =>{
    return request.post(`/smarttag/getTagData/`,params)
}

export const publistData =(params) =>{
    return request.post(`/smarttag/addTagPublishHistory/`,params)
}

export const tagInteractionData = (params) =>{
  return request.post(`/smarttag/tagInteraction/`,params)
}

export const pinUnpinGroupData = (params)=>{
    return request.post(`/smarttag/overview/pinUnpinGroup/`,params)
}
export const getTagBarGraphData = (params) =>{
    return request.post(`/smarttag/getTagBarGraph/`,params)
}

export const getAlarmStatus = (params)=>{
    return request.post(`/smarttag/overview/getGatewayAlarmStatus/`,params)
}

export const getMachineAlarmStatus = (params) =>{
    return request.post(`/smarttag/overview/getMachineAlarmStatus/`,params)
}

export const getDeviceAlarmStatus = (params)=>{
    return request.post(`/smarttag/overview/getDeviceAlarmStatus/`,params)
}

export const exportSmartTagAnalyticsCOC = (params) =>{
    return request.post(`/smarttag/exportSmartTagAnalyticsCOCExcel/`,params)
}
export const exportSmartTagAnalyticsPOP = (params) =>{
    return request.post(`/smarttag/exportSmartTagAnalyticsPOPExcel/`,params)
}
export const exportSmartTagReportsExcel = (params) =>{
    return request.post(`/smarttag/exportSmartTagReportsExcel/`,params)
}