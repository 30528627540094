import React, { useEffect, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
HC_more(Highcharts); // Initialize the 'highcharts-more' module
exporting(Highcharts); // Initialize the 'exporting' module

const HighchartsGraph = ({
    selectUnit,
    combineChartData,
    selectTagData,
    averageValue,
    average
}) => {

    const [chartOption, setChartOption] = useState({
        chart: {
          type: "spline",
        },
        title: {
          text: null, // Remove the title
        },
        xAxis: {
          categories: [],
          scrollbar: {
            enabled: true,
          },
          gridLineWidth: 1,
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        series: [],
        colors: selectTagData.length ? selectTagData.map((e) => e.color) : [],
        exporting: {
          enabled: true, // Disable exporting options
        },
        credits: {
          enabled: false, // Disable credits
        },
      });


    const categoriesMaxLegth = combineChartData.reduce((maxIndex, currentArray, currentIndex, array) => {
        if (currentArray.length > array[maxIndex].length) {
            return currentIndex;
        } else {
            return maxIndex;
        }
    }, 0);

    const categories = combineChartData[categoriesMaxLegth]?.map((e) => e.tagTime) || [];

    const averageSeries = average ? [{
        name: 'Average',
        data: averageValue.slice(averageValue.length - combineChartData[0].length),
        color: 'green',
        dashStyle: 'ShortDash', // You can use other dash styles as well
    }] : [];

    const seriesForDevices = selectTagData.map((row, index) => {
        return {
            name: row.tagName,
            data: (combineChartData[index] && combineChartData[index].map((e) => parseFloat(e.tagValue))) || [],
            color: row.color,
            dashStyle: "Solid",
            lineWidth: 1,
        };
    });

    const series = [...seriesForDevices, ...averageSeries];

    useEffect(() => {
        setChartOption({
          ...chartOption,
          xAxis: {
            ...chartOption.xAxis,
            categories: categories.concat([""]),
          },
          yAxis: {
            title: {
              text: selectUnit,
            },
          },
          series: series,
        });
      }, [combineChartData, average]);


    return (
        <div style={{ height: "320px" }}>
            <HighchartsReact highcharts={Highcharts} options={chartOption} />
        </div>
    );
};

export default HighchartsGraph;
