import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { PieChartShimmer } from "../../components/Shimmer/PieChartShimmer/PieChartShimmer";
import { images } from "../../config";

export function VmsUpTimePieChart({
  AvailabilityPOPPieChartData,
  AvailabilityPOP1PieChartData,
  shimmer
}) {
  // Check if data is available before accessing
  const popPieChartData = AvailabilityPOPPieChartData?.deviceData || [];
  const pop1PieChartData = AvailabilityPOP1PieChartData?.deviceData || [];

  const data = {
    labels: AvailabilityPOPPieChartData?.deviceData
    ?.map((row) => row.device__device_name)
    .concat(
      AvailabilityPOP1PieChartData?.deviceData?.map(
        (row) => row.device__device_name
      )
    ) || [],
    seriesData: [
      {
        name: "# of Votes",
        data: [
          Math.abs(popPieChartData[0]?.up_time || 0),  // Use absolute value for display
          Math.abs(pop1PieChartData[0]?.up_time || 0),  // Use absolute value for display
        ],
        colors: ["#C0255F", "#E66595"],
      },
    ],
  };

  const options = {
    chart: {
      type: 'pie',
      height: 230,
      width: 230,
      backgroundColor: 'transparent',
    },
    title: {
      text: ''
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      formatter: function () {
        const total = this.series.data.reduce((acc, point) => acc + Math.abs(point.originalY), 0); // Use absolute values for total
        const percentage = ((Math.abs(this.point.originalY) / total) * 100).toFixed(2) + '%';
        return `<div style ="font-size : 12px">
                  <b>${this.point.name}</b><br/>
                  <span style="color:${this.point.color}">\u25A0</span> ${this.point.originalY} (${percentage})
                </div>`;
      }
    },
    plotOptions: {
      pie: {
        innerSize: '70%',
        dataLabels: {
          enabled: false,
        }
      }
    },
    series: data.seriesData.map((series, index) => ({
      name: series.name,
      data: series.data.map((value, dataIndex) => ({
        name: data.labels[dataIndex],
        y: value,  // Display absolute value in chart
        color: series.colors[dataIndex],
        originalY: [popPieChartData[dataIndex]?.up_time, pop1PieChartData[dataIndex]?.up_time][dataIndex],  // Store original value for tooltip
      })),
    })),
    credits: {
      enabled: false
    }
  };

  // Check if all data points are zero
  const allZeros = data.seriesData.every(series => series.data.every(value => value === 0));

  return (
    <div style={{ width: "100%", height: "195px" }} className="doughnut-chart1">
      {!shimmer ? (
        <>
          {!allZeros ? (
            <HighchartsReact highcharts={Highcharts} options={options} />
          ) : (
            <img src={images.noDataFoundImg} className="notFoundDataImg not-found" alt="" />
          )}
        </>
      ) : (
        <PieChartShimmer size="195px" position="relative" />
      )}

      <p className="doughnut-chartText" style={{ left: "25%" }}>
        {!allZeros ? ((AvailabilityPOPPieChartData?.deviceTotal?.total_up_time || 0) + (AvailabilityPOP1PieChartData?.deviceTotal?.total_up_time || 0)) : ""}
      </p>
      <p className="doughnut-chartText-unit" style={{ left: "27%", top: "51%" }}>
        {!allZeros ? (AvailabilityPOPPieChartData?.deviceTotal?.total_up_time ? "hr" : "") : ""}
      </p>
    </div>
  );
}
