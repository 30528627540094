import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ShimmerText } from "react-shimmer-effects";
import {ParetoChartShimmer} from "../../../components/Shimmer/ParetoChartShimmer/ParetoChartShimmer";

HC_more(Highcharts);
exporting(Highcharts);


const ParetoGraph = ({ Graphdata, tagID ,shimmer}) => {

    
   
    const [key, setKey] = useState(0);
    const [chartOption, setChartOption] = useState({
        chart: {
            type: "column",
        },
        title: {
            text: null,
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            title: {
                text: null,
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: "bold",
                    color: "gray",
                },
            },
        },
        plotOptions: {
            column: {
                stacking: "normal",
                dataLabels: {
                    enabled: false
                },
            },
        },
        series: [],
        scrollbar: {
            enabled: true, // Enable scrollbar
        },
        exporting: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    })

    const labels = Graphdata ? Graphdata.length ? Graphdata[0].data.map((label) => label.MIN_KEY) : [] : [];

    // const categoriesMaxLegth = Graphdata.reduce(
    //     (maxIndex, currentArray, currentIndex, array) => {
    //       if (currentArray.data.length > array.data[maxIndex].length) {
    //         return currentIndex;
    //       } else {
    //         return maxIndex;
    //       }
    //     },
    //     0
    //   );
    
    //   const MinKey =
    //   Graphdata[categoriesMaxLegth]?.map((e) => e.MIN_KEY) || [];

    //   console.log("MinKey",MinKey)

    const selectUnit = tagID ? tagID.length ? tagID[0]["tagUnit"] : "" : "";

    console.log("tagIDtagIDtagID", selectUnit);

    const data = Graphdata ? Graphdata.length ? Graphdata.map((row) => {
        const findTagId = tagID.find((f) => f.tagId == row.tag_id)
        return ({
            name: row.tag_name,
            data: row.data.map((value) => value.tag_value),
            color: findTagId ? findTagId.color : "",
            borderColor: findTagId ? findTagId.color : "",
            stack: "Stack 0",
            barPercentage: 1,
            barThickness: 30,
        })
    }) : []  : []

   
    const options = {
        chart: {
            type: "column",
        },
        title: {
            text: null,
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            categories: labels,
        },
        yAxis: {
            title: {
                text: null,
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: "bold",
                    color: "gray",
                },
            },
        },
        plotOptions: {
            column: {
                stacking: "normal",
                dataLabels: {
                    enabled: false
                },
            },
        },
        series: data,
        scrollbar: {
            enabled: true, // Enable scrollbar
        },
        exporting: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };


    useEffect(() => {
        // const min = 0;
        // const max = Graphdata.length;

        setChartOption({
            ...chartOption,
            xAxis: {
                ...chartOption.xAxis,
                // min: min,
                // max: max,
                categories: labels.concat([""]),
            },
            yAxis: {
                ...chartOption.yAxis,
                title: {
                    text: selectUnit,
                },
            },
            series: data,
        });

        // Update the key to force component re-render and chart redraw
        setKey(key + 1);
    }, [Graphdata]);

    return (
        <div key={key}>
           {!shimmer ? <HighchartsReact highcharts={Highcharts} options={chartOption} /> :  <ParetoChartShimmer className="ParetoChartShimmer-OverView"/> }

        </div>
    );
}

export default ParetoGraph;




{/* <ShimmerText line={12} gap={15} /> */}