import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "@mui/material";
import "../Source/Source.css";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 3,

  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function CardSlider({ deviceData }) {
  return (
    <Slider {...sliderSettings} className="my-slider-class">
      {deviceData
          ? deviceData.map((card, index) => (
            
              <div>
                <Card
                  style={{
                    width: "96%"
                  }}
                  // onClick={() => handleOpenPopup(card.device)}
                >
                  <h5
                    className="cardTitle"
                    style={{
                      backgroundColor: "#AEBCD2B3",
                      opacity: "0.8",
                    }}
                  >
                    {card.device__device_name}
                  </h5>
                  {/* <p className="cardDesc">Active: {card.active}</p>
            <p className="cardDesc">Apparent: {card.apparent}</p>
            <p className="cardDesc">KVAr :{card.KVAr}</p> */}
                  <Table className="card-slider-table">
                    <TableBody>
                      <TableRow>
                        <TableCell>Active</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>462222</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Apparent</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>1992</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>KVAr</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>01</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </div>
            ))
          : []}
    </Slider>
  );
}

export default CardSlider;
