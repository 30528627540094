import React, { useState } from "react";
import { SliderAuth } from "../../components/SliderAuth/SliderAuth";
import { Grid } from "@mui/material";
import ForgotPassword from './ForgotPassword/ForgotPassword';
import SignIn from "./SignIn/SignIn";
import Otp from "./OTP/Otp";
import NewPassword from './NewPassword/NewPassword';
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const navigate = useNavigate()
    const [currentState, setCurrentState] = useState("");
    const [email, setEmail] = useState("");
    const [OTP, setOTP] = useState("");

    const onNextClick = (nextState) => {
        setCurrentState(nextState);
    };

    const currentComponent = () => {
        switch (currentState) {
            case "verifyOTP":
                return (
                    <Otp
                        OTP={OTP}
                        email={email}
                        onNextClick={() => onNextClick("changePassword")}
                        onCancelClick={() => onNextClick("")}
                        setOTP={setOTP}
                        setEmail={setEmail}
                    />
                );
            case "changePassword":
                return (
                    <NewPassword
                        email={email}
                        OTP={OTP}
                        onNextClick={() => onNextClick(navigate("/"))}
                        onCancelClick={() => onNextClick("")}
                        setEmail={setEmail}
                        setOTP={setOTP}
                    />
                );

            default:
                return <ForgotPassword
                    email={email}
                    setEmail={setEmail}
                    setOTP={setOTP}
                    onNextClick={() => onNextClick("verifyOTP")}
                    onCancelClick={() => onNextClick(navigate("/"))}
                />
        }
    };

    return (
        <>
            {currentComponent()}
        </>
    )
}

export default Login;