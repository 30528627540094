import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Switch, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const EnergyIdealActual = () => {
  const [activeClass, setActiveClass] = useState("Analytics");
  const [active, setActive] = useState("Consumer");
  const [value, setValue] = React.useState("1");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  return (
    <>
      <div style={{ position: "relative" }}>
        <div className="analysis-tabs-btn pt-4">
          <div className="analytics-tabs-btn">
            <div className="d-flex">
              <button
                className={`${
                  active == "Consumer"
                    ? "Analytics-tabs-overview"
                    : "Reports-tabs-overview"
                }`}
                onClick={() => setActive("Consumer")}
                style={{ width: "210px", height: "50px" }}
              >
                Consumer over Consumer
              </button>
              <button
                className={`${
                  active == "Period"
                    ? "Analytics-tabs-overview"
                    : "Reports-tabs-overview"
                }`}
                onClick={() => setActive("Period")}
                style={{ width: "210px", height: "50px" }}
              >
                Period over Period
              </button>
            </div>
          </div>
          <div className="analytics-aggregation analytics-datepicker" style={{justifyContent:"flex-end"}}>
          <Box className="">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="custom-datepicker"
                  label="Start Date"
                  value={startDate}
                  inputFormat="DD/MM/YYYY"
                 
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ background: "#ffff" }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box className="">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="custom-datepicker"
                  label="End Date"
                  value={endDate}
                  inputFormat="DD/MM/YYYY"
                 
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ background: "#ffff" }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </div>
        </div>

        <div className="pt-2 d-flex gap-3">
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Box className="consumerData-boxes">
              <Box className="consumer-text-disc">
                <h6 className="Title">Title</h6>
                <h6>Ideal Vs Actual Cost Charts</h6>
              </Box>

              <Box className="consumer-text-disc mt-2">
                <h6 className="Title">Description</h6>
                <h6>Ideal(as Consumption) Vs Actual(As Billed) - Consumer over Consumer</h6>
              </Box>

              <TextField
                variant="outlined"
                className="search-textfield"
                placeholder="Search here"
                InputProps={{
                  startAdornment: <SearchIcon />,
                  inputProps: {
                    style: { padding: "9px" },
                  },
                }}
              />

<div className="furnace-1">
                    <Box className="consumer-text mt-2 d-flex justify-content-between">
                      <h6 className="FurnaceTitle mt-2">Furnace - 1</h6>
                   <Switch
                      onChange={handleChange}
                      defaultChecked
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#9A64B2",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#E2D9F1 !important",
                        },
                        "& .Mui-checked": {
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#9A64B2",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#E2D9F1 !important",
                          },
                        },
                      }}
                    />
                    </Box>
                    <Box className="consumer-text mt-2 d-flex justify-content-between">
                      <h6 className="FurnaceTitle mt-2">Furnace - 2</h6>
                   <Switch
                      onChange={handleChange}
                      defaultChecked
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#EF75A0",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#F0DCEE !important",
                        },
                        "& .Mui-checked": {
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#EF75A0",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#F0DCEE !important",
                          },
                        },
                      }}
                    />
                    </Box>
                    <Box className="consumer-text mt-2 d-flex justify-content-between">
                      <h6 className="FurnaceTitle mt-2">Furnace - 3</h6>
                   <Switch
                      onChange={handleChange}
                      defaultChecked
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#FEAA62",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#F2E1DE !important",
                        },
                        "& .Mui-checked": {
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#FEAA62",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#F2E1DE !important",
                          },
                        },
                      }}
                    />
                    </Box>
                    <Box className="consumer-text mt-2 d-flex justify-content-between">
                      <h6 className="FurnaceTitle mt-2">Furnace - 4</h6>
                   <Switch
                      onChange={handleChange}
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#001323",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#BFC2D2 !important",
                        },
                        "& .Mui-checked": {
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#001323",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#BFC2D2 !important",
                          },
                        },
                      }}
                    />
                    </Box>
                    <Box className="consumer-text mt-2 d-flex justify-content-between">
                      <h6 className="FurnaceTitle mt-2">Furnace - 6</h6>
                   <Switch
                      onChange={handleChange}
                 
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#001323",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#BFC2D2 !important",
                        },
                        "& .Mui-checked": {
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#001323",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#BFC2D2 !important",
                          },
                        },
                      }}
                    />
                    </Box>
                    <Box className="consumer-text mt-2 d-flex justify-content-between">
                      <h6 className="FurnaceTitle mt-2">Furnace - 6</h6>
                   <Switch
                      onChange={handleChange}
                
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#001323",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#BFC2D2 !important",
                        },
                        "& .Mui-checked": {
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#001323",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#BFC2D2 !important",
                          },
                        },
                      }}
                    />
                    </Box>
                    <Box className="consumer-text mt-2 d-flex justify-content-between">
                      <h6 className="FurnaceTitle mt-2">Furnace - 6</h6>
                   <Switch
                      onChange={handleChange}
          
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#001323",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#BFC2D2 !important",
                        },
                        "& .Mui-checked": {
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#001323",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#BFC2D2 !important",
                          },
                        },
                      }}
                    />
                    </Box>
                    <Box className="consumer-text mt-2 d-flex justify-content-between">
                      <h6 className="FurnaceTitle mt-2">Furnace - 6</h6>
                   <Switch
                      onChange={handleChange}
                    
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#001323",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#BFC2D2 !important",
                        },
                        "& .Mui-checked": {
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#001323",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#BFC2D2 !important",
                          },
                        },
                      }}
                    />
                    </Box>
                    <Box className="consumer-text mt-2 d-flex justify-content-between">
                      <h6 className="FurnaceTitle mt-2">Furnace - 6</h6>
                   <Switch
                      onChange={handleChange}
               
                      sx={{
                        "& .MuiSwitch-thumb": {
                          bgcolor: "#001323",
                        },
                        "& .MuiSwitch-track": {
                          bgcolor: "#BFC2D2 !important",
                        },
                        "& .Mui-checked": {
                          "& .MuiSwitch-thumb": {
                            bgcolor: "#001323",
                          },
                          "& .MuiSwitch-track": {
                            bgcolor: "#BFC2D2 !important",
                          },
                        },
                      }}
                    />
                    </Box>
                  </div>

              <div style={{ marginTop: "35px" }}>
                <button className="export-btn">EXPORT ENERGY DATA</button>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={9}>
            <Box className="consumerEnergyData-box">
              <div style={{ height: "322px" }}>
                <h6>
                  <b>Pareto Chart - Consumer over Consumer</b>
                </h6>
              </div>
              <div>
                <h6>
                  <b>Pie Chart - Consumer over Consumer</b>
                </h6>
              </div>
            </Box>
          </Grid>
        </div>
        <div className="overlay"></div>
      </div>
    </>
  );
};
export default EnergyIdealActual;
