import React, { useEffect } from "react";
import AuthenticationRoutes from "./routes/AuthenticationRoutes";
import UnAuthenticationRoutes from "./routes/UnAuthenticationRoutes";
import { BrowserRouter as Router,Routes, useParams } from "react-router-dom";
import Header from "./container/Header/Header";
import FooterText from "./components/FooterText/FooterText";
import { useSelector } from "react-redux";
import Cookies from 'universal-cookie';
import { toast, ToastContainer } from "react-toastify";

const App = () => {

  const {email,token } = useSelector(state => state.userState);
  // if (true) {
  //   console.log = function no_console() { };
  // }

  useEffect(() => {
    const isFirstLoad = !sessionStorage.getItem("pageVisited");

    if (isFirstLoad) {
      // This is the first visit, set the flag
      sessionStorage.setItem("pageVisited", "true");
    } else {
      // This is a page refresh
      console.log("Page refreshed");
    }
  }, []);
  return (
    <>
    <ToastContainer />
      {email && token ? (<><Router><Header /><FooterText/><AuthenticationRoutes /></Router></>) :  <UnAuthenticationRoutes />}
    </>
  );
}

export default App;
