import { Box, TextField } from "@mui/material";
import {
    DatePicker,
    LocalizationProvider,
    DateTimePicker,
} from "@mui/x-date-pickers";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";

const COCVmsDatePicker = ({
    time,
    startDateReFormat,
    endDateReFormat,
    handleStartDate,
    handleEndDate,
    access,
    handleTostMsg
}) => {
    return (
        <>
            {time ? (
                <>
                    <>
                        <Box
                            style={{ width: "40%", marginRight: "8px" }}
                            className=""
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label="Start Date"
                                    value={startDateReFormat}
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                    disableFuture
                                    onChange={(newValue) => handleStartDate(newValue)}
                                    className="custom-datepicker"
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box className="" style={{ width: "40%" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    label="End Date"
                                    value={endDateReFormat}
                                    onChange={(newValue) => handleEndDate(newValue)}
                                    disableFuture
                                    viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                    }}
                                    className="custom-datepicker"
                                />
                            </LocalizationProvider>
                        </Box>
                    </>

                </>
            ) : (
                <>
                    <>
                        <Box
                            style={{ width: "40%", marginRight: "8px" }}
                            className=""
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    className="custom-datepicker"
                                    label="Start Date"
                                    value={startDateReFormat}
                                    onChange={(newValue) => handleStartDate(newValue)}
                                    disableFuture
                                    inputFormat="DD/MM/YYYY"

                                    renderInput={(params) => (
                                        <TextField {...params} style={{ background: "#ffff" }} />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box className="" style={{ width: "40%" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    className="custom-datepicker"
                                    label="End Date"
                                    inputFormat="DD/MM/YYYY"

                                    value={endDateReFormat}
                                    onChange={(newValue) => handleEndDate(newValue)}
                                    disableFuture
                                    renderInput={(params) => (
                                        <TextField {...params} style={{ background: "#ffff" }} />
                                    )}
                                />
                            </LocalizationProvider>
                        </Box>
                    </>

                </>
            )}
        </>
    );
};

export default COCVmsDatePicker;
